import * as React from "react";
import styled from "styled-components";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { Action, ActionCreatorsMapObject, bindActionCreators } from "redux";
import moment from "moment";

import { ClientUnitType, UncomparedOrdersListDto } from "../../service-proxies";
import * as ordersApi from "../../_api/orders";
import * as notifyActions from "../../_actions/notification";
import * as searchActions from "../../_actions/search";
import * as layoutActions from "../../_actions/layout";
import { IGlobalStore } from "../../_reducers/reducers";
import withGranted, { IWithGrantedProps } from "../HOC/WithGranted";
import { ORDERS_UNCOMPARED_APPROVE } from "../../_constants/permissions";
import {
  saveFilterSettings,
  loadFilterSettings
} from "../../_utils/userSession";

import {
  Article,
  DatePicker,
  Link,
  MainContainer,
  Pagination,
  RunningLineLoader,
  Separator,
  Skeleton,
  Table
} from "../../components";
import LocationSelectorComponent from "../../components/LocationSelector";
import { clientUnitFilterOptions } from "../../_utils/clientUnitFilter";
import { IShortLocationInfo } from "../../_types/common";
import { IFlatLocationOption } from "../../_reducers/current-user";

interface ITwoLevelElement {
  className?: string;
}

const Position = {
  Container: styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
  `,

  Content: styled.div`
    height: 100%;
    flex: 1 1 100%;
  `,

  Footer: styled.div`
    flex: 0 0;
  `,

  Filter: styled.div`
    width: 260px;
    padding-top: 4px;
    margin-right: 16px;
  `,

  Toolbar: styled.div`
    text-align: right;
    > * {
      margin: 0 0 0 10px;
    }
  `,

  TopLine: styled<ITwoLevelElement>(function({ className, ...props }) {
    return (
      <div className={className}>
        <Separator>{props.children}</Separator>
      </div>
    );
  })`
    ${Separator} {
      width: 100%;
      padding: 10px 20px;
      display: flex;
    }
  `,

  TopLineElement: styled.div`
    flex: 1 1 auto;
    &:last-of-type {
      flex: 1 1 100%;
    }
    &:first-of-type {
      flex: 0 0 300px;
    }
  `,

  Head: styled<{ className?: string }>(({ className, children }) => (
    <header className={className}>
      <div>{children}</div>
    </header>
  ))`
    padding: 20px;
    > div {
      margin: -7.5px;
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
    }
  `,

  HeadElement: styled.div`
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    & > * {
      margin: 7.5px;
    }
  `,

  TabsSwitch: styled.div`
    padding: 10px 0 10px 10px;
  `
};

interface IListButtonProps {
  id?: string;
  onApproveClick: (id: string) => void;
}

const ListButton = function(props) {
  function handleClick() {
    props.onApproveClick(props.id);
  }
  return <Link.Button onClick={handleClick} {...props} />;
} as React.StatelessComponent<IListButtonProps>;

interface IListProps
  extends IConnectedProps,
    IConnectedActions,
    IWithGrantedProps {}

interface IListState {
  ordersStatus: string;
  orders?: UncomparedOrdersListDto[];
  totalCount: number;
  tab: string;
  startDate?: moment.Moment | null;
  dueDate?: moment.Moment | null;
  filter: string;
  sorting?: string;
  skip?: number;
  perPage?: number;
  focusedInput?: string;
  clientUnitId?: number;
  clientUnitType?: ClientUnitType;
}

interface IGetOrdersParams {
  startDate?: moment.Moment;
  dueDate?: moment.Moment;
  filter?: string;
  sorting?: string;
  skip?: number;
  perPage?: number;
  clientUnitId?: number;
  clientUnitType?: ClientUnitType;
}

class List extends React.Component<IListProps, IListState> {
  constructor(props) {
    super(props);

    this.handleTabChanged = this.handleTabChanged.bind(this);

    const filterSettings = loadFilterSettings("clientorders");

    this.state = {
      ordersStatus: "",
      totalCount: 0,
      tab: "orders",
      startDate:
        filterSettings.startDate != null
          ? moment(filterSettings.startDate)
          : null,
      dueDate:
        filterSettings.dueDate != null ? moment(filterSettings.dueDate) : null,
      filter: "",
      sorting: "submittingTime ASC",
      skip: 0,
      perPage: this.props.perPage || 10,
      clientUnitId: filterSettings.location || props.locationInfo.id,
      clientUnitType: ClientUnitType._3
    };
    this.getOrders = this.getOrders.bind(this);
    this.handleDateChanged = this.handleDateChanged.bind(this);
    this.handleFocusedChange = this.handleFocusedChange.bind(this);
    this.handleSortChange = this.handleSortChange.bind(this);
    this.handlePaginate = this.handlePaginate.bind(this);
    this.handleApproveOrder = this.handleApproveOrder.bind(this);
    this.handleFilterChange = this.handleFilterChange.bind(this);
  }

  componentDidMount() {
    this.props.setSearch("/clients-orders/client-orders", text => {
      this.setState({ skip: 0 }, () => {
        this.getOrders({ filter: text });
      });
    });
    this.getOrders();
  }

  componentWillReceiveProps(nextProps: IListProps) {
    if (nextProps.locationInfo.id !== this.props.locationInfo.id) {
      this.setState(
        {
          clientUnitId: nextProps.locationInfo.id,
          clientUnitType: ClientUnitType._3
        },
        () => {
          this.getOrders();
        }
      );
    }
  }

  static renderPreloader(perPage: number, status: string): React.Component[] {
    const rows = [];
    const elementsCount = status === "loading" ? perPage : 0;

    for (let i = 0; i < elementsCount; i++) {
      rows.push(
        <Table.Row
          key={i}
          rows={perPage}
          cells={[
            <Skeleton.Line key={`order-list-preloader-${i}`} size="sm" />,
            <Skeleton.Line key={`order-list-preloader-${i}`} size="sm" />,
            <Skeleton.Line key={`order-list-preloader-${i}`} size="sm" />,
            <Skeleton.Line key={`order-list-preloader-${i}`} size="sm" />,
            <Skeleton.Line key={`order-list-preloader-${i}`} size="sm" />,
            <Skeleton.Line key={`order-list-preloader-${i}`} size="sm" />
          ]}
        />
      );
    }
    return rows;
  }

  getOrders(params: IGetOrdersParams = {}): void {
    let statusStartKey = "loading";
    let statusFinnishKey = "loaded";
    const skip =
      Number.isInteger(params.skip) && params.skip >= 0
        ? params.skip
        : this.state.skip;

    if (params.sorting) {
      statusStartKey = "sorting";
      statusFinnishKey = "sorted";
    }

    this.setState({ ordersStatus: statusStartKey });
    ordersApi
      .getUncomparedOrders(
        params.startDate || this.state.startDate,
        params.dueDate || this.state.dueDate,
        params.clientUnitType || this.state.clientUnitType || undefined,
        params.clientUnitId || this.state.clientUnitId || undefined,
        params.filter || this.state.filter,
        params.sorting || this.state.sorting,
        skip,
        params.perPage || this.props.perPage
      )
      .then(({ items, totalCount }) => {
        this.setState({
          totalCount,
          orders: items,
          ordersStatus: statusFinnishKey
        });
      })
      .catch(e => {
        this.setState({ ordersStatus: "error" });
        this.props.notifySwagger(e, "error");
      });
  }

  handleTabChanged(value) {
    this.setState({ tab: value });
  }

  handleDateChanged(range: {
    startDate: moment.Moment;
    endDate: moment.Moment;
  }): void {
    const startDate = range.startDate
      ? range.startDate.startOf("day")
      : undefined;
    const dueDate = range.endDate ? range.endDate.startOf("day") : undefined;

    this.setState({ startDate, dueDate, skip: 0 }, () => {
      this.setFilterSettings();
      this.getOrders();
    });
  }

  handleFocusedChange(focusedInput) {
    this.setState({ focusedInput });
  }

  handleFilterChange(value: IFlatLocationOption) {
    this.setState(
      { clientUnitId: value.id, clientUnitType: value.level + 1, skip: 0 },
      () => {
        this.setFilterSettings();
        this.getOrders({ sorting: this.state.sorting });
      }
    );
  }

  handleSortChange(sorting: string): void {
    this.setState({ sorting }, () => {
      this.getOrders({ sorting });
    });
  }

  handlePaginate(pageInfo: { perPage: number; skip: number }): void {
    const { perPage, skip } = pageInfo;

    this.getOrders({ perPage, skip });
    this.props.setPerPage(perPage);
    this.setState({ skip });
  }

  handleApproveOrder(id): void {
    this.setState({ ordersStatus: "loading" });
    ordersApi
      .approveUncomparedOrder({ id })
      .then(() => {
        this.getOrders();
      })
      .catch(e => {
        this.props.notifySwagger(e, "error");
      });
  }

  setFilterSettings() {
    const filter = {
      location: this.state.clientUnitId,
      startDate: this.state.startDate,
      dueDate: this.state.dueDate
    };

    saveFilterSettings("clientorders", filter);
  }

  render() {
    return (
      <React.Fragment>
        <MainContainer>
          <Position.Container>
            <Position.Head>
              <Position.HeadElement>
                <Article.Text>
                  <strong>CLIENT ORDERS</strong>
                </Article.Text>
              </Position.HeadElement>
              <Position.HeadElement>
                <Position.Filter>
                  <LocationSelectorComponent
                    customFilter={clientUnitFilterOptions}
                    locationOptions={this.props.locationOptions}
                    onLocationChange={this.handleFilterChange}
                    selectedLocation={this.state.clientUnitId}
                  />
                </Position.Filter>
                <DatePicker
                  compact
                  type="range"
                  startDateId="startDate"
                  endDateId="endDate"
                  isOutsideRange={() => false}
                  startDate={this.state.startDate}
                  endDate={this.state.dueDate}
                  onDatesChange={this.handleDateChanged}
                  focusedInput={this.state.focusedInput}
                  keepOpenOnDateSelect
                  showPeriods
                  onFocusChange={this.handleFocusedChange}
                />
              </Position.HeadElement>
            </Position.Head>
            <RunningLineLoader
              isActive={this.state.ordersStatus === "loading"}
            />
            <Position.Content>
              <Table>
                <Table.Header
                  columns={[
                    {
                      name: "submittingTime",
                      label: "Submitted Date",
                      sortable: true
                    },
                    { name: "clientName", label: "Client", sortable: true },
                    { name: "ownerName", label: "Buyer", sortable: true },
                    { name: "id", label: "Order Number", sortable: true },
                    // { name: 'processed', label: 'Processed' },
                    { name: "address", label: "Location", sortable: true },
                    { name: "toolbar", label: "", size: "lg" }
                  ]}
                  sortBy={this.state.sorting}
                  onSortChanged={this.handleSortChange}
                />
                <Table.Body>
                  {(this.state.ordersStatus === "loaded" ||
                    this.state.ordersStatus === "sorting" ||
                    this.state.ordersStatus === "sorted") &&
                    (this.state.orders.length ? (
                      this.state.orders.map((item, index) => (
                        <Table.Row
                          key={`order-list-${index}-${item.id}`}
                          disabled={item.isApproved}
                          cells={[
                            moment(item.submittingTime).format("MM/DD/YYYY"),
                            item.clientName,
                            item.ownerName,
                            item.number,
                            // item.processed,
                            item.locationName,
                            <Position.Toolbar
                              key={`order-list-approve-${index}-${item.id}`}
                            >
                              <Link
                                key={`order-list-id-${index}-${item.id}`}
                                to={`/clients-orders/client-orders/${item.id}`}
                              >
                                View Order
                              </Link>
                              {this.props.isGranted(
                                ORDERS_UNCOMPARED_APPROVE
                              ) &&
                                !item.isApproved && (
                                  <ListButton
                                    id={String(item.id)}
                                    onApproveClick={this.handleApproveOrder}
                                  >
                                    Approve
                                  </ListButton>
                                )}
                            </Position.Toolbar>
                          ]}
                        />
                      ))
                    ) : (
                      <Table.Row
                        key={0}
                        colSpan="6"
                        rows={this.props.perPage}
                        cells={[<span key="0">NO ORDERS FOUND</span>]}
                      />
                    ))}
                  {List.renderPreloader(
                    this.props.perPage,
                    this.state.ordersStatus
                  )}
                </Table.Body>
              </Table>
            </Position.Content>
            <Position.Footer>
              <Pagination
                count={this.state.totalCount}
                skip={this.state.skip}
                perPage={this.props.perPage}
                onChange={this.handlePaginate}
              />
            </Position.Footer>
          </Position.Container>
        </MainContainer>
      </React.Fragment>
    );
  }
}

interface IConnectedProps {
  locationInfo?: IShortLocationInfo;
  locationOptions: IFlatLocationOption[];
  perPage: number;
}

interface IConnectedActions {
  notifySwagger: typeof notifyActions.notifySwagger;
  setSearch: typeof searchActions.set;
  setPerPage: typeof layoutActions.setPerPage;
}

export default connect(
  createStructuredSelector<IGlobalStore, IConnectedProps>({
    perPage: state => state.layout.perPage,
    locationInfo: state => state.currentUser.locationInfo,
    locationOptions: state => state.currentUser.locationOptions
  }),
  dispatch =>
    bindActionCreators<IConnectedActions & ActionCreatorsMapObject, ActionCreatorsMapObject<Action>>(
      {
        notifySwagger: notifyActions.notifySwagger,
        setSearch: searchActions.set,
        setPerPage: layoutActions.setPerPage
      },
      dispatch
    )
)(withGranted(List));

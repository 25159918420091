import * as APIServices from "./service-proxies";
import { apiUrl } from "./_constants/system";
import { getToken } from "./_utils/userSession";
import httpProxy from "./httpProxy";

const options = {
  token: getToken() || null
};

export const attributeServiceProxy = new APIServices.AttrbuteServiceProxy(
  options,
  apiUrl,
  httpProxy
);
export const categoryServiceProxy = new APIServices.CategoryServiceProxy(
  options,
  apiUrl,
  httpProxy
);
export const clientServiceProxy = new APIServices.ClientServiceProxy(
  options,
  apiUrl,
  httpProxy
);
export const catalogServiceProxy = new APIServices.CatalogServiceProxy(
  options,
  apiUrl,
  httpProxy
);
export const divisionServiceProxy = new APIServices.DivisionServiceProxy(
  options,
  apiUrl,
  httpProxy
);
export const locationServiceProxy = new APIServices.LocationServiceProxy(
  options,
  apiUrl,
  httpProxy
);
export const medicalSpecialityServiceProxy = new APIServices.MedicalSpecialityServiceProxy(
  options,
  apiUrl,
  httpProxy
);
export const permissionsServiceProxy = new APIServices.PermissionServiceProxy(
  options,
  apiUrl,
  httpProxy
);
export const productServiceProxy = new APIServices.ProductServiceProxy(
  options,
  apiUrl,
  httpProxy
);
export const productSectionServiceProxy = new APIServices.ProductSectionServiceProxy(
  options,
  apiUrl,
  httpProxy
);
export const purchaseHistoryServiceProxy = new APIServices.PurchaseHistoryServiceProxy(
  options,
  apiUrl,
  httpProxy
);
export const spendingTermServiceProxy = new APIServices.SpendingTermServiceProxy(
  options,
  apiUrl,
  httpProxy
);
export const roleServiceProxy = new APIServices.RoleServiceProxy(
  options,
  apiUrl,
  httpProxy
);
export const sessionServiceProxy = new APIServices.SessionServiceProxy(
  options,
  apiUrl,
  httpProxy
);
export const skuSegmentServiceProxy = new APIServices.SkuSegmentServiceProxy(
  options,
  apiUrl,
  httpProxy
);
export const stateServiceProxy = new APIServices.StateServiceProxy(
  options,
  apiUrl,
  httpProxy
);
export const unitOfMeasureServiceProxy = new APIServices.UnitOfMeasureServiceProxy(
  options,
  apiUrl,
  httpProxy
);
export const userServiceProxy = new APIServices.UserServiceProxy(
  options,
  apiUrl,
  httpProxy
);
export const userSupervisorsServiceProxy = new APIServices.SupervisorServiceProxy(
  options,
  apiUrl,
  httpProxy
);
export const ordersServiceProxy = new APIServices.OrderServiceProxy(
  options,
  apiUrl,
  httpProxy
);
export const pricingDetailsServiceProxy = new APIServices.PricingDetailServiceProxy(
  options,
  apiUrl,
  httpProxy
);
export const notesServiceProxy = new APIServices.NotesServiceProxy(
  options,
  apiUrl,
  httpProxy
);
export const invoicesServiceProxy = new APIServices.InvoiceServiceProxy(
  options,
  apiUrl,
  httpProxy
);
export const excelExportServiceProxy = new APIServices.ExcelExportServiceProxy(
  options,
  apiUrl,
  httpProxy
);
export const vendorServiceProxy = new APIServices.VendorServiceProxy(
  options,
  apiUrl,
  httpProxy
);
export const cartServiceProxy = new APIServices.CartServiceProxy(
  options,
  apiUrl,
  httpProxy
);
export const passwordServiceProxy = new APIServices.PasswordServiceProxy(
  options,
  apiUrl,
  httpProxy
);

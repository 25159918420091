import * as React from 'react';
import {RouteComponentProps} from 'react-router';
import RoutesMapper from '../Router/RoutesMapper';
import {IRouteEntry} from '../../_types/common';
import WithGranted, {IWithGrantedProps} from '../HOC/WithGranted';
import ListBuyer from './ListBuyer';
import View from './View';

class Products extends React.Component<IWithGrantedProps & RouteComponentProps<{}>> {
  public routsTopElements: IRouteEntry[];
  public routs: IRouteEntry[];

  constructor(props) {
    super(props);

    this.routs = [
      {
        exact: true,
        path: `/:categoryId?/:subCategoryId?/:serviceSlug(product)/:productId`,
        component: View,
      },
      {
        exact: true,
        path: `/:categoryId?/:subCategoryId?`,
        component: ListBuyer,
      }
    ];
  }

  render() {
    return <React.Fragment>
      <RoutesMapper routes={this.routs} addNotFound {...this.props} />
    </React.Fragment>;
  }
}


export default WithGranted(Products);
import React from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { closeRightSlider } from '../../_actions/layout';
import {
  XIcon,
} from '../../assets/icons';
import CartHeader from '../Cart/CartHeader';
import Cart from '../Cart';
import { IGlobalStore } from '../../_reducers/reducers';
import { Separator } from '../../components';

const SliderHeader = styled<ISliderContainerProps>((props) => {
  return <Separator borders="0 0 1" className={props.className}>
    {
      props.children
    }
  </Separator>;
})`
  width: 430px;
  min-width: 0;
  display: flex;
  flex-direction: column;
  height: 77px;
  flex-direction: row;
  flex-wrap: nowrap;
  flex: 0 0 77px;
`;

interface ISliderContainerProps {
  className?: string;
}

const SliderContainer = styled<ISliderContainerProps>((props) => {
  return <Separator className={props.className}>
    {
      props.children
    }
  </Separator>;
})`
  position: absolute;
  top: 0;
  left: 0;
  width: 430px;
  margin-top: 0px; 
  height: 100%;
  width: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  min-width: 0;
  height: 100%;
`;

const SliderHeaderContent = styled.div`
  flex: 1;
  flex-basis: 390px;
  min-width: 0;
  display: flex;
  flex-direction: column;
`;

const SliderHeaderClose = styled.div`
  flex: 0;
  flex-basis: 40px;
  align-self: flex-end;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  justify-items: center;
  min-width: 0;
  svg {
    cursor: pointer;
  }
`;

const ContentWrapper = styled.div`
  width: 430px;
  overflow: auto;
`;

interface IProps extends IConnectedProps, IConnectedActions {
  className: string;
}

const RightSideSlider = styled(class extends React.Component<IProps> {

  constructor(props) {
    super(props);
  }

  render() {
    const { className, sliderMode, isOpen } = this.props;
    return (
      <aside className={className}>
        <SliderContainer>
          <SliderHeader>
            <SliderHeaderContent>
              {sliderMode === 'CART' && <CartHeader />}
            </SliderHeaderContent>
            <SliderHeaderClose>
              <XIcon onClick={this.props.closeRightSlider} />
            </SliderHeaderClose>
          </SliderHeader>
          <ContentWrapper>
            {sliderMode === 'CART' && <Cart isCartOpen={isOpen} />}
          </ContentWrapper>
        </SliderContainer>
      </aside>
    );
  }
})`
  ${({ theme, ...props }) => {
    const width = props.isOpen ? '430px' : '0';
    const shadow = props.isOpen ? ' -2px 2px 5px 0 rgba(0, 0, 0, 0.07)' : '0 0 0 0 rgba(0,0,0,0)';
    return `
      flex-direction: column;
      position: relative;
      flex: 0 0;
      flex-basis: ${width};
      transition: all .3s ease-in-out;
      overflow: auto;
      box-shadow: ${shadow};
      min-width: 0;
    `;
  }}
`;

interface IConnectedProps {
  isOpen: boolean;
  sliderMode: string;
}

interface IConnectedActions {
  closeRightSlider: typeof closeRightSlider;
}

export default connect(
  createStructuredSelector<IGlobalStore, IConnectedProps>({
    isOpen: state => state.layout.rightSliderOpen,
    sliderMode: state => state.layout.rightSliderMode,
  }), {
    closeRightSlider
  })(RightSideSlider);
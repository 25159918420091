import * as React from "react";
import { FormikActions, FormikHandlers, FormikState } from "formik";
import PropTypes from "prop-types";
import { arrayMove } from "react-sortable-hoc";
import Lightbox from "react-images";
import styled from "styled-components";

import { history } from "../../store";
import { apiUrl } from "../../_constants/system";
import {
  Field,
  Select,
  Chips,
  Number,
  List,
  Textarea,
  Tree,
  DropField,
  FormGrid as Grid,
  Branch,
  Toggle,
  RadioButton,
  RadioGroup,
  Separator
} from "../../components/index";
import RelatedProduct from "./Fields/RelatedProduct";
import Attribute from "./Fields/Attribute";

import * as skuApi from "../../_api/sku";
import * as unitOfMeasureApi from "../../_api/unitOfMeasure";
import * as miscellaneousApi from "../../_api/miscellaneous";
import * as attributeApi from "../../_api/attribute";

import { updateBrunch, fillTree } from "../../_utils/data";
import { EditProductType } from "./Edit";
import { SearchOutputDto } from "../../service-proxies";
import { getProducSections } from "../../_api/productSection";
import { skuSegmentServiceProxy } from "../../servicePool";
import moment from "moment";
import DatePicker from "../../components/DatePicker";

const PositionToggle = styled.div`
  padding-top: 14px;
  padding-bottom: 14px;
  margin-left: 0;
`;

const PriceFieldLabel = styled<{ isInvalid: boolean }, "div">("div")`
  ${({ theme }) => theme.global}
  color: ${({ isInvalid, theme }) =>
    isInvalid ? theme.default : theme.primary};
  display: block;
  font-size: 14px;
  font-weight: normal;
  margin-bottom: 7px;
`;

interface IFormDetailsProps
  extends FormikActions<EditProductType>,
    FormikState<EditProductType>,
    FormikHandlers {
  onError: (error: any, msg: string) => void;
  isLoading: boolean;
}

interface IFormState {
  dimension: {
    items?: SearchOutputDto[];
    status: string;
  };
  productSections: {
    items: SearchOutputDto[];
    status: string;
  };
  vendorPriceDateFocused?: boolean;
}

class FormDetails extends React.Component<IFormDetailsProps, IFormState> {
  static propTypes = {
    values: PropTypes.object,
    errors: PropTypes.object,
    touched: PropTypes.object,
    handleChange: PropTypes.func,
    handleBlur: PropTypes.func,
    handleSubmit: PropTypes.func,
    isSubmitting: PropTypes.bool,
    setFieldValue: PropTypes.func,
    setFieldError: PropTypes.func,
    isSaving: PropTypes.bool,

    categories: PropTypes.array,
    onError: PropTypes.func.isRequired,
    isLoading: PropTypes.bool,
    isNew: PropTypes.bool
  };

  constructor(props) {
    super(props);

    this.handleNameSegAdd = this.handleNameSegAdd.bind(this);
    this.handleNameSegDelete = this.handleNameSegDelete.bind(this);
    this.handleNameSegEdit = this.handleNameSegEdit.bind(this);

    this.handleCreateSKUPrefix = this.handleCreateSKUPrefix.bind(this);
    this.handleDeleteSKUSuffix = this.handleDeleteSKUSuffix.bind(this);
    this.handleSKUPrefixSelect = this.handleSKUPrefixSelect.bind(this);
    this.handleSKUSuffixSelect = this.handleSKUSuffixSelect.bind(this);

    this.handleAddAttribute = this.handleAddAttribute.bind(this);
    this.handleAttributeNameChange = this.handleAttributeNameChange.bind(this);
    this.handleAttributeNameCreate = this.handleAttributeNameCreate.bind(this);
    this.handleAttributeValueChange = this.handleAttributeValueChange.bind(
      this
    );
    this.handleAttributeValueCreate = this.handleAttributeValueCreate.bind(
      this
    );
    this.handleAttributeIncludeInNameChange = this.handleAttributeIncludeInNameChange.bind(
      this
    );
    this.handleAttributeDelete = this.handleAttributeDelete.bind(this);
    this.handleAttributeSort = this.handleAttributeSort.bind(this);

    this.handleRelatedProductChange = this.handleRelatedProductChange.bind(
      this
    );
    this.handleRelatedProductDelete = this.handleRelatedProductDelete.bind(
      this
    );
    this.handleRelatedProductAdd = this.handleRelatedProductAdd.bind(this);

    this.handleCategoryTrigger = this.handleCategoryTrigger.bind(this);
    this.handleCategorySelect = this.handleCategorySelect.bind(this);

    this.handleImageDrop = this.handleImageDrop.bind(this);
    this.handleImageDelete = this.handleImageDelete.bind(this);
    this.handleImageSort = this.handleImageSort.bind(this);

    this.handleVendorChange = this.handleVendorChange.bind(this);
    // this.handleCreateVendor = this.handleCreateVendor.bind(this);
    // this.handleVendorDelete = this.handleVendorDelete.bind(this);

    // lightbox
    this.handleNextImage = this.handleNextImage.bind(this);
    this.handlePrevImage = this.handlePrevImage.bind(this);
    this.handleCloseLightbox = this.handleCloseLightbox.bind(this);
    this.handleOpenLightBox = this.handleOpenLightBox.bind(this);

    this.handleRadioClick = this.handleRadioClick.bind(this);
    this.handlePackageTypeChange = this.handlePackageTypeChange.bind(this);
    this.handleCreatePackageType = this.handleCreatePackageType.bind(this);
    this.handleDeletePackageType = this.handleDeletePackageType.bind(this);
    this.handleQuantityPerUnitOfMeasure = this.handleQuantityPerUnitOfMeasure.bind(
      this
    );

    this.handleVendorPriceDateChange = this.handleVendorPriceDateChange.bind(
      this
    );
    this.handleVendorPriceDateFocus = this.handleVendorPriceDateFocus.bind(
      this
    );
    this.handleVendorPriceDateTouched = this.handleVendorPriceDateTouched.bind(
      this
    );

    this.handleItemNumberChange = this.handleItemNumberChange.bind(this);

    this.state = {
      dimension: {
        items: [],
        status: ""
      },
      productSections: {
        items: [],
        status: ""
      }
    };
  }

  componentDidMount() {
    this.loadDimensions();
    this.loadProductSections();
  }

  // name
  handleNameSegAdd(names) {
    this.props.setFieldValue("nameSegments", names, false);
    this.props.setFieldTouched("nameSegments", true, true);
  }

  handleNameSegDelete(value) {
    const reducedValues = this.props.values.nameSegments.filter(nameSegment => {
      return nameSegment.segment !== value.segment;
    });

    this.props.setFieldValue("nameSegments", reducedValues, false);
    this.props.setFieldTouched("nameSegments", true, true);
  }

  handleNameSegEdit(oldValue, value) {
    const updatedValues = this.props.values.nameSegments.map(nameSegment => {
      if (nameSegment.segment === oldValue) {
        const newNameSegment: { label?: string; segment?: string } = {};
        newNameSegment.segment = value;
        return newNameSegment;
      }
      return nameSegment;
    });

    const uniqueValues = updatedValues.filter((updatedValue, index, self) => {
      return (
        self.findIndex(item => item.segment === updatedValue.segment) === index
      );
    });

    const clearedValues = uniqueValues.filter(uniqueValue => {
      return uniqueValue.segment.trim() !== "";
    });

    this.props.setFieldValue("nameSegments", clearedValues, false);
    this.props.setFieldTouched("nameSegments", true, true);
  }

  // SKU
  loadSKUPrefix(value, callback) {
    skuApi
      .getAllPrefixes(value)
      .then(({ items }) => {
        callback(null, {
          options: items,
          complete: true
        });
      })
      .catch(e => {});
  }

  handleCreateSKUPrefix(option) {
    if (option.displayValue.length <= 50) {
      skuApi
        .createPrefix(option.displayValue)
        .then(data => {
          const optionWithId = option;
          optionWithId.id = data.id;
          this.props.setFieldValue("skuPrefix", optionWithId, false);
          this.props.setFieldTouched("skuPrefix", true, true);
        })
        .catch(e => this.props.onError(e, "error"));
    }
  }

  handleDeleteSKUSuffix(option) {
    return skuSegmentServiceProxy
      .deleteSkuPrefix(option.id)
      .then(data => {
        this.props.setFieldValue("skuPrefix", null, false);
        this.props.setFieldTouched("skuPrefix", true, true);
      })
      .catch(e => this.props.onError(e, "error"));
  }

  handleSKUPrefixSelect(option) {
    this.props.setFieldValue("skuPrefix", option, false);
    this.props.setFieldTouched("skuPrefix", true, true);
  }

  loadSKUSuffix(value, callback) {
    skuApi
      .getAllSuffixes(value)
      .then(({ items }) => {
        callback(null, {
          options: items,
          complete: true
        });
      })
      .catch(e => {});
  }

  handleSKUSuffixSelect(option) {
    this.props.setFieldValue("skuSuffix", option, false);
    this.props.setFieldTouched("skuSuffix", true, true);
  }

  // Dimensions and Package Types
  loadDimensions() {
    this.setState({
      dimension: {
        status: "loading"
      }
    });
    unitOfMeasureApi
      .searchDimensions()
      .then(({ items }) => {
        this.setState({
          dimension: {
            items,
            status: "loaded"
          }
        });
      })
      .catch(e => {
        this.setState({
          dimension: {
            status: "error"
          }
        });
      });
  }

  loadProductSections() {
    this.setState({
      productSections: {
        items: [],
        status: "loading"
      }
    });
    getProducSections()
      .then(({ items }) => {
        this.setState({
          productSections: {
            items,
            status: "loaded"
          }
        });
      })
      .catch(() => {
        this.setState({
          productSections: {
            items: [],
            status: "error"
          }
        });
      });
  }

  loadPackageTypes(value, callback) {
    unitOfMeasureApi
      .searchPackageTypes(value)
      .then(({ items }) => {
        callback(null, {
          options: items,
          complete: true
        });
      })
      .catch(e => {});
  }

  loadVendors(value, callback) {
    miscellaneousApi
      .searchVendors(value)
      .then(({ items }) => {
        callback(null, {
          options: items,
          complete: true
        });
      })
      .catch(e => {});
  }

  // attributes
  handleAddAttribute() {
    const newAttributes = [...this.props.values.attributes];
    newAttributes.push({
      includeIntoName: false
    });
    this.props.setFieldValue("attributes", newAttributes);
  }

  handleAttributeNameChange(option, index) {
    const newAttributes = [...this.props.values.attributes];
    newAttributes[index].attribute = option;
    this.props.setFieldValue("attributes", newAttributes);
  }

  handleAttributeNameCreate(option, index) {
    const attributes = [...this.props.values.attributes];
    attributeApi.create(option.displayValue).then(data => {
      attributes[index].attribute = option;
      attributes[index].attribute.id = data.id;
      this.props.setFieldValue("attributes", attributes);
    });
  }

  handleAttributeValueChange(option, index) {
    const newAttributes = [...this.props.values.attributes];
    if (option) {
      newAttributes[index].attributeValue = option;
      newAttributes[index].attributeValueId = option.id;
    } else {
      newAttributes[index].attributeValue = null;
      newAttributes[index].attributeValueId = null;
    }
    this.props.setFieldValue("attributes", newAttributes);
  }

  handleAttributeValueCreate(option, index, nameId) {
    const attributes = [...this.props.values.attributes];
    attributeApi.createValue(option.displayValue, nameId).then(data => {
      attributes[index].attributeValue = option;
      attributes[index].attributeValue.id = data.id;
      this.props.setFieldValue("attributes", attributes);
    });
  }

  handleAttributeIncludeInNameChange(flag, index) {
    const newAttributes = [...this.props.values.attributes];
    newAttributes[index].include = flag;
    this.props.setFieldValue("attributes", newAttributes);
  }

  handleAttributeDelete(index) {
    const newAttributes = [...this.props.values.attributes];
    newAttributes.splice(index, 1);
    this.props.setFieldValue("attributes", newAttributes);
  }

  handleAttributeSort({ oldIndex, newIndex }) {
    const newAttributes = arrayMove(
      this.props.values.attributes,
      oldIndex,
      newIndex
    );
    this.props.setFieldValue("attributes", newAttributes);
  }

  handleQuantityPerUnitOfMeasure(value) {
    this.props.setFieldValue("quantityPerUnitOfMeasure", value, false);
    this.props.setFieldTouched("quantityPerUnitOfMeasure", true, true);
  }

  handlePackageTypeChange(value) {
    this.props.setFieldValue("packageType", value, false);
    this.props.setFieldTouched("packageType", true, true);
  }

  handleCreatePackageType(option) {
    unitOfMeasureApi
      .createPackageType(option.displayValue)
      .then(data => {
        const optionWithId = option;
        optionWithId.id = data.id;
        this.props.setFieldValue("packageType", optionWithId, false);
        this.props.setFieldTouched("packageType", true, true);
      })
      .catch(e => this.props.onError(e, "error"));
  }

  handleDeletePackageType(option) {
    return unitOfMeasureApi
      .deletePackageType(option.id)
      .then(data => {
        this.props.setFieldValue("packageType", null, false);
        this.props.setFieldTouched("packageType", true, true);
      })
      .catch(e => this.props.onError(e, "error"));
  }

  // related products
  handleRelatedProductChange(option, index) {
    const newRelatedProducts = [...this.props.values.similarProducts];
    newRelatedProducts[index] = option;
    newRelatedProducts[index].readOnly = true;
    const uniqueRelatedProducts = newRelatedProducts.filter(
      (value, relatedIndex, self) => {
        return self.findIndex(item => item.id === value.id) === relatedIndex;
      }
    );

    this.props.setFieldValue("similarProducts", uniqueRelatedProducts);
  }

  handleRelatedProductAdd() {
    const newRelatedProducts = [...this.props.values.similarProducts];
    // @ts-expect-error remove ts-ignore
    newRelatedProducts.push({});
    this.props.setFieldValue("similarProducts", newRelatedProducts);
  }

  handleRelatedProductDelete(index) {
    const newRelatedProducts = [...this.props.values.similarProducts];
    newRelatedProducts.splice(index, 1);
    this.props.setFieldValue("similarProducts", newRelatedProducts);
  }

  handleCategoryTrigger(e, path) {
    const newcategories = updateBrunch(
      [...this.props.values.categories],
      path,
      object => (object.expanded = !object.expanded),
      "childCategories"
    );

    this.props.setFieldValue("categories", newcategories);
  }

  handleCategorySelect(value, path, node) {
    const activeIds = [];
    // @ts-expect-error remove ts-ignore
    const categoryData = fillTree(
      [...this.props.values.categories],
      activeIds,
      node.id,
      value,
      "id",
      "childCategories"
    );

    const newCategories = categoryData.nodes;
    const categoryId = categoryData.activeIds[0]
      ? categoryData.activeIds[0]
      : null;

    this.props.setFieldValue("categoryId", value ? categoryId : null, false);
    this.props.setFieldValue("categories", newCategories, false);
    this.props.setFieldTouched("categoryId", true, true);
  }

  // image drop field
  handleImageDrop(acceptedImages) {
    const dropedImages = acceptedImages.map(picture => {
      picture.src = picture.preview || `${apiUrl}${picture.pictureUrl}`;
      return picture;
    });

    const newImages = [...this.props.values.pictures, ...dropedImages];

    this.props.setFieldValue("pictures", newImages);
  }

  handleImageDelete(index) {
    const newImages = [...this.props.values.pictures];
    newImages.splice(index, 1);
    this.props.setFieldValue("pictures", newImages);
  }

  handleImageSort({ oldIndex, newIndex }) {
    const newImages = arrayMove(this.props.values.pictures, oldIndex, newIndex);
    this.props.setFieldValue("pictures", newImages);
  }

  handleVendorChange(value) {
    this.props.setFieldValue("vendor", value, false);
    this.props.setFieldTouched("vendor", true, true);
  }

  // handleCreateVendor(option) {
  //   miscellaneousApi.createVendor(option.displayValue)
  //     .then(data => {
  //       const optionWithId = option;
  //       optionWithId.id = data.id;
  //       this.props.setFieldValue('vendor', optionWithId, false);
  //       this.props.setFieldTouched('vendor', true, true);
  //     })
  //     .catch(e => this.props.onError(e, 'error'));
  // }

  // handleVendorDelete(option) {
  //   return miscellaneousApi.deleteVendor(option.id)
  //     .then(data => {
  //       this.props.setFieldValue('vendor', null, false);
  //       this.props.setFieldTouched('vendor', true, true);
  //     })
  //     .catch(e => this.props.onError(e, 'error'));
  // }

  // image slider
  handlePrevImage() {
    this.props.setFieldValue("currentImage", --this.props.values.currentImage);
  }

  handleNextImage() {
    this.props.setFieldValue("currentImage", ++this.props.values.currentImage);
  }

  handleCloseLightbox() {
    this.props.setFieldValue("lightboxIsOpen", false);
  }

  handleOpenLightBox(index) {
    this.props.setFieldValue("currentImage", index);
    this.props.setFieldValue("lightboxIsOpen", true);
  }

  handleRadioClick(value, fieldName) {
    this.props.setFieldValue(fieldName, value, false);
    this.props.setFieldTouched(fieldName, true, true);
  }

  handleVendorPriceDateChange(value) {
    const stringDate = moment(value, "DD.MM.YYYY", true).isValid()
      ? moment(value, "DD.MM.YYYY", true)
      : null;
    this.props.setFieldValue("vendorPriceDate", stringDate);
  }

  handleVendorPriceDateFocus(value) {
    this.setState({ vendorPriceDateFocused: value.focused });
  }

  handleVendorPriceDateTouched() {
    this.props.setFieldTouched("vendorPriceDate", true);
  }

  handleItemNumberChange(event) {
    const strValue = event.target.value
      .toString()
      .replace(/[^a-zA-Z0-9]+/g, "")
      .slice(0, 48);
    this.props.setFieldValue("itemNumber", strValue, false);
    this.props.setFieldTouched("itemNumber", true);
  }

  handleCancel() {
    history.goBack();
  }

  render() {
    const { values, errors, touched, handleChange, handleBlur } = this.props;

    return (
      <Grid.Body>
        <Grid.Row>
          <Grid.Cell>
            <DropField
              label="Product Image"
              value={values.pictures}
              onDrop={this.handleImageDrop}
              onDelete={this.handleImageDelete}
              onSort={this.handleImageSort}
              onClick={this.handleOpenLightBox}
              urlPrefix={apiUrl}
            />
          </Grid.Cell>
          <Grid.Cell>
            <Chips
              required
              name="name"
              label="Product Name"
              placeholder="Type here..."
              value={values.nameSegments}
              onChange={this.handleNameSegAdd}
              onDelete={this.handleNameSegDelete}
              onEdit={this.handleNameSegEdit}
              valueKey="segment"
              prohibitKeys={["space"]}
              splitNewOption
              maxChipLength={126}
              disabled={this.props.isLoading}
              error={touched.nameSegments && errors.nameSegments}
            />
            <Textarea
              label={"Description"}
              name={"description"}
              placeholder="Type here..."
              value={values.description}
              onChange={handleChange}
              disabled={this.props.isLoading}
              error={touched.description && errors.description}
            />
          </Grid.Cell>
        </Grid.Row>

        <Grid.Row>
          <Grid.Cell size="75%">
            <Grid.Row>
              <Grid.Cell>
                <Select
                  creatable
                  autoload={false}
                  loadOnFocus
                  deletable
                  onOptionDelete={this.handleDeleteSKUSuffix}
                  required
                  name="SKUPrefix"
                  label="SKU prefix"
                  labelKey="displayValue"
                  valueKey="id"
                  value={values.skuPrefix}
                  onChange={this.handleSKUPrefixSelect}
                  onNewOptionClick={this.handleCreateSKUPrefix}
                  loadOptions={this.loadSKUPrefix}
                  maxOptionLength={50}
                  prohibitKeys={["space"]}
                  disabled={this.props.isLoading}
                  error={touched.skuPrefix && (errors.skuPrefix as string)}
                />
              </Grid.Cell>
              <Grid.Cell>
                <Field
                  type="text"
                  name="middleSku"
                  label="SKU"
                  required
                  placeholder="SKU..."
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.middleSku}
                  prohibitKeys={["space"]}
                  disabled={this.props.isLoading}
                  error={touched.middleSku && errors.middleSku}
                />
              </Grid.Cell>
              <Grid.Cell>
                <Select
                  async
                  required
                  name="SKUSuffix"
                  label="SKU suffix"
                  labelKey="displayValue"
                  valueKey="id"
                  onChange={this.handleSKUSuffixSelect}
                  value={values.skuSuffix}
                  loadOptions={this.loadSKUSuffix}
                  prohibitKeys={["space"]}
                  disabled={this.props.isLoading}
                  error={touched.skuSuffix && (errors.skuSuffix as string)}
                />
              </Grid.Cell>
            </Grid.Row>
          </Grid.Cell>
          {/*<Grid.Cell size="25%">*/}
          {/*  <Field*/}
          {/*    type="text"*/}
          {/*    name="glCategoryNumber"*/}
          {/*    label="GL Code" //GL Category changed to to GL code chages made in label and placeholder*/}
          {/*    placeholder="GL Code..."*/}
          {/*    onChange={handleChange}*/}
          {/*    onBlur={handleBlur}*/}
          {/*    value={values.glCategoryNumber}*/}
          {/*    disabled={this.props.isLoading}*/}
          {/*    error={touched.glCategoryNumber && errors.glCategoryNumber}*/}
          {/*  />*/}
          {/*</Grid.Cell>*/}
        </Grid.Row>

        <Grid.Row>
          <Grid.Cell>
            <Grid.Row>
              <Grid.Cell>
                <Number
                  positiveOnly
                  name="length"
                  label="Length"
                  placeholder="Length"
                  onChange={value => this.props.setFieldValue("length", value)}
                  onBlur={handleBlur}
                  value={values.length}
                  step={0.01}
                  disabled={this.props.isLoading}
                  error={touched.length && errors.length}
                />
              </Grid.Cell>
              <Grid.Cell>
                <Number
                  positiveOnly
                  name="width"
                  label="Width"
                  placeholder="Width"
                  onChange={value => this.props.setFieldValue("width", value)}
                  onBlur={handleBlur}
                  value={values.width}
                  step={0.01}
                  disabled={this.props.isLoading}
                  error={touched.width && errors.width}
                />
              </Grid.Cell>
              <Grid.Cell>
                <Number
                  positiveOnly
                  name="height"
                  label="Height"
                  placeholder="Height"
                  onChange={value => this.props.setFieldValue("height", value)}
                  onBlur={handleBlur}
                  value={values.height}
                  step={0.01}
                  disabled={this.props.isLoading}
                  error={touched.height && errors.height}
                />
              </Grid.Cell>
              <Grid.Cell>
                <Select
                  name="dimension"
                  label="Measures"
                  onChange={value =>
                    this.props.setFieldValue("dimension", value)
                  }
                  value={values.dimension}
                  labelKey="displayValue"
                  valueKey="id"
                  disabled={this.props.isLoading}
                  options={this.state.dimension.items}
                  isLoading={this.state.dimension.status === "loading"}
                  error={!!touched.dimension && (errors.dimension as string)}
                />
              </Grid.Cell>
            </Grid.Row>
          </Grid.Cell>
        </Grid.Row>

        <Grid.Row>
          <Grid.Cell size="75%">
            <Grid.Row>
              <Grid.Cell>
                <Number
                  positiveOnly
                  name="quantity"
                  label="Quantity"
                  placeholder="Quantity"
                  onChange={this.handleQuantityPerUnitOfMeasure}
                  onBlur={handleBlur}
                  value={values.quantityPerUnitOfMeasure}
                  step={0.01}
                  suffix="per"
                  integer
                  disabled={this.props.isLoading}
                  error={
                    touched.quantityPerUnitOfMeasure &&
                    errors.quantityPerUnitOfMeasure
                  }
                />
              </Grid.Cell>
              <Grid.Cell>
                <Select
                  async
                  creatable
                  deletable
                  loadOnFocus
                  autoload={false}
                  onOptionDelete={this.handleDeletePackageType}
                  loadOptions={this.loadPackageTypes}
                  name="packageType"
                  label="Package Type"
                  onChange={this.handlePackageTypeChange}
                  onNewOptionClick={this.handleCreatePackageType}
                  value={values.packageType}
                  labelKey="displayValue"
                  valueKey="id"
                  required
                  disabled={this.props.isLoading}
                  error={touched.packageType && (errors.packageType as string)}
                />
              </Grid.Cell>
            </Grid.Row>
          </Grid.Cell>
          <Grid.Cell size="25" />
        </Grid.Row>

        <Grid.Row>
          <Grid.Cell size="75%">
            <Grid.Row>
              <Grid.Cell>
                <Separator borders="1" />
                <br />
              </Grid.Cell>
            </Grid.Row>
          </Grid.Cell>
        </Grid.Row>

        <Grid.Row>
          <Grid.Cell size="75%">
            <Grid.Row>
              <Grid.Cell>
                <Field
                  type="text"
                  required
                  name="deliveryTime"
                  label="Delivery Time"
                  placeholder="Delivery Time"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.deliveryTime}
                  disabled={this.props.isLoading}
                  error={touched.deliveryTime && errors.deliveryTime}
                />
              </Grid.Cell>
              <Grid.Cell>
                <PriceFieldLabel
                  isInvalid={!!(touched.hazmatCharge && errors.hazmatCharge)}
                >
                  Hazmat charge *
                </PriceFieldLabel>
                <Field
                  label="$"
                  compact
                  type="number"
                  name="hazmatCharge"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.hazmatCharge}
                  disabled={this.props.isLoading}
                  error={touched.hazmatCharge && errors.hazmatCharge}
                />
              </Grid.Cell>
            </Grid.Row>
          </Grid.Cell>
        </Grid.Row>

        <Grid.Row>
          <Grid.Cell size="75%">
            <Grid.Row>
              <Grid.Cell>
                <Field
                  type="text"
                  required
                  name="manufacturer"
                  label="Manufacturer"
                  placeholder="Manufacturer"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.manufacturer}
                  disabled={this.props.isLoading}
                  error={touched.manufacturer && errors.manufacturer}
                />
              </Grid.Cell>
              <Grid.Cell>
                <Field
                  type="text"
                  required
                  name="manufacturerNumber"
                  label="Manufacturer #"
                  placeholder="Manufacturer #"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.manufacturerNumber}
                  disabled={this.props.isLoading}
                  error={
                    touched.manufacturerNumber && errors.manufacturerNumber
                  }
                />
              </Grid.Cell>
            </Grid.Row>
          </Grid.Cell>
        </Grid.Row>

        <Grid.Row>
          <Grid.Cell size="75%">
            <Grid.Row>
              <Grid.Cell size="43%">
                <Toggle
                  name="isHazardousMaterial"
                  id="isHazardousMaterial"
                  label="Hazardous Material: "
                  value={values.isHazardousMaterial}
                  disabled={this.props.isLoading}
                  onChange={handleChange}
                />
              </Grid.Cell>
              <Grid.Cell size="57%">
                <Toggle
                  name="isControlledSubstance"
                  id="isControlledSubstance"
                  label="Controlled Substance: "
                  value={values.isControlledSubstance}
                  disabled={this.props.isLoading}
                  onChange={handleChange}
                />
              </Grid.Cell>
            </Grid.Row>
          </Grid.Cell>
          <Grid.Cell size="25" />
        </Grid.Row>

        <Grid.Row>
          <Grid.Cell size="75%">
            <Grid.Row>
              <Grid.Cell size="42%">
                <PositionToggle>
                  <RadioGroup
                    label="Sterility:"
                    required
                    errors={touched.isSterile && errors.isSterile}
                  >
                    <RadioButton
                      name="isSterile"
                      value={values.isSterile}
                      activeValue
                      label="Sterile"
                      onChange={this.handleRadioClick}
                    />
                    <RadioButton
                      name="isSterile"
                      value={values.isSterile}
                      activeValue={false}
                      label="Not Sterile"
                      onChange={this.handleRadioClick}
                    />
                  </RadioGroup>
                </PositionToggle>
              </Grid.Cell>
              <Grid.Cell size="58%">
                <PositionToggle>
                  <RadioGroup
                    label="Latex Status:"
                    required
                    errors={touched.latexStatus && errors.latexStatus}
                  >
                    <RadioButton
                      name="latexStatus"
                      value={values.latexStatus}
                      onChange={this.handleRadioClick}
                      label="Latex"
                      activeValue={1}
                    />
                    <RadioButton
                      name="latexStatus"
                      value={values.latexStatus}
                      onChange={this.handleRadioClick}
                      label="Latex-Free"
                      activeValue={2}
                    />
                    <RadioButton
                      name="latexStatus"
                      value={values.latexStatus}
                      onChange={this.handleRadioClick}
                      label="N/A"
                      activeValue={3}
                    />
                  </RadioGroup>
                </PositionToggle>
              </Grid.Cell>
            </Grid.Row>
          </Grid.Cell>
        </Grid.Row>

        <Grid.Row>
          <Grid.Cell size="75%">
            <Grid.Row>
              <Grid.Cell size="43%">
                <Toggle
                  name="isForm222"
                  id="isForm222"
                  label="Form 222 required: "
                  value={values.isForm222}
                  disabled={this.props.isLoading}
                  onChange={handleChange}
                />
              </Grid.Cell>
              <Grid.Cell size="57%">
                <Toggle
                  name="isReturnable"
                  id="isReturnable"
                  label="Returnable: "
                  value={values.isReturnable}
                  disabled={this.props.isLoading}
                  onChange={handleChange}
                />
              </Grid.Cell>
            </Grid.Row>
          </Grid.Cell>
          <Grid.Cell size="25" />
        </Grid.Row>

        <Grid.Row>
          <Grid.Cell size="75%">
            <Grid.Row>
              <Grid.Cell>
                <br />
                <Separator borders="1" />
                <br />
              </Grid.Cell>
            </Grid.Row>
          </Grid.Cell>
        </Grid.Row>

        <Grid.Row>
          <Grid.Cell size="75%">
            <Grid.Row>
              <Grid.Cell>
                <Select
                  async
                  // creatable
                  // deletable
                  loadOnFocus
                  maxOptionLength={80}
                  autoload={false}
                  // onOptionDelete={this.handleVendorDelete}
                  loadOptions={this.loadVendors}
                  name="vendor"
                  label="Source/Vendor"
                  onChange={this.handleVendorChange}
                  // onNewOptionClick={this.handleCreateVendor}
                  value={values.vendor}
                  labelKey="displayValue"
                  valueKey="id"
                  required
                  disabled={this.props.isLoading}
                  error={touched.vendor && (errors.vendor as string)}
                />
              </Grid.Cell>
              <Grid.Cell>
                <Field
                  required
                  type="text"
                  name="itemNumber"
                  label="Item Number"
                  placeholder="Item Number"
                  onChange={this.handleItemNumberChange}
                  onBlur={handleBlur}
                  value={values.itemNumber}
                  disabled={this.props.isLoading}
                  error={touched.itemNumber && errors.itemNumber}
                />
              </Grid.Cell>
            </Grid.Row>
          </Grid.Cell>
        </Grid.Row>

        <Grid.Row>
          <Grid.Cell size="75%">
            <Grid.Row>
              <Grid.Cell size="50%">
                <PriceFieldLabel
                  isInvalid={!!(touched.vendorPrice && errors.vendorPrice)}
                >
                  Vendor price *
                </PriceFieldLabel>
                <Field
                  label="$"
                  compact
                  type="number"
                  name="vendorPrice"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.vendorPrice}
                  disabled={this.props.isLoading}
                  error={touched.vendorPrice && errors.vendorPrice}
                />
              </Grid.Cell>
              <Grid.Cell size="25%">
                <DatePicker
                  id="vendorPriceDate"
                  label="Vendor Price Date"
                  required
                  date={
                    values.vendorPriceDate && moment(values.vendorPriceDate)
                  }
                  focused={this.state.vendorPriceDateFocused}
                  isOutsideRange={() => false}
                  onDateChange={this.handleVendorPriceDateChange}
                  onFocusChange={this.handleVendorPriceDateFocus}
                  onClose={this.handleVendorPriceDateTouched}
                  disabled={this.props.isLoading}
                  error={
                    touched.vendorPriceDate &&
                    ((errors.vendorPriceDate as any) as string)
                  }
                />
              </Grid.Cell>
              <Grid.Cell size="25%">
                <PositionToggle>
                  <PositionToggle>
                    <Toggle
                      name="isTaxable"
                      id="isTaxable"
                      label="Taxable: "
                      value={values.isTaxable}
                      disabled={this.props.isLoading}
                      onChange={handleChange}
                    />
                  </PositionToggle>
                </PositionToggle>
              </Grid.Cell>
            </Grid.Row>
          </Grid.Cell>
        </Grid.Row>

        <Grid.Row>
          <Grid.Cell size="75%">
            <Grid.Row>
              <Grid.Cell size="75%">
                <RadioGroup
                  label="Stock Status:"
                  required
                  errors={touched.stockStatus && errors.stockStatus}
                >
                  <RadioButton
                    name="stockStatus"
                    value={values.stockStatus}
                    onChange={this.handleRadioClick}
                    label="Routine"
                    activeValue={1}
                  />
                  <RadioButton
                    name="stockStatus"
                    value={values.stockStatus}
                    onChange={this.handleRadioClick}
                    label="Non-Stock"
                    activeValue={2}
                  />
                  <RadioButton
                    name="stockStatus"
                    value={values.stockStatus}
                    onChange={this.handleRadioClick}
                    label="Drop-Ship"
                    activeValue={3}
                  />
                </RadioGroup>
              </Grid.Cell>
              <Grid.Cell size="25%" />
            </Grid.Row>
          </Grid.Cell>
        </Grid.Row>

        {/* Contract Status Section */}
        <div style={{ paddingTop: "14px" }}>
          <Grid.Row>
            <Grid.Cell size="100%">
              <Grid.Row>
                <Grid.Cell size="100%">
                  <RadioGroup label="Contract Status:" errors={null}>
                    <RadioButton
                      name="contractStatus"
                      value={values.contractStatus}
                      onChange={this.handleRadioClick}
                      label="GPO Contr"
                      activeValue={1}
                    />
                    <RadioButton
                      name="contractStatus"
                      value={values.contractStatus}
                      onChange={this.handleRadioClick}
                      label="GPO Non-Contr"
                      activeValue={2}
                    />
                    <RadioButton
                      name="contractStatus"
                      value={values.contractStatus}
                      onChange={this.handleRadioClick}
                      label="Pass-Thru"
                      activeValue={3}
                    />
                    <RadioButton
                      name="contractStatus"
                      value={values.contractStatus}
                      onChange={this.handleRadioClick}
                      label="Direct Bill"
                      activeValue={4}
                    />
                  </RadioGroup>
                </Grid.Cell>
                {/* <Grid.Cell size="2%" /> */}
              </Grid.Row>
            </Grid.Cell>
          </Grid.Row>
        </div>

        <Grid.Row>
          <Grid.Cell size="100%">
            <Grid.Row>
              <Grid.Cell size="100%">
                <PositionToggle>
                  <RadioGroup
                    label="Designation:" // Section changed to Designation - only change made was label
                    required
                    isLoading={this.state.productSections.status === "loading"}
                    errors={touched.productSectionId && errors.productSectionId}
                  >
                    {this.state.productSections.items.map(item => (
                      <RadioButton
                        key={`product-section-${item.id}`}
                        name="productSectionId"
                        value={values.productSectionId}
                        activeValue={item.id}
                        label={item.displayValue}
                        onChange={this.handleRadioClick}
                      />
                    ))}
                  </RadioGroup>
                </PositionToggle>
              </Grid.Cell>
              <Grid.Cell size="50%" />
            </Grid.Row>
          </Grid.Cell>
        </Grid.Row>

        <Grid.Row>
          <Grid.Cell size="75%">
            <Grid.Row>
              <Grid.Cell>
                <Separator borders="1" />
                <br />
              </Grid.Cell>
            </Grid.Row>
          </Grid.Cell>
        </Grid.Row>

        <Grid.Row>
          <Grid.Cell>
            <List
              ordered
              increasable
              sortable
              deletable
              label="Product Attributes"
              value={values.attributes}
              renderComponent={(value, index) => (
                <Attribute
                  value={value}
                  index={index}
                  onNameChange={this.handleAttributeNameChange}
                  onNameCreate={this.handleAttributeNameCreate}
                  onValueChange={this.handleAttributeValueChange}
                  onValueCreate={this.handleAttributeValueCreate}
                  onIncludeInNameChange={
                    this.handleAttributeIncludeInNameChange
                  }
                />
              )}
              onItemDelete={this.handleAttributeDelete}
              onAddItem={this.handleAddAttribute}
              onSort={this.handleAttributeSort}
              disabled={this.props.isLoading}
              isLoading={values.attributesStatus === "loading"}
            />
          </Grid.Cell>
        </Grid.Row>

        <Grid.Row>
          <Grid.Cell>
            <List
              ordered
              increasable
              deletable
              label="Related Products"
              value={values.similarProducts}
              renderComponent={(value, index) => (
                <RelatedProduct
                  value={value}
                  index={index}
                  onChange={this.handleRelatedProductChange}
                  elementId={values.productId}
                />
              )}
              onItemDelete={this.handleRelatedProductDelete}
              onAddItem={this.handleRelatedProductAdd}
              disabled={this.props.isLoading}
              isLoading={values.similarProductsStatus === "loading"}
            />
          </Grid.Cell>
        </Grid.Row>

        <Grid.Row>
          <Grid.Cell>
            <Tree
              required
              striped
              label="Category"
              nodes={values.categories}
              isLoading={this.props.isLoading}
              renderParent={props => (
                <Branch
                  catalog
                  {...props}
                  onClick={this.handleCategorySelect}
                  onExpand={this.handleCategoryTrigger}
                  disabled
                  activeId={values.categoryId}
                />
              )}
              renderChild={props => (
                <Branch
                  onClick={this.handleCategorySelect}
                  {...props}
                  activeId={values.categoryId}
                />
              )}
              childrenKey="childCategories"
              error={touched.categoryId && errors.categoryId}
            />
          </Grid.Cell>
        </Grid.Row>
        <Lightbox
          images={values.pictures}
          currentImage={values.currentImage}
          isOpen={values.lightboxIsOpen}
          onClickPrev={this.handlePrevImage}
          onClickNext={this.handleNextImage}
          onClose={this.handleCloseLightbox}
          preloadNextImage={false}
        />
      </Grid.Body>
    );
  }
}

export default FormDetails;

export enum types {
  PRODUCTS_GET_ALL = 'products/getAll',
  PRODUCTS_NEW = 'products/new',
  PRODUCTS_CREATE = 'products/create',
  PRODUCTS_UPDATE = 'products/update',
  PRODUCTS_SAVING = 'products/saving',
  PRODUCTS_ERROR = 'products/error',
  PRODUCTS_GET = 'products/get',
  PRODUCTS_GET_DETAILS = 'products/getDetails',
  PRODUCTS_ARCHIVE = 'products/archive',
  PRODUCTS_UNARCHIVE = 'products/unArchive',
  PRODUCTS_CHANGE_PAGE = 'products/changePage',
  PRODUCTS_TRIGGER_INACTIVE = 'products/triggerInactive',
  PRODUCTS_SET_DONE = 'products/setDone',
}

import {types} from '../_actionTypes/modal';
import {Reducer} from 'redux';

export interface IModalOptions {
  title?: string;
  question?: string;
  promise?: Promise<any> | (() => Promise<any>);
  hideDecline?: boolean;
  confirmText?: string;
  cancelText?: string;
  noBackdrop?: boolean;
}

export interface IModalStore {
  content: any;
  options: IModalOptions;
  confirm: () => void;
  decline: () => void;
  status: boolean;
  preloader?: boolean;
}

export interface IActionModalOpen {
  type: types.MODAL_OPEN;
  content?: any;
  options?: any;
  confirm?: (a?: any) => void;
  decline?: () => void;
  status?: boolean;
}

export interface IActionModalChange {
  type: types.MODAL_CHANGE;
  status: boolean;
  preloader: any;
}

export interface IActionModalClose {
  type: types.MODAL_CLOSE;
}

type IAction = IActionModalOpen | IActionModalChange | IActionModalClose;

const initialState: IModalStore = {
  content: '',
  options: {},
  confirm: null,
  decline: null,
  status: false,
};

 const reducer: Reducer<IModalStore> = (state: IModalStore = initialState, action: IAction) => {
  switch (action.type) {
    case types.MODAL_OPEN:
      return ({
        ...state,
        content: action.content,
        options: action.options,
        confirm: action.confirm,
        decline: action.decline,
        status: action.status,
      });
    case types.MODAL_CHANGE:
      return {
        ...state,
        preloader: action.preloader,
        status: action.status,
      };
    case types.MODAL_CLOSE:
      return initialState;
    default:
      return state;
  }
};

export default reducer;
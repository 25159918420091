
// @TODO - check and remove

export enum types {
  CLIENT_GET_ALL = 'client/getAll',
  CLIENT_CREATE = 'client/create',
  CLIENT_GET_NAME = 'client/getName',
  CLIENT_GET = 'client/get',
  CLIENT_UPDATE = 'client/update',
  CLIENT_REMOVE = 'client/remove',
  CLIENT_CHANGE_PAGE = 'client/changePage',
  CLIENT_GET_CLIENTS_TREE = 'client/getClientsTree',
}

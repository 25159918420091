import * as React from 'react';
import styled from 'styled-components';
import {  bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';

import {
  ProductListDto,
  ListResultDtoOfCountDtoOfInt64,
} from '../../service-proxies';

import { history } from '../../store';
import {
  MainContainer,
  Button,
  Pagination,
  Checkbox,
  Table,
  Link,
  Thumbnail,
  Skeleton,
} from '../../components';
import {
  PencilIcon,
  TrashIcon,
  PlusIcon,
  MinusIcon,
} from '../../assets/icons';
import { StyledSideMenu } from '../../components/SideMenu';
import * as actionsSearch from '../../_actions/search';
import * as actionsModal from '../../_actions/modal';
import { productsSetDone } from '../../_actions/products';
import { setPerPage } from 'src/_actions/layout';
import WithGranted, { IWithGrantedProps } from '../HOC/WithGranted';
import SubCategories from './SubCategories';
import {
  MASTERCATALOGUE_CATEGORY_ADD,
  MASTERCATALOGUE_CATEGORY_DELETE,
  MASTERCATALOGUE_PRODUCTS_ARCHIVE,
  MASTERCATALOGUE_PRODUCTS_CREATE,
  MASTERCATALOGUE_PRODUCTS_EDIT,
  MASTERCATALOGUE_PRODUCTS_UNARCHIVE,
} from '../../_constants/permissions';
import { apiUrl } from '../../_constants/system';
import { createStructuredSelector } from 'reselect';
import { IGlobalStore } from '../../_reducers/reducers';
import { IOpenCustomModalCallback } from '../../_actions/modal';
import PromptModal from '../../components/PromptModal';
import { createMasterCategory, deleteCategory, getMasterCategories, getCountForMasterCatalog } from '../../_api/categories';
import { IGetCategories } from '../../_reducers/category';
import { getProductsForMasterCatalog, archiveProduct, unArchiveProduct } from 'src/_api/products';
import { notifySwagger } from 'src/_actions/notification';
import {
  CategoryContextProvider,
  CategoryContextConsumer,
} from './Contexts/CategoryContext';
import CategoriesMaster from './CategoriesMaster';
import {setSearchFilter, getSearchFilter} from '../../_utils/userSession';
import PubSub from 'pubsub-js';

const Position = {
  Container: styled.div`
    display: flex;
    flex-direction: column; 
    flex: 1 1 100%;
    width: 100%;
  `,

  Body: styled.div`
    width: 100%;
    height: 100%; 
  `,

  Head: styled<{ className?: string }>(({ className, children }) => <header className={className}>
    <div>
      {children}
    </div>
  </header>)`
    padding: 20px;
    >div{
      margin: -7.5px;
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
    }
  `,

  HeadElement: styled.div`
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    & > * {
      margin: 7.5px;
    }
  `,

  Toolbar: styled.div`
    text-align: right;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding-right: 5px;
  `,

  ActionButton: styled.div`
    margin-left: 10px;
  `,
};

const MainContainerWithSidebar = styled(MainContainer)`
  ${StyledSideMenu} ~ ${Position.Container}{
    width: calc(100% - 269px);
  }
  ${Position.Container}{
    width: 100%;
  }
`;

interface IEditButton {
  id: number;
  onEdit: (id: number) => void;
}

const EditButton: React.SFC<IEditButton> = ({
  onEdit,
  id,
}) => {

  function handleEdit() {
    onEdit(id);
  }

  return <Link.Button onClick={handleEdit}><PencilIcon /></Link.Button>;
};

interface IDeleteButton {
  id: number;
  onDelete: (id: number) => void;
}

const DeleteButton: React.SFC<IDeleteButton> = ({
  onDelete,
  id,
}) => {

  function handleDelete() {
    onDelete(id);
  }

  return <Link.Button onClick={handleDelete}><TrashIcon /></Link.Button>;
};

interface IUnArchiveButton {
  id: number;
  onUnArchive: (id: number) => void;
}

const UnArchiveButton: React.SFC<IUnArchiveButton> = ({
  onUnArchive,
  id,
  children,
}) => {

  function handleUnArchive() {
    onUnArchive(id);
  }

  return <Link.Button onClick={handleUnArchive}>{children}</Link.Button>;
};

function findSubcategoryRecursive(categories: IGetCategories[], subCategoryId: number): IGetCategories | null {
  if (!categories) {
    return null;
  }
  for (const cat of categories) {
    if (cat.id === subCategoryId) {
      return cat;
    }
    if (cat.childCategories && cat.childCategories.length) {
      const childRes = findSubcategoryRecursive(cat.childCategories, subCategoryId);
      if (childRes) {
        return childRes;
      }
    }
  }
  return null;
}

interface IRouteParams {
  subCategoryId?: string;
  categoryId?: string;
}

interface IGetProductsParams {
  skip?: number;
  searchText?: string;
  perPage?: number;
  categoryId?: number;
  isArchived?: boolean;
}

interface IListProps extends
  IConnectedProps,
  IConnectedActions,
  IWithGrantedProps,
  RouteComponentProps<IRouteParams> {
}

interface IListState {
  subCategorySidebar?: boolean;
  searchText?: string;

  products: ProductListDto[];
  totalCount: number;
  skip: number;
  isArchived: boolean;
  status: 'loading' | 'done' | string;
}

const initialState = {
  subCategorySidebar: false,
  searchText: '',

  products: null,
  totalCount: null,
  skip: 0,
  isArchived: false,
  status: 'loading',
};

class List extends React.Component<IListProps, IListState> {
  private categoriesRef;
  constructor(props: IListProps) {
    super(props);
    this.handleAddNewClick = this.handleAddNewClick.bind(this);
    this.handleChangePage = this.handleChangePage.bind(this);
    this.handleTriggerInactive = this.handleTriggerInactive.bind(this);

    this.handleItemEdit = this.handleItemEdit.bind(this);
    this.handleItemArchiveRequest = this.handleItemArchiveRequest.bind(this);
    this.handleItemUnarchiveRequest = this.handleItemUnarchiveRequest.bind(this);

    this.handleAddNewCategory = this.handleAddNewCategory.bind(this);
    this.handleDeleteCategory = this.handleDeleteCategory.bind(this);

    this.checkForProductsInCategory = this.checkForProductsInCategory.bind(this);
    this.findSelectedCategory = this.findSelectedCategory.bind(this);

    this.categoriesRef = React.createRef();

    this.state = {
      ...initialState,
    };

  }

  componentDidMount() {
    const search = getSearchFilter() || '';
    this.setState({searchText: search});
    PubSub.publish('SEARCH', search);

    this.getProducts({
    });
    this.props.setSearch('/master-products', (searchText) => {
      setSearchFilter(searchText);
      this.setState({
        searchText,
        skip: 0,
      }, () => {
        this.getProducts({
          searchText,
          skip: 0,
        });
        this.refreshCategories(searchText);
      });
    });
  }

  getProducts(params: IGetProductsParams = {}) {
    const subCategoryId = this.props.match.params.subCategoryId;
    const categoryId = this.props.match.params.categoryId;
    const finalCategoryId = subCategoryId || categoryId;

    const skip = (Number.isInteger(params.skip) && (params.skip >= 0)) ? params.skip : this.state.skip;
    const searchText = params.searchText || this.state.searchText;
    const perPage = params.perPage || this.props.perPage;
    const isArchived = params.isArchived || this.state.isArchived;

    this.setState({ status: 'loading' }, () => {
      getProductsForMasterCatalog(finalCategoryId as any as number, isArchived, perPage, skip, searchText)
        .then(productsData => {
          this.setState({
            status: 'done',
            products: productsData.items,
            totalCount: productsData.totalCount,
            isArchived,
          }, () => {
            this.props.productsSetDone();
          });
        })
        .catch(e => {
          this.props.productsSetDone();
          this.props.notifySwagger(e, 'error');
        });
    });

  }

  refreshCategories(searchText: string = '') {
    this.categoriesRef.current.getCategories(searchText);
  }

  componentDidUpdate(prevProps: IListProps, prevState: IListState) {
    const productsStateChanges = List.checkPropsChanges(prevState, this.state);
    const matchParamsChanges = List.checkPropsChanges(this.props.match.params, prevProps.match.params);
    const productsPropsChanges = List.checkPropsChanges(this.props, prevProps);
    if (productsStateChanges.isChanged) {
      this.getProducts();
    }
    if (matchParamsChanges.isChanged) {
      this.getProducts();
      if (this.state.searchText) {
        this.setState({
          searchText: getSearchFilter() || ''
        }, () => {
          this.categoriesRef.current.getCategories();
        });
      }
    }
    if (productsPropsChanges.isChanged) {
      this.getProducts();
    }

    const isHasChildren = this.categoriesRef && !!this.categoriesRef.current.state.categories.length;

    if (!isHasChildren) {
      if (this.state.subCategorySidebar) {
        this.setState({ subCategorySidebar: false });
      }
    } else if (isHasChildren) {
      if (!this.state.subCategorySidebar) {
        this.setState({ subCategorySidebar: true });
      }
    }
  }

  static checkPropsChanges(firstObject, secondObject) {
    const changedParams = [];
    const changes = {};

    for (const param in secondObject) {
      if (
        (secondObject[param] !== firstObject[param]) &&
        (param !== 'status') &&
        (typeof secondObject[param] !== 'object')
      ) {
        changes[param] = firstObject[param];
        changedParams.push(changes);
      }
    }

    const isChanged = changedParams && !!changedParams.length;

    return {
      isChanged,
      changes,
    };
  }

  handleAddNewCategory() {
    this.checkForProductsInCategory().then(() => {
      return this.props.openModal(
        (resolve, reject) => (
          <PromptModal
            label="Category name"
            required
            maxLength={100}
            resolve={resolve}
            reject={reject}
            saveButtonText="Add"
            onSubmit={(name) => createMasterCategory(
              name,
              this.props.match.params.subCategoryId ? parseInt(this.props.match.params.subCategoryId as string, 10) :
                (this.props.match.params.categoryId ? parseInt(this.props.match.params.categoryId as string, 10) : null)
            )}
          />
        ),
        { title: 'NEW CATEGORY' });
    }).then(() => {
      this.categoriesRef.current.getCategories('', this.state.isArchived, this.props.match.params.subCategoryId);
    }).catch(() => { });
  }

  checkForProductsInCategory(): Promise<void> {
    const category = this.findSelectedCategory();
    const childCategories = category ? category.childCategories : this.categoriesRef.current.state.categories;
    if ((childCategories && childCategories.length === 0) && this.state.products.length > 0) {
      // current category has some products
      return this.props.prompt({
        title: 'Warning!',
        question: `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam?`,
        confirmText: 'Submit',
        cancelText: 'Cancel',
      });
    } else {
      return Promise.resolve();
    }
  }

  handleDeleteCategory() {
    const category = this.findSelectedCategory();
    if (!category) {
      console.error('internal error - cannot find category to delete');
      return;
    }
    if (category.count || this.state.products && this.state.products.length) {
      this.props.alert(`Category "${category.name}" cannot be deleted since it contains products. Please remove the products first.`, '')
        .then(() => { }).catch(() => { });
      return;
    }

    this.props.prompt({
      title: 'Warning!',
      question: `Are you sure you want to delete "${category.name}" category?`,
      promise: () => deleteCategory(category.id),
    }).then((res) => {
      this.categoriesRef.current.getCategories('', this.state.isArchived);
      const baseUrl = '/master-products';
      if (parseInt(this.props.match.params.categoryId as string, 10) === category.id) {
        history.push(baseUrl); // removed top category - redirect to master catalog root
        return;
      } else if (parseInt(this.props.match.params.subCategoryId as string, 10) === category.id) {
        history.push(baseUrl + '/' + this.props.match.params.categoryId); // removed subcategory - redirect to a top category
        return;
      }
    }).catch(() => { });
  }

  findSelectedCategory(): IGetCategories | null {
    const { categoryId, subCategoryId } = this.props.match.params;
    if (!categoryId && !this.categoriesRef.current.state.categories) {
      return null;
    }

    const _categoryId = parseInt(categoryId, 10);

    const topCategory = this.categoriesRef.current.state.categories.find(category => category.id === _categoryId);

    if (!topCategory) {
      return null;
    } else if (!subCategoryId) {
      return topCategory;
    } else {
      const _subCategoryId = parseInt(subCategoryId, 10);
      return findSubcategoryRecursive(topCategory.childCategories, _subCategoryId);
    }
  }

  handleAddNewClick() {
    history.push(`${this.props.match.url}/new`);
  }

  handleChangePage(pageInfo) {
    const {
      perPage,
      skip,
    } = pageInfo;

    this.getProducts({
      skip,
      perPage,
    });
    this.props.setPerPage(perPage);
    this.setState({ skip });
  }


  handleTriggerInactive() {
    this.setState({
      isArchived: !this.state.isArchived,
    });
  }

  renderPreloaderRows(perPage, status) {
    const rows = [];
    const elementsCount = (status === 'loading') ? perPage - 1 : 0;

    for (let i = 0; i < elementsCount; i++) {
      rows.push(<Table.Row
        key={'preloader' + i}
        rows={perPage}
        cells={[
          <Skeleton.Square key={`thumbnail-preloader-${i}`} />,
          <React.Fragment key={`info-preloader-${i}`}>
            <p><Skeleton.Line /></p>
            <p><Skeleton.Line /></p>
            <p><Skeleton.Line /></p>
          </React.Fragment>,
          <Position.Toolbar key={`actions-preloader-${i}`}>
            <Skeleton.Line size="sm" />
          </Position.Toolbar>,
        ]}
      />);
    }
    return rows;
  }

  handleItemEdit(id: number) {
    history.push(`${this.props.match.url}/edit/${id}`);
  }

  handleItemArchiveRequest(id: number) {
    this.props.prompt({ question: 'Are you sure you want to archive this item?' })
      .then(() => {
        archiveProduct(id)
          .then(() => {
            const categoryId = this.props.match.params.subCategoryId ?
              this.props.match.params.subCategoryId :
              this.props.match.params.categoryId;
            this.getProducts({ categoryId: categoryId as any as number });
            this.categoriesRef.current.getCategories('', this.state.isArchived, this.props.match.params.subCategoryId);
          });
      })
      .catch(() => { });
  }

  handleItemUnarchiveRequest(id: number) {
    this.props.prompt({
      question: 'Are you sure you want to unarchive this item',
      cancelText: 'No',
      confirmText: 'UnArchive',
    })
      .then(() => {
        unArchiveProduct(id)
          .then(() => {
            const categoryId = this.props.match.params.subCategoryId ?
              this.props.match.params.subCategoryId :
              this.props.match.params.categoryId;
            this.getProducts({ categoryId: categoryId as any as number });
            this.categoriesRef.current.getCategories('', this.state.isArchived, this.props.match.params.subCategoryId);
          });
      })
      .catch(() => { });
  }

  handleGetCategoriesCounts(filter: string, catalogId: number, isArchived: boolean): Promise<ListResultDtoOfCountDtoOfInt64> {
    return getCountForMasterCatalog(isArchived, filter);
  }

  render() {
    return (
      <CategoryContextProvider
        ref={this.categoriesRef}
        onGetCategories={getMasterCategories}
        onGetCategoriesCounts={this.handleGetCategoriesCounts}
      >
        <CategoryContextConsumer>
          {(context) => {
            return <>
              <CategoriesMaster />
              <MainContainerWithSidebar>
                {
                  this.state.subCategorySidebar &&
                  <SubCategories
                    match={this.props.match}
                    categories={context.categories}
                    totalCount={context.totalCount}
                  />
                }
                <Position.Container>
                  <Position.Head>
                    <Position.HeadElement>
                      {
                        this.props.isGranted(MASTERCATALOGUE_PRODUCTS_UNARCHIVE) &&
                        <Checkbox value={this.state.isArchived} id="inactiveFlag" label="Inactive Products" onChange={this.handleTriggerInactive} />
                      }
                    </Position.HeadElement>
                    <Position.HeadElement>
                      {
                        this.props.isGranted(MASTERCATALOGUE_PRODUCTS_CREATE) &&
                        <Position.ActionButton>
                          <Button onClick={this.handleAddNewClick} icon={<PlusIcon />}>Add product</Button>
                        </Position.ActionButton>
                      }
                      {
                        this.props.isGranted(MASTERCATALOGUE_CATEGORY_ADD) &&
                        <Position.ActionButton>
                          <Button negative onClick={this.handleAddNewCategory} icon={<PlusIcon />}>Add category</Button>
                        </Position.ActionButton>
                      }
                      {
                        this.props.isGranted(MASTERCATALOGUE_CATEGORY_DELETE) &&
                        <Position.ActionButton>
                          <Button
                            primary
                            onClick={this.handleDeleteCategory}
                            disabled={!this.props.match.params.categoryId && !this.props.match.params.subCategoryId}
                            icon={<MinusIcon />}
                          >
                            Delete category
                    </Button>
                        </Position.ActionButton>
                      }
                    </Position.HeadElement>
                  </Position.Head>
                  <Position.Body>
                    {
                      <Table fullHeight>
                        <Table.Header columns={[
                          { name: 'thumbnail', label: '', size: "sm" },
                          { name: 'product', label: 'product' },
                          { name: 'actions', label: '' },
                        ]} />
                        <Table.Body>
                          {
                            (this.state.status === 'done') &&
                            (this.state.products && !!this.state.products.length) &&
                            this.state.products.map((item, index) =>
                              <Table.Row
                                key={`${index}-${item.id}`}
                                cells={[
                                  <React.Fragment key={`thumbnail-${index}-${item.id}`}>
                                    {
                                      this.state.isArchived ?
                                        <Thumbnail
                                          image={(item.picture && item.picture.pictureUrl) ? apiUrl + item.picture.pictureUrl : ''}
                                          name={(item.picture && item.picture.name) ? item.picture.name : 'thumbnail'}
                                        /> :
                                        <Link to={`${this.props.match.url}/product/${item.id}`}>
                                          <Thumbnail
                                            image={(item.picture && item.picture.pictureUrl) ? apiUrl + item.picture.pictureUrl : ''}
                                            name={(item.picture && item.picture.name) ? item.picture.name : 'thumbnail'}
                                          />
                                        </Link>
                                    }
                                  </React.Fragment>,
                                  [
                                    <p key={`name-${index}-${item.id}`}>
                                      {
                                        this.state.isArchived ?
                                          item.name :
                                          <Link to={`${this.props.match.url}/product/${item.id}`}>
                                            {item.name}
                                          </Link>
                                      }
                                    </p>,
                                    <p key={`sku-${index}-${item.id}`}>
                                      <span  style={{color: '#000'}}>{item.sku}</span>
                                    </p>,
                                    <p key={`desc-${index}-${item.id}`}>
                                      <span style={{color: '#000'}}>
                                        {item.description}
                                      </span>
                                      
                                    </p>,
                                  ],
                                  <Position.Toolbar key={`cell-toolbar-${index}-${item.id}`} >
                                    {
                                      this.state.isArchived ?
                                        <React.Fragment>
                                          {
                                            this.props.isGranted(MASTERCATALOGUE_PRODUCTS_UNARCHIVE) &&
                                            <UnArchiveButton onUnArchive={this.handleItemUnarchiveRequest} id={item.id}>Reactivate</UnArchiveButton>
                                          }
                                        </React.Fragment> :
                                        <React.Fragment>
                                          {
                                            this.props.isGranted(MASTERCATALOGUE_PRODUCTS_EDIT) &&
                                            <EditButton onEdit={this.handleItemEdit} id={item.id} />
                                          }
                                          {
                                            this.props.isGranted(MASTERCATALOGUE_PRODUCTS_ARCHIVE) &&
                                            <DeleteButton onDelete={this.handleItemArchiveRequest} id={item.id} />
                                          }
                                        </React.Fragment>
                                    }
                                  </Position.Toolbar>
                                ]}
                              />)
                          }
                          {
                            (this.state.status === 'done') &&
                            (!this.state.products.length) &&
                            <Table.Row
                              key={'no_poroducts'}
                              colSpan="3"
                              cells={[<span key="0">NO PRODUCTS FOUND</span>]}
                            />
                          }
                          {
                            (this.state.status === 'loading') &&
                            this.renderPreloaderRows(this.props.perPage, this.state.status)
                          }
                        </Table.Body>
                      </Table>
                    }
                  </Position.Body>
                  <Pagination
                    skip={this.state.skip}
                    perPage={this.props.perPage}
                    count={this.state.totalCount}
                    onChange={this.handleChangePage}
                  />
                </Position.Container>
              </MainContainerWithSidebar>;
            </>;
          }}
        </CategoryContextConsumer>
      </CategoryContextProvider>
    );
  }
}

interface IConnectedProps {
  perPage: number;
}

interface IConnectedActions {
  setSearch: typeof actionsSearch.set;
  setPerPage: typeof setPerPage;
  prompt: actionsModal.IOpenConfirmPrompt;
  alert: actionsModal.IOpenAlert;
  openModal: IOpenCustomModalCallback;
  notifySwagger: typeof notifySwagger;
  productsSetDone: typeof productsSetDone;
}

export default connect(
  createStructuredSelector<IGlobalStore, IConnectedProps>({
    perPage: state => state.layout.perPage,
  }),
  dispatch => bindActionCreators({
    setSearch: actionsSearch.set,
    setPerPage,
    prompt: actionsModal.prompt as any as actionsModal.IOpenConfirmPrompt,
    alert: actionsModal.alert as any as actionsModal.IOpenAlert,
    openModal: actionsModal.openCustomModalCallback as any as IOpenCustomModalCallback,
    notifySwagger,
    productsSetDone,
  }, dispatch)
)(WithGranted(List));

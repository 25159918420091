import * as React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { Formik } from "formik";
import * as Yup from "yup";

import * as actions from "../../_actions/current-user";
import Container from "./ChangePasswordContainer";
import ForgotPasswordForm from "./ChangePasswordForm";
import { Link } from "../../components";
import { AuthenticateModelWithConfirmPassword } from "../../service-proxies";
import { createStructuredSelector } from "reselect";
import { IGlobalStore } from "../../_reducers/reducers";

const loginValidationSchema = Yup.object().shape({
  password: Yup.string()
    .required("Password is required!")
    .min(6, "Your password must contain at least 6 characters!"),
  confirmPassword: Yup.string()
    .required("Confirmation password is required!")
    .oneOf([Yup.ref("password"), null], "Passwords do not match!")
    .min(6, "Your password must contain at least 6 characters!")
});

const loginInitialValues: AuthenticateModelWithConfirmPassword = {
  password: "",
  confirmPassword: "",
  rememberClient: false
};

interface ILoginProps extends IConnectedActions, IConnectedProps {}

class Login extends React.Component<ILoginProps> {
  constructor(props) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleSubmit(values, { setSubmitting, setErrors }) {
    setSubmitting(true);
    this.props.login(values).then(() => {
      setSubmitting(false);
    });
  }

  render() {
    return (
      <Container>
        <div className="logo">
          <img src="/img/otto-logo.svg" width="140" alt="Otto logo" />
        </div>

        <Formik
          initialValues={loginInitialValues}
          validationSchema={loginValidationSchema}
          onSubmit={this.handleSubmit}
          render={props => (
            <ForgotPasswordForm
              {...props}
              errorMessage={this.props.errorMessage}
              errorDetails={this.props.errorDetails}
            />
          )}
        />

        <Link primary to="/">
          Return to Login
        </Link>
      </Container>
    );
  }
}

interface IConnectedProps {
  errorMessage: string | null;
  errorDetails: string | null;
}

interface IConnectedActions {
  login: (data: AuthenticateModelWithConfirmPassword) => Promise<void>;
}

export default connect(
  createStructuredSelector<IGlobalStore, IConnectedProps>({
    errorMessage: state =>
      state.currentUser.loginError
        ? state.currentUser.loginError.message
        : null,
    errorDetails: state =>
      state.currentUser.loginError ? state.currentUser.loginError.details : null
  }),
  dispatch =>
    bindActionCreators(
      {
        login: (actions.login as any) as (
          data: AuthenticateModelWithConfirmPassword
        ) => Promise<void>
      },
      dispatch
    )
)(Login);

import {SwaggerException} from '../service-proxies';

export function getSwaggerErrorResponse(error) {
  if (SwaggerException.isSwaggerException(error)) {
    if (error.response) {
      return JSON.parse(error.response);
    } else if (error.message) {
      return {
        error: {
          message: error.message,
        }
      };
    }
  } else {
    return {
      error: {
        message: 'An internal server error occurred.',
      }
    };
  }
}

import styled, { StyledComponentClass } from 'styled-components';

export interface IRunningLineLoader {
  isActive: boolean;
}

const RunningLineLoader = styled.div<IRunningLineLoader>`
${({ isActive, theme }) => {

    const width = '30%';
    const height = '1px';

    return `
    height: ${height};
    width: 100%;
    position: relative;
    overflow: hidden;
    background-color: transparent;
    
    ${isActive ?
        `
      &:before {
      display: block;
      position: absolute;
      content: "";
      left: -${width};
      width: ${width};
      height: ${height};
      background-color: ${theme.default};
      animation: loading 1.5s linear infinite;
    }
    
    @keyframes loading {
        from {left: -${width};}
        to {left: 100%;}
    }
      ` : ''}
  `;
  } }
` as unknown as StyledComponentClass<IRunningLineLoader, any>;


RunningLineLoader.defaultProps = {
  isActive: false,
};

export default RunningLineLoader;

import { vendorServiceProxy } from '../servicePool';
// import { EntityDtoOfInt64 } from '../service-proxies';

export function searchVendors(filter: string): Promise<any> {
  return vendorServiceProxy.searchVendors({
    filter,
    maxResultCount: 5,
  });
}

// export function createVendor(name: string): Promise<EntityDtoOfInt64> {
//   return vendorServiceProxy.createVendor({
//     name
//   });
// }

// export function deleteVendor(id: number): Promise<void> {
//   return vendorServiceProxy.deleteVendor(id);
// } 
import * as React from 'react';
import * as Yup from 'yup';

import { PackingSlipOrderItemDto } from '../../../service-proxies';

import {
  Number,
  Button,
  Modal,
} from '../../../components';
import {
  NUMBER_MESSAGE, INT32_MAX,
} from '../../../_constants/validation';
import {
  getMaxNumberLengthMessage,
} from 'src/_utils/validation';

const ListNumber = function ({ id, ...props }) {
  function handleChange(e) {
    props.onChange(id, e);
  }
  function handleBlur(e) {
    props.onBlur(id, e);
  }
  return <Number
    {...props}
    onChange={handleChange}
    onBlur={handleBlur}
  />;
};

interface IReconciliationModalState {
  items: PackingSlipOrderItemDto[];
  errors?: {};
  isValid: boolean;
}

interface IReconciliationModalProps {
  items: PackingSlipOrderItemDto[];
  onClickReject: () => void;
  onClickResolve: (items: PackingSlipOrderItemDto[]) => void;
}

class ReconsaliationModal extends React.Component<IReconciliationModalProps, IReconciliationModalState> {

  static initialState: IReconciliationModalState = {
    items: [],
    errors: {},
    isValid: true,
  };

  static validationScheme = Yup.object().shape({
    items: Yup.array(Yup.object().shape({
      quantityReturned: Yup.number()
        .max(INT32_MAX, getMaxNumberLengthMessage(INT32_MAX))
        .typeError(NUMBER_MESSAGE)
        .nullable(),
    }))
  });

  constructor(props) {
    super(props);
    this.state = ReconsaliationModal.initialState;

    this.validate = this.validate.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleBlur = this.handleBlur.bind(this);
    this.handleResolve = this.handleResolve.bind(this);
  }

  componentDidMount() {
    this.setState({
      items: this.props.items,
    });
  }

  async validate() {
    try {
      await ReconsaliationModal.validationScheme.validate(this.state);
      this.setState({ errors: {}, isValid: true });
    } catch (e) {
      const newState = Object.assign({}, this.state);
      newState.errors[e.path] = e.message;
      this.setState({ ...newState, isValid: false });
    }
  }

  handleChange(id, value) {
    const items = this.state.items;
    items[id].quantityReturned = value;

    this.setState({ items }, () => {
      this.validate();
    });
  }

  handleBlur(id, e) {
    const value = e.target.value;
    const items = this.state.items;
    items[id].quantityReturned = value;

    this.setState({ items }, () => {
      this.validate();
    });
  }

  async handleResolve() {
    await this.validate();
    if (this.state.isValid) {
      this.props.onClickResolve(this.state.items);
    }
  }

  render() {
    return <React.Fragment>
      <Modal.Body>
        {
          this.state.items
            .map((item, index) => item.isNeedToReturn &&
              <ListNumber
                label={item.product.name}
                key={index}
                value={item.quantityReturned}
                placeholder="Quantity"
                id={index}
                onChange={this.handleChange}
                onBlur={this.handleBlur}
                error={this.state.errors[`items[${index}].quantityReturned`]}
                positiveOnly
                integer
              />
            )
        }
      </Modal.Body>
      <Modal.Footer>
        <Modal.FooterElement />
        <Modal.FooterElement>
          <Button
            primary
            onClick={this.props.onClickReject}
          >
            Cancel Returns
          </Button>
          <Button
            disabled={!this.state.isValid}
            onClick={this.handleResolve}
          >
            Submit Returns
          </Button>
        </Modal.FooterElement>
      </Modal.Footer>
    </React.Fragment>;
  }
}

export default ReconsaliationModal;
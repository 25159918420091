import * as React from 'react';
import RoutesMapper from '../../Router/RoutesMapper';
import List from './List';
import Edit from './Edit';
import {
  ADMINISTRATION_USERS,
  ADMINISTRATION_USERS_CREATE,
  ADMINISTRATION_USERS_UPDATE,
  ADMINISTRATION_USERS_PERMISSIONS_EDIT,
  ADMINISTRATION_USERS_ROLES_EDIT,
  ADMINISTRATION_USERS_SUPERVISORS_EDIT,
} from '../../../_constants/permissions';
import {IRouteEntry} from '../../../_types/common';

const routes: IRouteEntry[] = [
  {
    path: '',
    exact: true,
    component: List,
    permission: ADMINISTRATION_USERS,
  },
  {
    path: '/new',
    exact: true,
    render: (props) => <Edit {...props} userId="new" />,
    permission: ADMINISTRATION_USERS_CREATE,
    keepForbiddenRoute: true,
  },
  {
    path: '/:id',
    exact: true,
    render: (props, match) => {
      return <Edit {...props} userId={match.params.id} />;
    },
    someOfPermissions: [ADMINISTRATION_USERS_UPDATE, ADMINISTRATION_USERS_PERMISSIONS_EDIT, ADMINISTRATION_USERS_ROLES_EDIT, ADMINISTRATION_USERS_SUPERVISORS_EDIT],
  },
];

const UserRoutes = (props) => {
  return (
    <RoutesMapper
      routes={routes}
      addNotFound
      {...props}
    />
  );
};

export default UserRoutes;

import React, { Fragment } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import {
  Label,
  Error,
} from '../components';

import * as Skeleton from './Skeleton';

export const Preloader = styled(({ className }) => {
  return <ul className={className}>
    <li><Skeleton.Line size="xs" /> <Skeleton.Line /></li>
    <li><Skeleton.Line size="xs" /> <Skeleton.Line /></li>
    <li><Skeleton.Line size="xs" /> <Skeleton.Line /></li>
  </ul>;
})`
  list-style: none;

  li{
    display: flex;
    margin: 0 0 0 20px;
    .${Skeleton.Line.styledComponentId}{
      margin: 0 20px 20px 0;
    }
    &:first-of-type{
      margin: 0;
    }
    &:last-of-type{
      .${Skeleton.Line.styledComponentId}{
        margin: 0 20px 0 0;
      }
    }
  }

`;

export const Body = styled.div`
  ${ ({ theme, ...props }) => {

    let {
      lighter,
      lightest,
      main,
    } = theme;

    let margin = props.compact ?
      '0' :
      '7px 0 3px';

    let background = props.striped ?
      lightest :
      main;

    let backgroundStep = props.striped ?
      '2' :
      '1';

    let borderColor = props.compact ?
      'transparent' :
      lighter;

    let branchBorderColor = props.striped || props.compact ?
      'transparent' :
      lighter;

    return `
      ${!props.borderless ? `
      border-top: 1px solid ${borderColor};
      border-bottom: 1px solid ${borderColor};
      border-left: 1px solid ${borderColor};
      border-right: 1px solid ${borderColor};` : ''}
      position: relative;
      margin: ${margin};
      &>*{
        border-bottom: 1px solid ${branchBorderColor};
        &:last-of-type{
          border-bottom: none;
        }
        &:nth-of-type(${backgroundStep}n) {
          background: ${background};
        }
      }
    `;
  }}
`;

export const NodeParent = styled(({ className, children }) => {
  return <ul className={className}>
    {children}
  </ul>;
})`
  ${({ theme }) => {

    let {
      global,
    } = theme;

    return `
      ${global}
      padding-left: 20px;
      margin: 10px 0;
    `;
  }}
`;

export const NodeChildParent = styled(({ className, children, onChange }) => {
  return <li onClick={onChange} className={className}>
    {children}
  </li>;
})`
  ${({ theme, ...props }) => {

    let {
      global,
    } = theme;

    return `
      ${global}
    `;
  }}
`;

export const NodeChild = styled(({ className, children, onChange }) => {
  return <li onClick={onChange} className={className}>
    {children}
  </li>;
})`
  ${({ theme, ...props }) => {

    let {
      global,
    } = theme;

    return `
      ${global}
    `;
  }}
`;

export default styled(class extends React.Component {
  static propTypes = {
    className: PropTypes.string,

    cascade: PropTypes.bool,
    compact: PropTypes.bool,
    striped: PropTypes.bool,
    borderless: PropTypes.bool,

    onChange: PropTypes.func,
    renderParent: PropTypes.func,
    renderChild: PropTypes.func.isRequired,

    childrenKey: PropTypes.string,
    nodes: PropTypes.array,

    expandChildless: PropTypes.bool,

    label: PropTypes.string,
    error: PropTypes.string,
    required: PropTypes.bool,
    isLoading: PropTypes.bool,
  };

  static defaultProps = {
    expandChildless: false,
    borderless: false,
  };

  constructor(props) {
    super(props);

    this.childrenKey = this.props.childrenKey ?
      this.props.childrenKey :
      'children';

    this.pair = false;

    this.renderChildParentNode = this.renderChildParentNode.bind(this);
    this.renderChildNode = this.renderChildNode.bind(this);
    this.nodeDive = this.nodeDive.bind(this);

    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(e, path) {
    e.stopPropagation();
    e.preventDefault();
    if (this.props.onChange) {
      this.props.onChange(path);
    }
  }

  renderChildParentNode(node, index, path, pair) {

    let newPath = [...path, index];

    let Node = this.props.renderParent ?
      this.props.renderParent :
      NodeChildParent;

    let content = this.props.renderParent ?
      '' :
      node.name || '';

    let children = this.nodeDive(node[this.childrenKey], newPath);

    return <Node
      depth={newPath.length}
      onChange={(e) => this.handleChange(e, newPath)}
      node={node}
      path={newPath}
      pair={pair}
    >
      {content}
      {children}
    </Node>;
  }

  renderChildNode(node, index, path, pair) {

    let newPath = [...path, index];

    let Node = this.props.renderChild ?
      this.props.renderChild :
      NodeChild;

    let content = this.props.renderParent ?
      '' :
      node.name || '';

    return <Node
      depth={newPath.length}
      onChange={(e) => this.handleChange(e, newPath)}
      node={node}
      path={newPath}
      pair={pair}
    >{content}</Node>;
  }

  nodeDive(nodes, path = []) {
    let Parent = this.props.cascade ?
      NodeParent :
      Fragment;

    if (nodes) {
      return nodes.map((node, index) => {
        this.pair = !this.pair;
        return <Parent key={index}>
          {
            (node[this.childrenKey] && (this.props.expandChildless || !!node[this.childrenKey].length)) ?
              this.renderChildParentNode(node, index, path, this.pair) :
              this.renderChildNode(node, index, path, this.pair)
          }
        </Parent>;
      });
    }
  }

  render() {

    let {
      className,
      label,
      error,
      required,
      nodes,
      compact,
      borderless,
      striped,
      isLoading,
      ...restProps
    } = this.props;

    return <div className={className}>
      {
        label &&
        <Label
          error={error}
          htmlFor={restProps.id ? restProps.id : null}
        >
          {`${label} ${required ? ' * ' : ''}`}
        </Label>
      }
      <Body
        compact={compact}
        striped={striped}
        borderless={borderless}
      >
        {
          !isLoading ?
            this.nodeDive(nodes) :
            <Preloader />
        }
      </Body>
      <Error compact={compact}>
        {error}
      </Error>
    </div>;
  }
})`
  ${({ theme }) => {

    let {
      global,
    } = theme;

    return `
      ${global}
    `;
  }}
`;
import { divisionServiceProxy } from '../servicePool';
import {
  EditDivisionDto,
  EntityDtoOfInt64,
  CreateDivisionDto,
  UpdateDivisionDto,
} from '../service-proxies';

// @TODO - remove from front and backend
export function searchDivisions(clientId, text = '', callback) {
  divisionServiceProxy.searchDivisionsByClientId(clientId, 10, text).then(data => {
    callback(data);
  });
}

export function getDivisionForEdit(id: number): Promise<EditDivisionDto> {
  return divisionServiceProxy.getDivisionForEdit(id);
}

export function updateDivision(input: UpdateDivisionDto): Promise<EntityDtoOfInt64> {
  return divisionServiceProxy.updateDivision(input);
}

export function createDivision(input: CreateDivisionDto): Promise<EntityDtoOfInt64> {
  return divisionServiceProxy.createDivision(input);
}

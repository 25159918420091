import * as types from '../_actionTypes/users';
import { types as typesNotification } from '../_actionTypes/notification';
import { userServiceProxy, userSupervisorsServiceProxy } from '../servicePool';

import { ListUserDto } from '../service-proxies';

export interface IListUserFormatted extends ListUserDto {
  formattedPhone: string;
  title: string;
}

import {
  getSwaggerErrorResponse,
} from '../_utils/errorHandling';

export function getUserById(userId) {
  return function (dispatch) {
    dispatch({
      type: types.USER_GET_BY_ID,
      allStatus: 'loading',
      status: '',
    });
    userServiceProxy.getUserForEdit(userId).then(data => {
      dispatch({
        type: types.USER_GET_BY_ID,
        status: 'loaded',
        user: {
          ...data,
          password: '',
          confirmPassword: '',
        }
      });
    }).catch(e => {
      dispatch({
        type: types.USER_GET_BY_ID,
        allStatus: 'error',
      });
      dispatch({
        type: typesNotification.NOTIFICATION_NOTIFY,
        message: getSwaggerErrorResponse(e).error.message,
        messageType: 'error',
      });
    });
  };
}

export function getUserSupervisors(userId) {
  return function (dispatch) {
    dispatch({
      type: types.USER_GET_SUPERVISORS,
      userSupervisorsStatus: 'loading',
    });
    return userSupervisorsServiceProxy.getUserSupervisors(userId).then(data => {
      dispatch({
        type: types.USER_GET_SUPERVISORS,
        userSupervisorsStatus: 'loaded',
        buyingManagers: data.items,
      });
      return data.items;
    }).catch(e => {
      dispatch({
        type: types.USER_GET_SUPERVISORS,
        userSupervisorsStatus: 'error',
      });
      dispatch({
        type: typesNotification.NOTIFICATION_NOTIFY,
        message: getSwaggerErrorResponse(e).error.message,
        messageType: 'error',
      });
    });
  };
}

export function getAll(clientId,
  filter = '',
  inactive = false,
  sorting = 'surname ASC',
  skipCount = 0,
  maxResultCountPerPage = 20,
  loadingStatus = 'loading') {

  return function (dispatch, getState) {
    dispatch({
      type: types.USER_GET_ALL,
      allStatus: loadingStatus,
      users: loadingStatus === 'sorting' ? getState().users.users : undefined,
      status: '',
    });
    userServiceProxy.getUsers(clientId, filter, inactive, sorting, skipCount, maxResultCountPerPage)
      .then(data => {
        const {
          items,
          totalCount,
        } = data;

        items.forEach(item => {
          const newItem: IListUserFormatted = item as IListUserFormatted;
          if (newItem.phoneNumber) {
            const phoneStr = newItem.phoneNumber.toString();
            newItem.formattedPhone = `+${phoneStr.substr(0, 1)} (${phoneStr.substr(1, 3)}) ${phoneStr.substr(4, 3)}-${phoneStr.substr(7)}`;
          }
        });

        dispatch({
          type: types.USER_GET_ALL,
          users: items,
          totalCount,
          allStatus: 'loaded',
        });
      })
      .catch(e => {
        dispatch({
          type: types.USER_GET_ALL,
          allStatus: 'error',
        });
        dispatch({
          type: typesNotification.NOTIFICATION_NOTIFY,
          message: getSwaggerErrorResponse(e).error.message,
          messageType: 'error',
        });
      });
  };
}

export function create(data) {
  return function (dispatch, getState) {
    dispatch({
      type: types.USER_CREATE,
      status: 'loading',
    });
    userServiceProxy.createUser(data)
      .then(() => {
        dispatch({
          type: types.USER_CREATE,
          status: 'saved',
        });
      })
      .catch(e => {
        dispatch({
          type: types.USER_CREATE,
          allStatus: 'error',
        });
        dispatch({
          type: typesNotification.NOTIFICATION_NOTIFY,
          message: getSwaggerErrorResponse(e).error.message,
          messageType: 'error',
        });
      });
  };
}

export function update(data) {
  return function (dispatch) {
    dispatch({
      type: types.USER_UPDATE,
      status: 'loading',
    });
    userServiceProxy.updateUser(data)
      .then(() => {
        if (data.permissionNames) {
          userServiceProxy.updateUserPermissions({ userId: data.id, grantedPermissionNames: data.permissionNames })
            .then(() => {
              dispatch({
                type: types.USER_UPDATE,
                status: 'saved',
              });
            })
            .catch(e => {
              dispatch({
                type: types.USER_UPDATE,
                allStatus: 'error',
              });
              dispatch({
                type: typesNotification.NOTIFICATION_NOTIFY,
                message: getSwaggerErrorResponse(e).error.message,
                messageType: 'error',
              });
            });
        } else {
          dispatch({
            type: types.USER_UPDATE,
            status: 'saved',
          });
        }
      })
      .catch(e => {
        dispatch({
          type: types.USER_UPDATE,
          allStatus: 'error',
        });
        dispatch({
          type: typesNotification.NOTIFICATION_NOTIFY,
          message: getSwaggerErrorResponse(e).error.message,
          messageType: 'error',
        });
      });
  };
}

export function updateUserPermissions(userId, grantedPermissionNames) {
  return function (dispatch) {
    dispatch({
      type: types.USER_UPDATE_PERMISSIONS,
      status: 'loading',
    });
    userServiceProxy.updateUserPermissions({ userId, grantedPermissionNames })
      .then(() => {
        dispatch({
          type: types.USER_UPDATE_PERMISSIONS,
          status: 'saved',
        });
      })
      .catch(e => {
        dispatch({
          type: types.USER_UPDATE_PERMISSIONS,
          allStatus: 'error',
        });
        dispatch({
          type: typesNotification.NOTIFICATION_NOTIFY,
          message: getSwaggerErrorResponse(e).error.message,
          messageType: 'error',
        });
      })
      .catch(e => {
        dispatch({
          type: types.USER_UPDATE_PERMISSIONS,
          allStatus: 'error',
        });
        dispatch({
          type: typesNotification.NOTIFICATION_NOTIFY,
          message: getSwaggerErrorResponse(e).error.message,
          messageType: 'error',
        });
      });
  };
}

export function deleteUser(userId) {
  return function (dispatch) {
    dispatch({
      type: types.USER_DELETE,
      status: 'loading',
    });
    return userServiceProxy.deactivateUser(userId)
      .then(data => {
        dispatch({
          type: types.USER_DELETE,
          status: 'loaded',
        });
      })
      .catch(e => {
        dispatch({
          type: types.USER_DELETE,
          allStatus: 'error',
        });
        dispatch({
          type: typesNotification.NOTIFICATION_NOTIFY,
          message: getSwaggerErrorResponse(e).error.message,
          messageType: 'error',
        });
      });
  };
}

export function reactivateUser(id) {
  return function (dispatch) {
    dispatch({
      type: types.USER_REACTIVATE,
      allStatus: 'loading',
    });
    userServiceProxy.reactivateUser({ id })
      .then(() => {
        dispatch({
          type: types.USER_REACTIVATE,
          allStatus: 'loaded',
        });
      })
      .catch(e => {
        dispatch({
          type: types.USER_REACTIVATE,
          allStatus: 'error',
        });
        dispatch({
          type: typesNotification.NOTIFICATION_NOTIFY,
          message: getSwaggerErrorResponse(e).error.message,
          messageType: 'error',
        });
      });
  };
}

export function getUserPermissions(userId) {
  return function (dispatch) {
    return userServiceProxy.getUserPermissions(userId).then(data => {
      dispatch({
        type: types.USER_GET_PERMISSIONS,
        permissions: data.items,
      });
      return data.items;
    }).catch(e => {
      dispatch({
        type: types.USER_GET_PERMISSIONS,
        permissions: [],
      });
      dispatch({
        type: typesNotification.NOTIFICATION_NOTIFY,
        message: getSwaggerErrorResponse(e).error.message,
        messageType: 'error',
      });
    });
  };
}

export function getUserOverrideLocations(userId) {
  return function (dispatch) {
    dispatch({
      type: types.USER_GET_OVERRIDE_LOCATIONS,
      addressOverridesStatus: 'loading',
    });
    return userServiceProxy.getUserOverridedLocations(userId).then(data => {
      dispatch({
        type: types.USER_GET_OVERRIDE_LOCATIONS,
        addressOverrides: data.items,
        addressOverridesStatus: 'loaded'
      });
      return data.items;
    }).catch(e => {
      dispatch({
        type: types.USER_GET_OVERRIDE_LOCATIONS,
        addressOverrides: [],
        addressOverridesStatus: 'error',
      });
      dispatch({
        type: typesNotification.NOTIFICATION_NOTIFY,
        message: getSwaggerErrorResponse(e).error.message,
        messageType: 'error',
      });
    });
  };
}

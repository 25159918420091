import * as React from 'react';
import * as Yup from "yup";

import { EditClientInvoiceItemDto } from '../../../service-proxies';

import {
  Button,
  Modal,
  Textarea,
} from '../../../components';

import {
  getMaxTextLengthMessage,
} from '../../../_utils/validation';

interface IClientInvoicesModalProps {
  item: EditClientInvoiceItemDto;
  onClickReject: () => void;
  onClickResolve: (note: string) => void;
}

interface IClientInvoicesModalState {
  value: string;
  errors?: {
    value?: string;
  };
  isValid: boolean;
}

export default class extends React.Component<IClientInvoicesModalProps, IClientInvoicesModalState> {

  private validationScheme = Yup.object().shape({
    value: Yup.string()
      .max(250, getMaxTextLengthMessage(250))
      .nullable(),
  });

  constructor(props) {
    super(props);
    this.state = {
      value: '',
      errors: {},
      isValid: true,
    };

    this.validate = this.validate.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleBlur = this.handleBlur.bind(this);
    this.handleResolve = this.handleResolve.bind(this);
  }

  componentDidMount() {
    this.setState({
      value: this.props.item.zeroPriceNote,
    });
  }

  async validate() {
    try {
      await this.validationScheme.validate(this.state);
      this.setState({ errors: {}, isValid: true });
    } catch (e) {
      const newState = Object.assign({}, this.state);
      newState.errors[e.path] = e.message;
      this.setState({ ...newState, isValid: false });
    }
  }

  handleChange(e) {
    const value = e.target.value;
    this.setState({ value }, () => {
      this.validate();
    });
  }

  handleBlur(e) {
    const value = e.target.value;
    this.setState({ value }, () => {
      this.validate();
    });
  }

  async handleResolve() {
    await this.validate();
    if (this.state.isValid) {
      this.props.onClickResolve(this.state.value);
    }
  }

  render() {
    return <React.Fragment>
      <Modal.Body>
        <Textarea
          value={this.state.value}
          label="Message"
          placeholder="Optional"
          onChange={this.handleChange}
          onBlure={this.handleChange}
          error={this.state.errors.value}
        />
      </Modal.Body>
      <Modal.Footer>
        <Modal.FooterElement />
        <Modal.FooterElement>
          <Button primary onClick={this.props.onClickReject}>Cancel</Button>
          <Button onClick={this.handleResolve}>Submit</Button>
        </Modal.FooterElement>
      </Modal.Footer>
    </React.Fragment >;
  }
}
import React from 'react';
import styled from 'styled-components';

import {
  TriangleIcon,
} from '../assets/icons';
import { IWindowWithResize } from '../_types/common';

export const MoreButton = styled.div`
  flex-wrap: nowrap;
  white-space: nowrap;
  margin: 0 10px;
  display: flex;
  align-items: center;
  height: 100%;
  text-transform: uppercase;
`;

export const MoreList = styled.div`
  ${props => {
    const {
      global,
      main,
    } = props.theme;
    return `
      ${global}
      position: absolute;
      display: none;
      width: 148px;
      padding: 10px 0;
      box-shadow: 0 0 10px 0 rgba(0,0,0,0.1);
      background: ${main};
      right: 0;
      z-index: 1;
    `;
  }}
`;

export const MoreListItem = styled.div``;

export interface IMoreProps {
  className?: string;
  empty: boolean;
}

const More = styled<IMoreProps>(({ className, children }) => {
  return <div className={className}>
    <MoreButton>More <TriangleIcon /></MoreButton>
    <MoreList>{children}</MoreList>
  </div>;
})`
  ${({ theme, ...props }) => {

    const {
      global,
      light,
      fs_xs,
    } = theme;

    const visibility = props.empty ?
      'hidden' :
      'visible';

    return `
      ${global}
      font-size: ${fs_xs};
      color: ${theme.default};
      visibility: ${visibility};
      position: relative;
      
      i{
        transform: rotate(-90deg);
        margin-left: 15px;
        svg{
          path{
            fill: ${light};
          }
        }
      }
    `;
  }}
    
  &:hover{
    ${MoreList}{
      display: block;
    }  
  }

  ${MoreList}{
    ${props => {
    const {
      fs_xs,
    } = props.theme;
    return `
        a{
          font-size: ${fs_xs};
          font-weight: 500;
          border: none;
          display: block;
        }
      `;
  }}
  }

`;

const Menu = styled.div`
  flex: 1 1 100%;
`;

const MenuItem = styled.div`
  display: inline-block;
`;

export interface IHorizontalMenuProps {
  className?: string;
}

export interface IHorizontalMenuState {
  items: object[];
  extrudedItems: object[];
}

class HorizontalMenu extends React.Component<IHorizontalMenuProps, IHorizontalMenuState> {

  private originalItems = [];
  private container;
  private itemsSumWidth;
  private beforeBorderItemsQueue;
  private afterBorderItemsQueue;
  private resizeObserver;

  constructor(props) {
    super(props);

    this.originalItems = props.children;

    this.state = {
      items: this.originalItems,
      extrudedItems: [],
    };

    this.container = React.createRef();

    this.itemsSumWidth = 0;
    this.beforeBorderItemsQueue = [];
    this.afterBorderItemsQueue = [];

    const Window: IWindowWithResize = window as IWindowWithResize;
    this.resizeObserver = new Window.ResizeObserver(this.updateDimensions.bind(this));
  }

  componentDidMount() {
    this.updateDimensions();
    this.resizeObserver.observe(this.container.current);
  }

  componentDidUpdate() {
    this.itemsSumWidth = 0;
  }

  componentWillUnmount() {
    this.resizeObserver.unobserve(this.container.current);
  }

  updateDimensions() {
    this.setState({
      items: this.originalItems && this.originalItems.slice(0),
    });
  }

  init(itemWidth, index) {

    const container = this.container.current;

    if (container) {
      this.itemsSumWidth += itemWidth;

      const isSomeItemOutOfBorder = container.offsetWidth <= this.itemsSumWidth;
      const isAllItemsBeforeContainerBorder = container.offsetWidth > this.itemsSumWidth;
      const isLastItemInContainer = (index) === (this.originalItems.length - 1);

      if (isSomeItemOutOfBorder) {

        const beforeBorderItems = this.originalItems && this.originalItems.slice(0, index);
        const afterBorderItems = this.originalItems && this.originalItems.slice(index, this.originalItems.length);

        this.beforeBorderItemsQueue.push(beforeBorderItems);
        this.afterBorderItemsQueue.push(afterBorderItems);

        if (isLastItemInContainer) {

          const items = this.beforeBorderItemsQueue[0];
          const extrudedItems = this.afterBorderItemsQueue[0];

          this.beforeBorderItemsQueue = [];
          this.afterBorderItemsQueue = [];

          this.setState({
            items,
            extrudedItems
          });
        }
      } else if (isAllItemsBeforeContainerBorder) {

        const isExtruded = !this.state.extrudedItems.length;

        if (isLastItemInContainer && !isExtruded) {
          this.setState({ extrudedItems: [] });
        }
      }
    }
  }

  render() {
    return (
      <div className={this.props.className}>
        <Menu innerRef={this.container}>
          {
            this.state.items &&
            this.state.items.map((item, index) => {
              return <MenuItem
                key={`${index}`}
                innerRef={itemRef => itemRef && this.init(itemRef.offsetWidth, index)}
              >
                {item}
              </MenuItem>;
            })
          }
        </Menu>
        <More empty={!this.state.extrudedItems.length}>
          {
            !!this.state.extrudedItems.length &&
            this.state.extrudedItems.map((item, index) => {
              return <MoreListItem key={index}>{item}</MoreListItem>;
            })
          }
        </More>
      </div>
    );
  }
}

export default styled(HorizontalMenu)`
  display: flex;
  height: 100%;
`;

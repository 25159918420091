import React from 'react';
import styled from 'styled-components';

export default styled(props => {
  return (
    <i {...props}>
      <svg viewBox="909 20823 19.994 16" width="19.994" height="16">
        <path d=" M 911 20823 C 909.895 20823 909 20823.895 909 20825 C 909 20825 909 20837 909 20837 C 909 20837.828 909.5 20838.539 910.219 20838.844 C 910.227 20838.848 910.242 20838.84 910.25 20838.844 C 910.363 20838.891 910.473 20838.945 910.594 20838.969 C 910.727 20839 910.852 20839 911 20839 C 911 20839 925 20839 925 20839 C 925.285 20839 925.566 20838.953 925.813 20838.844 C 926.605 20838.539 927.301 20837.828 927.5 20837 C 927.5 20837 928.938 20831 928.938 20831 C 929.203 20829.895 928.512 20829 927.406 20829 Q 927.406 20829 913 20829 L 913 20828 L 927 20828 Q 927 20827 927 20827 C 927 20825.895 926.105 20825 925 20825 Q 925 20825 918 20825 L 916 20823 Q 911 20823 911 20823 Z " />
      </svg>
    </i>
  );
})`
  ${ props => `
    width: 21px;
    height: 21px;
    display: inline-flex;
    justify-content: center;
    align-items: center;

    svg{
      path{
        fill: ${props.theme.primary}; 
      }
    }
  `}
`;

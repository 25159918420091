import React from 'react';
import styled, { StyledComponentClass } from 'styled-components';
import OriginalModal from 'react-modal';

import { IThemeProps } from '../assets/theme';

import {
  Link,
  Button,
} from '../components';

import {
  XIcon,
} from '../assets/icons';

export interface IOptions {
  noBackdrop?: boolean;
  title?: string;
  question?: string;
  hideDecline?: boolean;
  cancelText?: string;
  confirmText?: string;
}

export interface IModalProps extends IThemeProps {
  className?: string;
  options: IOptions;
  onDecline: () => void;
  onConfirm: () => void;
  preloader: boolean;
  width?: number;
  isOpen?: boolean;
  id?: string;
}

interface IPortalProps extends  React.HTMLProps<HTMLDivElement> {}
interface IHeaderProps extends IThemeProps {}
interface IFooterProps extends IThemeProps {}
interface IFooterElementProps extends IThemeProps {}
interface IBodyProps extends IThemeProps {}
interface ITitleProps extends IThemeProps {}
interface IButtonsWrapProps extends IThemeProps {}

export interface INestedComponents extends StyledComponentClass<IModalProps, any> {
  Portal: StyledComponentClass<IPortalProps, any>;
  Header: StyledComponentClass<IHeaderProps, any>;
  Footer: StyledComponentClass<IFooterProps, any>;
  FooterElement: StyledComponentClass<IFooterElementProps, any>;
  Body: StyledComponentClass<IBodyProps, any>;
  Title: StyledComponentClass<ITitleProps, any>;
  ButtonsWrap: StyledComponentClass<IButtonsWrapProps, any>;
}

export interface IPortal {
  id?: string;
}

const Portal = styled.div<IPortalProps>`
  .ReactModal__Overlay{
    background-color: rgba(0, 0, 0, 0.5);
  }
`;

const Header = styled.header<IHeaderProps>`
  ${({ theme }) => {
    const {
      global,
      primary,
      lighter,
    } = theme;
    return `
      ${global}
      padding: 25px 20px;
      display: flex;
      justify-content: space-between;
      border-bottom: 1px solid ${lighter};
      i{
        svg{
          path{
            fill: ${primary}; 
          }
        }
      }
    `;
  }}
`;

const Footer = styled.footer<IFooterProps>`
  ${({ theme }) => {
    const {
      global,
      lighter,
    } = theme;
    return `
      ${global}
      padding: 20px 10px 25px;
      display: flex;
      justify-content: space-between;
      border-top: 1px solid ${lighter};
      align-items: center;
    `;
  }}
`;

const FooterElement = styled.footer<IFooterElementProps>`
  & > * {
    margin: 0 10px;
  }
`;

const Body = styled.div<IBodyProps>`
  padding: 20px;
  flex: 1 1 auto;
  overflow: auto;
`;

const Title = styled.div<ITitleProps>`
  font-size: 1.3em;
  font-weight: bold;
`;

const ButtonsWrap = styled.div<IButtonsWrapProps>`
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: flex-end;
  button {
    margin-left: 10px;
  }
`;

class ModalComponent extends React.Component<IModalProps> {
  static Portal = Portal;
  static Header = Header;
  static Footer = Footer;
  static FooterElement = FooterElement;
  static Body = Body;
  static Title = Title;
  static ButtonsWrap = ButtonsWrap;

  render() {
    const {
      className,
      options,
      onDecline,
      onConfirm,
      preloader,
      children,
      ...modalProps
    } = this.props;

    return <OriginalModal
      className={className}
      {...modalProps}
      shouldCloseOnOverlayClick={options ? !options.noBackdrop : true}
      parentSelector={() => document.getElementById('modalPlace')}
      ariaHideApp={false}
      onRequestClose={onDecline}
      style={{
        overlay: {
          backgroundColor: null,
        },
        content: {
          top: null,
          left: null,
          right: null,
          bottom: null,
          border: null,
          background: null,
          borderRadius: null,
          outline: null,
          padding: null,
        }
      }}
    >
      {
        <Header>
          <Title>
            {options && options.title ? options.title : ' '}
          </Title>
          {!options.noBackdrop &&
            <Link.Button onClick={onDecline}>
              <XIcon />
            </Link.Button>
          }
        </Header>
      }
      {
        children ?
          children :
          <React.Fragment>
            <Body>
              {options && options.question ? options.question : 'Do you realy want delete this item?'}
            </Body>
            <Footer>
              <FooterElement />
              <FooterElement>
                <ButtonsWrap>
                  {(!options || !options.hideDecline) &&
                    <Button
                      primary
                      onClick={onDecline}
                      disabled={preloader}>
                      {(options && options.cancelText) ? options.cancelText : 'Cancel'}
                    </Button>
                  }
                  <Button
                    onClick={onConfirm}
                    preloader={preloader}
                    disabled={preloader}>
                    {(options && options.confirmText) ? options.confirmText : 'Confirm'}
                  </Button>
                </ButtonsWrap>
              </FooterElement>
            </Footer>
          </React.Fragment>
      }
    </OriginalModal>;
  }
}

const Modal = styled(ModalComponent)<IModalProps>`
  ${({ theme, width }) => `
    ${theme.global}
    & {
      position: fixed;
      right: 0;
      left: 0;
      margin: auto;
      width: ${width || '500px'};
      max-height: 100%;
      max-width: 100%;
      top: 50%;
      transform: translateY(-50%);
      outline: none;
      background: ${theme.main};
      display: flex;
      flex-direction: column;
    }
  `}
`;

export default Modal as INestedComponents;
type minMax = 'max' | 'min';
export function getDecimalCount(tier: minMax, value: number): number {
  if (value !== 0) {
    if (tier === 'min') {
      return parseInt(Array(value - 1).fill(9).toString(), 10);
    }
    if (tier === 'max') {
      return parseInt(Array(value).fill(9).toString(), 10);
    }
    return 0;
  } else {
    return 0;
  }
}

// symbols length messages
export function getLengthMessage(value: number, subject: string): string {
  return `Entry must contain ${value} ${subject}`;
}

export function getNumberLengthMessage(value: number): string {
  return getLengthMessage(value, 'decimals');
}

export function getTextLengthMessage(value: number): string {
  return getLengthMessage(value, 'characters');
}

// min messages
export function getMinLengthMessage(value: number, subject: string): string {
  return `Entry must contain more than ${--value} ${subject}`;
}

export function getMinNumberLengthMessage(value: number): string {
  return getMinLengthMessage(value, 'decimals');
}

export function getMinTextLengthMessage(value: number): string {
  return getMinLengthMessage(value, 'characters');
}

// max messages
export function getMaxLengthMessage(value: number, subject: string): string {
  return `Entry must contain less than ${++value} ${subject}`;
}

export function getMaxNumberLengthMessage(value: number): string {
  return getMaxLengthMessage(value, 'decimals');
}

export function getMaxTextLengthMessage(value: number): string {
  return getMaxLengthMessage(value, 'characters');
}

// min number
export function getMinNumberValue(min: number): string {
  return `Entry must be more than ${min}`;
}

// max number
export function getMaxNumberValue(min: number): string {
  return `Entry must be less than ${min}`;
}

// at least
export function getAtLeastMessage(value: number, subject: string): string {
  return `must have at least ${value} ${subject}`;
}







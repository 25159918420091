import * as React from 'react';
import { Switch, NavLink, Redirect } from 'react-router-dom';
import { RouteComponentProps, withRouter } from 'react-router';
import withGranted, { IWithGrantedProps } from '../HOC/WithGranted';
import { IRouteEntry } from '../../_types/common';
import RoutesMapper from '../Router/RoutesMapper';
import Products from '../Products/Products';
import MasterProducts from '../Products/ProductsMaster';
import OrdersRoutes from '../MyOrders/Routes';
import NotFound from '../NotFound/NotFound';
import MenuSidebar from '../MenuSidebar/MenuSidebar';
import Admin from '../Admin/Admin';
import ClientOrdersRoutes from '../ClientsOrders/Routes';
import InvoicesRoutes from '../Invoices/Routes';
import ReconcileOrders from '../PackingSlipReconciliation/Routes';
import ClientInvoicesRoutes from '../ClientInvoices/Routes';

import {
  ADMINISTRATION,
  CLIENTCATALOGUE,
  MASTERCATALOGUE,
  ORDERS_MYORDERS,
  ORDERS_BUYERORDERS,
  INVOICES,
  PACKINGSLIPS,
  CLIENTINVOICES,
  ORDERS_UNCOMPARED,
  ORDERS_SUBMIT,
  COMPANYPACKINGSLIPS,
  COMPANYPACKINGSLIPS_EDITORRECONCILE,
} from '../../_constants/permissions';

import {
  MainMenu,
  HorizontalMenu,
} from '../../components';
import PurchaseHistory from "../Products/PurchaseHistory";


const Navigation = (props: IWithGrantedProps) => (
  <MenuSidebar>
    <MainMenu>
      <HorizontalMenu>
        {
          (props.isGranted(MASTERCATALOGUE) || props.isGranted(CLIENTCATALOGUE)) &&
          <NavLink to={`/products`}>products</NavLink>
        }
        {/*<NavLink to={`/vendors`}>vendors</NavLink>
          <NavLink to={`/photos`}>photos</NavLink>*/}
        {/* <NavLink to={`/reports`}>reports</NavLink> */}
        {/*<NavLink to={`/finance`}>finance</NavLink>*/}
        {
          props.isGranted(MASTERCATALOGUE) &&
          <NavLink to={`/master-products`}>master catalog</NavLink>
        }
        <NavLink to={`/purchase-history`}>purchase history</NavLink>
      </HorizontalMenu>
    </MainMenu>
  </MenuSidebar>
);

const routes: IRouteEntry[] = [
  {
    absolutePath: '/products',
    exact: false,
    render: (props) => (
      <React.Fragment>
        <Navigation {...props} />
        <Products {...props} />
      </React.Fragment>),
    someOfPermissions: [MASTERCATALOGUE, CLIENTCATALOGUE],
  },
  {
    absolutePath: '/master-products',
    exact: false,
    render: (props) => (
      <React.Fragment>
        <Navigation {...props} />
        <MasterProducts {...props} />
      </React.Fragment>),
    permission: MASTERCATALOGUE,
  },
  {
    absolutePath: '/purchase-history',
    exact: false,
    render: (props) => (
      <React.Fragment>
        <Navigation {...props} />
        <PurchaseHistory {...props} />
      </React.Fragment>),
    someOfPermissions: [MASTERCATALOGUE, CLIENTCATALOGUE],
  },
  {
    absolutePath: '/admin',
    exact: false,
    component: Admin,
    permission: ADMINISTRATION,
  },
  {
    absolutePath: '/orders',
    exact: false,
    component: OrdersRoutes,
    someOfPermissions: [ORDERS_SUBMIT, ORDERS_MYORDERS, ORDERS_BUYERORDERS],
  },
  {
    absolutePath: '/clients-orders',
    exact: false,
    component: ClientOrdersRoutes,
    someOfPermissions: [ORDERS_UNCOMPARED],
  },
  {
    absolutePath: '/invoices',
    exact: false,
    component: InvoicesRoutes,
    permission: INVOICES,
  },
  {
    absolutePath: '/packing-slip-reconciliation',
    exact: false,
    component: ReconcileOrders,
    someOfPermissions: [COMPANYPACKINGSLIPS_EDITORRECONCILE, COMPANYPACKINGSLIPS, PACKINGSLIPS],
  },
  {
    absolutePath: '/client-invoices',
    exact: false,
    component: ClientInvoicesRoutes,
    permission: CLIENTINVOICES,
  },
  {
    absolutePath: '*',
    exact: false,
    component: NotFound,
    permission: null,
  },
];

class MainView extends React.Component<RouteComponentProps<{}> & IWithGrantedProps> {

  allowedRoutePath: string;

  constructor(props) {
    super(props);
    const firstAllowedRoute = routes.find(route => (
      (route.someOfPermissions ? props.isSomeGranted(route.someOfPermissions) : props.isGranted(route.permission))));
    this.allowedRoutePath = firstAllowedRoute ? (firstAllowedRoute.path || firstAllowedRoute.absolutePath) : '';
  }

  render() {
    return (
      <React.Fragment>
        <Switch>
          <Redirect exact from="/" to={`${this.allowedRoutePath}`} />
          <RoutesMapper routes={routes} {...this.props} />
        </Switch>
      </React.Fragment>
    );
  }
}


export default withRouter(withGranted(MainView));

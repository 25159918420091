import * as React from 'react';
import { match } from 'react-router';

import * as urlParse from '../../_utils/urlParser';
import { updateBrunch } from '../../_utils/data';

import {
  SideMenu,
  Tree,
  Branch,
} from '../../components';
import { GetСategoriesDto } from '../../service-proxies';
import { history } from '../../store';
import { CategoryContextConsumer } from './Contexts/CategoryContext';

const ContrastTree = Tree.extend`
  ${({ theme }) => {
    const {
      fs_xs,
    } = theme;
    return `
      *{
        text-transform: uppercase;
        font-size: ${fs_xs};
      }
      ul > ul {
        *{
          text-transform: capitalize;
        }
        padding-left: 10px;
        margin: 2px 0;
      }
    `;
  }}
`;

interface ISubCategoriesProps extends IConnectedProps {
  match: match<any>;
  inner?: boolean;
  categories?: GetСategoriesDto[];
  totalCount?: number;
}

interface ISubCategoriesState {
  subCategories: GetСategoriesDto[];
}

class SubCategories extends React.Component<ISubCategoriesProps, ISubCategoriesState> {
  constructor(props) {
    super(props);
    this.state = {
      subCategories: [],
    };
    this.handleExpand = this.handleExpand.bind(this);
    this.getSubcategories = this.getSubcategories.bind(this);
    this.getParentPath = this.getParentPath.bind(this);
    this.handleParentCategoryClick = this.handleParentCategoryClick.bind(this);
  }

  componentDidMount() {
    const categoryId = this.props.match.params.categoryId;
    const categories = this.props.categories;
    const isCategories = !!categories.length;
    if (isCategories) {
      this.getSubcategories(categories, categoryId);
    }
  }

  componentDidUpdate(prevProps) {
    const categories = this.props.categories;
    const isCategories = !!categories.length;

    if (prevProps.totalCount !== this.props.totalCount) {
      this.getSubcategories(categories, this.props.match.params.categoryId);
    }

    if (isCategories) {
      const categoryId = this.props.match.params.categoryId;
      const prevCategoryId = prevProps.match.params.categoryId;

      if (categoryId && !this.isServiceSlug(categoryId)) {
        if (categoryId !== prevCategoryId) {
          this.getSubcategories(categories, categoryId);
        }
      }

      if (!categoryId) {
        if (categoryId !== prevCategoryId) {
          this.setState({
            subCategories: [],
          });
        }
      }
    }
  }

  getSubcategories(categories, categoryId) {
    if (categoryId) {
      const parentCategory = categories.find(category => category.id === parseInt(categoryId, 10));
      const subCategories = parentCategory && parentCategory.childCategories;
      this.setState({
        subCategories,
      });
    }
  }

  handleExpand(e, path) {

    const subCategories = updateBrunch(
      [...this.state.subCategories],
      path,
      object => object.expanded = !object.expanded,
      'childCategories'
    );

    this.setState({
      subCategories,
    });
  }

  handleParentCategoryClick(node, path) {
    const delimiter = /\/$/.test(this.props.match.url) ? '' : '/';
    const url = `${this.getParentPath()}${delimiter}${node.id}`;

    const subCategories = updateBrunch(
      [...this.state.subCategories],
      path,
      object => object.expanded = !object.expanded,
      'childCategories'
    );

    this.setState({
      subCategories,
    });

    history.push(url);
  }

  // help to concate parent path with new child index.
  // We cant match parent url, with exact route, so we need to get it manualy
  getParentPath() {
    let parentPath = this.props.match.url;
    if (this.props.match.params.subCategoryId) {
      parentPath = urlParse.reducePath(this.props.match.url, this.props.match.params.subCategoryId);
    }
    return parentPath;
  }

  isServiceSlug(slug) {
    const serviceSlugMap = [
      'product',
      'new',
      'edit',
    ];
    return serviceSlugMap.includes(slug);
  }

  renderSubCategories() {
    const delimiter = /\/$/.test(this.props.match.url) ? '' : '/';

    return (
      <CategoryContextConsumer>
        {
          (context) => <SideMenu inner={this.props.inner}>
            <ContrastTree
              compact
              cascade
              nodes={this.state.subCategories}
              childrenKey="childCategories"
              renderParent={props => <Branch
                cascade
                onExpand={this.handleExpand}
                label={
                  <SideMenu.Label
                    expanded={props.node.expanded}
                    active={props.node.id === parseInt(this.props.match.params.subCategoryId as any as string, 10)}
                    onClick={() => this.handleParentCategoryClick(props.node, props.path)}>
                    {props.node.name}
                  </SideMenu.Label>
                }
                {...props}
              />}
              renderChild={props => <Branch
                cascade
                to={`${this.getParentPath()}${delimiter}${props.node.id}`}
                {...props}
              />}
            />
          </SideMenu>
        }
      </CategoryContextConsumer>
    );
  }

  render() {
    return (
      <React.Fragment>
        <div />
        {
          !!(this.state.subCategories && this.state.subCategories.length) &&
          this.renderSubCategories()
        }
      </React.Fragment>
    );
  }
}

interface IConnectedProps {
  // categories: GetСategoriesDto[];
  // totalCount: number;
}

export default SubCategories;

import React from 'react';
import {connect} from "react-redux";
import {StyledComponentClass} from 'styled-components';
import {createStructuredSelector} from 'reselect';
import {SimpleMap} from '../../_types/common';
import { IGlobalStore } from 'src/_reducers/reducers';

interface IConnectedProps {
  permissionsMap: SimpleMap;
}

export interface IWithGrantedProps {
  isGranted: (permission: string) => boolean;
  isSomeGranted: (permissions: string[]) => boolean;
  isEveryGranted: (permissions: string[]) => boolean;
}

type AllowedType<P> = React.ComponentType<P & IWithGrantedProps> | StyledComponentClass<P & IWithGrantedProps, React.StatelessComponent<P & IWithGrantedProps>>;

export default function withGranted<P extends object>(Component: AllowedType<P>) {
  class WithGranted extends React.Component<P & IConnectedProps> {

    constructor(props) {
      super(props);
      this.isGranted = this.isGranted.bind(this);
      this.isSomeGranted = this.isSomeGranted.bind(this);
      this.isEveryGranted = this.isEveryGranted.bind(this);
    }

    isGranted(permission: string): boolean {
      return (!!this.props.permissionsMap && !!this.props.permissionsMap[permission]);
    }

    isSomeGranted(permissions: string[]): boolean {
      return permissions.some((permission) => {
        return (!!this.props.permissionsMap && !!this.props.permissionsMap[permission]);
      });
    }

    isEveryGranted(permissions: string[]): boolean {
      return permissions.every((permission) => {
        return (!!this.props.permissionsMap && !!this.props.permissionsMap[permission]);
      });
    }

    render() {
      return <Component
        {...this.props}
        isGranted={this.isGranted}
        isSomeGranted={this.isSomeGranted}
        isEveryGranted={this.isEveryGranted}
      />;
    }
  }

  return connect(
    createStructuredSelector<IGlobalStore, IConnectedProps>({
      permissionsMap: state => (state.currentUser && state.currentUser.permissions),
    }), null, null, {pure: false})(WithGranted);
}

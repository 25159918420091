import * as React from 'react';
import { withRouter, Switch, NavLink, Redirect } from 'react-router-dom';
import RoutesMapper from '../Router/RoutesMapper';
import InvoicesList from './List';
import InvoicesView from './View';
import ArchiveInvoiceList from './Archived/List';

import { IRouteEntry } from '../../_types/common';

import withGranted, { IWithGrantedProps } from '../HOC/WithGranted';
import { INVOICES } from '../../_constants/permissions';
import MenuSidebar from '../MenuSidebar/MenuSidebar';

import {
  MainMenu,
  HorizontalMenu,
} from '../../components';
import { RouteComponentProps } from "react-router";

const routes: IRouteEntry[] = [
  {
    path: '/my',
    exact: true,
    component: InvoicesList,
    permission: INVOICES,
  },
  {
    path: '/archived',
    exact: true,
    component: ArchiveInvoiceList,
    permission: INVOICES
  },
  {
    path: '/my/:invoiceId',
    exact: true,
    component: InvoicesView,
    permission: INVOICES,
  }

];

class OrderRoutes extends React.Component<IWithGrantedProps & RouteComponentProps<{}>> {

  allowedRoutePath: string;

  constructor(props) {
    super(props);
    const firstAllowedRoute = routes.find(route => (!route.excludeAsDefaultRoute && route.path && props.isGranted(route.permission)));
    this.allowedRoutePath = firstAllowedRoute ? firstAllowedRoute.path : '';
  }
  render() {
    const { match: { url }, isGranted } = this.props;
    return (
      <React.Fragment>
        <MenuSidebar>
          <MainMenu>
            <HorizontalMenu>
            {isGranted(INVOICES) && <NavLink to="/invoices/my">Invoices</NavLink>}
            {isGranted(INVOICES) && <NavLink to="/invoices/archived">Archived Invoices</NavLink>}
            </HorizontalMenu>
          </MainMenu>
        </MenuSidebar>        
        <Switch>
        <Redirect exact from={`${url}`} to={this.allowedRoutePath ? `${url}${this.allowedRoutePath}` : '/'} />
          <RoutesMapper routes={routes} addNotFound {...this.props} />
        </Switch>
      </React.Fragment>
    );
  }
}

export default withGranted(withRouter(OrderRoutes));

export function reducePath(path, reducer) {

  let newPath;

  let parts = path.split('/').filter(item => {
    return item !== '';
  });

  if (reducer) {
    var index = parts.lastIndexOf(reducer);
    if (index !== -1) { parts.splice(index, 1); }
  }

  newPath = '/' + parts.join('/');

  return newPath;
}

export function cutRest(path, reducer) {
  let newPath;

  let parts = path.split('/').filter(item => {
    return item !== '';
  });

  if (reducer) {
    var index = parts.lastIndexOf(reducer);
    if (index !== -1) { parts.splice(index + 1, parts.length); }
  }

  newPath = '/' + parts.join('/');

  return newPath;
}
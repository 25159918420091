import * as React from "react";
import styled from "styled-components";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { apiUrl } from "../../_constants/system";
import moment from "moment";

import { SearchOutputDto, EditMyOrderDto } from "../../service-proxies";

import withGranted, { IWithGrantedProps } from "../HOC/WithGranted";
import { IGlobalStore } from "../../_reducers/reducers";
import { notify, notifySwagger } from "../../_actions/notification";
import { history } from "../../store";
import {
  MainContainer,
  Table,
  Skeleton,
  Thumbnail,
  Link,
  Article,
  TabsSwitch
} from "../../components";
import { IMatch } from "../../_types/common";
import withMenu from "../../containers/HOC/WithMenu";
import Title from "../../sharedComponents/Title";
import { getMyOrderForEdit } from "../../_api/orders";
import { formatPrice, roundAndFormatPrice } from "../../_utils/prices";
import { removeHashSign } from "src/_utils/misc";

// positions

const Position = {
  Tabs: styled.div`
    display: flex;
    width: 100%;
    padding: 20px;
    ${Skeleton.Line} {
      margin: 4px 20px 0 0;
    }
  `,

  Content: styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
  `,

  Body: styled.div`
    height: 100%;
  `,

  TableBody: styled.div`
    height: 100%;
  `,

  Footer: styled.div`
    display: flex;
    justify-content: space-between;
    padding: 20px 20px 30px;
  `,

  Info: styled.div`
    text-align: right;
    padding: 0 20px 20px;
  `,

  FooterElement: styled.div`
    > * {
      margin-left: 10px;
    }
  `,

  RightContainer: styled.div`
    text-align: right;
    height: 100%;
    width: 100%;
  `
};

// interfaces
interface IViewProps extends IConnectedActions, IWithGrantedProps {
  match: IMatch;
  locationId: number;
  locationName: string;
  catalogId: number;
  handleTabChange: (tab: string) => void;
  notifySwagger: typeof notifySwagger;
  order?: EditMyOrderDto;
}

interface ITabItem {
  value: string;
  label: string;
}

interface IViewState {
  isChanged?: boolean;
  orderStatus: string;
  location?: SearchOutputDto;
  message?: string;
  notesId?: number;
  order?: EditMyOrderDto;
  id?: number;
  notes: SearchOutputDto[];
  status: string;
  tabs: ITabItem[];
  tab: string;
}

class View extends React.Component<IViewProps, IViewState> {
  constructor(props) {
    super(props);

    this.state = {
      orderStatus: "loading",
      message: "",
      isChanged: false,
      notes: [],
      status: "",
      tabs: [
        { value: "details", label: "Details" }
        /* { value: 'edits', label: 'Edites' },*/
      ],
      tab: "details"
    };

    this.getOrder = this.getOrder.bind(this);
    this.handleClickBack = this.handleClickBack.bind(this);
    this.handleTabChange = this.handleTabChange.bind(this);
  }

  componentDidMount() {
    this.getOrder();
  }

  getOrder(): void {
    this.setState({ orderStatus: "loading" });

    getMyOrderForEdit(this.props.match.params.orderId)
      .then(orderData => {
        this.setState({
          orderStatus: "loaded",
          order: orderData,
          location: orderData.location,
          id: this.props.match.params.orderId
        });
      })
      .catch(e => {
        this.props.notifySwagger(e, "error");
        history.push("/notFound");
      });
  }

  handleClickBack(): void {
    history.goBack();
  }

  handleTabChange(tab: string): void {
    this.setState({ tab });
  }

  renderPreloaderRows(perPage: number, status?: string): React.ReactNode {
    const rows = [];
    const elementsCount = status === "loading" ? perPage - 1 : 0;

    for (let i = 0; i < elementsCount; i++) {
      rows.push(
        <Table.Row
          key={"preloader" + i}
          rows={perPage}
          cells={[
            <Skeleton.Square key="thumb-preloader" />,
            <React.Fragment key="item-preloader">
              <p>
                <Skeleton.Line />
              </p>
              <p>
                <Skeleton.Line />
              </p>
              <p>
                <Skeleton.Line />
              </p>
            </React.Fragment>,
            <Skeleton.Line key="price-preloader" size="xs" />,
            <Skeleton.Line key="count-preloader" size="sm" />,
            <Skeleton.Line key="sub-price-preloader" size="xs" />
          ]}
        />
      );
    }
    return rows;
  }

  renderTabsPreloader() {
    return (
      <React.Fragment>
        <Skeleton.Line size="sm" key={`preloader-tab-1`} />
        <Skeleton.Line size="sm" key={`preloader-tab-2`} />
      </React.Fragment>
    );
  }

  render() {
    return (
      <MainContainer
        centered
        scale="md"
        renderTitle={
          <Title>
            {this.state.orderStatus === "loading" && (
              <React.Fragment>
                <Title.Element>
                  <Skeleton.Line />
                </Title.Element>
                <Title.Element>
                  <Skeleton.Line />
                </Title.Element>
                <Title.Element>
                  <Skeleton.Line />
                </Title.Element>
              </React.Fragment>
            )}
            {this.state.orderStatus === "loaded" && (
              <React.Fragment>
                <Title.Element>
                  Buyer:{" "}
                  {this.state.order.creatorUser &&
                    this.state.order.creatorUser.displayValue}
                </Title.Element>
                <Title.Element>
                  Order #: {removeHashSign(this.state.order.number)}
                </Title.Element>
                <Title.Element>
                  Date/Time:{" "}
                  {moment(this.state.order.creationTime).format(
                    "MM/DD/YYYY LT"
                  )}
                </Title.Element>
              </React.Fragment>
            )}
          </Title>
        }
      >
        <Position.Content>
          <Position.Body>
            <Position.Tabs>
              {this.state.orderStatus === "loaded" && (
                <TabsSwitch
                  items={this.state.tabs}
                  onTabChanged={this.handleTabChange}
                />
              )}
              {this.state.orderStatus === "loading" &&
                this.renderTabsPreloader()}
            </Position.Tabs>
            <Table
              columns={[
                { name: "thumb", label: "", size: "xs" },
                { name: "item", label: "item", size: "xl" },
                {
                  name: "price",
                  label: "price",
                  size: "md",
                  alignment: "right"
                },
                { name: "quantity", label: "quantity", alignment: "right" },
                { name: "subtotal", label: "subtotal", alignment: "right" }
              ]}
            >
              <Table.Header />
              <Table.Body>
                {this.state.orderStatus === "loaded" &&
                  this.state.order.items.map((orderItem, index) => {
                    return (
                      <Table.Row
                        key={`order-product-${index}`}
                        cells={[
                          <Thumbnail
                            image={
                              orderItem.product.picture
                                ? apiUrl + orderItem.product.picture.pictureUrl
                                : null
                            }
                            key={`thumbnail-${index}`}
                          />,
                          <React.Fragment key={`item-${index}`}>
                            <p>
                              {" "}
                              <span style={{ color: "#000" }}>
                                <Link
                                  key={`item-${index}`}
                                  primary
                                  to={`/products/product/${
                                    orderItem.product.id
                                  }`}
                                >
                                  {orderItem.product.name}
                                </Link>
                              </span>
                            </p>
                            <p>
                              <span style={{ color: "#000" }}>
                                {orderItem.product.sku}
                              </span>
                            </p>
                          </React.Fragment>,
                          formatPrice(orderItem.price),
                          `${orderItem.quantity}`,
                          roundAndFormatPrice(
                            orderItem.price * orderItem.quantity
                          )
                        ]}
                      />
                    );
                  })}
                {this.state.orderStatus === "loading" &&
                  this.renderPreloaderRows(6, this.state.orderStatus)}
                <Table.Row
                  cells={[
                    "",
                    "",
                    "",
                    <Position.RightContainer
                      key={`order-product-price-total-label`}
                    >
                      <Article.P light scale="lg">
                        TOTAL:
                      </Article.P>
                    </Position.RightContainer>,
                    <React.Fragment key={`order-product-price-total-label`}>
                      {this.state.orderStatus === "loaded" && (
                        <Article.P scale="lg">
                          {roundAndFormatPrice(
                            this.state.order.items.reduce(
                              (result, orderItem) =>
                                result + orderItem.price * orderItem.quantity,
                              0
                            )
                          )}
                        </Article.P>
                      )}
                    </React.Fragment>
                  ]}
                />
              </Table.Body>
            </Table>
            <Position.Info>
              {this.state.orderStatus === "loaded" && this.state.location ? (
                <Article.P scale="sm">
                  Facility Location: {this.state.location.displayValue}
                </Article.P>
              ) : null}
            </Position.Info>
          </Position.Body>
          <Position.Footer>
            <Position.FooterElement />
            <Position.FooterElement>
              <Link.Button
                onClick={this.handleClickBack}
                preloader={
                  this.state.orderStatus === "loading" ||
                  this.state.orderStatus === "saving"
                }
              >
                Back
              </Link.Button>
            </Position.FooterElement>
          </Position.Footer>
        </Position.Content>
      </MainContainer>
    );
  }
}

interface IConnectedProps {
  locationId: number;
  locationName: string;
  catalogId: number;
}

interface IConnectedActions {
  notify: typeof notify;
  notifySwagger: typeof notifySwagger;
}

export default withMenu(
  connect(
    createStructuredSelector<IGlobalStore, IConnectedProps>({
      locationId: state => state.currentUser.locationInfo.id,
      locationName: state => state.currentUser.locationInfo.name,
      catalogId: state => state.currentUser.catalogId
    }),
    {
      notify,
      notifySwagger
    }
  )(withGranted(View)),
  false
);

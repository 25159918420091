import * as React from 'react';
import { Fragment } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import OriginalSelect, { Async, Creatable, AsyncCreatable } from "react-select";
import 'react-select/dist/react-select.css';
import keycode from 'keycode';

import OriginalLabel from '../components/Label';
import Error from '../components/Error';
import Link from './Link';

import {
  CaretIcon,
  XIcon,
} from '../assets/icons';

export const CompactLabel = styled<{ error?: string, primary?: boolean, compact?: boolean }, 'label'>('label')`
  ${({ theme, ...props }) => {

    const {
      global,
      warning,
      primary,
      fs_md,
      lightest,
      light,
    } = theme;

    const borderColor = props.error ?
      warning :
      light;

    const color = props.error ?
      warning :
      primary;

    const borderWidth = props.primary ?
      '2px' :
      '1px';

    return `
      ${global}
      display: inline-flex;
      white-space: nowrap;
      flex-wrap: wrap;
      flex: 0 0 auto;
      min-height: 18px;

      font-size: ${fs_md};
      padding: 10px 22px;
      border-top-width: ${borderWidth};
      border-left-width: ${borderWidth};
      border-bottom-width: ${borderWidth};
      margin: 0;

      border-top-style: solid;
      border-left-style: solid;
      border-bottom-style: solid;

      color: ${color};
      background-color: ${lightest};
      border-color: ${borderColor};
    `;
  }}
`;

export const Suffix = styled.div`
  ${ ({ theme }) => {
    const {
      global,
      fs_md,
      primary,
    } = theme;
    return `
      ${global}
      font-size: ${fs_md};
      padding: 0 0 0 10px;
      color: ${primary};
      
      font-weight: 300;

      display: flex;
      align-items: center;
    `;
  }}
`;

interface IDeletableOptionProps {
  className?: string;
  onOptionDelete: (o: any) => any;
  option: any;
}

export const DeletableOptionLabel = styled.div`
  flex: 1 1 100%;
`;

export const DeletableOption = styled<IDeletableOptionProps>(({
  className,
  children,
  onOptionDelete,
  option,
}) => {

  function handleClick(e) {
    e.preventDefault();
    e.stopPropagation();
    onOptionDelete(option);
  }

  return <div className={className}>
    <DeletableOptionLabel>
      {children}
    </DeletableOptionLabel>
    {
      !option.className &&
      <Link.Button onMouseDown={handleClick}><XIcon /></Link.Button>
    }
  </div>;
})`
  display: flex;
  ${Link.Button}{
    ${({ theme }) => {
    const {
      primary,
    } = theme;
    return `
        flex: 0 0 8px;
        svg{
          margin: 0 0 0 10px;
          height: 8px;
          width: 8px;
          path{
            fill: ${primary};
          }
        }
      `;
  }}
  }
`;

interface ISelectDelegatorProps {
  labelKey?: string;
  valueKey?: string;
  clearable?: boolean;
  creatable?: boolean;
  deletable?: boolean;
  async?: boolean;
  placeholder?: string;
  onChange?: (value: any) => void;
  value: any;
  options?: any[];
  optionRenderer?: (o: any) => any;
  valueRenderer?: (o: any) => any;
  filterOptions?: (values: any[], inputValue: string) => void;
  isLoading?: boolean;
  loadOptions?: (value, cb: (e: any, data: { options: any[] }) => void) => void;
  loadOnFocus?: boolean;
  setSelectRef?: (ref: any) => void;
  searchable?: boolean;
  arrowRenderer?: () => any;
  onInputKeyDown?: (e: any) => void;
  isValidNewOption?: (value: { label: string }) => boolean;
  onOptionDelete?: (o: any) => any;
  maxOptionLength?: number;
  autoload?: boolean;
  name?: string;
  onNewOptionClick?: (option: any) => void;
  disabled?: boolean;
}

interface ISelectDelegatorState {
  optionsLoaded: boolean;
  options: any[];
  isLoading: boolean;
  noResultsText: string;
}

class SelectDelegator extends React.Component<ISelectDelegatorProps, ISelectDelegatorState> {
  static propTypes = {
    loadOnFocus: PropTypes.bool,
    loadOptions: PropTypes.func,
    onChange: PropTypes.func,
    searchable: PropTypes.bool,
  };

  static defaultProps = {
    searchable: true,
  };

  constructor(props) {
    super(props);

    if (props.loadOnFocus) {
      this.state = {
        optionsLoaded: false,
        options: [],
        isLoading: false,
        noResultsText: 'Not fount',
      };
    }

    this.handleOptionDelete = this.handleOptionDelete.bind(this);
  }

  async handleOptionDelete(option) {
    await this.props.onOptionDelete(option);
    this.setState({ isLoading: true });

    this.props.loadOptions(option.displayValue, (_, data) => {
      this.setState({
        optionsLoaded: true,
        options: data.options,
        isLoading: false,
      });
    });
  }

  renderSelectComponent = ({
    creatable,
    async,
    setSelectRef,
    deletable,
    onOptionDelete,
    ...restProps
  }) => {

    if (deletable) {
      restProps.optionRenderer = (option) => {
        return <DeletableOption
          onOptionDelete={this.handleOptionDelete}
          option={option}
        >
          {option[restProps.labelKey]}
        </DeletableOption>;
      };
      restProps.cache = false;
    }

    if (creatable && async) {
      // @ts-expect-error change
      return <AsyncCreatable {...restProps} />;
    } else if (async) {
      // @ts-expect-error change
      return setSelectRef ? <Async ref={setSelectRef} {...restProps} /> : <Async {...restProps} />;
    } else if (creatable) {
      return <Creatable {...restProps} />;
    } else {
      return <OriginalSelect {...restProps} />;
    }
  };

  unRequiredLoadOptions = (event) => {
    if (!this.state.optionsLoaded) {
      this.setState({ isLoading: true });

      this.props.loadOptions(event.target.value, (_, data) => {
        this.setState({
          optionsLoaded: true,
          options: data.options,
          isLoading: false,
        });
      });
    }
  };

  handleInputChange = (value) => {
    if (value && this.props.maxOptionLength && value.length > this.props.maxOptionLength) {
      this.setState({ noResultsText: 'Segment is too long' });
    } else if (value) {
      this.setState({ isLoading: true, noResultsText: 'Not found' });
      this.props.loadOptions(value, (_, data) => {
        this.setState({
          optionsLoaded: true,
          options: data.options,
          isLoading: false,
        });
      });
    } else {
      this.setState({ noResultsText: 'Start typing' });
    }
    return value;
  };

  resetSelectState = (event) => {
    this.setState({
      optionsLoaded: false,
      isLoading: false,
      noResultsText: 'Not found',
    });
  };

  render() {
    return <Fragment>
      {
        this.props.loadOnFocus ?
          // @ts-expect-error change
          this.renderSelectComponent({
            ...this.props,
            noResultsText: this.state.noResultsText,
            onFocus: this.unRequiredLoadOptions,
            onBlur: this.resetSelectState,
            onInputChange: this.handleInputChange,
            searchable: this.props.searchable,
            onBlurResetsInput: false,
            onCloseResetsInput: false,
            autoload: false,
            isLoading: this.state && this.state.isLoading,
            options: this.state && this.state.options,
          }) :
          // @ts-expect-error change
          this.renderSelectComponent({
            ...this.props,
            onBlurResetsInput: false,
          })
      }
    </Fragment>;
  }
}

// @todo - need to make to be extended from props of select component
export interface ISelectProps {
  autocomplete?: boolean;
  labelKey?: string;
  valueKey?: string;
  className?: string;
  label?: string;
  error?: string;
  required?: boolean;
  compact?: boolean;
  creatable?: boolean;
  deletable?: boolean;
  async?: boolean;
  prohibitKeys?: string[];
  icon?: any;
  suffix?: string;
  primary?: boolean;
  clearable?: boolean;
  placeholder?: string;
  onChange?: (value: any) => void;
  inputProps?: { readOnly: boolean };
  value: any;
  options?: any[];
  optionRenderer?: (o: any) => any;
  valueRenderer?: (o: any) => any;
  filterOptions?: (values: any[], inputValue: string) => void;
  isLoading?: boolean;
  onSelect?: (value: any) => void;
  loadOptions?: (value, cb: (e: any, data: { options: any[] }) => void) => void;
  onOptionDelete?: (o: any) => any;
  loadOnFocus?: boolean;
  setSelectRef?: (ref: any) => void;
  searchable?: boolean;
  width?: number;
  id?: string;
  iconStatic?: boolean;
  maxOptionLength?: number;
  autoload?: boolean;
  name?: string;
  onNewOptionClick?: (option: any) => void;
  disabled?: boolean;
  onDelete?: (value: any) => void;
  multi?: boolean;
  onBlur?: (e: any) => any;
}

export const Select = styled<ISelectProps>(
  class extends React.Component<ISelectProps> {

    constructor(props) {
      super(props);
      this.handleInputKeyDown = this.handleInputKeyDown.bind(this);
      this.isValidNewOption = this.isValidNewOption.bind(this);
    }

    handleInputKeyDown(e) {
      if (this.props.prohibitKeys && this.props.prohibitKeys.includes(keycode(e.which))) {
        e.preventDefault();
      }
    }

    isValidNewOption(value: { label: string }): boolean {
      if (!value || !value.label) {
        return false;
      }
      if (this.props.maxOptionLength && value.label.length > this.props.maxOptionLength) {
        if (this.props.multi) {
          const segments = value.label.split((/[\s\t\n]+/));
          if (segments.some(segment => segment.length > this.props.maxOptionLength)) {
            return false;
          }
        } else {
          return false;
        }
      }
      return true;
    }

    render() {
      const {
        className,
        label,
        error,
        required,
        compact,
        prohibitKeys,
        icon,
        suffix,
        primary,
        ...selectProps
      } = this.props;

      const isLabel = compact ?
        !!label :
        true;

      const Icon = icon;

      const Label = compact ?
        CompactLabel :
        OriginalLabel;

      return (
        <div className={className}>
          {
            isLabel &&
            <Label
              error={error}
              htmlFor={selectProps.id ? selectProps.id : null}
              compact={compact}
              primary={primary}
            >
              {`${label ? label : ''} ${required ? ' * ' : ''}`}
            </Label>
          }
          <SelectDelegator
            arrowRenderer={() => icon ? <Icon /> : <CaretIcon />}
            onInputKeyDown={this.handleInputKeyDown}
            isValidNewOption={selectProps.maxOptionLength ? this.isValidNewOption : undefined}
            {...selectProps}
          />
          {
            suffix && <Suffix>{suffix}</Suffix>
          }
          <Error compact={compact}>{error}</Error>
        </div>);
    }
  }
)`
${({ theme, ...props }) => {

    const {
      global,
      light,
      primary,
      main,
      fs_md,
      dark,
      warning,
    } = theme;

    const borderColor = props.error ?
      warning :
      light;

    const wrap = props.compact ?
      'wrap' :
      'wrap';

    const margin = props.compact ?
      '0' :
      '7px 0 3px';

    const iconTransform = props.iconStatic ?
      '' :
      'rotateX(180deg)';

    const borderWidth = props.primary ?
      '2px' :
      '1px';

    return `
    ${global}

    display: inline-flex;
    position: relative;
    flex-wrap: ${wrap};
    width: 100%;
    ${props.width ? `max-width:${props.width}px;` : ''}

    .Select{
      flex: 1 1 auto;
      margin: ${margin};
    }
    
    .Select-control{
      height: 40px;

      border-radius: 0;

      border-width: ${borderWidth};

      border-color: ${borderColor}
    }

    .Select-value{
      line-height: 37px;
      .Select-value-label{
        font-size: 13px;
        color: ${primary}
      }

    }

    .Select-placeholder{
      line-height: 40px;
      font-size: ${fs_md};
      
      font-weight: 300;
      color: ${dark};
    }

    .Select-input{
      > input {
        line-height: 20px;
        font-size: ${fs_md};
      }
    }

    .Select-arrow-zone{
      vertical-align: baseline;
      padding-right: 10px;
      i {
        height: 100%;
        svg{
          height: 100%;
          transition: all .25s ease-in-out;
  
          path{
            fill-rule: evenodd; 
            fill: ${primary};
          }
        }
      }
    }
    
    .Select-clear-zone {
      padding-top: 2px; 
    }
    
    .Select-clear {
      color: ${primary};
      font-size: 22px;
    }


    &.Select.has-value.Select--single{
      > .Select-control{

        .Select-value{

          .Select-value-label{
            color: ${theme.primary};
            transition: all .25 ease-in-out;
          }
  
        }

      }
    }

    &.Select.has-value.is-open.Select--single{
      > .Select-control{

        .Select-value{

          .Select-value-label{
            color: ${theme.default};
          }
  
        }

      }
    }

    // opend condition
    & .Select.is-open{

      .Select-control{
        border-color: ${borderColor};
        
      }

      i {
        svg{
          transform: ${iconTransform};
          transform-origin: 50% 50%;

          path{
            fill: ${theme.default};
          }
        }
      }
    }

    .Select-menu-outer{
      border-radius: 0;
      
      border-width: ${borderWidth};
      margin-top: -1px;
      max-height: 700px;

      border-color: ${borderColor};
      
      background: ${main};

      .Select-menu{
        padding: 8px 0;
        max-height: 300px;
        .Select-option{
          font-size: 13px;
          color: ${primary};

          &.is-focused{
            background: ${main};
            color: ${theme.default};            
          }
          
          &.is-selected{
            background: ${main};
            color: ${theme.default};   
          }
          
          &.is-disabled {
            background: ${main};
            color: ${theme.dark};
          }

        }
      }

    }

    & .Select.is-pseudo-focused:not(.is-open){
      .Select-control{
        border-color: ${light};
        box-shadow: none;
      }
    }
    
    & .Select.is-focused:not(.is-open){
      > .Select-control{
        border-color: ${light} !important;
        box-shadow: none;
      }
    }
    `;
  }}

  ${OriginalLabel}{
    display: inline-flex;
    white-space: nowrap;
    flex-wrap: nowrap;
    flex: 0 0 100%;
    min-height: 18px;
  }
      
  ${Error}{
    ${({ compact }) => {
    const errorPosition = compact ?
      'absolute' :
      'static';
    return `
        position: ${errorPosition}
          min-height: 19px;
          margin: 0 0 3px;
          width: 100%;
          flex: 0 0 100%;
          top: 100%;
        `;
  }}
    }
  `;

Select.defaultProps = {
  searchable: true,
};

export default Select;

import React from 'react';
import List from './List';
import Edit from './Edit';
import Locations from '../Location';
import {
  ADMINISTRATION_CLIENTS_DIVISIONS,
  ADMINISTRATION_CLIENTS_DIVISIONS_LOCATIONS,
  ADMINISTRATION_CLIENTS_DIVISION_CREATE,
  ADMINISTRATION_CLIENTS_DIVISIONS_EDIT,
} from '../../../_constants/permissions';
import RoutesMapper from '../../Router/RoutesMapper';
import {IRouteEntry} from '../../../_types/common';

const routes: IRouteEntry[] = [
  {
    absolutePath: '/admin/clients/:clientId/divisions',
    exact: true,
    component: List,
    permission: ADMINISTRATION_CLIENTS_DIVISIONS,
  },
  {
    absolutePath: '/admin/clients/:clientId/divisions/new',
    exact: true,
    render: (props, customMatch) => <Edit {...props} match={customMatch} divisionId="new"/>,
    permission: ADMINISTRATION_CLIENTS_DIVISION_CREATE,
    keepForbiddenRoute: true,
  },
  {
    absolutePath: '/admin/clients/:clientId/divisions/:id',
    exact: true,
    render: (props, customMatch) => <Edit {...props} match={customMatch} divisionId={customMatch.params.id}/>,
    permission: ADMINISTRATION_CLIENTS_DIVISIONS_EDIT,
  },
  {
    absolutePath: '/admin/clients/:clientId/divisions/:id/locations',
    component: Locations,
    permission: ADMINISTRATION_CLIENTS_DIVISIONS_LOCATIONS,
  },
];

const Division: React.SFC = (props) => {
  return <RoutesMapper routes={routes} addNotFound {...props} />;
};

export default Division;

import React from 'react';
import styled from 'styled-components';

const PencilIcon = props => {
  return(
    <i {...props}>
      <svg viewBox="2046 9157 19.986 20" width="20" height="20">
        <path d=" M 2062.406 9157 C 2062.156 9157 2061.887 9157.113 2061.688 9157.313 Q 2061.688 9157.313 2060 9159 L 2064 9163 Q 2065.688 9161.313 2065.688 9161.313 C 2066.086 9160.914 2066.086 9160.207 2065.688 9159.906 C 2065.688 9159.906 2063.094 9157.313 2063.094 9157.313 C 2062.895 9157.113 2062.656 9157 2062.406 9157 Z  M 2059 9160.094 L 2046 9173 L 2046 9177 L 2050 9177 L 2062.906 9164 L 2059 9160.094 Z "/>
      </svg>
    </i>
  );
};

export default styled(PencilIcon)`
  ${ props => `
    width: 21px;
    height: 21px;
    display: inline-flex;
    justify-content: center;

    svg{
      path{
        fill: ${props.theme.success}; 
      }
    }
  `}
`;

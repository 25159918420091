import * as React from 'react';
import {RouteComponentProps} from 'react-router';
import RoutesMapper from '../Router/RoutesMapper';
import {IRouteEntry} from '../../_types/common';
import WithGranted, {IWithGrantedProps} from '../HOC/WithGranted';
import ListPurchaseHistory from './ListPurchaseHistory';
import View from './View';

class PurchaseHistory extends React.Component<IWithGrantedProps & RouteComponentProps<{}>> {
  public routs: IRouteEntry[];

  constructor(props) {
    super(props);

    this.routs = [
      {
        exact: true,
        path: `/product/:productId`,
        component: View,
      },
      {
        exact: true,
        path: `/`,
        component: ListPurchaseHistory,
      }
    ];
  }

  render() {
    return <React.Fragment>
      <RoutesMapper routes={this.routs} addNotFound {...this.props} />
    </React.Fragment>;
  }
}


export default WithGranted(PurchaseHistory);
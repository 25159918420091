import * as React from 'react';
import { RouteComponentProps } from 'react-router';
import RoutesMapper from '../Router/RoutesMapper';
import { IRouteEntry } from '../../_types/common';
import WithGranted, { IWithGrantedProps } from '../HOC/WithGranted';
import CategoriesMaster from './CategoriesMaster';
import ListAdmin from './ListAdmin';
import View from './View';
import Edit from './Edit';

import {
  MASTERCATALOGUE,
  MASTERCATALOGUE_PRODUCTS_EDIT,
  MASTERCATALOGUE_PRODUCTS_CREATE,
} from '../../_constants/permissions';

class Products extends React.Component<IWithGrantedProps & RouteComponentProps<{}>> {
  public routsTopElements: IRouteEntry[];
  public routs: IRouteEntry[];

  constructor(props) {
    super(props);
    this.routsTopElements = [
      {
        path: `/:categoryId?/:subCategoryId?/(product|new|edit)`,
        render: () => null
      },
      {
        exact: true,
        path: `/:categoryId?/:subCategoryId?`,
        permission: MASTERCATALOGUE,
        render: () => <CategoriesMaster />,
      }
    ];

    this.routs = [
      {
        exact: true,
        path: `/:categoryId?/:subCategoryId?/:serviceSlug(new)/:id?`,
        permission: MASTERCATALOGUE_PRODUCTS_CREATE,
        component: Edit,
      },
      {
        exact: true,
        path: `/:categoryId?/:subCategoryId?/:serviceSlug(edit)/:id?`,
        permission: MASTERCATALOGUE_PRODUCTS_EDIT,
        component: Edit,
      },
      {
        exact: true,
        path: `/:categoryId?/:subCategoryId?/:serviceSlug(product)/:productId`,
        permission: MASTERCATALOGUE,
        component: View,
      },
      {
        exact: true,
        path: `/:categoryId?/:subCategoryId?`,
        permission: MASTERCATALOGUE,
        component: ListAdmin,
      }
    ];
  }

  render() {
    return <React.Fragment>
      {/* <RoutesMapper routes={this.routsTopElements} {...this.props} /> */}
      <RoutesMapper routes={this.routs} addNotFound {...this.props} />
    </React.Fragment>;
  }
}


export default WithGranted(Products);
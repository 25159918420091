import React from 'react';
import { FormikState, FormikActions, FormikHandlers } from 'formik';

import { IModalValues } from '../types';

import {
  Button,
  Field,
  Select,
  Telephone,
  FormGrid as Grid,
  Textarea,
} from '../../../../components/index';

import * as stateApi from '../../../../_api/state';
import { Label } from "../../../../components/Branch";

interface IFormProps extends FormikState<IModalValues>, FormikActions<IModalValues>, FormikHandlers {
  isLoading: boolean;
  isNew?: boolean;
  onDelete: (id: number) => void;
}

class Form extends React.Component<IFormProps> {

  constructor(props) {
    super(props);

    this.state = {};

    this.loadState = this.loadState.bind(this);
    this.handleStateSelect = this.handleStateSelect.bind(this);
    this.handlePhoneNumberChange = this.handlePhoneNumberChange.bind(this);
    this.handleTelephoneBlur = this.handleTelephoneBlur.bind(this);

    this.handleDelete = this.handleDelete.bind(this);
  }

  loadState(text, callback) {
    stateApi.getAll(text)
      .then(data => {
        const options = data.items;
        callback(null, {
          options,
          complete: true,
        });
      });
  }

  handleStateSelect(data) {
    this.props.setFieldValue('stateId', data.id);
  }

  handlePhoneNumberChange(value) {
    this.props.setFieldValue('phoneNumber', value);
  }

  handleTelephoneBlur() {
    this.props.setFieldTouched('phoneNumber', true);
  }

  handleDelete() {
    this.props.onDelete(this.props.values.id);
  }

  render() {
    const {
      values,
      errors,
      touched,
      handleChange,
      handleBlur,
      handleSubmit,
    } = this.props;

    return <form noValidate onSubmit={handleSubmit}>
      <Grid>
        <Grid.Body>

          <Grid.Row>
            <Grid.Cell>
              <Field
                required
                type="text"
                name="name"
                label="Name"
                placeholder="Name..."
                value={values.name}
                onChange={handleChange}
                onBlur={handleBlur}
                disabled={this.props.isLoading}
                error={
                  touched.name &&
                  errors.name
                }
              />
            </Grid.Cell>
            <Grid.Cell>
              <Telephone
                required
                reduce
                name="phoneNumber"
                id="phoneNumber"
                label="Phone Number"
                placeholder="Phone Number..."
                value={values.phoneNumber}
                onChange={this.handlePhoneNumberChange}
                onBlur={this.handleTelephoneBlur}
                disabled={this.props.isLoading}
                error={
                  touched.phoneNumber &&
                  errors.phoneNumber
                }
              />
            </Grid.Cell>
          </Grid.Row>

          <Grid.Row>
            <Grid.Cell>
              <Textarea
                required
                name="address1"
                label="Address 1"
                placeholder="Address..."
                value={values.address1}
                onChange={handleChange}
                onBlur={handleBlur}
                disabled={this.props.isLoading}
                error={
                  touched.address1 &&
                  errors.address1
                }
              />
            </Grid.Cell>
          </Grid.Row>
          <Grid.Row>
            <Grid.Cell>
              <Textarea
                name="address2"
                label="Address 2"
                placeholder="Address..."
                value={values.address2}
                onChange={handleChange}
                onBlur={handleBlur}
                disabled={this.props.isLoading}
                error={
                  touched.address2 &&
                  errors.address2
                }
              />
            </Grid.Cell>
          </Grid.Row>

          <Grid.Row>
            <Grid.Cell>
              <Field
                required
                type="text"
                name="city"
                label="City"
                placeholder="City..."
                value={values.city}
                onChange={handleChange}
                onBlur={handleBlur}
                disabled={this.props.isLoading}
                error={
                  touched.city &&
                  errors.city
                }
              />
            </Grid.Cell>
            <Grid.Cell>
              <Select
                required
                async
                autoload
                name="state"
                label="State"
                placeholder="State..."
                labelKey="displayValue"
                valueKey="id"
                value={values.stateId}
                loadOptions={this.loadState}
                onChange={this.handleStateSelect}
                disabled={this.props.isLoading}
                error={
                  touched.stateId &&
                  errors.stateId
                }
              />
            </Grid.Cell>
            <Grid.Cell>
              <Field
                required
                type="text"
                name="zipCode"
                label="Zip Code"
                placeholder="Zip Code..."
                value={values.zipCode}
                onChange={handleChange}
                onBlur={handleBlur}
                disabled={this.props.isLoading}
                error={
                  touched.zipCode &&
                  errors.zipCode
                }
              />
            </Grid.Cell>
          </Grid.Row>

          <Grid.Row>
            <Grid.Cell>
              <Label />
            </Grid.Cell>
          </Grid.Row>

          <Grid.Row>
            <Grid.Cell>
              {
                !this.props.isNew &&
                <Button
                  primary
                  disabled={this.props.isLoading}
                  onClick={this.handleDelete}
                  preloader={this.props.isLoading}
                  scale="lg"
                >
                  Delete
                </Button>
              }
            </Grid.Cell>
            <Grid.Cell>
              <Button
                type="submit"
                disabled={this.props.isLoading}
                scale="lg"
                preloader={this.props.isLoading}
              >
                {this.props.isNew ? 'Submit' : 'Save'}
              </Button>
            </Grid.Cell>
          </Grid.Row>

        </Grid.Body>
      </Grid>
    </form>;
  }
}

export default Form;
import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import {
  Label,
  Error,
} from '../components';

export const Body = styled.div`
  ${ ({ compact }) => {

    let margin = compact ?
      '0' :
      '7px 0 3px';

    return `
      position: relative;
      margin: ${margin};
    `;
  }}
`;

export const TextareaInput = styled.textarea`

  ${({ theme, ...props }) => {

    let {
      global,
      warning,
      light,
      main,
      primary,
      fs_md,
      dark,
      defaultRGB,
      lightest,
    } = theme;

    let background = props.disabled ?
      lightest :
      main;

    let borderColor;
    if (props.error) {
      borderColor = warning;
    } else {
      borderColor = light;
    }

    let padding;
    let paddingIcon;
    switch (props.size) {
      case 'small':
      case 'large':
        padding = '15px';
        paddingIcon = '50px';
        break;
      default:
        padding = '10px';
        paddingIcon = '40px';
    }

    return `

      ${global}
      border: none;
      background: ${background};
      border: 1px solid ${borderColor}; 
      color: ${primary};

      padding: ${padding} ${padding} ${padding} ${padding};
      font-size: ${fs_md};
      min-height: 100px;
      width: 100%;

      resize: none;
      font-weight: 300;

      &:focus{
        outline: none;
        border: 1px solid rgba(${defaultRGB},0.5);
      }
      
      &:active{
        border: 1px solid rgba(${defaultRGB},0.5);
      }
      
      &.icon{
        padding: ${padding} ${padding} ${padding} ${paddingIcon};
      }

      ::-webkit-input-placeholder { 
        color: ${dark};
      }
      ::-moz-placeholder { 
        color: ${dark};
      }
      :-ms-input-placeholder { 
        color: ${dark};
      }
      :-moz-placeholder { 
        color: ${dark};
      }

    `;

  }}
`;

const Textarea = styled(props => {

  let {
    className,
    placeholder,
    compact,
    label,
    error,
    required,
    onChange,
    value,
    ...restProps
  } = props;

  function handleChange(event) {
    if (onChange) {
      onChange(event);
    }
  }

  return <div className={className}>
    {
      label &&
      <Label
        error={error}
        htmlFor={restProps.id ? restProps.id : null}
      >
        {`${label} ${required ? ' * ' : ''}`}
      </Label>
    }
    <Body>
      <TextareaInput
        placeholder={placeholder}
        value={value || ''}
        onChange={handleChange}
        error={error}
        {...restProps}
      />
    </Body>
    <Error compact={compact}>
      {error}
    </Error>
  </div>;
})`
  ${({ theme }) => {

    let {
      global,
    } = theme;

    return `
      ${global}
      min-height: 100px;
      width: 100%;
    `;

  }}
`;

Textarea.propTypes = {
  className: PropTypes.string,
  placeholder: PropTypes.string,
  compact: PropTypes.bool,
  label: PropTypes.string,
  error: PropTypes.string,
  required: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string,
};

export default Textarea;
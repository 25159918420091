import { createStore, applyMiddleware, compose, Store, Middleware, StoreEnhancer } from 'redux';
import { routerMiddleware } from 'react-router-redux';
import thunk from 'redux-thunk';
import createHistory from 'history/createBrowserHistory';
import reducers, {IGlobalStore} from './_reducers/reducers';

export const history = createHistory();

const initialState: IGlobalStore = undefined;

const enhancers: Array<StoreEnhancer<IGlobalStore>> = [];
const middleware: Middleware[] = [
  thunk,
  routerMiddleware(history),
];

if (process.env.NODE_ENV === 'development') {
  // tslint:disable-next-line
  const devToolsExtension = window['devToolsExtension'];

  if (typeof devToolsExtension === 'function') {
    enhancers.push(devToolsExtension());
  }
}

const composedEnhancers = compose(
  applyMiddleware(...middleware),
  ...enhancers
);

const store: Store<IGlobalStore> = createStore(
  reducers,
  initialState,
  composedEnhancers
);

export default store;

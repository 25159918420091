import * as React from "react";
import {
  FormikActions,
  FormikComputedProps,
  FormikHandlers,
  FormikState
} from "formik";
// import styled from "styled-components";
import { resetPassword } from "../../_api/resetPassword";
import { Button, Field } from "../../components";
import { LockIcon } from "../../assets/icons";
import { AuthenticateModelWithConfirmPassword } from "../../service-proxies";
import { connect } from "react-redux";
import { getSwaggerErrorResponse } from "src/_utils/errorHandling";
import {
  prompt as openConfirm,
  alert,
  openCustomModalCallback,
  IOpenCustomModalCallback,
  IOpenAlert,
  IOpenConfirmPrompt
} from "../../_actions/modal";
import { history } from "../../store";
import debounce from "lodash.debounce";

// const LoginError = styled.div`
//   ${({ theme }) => theme.global}
//   color: ${({ theme }) => theme.default};
//   padding-bottom: 16px;
// `;

// const LoginErrorMessage = styled.div`
//   font-size: 18px;
// `;

// const LoginErrorDeatils = styled.div`
//   font-size: 14px;
// `;

const handlePasswordChange = async (
  password: string,
  code: string,
  modal: any
) => {
  const pathArr = code.split("/");
  const uid = pathArr[pathArr.length - 2];
  const resetCode = pathArr[pathArr.length - 1];
  const input = {
    password,
    PasswordResetCode: resetCode,
    id: parseInt(uid)
  };
  const successMessage = "Your password has been reset.";
  try {
    await resetPassword(input);
    modal(successMessage, "Success!");
    history.push("/login");
  } catch (err) {
    modal(getSwaggerErrorResponse(err).error.message, "Error!", true);
    const resetPasswordBtn = document.getElementsByClassName(
      "Button-hYXUXp cWZELY"
    )[0];
    resetPasswordBtn.addEventListener("click", () => {
      history.push("/forgot-password");
    });
  }

  // resetPassword(input)
  //   .then(() => modal(successMessage, "Success!"))
  //   .then(() => history.push("/login"))
  //   .catch(err =>
  //     modal(getSwaggerErrorResponse(err).error.message, "Error!", true)
  //   )
  //   .then(() => history.push("/forgot-password"));
};

interface ILoginFormProps
  extends FormikState<AuthenticateModelWithConfirmPassword>,
    FormikActions<AuthenticateModelWithConfirmPassword>,
    FormikComputedProps<AuthenticateModelWithConfirmPassword>,
    FormikHandlers,
    IConnectedProps {
  openModal: IOpenCustomModalCallback;
  openConfirm: IOpenConfirmPrompt;
  alertModal: IOpenAlert;
}

const ChangePasswordForm: React.SFC<ILoginFormProps> = ({
  values,
  errors,
  touched,
  handleChange,
  handleBlur,
  handleSubmit,
  isSubmitting,
  errorMessage,
  errorDetails,
  isValid,
  ...restProps
}) => (
  <form onSubmit={handleSubmit}>
    <legend>Change Password</legend>

    {/* {errorMessage && (
      <LoginError>
        <LoginErrorMessage>{errorMessage}</LoginErrorMessage>
        <LoginErrorDeatils>{errorDetails}</LoginErrorDeatils>
      </LoginError>
    )} */}

    <Field
      icon={LockIcon}
      type="password"
      name="password"
      placeholder="new password"
      onChange={handleChange}
      onBlur={handleBlur}
      value={values.password}
      size="lg"
      error={touched.password && errors.password}
    />

    <Field
      icon={LockIcon}
      type="password"
      name="confirmPassword"
      placeholder="confirm password"
      onChange={handleChange}
      onBlur={handleBlur}
      value={values.confirmPassword}
      size="lg"
      error={touched.confirmPassword && errors.confirmPassword}
    />

    <Button
      onClick={debounce(
        () =>
          handlePasswordChange(
            values.password,
            location.pathname,
            restProps.alertModal
          ),
        500
      )}
      disabled={isSubmitting || !isValid}
      scale="lg"
      preloader={isSubmitting}
    >
      Reset Password
    </Button>
  </form>
);

interface IConnectedProps {
  errorMessage: string | null;
  errorDetails: string | null;
}

export default connect(
  null,
  {
    openModal: openCustomModalCallback,
    openConfirm,
    alertModal: alert
  }
)(ChangePasswordForm);

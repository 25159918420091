import moment from "moment";
import { ordersServiceProxy } from "../servicePool";
import {
  CartItemDto,
  EntityDtoOfInt64,
  EditBuyerOrderDto,
  SubmitOrderItem,
  OrderItemDto,
  PagedResultDtoOfUncomparedOrdersListDto,
  PagedResultDtoOfSCMDashboardOrderListDto,
  ClientUnitType,
  EditUncomparedOrderDto,
  UpdateUncomparedOrderDto,
  PagedResultDtoOfMyOrdersListDto,
  EditMyOrderDto,
  PagedResultDtoOfPackingSlipOrdersListDto,
  EditPackingSlipOrderDto,
  UpdatePackingSlipOrderItemDto,
  PagedResultDtoOfBuyerOrderListDto,
  UpdatePackingSlipOutput,
  ErrorsOutputDto,
  EditAllOrderDto,
  PackingSlipDetailsDto
} from "../service-proxies";

export function saveOrder(
  locationId: number,
  items: CartItemDto[]
): Promise<EntityDtoOfInt64> {
  return ordersServiceProxy.saveOrder({ locationId, items });
}

export function submitOrder(
  locationId: number,
  items: SubmitOrderItem[],
  comment: string
): Promise<EntityDtoOfInt64> {
  return ordersServiceProxy.submitOrder({ locationId, items, comment });
}

export function getBuyerOrders(
  startDate: moment.Moment,
  dueDate: moment.Moment,
  locationId: number,
  filter: string,
  sorting: string,
  skipCount: number,
  maxResultCount: number
): Promise<PagedResultDtoOfBuyerOrderListDto> {
  return ordersServiceProxy.getBuyerOrders(
    startDate,
    dueDate,
    locationId,
    filter,
    sorting,
    skipCount,
    maxResultCount
  );
}

export function getBuyerOrderForEdit(
  orderId: number
): Promise<EditBuyerOrderDto> {
  return ordersServiceProxy.getBuyerOrderForEdit(orderId);
}

export function approveBuyerOrder(id: number): Promise<void> {
  return ordersServiceProxy.approveBuyerOrder({ id });
}

export function submitBuyerOrder(id: number): Promise<void> {
  return ordersServiceProxy.submitBuyerOrder({ id });
}

export function cancelBuyerOrder(id: number): Promise<void> {
  return ordersServiceProxy.cancelBuyerOrder({ id });
}

export function updateBuyerOrder(
  id: number,
  items: OrderItemDto[],
  clientPoNumber
): Promise<void> {
  return ordersServiceProxy.updateBuyerOrder({ id, items, clientPoNumber });
}

export function getAllOrdersForSCMDashboard(
  locationId: number,
  filter: string,
  sorting: string,
  skipCount: number,
  maxResultCount: number
): Promise<PagedResultDtoOfSCMDashboardOrderListDto> {
  const myReturn = ordersServiceProxy.getAllOrdersForSCMDashboard(
    locationId,
    filter,
    sorting,
    skipCount,
    maxResultCount
  );
  return myReturn;
}

export function getUncomparedOrders(
  startDate: moment.Moment,
  dueDate: moment.Moment,
  clientUnitType: ClientUnitType,
  clientUnitId: number,
  filter: string,
  sorting: string,
  skipCount: number,
  maxResultCount: number
): Promise<PagedResultDtoOfUncomparedOrdersListDto> {
  return ordersServiceProxy.getUncomparedOrders(
    startDate,
    dueDate,
    clientUnitType,
    clientUnitId,
    filter,
    sorting,
    skipCount,
    maxResultCount
  );
}

export function getUncomparedOrderForEdit(
  id: number
): Promise<EditUncomparedOrderDto> {
  return ordersServiceProxy.getUncomparedOrderForEdit(id);
}

export function updateUncomparedOrder(
  input: UpdateUncomparedOrderDto
): Promise<void> {
  return ordersServiceProxy.updateUncomparedOrder(input);
}

export function approveUncomparedOrder(input: EntityDtoOfInt64): Promise<void> {
  return ordersServiceProxy.approveUncomparedOrder(input);
}

export function getMyOrders(
  clientUnitId: number,
  filter: string,
  sorting: string,
  skip: number,
  perPage: number
): Promise<PagedResultDtoOfMyOrdersListDto> {
  return ordersServiceProxy.getMyOrders(
    clientUnitId,
    filter,
    sorting,
    skip,
    perPage
  );
}

export function getMyOrderForEdit(id: number): Promise<EditMyOrderDto> {
  return ordersServiceProxy.getMyOrderForEdit(id);
}

export function getPackingSlips(
  clientUnitId: number,
  filter: string,
  startDate: moment.Moment,
  dueDate: moment.Moment,
  sorting: string,
  skip: number,
  perPage: number
): Promise<PagedResultDtoOfPackingSlipOrdersListDto> {
  return ordersServiceProxy.getPackingSlips(
    clientUnitId,
    startDate,
    dueDate,
    filter,
    sorting,
    skip,
    perPage
  );
}

export function getCompanyPackingSlips(
  clientUnitId: number,
  filter: string,
  startDate: moment.Moment,
  dueDate: moment.Moment,
  sorting: string,
  skip: number,
  perPage: number
): Promise<PagedResultDtoOfPackingSlipOrdersListDto> {
  return ordersServiceProxy.getCompanyPackingSlips(
    clientUnitId,
    startDate,
    dueDate,
    filter,
    sorting,
    skip,
    perPage
  );
}

export function getPackingSlipForEdit(
  id: number
): Promise<EditPackingSlipOrderDto> {
  return ordersServiceProxy.getPackingSlipOrderForEdit(id);
}

export function getOtherPackingSlipForEdit(
  id: number
): Promise<PackingSlipDetailsDto> {
  return ordersServiceProxy.getPackingSlipDetails(id);
}

export function updatePackingSlipOrder(
  items: UpdatePackingSlipOrderItemDto[],
  id: number
): Promise<UpdatePackingSlipOutput> {
  return ordersServiceProxy.updatePackingSlipOrder({
    items,
    id
  });
}

export function reconcilePackingSlipOrder(id: number): Promise<void> {
  return ordersServiceProxy.reconcilePackingSlipOrder({ id });
}

export function getAllOrders(
  locationId: number,
  filter: string,
  sorting: string,
  skipCount: number,
  maxResultCount: number
): Promise<PagedResultDtoOfBuyerOrderListDto> {
  const myReturn = ordersServiceProxy.getAllOrders(
    locationId,
    filter,
    sorting,
    skipCount,
    maxResultCount
  );
  return myReturn;
}

export function getAllOrderForEdit(orderId: number): Promise<EditAllOrderDto> {
  return ordersServiceProxy.getAllOrderForEdit(orderId);
}

export function approveAllOrder(id: number): Promise<void | ErrorsOutputDto> {
  return ordersServiceProxy.approveAllOrder({ id });
}

export function submitAllOrder(id: number): Promise<void | ErrorsOutputDto> {
  return ordersServiceProxy.submitAllOrder({ id });
}

export function cancelAllOrder(id: number): Promise<void> {
  return ordersServiceProxy.cancelAllOrder({ id });
}

export function updateAllOrder(
  id: number,
  items: OrderItemDto[],
  clientPoNumber: string
): Promise<void> {
  return ordersServiceProxy.updateAllOrder({ id, items, clientPoNumber });
}

import React from 'react';
import styled from 'styled-components';

export default styled(props => {
  return (
    <i {...props}>
      <svg viewBox="6.913 5.245 6.175 10" width="6.175" height="10">
          <path d=" M 13.088 14.07 L 9.271 10.245 L 13.088 6.42 L 11.913 5.245 L 6.913 10.245 L 11.913 15.245 L 13.088 14.07 Z " />
      </svg>
    </i>
  );
})`
  ${ props => `
    width: 21px;
    height: 21px;
    display: inline-flex;
    justify-content: center;
    align-items: center;

    svg{
      path{
        fill: ${props.theme.primary}; 
      }
    }
  `}
`;

import * as React from 'react';
import {FormikActions, FormikComputedProps, FormikHandlers, FormikState} from 'formik';
import styled from 'styled-components';

import { Button, Field, Checkbox } from '../../components';
import { ActorIcon, LockIcon } from '../../assets/icons';
import { AuthenticateModel } from'../../service-proxies';

const LoginError = styled.div`
  ${({theme}) => theme.global}
  color: ${({theme}) => theme.default};
  padding-bottom: 16px;
`;

const LoginErrorMessage = styled.div`
  font-size: 18px;
`;

const LoginErrorDeatils = styled.div`
  font-size: 14px;
`;

interface ILoginFormProps extends
  FormikState<AuthenticateModel>,
  FormikActions<AuthenticateModel>,
  FormikComputedProps<AuthenticateModel>,
  FormikHandlers,
  IConnectedProps {
}

const LoginForm: React.SFC<ILoginFormProps> = ({
  values,
  errors,
  touched,
  handleChange,
  handleBlur,
  handleSubmit,
  isSubmitting,
  errorMessage,
  errorDetails,
  isValid,
  ...restProps
}) => (
  <form onSubmit={handleSubmit}>

    <legend>sign in</legend>

    {errorMessage &&
      <LoginError>
        <LoginErrorMessage>{errorMessage}</LoginErrorMessage>
        <LoginErrorDeatils>{errorDetails}</LoginErrorDeatils>
      </LoginError>
    }

    <Field
      icon={ActorIcon}
      type="text"
      name="userNameOrEmailAddress"
      placeholder="username or email"
      onChange={handleChange}
      onBlur={handleBlur}
      value={values.userNameOrEmailAddress}
      size="lg"
      error={
        touched.userNameOrEmailAddress &&
        errors.userNameOrEmailAddress
      }
    />

    <Field
      icon={LockIcon}
      type="password"
      name="password"
      placeholder="password"
      onChange={handleChange}
      onBlur={handleBlur}
      value={values.password}
      size="lg"
      error={touched.password && errors.password}
    />

    <Checkbox
      value={values.rememberClient}
      label="Remember me"
      onChange={value => restProps.setFieldValue('rememberClient', value)}
      name="rememberClient"
      id="rememberClient"
    />

    <Button
      type="submit"
      disabled={isSubmitting || !isValid}
      scale="lg"
      preloader={isSubmitting}
    >
      Sign In
    </Button>

  </form>
);

interface IConnectedProps {
  errorMessage: string | null;
  errorDetails: string | null;
}

export default LoginForm;

import { types } from '../_actionTypes/divisions';
import { DivisionListDto, EditDivisionDto } from '../service-proxies';

export interface IDivisionsStore {
  divisions: DivisionListDto[];
  totalCount: number;
  listStatus: string;
  divisionName?: string;
  division: EditDivisionDto;
  status: string;
  perPage: number;
  skip: number;
}

const initialState: IDivisionsStore = {
  divisions: [],
  totalCount: null,
  listStatus: '',
  divisionName: '',
  division: null,
  status: '',
  perPage: 10,
  skip: 0,
};

interface IGetAllAction {
  type: types.DIVISION_GET_ALL;
  divisions: DivisionListDto[];
  listStatus: string;
  status: string;
  totalCount: number;
}

interface IGetDivision {
  type: types.DIVISION_GET;
  division: EditDivisionDto;
  status: string;
}

interface IDivisionCreate {
  type: types.DIVISION_CREATE;
  status: string;
}

interface IDivisionUpdate {
  type: types.DIVISION_UPDATE;
  status: string;
}

interface IDivisionRemove {
  type: types.DIVISION_REMOVE;
  status: string;
}

interface IDivisionGetName {
  type: types.DIVISION_GET_NAME;
  divisionName: string;
}

interface IDivisionChangePage {
  type: types.DIVISION_CHANGE_PAGE;
  perPage: number;
  skip: number;
}

type IActions = IGetAllAction | IGetDivision | IDivisionCreate | IDivisionUpdate | IDivisionRemove | IDivisionGetName | IDivisionChangePage;

export default (state: IDivisionsStore = initialState, action: IActions) => {

  switch (action.type) {
    case types.DIVISION_GET_ALL:
      return ({
        ...state,
        divisions: action.divisions,
        listStatus: action.listStatus,
        status: action.status,
        totalCount: action.totalCount,
      });
    case types.DIVISION_GET:
      return ({
        ...state,
        division: action.division,
        status: action.status,
      });
    case types.DIVISION_CREATE:
      return ({
        ...state,
        status: action.status,
      });
    case types.DIVISION_UPDATE:
      return ({
        ...state,
        status: action.status,
      });
    case types.DIVISION_GET_NAME:
      return ({
        ...state,
        divisionName: action.divisionName,
      });
    case types.DIVISION_REMOVE:
      return ({
        ...state,
        status: action.status,
      });
    case types.DIVISION_CHANGE_PAGE:
      return ({
        ...state,
        perPage: action.perPage,
        skip: action.skip,
      });
    default:
      return state;
  }
};

import styled from 'styled-components';

interface IBadgeProps {
  negative?: boolean;
  children?: any;
  className?: string;
}

const Badge = styled<IBadgeProps, 'div'>('div')`
  ${({ theme, ...props }) => {
    const {
      global,
      main,
      primary,
    } = theme;

    const minSize = props.children ?
      '24px' :
      'inherite';

    let borderColor = main;
    let borderWidth = '2px';
    let backgroundColor = theme.default;
    let color = main;
    let padding = '3px';

    if (props.negative) {
      borderColor = theme.default;
      borderWidth = '1px';
      backgroundColor = 'transparent';
      color = primary;
      padding = '4px';
    }

    return `
      ${global}
      color: ${color};
      border: ${borderWidth} solid ${borderColor};
      background-color: ${backgroundColor};
      padding: ${padding};
      min-width: ${minSize};
      min-height: ${minSize};
      border-radius: 15px;
      font-size: 11px;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      text-align: center;
    `;
  }}
`;


export default Badge;

import * as React from 'react';
import { Fragment } from 'react';
import styled from 'styled-components';

import Link from './Link';
import Checkbox from './Checkbox';
import IconTrigger from './IconTrigger';

import {
  TriangleIcon,
  FolderIcon,
  FolderOpenIcon,
} from '../assets/icons';

export const Label = styled.div`
  ${({ theme }) => {
    const {
      global,
    } = theme;
    return `
      ${global}
      display: flex;
      align-items: center;
      margin-left: 5px;
      .active {
        color: ${theme.default};  
      }
    `;
  }}
`;

export const Body = styled.div`
  padding: 8px 10px;
`;

export const BlockBody = Body;
export const ListBody = Body.withComponent('li').extend`
  padding-left: 20px;
`;

export const ExpandButton = Link.Button.extend`
  ${({ theme }) => {
    const {
      primary,
    } = theme;
    return `  
      margin-left: -20px;
      i{
        margin: 0;
        svg{
          path{
            fill: ${primary};
          }
        }
      }
    `;
  }}
`;

interface INode {
  name: string;
  id: number;
  expanded?: boolean;
  active?: boolean;
  children?: INode[];
  indeterminate?; boolean;
}

interface IBranchProps {
  className?: string;
  node: INode;
  path?: number[];
  cascade: boolean;
  catalog?: any;
  label?: any;
  to?: string;
  disabled?: boolean;
  onExpand?: (event: any, path?: number[]) => void;
  onClick?: (event: any, path: number[], node: any) => void;
  depth?: number;
  expandElement?: string;
}

const Branch = styled<IBranchProps>(({
  className,
  children,
  node,
  path,
  cascade,
  catalog,
  onExpand,
  onClick,
  label,
  to,
  disabled,
}) => {

  function handleExpand(e) {
    if (onExpand) {
      onExpand(e, path);
    }
  }

  function handleClick(e) {
    if (onClick) {
      onClick(e, path, node);
    }
  }

  const BranchBody = cascade ?
    ListBody :
    BlockBody;

  return <Fragment>
    <BranchBody className={className} >
      {
        onExpand &&
        <ExpandButton onClick={handleExpand} >
          <TriangleIcon />
        </ExpandButton>
      }
      {
        onClick &&
        <Checkbox
          disabled={disabled}
          value={node.active}
          indeterminate={node.indeterminate}
          onChange={handleClick}
        />
      }
      {
        catalog &&
        <Link.Button onClick={handleExpand} >
          <IconTrigger
            status={!node.expanded}
            icons={[
              FolderIcon,
              FolderOpenIcon,
            ]}
          />
        </Link.Button>
      }
      {
        label ? label :
          to ?
            <Label>
              <Link.NavLink primary to={to} >
                {node.name}
              </Link.NavLink>
            </Label> :
            <Label>
              <Link.Button primary onClick={(onClick && !disabled) ? handleClick : handleExpand}>
                {node.name}
              </Link.Button>
            </Label>
      }
    </BranchBody>
    {
      node.expanded &&
      children
    }
  </Fragment>;
})`
  ${({ theme, ...props }) => {

    const globalIndent = 40;

    const indent = props.cascade ?
      '0px' :
      `${((props.depth - 1) * 26) + globalIndent}px`;

    return `
      display: flex;
      flex-wrap: wrap;
      list-style: none;
      padding-left: ${indent}; 
      position: relative;
    `;
  }}

  > ${ExpandButton}{
    > ${TriangleIcon}{
      ${({ theme, ...props }) => {

        const {
          primary,
        } = theme;

        const tringleRotate = !props.node.expanded ?
          'rotate(-90deg)' :
          'rotate(0deg)';
        
        return `
          transform: ${tringleRotate};
          path{
            fill: ${primary};
          }
        `;

      }}
    }

    > ${FolderIcon}{
      ${({ theme, ...props }) => {

        const {
          dark,
        } = theme;
    
        const folderColor = (props.depth === 1) ?
          theme.default :
          dark;
    
        return `
          margin: 0 0 0 5px;
          path{
            fill: ${folderColor};
          }
        `;
      }}
    }

    > ${FolderOpenIcon}{
      ${({ theme, ...props }) => {

        const {
          dark,
        } = theme;
    
        const folderColor = (props.depth === 1) ?
          theme.default :
          dark;
    
        return `
          margin: 0 0 0 5px;
          path{
            fill: ${folderColor};
          }
        `;
      }}
    }
  }

  > ${Checkbox}{
    margin: 0 0 0 5px;
  }
`;

Branch.defaultProps = {
  expandElement: 'triangle',
};

export default Branch;

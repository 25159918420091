import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import TelephoneOriginal from 'react-telephone-input';

import {
  Label,
  Error,
} from '../components';

export const Body = styled.div`
  ${ ({ size, compact, iconShow }) => {

    let paddingIcon;
    switch (size) {
      case 'small':
      case 'large':
        paddingIcon = '50px';
        break;
      default:
        paddingIcon = '40px';
    }

    let margin = compact ?
      '0' :
      '7px 0 3px';

    return `
      position: relative;
      margin: ${margin};

      ${ !!iconShow && `
        > .${iconShow.styledComponentId} {
          position: absolute;
          display: flex;
          justify-content: center;
          align-items: center;
          height: auto;
          width: ${paddingIcon};
          top: 0px;
          left: 0px;
          bottom: 0px;
          pointer-events: none;
        }`
      }

    `;
  }}
`;

export const Telephone = ({
  className,
  label,
  error,
  required,
  compact,
  size,
  onChange,
  reduce,
  prohibitKeys,
  ...inputProps
}) => {

  function handleChange(value) {
    let editedValue = value.substr(0, 17);
    if (reduce) {
      editedValue = editedValue.replace(/[^0-9]+/g, '');
    }
    onChange(editedValue);
  }

  return <div className={className} >

    {
      label &&
      <Label
        error={error}
        htmlFor={inputProps.id ? inputProps.id : null}
      >
        {`${label} ${required ? ' * ' : ''}`}
      </Label>
    }

    <Body compact={compact} size={size}>
      <TelephoneOriginal
        defaultCountry="us"
        {...inputProps}
        onChange={handleChange}
        className={className}
        onlyCountries={[
          { name: "United States", iso2: "us", dialCode: "1", priority: 0, format: "+. (...) ...-...." },
        ]}
      />
    </Body>

    <Error compact={compact}>
      {error}
    </Error>

  </div>;

};

Telephone.propTypes = {
  className: PropTypes.string,
  label: PropTypes.string,
  error: PropTypes.string,
  placeholder: PropTypes.string,
  value: PropTypes.string,
  required: PropTypes.bool,
  icon: PropTypes.func,
  compact: PropTypes.bool,
  prohibitKeys: PropTypes.array,
  size: PropTypes.string,
  onChange: PropTypes.func,
  reduce: PropTypes.bool,
};

export default styled(Telephone)`
  ${props => {

    let {
      global,
      warning,
      light,
      main,
      primary,
      fs_md,
      dark,
      defaultRGB,
      lightest,
    } = props.theme;

    let borderColor;
    if (props.error) {
      borderColor = warning;
    } else {
      borderColor = light;
    }

    let padding;
    let paddingIcon;
    switch (props.size) {
      case 'small':
      case 'large':
        padding = '15px';
        paddingIcon = '50px';
        break;
      default:
        padding = '10px';
        paddingIcon = '40px';
    }

    let background = props.disabled ?
      lightest :
      main;

    return `
      input{

        ${global}
        border: none;
        background: ${background};
        border: 1px solid ${borderColor};
        color: ${primary};

        padding: ${padding} ${padding} ${padding} ${padding};
        font-size: ${fs_md};

        font-weight: 300;
        width: 100%;

        &:focus{
          outline: none;
          border: 1px solid rgba(${defaultRGB},0.5);
        }
        
        &:active{
          border: 1px solid rgba(${defaultRGB},0.5);
        }
        
        &.icon{
          padding: ${padding} ${padding} ${padding} ${paddingIcon};
        }

        ::-webkit-input-placeholder { 
          color: ${dark};
        }
        ::-moz-placeholder { 
          color: ${dark};
        }
        :-ms-input-placeholder { 
          color: ${dark};
        }
        :-moz-placeholder { 
          color: ${dark};
        }
      }

    `;
  }}
`;
import * as React from 'react';
import styled, { StyledComponentClass } from 'styled-components';
import { Formik } from 'formik';
import * as Yup from "yup";
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import moment from 'moment';
import { match } from 'react-router';
import * as actionsNotification from '../../../_actions/notification';

import { history } from '../../../store';

import Form from './Form';

import {
  MainContainer,
} from '../../../components';
import { IOpenConfirmPrompt, prompt } from '../../../_actions/modal';
import * as actionsSearch from '../../../_actions/search';
import * as clientsApi from '../../../_api/client';
import { EditClientDto } from '../../../service-proxies';
import { Omit } from '../../../_types/common';
import {
  REQUIRED_MESSAGE,
  INT32_MAX,
  DECIMAL_MAX,
} from '../../../_constants/validation';
import {
  getMaxTextLengthMessage,
  getMaxNumberValue,
  getAtLeastMessage,
} from '../../../_utils/validation';
import { JSONMapper } from 'src/_utils/misc';

type StyledComponent = StyledComponentClass<{}, React.StatelessComponent<{}>>;

interface IPosition extends StyledComponent {
  Body?: StyledComponent;
  Head?: StyledComponent;
}

const Position: IPosition = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column; 
`;

Position.Body = styled.div`
  width: 100%; 
  height: 100%; 
`;

Position.Head = styled.header`
  text-align: right;
  padding: 20px;
`;

const validationSchema = Yup.object().shape({
  name: Yup.string()
    .trim()
    .max(128, getMaxTextLengthMessage(128))
    .required(REQUIRED_MESSAGE),
  numberOfDivisions: Yup.number()
    .lessThan(INT32_MAX, getMaxNumberValue(INT32_MAX))
    .required(REQUIRED_MESSAGE),
  numberOfLocations: Yup.number()
    .lessThan(INT32_MAX, getMaxNumberValue(INT32_MAX))
    .required(REQUIRED_MESSAGE),
  numberOfEmployees: Yup.number()
    .lessThan(INT32_MAX, getMaxNumberValue(INT32_MAX))
    .required(REQUIRED_MESSAGE),
  numberOfPhysicians: Yup.number()
    .lessThan(INT32_MAX, getMaxNumberValue(INT32_MAX))
    .required(REQUIRED_MESSAGE),
  medicalSpeciality: Yup.object()
    .required(REQUIRED_MESSAGE)
    .typeError(REQUIRED_MESSAGE),
  historicalAnnualSpend: Yup.number()
    .lessThan(DECIMAL_MAX, getMaxNumberValue(DECIMAL_MAX))
    .required(REQUIRED_MESSAGE)
    .typeError(REQUIRED_MESSAGE),
  startDate: Yup.string()
    .trim()
    .required(REQUIRED_MESSAGE)
    .typeError(REQUIRED_MESSAGE),
  primaryPreviousSuppliers: Yup.array()
    .min(1, getAtLeastMessage(1, 'supplier'))
    .required(REQUIRED_MESSAGE),
  meetingSchedule: Yup.string()
    .trim()
    .max(500, getMaxTextLengthMessage(500))
    .required(REQUIRED_MESSAGE),
  orderingRestriction: Yup.string()
    .trim()
    .max(500, getMaxTextLengthMessage(500))
    .required(REQUIRED_MESSAGE),
  approvalRestriction: Yup.string()
    .trim()
    .max(500, getMaxTextLengthMessage(500))
    .required(REQUIRED_MESSAGE),
});

function replaceNullsWithZero(client: EditClientDto): EditClientDto {
  return {
    ...client,
    numberOfDivisions: client.numberOfDivisions === null ? 0 : client.numberOfDivisions,
    numberOfLocations: client.numberOfLocations === null ? 0 : client.numberOfLocations,
    numberOfEmployees: client.numberOfEmployees === null ? 0 : client.numberOfEmployees,
    numberOfPhysicians: client.numberOfPhysicians === null ? 0 : client.numberOfPhysicians,
    historicalAnnualSpend: client.historicalAnnualSpend === null ? 0 : client.historicalAnnualSpend,
  };
}

type EditClientMod = Omit<Omit<EditClientDto, 'id'>, 'primaryPreviousSuppliers'>;

export interface ISuppliersEdit {
  supplier?: string;
  id?: string;
}

export interface IEditClientState extends EditClientMod {
  id?: number;
  primaryPreviousSuppliers: ISuppliersEdit[];
  status: 'loading' | 'done';
  filter?: string;
}

class TrimMapper extends JSONMapper<IEditClientState> {
  map(node) {
    if (typeof node === 'string') {
      node = node.trim();
    }
    return node;
  }
}

const initialState: IEditClientState = {
  name: '',
  numberOfDivisions: 0,
  numberOfLocations: 0,
  numberOfEmployees: 0,
  numberOfPhysicians: 0,
  medicalSpeciality: null,
  historicalAnnualSpend: 0,
  startDate: null,
  primaryPreviousSuppliers: [],
  meetingSchedule: '',
  orderingRestriction: '',
  approvalRestriction: '',
  scManager: null,
  status: 'loading',
};

interface IClientEditProps extends IConnectedActions {
  match: match<{
    id: string,
  }>;
}

class Edit extends React.Component<IClientEditProps, IEditClientState> {

  isNew: boolean;

  constructor(props) {
    super(props);

    this.state = {
      ...initialState,
      filter: '',
    };

    this.isNew = !this.props.match.params.id;

    this.mapDateToString = this.mapDateToString.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);

  }

  componentDidMount() {
    !this.isNew ?
      this.getClient() :
      this.setState({ status: 'done' });
  }

  getClient(): void {
    this.setState({ status: 'loading' }, () => {

      const clientId = +this.props.match.params.id;

      clientsApi.getClientForEdit(clientId)
        .then(clientData => {
          this.setState({
            ...replaceNullsWithZero(clientData),
            primaryPreviousSuppliers: this.mapSuppliersToEdit(clientData.primaryPreviousSuppliers),
            status: 'done',
          });
        })
        .catch(e => {
          this.props.notify(e, 'error');
        });

    });
  }

  mapDateToString(date) {
    return moment(date).isValid() ? moment(date).format('YYYY-MM-DD') : null;
  }

  mapSuppliers(items) {
    const newValues = items.map(item => {
      return item.supplier;
    });

    return newValues;
  }

  mapSuppliersToEdit(items): ISuppliersEdit[] {
    const newValues = items.map(item => {
      return { supplier: item, id: item };
    });

    return newValues;
  }

  convertEmptyToNull(data) {
    const options = data;
    for (const param in options) {
      if (!options[param] && options[param] !== 0) {
        options[param] = null;
      }
    }
    return options;
  }

  handleSubmit(values: IEditClientState) {
    if (!values.scManager) {
      this.props.prompt({
        title: 'Warning!',
        question: 'This client will not be able to process returns without a designated supply chain manager.',
        confirmText: 'Proceed',
        cancelText: 'Cancel',
      }).then(() => {
        this.saveChanges(values);
      }).catch(() => { });
    } else {
      this.saveChanges(values);
    }
  }

  saveChanges({ scManager, startDate, primaryPreviousSuppliers, medicalSpeciality, ...restValues }: IEditClientState) {
    let  newValues = this.convertEmptyToNull({
      ...restValues,
      startDate: this.mapDateToString(startDate),
      primaryPreviousSuppliers: this.mapSuppliers(primaryPreviousSuppliers),
      medicalSpecialityId: (medicalSpeciality && medicalSpeciality.id) || null,
      scManagerId: scManager ? scManager.id : null,
    });

    newValues = new TrimMapper(newValues).toJSON();
    
    this.setState({
      status: 'loading',
    }, () => {
      if (this.isNew) {
        clientsApi.createClient(newValues)
          .then(() => {
            history.push('/admin/clients');
          })
          .catch(e => {
            this.props.notify(e, 'error');
          });
      } else {
        clientsApi.updateClient(newValues)
          .then(() => {
            history.push('/admin/clients');
          })
          .catch(e => {
            this.props.notify(e, 'error');
          });
      }
    });


  }

  render() {
    return (
      <MainContainer
        scale="md"
        title={`${this.isNew ? 'create new' : 'edit'} client`}
        subTitle={`Please fill out all the fields below properly to ${this.isNew ? 'create a new' : 'edit a'} client`}
        centered
      >
        {
          <Formik
            enableReinitialize
            initialValues={this.state}
            validationSchema={validationSchema}
            onSubmit={this.handleSubmit}
            render={props => <Form
              {...props}
              isLoading={this.state.status === 'loading'}
              isNew={this.isNew}
            />}
          />
        }
      </MainContainer>
    );
  }
}

interface IConnectedActions {
  setSearch: typeof actionsSearch.set;
  prompt: IOpenConfirmPrompt;
  notify: typeof actionsNotification.notifySwagger;
}

export default connect(
  null,
  (dispatch) => bindActionCreators({
    setSearch: actionsSearch.set,
    prompt: prompt as any as IOpenConfirmPrompt,
    notify: actionsNotification.notifySwagger,
  }, dispatch)
)(Edit);

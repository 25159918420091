import {Dispatch} from 'redux';
import {types} from './_actionTypes/current-user';
import {IActionClearAuthData} from './_reducers/current-user';

class HTTPProxy {

  dispatch: Dispatch<IActionClearAuthData>;

  constructor() {
    this.dispatch = null;
  }

  public setDispatch(dispatch) {
    this.dispatch = dispatch;
  }

  public fetch(...args: Parameters<typeof window.fetch>) {
    return window.fetch(...args)
      .then(res => {
        if (res.status === 401) {
          if (this.dispatch) {
            this.dispatch({
              type: types.CURRENT_USER_CLEAR_AUTH_DATA,
            });
          }

        }
        return res;
      });
  }
}

const httpProxy = new HTTPProxy();

export default httpProxy;

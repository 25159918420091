import styled, { keyframes, StyledComponentClass } from 'styled-components';
import { IThemeProps } from '../assets/theme';

const animation = keyframes`
  0% {
    background-position: 150%;
  }
  40%, 100% {
    background-position: 0%;
  }
`;

export interface ILine extends IThemeProps{
  randomSize?: boolean;
  size?: string;
}

export const Line = styled.span<ILine>`
  ${({ theme, ...props }) => {

    const {
      global, primary, lighter, lightest,
    } = theme;

    const sizes = {
      'xs': '20px',
      'sm': '100px',
      'md': '200px',
      'lg': '300px',
    };

    let size;

    if (props.randomSize) {
      const sizeIndex = Math.floor((Math.random() * Object.keys(sizes).length) + 0);
      const sizeKey = Object.keys(sizes)[sizeIndex];
      size = sizes[sizeKey];
    } else {
      size = sizes[props.size] || '200px';
    }

    return `
      ${global}
      height: 12px;
      display: inline-block;
      width: ${size};
      background-color: ${primary};
      background-image: linear-gradient(90deg, ${lighter} 0%, ${lightest}  70%, ${lightest}  80%, ${lighter} 100%);
      background-size: 600px;
      animation: ${animation} 1.6s infinite linear;
      border-radius: 5px;
    `;
  } }
` as unknown as StyledComponentClass<ILine, any>;

Line.defaultProps = {
  size: 'md'
};

export interface ISquare extends IThemeProps{
  size?: string;
}

export const Square = styled.div<ISquare>`
  ${({ theme, ...props }) => {

    const {
      global, primary, lighter, lightest,
    } = theme;

    let size;

    switch (props.size) {
      case 'xs':
        size = '34px';
        break;
      case 'sm':
        size = '50px';
        break;
      case 'md':
        size = '72px';
        break;
      case 'lg':
        size = '75px';
        break;
      case 'xl':
        size = '230px';
        break;
      default:
        size = '72px';
        break;
    }

    return `
      ${global}
      height: ${size};
      display: block;
      width: ${size};
      background-color: ${primary};
      background-image: linear-gradient(90deg, ${lighter} 0%, ${lightest}  70%, ${lightest}  80%, ${lighter} 100%);
      background-size: 600px;
      animation: ${animation} 1.6s infinite linear;
      border-radius: 5px;
    `;
  } }
` as unknown as StyledComponentClass<ISquare, any>;

Square.defaultProps = {
  size: 'md'
};

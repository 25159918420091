import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import {
  TicIcon,
  RectIcon,
} from '../assets/icons';

const Tic = styled(({ className, checked, indeterminate }) => {
  return <div className={className}>
    {
      checked && <TicIcon />
    }
    {
      (checked === false && indeterminate) && <RectIcon />
    }
  </div>;
})`
  transition: all ease-in-out .5s;
`;

const Input = styled.input.attrs({
  ...props => props,
  type: 'checkbox',
})`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 20px;
  height: 100%;
  margin: 0;
  display: block;
  opacity: 0;
`;

const Label = styled.label`
  ${ props => {

    let {
      light,
      primary,
    } = props.theme;

    let labelColor = props.disabled ?
      light :
      primary;

    return `
        color: ${labelColor};

        font-size: 13px;
        font-weight: 400;
        margin: 0 0 0 7px;
      `;
  }
  }
`;

const Checkbox = styled(({
  className,
  label,
  value,
  onChange,
  indeterminate,
  ...inputProps
}) => {

  let checked = value;

  function handleClick(event) {
    if (onChange) {
      onChange(event.target.checked);
    }
  }

  return (
    <div
      className={`${className} ${inputProps.disabled ? className + ' disabled' : ''}`}
    >

      <Tic checked={checked} indeterminate={indeterminate} />

      <Input onChange={handleClick} {...inputProps} checked={checked} />

      {
        label &&
        <Label
          htmlFor={inputProps.id ? inputProps.id : null}
        >
          {`${label}`}
        </Label>
      }

    </div>
  );
})`
  ${({ theme, ...props }) => {

    let {
      global,
      light,
      main,
    } = theme;

    // regular
    let mainColor;
    if (props.disabled) {

      mainColor = light;

    } else {

      /* 
       * @todo remake with ifs
       */
      mainColor = props.value ?
        theme.default :
        props.indeterminate && !props.disabled ?
          theme.default :
          light;

    }

    return `

      ${global}

      display: flex;
      align-items: center;
      position: relative;
      
      * {
        cursor: pointer;
      }

      &.disabled * {
        cursor: default;
      }

      .${Tic.styledComponentId}{
        
        display: inline-block;

        background: ${main}
        border: 1px solid ${mainColor};
        
        width: 20px;
        height: 20px;
        flex: 0 0 20px;

        path{
          fill: ${mainColor};
        }

      }

    `;
  }
  }
`;

Checkbox.propTypes = {
  className: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.bool,
  indeterminate: PropTypes.bool,
  onChange: PropTypes.func,
};

Checkbox.defaultProps = {
  value: false,
  tabIndex: 1,
};

export default Checkbox;
import React from 'react';
import styled from 'styled-components';
import keycode from 'keycode';

import {
  Input as NumberInput,
  Body as NumberBody,
} from '../components/Number';

import Number from '../components/Number';
import Select from './Select';
import Button from '../components/Button';

import {
  CaretIcon,
} from '../assets/icons';

// @TODO - need to add display name for each element

const Position = {
  Container: styled.div`
    display: flex;
    flex-wrap: wrap;
    margin: 0 -10px;
    align-items: center;
  `,
  Info: styled.div`
    flex-basis: auto;
    flex-grow: 1;
    margin: 0 10px;
    align-items: center;
  `,
  Toolbar: styled.div`
    flex-basis: auto;
    flex-grow: 0;
    flex-wrap: nowrap;
    margin: 0 5px;
    display: flex;
  `,
};

export interface IPaginationProps {
  skip: number;
  perPage: number;
  count: number;
  onChange: (data: {
    skip: number;
    perPage: number;
    count: number;
  }) => void;
  className: string;
}

export interface IPaginationState {
  skip?: number;
  perPage?: number;
  count?: number;
}

export interface ICheckStateChanges {
  isChanged: boolean;
  stateChanges: IPaginationState;
}

const Pagnation = styled(class Pagination extends React.Component<IPaginationProps, IPaginationState> {

  public showPerPage;

  constructor(props) {
    super(props);
    this.showPerPage = [
      { label: 10, value: 10 },
      { label: 25, value: 25 },
      { label: 50, value: 50 },
    ];
    this.state = {
      skip: this.props.skip,
      perPage: this.props.perPage,
      count: this.props.count,
    };

    this.handleSelect = this.handleSelect.bind(this);
    this.handleGoBack = this.handleGoBack.bind(this);
    this.handleGoForward = this.handleGoForward.bind(this);
    this.handlePageChange = this.handlePageChange.bind(this);
    this.handlePageEdit = this.handlePageEdit.bind(this);
    this.handlePageEnter = this.handlePageEnter.bind(this);

  }

  componentDidMount() {
    this.setState({
      skip: this.props.skip,
      perPage: this.props.perPage,
      count: this.props.count,
    });
  }

  componentDidUpdate(prevProps: IPaginationProps, prevState: IPaginationState) {
    const stateChangesData = Pagination.checkStateChanges(this.state as IPaginationProps, prevState);
    const propsChangesData = Pagination.checkStateChanges(this.props, prevProps);
    if (stateChangesData.isChanged) {
      this.setState(stateChangesData.stateChanges);
    } else if (propsChangesData.isChanged) {
      this.setState(propsChangesData.stateChanges);
    }
  }

  static checkStateChanges(props: IPaginationProps, state: IPaginationState): ICheckStateChanges {
    const changedParams = [];
    const stateChanges: {
      skip?: number;
      perPage?: number;
      count?: number;
    } = {};

    for (const param in state) {
      if (state[param] !== props[param]) {
        stateChanges[param] = props[param];
        changedParams.push(stateChanges);
      }
    }

    const isChanged = !!changedParams.length;
    const currentPage = Math.ceil(stateChanges.skip / stateChanges.perPage) + 1;

    if (stateChanges.count <= currentPage) {
      stateChanges.skip = 0;
    }

    return {
      isChanged,
      stateChanges,
    };
  }

  handleSelect(value) {
    if (this.props.onChange) {
      this.props.onChange({
        count: this.state.count,
        skip: 0,
        perPage: value.value,
      });
    }
  }

  handleGoBack() {
    if (this.props.onChange) {
      this.props.onChange({
        perPage: this.state.perPage,
        count: this.state.count,
        skip: this.state.skip - this.state.perPage,
      });
    }
  }

  handleGoForward() {
    if (this.props.onChange) {
      this.props.onChange({
        perPage: this.state.perPage,
        count: this.state.count,
        skip: this.state.skip + this.state.perPage,
      });
    }
  }

  handlePageChange(event) {
    const totalPages = Math.ceil(this.state.count / this.state.perPage);
    let page = event.target.value;
    if (page <= 0) {
      page = 1;
    } else if (page > totalPages) {
      page = totalPages ? totalPages : 1;
    }
    const skip = (page - 1) * this.state.perPage;

    if (this.props.onChange) {
      this.props.onChange({
        perPage: this.state.perPage,
        count: this.state.count,
        skip
      });
    }
  }

  handlePageEnter(event) {
    if (keycode(event.which) === 'enter' || keycode(event.keyCode) === 'enter') {
      this.handlePageChange(event);
    }
  }

  handlePageEdit(page) {
    const skip = (page - 1) * this.state.perPage;

    this.setState({
      skip,
    });
  }

  render() {
    const currentPage = Math.ceil(this.state.skip / this.state.perPage) + 1;
    const totalPages = this.state.count ? Math.ceil(this.state.count / this.state.perPage) : 1;
    const total = this.state.count;
    let startFrom = this.state.skip + 1;
    let endBy = this.state.skip + this.state.perPage;
    startFrom = (startFrom <= 0) ? 0 : startFrom;
    endBy = (endBy > total) ? total : endBy;

    return <div className={this.props.className}>
      <Position.Container>
        <Select
          label="Show per page"
          compact
          options={this.showPerPage}
          value={this.state.perPage}
          onChange={this.handleSelect}
          clearable={false}
        />
        <Position.Info>
          Showing {startFrom} to {endBy} of {total} items
        </Position.Info>
        <Position.Toolbar>
          {
            (currentPage > 1) &&
            <Button
              primary
              negative
              onClick={this.handleGoBack}
            >
              <CaretIcon />
            </Button>
          }
          <Number
            suffix="/"
            compact
            controls={false}
            value={currentPage}
            onChange={this.handlePageEdit}
            onBlur={this.handlePageChange}
            onKeyPress={this.handlePageEnter}
          />
          <Number
            compact
            controls={false}
            disabled
            type="number"
            min={1}
            value={totalPages}
          />
          {
            (currentPage < totalPages) &&
            <Button
              primary
              negative
              onClick={this.handleGoForward}
            >
              <CaretIcon />
            </Button>
          }
        </Position.Toolbar>
      </Position.Container>
    </div>;
  }
})`
  ${({ theme }) => {
    const {
      global,
      fs_md,
    } = theme;
    return `
      ${global}
      padding: 15px 20px;
      font-size: ${fs_md};
    `;
  }}

  ${Select}{
    width: 210px;
    margin: 5px 10px;
  }
  
  ${Button}{
    min-width: 40px;
    margin: 5px 5px;
    padding: 7px;
    svg{
      transform: rotate(90deg);
    }
  }

  ${Number} + ${Button}{
    svg{
      transform: rotate(-90deg);
    }
  }

  ${Number}{
    width: 55px;
    display: inline-block;
    margin: 5px 5px;
    ${NumberBody} {
      height: 100%;
    }
    ${NumberInput} {
      text-align: center;
      height: 100%;
      padding: 10px 0;
    }
  }

  ${Number} + ${Number}{
    width: 40px;
    margin: 5px 5px;
  }

`;

export default Pagnation;

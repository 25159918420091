import * as React from "react";
import { Action, ActionCreatorsMapObject, bindActionCreators } from "redux";
import { connect } from "react-redux";
import { Formik } from "formik";
import * as Yup from "yup";

import * as actions from "../../_actions/current-user";
import Container from "./ForgotPasswordContainer";
import ForgotPasswordForm from "./ForgotPasswordForm";
import { Link } from "../../components";
import { AuthenticateModelWithEmail } from "../../service-proxies";
import { createStructuredSelector } from "reselect";
import { IGlobalStore } from "../../_reducers/reducers";

const loginValidationSchema = Yup.object().shape({
  email: Yup.string()
    .required("Email is required!")
    .email()
});

const loginInitialValues: AuthenticateModelWithEmail = {
  email: ""
};

interface ILoginProps extends IConnectedActions, IConnectedProps {}

class Login extends React.Component<ILoginProps> {
  constructor(props) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleSubmit(values, { setSubmitting, setErrors }) {
    setSubmitting(true);
    this.props.login(values).then(() => {
      setSubmitting(false);
    });
  }

  render() {
    return (
      <Container>
        <div className="logo">
          <img src="/img/otto-logo.svg" width="140" alt="Otto logo" />
        </div>

        <Formik
          initialValues={loginInitialValues}
          validationSchema={loginValidationSchema}
          onSubmit={this.handleSubmit}
          render={props => (
            <ForgotPasswordForm
              {...props}
              errorMessage={this.props.errorMessage}
              errorDetails={this.props.errorDetails}
            />
          )}
        />

        <Link primary to="/">
          Return to Login
        </Link>
      </Container>
    );
  }
}

interface IConnectedProps {
  errorMessage: string | null;
  errorDetails: string | null;
}

interface IConnectedActions {
  login: (data: AuthenticateModelWithEmail) => Promise<void>;
}

export default connect(
  createStructuredSelector<IGlobalStore, IConnectedProps>({
    errorMessage: state =>
      state.currentUser.loginError
        ? state.currentUser.loginError.message
        : null,
    errorDetails: state =>
      state.currentUser.loginError ? state.currentUser.loginError.details : null
  }),
  dispatch =>
    bindActionCreators<IConnectedActions & ActionCreatorsMapObject, ActionCreatorsMapObject<Action>>(
      {
        login: (actions.login as any) as (
          data: AuthenticateModelWithEmail
        ) => Promise<void>
      },
      dispatch
    )
)(Login);

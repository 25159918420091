import * as React from "react";
import { connect } from "react-redux";
import { RouteComponentProps } from "react-router";
import styled from "styled-components";
import immer from "immer";
import CatalogTree from "./CatalogTree";
import {
  prompt as openConfirm,
  alert,
  openCustomModalCallback,
  IOpenCustomModalCallback,
  IOpenAlert,
  IOpenConfirmPrompt
} from "../../../_actions/modal";
import {
  collectChildrenInfoRecursive,
  extractSelectionTreeFromCatalog,
  extractSelectionForRemoving,
  getSelectionAndExpandedMapFromTree,
  findNodePath,
  removeSelectedFromTree,
  getTreeNodeByPath,
  sortNameItems,
  cleanMovedProducts
} from "../../../_utils/clientCatalogEditor";
import { SimpleMap } from "../../../_types/common";
import {
  TreeNode,
  SelectedNode,
  IExGetCategoriesDto,
  IModCategoryProductDto,
  NodePathType
} from "../../../_types/clientCatalogEditor";
import {
  getCatalogForEdit,
  getClientCatalog,
  getMasterCatalog,
  addSelectedTreeToClientCatalog,
  removeSelectedTreeFromClientCatalog,
  renameClientCategory,
  createCategoryInClientCatalog,
  getProductsByCategory,
  IAddTreeToClientResultItem
} from "../../../_api/clientCatalog";
import withGranted, { IWithGrantedProps } from "../../HOC/WithGranted";
import { ADMINISTRATION_CLIENTCATALOGUE_EDIT } from "../../../_constants/permissions";
import { Select, Button, PromptModal } from "../../../components";
import {
  CategoryProductDto,
  CategoryProductsDto
} from "../../../service-proxies";

const Container = styled.div`
  display: flex;
  background-color: ${({ theme }) => theme.main};
  margin: 2px auto 1px auto;
  min-height: 720px;
  width: 100%;
`;

const LeftPanel = styled.div`
  flex: 1 0 50%;
  min-width: 0;
  border-right: 2px solid ${({ theme }) => theme.lighter};
`;

const LeftPanelHeader = styled.div`
  border-bottom: 1px solid ${({ theme }) => theme.lighter};
  padding: 16px;
  height: 44px;
`;

const LeftPanelTitle = styled.div`
  font-size: 16px;
  font-weight: bold;
  display: block;
  padding: 4px 0;
`;

const LeftPanelSubtitle = styled.div`
  font-size: 14px;
  font-weight: normal;
  display: block;
`;

const PanelFilter = styled.div`
  padding: 12px 16px 10px;
  border-bottom: 1px solid ${({ theme }) => theme.lighter};
`;

const PanelTreeHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  text-transform: uppercase;
  padding: 12px 16px 8px;
`;

const PanelTreeTitle = styled.div`
  font-size: 16px;
  font-weight: bold;
  padding-top: 8px;
`;

const PanelActionButton = styled.div`
  ${Button} {
    background-color: #f9f9f9;
    border: solid 1px ${({ theme }) => theme.light};
    color: #424242;
    font-weight: normal;
    font-size: 13px;
    outline: none;
    &:disabled {
      background-color: #eee;
      color: #bbb;
    }
  }
`;

const RightPanel = styled.div`
  flex: 1 0 50%;
  min-width: 0;
  border-left: 2px solid ${({ theme }) => theme.lighter};
`;

const RightPanelHeader = styled.div`
  border-bottom: 1px solid ${({ theme }) => theme.lighter};
  padding: 16px;
  height: 44px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const PositionSelect = styled.div`
  max-width: 200px;
`;

interface ICategories {
  categoryId: number;
  path: number[];
}

type ListCategoriesToLoad = ICategories[];

type StateType = {
  masterCatalog: TreeNode[];
  clientCatalog: TreeNode[];
  isLoading: boolean;
  catalogName: string;
  selectedMasterCatalog: TreeNode[];
  selectedClientCatalog: TreeNode[];
  selectedMasterCategoryId: number;
  selectedMasterCategoryName: string;
  selectedClientCategoryName: string;
  selectedClientCategoryId: number;
  selectedMasterMap: SimpleMap;
  selectedClientMap: SimpleMap;
  expandedMasterMap: SimpleMap;
  expandedClientMap: SimpleMap;
  selectedMasterCount: number;
  selectedClientCount: number;
};

type PropsType = {
  openModal: IOpenCustomModalCallback;
  openConfirm: IOpenConfirmPrompt;
  alertModal: IOpenAlert;
} & RouteComponentProps<{ catalogId: string }> &
  IWithGrantedProps;

class Catalog extends React.Component<PropsType, StateType> {
  catalogId: number;
  editPermission: boolean;

  constructor(props) {
    super(props);

    this.catalogId = parseInt(props.match.params.catalogId);
    this.editPermission = props.isGranted(ADMINISTRATION_CLIENTCATALOGUE_EDIT);

    this.state = {
      isLoading: true,
      catalogName: "",
      masterCatalog: [],
      clientCatalog: [],
      selectedMasterCatalog: [],
      selectedClientCatalog: [],
      selectedMasterCategoryId: null,
      selectedMasterCategoryName: "/",
      selectedClientCategoryId: null,
      selectedClientCategoryName: "/",
      selectedMasterMap: {},
      selectedMasterCount: 0,
      expandedMasterMap: {},
      selectedClientMap: {},
      selectedClientCount: 0,
      expandedClientMap: {}
    };

    this.handleSelectMasterCategory = this.handleSelectMasterCategory.bind(
      this
    );
    this.handleAddSelected = this.handleAddSelected.bind(this);
    this.handleAddCategory = this.handleAddCategory.bind(this);
    this.handleAddCategoryModal = this.handleAddCategoryModal.bind(this);
    this.handleSelectClientCategory = this.handleSelectClientCategory.bind(
      this
    );
    this.handleOnClientCatalogSelect = this.handleOnClientCatalogSelect.bind(
      this
    );
    this.handleOnMasterCatalogSelect = this.handleOnMasterCatalogSelect.bind(
      this
    );
    this.handleMasterTrigger = this.handleMasterTrigger.bind(this);
    this.handleClientTrigger = this.handleClientTrigger.bind(this);
    this.handleRemoveSelected = this.handleRemoveSelected.bind(this);
    this.handleSingleRemoveProduct = this.handleSingleRemoveProduct.bind(this);
    this.handleRenameCategory = this.handleRenameCategory.bind(this);
  }

  componentDidMount() {
    Promise.all([
      getMasterCatalog(),
      getCatalogForEdit(this.catalogId),
      getClientCatalog(this.catalogId)
    ]).then(([masterCatalog, catalogInfo, clientCatalog]) => {
      this.setState({
        isLoading: false,
        catalogName: catalogInfo.name,
        masterCatalog,
        clientCatalog,
        selectedMasterCatalog: masterCatalog,
        selectedClientCatalog: clientCatalog
      });
    });
  }

  handleSelectMasterCategory(value) {
    this._handleSelectCategory(true, value);
  }
  handleSelectClientCategory(value) {
    this._handleSelectCategory(false, value);
  }

  _handleSelectCategory(isMaster, value) {
    const masterClient = isMaster ? "Master" : "Client";
    if (!value) {
      this.setState({
        [`selected${masterClient}CategoryId`]: null,
        [`selected${masterClient}CategoryName`]: "/",
        [`selected${masterClient}Map`]: {},
        [`selected${masterClient}Count`]: 0,
        [`expanded${masterClient}Map`]: {},
        [`selected${masterClient}Catalog`]: isMaster
          ? this.state.masterCatalog
          : this.state.clientCatalog
      } as StateType);
      return;
    }
    if (value.id === this.state[`selected${masterClient}CategoryId`]) {
      return;
    }

    const catalogNode: IExGetCategoriesDto = (isMaster
      ? this.state.masterCatalog
      : this.state.clientCatalog
    ).find(m => m.id === value.id) as IExGetCategoriesDto;

    let catalogWithPreloaders = null;
    if (
      !catalogNode.productsLoaded &&
      catalogNode.childCategories.length === 0
    ) {
      const categoriesToLoad = [{ categoryId: value.id, path: null }];
      catalogWithPreloaders = this.showPreloaders(isMaster, categoriesToLoad);
      this.loadProductsIntoCatalog(isMaster, categoriesToLoad, false);
    }
    this.setState({
      [`selected${masterClient}CategoryId`]: value.id,
      [`selected${masterClient}CategoryName`]: value.name,
      [`selected${masterClient}Map`]: {},
      [`selected${masterClient}Count`]: 0,
      [`expanded${masterClient}Map`]: {},
      [`selected${masterClient}Catalog`]: catalogWithPreloaders
        ? catalogWithPreloaders
        : catalogNode.childCategories
    } as StateType);
  }

  validateAddFromMaster(
    selectionTree: SelectedNode[],
    counter: number,
    nodeToAdd: IExGetCategoriesDto,
    clientSelectionTree: SelectedNode[] | null
  ) {
    if (counter !== this.state.selectedMasterCount) {
      return "Selection is inconsistent and contains unselected category in the hierarchy.";
    }

    const isAllProducts = selectionTree.every(node => !node.children);
    const isAllCategories = selectionTree.every(node => !!node.children);

    if (!isAllProducts && !isAllCategories) {
      return "Category and product cannot be added on the same level.";
    }

    if (
      isAllProducts &&
      this.state.selectedClientCount === 0 &&
      this.state.selectedClientCategoryId === null
    ) {
      return "You cannot add products into the root of client catalog.";
    }

    if (clientSelectionTree && clientSelectionTree.length > 1) {
      return "You cannot add selected items into several categories. Please make sure that you have one or no selected category in the right panel.";
    }

    if (
      this.state.selectedClientCount === 1 &&
      Object.keys(this.state.selectedClientMap)[0]
        .toString()
        .startsWith("product")
    ) {
      return "You cannot add selection into the product. Please make sure that you have one or no selected category in the right panel.";
    }

    if (
      nodeToAdd &&
      nodeToAdd.childCategories &&
      nodeToAdd.childCategories.length
    ) {
      if (
        isAllCategories &&
        nodeToAdd.childCategories.some(n =>
          n.id.toString().startsWith("product")
        )
      ) {
        return "You cannot add subcategories into a category that contains products. Remove products first.";
      }

      if (isAllProducts) {
        // check if some products are already in the category
        if (
          selectionTree.some(n => {
            const productPrefix = `product-${(n.id as string).split("-")[1]}-`;
            return nodeToAdd.childCategories.some(nn =>
              nn.id.toString().startsWith(productPrefix)
            );
          })
        ) {
          return "Some product is already exists in selected client category.";
        }
      }
    }

    if (
      isAllProducts &&
      nodeToAdd &&
      nodeToAdd.childCategories &&
      nodeToAdd.childCategories.length
    ) {
      // check if destination category contains any subcategories
      if (
        nodeToAdd.childCategories
          .filter(n => !(n as IModCategoryProductDto).noProducts)
          .some(node => !node.id.toString().startsWith("product"))
      ) {
        return "Destination category already contains subcategories and cannot contain products.";
      }
    }

    return null;
  }

  handleAddSelected() {
    const selectionTree: IModCategoryProductDto[] = [];
    const parentNode =
      this.state.selectedMasterCategoryId &&
      this.state.masterCatalog.find(
        it => it.id === this.state.selectedMasterCategoryId
      );
    const counter = extractSelectionTreeFromCatalog(
      this.state.selectedMasterCatalog,
      this.state.selectedMasterMap,
      parentNode as IModCategoryProductDto,
      selectionTree
    );

    const clientSelectionTree: IModCategoryProductDto[] = [];
    const clientParentNode =
      this.state.selectedClientCategoryId &&
      this.state.clientCatalog.find(
        it => it.id === this.state.selectedClientCategoryId
      );
    extractSelectionTreeFromCatalog(
      this.state.selectedClientCatalog,
      this.state.selectedClientMap,
      clientParentNode as IModCategoryProductDto,
      clientSelectionTree
    );

    let addToNodeId = this.state.selectedClientCategoryId;
    let nodePath = null;

    if (this.state.selectedClientCount === 1) {
      // if selected some category/product to add to it
      let nodeId: string | number = Object.keys(
        this.state.selectedClientMap
      )[0];
      nodeId = nodeId.toString().startsWith("product")
        ? nodeId
        : parseInt(nodeId, 10);
      nodePath = findNodePath(this.state.clientCatalog, nodeId);
      if (!nodePath) {
        console.error("Cannot find path to selected node 1");
        return;
      }
      addToNodeId = nodePath.node.id;
    } else if (
      this.state.selectedClientCount === 0 &&
      this.state.selectedClientCategoryId
    ) {
      nodePath = findNodePath(
        this.state.clientCatalog,
        this.state.selectedClientCategoryId
      );
      if (!nodePath) {
        console.error("Cannot find path to selected node 2");
        return;
      }
      addToNodeId = nodePath.node.id;
    } else if (
      this.state.selectedClientCount > 1 &&
      clientSelectionTree.length === 1
    ) {
      // add product into a category despite it has sub-selections
      nodePath = findNodePath(
        this.state.clientCatalog,
        clientSelectionTree[0].id
      );
      if (!nodePath) {
        console.error("Cannot find path to selected node 3");
        return;
      }
      addToNodeId = nodePath.node.id;
    }

    const validMessage = this.validateAddFromMaster(
      selectionTree,
      counter,
      nodePath && nodePath.node,
      clientSelectionTree
    );

    if (validMessage) {
      this.props.alertModal(validMessage, "Validation Error").catch(() => {});
      return;
    }

    const movedProducts: CategoryProductsDto[] = [];

    this.props
      .openConfirm({
        title: "Add Items To Client Catalog",
        question:
          "Are you sure that you want to add selected items to client's catalog?",
        promise: () =>
          addSelectedTreeToClientCatalog(
            addToNodeId,
            this.catalogId,
            selectionTree,
            movedProducts
          )
      })
      .then(resTree => {
        let updatedCatalog: TreeNode[] = this.state.clientCatalog;
        if (nodePath) {
          updatedCatalog = immer(
            updatedCatalog,
            (draft: TreeNode[]): void | TreeNode[] => {
              const node = getTreeNodeByPath(
                draft,
                nodePath.path
              ) as IExGetCategoriesDto;
              if (movedProducts.length) {
                cleanMovedProducts(draft, movedProducts);
              }
              node.childCategories = sortNameItems(
                node.childCategories
                  .concat(resTree)
                  .filter(n => !(n as IModCategoryProductDto).noProducts)
              ) as TreeNode[];
            }
          );
        } else {
          if (movedProducts.length) {
            updatedCatalog = immer(
              updatedCatalog,
              (draft: TreeNode[]): void | TreeNode[] => {
                cleanMovedProducts(draft, movedProducts);
              }
            );
          }
          updatedCatalog = sortNameItems(
            updatedCatalog
              .concat(resTree)
              .filter(n => !(n as IModCategoryProductDto).noProducts)
          ) as TreeNode[];
        }
        const selectedMap = {};
        const expandedMap = { ...this.state.expandedClientMap };

        getSelectionAndExpandedMapFromTree(resTree, selectedMap, expandedMap);

        this.setState({
          clientCatalog: updatedCatalog,
          selectedClientCatalog: this.getSelectedCatalog(
            updatedCatalog,
            this.state.selectedClientCategoryId
          ),
          selectedMasterMap: {},
          selectedMasterCount: 0,
          selectedClientMap: selectedMap,
          expandedClientMap: expandedMap,
          selectedClientCount: Object.keys(selectedMap).length
        });
      })
      .catch(e => {
        console.log("exception in handleAddSelected", e);
      });
  }

  validateSelectionForAddingCategory(
    clientSelectionTree: SelectedNode[] | null
  ): void | string {
    if (clientSelectionTree && clientSelectionTree.length > 1) {
      return "Please select only one category if you want to add a subcategory.";
    }

    if (this.state.selectedClientCount === 1) {
      const nodeId = Object.keys(this.state.selectedClientMap)[0];

      if (nodeId.toString().startsWith("product")) {
        return "You cannot add subcategory to products.";
      }

      const nodePath = findNodePath(
        this.state.selectedClientCatalog,
        parseInt(Object.keys(this.state.selectedClientMap)[0], 10)
      );
      if (!nodePath) {
        console.error("Cannot find path to selected node");
        return "Validation error";
      }
    }
  }

  handleAddCategoryModal() {
    const clientSelectionTree: SelectedNode[] = [];
    const clientParentNode =
      this.state.selectedClientCategoryId &&
      this.state.clientCatalog.find(
        it => it.id === this.state.selectedClientCategoryId
      );
    extractSelectionTreeFromCatalog(
      this.state.selectedClientCatalog,
      this.state.selectedClientMap,
      clientParentNode as IModCategoryProductDto,
      clientSelectionTree
    );

    const validMessage = this.validateSelectionForAddingCategory(
      clientSelectionTree
    );

    if (validMessage) {
      this.props.alertModal(validMessage, "Validation Error").catch(() => {});
      return;
    }

    if (
      this.state.selectedClientCount > 0 &&
      clientSelectionTree.length === 0
    ) {
      console.error("internal error at handleAddCategoryModal");
      return;
    }

    const parentCategoryId =
      this.state.selectedClientCount > 0
        ? (clientSelectionTree[0].id as number)
        : this.state.selectedClientCategoryId;

    this.props
      .openModal(
        (resolve, reject) => (
          <PromptModal
            label="Category name"
            required
            maxLength={100}
            resolve={resolve}
            reject={reject}
            saveButtonText="Add"
            onSubmit={name =>
              createCategoryInClientCatalog(
                parentCategoryId,
                name,
                this.catalogId
              )
            }
          />
        ),
        { title: "NEW CLIENT CATEGORY" }
      )
      .then(res => {
        this.handleAddCategory(res, clientSelectionTree);
      })
      .catch(() => {});
  }

  handleAddCategory(
    data: IAddTreeToClientResultItem,
    clientSelectionTree: SelectedNode[] | null
  ) {
    const selectedClientMap: SimpleMap = { [data.id]: true } as SimpleMap;
    const expandedMap: SimpleMap = { ...this.state.expandedClientMap };
    expandedMap[data.id] = true;

    if (this.state.selectedClientCount > 0) {
      // if selected some category add to it

      let nodePath: NodePathType | null = null;
      if (this.state.selectedClientCount === 1) {
        nodePath = findNodePath(
          this.state.selectedClientCatalog,
          parseInt(Object.keys(this.state.selectedClientMap)[0], 10)
        );
      } else if (
        this.state.selectedClientCount > 1 &&
        clientSelectionTree &&
        clientSelectionTree.length === 1
      ) {
        nodePath = findNodePath(
          this.state.selectedClientCatalog,
          clientSelectionTree[0].id
        );
      }
      if (!nodePath) {
        console.error("Cannot find path to selected node");
        return;
      }

      let newCatPath;

      const selectedClientCatalog = immer(
        this.state.selectedClientCatalog,
        draft => {
          const node = getTreeNodeByPath(
            draft,
            nodePath.path
          ) as IExGetCategoriesDto;
          const sortedCategories = sortNameItems(
            node.childCategories
              .filter(
                n =>
                  !(
                    (n as IModCategoryProductDto).noProducts ||
                    n.id.toString().startsWith("product")
                  )
              )
              .concat({
                id: data.id as number,
                name: data.name,
                childCategories: [
                  {
                    id: `preloader-1`,
                    preloader: true
                  } as IModCategoryProductDto
                ]
              })
          ) as TreeNode[];
          node.childCategories = sortedCategories;
          const index = sortedCategories.findIndex(item => item.id === data.id);
          newCatPath = nodePath.path.concat([index]);
          return undefined;
        }
      );

      const categoriesToLoad = [{ categoryId: data.id, path: newCatPath }];

      this.setState(
        {
          expandedClientMap: expandedMap,
          selectedClientMap,
          selectedClientCount: 1,
          selectedClientCatalog
        },
        () => {
          this.loadProductsIntoCatalog(false, categoriesToLoad, false);
        }
      );
      return;
    }

    if (this.state.selectedClientCategoryId === null) {
      let selectedCatalog: TreeNode[] = this.state.clientCatalog.slice();
      selectedCatalog.push({
        id: data.id as number,
        name: data.name,
        childCategories: [
          { id: "no-products", name: "No products", noProducts: true }
        ]
      } as IExGetCategoriesDto);

      selectedCatalog = sortNameItems(selectedCatalog) as TreeNode[];

      this.setState({
        clientCatalog: selectedCatalog,
        selectedClientCatalog: selectedCatalog,
        selectedClientMap,
        selectedClientCount: 1,
        expandedClientMap: expandedMap
      });
    } else {
      let selectedClientCatalog: TreeNode[] = this.state.selectedClientCatalog.slice();
      let clientCatalog = this.state.clientCatalog;
      selectedClientCatalog = selectedClientCatalog.filter(
        n =>
          !(
            (n as IModCategoryProductDto).noProducts ||
            n.id.toString().startsWith("product")
          )
      );
      selectedClientCatalog.push({
        id: data.id as number,
        name: data.name,
        childCategories: [
          { id: `preloader-1`, preloader: true } as IModCategoryProductDto
        ]
      } as IExGetCategoriesDto);
      selectedClientCatalog = sortNameItems(
        selectedClientCatalog
      ) as TreeNode[];
      const addedIndex = selectedClientCatalog.findIndex(
        item => item.id === data.id
      );
      const index = this.state.clientCatalog.findIndex(
        c => c.id === this.state.selectedClientCategoryId
      );
      if (index !== -1) {
        clientCatalog = immer(this.state.clientCatalog, draft => {
          (draft[
            index
          ] as IExGetCategoriesDto).childCategories = selectedClientCatalog;
        });
      }

      const categoriesToLoad = [{ categoryId: data.id, path: [addedIndex] }];
      this.loadProductsIntoCatalog(false, categoriesToLoad, false);

      this.setState({
        clientCatalog,
        selectedClientCatalog,
        selectedClientMap,
        selectedClientCount: 1,
        expandedClientMap: expandedMap
      });
    }
  }

  handleRemoveSelected() {
    const selectionTree = [];
    const parentNode =
      this.state.selectedClientCategoryId &&
      this.state.clientCatalog.find(
        it => it.id === this.state.selectedClientCategoryId
      );
    extractSelectionForRemoving(
      this.state.selectedClientCatalog,
      this.state.selectedClientMap,
      parentNode as IExGetCategoriesDto,
      selectionTree
    );
    this.props
      .openConfirm({
        title: "Remove Item(s) From Client's Catalog",
        question:
          "Are you sure that you want to remove selected items from client's catalog?",
        promise: () =>
          removeSelectedTreeFromClientCatalog(selectionTree, this.catalogId)
      })
      .then(() => {
        const selectedClientCatalog = removeSelectedFromTree(
          this.state.selectedClientCatalog,
          this.state.selectedClientMap
        );
        const newState: Partial<StateType> = {
          selectedClientCatalog,
          selectedClientMap: {},
          selectedClientCount: 0
        };
        if (!this.state.selectedClientCategoryId) {
          newState.clientCatalog = selectedClientCatalog;
        }
        this.setState(newState as StateType);
      })
      .catch(e => {
        console.log("exception in handleRemoveSelected", e);
      });
  }

  getSelectedCatalog(clientCatalog, categoryId) {
    if (!categoryId) {
      return clientCatalog;
    }
    const selectedNode = clientCatalog.find(node => node.id === categoryId);
    if (selectedNode) {
      return selectedNode.childCategories;
    } else {
      console.error("Cannot find category in the catalog");
      return null;
    }
  }

  handleOnMasterCatalogSelect(value: boolean, path: number[], node: TreeNode) {
    this._handleOnCatalogSelect(true, value, path, node);
  }

  handleOnClientCatalogSelect(value: boolean, path: number[], node: TreeNode) {
    this._handleOnCatalogSelect(false, value, path, node);
  }

  _handleOnCatalogSelect(
    isMaster: boolean,
    value: boolean,
    path: number[],
    node: TreeNode
  ) {
    const selectedMap: SimpleMap = isMaster
      ? { ...this.state.selectedMasterMap }
      : { ...this.state.selectedClientMap };
    const expandedMap: SimpleMap = isMaster
      ? { ...this.state.expandedMasterMap }
      : { ...this.state.expandedClientMap };

    const childrenIds = [];
    const categoriesToLoad = [];
    const subCategories = [];
    let catalogWithPreloaders = null;

    if (
      (node as IExGetCategoriesDto).childCategories &&
      (node as IExGetCategoriesDto).childCategories.length
    ) {
      collectChildrenInfoRecursive(
        path,
        (node as IExGetCategoriesDto).childCategories,
        childrenIds,
        subCategories,
        categoriesToLoad
      );
    }

    if (value) {
      if ((node as IExGetCategoriesDto).childCategories) {
        if (
          !(node as IExGetCategoriesDto).productsLoaded &&
          (node as IExGetCategoriesDto).childCategories.length === 0
        ) {
          categoriesToLoad.push({ categoryId: node.id, path });
        }

        if (categoriesToLoad.length) {
          catalogWithPreloaders = this.showPreloaders(
            isMaster,
            categoriesToLoad
          );
          this.loadProductsIntoCatalog(isMaster, categoriesToLoad, true);
        }
      }

      selectedMap[node.id] = value;
      childrenIds.forEach(id => {
        selectedMap[id] = true;
      });
      expandedMap[node.id] = true;
      subCategories.forEach(id => {
        expandedMap[id] = true;
      });
    } else if (selectedMap[node.id]) {
      delete selectedMap[node.id];
      childrenIds.forEach(id => {
        delete selectedMap[id];
      });
    }

    const masterOrClient = isMaster ? "Master" : "Client";

    const newState: Partial<StateType> = {
      [`selected${masterOrClient}Map`]: selectedMap,
      [`selected${masterOrClient}Count`]: Object.keys(selectedMap).length,
      [`expanded${masterOrClient}Map`]: expandedMap
    };

    if (catalogWithPreloaders) {
      newState[
        isMaster ? "selectedMasterCatalog" : "selectedClientCatalog"
      ] = catalogWithPreloaders;
    }

    this.setState(newState as StateType);
  }

  handleMasterTrigger(value: boolean, path: number[], node: TreeNode) {
    this._handleNodeTrigger(true, value, path, node);
  }

  handleClientTrigger(value: boolean, path: number[], node: TreeNode) {
    this._handleNodeTrigger(false, value, path, node);
  }

  _handleNodeTrigger(
    isMaster: boolean,
    value: boolean,
    path: number[],
    node: TreeNode
  ) {
    const map: SimpleMap = isMaster
      ? { ...this.state.expandedMasterMap }
      : { ...this.state.expandedClientMap };

    if (value) {
      map[node.id] = true;
    } else {
      delete map[node.id];
    }

    let catalogWithPreloaders = null;

    if (
      value &&
      (node as IExGetCategoriesDto).childCategories &&
      (node as IExGetCategoriesDto).childCategories.length === 0 &&
      !(node as IExGetCategoriesDto).productsLoaded
    ) {
      const categoriesToLoad = [{ categoryId: node.id as number, path }];
      catalogWithPreloaders = this.showPreloaders(isMaster, categoriesToLoad);
      this.loadProductsIntoCatalog(isMaster, categoriesToLoad, false);
    }

    const newState: Partial<StateType> = isMaster
      ? { expandedMasterMap: map }
      : { expandedClientMap: map };

    if (catalogWithPreloaders) {
      newState[
        isMaster ? "selectedMasterCatalog" : "selectedClientCatalog"
      ] = catalogWithPreloaders;
    }

    this.setState(newState as StateType);
  }

  loadProductsIntoCatalog(isMaster, categories, selectProducts) {
    const promises = categories.map(cat =>
      getProductsByCategory(cat.categoryId)
    );
    Promise.all(promises).then(res => {
      const selectedMap: SimpleMap = isMaster
        ? { ...this.state.selectedMasterMap }
        : { ...this.state.selectedClientMap };

      const categoriesWithProducts = immer(
        isMaster
          ? this.state.selectedMasterCatalog
          : this.state.selectedClientCatalog,
        draft => {
          let newLevel;

          res.forEach((productsList: CategoryProductDto[], index: number) => {
            let products: IModCategoryProductDto[] = productsList.map(p => {
              const id = `product-${p.id}-${categories[index].categoryId}`;
              if (selectProducts) {
                selectedMap[id] = true;
              }
              return { ...p, id };
            });

            if (products.length === 0) {
              products = [
                {
                  id: "no-products",
                  name: "No products",
                  noProducts: true
                } as IModCategoryProductDto
              ];
            }

            if (categories[index].path) {
              const node = getTreeNodeByPath(
                draft,
                categories[index].path
              ) as IExGetCategoriesDto;
              node.productsLoaded = true;
              node.childCategories = products;
            } else {
              const topNode: IExGetCategoriesDto = (isMaster
                ? this.state.masterCatalog
                : this.state.clientCatalog
              ).find(
                n => n.id === categories[index].categoryId
              ) as IExGetCategoriesDto;
              topNode.childCategories = products;
              topNode.productsLoaded = true;
              newLevel = topNode.childCategories;
            }
          });

          if (newLevel) {
            return newLevel;
          }
        }
      );

      const catalog = immer(
        isMaster ? this.state.masterCatalog : this.state.clientCatalog,
        (draft): void | TreeNode[] => {
          const selectedCategoryId = isMaster
            ? this.state.selectedMasterCategoryId
            : this.state.selectedClientCategoryId;
          if (selectedCategoryId) {
            const category = draft.find(
              node => node.id === selectedCategoryId
            ) as IExGetCategoriesDto;
            if (category) {
              category.childCategories = categoriesWithProducts;
              category.productsLoaded = true;
            }
          } else {
            return categoriesWithProducts;
          }
        }
      );

      if (isMaster) {
        this.setState({
          masterCatalog: catalog,
          selectedMasterCatalog: categoriesWithProducts,
          selectedMasterMap: selectedMap,
          selectedMasterCount: Object.keys(selectedMap).length
        });
      } else {
        this.setState({
          clientCatalog: catalog,
          selectedClientCatalog: categoriesWithProducts,
          selectedClientMap: selectedMap,
          selectedClientCount: Object.keys(selectedMap).length
        });
      }
    });
  }

  showPreloaders(isMaster: boolean, categories: ListCategoriesToLoad) {
    const categoriesWithProducts = immer(
      isMaster
        ? this.state.selectedMasterCatalog
        : this.state.selectedClientCatalog,
      draft => {
        let newLevel;
        categories.forEach(cat => {
          if (cat.path) {
            const node = getTreeNodeByPath(
              draft,
              cat.path
            ) as IExGetCategoriesDto;
            node.childCategories = [
              { id: `preloader-1`, preloader: true } as IModCategoryProductDto
            ];
          } else {
            newLevel = [{ id: `preloader-1`, preloader: true }];
          }
        });
        return newLevel;
      }
    );
    return categoriesWithProducts;
  }

  handleSingleRemoveProduct(node: IModCategoryProductDto) {
    const selectedMap: SimpleMap = {
      [node.id]: true
    };
    const selectionTree = [];
    const parentNode =
      this.state.selectedClientCategoryId &&
      this.state.clientCatalog.find(
        it => it.id === this.state.selectedClientCategoryId
      );
    extractSelectionForRemoving(
      this.state.selectedClientCatalog,
      selectedMap,
      parentNode as IExGetCategoriesDto,
      selectionTree
    );

    const categoryName = selectionTree[0].categoryName;

    const promise = () =>
      removeSelectedTreeFromClientCatalog(selectionTree, this.catalogId);

    this.props
      .openConfirm({
        title: "Remove Item From Client's Catalog",
        question: `Are you sure that you want to remove product "${
          node.name
        }" from category "${categoryName}"?`,
        promise
      })
      .then(async () => {
        const selectedClientCatalog = removeSelectedFromTree(
          this.state.selectedClientCatalog,
          selectedMap
        );

        const selectedClientMap = { ...this.state.selectedClientMap };
        delete selectedClientMap[node.id];

        this.setState({
          selectedClientCatalog,
          selectedClientMap,
          selectedClientCount: Object.keys(selectedClientMap).length
        });
      })
      .catch(e => {
        console.log("exception at handleSingleRemoveProduct", e);
      });
  }

  handleRenameCategory(node: IExGetCategoriesDto, path: number[]) {
    this.props
      .openModal(
        (resolve, reject) => (
          <PromptModal
            resolve={resolve}
            reject={reject}
            value={node.name}
            label="Category name"
            required
            saveButtonText="Save"
            onSubmit={newName => renameClientCategory(node.id, newName)}
          />
        ),
        {
          title: "Rename Client Category"
        }
      )
      .then(res => {
        const selectedClientCatalog = immer(
          this.state.selectedClientCatalog,
          draft => {
            const newNode = getTreeNodeByPath(draft, path);
            newNode.name = res.name;
          }
        );
        this.setState({ selectedClientCatalog });
      })
      .catch(e => {});
  }

  render() {
    return (
      <Container>
        <LeftPanel>
          <LeftPanelHeader>
            <LeftPanelTitle>
              BUILD CLIENT CATALOG - {this.state.catalogName}
            </LeftPanelTitle>
            <LeftPanelSubtitle>
              Select and add items to client&apos;s catalog
            </LeftPanelSubtitle>
          </LeftPanelHeader>
          <PanelFilter>
            <PositionSelect>
              <Select
                compact
                clearable
                labelKey="name"
                valueKey="id"
                placeholder="Select category"
                options={this.state.masterCatalog}
                value={this.state.selectedMasterCategoryId}
                onChange={this.handleSelectMasterCategory}
              />
            </PositionSelect>
          </PanelFilter>
          <PanelTreeHeader>
            <PanelTreeTitle>
              {this.state.selectedMasterCategoryName}
            </PanelTreeTitle>
            <PanelActionButton>
              {this.editPermission && (
                <Button
                  onClick={this.handleAddSelected}
                  disabled={!this.state.selectedMasterCount}
                >
                  Add selected ({this.state.selectedMasterCount})
                </Button>
              )}
            </PanelActionButton>
          </PanelTreeHeader>
          <CatalogTree
            categories={this.state.selectedMasterCatalog}
            isLoading={this.state.isLoading}
            onSelect={this.handleOnMasterCatalogSelect}
            selectedMap={this.state.selectedMasterMap}
            onExpand={this.handleMasterTrigger}
            expandedMap={this.state.expandedMasterMap}
          />
        </LeftPanel>
        <RightPanel>
          <RightPanelHeader>
            <div />
            {this.editPermission && (
              <Button primary onClick={this.handleAddCategoryModal}>
                Add New Category
              </Button>
            )}
          </RightPanelHeader>
          <PanelFilter>
            <PositionSelect>
              <Select
                compact
                clearable
                labelKey="name"
                valueKey="id"
                placeholder="Select category"
                options={this.state.clientCatalog}
                value={this.state.selectedClientCategoryId}
                onChange={this.handleSelectClientCategory}
              />
            </PositionSelect>
          </PanelFilter>
          <PanelTreeHeader>
            <PanelTreeTitle>
              {this.state.selectedClientCategoryName}
            </PanelTreeTitle>
            <PanelActionButton>
              {this.editPermission && (
                <Button
                  onClick={this.handleRemoveSelected}
                  disabled={!this.state.selectedClientCount}
                >
                  Remove selected ({this.state.selectedClientCount})
                </Button>
              )}
            </PanelActionButton>
          </PanelTreeHeader>
          <CatalogTree
            categories={this.state.selectedClientCatalog}
            isLoading={this.state.isLoading}
            onSelect={this.handleOnClientCatalogSelect}
            selectedMap={this.state.selectedClientMap}
            onExpand={this.handleClientTrigger}
            expandedMap={this.state.expandedClientMap}
            onRemoveProduct={this.handleSingleRemoveProduct}
            onRenameCategory={this.handleRenameCategory}
            showDropDown={this.editPermission}
          />
        </RightPanel>
      </Container>
    );
  }
}

export default connect(
  null,
  {
    openModal: openCustomModalCallback,
    openConfirm,
    alertModal: alert
  }
)(withGranted(Catalog));

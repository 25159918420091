import React from 'react';

import {
  List,
} from '../../../../components';

import SupervisorItem from './SupervisorsItem';

import {
  UserSupervisorDto
} from '../../../../service-proxies';

interface IOptionsData {
  options: any;
  complete: boolean;
}

interface ISupervisorsListProps {
  values: UserSupervisorDto[];
  isLoading?: boolean;
  disabled: boolean;
  onSort?: (e) => void;
  onAdd: (e) => void;
  onDelete: (e) => void;
  onItemChange: (item: any, index: any) => void;
  label: string;
  loadUsers: (text: string, callback: (argument: any, optionsData: IOptionsData) => any) => void;
}

class SupervisorsList extends React.Component<ISupervisorsListProps> {
  render() {
    return <List
      ordered
      increasable
      sortable
      deletable
      itemName="Buying Manager"
      label={this.props.label}
      value={this.props.values}
      disabled={this.props.disabled}
      renderComponent={(value, index) =>
        <SupervisorItem
          value={value}
          index={index}
          values={this.props.values}
          disabled={this.props.disabled}
          loadUsers={this.props.loadUsers}
          onSupervisorItemChange={this.props.onItemChange}
        />
      }
      onSort={this.props.onSort}
      onAddItem={this.props.onAdd}
      onItemDelete={this.props.onDelete}
      isLoading={this.props.isLoading}
    />;
  }
}

export default SupervisorsList;

import React from 'react';
import styled from 'styled-components';

export default styled(props => {
  return (
    <i {...props}>
      <svg viewBox="2 2 20 20" width="20" height="20">
        <path d=" M 6.349 6.349 C 4.9 7.796 4 9.794 4 12 C 4 16.411 7.589 20 12 20 C 16.411 20 20 16.411 20 12 C 20 7.589 16.411 4 12 4 L 12 2 C 17.514 2 22 6.486 22 12 C 22 17.514 17.514 22 12 22 C 6.486 22 2 17.514 2 12 C 2 9.243 3.125 6.746 4.938 4.938 L 2 2 L 9 2 L 9 9 L 6.349 6.349 Z " />
      </svg>
    </i>
  );
}) `
  ${ props => `
    width: 21px;
    height: 21px;
    display: inline-flex;
    justify-content: center;
    align-items: center;

    svg{
      path{
        fill: ${props.theme.primary}; 
      }
    }
  `}
`;

import React from 'react';
import styled from 'styled-components';

export default styled(props => {
  return (
    <i {...props}>
      <svg viewBox="0 -4 24 24" width="20" height="20">
        <path d=" M 1 0 C 0.449 0 0 0.449 0 1 C 0 1.551 0.449 2 1 2 Q 1 2 3.219 2 Q 5.844 12.5 5.844 12.5 C 6.066 13.391 6.863 14 7.781 14 C 7.781 14 19.25 14 19.25 14 C 20.152 14 20.918 13.402 21.156 12.531 Q 21.156 12.531 23.75 3 L 7 3 L 7.5 5 L 21.156 5 L 19.25 12 L 7.781 12 Q 5.156 1.5 5.156 1.5 C 4.934 0.609 4.137 0 3.219 0 C 3.219 0 1 0 1 0 Z  M 18 14 C 16.355 14 15 15.355 15 17 C 15 18.645 16.355 20 18 20 C 19.645 20 21 18.645 21 17 C 21 15.355 19.645 14 18 14 Z  M 9 14 C 7.355 14 6 15.355 6 17 C 6 18.645 7.355 20 9 20 C 10.645 20 12 18.645 12 17 C 12 15.355 10.645 14 9 14 Z  M 9 16 C 9.563 16 10 16.438 10 17 C 10 17.563 9.563 18 9 18 C 8.438 18 8 17.563 8 17 C 8 16.438 8.438 16 9 16 Z  M 18 16 C 18.563 16 19 16.438 19 17 C 19 17.563 18.563 18 18 18 C 17.438 18 17 17.563 17 17 C 17 16.438 17.438 16 18 16 Z " />
      </svg>
    </i>
  );
}) `
  ${ props => `
    width: 21px;
    height: 21px;
    display: inline-flex;
    justify-content: center;
    align-items: center;

    svg{
      path{
        fill: ${props.theme.primary}; 
      }
    }
  `}
`;

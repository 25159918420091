import styled from 'styled-components';

export default styled<{error?: string, compact?: boolean, primary?: boolean}, 'label'>('label')`
  ${ ({ theme, ...props }) => {

    const {
      global,
      warning,
      primary,
      fs_md,
    } = theme;

    const color = !props.error ?
      primary :
      warning;

    return `
        ${global}
        color: ${color};
        font-size: ${fs_md}; 
      `;
  }}
`;

import React from 'react';
import styled from 'styled-components';

export default styled(props => {
  return (
    <i {...props}>
      <svg viewBox="0 0 32 32" version="1.1" width="32px" height="32px">
        <path d="M 16 5 L 12 9 L 20 9 Z M 3 11 L 3 13 L 29 13 L 29 11 Z M 3 15 L 3 17 L 29 17 L 29 15 Z M 3 19 L 3 21 L 29 21 L 29 19 Z M 12 23 L 16 27 L 20 23 Z "/>
      </svg>
    </i>
  );
})`
  ${ props => `
    width: 21px;
    height: 21px;
    display: inline-flex;
    justify-content: center;
    align-items: center;

    svg{
      path{
        fill: ${props.theme.primary}; 
      }
    }
  `}
`;

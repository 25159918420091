import { userServiceProxy } from '../servicePool';
import {
  ListResultDtoOfSearchOutputDto,
  CheckSpendLimitOutput,
} from '../service-proxies';

export function getUsers(clientUnitId: number, text: string = ''): Promise<ListResultDtoOfSearchOutputDto> {
  return userServiceProxy.searchUsers(clientUnitId, 15, text);
}

export function checkSpendingLimit(totalAmount: number, locationId: number): Promise<CheckSpendLimitOutput> {
  return userServiceProxy.checkSpendLimit(totalAmount, locationId);
}

export function reactivateUser(id: number): Promise<void> {
  return userServiceProxy.reactivateUser({ id });
}

export function searchSupplyChainManagers(maxResultCount: number = 10, filter: string = '') {
  return userServiceProxy.searchSupplyChainManagers(maxResultCount, filter);
}

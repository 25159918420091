import React from 'react';
import styled from 'styled-components';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { history } from '../../../store';
import {
  ClientListDto,
} from '../../../service-proxies';

import {
  IMatch
} from '../../../_types/common';

import {
  Table,
  Link,
  MainContainer,
  Button,
  Pagination,
} from '../../../components';

import {
  PencilIcon,
  TrashIcon,
  PlusIcon,
} from '../../../assets/icons';

import WithGranted, { IWithGrantedProps } from '../../HOC/WithGranted';
import * as actionsSearch from '../../../_actions/search';
import * as actionsModal from '../../../_actions/modal';
import * as actionsLayout from '../../../_actions/layout';
import * as actionsNotification from '../../../_actions/notification';
import * as clientsApi from '../../../_api/client';
import {
  ADMINISTRATION_CLIENTS_CREATE,
  ADMINISTRATION_CLIENTS_DELETE,
  ADMINISTRATION_CLIENTS_EDIT,
  ADMINISTRATION_CLIENTS_DIVISIONS,
} from '../../../_constants/permissions';

const Position = {
  Container: styled.div`
    width: 100%;
    display: flex;
    flex-direction: column; 
  `,

  Head: styled<{ className?: string }>(({ className, children }) => <header className={className}>
    <div>
      {children}
    </div>
  </header>)`
    padding: 20px;
    >div{
      margin: -7.5px;
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
    }
  `,

  HeadElement: styled.div`
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    & > * {
      margin: 7.5px;
    }
  `,

  Toolbar: styled.header`
    text-align: right;
    height: 15px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  `,

  Body: styled.div`
    width: 100%; 
    height: 100%; 
  `,

};

interface IEditButtonProps {
  onEdit: (id: number) => void;
  id: number;
}

const EditButton: React.SFC<IEditButtonProps> = ({
  onEdit,
  id,
}) => {

  function handleEdit() {
    onEdit(id);
  }

  return <Link.Button onClick={handleEdit}><PencilIcon /></Link.Button>;
};

interface IDeleteButtonProps {
  onDelete: (id: number) => void;
  id: number;
}

const DeleteButton: React.SFC<IDeleteButtonProps> = ({
  onDelete,
  id,
}) => {

  function handleDelete() {
    onDelete(id);
  }

  return <Link.Button onClick={handleDelete}><TrashIcon /></Link.Button>;
};

interface IListProps extends IWithGrantedProps {
  setSearch: typeof actionsSearch.set;
  match: IMatch;
  prompt: typeof actionsModal.prompt;
  perPage: number;
  setPerPage: typeof actionsLayout.setPerPage;
  notifySwagger: typeof actionsNotification.notifySwagger;
}

interface IListState {
  clients?: ClientListDto[];
  totalCount?: number;
  listStatus?: string;
  skip?: number;
  filter?: string;
}

interface IGetClientsParams {
  filter?: string;
  skip?: number;
  perPage?: number;
}

class List extends React.Component<IListProps, IListState> {
  constructor(props) {
    super(props);
    this.handleAddNewClick = this.handleAddNewClick.bind(this);
    this.handleChangePage = this.handleChangePage.bind(this);
    this.handleItemEdit = this.handleItemEdit.bind(this);
    this.handleItemDeleteRequest = this.handleItemDeleteRequest.bind(this);
    this.getClients = this.getClients.bind(this);

    this.state = {
      clients: [],
      totalCount: 0,
      listStatus: '',
      skip: 0,
    };

    this.props.setSearch('/admin/clients', (filter) => {
      this.setState({ filter, skip: 0 }, () => {
        this.getClients({ filter });
      });
    });

  }

  componentDidMount() {
    this.getClients();
  }

  getClients(params: IGetClientsParams = {}) {
    const skip = (Number.isInteger(params.skip) && params >= 0) ? params.skip : this.state.skip;
    const perPage = params.perPage || this.props.perPage;
    const filter = params.filter || this.state.filter;

    const statusStartKey = 'loading';
    const statusFinnishKey = 'loaded';

    this.setState({ listStatus: statusStartKey });

    clientsApi.getClients(perPage, skip, filter)
      .then(clientsData => {
        this.setState({
          listStatus: statusFinnishKey,
          clients: clientsData.items,
          totalCount: clientsData.totalCount
        });
      })
      .catch(e => {
        this.props.notifySwagger(e, 'error');
      });
  }

  handleAddNewClick() {
    history.push('/admin/clients/new');
  }

  handleChangePage(pageInfo) {
    const {
      perPage,
      skip,
    } = pageInfo;

    this.setState({ skip }, () => {
      this.getClients({ perPage, skip });
      this.props.setPerPage(perPage);
    });
  }

  renderPreloaderRows(perPage, status) {
    const rows = [];
    const elementsCount = (status === 'loading') ? perPage - 1 : 0;

    for (let i = 0; i < elementsCount; i++) {
      rows.push(<Table.Row
        key={i}
        colSpan="5"
        rows={perPage}
        isLoading={status === 'loading'}
        cells={[<span key="0">NO CLIENTS FOUND</span>]}
      />);
    }
    return rows;
  }

  handleItemEdit(id) {
    history.push(`/admin/clients/${id}`);
  }

  handleItemDeleteRequest(id) {
    this.props.prompt()
      .then(() => {
        clientsApi.removeClient(id)
          .then(() => {
            this.getClients();
          })
          .catch((e) => {
            this.props.notifySwagger(e, 'error');
          });
      })
      .catch(() => { });
  }

  render() {
    return (
      <MainContainer title="Client Management">
        <Position.Container>
          <Position.Head>
            <Position.HeadElement />
            <Position.HeadElement>
              {this.props.isGranted(ADMINISTRATION_CLIENTS_CREATE) && <Button onClick={this.handleAddNewClick} icon={<PlusIcon />}>Add new</Button>}
            </Position.HeadElement>
          </Position.Head>
          <Position.Body>
            <Table fullHeight>
              <Table.Header columns={[
                { name: 'name', label: 'name' },
                { name: 'numberOfDivisions', label: 'number of divisions' },
                { name: 'numberOfLocations', label: 'number of locations' },
                { name: 'numberOfEmployeesTotal', label: 'total number of employees' },
                { name: '', label: '' },
              ]} />
              <Table.Body>
                {
                  this.state.listStatus !== 'loading' &&
                  (
                    (this.state.clients && !!this.state.clients.length) ?
                    this.state.clients.map((item, index) =>
                      <Table.Row
                        key={`${index}-${item.id}`}
                        cells={[
                          (this.props.isGranted(ADMINISTRATION_CLIENTS_DIVISIONS) ?
                            <Link key={`cell-name-${index}-${item.id}`} to={`${this.props.match.url}/${item.id}/divisions`}>{item.name}</Link>
                            : item.name
                          ),
                          item.numberOfDivisions,
                          item.numberOfLocations,
                          item.numberOfEmployeesTotal,
                          <Position.Toolbar key={`cell-toolbar-${index}-${item.id}`} >
                            {this.props.isGranted(ADMINISTRATION_CLIENTS_EDIT) && <EditButton onEdit={this.handleItemEdit} id={item.id} />}
                            {this.props.isGranted(ADMINISTRATION_CLIENTS_DELETE) && <DeleteButton onDelete={this.handleItemDeleteRequest} id={item.id} />}
                          </Position.Toolbar>
                        ]}
                      />) :
                    <Table.Row
                      key={0}
                      colSpan="5"
                      rows={this.props.perPage}
                      isLoading={this.state.listStatus === 'loading'}
                      cells={[<span key="0">NO CLIENTS FOUND</span>]}
                    />
                  )
                }
                {
                  this.state.listStatus === 'loading' &&
                  this.renderPreloaderRows(this.props.perPage, this.state.listStatus)
                }
              </Table.Body>
            </Table>
          </Position.Body>
          <Pagination
            skip={this.state.skip}
            perPage={this.props.perPage}
            count={this.state.totalCount}
            onChange={this.handleChangePage}
          />
        </Position.Container>
      </MainContainer>
    );
  }
}

export default withRouter(connect(
  state => ({
    clients: state.clients.clients,
    listStatus: state.clients.listStatus,
    totalCount: state.clients.totalCount,
    skip: state.clients.skip,
    perPage: state.layout.perPage,
  }),
  dispatch => bindActionCreators({
    setSearch: actionsSearch.set,
    prompt: actionsModal.prompt,
    setPerPage: actionsLayout.setPerPage,
    notifySwagger: actionsNotification.notifySwagger,
  }, dispatch)
)(WithGranted(List)));
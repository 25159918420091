import styled from 'styled-components';
// import PropTypes from 'prop-types';

interface ISeparatorProps {
  borders?: string;
  children?: any;
}

export const Separator = styled<ISeparatorProps, 'div'>('div')`
  ${({ theme, ...props }) => {

    const {
      global,
      lighter,
      main,
    } = theme;

    let bordersMap = [];

    if (props.borders) {
      bordersMap = props.borders.split(' ');
    }

    return `
      ${global}
      
      ${parseInt(bordersMap[0], 10) ?
        `
          border-top-style: solid;
          border-top-width: 1px;
        `:
        ''}

      ${parseInt(bordersMap[1], 10) ?
        `
          border-right-style: solid;
          border-right-width: 1px;
        `:
        ''}

      ${parseInt(bordersMap[2], 10) ?
        `
          border-bottom-style: solid;
          border-bottom-width: 1px;
        `:
        ''}

      ${parseInt(bordersMap[3], 10) ?
        `
          border-top-style: solid;
          border-top-width: 1px;
        `:
        ''}

      border-color: ${lighter};
      background-color: ${main}; 
    `;
  }}
`;

// Separator.propTypes = {
//   borders: PropTypes.string,
//   children: PropTypes.any,
// };

export default Separator;
import * as React from 'react';
import styled from 'styled-components';

export interface IRadioProps {
  value: any;
  activeValue: any;
  name?: string;
  onChange?: (newValue: any, name?: string) => void;
  disabled?: boolean;
  label?: string;
  className?: string;
}

class RadioButton extends React.PureComponent<IRadioProps> {

  constructor(props) {
    super(props);
    this.handleClick = this.handleClick.bind(this);
  }

  handleClick() {
    if (this.props.value === this.props.activeValue || this.props.disabled) {
      return;
    }
    if (this.props.onChange) {
      this.props.onChange(this.props.activeValue, this.props.name);
    }
  }

  render() {
    const {className, value, activeValue, label} = this.props;
    return (
      <div className={className}>
        <svg viewBox="0 0 26 26" width="26" height="26" onClick={this.handleClick}>
          <circle cx="13" cy="13" r="10" strokeWidth="1" />
          {
            value === activeValue &&
            <circle cx="13" cy="13" r="4" strokeWidth="0" />
          }
        </svg>
        {label && <label onClick={this.handleClick}>{label}</label>}
      </div>
    );
  }
}

export default styled(RadioButton)`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  justify-items: center;
  ${({theme}) => theme.global};
${({theme, value, activeValue, disabled}) => `
    svg {
      width: 26px;
      height: 26px;
      cursor: ${disabled ? 'normal' : 'pointer'};
      align-self: center;
      circle:first-child {
        fill: ${theme.main};
        stroke: ${value === activeValue ? (disabled ? theme.dark : theme.default) : theme.dark};
      }
      circle:nth-child(2) {
        fill: ${disabled ? theme.dark : theme.default};
      }
      ${disabled ? '' : `:hover > circle:first-child {
        stroke: ${theme.default} !important;
        }`
      }
    }
`}
  label {
    margin-left: 6px;
    font-size: 14px;
    font-weight: normal;
    align-self: center;
    cursor: ${({disabled}) => disabled ? 'normal' : 'pointer'};
  }
`;

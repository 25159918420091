import * as React from "react";
import { types } from "../_actionTypes/modal";
import { Action } from 'redux';
import { ThunkAction } from "redux-thunk";
import {
  IActionModalChange,
  IActionModalClose,
  IActionModalOpen,
  IModalOptions
} from "../_reducers/modal";
import { IGlobalStore } from "../_reducers/reducers";

export type IOpenConfirmPrompt = (options?: IModalOptions) => Promise<any>;

export function prompt(
  options: IModalOptions = {
    title: "",
    question: "Do you really want delete this item?"
  }
): ((dispatch) => Promise<any>) | any {
  return function(dispatch) {
    return new Promise(
      (resolve: (r?: any) => void, reject: (r?: any) => void) => {
        const confirm = function() {
          if (options.promise && typeof options.promise === "function") {
            dispatch({
              type: types.MODAL_CHANGE,
              preloader: true,
              status: true
            } as IActionModalChange);
            const cbr = options.promise();
            if ((cbr as any) instanceof Promise) {
              cbr
                .then(res => {
                  dispatch({
                    type: types.MODAL_CLOSE
                  });
                  resolve(res);
                })
                .catch(() => {
                  dispatch({
                    type: types.MODAL_CHANGE,
                    preloader: false,
                    status: true
                  } as IActionModalChange);
                });
            } else {
              dispatch({
                type: types.MODAL_CLOSE,
                status: false
              } as IActionModalClose);
              resolve(cbr);
            }
          } else {
            dispatch({
              type: types.MODAL_CLOSE
            });
            resolve();
          }
        };

        const decline = function() {
          dispatch({
            type: types.MODAL_CLOSE
          });
          reject();
        };

        const openAction: IActionModalOpen = {
          type: types.MODAL_OPEN,
          options,
          confirm,
          decline,
          status: true
        };

        dispatch(openAction);
      }
    );
  };
}

export type IOpenAlert = (message: string, title?: string) => Promise<void>;

export function alert(message: string, title: string = "", showLink?: boolean) {
  if (showLink) {
    return prompt({
      title,
      question: message,
      hideDecline: false,
      cancelText: "Cancel",
      confirmText: "Reset Password"
    });
  }

  return prompt({
    title,
    question: message,
    hideDecline: true,
    confirmText: "Ok"
  });
}

export type IOpenCustomModalCallback = (
  contentCallback: (resolve, reject) => React.ReactElement<any>,
  options: IModalOptions
) => Promise<any>;

export const openCustomModalCallback = (
  contentCallback: (resolve, reject) => React.ReactElement<any>,
  options: IModalOptions
): ThunkAction<Promise<any>, IGlobalStore, void, Action> => {
  return function(dispatch) {
    return new Promise((resolve, reject) => {
      const confirm = function(res) {
        const closeAction: IActionModalClose = {
          type: types.MODAL_CLOSE
        };
        dispatch(closeAction);
        resolve(res);
      };

      const decline = function() {
        const closeAction: IActionModalClose = {
          type: types.MODAL_CLOSE
        };
        dispatch(closeAction);
        reject();
      };

      const openAction: IActionModalOpen = {
        type: types.MODAL_OPEN,
        status: true,
        content: contentCallback(confirm, decline),
        options,
        confirm,
        decline
      };

      dispatch(openAction);
    });
  };
};

import React, { Fragment } from 'react';
import styled, { StyledComponentClass } from 'styled-components';
import { IThemeProps } from '../assets/theme';

export interface ITitleContainerProps extends IThemeProps {
  centered?: boolean;
  scale?: string;
}

export const TitleContainer = styled.div<ITitleContainerProps>`
  ${({ theme, ...props }) => {
    const {
      global,
    } = theme;
    const top = '20px';
    let left = '20px';
    let right = '20px';
    if (props.centered) {
      left = 'auto';
      right = 'auto';
    }

    let width;
    switch (props.scale) {
      case 'md': width = '940px'; break;
      default: width = 'auto';
    }

    return `
      ${global}
      width: ${width};
      margin: ${top} ${right} 0px ${left};
    `;
  } }
` as unknown as StyledComponentClass<ITitleContainerProps, any>;

export interface ITitleProps extends IThemeProps {
  centered?: boolean;
  scale?: string;
}

export const Title = styled.div<ITitleProps>`
  ${({ theme, ...props }) => {
    const {
      global,
    } = theme;
    const top = props.centered ? '60px' : '20px';
    let left = '20px';
    let right = '20px';
    if (props.centered) {
      left = 'auto';
      right = 'auto';
    }

    let width;
    switch (props.scale) {
      case 'md': width = '940px'; break;
      default: width = 'auto';
    }

    return `
      ${global}
      font-size: 20px;
      font-weight: 600;
      text-transform: uppercase;
      width: ${width};
      margin: ${top} ${right} 0px ${left};
    `;
  } }
` as unknown as StyledComponentClass<ITitleProps, any>;

export interface ISubTitleProps extends IThemeProps {
  centered?: boolean;
  scale?: string;
}

export const SubTitle = styled.div<ISubTitleProps>`
  ${({ theme, ...props }) => {
    const {
      global, fs_md
    } = theme;

    let left = '20px';
    let right = '20px';
    if (props.centered) {
      left = 'auto';
      right = 'auto';
    }

    let width;
    switch (props.scale) {
      case 'md': width = '940px'; break;
      default: width = 'auto';
    }

    return `
      ${global}
      font-size: ${fs_md};
      width: ${width};
      margin: 0px ${right} 26px ${left};
    `;
  } }
` as unknown as StyledComponentClass<ISubTitleProps, any>;

export interface IMainContainerProps extends IThemeProps {
  className?: string;
  title?: string;
  subTitle?: string;
  centered?: boolean;
  scale?: string;
  renderTitle?: JSX.Element | JSX.Element[];
  width?: number;
}

const MainContainer = styled<IMainContainerProps>(function ({
  className,
  children,
  title,
  subTitle,
  centered,
  scale,
  renderTitle,
}) {
  return <Fragment>
    {
      renderTitle &&
        Array.isArray(renderTitle) ?
        renderTitle.map((TitleElement, index) => <TitleContainer key={index} centered={centered} scale={scale}>
          {TitleElement}
        </TitleContainer>) :
        <TitleContainer centered={centered} scale={scale}>
          {renderTitle}
        </TitleContainer>
    }
    {
      !renderTitle &&
      title &&
      <Title centered={centered} scale={scale}>{title}</Title>
    }
    {
      !renderTitle &&
      subTitle &&
      <SubTitle centered={centered} scale={scale}>{subTitle}</SubTitle>
    }
    <section className={className}>
      {children}
    </section>
  </Fragment>;
  // @ts-expect-error change
}).withConfig({displayName: 'MainContainer'})`
  ${ ({ theme, ...props }) => {
    const {
      main,
    } = theme;

    let paddingLeft = '20px';
    let paddingRight = '20px';
    if (props.centered) {
      paddingLeft = 'auto';
      paddingRight = 'auto';
    }

    let width;
    switch (props.scale) {
      case 'md': width = '940px'; break;
      default: width = 'auto';
    }

    return `
      display: flex;
      background: ${main}
      box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.07);
      margin: 20px ${paddingRight} 37px ${paddingLeft};
      min-height: 720px;
      width: ${width};
      transition: all .5s ease-in-out;
      & + & {
        display: none;
      }

      form{
        width: 100%;
      }
      
    `;
  }}

  ${Title}{ 
    ${({ theme, ...props }) => {
    let paddingLeft = '20px';
    let paddingRight = '20px';
    if (props.centered) {
      paddingLeft = 'auto';
      paddingRight = 'auto';
    }
    return `
        width: ${ props.width ? `${props.width}px` : 'auto'}; 
        margin: 20px ${paddingRight} 37px ${paddingLeft}; 
      `;
  }}
  } 

  ${SubTitle}{ 
    ${({ theme, ...props }) => {
    let paddingLeft = '20px';
    let paddingRight = '20px';
    if (props.centered) {
      paddingLeft = 'auto';
      paddingRight = 'auto';
    }
    return `
        width: ${ props.width ? `${props.width}px` : 'auto'}; 
        margin: 20px ${paddingRight} 37px ${paddingLeft}; 
      `;
  }}

  } 

`;

export default MainContainer;
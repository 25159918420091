import React from 'react';
import styled from 'styled-components';

export default styled<{
  compact?: boolean;
  children?: React.ReactDOM | void | string; 
}, 'div'>('div')`
  ${ ({ theme, compact }) => {

    const {
      global,
      warning,
      fs_xs,
    } = theme;

    return `
        ${global}
        min-height: 19px;
        margin: 0 0 3px;
        font-weight: 300;
        color: ${warning};
        font-size: ${fs_xs}; 
      `;
  }}
`;

import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import {
  Select,
  Checkbox,
} from '../../../components';

import * as attributeApi from '../../../_api/attribute';

export default styled(class extends React.Component {
  static propTypes = {
    className: PropTypes.string.isRequired,
    value: PropTypes.object,
    index: PropTypes.number,
    onNameChange: PropTypes.func.isRequired,
    onNameCreate: PropTypes.func.isRequired,
    onValueChange: PropTypes.func.isRequired,
    onValueCreate: PropTypes.func.isRequired,
    onIncludeInNameChange: PropTypes.func.isRequired,
  };

  render() {
    let {
      className,
      value,
      index,
      onNameChange,
      onNameCreate,
      onValueChange,
      onValueCreate,
      onIncludeInNameChange,
    } = this.props;

    function loadAtributeNames(text, callback) {

      attributeApi.getAll(text).then(data => {
        let options = data.items;

        callback(null, {
          options,
          complete: true,
        });
      });

    }

    function loadAtributeValues(text, callback) {
      attributeApi.getAllValues(value.attribute.id, text).then(data => {

        let options = data.items;

        callback(null, {
          options,
          complete: true,
        });
      });
    }

    function handleOnNameChange(text) {
      onNameChange(text, index);
    }

    function handleNewNameClick(text) {
      onNameCreate(text, index);
    }

    function handleOnValueChange(text) {
      onValueChange(text, index);
    }

    function handleNewValueClick(text) {
      onValueCreate(text, index, value.attribute.id);
    }

    function handleIncludeInNameChange(flag) {
      onIncludeInNameChange(flag, index);
    }

    return <div className={className}>
      <Select
        compact
        creatable
        async
        loadOnFocus
        autoload={false}
        labelKey="displayValue"
        placeholder={'Name...'}
        valueKey="id"
        onChange={handleOnNameChange}
        value={value.attribute}
        onNewOptionClick={handleNewNameClick}
        loadOptions={loadAtributeNames}
      />
      {
        value.attribute &&
        value.attribute.id &&
        <Select
          compact
          creatable
          async
          loadOnFocus
          autoload={false}
          labelKey="displayValue"
          placeholder={'Value...'}
          valueKey="id"
          onChange={handleOnValueChange}
          value={value.attributeValue}
          onNewOptionClick={handleNewValueClick}
          loadOptions={loadAtributeValues}
        />
      }
      {
        value.attributeValue &&
        value.attributeValue.id &&
        <Checkbox
          label="Include in Name"
          id={`include-${index}`}
          value={value.include}
          onChange={handleIncludeInNameChange}
        />
      }
    </div>;
  }
}) `
  display: flex;
  ${Select}{
    width: 35%;
    margin: 0 10px 0 0;
  }
  ${Checkbox}{
    margin: 0 10px 0 0;
  }
`;
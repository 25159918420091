import React from 'react';
import styled from 'styled-components';

export default styled(props => {
  return (
    <i {...props}>
      <svg viewBox="-766 119 20 21" width="21" height="20">
        <path d=" M -762 119 L -762 120 L -766 120 L -766 140 L -746 140 L -746 120 L -750 120 L -750 119 L -752 119 L -752 120 L -760 120 L -760 119 L -762 119 Z  M -764 122 L -762 122 L -762 123 L -760 123 L -760 122 L -752 122 L -752 123 L -750 123 L -750 122 L -748 122 L -748 124 L -764 124 L -764 122 Z  M -764 126 L -748 126 L -748 138 L -764 138 L -764 126 Z  M -761 129 L -761 131 L -759 131 L -759 129 L -761 129 Z  M -757 129 L -757 131 L -755 131 L -755 129 L -757 129 Z  M -753 129 L -753 131 L -751 131 L -751 129 L -753 129 Z  M -761 133 L -761 135 L -759 135 L -759 133 L -761 133 Z  M -757 133 L -757 135 L -755 135 L -755 133 L -757 133 Z  M -753 133 L -753 135 L -751 135 L -751 133 L -753 133 Z " />
      </svg>
    </i>
  );
})`
  ${ props => `
    width: 21px;
    height: 21px;
    display: inline-flex;
    justify-content: center;
    align-items: center;

    svg{
      path{
        fill: ${props.theme.primary}; 
      }
    }
  `}
`;

import { Reducer } from 'redux';
import { types } from '../_actionTypes/layout';

export interface ILayoutStore {
  drawerStatus: boolean;
  rightSliderOpen: boolean;
  rightSliderMode: "CART";
  menuStatus: boolean;
  perPage: number;
}

const initialState: ILayoutStore = {
  drawerStatus: true,
  rightSliderOpen: false,
  rightSliderMode: null,
  menuStatus: true,
  perPage: 25,
};

interface IActionLayoutDrawerToggle {
  type: types.LAYOUT_DRAWER_TOGGLE;
  drawerStatus: boolean;
}

interface IActionLayoutMenuShow {
  type: types.LAYOUT_MENU_SHOW;
  menuStatus: boolean;
}

interface IActionLayoutOpenCart {
  type: types.LAYOUT_OPEN_CART;
}

interface IActionLayputCloseRightSlider {
  type: types.LAYOUT_CLOSE_RIGHT_SLIDER;
}

export interface IActionLayoutSetPerPage {
  type: types.LAYOUT_SET_PER_PAGE;
  perPage: number;
}

type IAction = IActionLayoutDrawerToggle
  | IActionLayoutMenuShow
  | IActionLayoutOpenCart
  | IActionLayputCloseRightSlider
  | IActionLayoutSetPerPage;

const reducer: Reducer<ILayoutStore> = (state: ILayoutStore = initialState, action: IAction) => {

  switch (action.type) {
    case types.LAYOUT_DRAWER_TOGGLE:
      return ({
        ...state,
        drawerStatus: action.drawerStatus,
      });
    case types.LAYOUT_MENU_SHOW:
      return ({
        ...state,
        menuStatus: action.menuStatus,
      });
    case types.LAYOUT_OPEN_CART:
      return {
        ...state,
        rightSliderOpen: true,
        rightSliderMode: 'CART'
      };
    case types.LAYOUT_CLOSE_RIGHT_SLIDER:
      return {
        ...state,
        rightSliderOpen: false,
      };
    case types.LAYOUT_SET_PER_PAGE:
      return {
        ...state,
        perPage: action.perPage,
      };

    default:
      return state;
  }
};

export default reducer;

import * as React from 'react';
import styled from 'styled-components';

import {
  IThemeProps
} from '../../../../assets/theme';

import {
  Article,
} from '../../../../components';

interface ITitleProps {
  label?: string;
  Element?: React.ComponentType<IThemeProps>;
}

const Element = styled.div<IThemeProps>``;

const Title = class extends React.Component<ITitleProps> {
  static Element = Element;

  render() {
    return <div>
      <Article.H1>
        {this.props.label}
      </Article.H1>
      {this.props.children}
    </div>;
  }
};

export default Title;
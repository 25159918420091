import * as React from 'react';
import styled from 'styled-components';
import * as PopperJS from 'popper.js';
import { Popper, Manager, Reference } from 'react-popper';

function getSecondAxis(placement) {
  return placement.split('-')[0];
}

export const Arrow = styled<{placement: string, appearance: 'dark' | 'light'}, 'div'>('div')`
  ${(props) => {
    const {
      global,
      light,
    } = props.theme;
    
    const position = `${getSecondAxis(props.placement)}: -12px;`;

    return `
      ${global};
      width: 15px;
      height: 15px;
      overflow: hidden;
      &:before{
        content: '';
        width: 15px;
        height: 15px;
        box-sizing: border-box;
        background: ${props.appearance === 'dark' ? 'black' : 'white'};
        border: ${props.appearance === 'dark' ? '1px solid black' : `1px solid ${light}`};
        display: block;
        transform: rotate(45deg);
        position: relative;
       ${position}
      }
    `;
  }}
  
`;

const BubbleContainer = styled<{placement: string, appearance: 'dark' | 'light'}, 'div'>('div')`
  ${({ theme, ...props }) => {
    const {
      global,
      primary,
      light,
      main,
    } = theme;
    
    const position = `${getSecondAxis(props.placement)}: -5px;`;

    return `
      ${global};
      background-color: ${props.appearance === 'dark' ? 'black' : 'white'};
      padding: 4px 7px 4px 8px;
      font-size: 9px;
      font-weight: bold;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: 0.4px;
      text-align: left;
      color: ${props.appearance === 'dark' ? main : primary};
      border: ${props.appearance === 'dark' ? `1px solid ${primary}` : `1px solid ${light}`};
      border-radius: 0;
      box-sizing: border-box;
      position: relative;
      ${position}
    `;
  }}
`;

const TargetBubble: React.SFC<{text: string, parentRef: any}> =
  ({text, parentRef, ...props}) => <div {...props} ref={parentRef}>{text}</div>;


interface ITooltipProps {
  className?: string;
  triggerActionName?: string;
  placement?: PopperJS.Placement;
  isActive?: boolean;
  text: string;
  appearance?: 'dark' | 'light';
  disabled?: boolean;
}

interface ITooltipState {
  outside: boolean;
  active: boolean;
}

class Tooltip extends React.Component<ITooltipProps, ITooltipState> {

  static defaultProps = {
    triggerActionName: 'hover',
    isActive: false,
    placement: 'right',
    appearance: 'dark',
    disabled: false,
  };

  arrowCustomStyles: {
    [key: string]: any;
  };

  bubbleRef: any;

  constructor(props) {
    super(props);
    this.state = {
      active: props.isActive,
      outside: true,
    };
    this.handleClick = this.handleClick.bind(this);
    this.handleMouseEnter = this.handleMouseEnter.bind(this);
    this.handleMouseLeave = this.handleMouseLeave.bind(this);
    this.handleClickOutside = this.handleClickOutside.bind(this);

    this.arrowCustomStyles = {
      'zIndex': 1,
    };
    this.arrowCustomStyles[getSecondAxis(props.placement)] = 'calc(100% - 6px)';
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.disabled && prevState.active) {
      return {
        active: false,
      };
    } else {
      return null;
    }
  }

  handleClick() {
    window.document.addEventListener('mousedown', this.handleClickOutside, true);
    this.setState({ active: !this.state.active });
  }

  handleMouseEnter() {
    if (this.props.triggerActionName === 'hover') {
      if (!this.state.active) {
        this.setState({ active: true, outside: false });
      }
    } else if (this.props.triggerActionName === 'click') {
      this.setState({ outside: false });
    }
  }

  handleMouseLeave() {
    if (this.props.triggerActionName === 'hover') {
      if (this.state.active) {
        this.setState({ active: false, outside: true });
      }
    } else if (this.props.triggerActionName === 'click') {
      this.setState({ outside: true });
    }
  }

  handleClickOutside(e) {
    if (this.bubbleRef && !this.bubbleRef.contains(e.target)) {
      if (this.state.outside) {
        this.setState({ active: false });
      }
      document.removeEventListener('mousedown', this.handleClickOutside, true);
    }
  }

  render() {
    const {
      className,
      text,
      triggerActionName,
      children,
      disabled,
    } = this.props;

    return <Manager>
      <Reference>
        {({ ref }) => (
          <span
            ref={ref}
            onMouseDown={(!disabled && triggerActionName === 'click') ? this.handleClick : undefined}
            onMouseEnter={disabled ? undefined : this.handleMouseEnter}
            onMouseLeave={disabled ? undefined : this.handleMouseLeave}
          >
            {children}
          </span>
        )}
      </Reference>
      {
        this.state.active &&
        <Popper
          placement={this.props.placement}
          positionFixed
          modifiers={{
            keepTogether: { enabled: true },
          }}
        >
          {({ ref, style, placement, arrowProps }) => (
            <div
              className={className}
              ref={ref}
              style={style}
              data-placement={placement}
            >
              <BubbleContainer placement={this.props.placement} appearance={this.props.appearance}>
                <TargetBubble text={text} parentRef={el => { this.bubbleRef = el; }} />
              </BubbleContainer>
              <div
                ref={arrowProps.ref}
                style={{ ...arrowProps.style, position: 'absolute', ...this.arrowCustomStyles }}
              >
                <Arrow placement={this.props.placement} appearance={this.props.appearance} />
              </div>
            </div>
          )}
        </Popper>
      }
    </Manager>;
  }
}

export default styled(Tooltip)`
  box-sizing: border-box;
  pointer-events: none;
  z-index: 1;
`;

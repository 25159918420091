export function removeHashSign(value: string): string {
  const isContainHashSign = value && value.indexOf("#") >= 0;
  let noHashSignValue = value;
  if (isContainHashSign) {
    noHashSignValue = value.replace("#", "");
  }
  return noHashSignValue;
}

export class JSONMapper<O> {
  constructor(public jsonObject: O) {
    this.jsonObject = this.goThroughtJSON(this.jsonObject);
  }

  goThroughtJSON(jsonObject): O {
    for (const property in jsonObject) {
      if (typeof jsonObject[property] === "object") {
        jsonObject[property] = this.goThroughtJSON(jsonObject[property]);
      } else {
        jsonObject[property] = this.map(jsonObject[property], property);
      }
    }

    return jsonObject;
  }

  map(node, hostPropertyName?: string) {
    console.log(node);
    return node;
  }

  toJSON(): O {
    return this.jsonObject;
  }
}

import { types } from '../_actionTypes/orders';
import { OrderItemDto } from "../service-proxies";
import { Moment } from "moment";

export interface IOrdersStore {
  orders: OrderItemDto[];
  listStatus: string;
  totalCount: number;
  perPage: number;
  skip: number;
  filter: string;
  startDate: Moment;
  dueDate: Moment;
}

const initialState: IOrdersStore = {
  orders: [],
  listStatus: '',
  totalCount: null,
  perPage: 10,
  skip: 0,
  filter: '',
  startDate: undefined,
  dueDate: undefined,
};

export default (state = initialState, action) => {
  const {
    orders,
    listStatus,
    totalCount,
    perPage,
    skip,
    filter,
    startDate,
    dueDate,
  } = action;

  switch (action.type) {
    case types.ORDERS_GET_UNAPPROVED:
      return ({
        ...state,
        orders,
        totalCount,
        listStatus,
        status,
      });
    case types.ORDERS_GET_PENDING:
      return ({
        ...state,
        orders,
        totalCount,
        listStatus,
        status,
      });
    case types.ORDERS_CHANGE_PAGE:
      return ({
        ...state,
        perPage,
        skip,
      });
    case types.ORDERS_SET_FILTER:
      return ({
        ...state,
        filter,
      });
    case types.ORDERS_SET_DATE_FILTER:
      return ({
        ...state,
        startDate,
        dueDate,
      });
    default:
      return state;
  }
};

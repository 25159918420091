import React from "react";
import styled from "styled-components";
import { Action, ActionCreatorsMapObject, bindActionCreators } from "redux";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { withRouter } from "react-router-dom";
import { history } from "../../../store";
import withGranted, {
  IWithGrantedProps
} from "../../../containers/HOC/WithGranted";
import WithPermission from "../../HOC/WithPermission";
import { TabsSwitch, RunningLineLoader } from "../../../components";
import { IListUserFormatted } from "../../../_actions/users";
import { IGlobalStore } from "../../../_reducers/reducers";

import {
  Table,
  Link,
  MainContainer,
  Button,
  Pagination,
  Select,
  Checkbox,
  Separator
} from "../../../components";

import { PencilIcon, TrashIcon, PlusIcon } from "../../../assets/icons";

import * as actions from "../../../_actions/users";
import * as actionsSearch from "../../../_actions/search";
import * as notifyActions from "../../../_actions/notification";
import * as actionsModal from "../../../_actions/modal";
import * as clientApi from "../../../_api/client";
import * as userApi from "../../../_api/user";

import {
  ADMINISTRATION_USERS_CREATE,
  ADMINISTRATION_USERS_UPDATE,
  ADMINISTRATION_USERS_DELETE,
  ADMINISTRATION_USERS_INACTIVE,
  ADMINISTRATION_USERS_PERMISSIONS_EDIT,
  ADMINISTRATION_USERS_ROLES_EDIT,
  ADMINISTRATION_USERS_SUPERVISORS_EDIT,
  ADMINISTRATION_USERS_INACTIVE_REACTIVATE
} from "../../../_constants/permissions";
import { IOpenConfirmPrompt } from "../../../_actions/modal";

const Position = {
  Container: styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
  `,

  Body: styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
  `,

  Toolbar: styled.header`
    text-align: right;
    height: 15px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
    ${Link.Button} {
      i {
        margin: 0;
      }
      margin: 0 0 0 10px;
    }
  `,

  Head: styled<{ className?: string }>(({ className, children }) => (
    <header className={className}>
      <div>{children}</div>
    </header>
  ))`
    padding: 20px;
    > div {
      margin: -7.5px;
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
    }
  `,

  HeadElement: styled.div`
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    & > * {
      margin: 7.5px;
    }
  `,

  Filter: styled.div`
    width: 260px;
  `,

  TabsSwitch: styled.div`
    padding: 10px 0 10px 10px;
  `
};

interface IUserListProps
  extends IConnectedActions,
    IConnectedProps,
    IWithGrantedProps {}

interface IUserListState {
  skip: number;
  perPage: number;
  tab: string;
  sortBy: string;
  filterText: string;
  inactiveFilter: boolean;
  clientId?: number;
}

class UserList extends React.Component<IUserListProps, IUserListState> {
  constructor(props) {
    super(props);

    this.handleAddNewClick = this.handleAddNewClick.bind(this);
    this.handleChangePage = this.handleChangePage.bind(this);
    this.handleTabChanged = this.handleTabChanged.bind(this);
    this.loadClients = this.loadClients.bind(this);
    this.handleClientSelect = this.handleClientSelect.bind(this);
    this.handleSortChanged = this.handleSortChanged.bind(this);
    this.handleInactiveChange = this.handleInactiveChange.bind(this);

    this.state = {
      skip: 0,
      perPage: 10,
      tab: "users",
      sortBy: "surname ASC",
      filterText: "",
      inactiveFilter: false
    };

    this.props.setSearch("/admin/users", text => {
      this.setState({ skip: 0, filterText: text });

      this.props.getAll(
        this.state.clientId,
        text,
        this.state.inactiveFilter,
        this.state.sortBy,
        0,
        this.state.perPage
      );
    });
  }

  componentDidMount() {
    this.props.getAll(
      this.state.clientId,
      this.state.filterText,
      this.state.inactiveFilter,
      this.state.sortBy,
      this.state.skip,
      this.state.perPage
    );
  }

  handleAddNewClick() {
    history.push("/admin/users/new");
  }

  handleExportClick() {}

  handleChangePage(pageInfo) {
    const { perPage, skip } = pageInfo;

    this.props.getAll(
      this.state.clientId,
      this.state.filterText,
      this.state.inactiveFilter,
      this.state.sortBy,
      skip,
      perPage
    );

    this.setState({
      skip,
      perPage
    });
  }

  handleTabChanged(value) {
    this.setState({ tab: value });
  }

  renderPreLoaderRows(perPage, status) {
    const rows = [];
    const elementsCount = status === "loading" ? perPage - 1 : 0;

    for (let i = 0; i < elementsCount; i++) {
      rows.push(
        <Table.Row
          key={i}
          colSpan={this.state.tab === "users" ? "4" : "6"}
          rows={perPage}
          isLoading={status === "loading"}
          cells={[<span key={0}>NO USERS FOUND</span>]}
        />
      );
    }

    return rows;
  }

  handleDelete(userId) {
    this.props
      .confirmPrompt()
      .then(() => {
        this.props.deleteUser(userId).then(() => {
          this.props.getAll(
            this.state.clientId,
            this.state.filterText,
            this.state.inactiveFilter,
            this.state.sortBy,
            this.state.skip,
            this.state.perPage
          );
        });
      })
      .catch(() => {});
  }

  loadClients(value, callback) {
    clientApi
      .searchClients(value)
      .then(({ items }) => {
        callback(null, {
          options: items,
          complete: true
        });
      })
      .catch(e => {});
  }

  handleClientSelect(data) {
    const clientId = data ? data.id : undefined;
    this.setState({ clientId, skip: 0 });

    this.props.getAll(
      clientId,
      this.state.filterText,
      this.state.inactiveFilter,
      this.state.sortBy,
      0,
      this.state.perPage
    );
  }

  handleInactiveChange(value) {
    this.setState({ inactiveFilter: value });

    this.props.getAll(
      this.state.clientId,
      this.state.filterText,
      value,
      this.state.sortBy,
      0,
      this.state.perPage
    );
  }

  handleSortChanged(sortValue) {
    this.setState({ sortBy: sortValue });

    this.props.getAll(
      this.state.clientId,
      this.state.filterText,
      this.state.inactiveFilter,
      sortValue,
      this.state.skip,
      this.state.perPage,
      "sorting"
    );
  }

  handleEditClick(id) {
    history.push(`/admin/users/${id}`);
  }

  handleReactivate(id) {
    userApi
      .reactivateUser(id)
      .then(() => {
        this.props.getAll(
          this.state.clientId,
          this.state.filterText,
          this.state.inactiveFilter,
          this.state.sortBy,
          this.state.skip,
          this.state.perPage
        );
      })
      .catch(e => {
        this.props.notifySwagger(e, "error");
      });
  }

  render() {
    return (
      <MainContainer title="User management" marginTop={20}>
        <Position.Container>
          <Separator borders="0 0 1">
            <Position.Head>
              <Position.HeadElement>
                <Position.Filter>
                  <Select
                    async
                    autoload
                    autocomplete
                    name="clientId"
                    placeholder="Filter by client..."
                    labelKey="displayValue"
                    valueKey="id"
                    value={this.state.clientId}
                    loadOptions={this.loadClients}
                    onChange={this.handleClientSelect}
                    compact
                  />
                </Position.Filter>
                <WithPermission permission={ADMINISTRATION_USERS_INACTIVE}>
                  <Checkbox
                    value={this.state.inactiveFilter}
                    label="Inactive users"
                    onChange={this.handleInactiveChange}
                  />
                </WithPermission>
              </Position.HeadElement>
              <Position.HeadElement>
                <WithPermission permission={ADMINISTRATION_USERS_CREATE}>
                  <Button onClick={this.handleAddNewClick} icon={<PlusIcon />}>
                    Add User
                  </Button>
                </WithPermission>
                <Button onClick={this.handleExportClick} primary>
                  Export
                </Button>
              </Position.HeadElement>
            </Position.Head>
          </Separator>
          <Position.Body>
            <RunningLineLoader
              isActive={
                this.props.allStatus === "sorting" ||
                this.props.allStatus === "loading"
              }
            />
            <Position.TabsSwitch>
              <TabsSwitch
                onTabChanged={this.handleTabChanged}
                items={[
                  { value: "users", label: "USERS" },
                  { value: "clients", label: "CLIENT CONTACTS" }
                ]}
              />
            </Position.TabsSwitch>
            <Table fullHeight>
              <Table.Header
                columns={
                  this.state.tab === "users"
                    ? [
                        { name: "name", label: "name", sortable: true },
                        { name: "surname", label: "last name", sortable: true },
                        { name: "title", label: "role" },
                        { name: "client", label: "client" },
                        { name: "", label: "" }
                      ]
                    : [
                        { name: "name", label: "name", sortable: true },
                        { name: "surname", label: "last name", sortable: true },
                        { name: "title", label: "title" },
                        {
                          name: "emailAddress",
                          label: "email",
                          sortable: true
                        },
                        {
                          name: "phoneNumber",
                          label: "phone number",
                          sortable: true
                        },
                        { name: "", label: "" }
                      ]
                }
                sortBy={this.state.sortBy}
                onSortChanged={this.handleSortChanged}
              />
              <Table.Body>
                {this.props.users && !!this.props.users.length ? (
                  this.props.users.map((item, index) => {
                    const actionButtons = (
                      <Position.Toolbar>
                        {this.props.isSomeGranted([
                          ADMINISTRATION_USERS_UPDATE,
                          ADMINISTRATION_USERS_PERMISSIONS_EDIT,
                          ADMINISTRATION_USERS_ROLES_EDIT,
                          ADMINISTRATION_USERS_SUPERVISORS_EDIT
                        ]) && (
                          <Link.Button
                            onClick={() => this.handleEditClick(item.id)}
                          >
                            {item.isActive ? <PencilIcon /> : "View"}
                          </Link.Button>
                        )}{" "}
                        {item.isActive
                          ? this.props.isGranted(
                              ADMINISTRATION_USERS_DELETE
                            ) && (
                              <Link.Button
                                onClick={() => this.handleDelete(item.id)}
                              >
                                <TrashIcon />
                              </Link.Button>
                            )
                          : this.props.isGranted(
                              ADMINISTRATION_USERS_INACTIVE_REACTIVATE
                            ) && (
                              <Link.Button
                                onClick={() => this.handleReactivate(item.id)}
                              >
                                {" "}
                                Reactivate
                              </Link.Button>
                            )}
                      </Position.Toolbar>
                    );

                    return (
                      <Table.Row
                        key={`${index}-${item.id}`}
                        cells={
                          this.state.tab === "users"
                            ? [
                                item.name,
                                item.surname,
                                item.role,
                                item.clientName,
                                actionButtons
                              ]
                            : [
                                item.name,
                                item.surname,
                                item.title || "",
                                item.emailAddress || "",
                                item.formattedPhone || "",
                                actionButtons
                              ]
                        }
                      />
                    );
                  })
                ) : (
                  <Table.Row
                    key={0}
                    colSpan={this.state.tab === "users" ? "4" : "6"}
                    rows={this.state.perPage}
                    isLoading={this.props.allStatus === "loading"}
                    cells={[<span key={0}>NO USERS FOUND</span>]}
                  />
                )}
                {(this.props.users && !!this.props.users.length) ||
                  this.renderPreLoaderRows(
                    this.state.perPage,
                    this.props.allStatus
                  )}
              </Table.Body>
            </Table>
          </Position.Body>
          {
            <Pagination
              skip={this.state.skip}
              perPage={this.state.perPage}
              count={this.props.totalCount}
              onChange={this.handleChangePage}
            />
          }
        </Position.Container>
      </MainContainer>
    );
  }
}

interface IConnectedActions {
  getAll: typeof actions.getAll;
  deleteUser: (id: number) => Promise<void>;
  reactivateUser: typeof actions.reactivateUser;
  confirmPrompt: IOpenConfirmPrompt;
  setSearch: typeof actionsSearch.set;
  notifySwagger: typeof notifyActions.notifySwagger;
}

interface IConnectedProps {
  users: IListUserFormatted[];
  allStatus: string;
  totalCount: number;
}

export default withRouter(
  connect(
    createStructuredSelector<IGlobalStore, IConnectedProps>({
      users: state => state.users.users,
      allStatus: state => state.users.allStatus,
      totalCount: state => state.users.totalCount
    }),
    dispatch =>
      bindActionCreators<IConnectedActions & ActionCreatorsMapObject, ActionCreatorsMapObject<Action>>(
        {
          getAll: actions.getAll,
          deleteUser: (actions.deleteUser as any) as (
            id: number
          ) => Promise<void>,
          reactivateUser: actions.reactivateUser,
          confirmPrompt: (actionsModal.prompt as any) as IOpenConfirmPrompt,
          setSearch: actionsSearch.set,
          notifySwagger: notifyActions.notifySwagger
        },
        dispatch
      )
  )(withGranted(UserList))
);

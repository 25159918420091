import React from 'react';
import styled from 'styled-components';

export default styled(props => {
  return (
    <i {...props}>
      <svg viewBox="1373 2861 16 16" width="16" height="16">
        <path d=" M 1374.319 2861 L 1373 2862.319 L 1379.681 2869 L 1373 2875.681 L 1374.319 2877 L 1381 2870.319 L 1387.681 2877 L 1389 2875.681 L 1382.319 2869 L 1389 2862.319 L 1387.681 2861 L 1381 2867.681 L 1374.319 2861 Z " />
      </svg>
    </i>
  );
})`
  ${ props => `
    width: 21px;
    height: 21px;
    display: inline-flex;
    justify-content: center;
    align-items: center;

    svg{
      path{
        fill: ${props.theme.primary}; 
      }
    }
  `}
`;

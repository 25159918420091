import lscache from 'lscache';
import sessionstorage from 'sessionstorage';
import * as APIServices from '../servicePool';
import {ICartStore} from '../_reducers/cart';
import {IShortLocationInfo, IDateLocationFilterSettings} from '../_types/common';
import { cartServiceProxy } from '../servicePool';

export function setToken(accessToken: string, expiration, rememberClient: boolean) {
  if (rememberClient) {
    sessionstorage.removeItem('accessToken');
    lscache.set('accessToken', accessToken, expiration);
  } else {
    lscache.remove('accessToken');
    sessionstorage.setItem('accessToken', accessToken);
  }
}

export function getToken() {
  let token = lscache.get('accessToken');
  if (!token) {
    token = sessionstorage.getItem('accessToken');
  }
  return token;
}

export function removeToken() {
  lscache.remove('accessToken');
  sessionstorage.removeItem('accessToken');
}

export function updateAPIServicesWithToken(token: string) {
  Object.keys(APIServices).forEach(serviceKey => {
    APIServices[serviceKey]._token = token;
  });
}

export function setUserLocation(userId: number, location: IShortLocationInfo ) {
  lscache.set(`userLocation-${userId}`, btoa(JSON.stringify(location)));
}

export function getUserLocation(userId: number): IShortLocationInfo {
  const location = lscache.get(`userLocation-${userId}`);
  if (location) {
    try {
      return JSON.parse(atob(location));
    } catch(e) {
      return {id: null, name: null};
    }
  } else {
    return {id: null, name: null};
  }
}

export function saveCartToLS(userId: number, catalogId: number, cart: ICartStore) {
  const s = JSON.stringify(cart);
  const s1 = JSON.parse(s);
  cartServiceProxy.saveCart(s1);

  // lscache.set(`cart-${userId}-${catalogId}`, btoa(unescape(encodeURIComponent(JSON.stringify(cart)))));
}

export function loadCartFromLS(userId: number, catalogId: number): ICartStore | null {
  
  const cartRaw = lscache.get(`cart-${userId}-${catalogId}`);
  if (cartRaw) {
    try {
      return JSON.parse(atob(cartRaw)) as ICartStore;
    } catch(e) {
      return null;
    }
  } else {
    return null;
  }
}


export function saveFilterSettings(key: string, filter: IDateLocationFilterSettings){
  lscache.set(key, btoa(JSON.stringify(filter)));
}

export function loadFilterSettings(key:string) : IDateLocationFilterSettings{
  const filter = lscache.get(key);
  if (filter) {
    try {
      return JSON.parse(atob(filter));
    } catch(e) {
      return {
        location: null,
        startDate: null,
        dueDate: null
      };
    }
  } else {
    return {
      location: null,
      startDate: null,
      dueDate: null
    };
  }
}

export function clearSearchFilter(){
  lscache.remove('searchFilter');
}

export function setSearchFilter(filter: string){
  lscache.set('searchFilter', btoa(filter));
}

export function getSearchFilter() : string {
  const filter = lscache.get('searchFilter');
  return filter != null ? atob(filter) : '';
}
import * as React from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import { FieldArray, FormikState } from "formik";
// import { updateInvoicePaidStatus } from "../../_api/invoices";
import { UpdateInvoicePaidStatusDto } from "./../../service-proxies";
import { apiUrl } from "../../_constants/system";
import { notifySwagger } from "../../_actions/notification";

import { history } from "../../store";
import {
  Table,
  Thumbnail,
  Link,
  Article,
  Skeleton,
  Toggle
} from "../../components";
import { IViewInvoiceState } from "./View";
import ManualEntryView from "../../sharedComponents/ManualEntryView";
import { formatPrice, roundAndFormatPrice } from "../../_utils/prices";

const CenteredContainer = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: center;
  height: 100%;
  width: 100%;
  position: relative;
`;

const RightContainer = styled.div`
  align-items: center;
  justify-content: flex-end;
  height: 100%;
  width: 100%;
`;

const Body = styled.div`
  height: 100%;
`;

const Footer = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 20px 20px 30px;
`;

const Info = styled.div`
  text-align: right;
  padding: 0 20px 20px;
`;

const FooterElement = styled.div`
  > * {
    margin-left: 10px;
  }
`;

const LeftSidedContent = styled.div`
  width: 100%;
  text-align: right;
`;

const Position = class {
  static CenteredContainer = CenteredContainer;
  static RightContainer = RightContainer;
  static Body = Body;
  static Footer = Footer;
  static Info = Info;
  static FooterElement = FooterElement;
  static LeftSidedContent = LeftSidedContent;
};

interface IViewFormProps extends FormikState<IViewInvoiceState> {
  notifySwagger: typeof notifySwagger;
  handlePaidStatusChange: (input: UpdateInvoicePaidStatusDto) => void;
  isPaid: boolean;
}

interface IViewFormState {
  status: string;
}

class ViewForm extends React.Component<IViewFormProps, IViewFormState> {
  constructor(props) {
    super(props);
    this.getData = this.getData.bind(this);
    this.handleClickBack = this.handleClickBack.bind(this);
    this.handleIsPaidToggle = this.handleIsPaidToggle.bind(this);

    this.state = {
      status: ""
    };
  }

  componentDidMount() {
    this.getData();
  }

  async getData() {
    this.setState({ status: "loading" });
    try {
      this.setState({
        status: "loaded"
      });
    } catch (e) {
      this.setState({ status: "error" });
      this.props.notifySwagger(e, "error");
    }
  }

  handleClickBack(): void {
    history.goBack();
  }

  handleIsPaidToggle() {
    const input: UpdateInvoicePaidStatusDto = {
      id: this.props.values.id,
      hasBeenPaid: !this.props.isPaid
    };
    this.props.handlePaidStatusChange(input);
  }

  renderPreloaderRows(perPage: number, status?: string): React.ReactNode {
    const rows = [];
    const elementsCount = status === "loading" ? perPage - 1 : 0;

    for (let i = 0; i < elementsCount; i++) {
      rows.push(
        <Table.Row
          key={"preloader" + i}
          rows={perPage}
          cells={[
            <Skeleton.Square key="thumb-preloader" />,
            <React.Fragment key="item-preloader">
              <p>
                <Skeleton.Line />
              </p>
              <p>
                <Skeleton.Line />
              </p>
              <p>
                <Skeleton.Line />
              </p>
            </React.Fragment>,
            <Skeleton.Line key="price-preloader" size="xs" />,
            <Skeleton.Line key="count-preloader" size="xs" />,
            <Skeleton.Line key="sub-price-preloader" size="xs" />,
            <Skeleton.Line key="action-preloader" size="xs" />
          ]}
        />
      );
    }
    return rows;
  }

  render() {
    const {
      values
      // errors,
    } = this.props;

    return (
      <form onSubmit={() => {}}>
        <Position.Body>
          {
            <Table
              columns={[
                { name: "thumb", label: "", size: "xs" },
                { name: "item", label: "item", size: "xl" },
                {
                  name: "price",
                  label: "price",
                  size: "md",
                  alignment: "right"
                },
                {
                  name: "quantity",
                  label: "quantity",
                  size: "md",
                  alignment: "right"
                },
                {
                  name: "subtotal",
                  label: "subtotal",
                  size: "md",
                  alignment: "right"
                },
                { name: "uom", label: "uom", size: "md", alignment: "center" }
              ]}
            >
              <Table.Header />
              <Table.Body>
                {values.invoiceStatus === "loaded" && (
                  <FieldArray
                    name="productEntries"
                    render={arrayHelpers =>
                      values.items &&
                      !!values.items.length &&
                      values.items.map((orderItem, index) => {
                        const orderPriсe = orderItem.isZeroPrice
                          ? 0.0
                          : orderItem.price;
                        return (
                          <React.Fragment
                            key={`container-order-product-${index}`}
                          >
                            <Table.Row
                              key={`order-product-${index}`}
                              cells={[
                                <Thumbnail
                                  image={
                                    orderItem.product.picture
                                      ? apiUrl +
                                        orderItem.product.picture.pictureUrl
                                      : null
                                  }
                                  key={`thumbnail-${index}`}
                                />,
                                <React.Fragment key={`item-${index}`}>
                                  <p>
                                    <Link
                                      key={`item-${index}`}
                                      primary
                                      to={`/products/product/${index}`}
                                    >
                                      <span style={{ color: "#000" }}>
                                        {orderItem.productName}
                                      </span>
                                    </Link>
                                  </p>
                                  <p>
                                    <span style={{ color: "#000" }}>
                                      {orderItem.sku}
                                    </span>
                                  </p>
                                </React.Fragment>,
                                <Position.RightContainer
                                  key={`productEntries.${index}.price`}
                                >
                                  {formatPrice(orderPriсe)}
                                </Position.RightContainer>,
                                <Position.RightContainer
                                  key={`productEntries.${index}.quantity`}
                                >
                                  {orderItem.quantity}
                                </Position.RightContainer>,
                                <Position.RightContainer
                                  key={`productEntries.${index}.subtotal`}
                                >
                                  {roundAndFormatPrice(
                                    orderItem.subTotalAmount
                                  )}
                                </Position.RightContainer>,
                                <Position.CenteredContainer
                                  key={`productEntries.${index}.packageType`}
                                >
                                  {orderItem.packageType.displayValue}
                                </Position.CenteredContainer>
                              ]}
                            />
                            {orderItem.zeroPriceNote && (
                              <Table.Row
                                key={`order-product-zero-price-${index}`}
                                colSpan="7"
                                cells={[
                                  <React.Fragment
                                    key={`order-product-zero-price-control-${index}`}
                                  >
                                    <Article.Text scale="lg">
                                      {orderItem.zeroPriceNote}
                                    </Article.Text>
                                  </React.Fragment>
                                ]}
                              />
                            )}
                            {orderItem.note && (
                              <ManualEntryView
                                colSpan={6}
                                description={orderItem.note.displayValue}
                                entry={orderItem.manualEntry}
                              />
                            )}
                          </React.Fragment>
                        );
                      })
                    }
                  />
                )}
                {values.invoiceStatus === "loading" &&
                  this.renderPreloaderRows(5, values.invoiceStatus)}
                {values.items && (
                  <>
                    <Table.Row
                      cells={[
                        "",
                        "",
                        "",
                        <Position.RightContainer
                          key={`order-product-price-total-label`}
                        >
                          <Article.Text light scale="sm">
                            SUB TOTAL:
                          </Article.Text>
                        </Position.RightContainer>,
                        <Article.Text
                          scale="sm"
                          key={`order-product-price-total-label`}
                        >
                          {roundAndFormatPrice(values.subTotalAmount)}
                        </Article.Text>,
                        ""
                      ]}
                    />
                    <Table.Row
                      cells={[
                        "",
                        "",
                        "",
                        <Position.RightContainer
                          key={`order-product-price-total-label`}
                        >
                          <Article.Text light scale="sm">
                            TAXES ({values.taxRate}%):
                          </Article.Text>
                        </Position.RightContainer>,
                        <Article.Text
                          scale="sm"
                          key={`order-product-price-total-label`}
                        >
                          {roundAndFormatPrice(values.taxesTotal)}
                        </Article.Text>,
                        ""
                      ]}
                    />
                    <Table.Row
                      cells={[
                        "",
                        "",
                        "",
                        <Position.RightContainer
                          key={`order-product-price-total-label`}
                        >
                          <Article.P light scale="lg">
                            TOTAL:
                          </Article.P>
                        </Position.RightContainer>,
                        <Article.P
                          scale="lg"
                          key={`order-product-price-total-label`}
                        >
                          {roundAndFormatPrice(values.totalAmount)}
                        </Article.P>,
                        ""
                      ]}
                    />
                  </>
                )}
              </Table.Body>
            </Table>
          }
          <Position.Info>
            {values.invoiceStatus === "loaded" && values.location ? (
              <Article.P scale="sm">
                Facility Location: {values.location.displayValue}
              </Article.P>
            ) : null}
          </Position.Info>
        </Position.Body>
        <Position.Footer>
          <Position.FooterElement />
          <Position.FooterElement>
            <Link.Button
              onClick={this.handleClickBack}
              preloader={values.invoiceStatus === "loading"}
            >
              Back
            </Link.Button>
            <div style={{ padding: "0 25px", display: "inline-block" }}>
              <Toggle
                label={"Paid:"}
                value={this.props.isPaid}
                onChange={this.handleIsPaidToggle}
              />
            </div>
          </Position.FooterElement>
        </Position.Footer>
      </form>
    );
  }
}

export default connect(
  null,
  {
    notifySwagger
  }
)(ViewForm);

import * as React from 'react';
import {
  FieldArray,
  FormikHandlers,
  FormikState,
  Field as FieldFormik,
  FormikActions,
} from 'formik';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';

import { IMatch } from '../../../_types/common';
// import { history } from '../../../store';
import {
  notify,
  notifySwagger,
} from '../../../_actions/notification';
import {
  openCustomModalCallback,
} from '../../../_actions/modal';
import withGranted, { IWithGrantedProps } from '../../HOC/WithGranted';
import {
  Table,
  Thumbnail,
  Link,
  Article,
  Toggle,
  Field,
  Checkbox,
  Skeleton,
  // Button,
} from '../../../components';
import { apiUrl } from '../../../_constants/system';
import { IViewState } from './types';
// import * as ordersApi from '../../../_api/orders';
// import ReconciliationModal from '../parts/ReconcilationModal';
import ManualEntryView from '../../../sharedComponents/ManualEntryView';
import { formatPrice, roundAndFormatPrice } from '../../../_utils/prices';
// import { COMPANYPACKINGSLIPS_EDITORRECONCILE } from 'src/_constants/permissions';
// import { UpdatePackingSlipOutput } from '../../../service-proxies';

const Position = {
  Body: styled.div`
    height: 100%;
  `,

  TableBody: styled.div`
    height: 100%;
  `,

  Footer: styled.div`
    display: flex;
    justify-content: space-between;
    padding: 20px 20px 30px;
  `,

  FooterElement: styled.div`
    > * {
      margin-left: 10px;
    }
  `,

  RightContainer: styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-end;
    height: 100%;
    width: 100%;
  `,

  CenteredContainer: styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
    position: relative;
  `,
};

interface IViewFormProps extends FormikHandlers, FormikState<IViewState>, FormikActions<{}>, IWithGrantedProps {
  onClickBack: () => void;
  notify: typeof notify;
  notifySwagger: typeof notifySwagger;
  openCustomModalCallback: typeof openCustomModalCallback;
  match: IMatch;
}

class ViewForm extends React.Component<IViewFormProps> {

  static renderPreloaderRows(perPage: number, status?: string): React.ReactNode {
    const rows = [];
    const elementsCount = ((status === 'loading') || (status === 'submiting')) ? perPage - 1 : 0;

    for (let i = 0; i < elementsCount; i++) {
      rows.push(<Table.Row
        key={'preloader' + i}
        rows={perPage}
        cells={[
          <Skeleton.Square key="thumb-preloader" />,
          <React.Fragment key="item-preloader">
            <p><Skeleton.Line /></p>
            <p><Skeleton.Line /></p>
            <p><Skeleton.Line /></p>
          </React.Fragment>,
          <Skeleton.Line key="price-preloader" size="xs" />,
          <Skeleton.Line key="count-preloader" size="sm" />,
          <Skeleton.Line key="recieved-preloader" size="xs" />,
          <Skeleton.Line key="recieved-count-preloader" size="sm" />,
          <Skeleton.Line key="return-preloader" size="xs" />,
          <Skeleton.Line key="sub-price-preloader" size="xs" />,
        ]}
      />);
    }
    return rows;
  }

  constructor(props) {
    super(props);
    // this.handleClickSaveEdits = this.handleClickSaveEdits.bind(this);
    // this.handleClickReconcile = this.handleClickReconcile.bind(this);
    // this.saveEdits = this.saveEdits.bind(this);
    // this.reconcile = this.reconcile.bind(this);
  }

  // async handleClickSaveEdits() {

  //   const isNeedToReturn = this.props.values.items.some(item => item.isNeedToReturn);

  //   if (isNeedToReturn) {

  //     try {

  //       await this.props.openCustomModalCallback((resolve, reject) => {
  //         return <ReconciliationModal
  //           items={this.props.values.items}
  //           onClickReject={() => {
  //             reject();
  //           }}
  //           onClickResolve={(items) => {
  //             this.props.setFieldValue('items', items);
  //             resolve();
  //           }}
  //         />;
  //       }, {
  //           title: 'Return Items',
  //         });

  //       this.props.setFieldValue('orderStatus', 'submiting');

  //       if (this.props.values.isChanged) {

  //         const emailSent = await this.saveEdits();
  //         console.log(emailSent);
  //         if (emailSent && emailSent.emailSent) {
  //           this.props.notify('An email has been sent to your Supply Chain Manager for these items to be returned.', 'info');
  //         }

  //         this.props.setValues({
  //           ...this.props.values,
  //           isChanged: false,
  //           orderStatus: 'done',
  //         });
  //       }

  //     } catch (e) {

  //       if (e) {
  //         this.props.notifySwagger(e, 'error');
  //       }

  //     }

  //   } else {

  //     this.props.setFieldValue('orderStatus', 'submiting');

  //     try {

  //       if (this.props.values.isChanged) {

  //         await this.saveEdits();

  //         this.props.setValues({
  //           ...this.props.values,
  //           isChanged: false,
  //           orderStatus: 'done',
  //         });
  //       }

  //     } catch (e) {

  //       this.props.notifySwagger(e, 'error');

  //     }
  //   }
  // }

  // async handleClickReconcile() {
  //   const isNeedToReturn = this.props.values.items.some(item => item.isNeedToReturn);

  //   if (isNeedToReturn) {

  //     try {

  //       await this.props.openCustomModalCallback((resolve, reject) => {
  //         return <ReconciliationModal
  //           items={this.props.values.items}
  //           onClickReject={() => {
  //             reject();
  //           }}
  //           onClickResolve={(items) => {
  //             this.props.setFieldValue('items', items);
  //             resolve();
  //           }}
  //         />;
  //       }, {
  //           title: 'You are choosing to return the following items:',
  //         });

  //       this.props.setFieldValue('orderStatus', 'submiting');

  //       if (this.props.values.isChanged) {
  //         const emailSent = await this.saveEdits();

  //         if (emailSent && emailSent.emailSent) {
  //           this.props.notify('An email has been sent to your Supply Chain Manager for these items to be returned.', 'info');
  //         }

  //       }

  //       await this.reconcile();

  //       history.goBack();

  //     } catch (e) {

  //       if (e) {
  //         this.props.notifySwagger(e, 'error');
  //       }

  //     }

  //   } else {

  //     this.props.setFieldValue('orderStatus', 'submiting');

  //     try {

  //       if (this.props.values.isChanged) {
  //         await this.saveEdits();
  //       }

  //       await this.reconcile();

  //     } catch (e) {

  //       this.props.notifySwagger(e, 'error');

  //     }

  //     history.goBack();
  //   }
  // }

  // saveEdits(): Promise<UpdatePackingSlipOutput> {
  //   return ordersApi.updatePackingSlipOrder(this.props.values.items, this.props.match.params.orderId);
  // }

  // reconcile() {
  //   return ordersApi.reconcilePackingSlipOrder(this.props.match.params.orderId);
  // }

  render() {
    const {
      values,
      // errors,
    } = this.props;

    return <React.Fragment>

      <Position.Body>
        <Table columns={[
          { name: 'thumb', label: '', size: 'xs' },
          { name: 'item', label: 'item', size: 'xl' },
          { name: 'price', label: 'price', size: 'md', alignment: 'right' },
          { name: 'quantity', label: 'quantity', alignment: 'right' },
          { name: 'received', label: 'received', alignment: 'center' },
          { name: 'quantityReceived', label: 'Quantity Received', alignment: 'center' },
          { name: 'return', label: 'return' },
          { name: 'subtotal', label: 'subtotal', alignment: 'right' },
        ]}>
          <Table.Header />
          <Table.Body>
            <FieldArray
              name="items"
              render={() =>
                (values.orderStatus === 'done') &&
                values.items && !!values.items.length &&
                (
                  values.items.map((orderItem, index) => {
                    const price = orderItem.price ?
                      orderItem.price :
                      0;

                    const finalQuantity = (orderItem.quantityReceived) ?
                      orderItem.quantityReceived :
                      orderItem.quantity;

                    return <React.Fragment key={`container-order-product-${index}`}>

                      <Table.Row key={`order-product-${index}`} cells={[
                        <Thumbnail
                          image={orderItem.product.picture ? apiUrl + orderItem.product.picture.pictureUrl : null}
                          key={`thumbnail-${index}`}
                        />,
                        <React.Fragment key={`item-${index}`}>
                          <p>
                            <Link
                              key={`item-${index}`}
                              primary
                              to={`/products/product/${orderItem.product.id}`}
                            >
                              {orderItem.product.name}
                            </Link>
                          </p>
                          <p>
                            <span>
                              {orderItem.product.sku}
                            </span>
                          </p>
                        </React.Fragment>,
                        <Position.RightContainer key={`items.${index}.price`}>
                          {formatPrice(price)}
                        </Position.RightContainer>,
                        <Position.RightContainer key={`items.${index}.quantity`}>
                          {`${orderItem.quantity}`}
                        </Position.RightContainer>,
                        <Position.RightContainer key={`items.${index}.isRecived`}>
                          <FieldFormik
                            name={`items.${index}.isRecived`}
                            render={props => {
                              return <Toggle
                                {...props.field}
                                disabled
                              // onChange={e => {
                              //   if (!values.isChanged) {
                              //     props.form.setFieldValue('isChanged', true);
                              //   }
                              //   if (e.target.checked && !orderItem.quantityReceived) {
                              //     props.form.setFieldValue(`items.${index}.quantityReceived`, orderItem.quantity);
                              //   }
                              //   if (!e.target.checked && orderItem.isRecived) {
                              //     props.form.setFieldValue(`items.${index}.isNeedToReturn`, false);
                              //   }
                              //   props.form.setFieldValue(props.field.name, e.target.checked);
                              // }}
                              />;
                            }}
                          />
                        </Position.RightContainer>,
                        <Position.RightContainer key={`items.${index}.quantityReceived`}>
                          <FieldFormik
                            name={`items.${index}.quantityReceived`}
                            render={props => <Field
                              {...props.field}
                              compact
                              id={index}
                              scale="sm"
                              // error={errors.items && errors.items[index] && errors.items[index].quantityReceived}
                              // disabled={!orderItem.isRecived}
                              disabled
                            // onChange={e => {
                            //   if (!values.isChanged) {
                            //     props.form.setFieldValue('isChanged', true);
                            //   }
                            //   props.form.setFieldValue(props.field.name, e.target.value);
                            // }}
                            />}
                          />
                        </Position.RightContainer>,
                        <Position.CenteredContainer key={`items.${index}.isNeedToReturn`}>
                          <FieldFormik
                            name={`items.${index}.isNeedToReturn`}
                            key={`order-product-isNeedToReturn-${index}`}
                            render={props => <Checkbox
                              {...props.field}
                              // disabled={!orderItem.isRecived}
                              disabled
                            // onChange={value => {
                            //   if (!values.isChanged) {
                            //     props.form.setFieldValue('isChanged', true);
                            //   }
                            //   return this.props.setFieldValue(props.field.name, value);
                            // }}
                            />}
                          />
                        </Position.CenteredContainer>,
                        <Position.RightContainer key={`items.${index}.total`}>
                          {roundAndFormatPrice(price * finalQuantity)}
                        </Position.RightContainer>,
                      ]} />
                      {
                        orderItem.note &&
                        <ManualEntryView
                          colSpan={9}
                          description={orderItem.note.displayValue}
                          entry={orderItem.manualEntry}
                        />
                      }
                      {
                        !!parseInt(`${orderItem.quantityReturned}`, 10) &&
                        <Table.Row colSpan="9" cells={[
                          `Returned items: ${orderItem.quantityReturned}`
                        ]} />
                      }
                    </React.Fragment>;
                  })
                )
              }
            />
            {
              (
                (values.orderStatus === 'loading') || (values.orderStatus === 'submiting')
              ) &&
              ViewForm.renderPreloaderRows(9, values.orderStatus)
            }
            <Table.Row cells={[
              '', '', '', '', '', '',
              <Position.RightContainer key={`order-product-price-total-label`}>
                <Article.P light scale="lg">
                  TOTAL:
                </Article.P>
              </Position.RightContainer>,
              <React.Fragment key={`order-product-price-total-label`}>
                <Article.P scale="lg">
                  {
                    values.items &&
                    roundAndFormatPrice(values.items.reduce((result, orderItem) => {
                      const price = orderItem.price ?
                        orderItem.price :
                        0;
                      const finalQuantity = (orderItem.quantityReceived) ?
                        orderItem.quantityReceived :
                        orderItem.quantity;
                      return result + (price * finalQuantity);
                    }, 0))
                  }
                </Article.P>
              </React.Fragment>,
            ]} />

          </Table.Body>
        </Table>
      </Position.Body>
      <Position.Footer>
        <Position.FooterElement />
        <Position.FooterElement>
          <Link.Button
            onClick={this.props.onClickBack}
            preloader={(values.orderStatus === 'loading') || (values.orderStatus === 'submiting')}
          >
            Back
          </Link.Button>
          {
            // this.props.isGranted(COMPANYPACKINGSLIPS_EDITORRECONCILE) &&
            // values.isChanged &&
            // <Button
            //   onClick={this.handleClickSaveEdits}
            //   primary
            //   disabled={!!this.props.errors.items}
            //   preloader={(values.orderStatus === 'loading') || (values.orderStatus === 'submiting')}
            // >
            //   Save Edits
            // </Button>
          }
          {
            // this.props.isGranted(COMPANYPACKINGSLIPS_EDITORRECONCILE) &&
            // <Button
            //   onClick={this.handleClickReconcile}
            //   disabled={!!this.props.errors.items}
            //   preloader={(values.orderStatus === 'loading') || (values.orderStatus === 'submiting')}
            // >
            //   Reconcile Order
            // </Button>
          }
        </Position.FooterElement>
      </Position.Footer>

    </React.Fragment>;
  }
}

export default withGranted(connect(
  null, {
    notify,
    notifySwagger,
    openCustomModalCallback,
  }
)(withRouter(ViewForm)));

import * as types from '../_actionTypes/category';
import { types as typesNotification } from '../_actionTypes/notification';
import { categoryServiceProxy, productServiceProxy } from '../servicePool';
import { ListResultDtoOfGetСategoriesDto, ListResultDtoOfCountDtoOfInt64 } from '../service-proxies';
import {
  getSwaggerErrorResponse,
} from '../_utils/errorHandling';

export type TGetCategories = (catalogId: number, isArchived: boolean, filter: string) => (d, s) => void;

export function getClientCategories(catalogId: number, isArchived: boolean = false, filter: string = '') {
  return function (dispatch) {
    dispatch({
      type: types.CATEGORY_GET_CATEGORIES,
      categories: [],
      listStatus: 'loading',
    });
    return categoryServiceProxy.getCategoriesForClientCatalog(catalogId)
      .then((categories: ListResultDtoOfGetСategoriesDto) => {
        dispatch({
          type: types.CATEGORY_GET_CATEGORIES,
          categories: categories.items,
          listStatus: 'loaded',
        });

      })
      .catch(e => {
        dispatch({
          type: types.CATEGORY_GET_CATEGORIES,
          listStatus: 'error',
        });
        dispatch({
          type: typesNotification.NOTIFICATION_NOTIFY,
          message: getSwaggerErrorResponse(e).error.message,
          messageType: 'error',
        });
      });
  };
}

export function getCountedClientCategories(catalogId: number, filter: string = '') {
  return async function (dispatch) {
    dispatch({
      type: types.CATEGORY_GET_CATEGORIES,
      categories: [],
      listStatus: 'loading',
    });

    let productsCounts = null;

    try {
      productsCounts = await productServiceProxy.getCountForClientCatalog(catalogId, filter);
    } catch (e) {
      dispatch({
        type: typesNotification.NOTIFICATION_NOTIFY,
        message: getSwaggerErrorResponse(e).error.message,
        messageType: 'error',
      });
    }

    try {

      const categoriesData = await categoryServiceProxy.getCategoriesForClientCatalog(catalogId);
      const categories = categoriesData.items;
      categories.map(category => {
        if (productsCounts) {
          const categoryCountData = productsCounts.items.find((count) => {
            return count.id === category.id;
          });
          category.name = category.name + ` (${categoryCountData.count})`;
          return category;
        }
        return category;
      });
      dispatch({
        type: types.CATEGORY_GET_CATEGORIES,
        categories,
        listStatus: 'loaded',
      });

    } catch (e) {
      dispatch({
        type: typesNotification.NOTIFICATION_NOTIFY,
        message: getSwaggerErrorResponse(e).error.message,
        messageType: 'error',
      });
    }

  };
}

export function getMasterCategories(isArchived: boolean = false, filter: string = '', expandSubCategoryId?: number | string) {
  return function (dispatch) {
    dispatch({
      type: types.CATEGORY_GET_CATEGORIES,
      listStatus: 'loading',
    });
    return categoryServiceProxy.getCategoriesForMasterCatalog()
      .then((result: ListResultDtoOfGetСategoriesDto) => {
        dispatch({
          type: types.CATEGORY_GET_CATEGORIES,
          categories: result.items,
          listStatus: 'loaded',
          expandSubCategoryId,
        });
      })
      .catch(e => {
        dispatch({
          type: types.CATEGORY_GET_CATEGORIES,
          listStatus: 'error',
        });
        dispatch({
          type: typesNotification.NOTIFICATION_NOTIFY,
          message: getSwaggerErrorResponse(e).error.message,
          messageType: 'error',
        });
      });
  };
}

export function getCountedMasterCategories(isArchived: boolean = false, filter: string = '', expandSubCategoryId?: number | string) {
  return async function (dispatch) {
    dispatch({
      type: types.CATEGORY_GET_CATEGORIES,
      listStatus: 'loading',
    });

    let productsCounts = null;

    try {
      productsCounts = await productServiceProxy.getCountForMasterCatalog(isArchived, filter);
    } catch (e) {
      dispatch({
        type: typesNotification.NOTIFICATION_NOTIFY,
        message: getSwaggerErrorResponse(e).error.message,
        messageType: 'error',
      });
    }

    try {

      const categoriesData = await categoryServiceProxy.getCategoriesForMasterCatalog();
      const categories = categoriesData.items;
      categories.map(category => {
        if (productsCounts) {
          const categoryCountData = productsCounts.items.find((count) => {
            return count.id === category.id;
          });
          category.name = category.name + ` (${categoryCountData.count})`;
          return category;
        }
        return category;
      });
      dispatch({
        type: types.CATEGORY_GET_CATEGORIES,
        categories,
        listStatus: 'loaded',
      });

    } catch (e) {
      dispatch({
        type: typesNotification.NOTIFICATION_NOTIFY,
        message: getSwaggerErrorResponse(e).error.message,
        messageType: 'error',
      });
    }

  };
}

export type TGetCategoriesProductsCount = (isArchived: boolean, filter: string, clientUnitId?: number) => (d, s) => void;

export function getCategoriesProductsCount(isArchived: boolean, filter: string = '', clientUnitId?: number) {
  return function (dispatch) {
    productServiceProxy.getCountForClientCatalog(clientUnitId, filter)
      .then((productsCounts: ListResultDtoOfCountDtoOfInt64) => {
        dispatch({
          type: types.CATEGORY_GET_CATEGORY_PRODUCTS_COUNTS,
          productsCounts: productsCounts.items,
        });
      });
  };
}

export type TGetCategoriesProductsCountMaster = (isArchived: boolean, filter: string, clientUnitId?: number) => (d, s) => void;

export function getCategoriesProductsCountMaster(isArchived: boolean, filter: string = '', clientUnitId?: number) {
  return function (dispatch) {
    productServiceProxy.getCountForMasterCatalog(isArchived, filter)
      .then((productsCounts: ListResultDtoOfCountDtoOfInt64) => {
        dispatch({
          type: types.CATEGORY_GET_CATEGORY_PRODUCTS_COUNTS,
          productsCounts: productsCounts.items,
        });
      });
  };
}

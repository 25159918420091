import * as React from 'react';
import styled, { StyledComponentClass } from 'styled-components';
import { Formik } from 'formik';
import * as Yup from "yup";
import { Action, ActionCreatorsMapObject, bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import moment from 'moment';
import { match } from 'react-router';
import * as actionsNotification from '../../../_actions/notification';

import {
  EditVendorDto,
  UpdateVendorDto,
  CreateVendorDto,
  EditVendorDtoTransmissionType,
} from '../../../service-proxies';

import Form from './Form';

import { history } from '../../../store';
import {
  MainContainer,
} from '../../../components';
import { IOpenConfirmPrompt, prompt } from '../../../_actions/modal';
import * as actionsSearch from '../../../_actions/search';
import * as vendorsApi from '../../../_api/vendor';
import { Omit } from '../../../_types/common';
import {
  REQUIRED_MESSAGE, 
} from '../../../_constants/validation';
import {
  getMaxTextLengthMessage,
} from '../../../_utils/validation';
import { JSONMapper } from 'src/_utils/misc';

type StyledComponent = StyledComponentClass<{}, React.StatelessComponent<{}>>;

interface IPosition extends StyledComponent {
  Body?: StyledComponent;
  Head?: StyledComponent;
}

const Position: IPosition = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column; 
`;

Position.Body = styled.div`
  width: 100%; 
  height: 100%; 
`;

Position.Head = styled.header`
  text-align: right;
  padding: 20px;
`;

const validationSchema = Yup.object().shape({
  name: Yup.string()
    .trim()
    .max(80, getMaxTextLengthMessage(80))
    .required(REQUIRED_MESSAGE),
  email: Yup.string()
    .trim()
    .max(256, getMaxTextLengthMessage(256))
    .email('Invalid E-mail address')
    .test('email is required', REQUIRED_MESSAGE, function (value) {
      if (!value) {
        return this.parent.transmissionType === 2;
      }else {
        return true;
      }
    }),
  transmissionType: Yup.number()
    .required(REQUIRED_MESSAGE),
});

type EditClientMod = Omit<EditVendorDto, 'id'>;

export interface ISuppliersEdit {
  supplier?: string;
  id?: string;
}

export interface IEditVendorState extends EditClientMod {
  id: number;
  status: 'loading' | 'done';
  filter?: string;
  transmissionTypeName?: string;
}

class EmptyToNullAndTrimMapper extends JSONMapper<IEditVendorState | EditVendorDto> {
  map(node) {
    if (typeof node === 'string') {
      node = node.trim();
    }
    if (!node) {
      node = null;
    }
    return node;
  }
}

const initialState: IEditVendorState = {
  id: null,
  name: '',
  email: '',
  transmissionType: EditVendorDtoTransmissionType._1,
  status: 'loading',
};

interface IClientEditProps extends IConnectedActions {
  match: match<{
    id: string,
  }>;
}

class Edit extends React.Component<IClientEditProps, IEditVendorState> {

  isNew: boolean;

  constructor(props) {
    super(props);

    this.state = {
      ...initialState,
      filter: '',
    };

    this.isNew = !this.props.match.params.id;

    this.mapDateToString = this.mapDateToString.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);

  }

  componentDidMount() {
    !this.isNew ?
      this.getClient() :
      this.setState({ status: 'done' });
  }

  getClient(): void {
    this.setState({ status: 'loading' }, () => {

      const clientId = +this.props.match.params.id;

      vendorsApi.getVendorForEdit(clientId)
        .then(clientData => {
          this.setState({
            ...new EmptyToNullAndTrimMapper(clientData).toJSON(),
            email: !clientData.email ? '' : clientData.email,
            transmissionType: clientData.transmissionType,
            status: 'done',
          });
        })
        .catch(e => {
          this.props.notify(e, 'error');
        });

    });
  }

  mapDateToString(date) {
    return moment(date).isValid() ? moment(date).format('YYYY-MM-DD') : null;
  }

  mapSuppliers(items) {
    const newValues = items.map(item => {
      return item.supplier;
    });

    return newValues;
  }

  mapSuppliersToEdit(items): ISuppliersEdit[] {
    const newValues = items.map(item => {
      return { supplier: item, id: item };
    });

    return newValues;
  }

  handleSubmit(values: IEditVendorState) {
    this.saveChanges(values);
  }

  saveChanges(values: IEditVendorState) {
    const newValues = new EmptyToNullAndTrimMapper(values).toJSON();
    newValues.email = values.email;

    this.setState({
      status: 'loading',
    }, () => {
      if (this.isNew) {
        vendorsApi.createVendor(newValues as any as CreateVendorDto)
          .then(() => {
            history.push('/admin/vendors');
          })
          .catch(e => {
            this.props.notify(e, 'error');
          });
      } else {
        vendorsApi.updateVendor(newValues as any as UpdateVendorDto)
          .then(() => {
            history.push('/admin/vendors');
          })
          .catch(e => {
            this.props.notify(e, 'error');
          });
      }
    });

  }

  render() {
    return (
      <MainContainer
        scale="md"
        title={`${this.isNew ? 'create new' : 'edit'} vendor`}
        subTitle={`Please fill out all the fields below properly to ${this.isNew ? 'create a new' : 'edit a'} vendor`}
        centered
      >
        {
          <Formik
            enableReinitialize
            initialValues={this.state}
            validationSchema={validationSchema}
            onSubmit={this.handleSubmit}
            render={props => <Form
              {...props}
              isLoading={this.state.status === 'loading'}
              isNew={this.isNew}
            />}
          />
        }
      </MainContainer>
    );
  }
}

interface IConnectedActions {
  setSearch: typeof actionsSearch.set;
  prompt: IOpenConfirmPrompt;
  notify: typeof actionsNotification.notifySwagger;
}

export default connect(
  null,
  dispatch => bindActionCreators<IConnectedActions & ActionCreatorsMapObject, ActionCreatorsMapObject<Action>>({
    setSearch: actionsSearch.set,
    prompt: prompt as any as IOpenConfirmPrompt,
    notify: actionsNotification.notifySwagger,
  }, dispatch)
)(Edit);

import * as React from 'react';
import styled, { StyledComponentClass } from 'styled-components';
import { Link as RouteLink, NavLink } from 'react-router-dom';

const Link = ({
  primary,
  primaryRoute,
  children,
  ...linkProps
}) => {
  return <RouteLink primary={primaryRoute} {...linkProps}>{children}</RouteLink>;
};

interface ILinkProps extends React.HTMLProps<HTMLAnchorElement> {
  disabled?: boolean;
  primary?: boolean;
  primaryRoute?: string;
  children?: any;
  theme?: any;
  [prop: string]: any;
}

interface IDefaultLink extends StyledComponentClass<ILinkProps, React.StatelessComponent<ILinkProps>> {
  Regular?: StyledComponentClass<ILinkProps, React.StatelessComponent<ILinkProps>>;
  Button?:  StyledComponentClass<ILinkProps, React.StatelessComponent<ILinkProps>>;
  NavLink?: StyledComponentClass<ILinkProps, React.StatelessComponent<ILinkProps>>;
}

export const DefaultLink: IDefaultLink = styled(Link) `
  ${({ theme, ...props }) => {

    const {
      global,
      primary,
      primaryRGB,
      defaultRGB,
      fs_md,
      light,
      active,
    } = theme;

    // regular
    const fontColor = props.primary ?
      primary :
      theme.default;

    // hover
    const fontColorHover = props.primary ?
      primaryRGB :
      defaultRGB;

    // active
    const fontColorActive = props.primary ?
      theme.default :
      primary;

    return `

      ${global}

      font-size: ${fs_md};

      color: ${fontColor};
      text-decoration: none;

      cursor: pointer;

      transition: color .25s ease-in-out;

      &:hover:enabled {

        color: rgba(${fontColorHover}, .8);

      }
      
      &:hover:disabled {
        cursor: auto;
      }

      &:active:enabled{
        color: ${fontColorActive};
        outline-color: ${active}
      }

      &:focus{
        outline-color: ${active}
      }

      &:focus-within{
        outline-color: ${active}
      }
      
      &.active{
        outline-color: ${active}
        color: ${fontColorActive};
      }
    
      i{
        margin-left: 10px;
        svg{
          path{
            transition: all .25s ease-in-out;
            fill: ${light};
          }
        }
      }

      &:hover{
        svg{
          path{
            fill: ${theme.default};
          }
        }
      }

    `;
  }}
`as IDefaultLink;

DefaultLink.Regular = DefaultLink.withComponent('a');

DefaultLink.Button = styled(DefaultLink.withComponent('button'))<ILinkProps> `
    ${({ disabled, theme }) => {

    const {
      global,
    } = theme;

    return `
        ${global}
        background: none;
        border: 0;
        outline: none;
        padding: 0;
        display: inline-flex;
        font-weight: 500;
        align-items: center;
        ${disabled ? `color: ${theme.light};` : ''}
      `;
  }}
  `;

DefaultLink.NavLink = DefaultLink.withComponent(({
  primaryRoute,
  primary,
  children,
  ...linkProps
}) => {
  return <NavLink primary={primaryRoute} {...linkProps}>{children}</NavLink>;
});

export default DefaultLink;
import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import {
  ActorIcon,
} from '../assets/icons';

export const Placeholder = styled(ActorIcon) `
  ${({ theme, ...props }) => {
    let {
      lightest,
    } = theme;

    return `
      width: 70%;
      height: 70%;
      svg{
        width: 70%;
        height: 70%;
        path{
          stroke: ${lightest};
        }
      }
    `;
  }}
`;

const Avatar = styled((props) => {
  return <div className={props.className}>
    {
      props.image ?
        <img src={props.image} alt="logo"/> :
        <Placeholder />
    }
  </div>;
}) `
  ${({ theme, ...props }) => {
    let {
      global,
      lighter,
    } = theme;

    let size;

    switch (props.size) {
      case 'xs':
        size = '30px';
        break;
      case 'sm':
        size = '40px';
        break;
      case 'md':
        size = '46px';
        break;
      case 'lg':
        size = '60px';
        break;
      default:
        size = '46px';
        break;
    }

    return `
      ${global}
      width: ${size};
      height: ${size};
      background-color: ${lighter};  
      border-radius: 50%;
      overflow: hidden;
      transition: all .5s ease-in-out;    
      display: flex;
      align-items: center;
      justify-content: center;
      > img{
        transform: scale(1.2);
      }
    `;
  }}
`;

Avatar.propTypes = {
  className: PropTypes.string,
  size: PropTypes.string,
  image: PropTypes.string,
};

export default Avatar;
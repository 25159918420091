import * as React from 'react';
import moment from 'moment';

import { history } from '../../../store';

import {
  Button,
  Field,
  Select,
  TimePicker,
  DatePicker,
  Telephone,
  Toggle,
  FormGrid as Grid,
  Link,
  Number,
  List,
  Textarea,
} from '../../../components';

import withMenu from '../../HOC/WithMenu';

import VendorsListItem from './parts/VendorsListItem';

import * as stateApi from '../../../_api/state';
import { ILocationEditState } from './Edit';
import { FormikActions, FormikComputedProps, FormikHandlers, FormikState } from 'formik';
import {
  SearchOutputDto,
} from '../../../service-proxies';


interface IFormProps extends FormikState<ILocationEditState>, FormikActions<ILocationEditState>, FormikComputedProps<ILocationEditState>, FormikHandlers {
  isLoading: boolean;
  isNew: boolean;
}

interface IFormState {
  states: {
    items?: SearchOutputDto[];
    status: string;
  };
  physicianLicenseExpirationDateFocused?: boolean;
  physicianDeaExpirationDateFocused?: boolean;
}

class Form extends React.Component<IFormProps, IFormState> {
  constructor(props) {
    super(props);

    this.state = {
      physicianLicenseExpirationDateFocused: false,
      physicianDeaExpirationDateFocused: false,
      states: {
        items: [],
        status: '',
      },
    };

    this.loadState = this.loadState.bind(this);
    this.handleStateSelect = this.handleStateSelect.bind(this);

    this.handleChangeOperationStartTime = this.handleChangeOperationStartTime.bind(this);
    this.handleChangeOperationEndTime = this.handleChangeOperationEndTime.bind(this);
    this.handlePhoneNumberChange = this.handlePhoneNumberChange.bind(this);
    this.handleFaxNumberChange = this.handleFaxNumberChange.bind(this);

    this.handlePhysicianLicenseExpirationDateChange = this.handlePhysicianLicenseExpirationDateChange.bind(this);
    this.handlePhysicianLicenseExpirationDateFocus = this.handlePhysicianLicenseExpirationDateFocus.bind(this);
    this.handlePhysicianLicenseExpirationDateTouched = this.handlePhysicianLicenseExpirationDateTouched.bind(this);

    this.handlePhysicianDeaExpirationDateChange = this.handlePhysicianDeaExpirationDateChange.bind(this);
    this.handlePhysicianDeaExpirationDateFocus = this.handlePhysicianDeaExpirationDateFocus.bind(this);
    this.handlePhysicianDeaExpirationDateTouched = this.handlePhysicianDeaExpirationDateTouched.bind(this);

    this.handleTelephoneBlur = this.handleTelephoneBlur.bind(this);
    this.handleFaxBlur = this.handleFaxBlur.bind(this);

    this.handleCancel = this.handleCancel.bind(this);
    this.handleDiscard = this.handleDiscard.bind(this);

    this.handleVendorNameChange = this.handleVendorNameChange.bind(this);
    this.handleVendorNumberChange = this.handleVendorNumberChange.bind(this);
    this.handleVendorDelete = this.handleVendorDelete.bind(this);
    this.handleVendorAdd = this.handleVendorAdd.bind(this);

    this.handleListItemBlur = this.handleListItemBlur.bind(this);

  }

  componentDidMount() {
    this.loadState();
  }

  loadState() {
    this.setState({
      states: {
        status: 'loading',
      }
    });
    stateApi.getAll()
      .then(({ items }) => {
        this.setState({
          states: {
            items,
            status: 'loaded',
          }
        });
      })
      .catch(e => {
        this.setState({
          states: {
            status: 'error',
          }
        });
      });
  }

  handleStateSelect(data) {
    this.props.setFieldValue('stateId', data.id);
  }

  handleChangeOperationStartTime(time) {
    this.props.setFieldValue('operationStartTime', time.value);
  }

  handleChangeOperationEndTime(time) {
    this.props.setFieldValue('operationEndTime', time.value);
  }

  handlePhoneNumberChange(value) {
    this.props.setFieldValue('phoneNumber', value);
  }

  handleFaxNumberChange(value) {
    this.props.setFieldValue('faxNumber', value);
  }

  handlePhysicianLicenseExpirationDateChange(value) {
    const stringDate = moment(value, 'DD.MM.YYYY', true).isValid() ? moment(value, 'DD.MM.YYYY', true) : null;
    this.props.setFieldValue('physicianLicenseExpirationDate', stringDate);
  }

  handlePhysicianLicenseExpirationDateFocus(value) {
    this.setState({ physicianLicenseExpirationDateFocused: value.focused });
  }

  handlePhysicianLicenseExpirationDateTouched() {
    this.props.setFieldTouched('physicianLicenseExpirationDate', true);
  }

  handlePhysicianDeaExpirationDateChange(value) {
    const stringDate = moment(value, 'DD.MM.YYYY', true).isValid() ? moment(value, 'DD.MM.YYYY', true) : null;
    this.props.setFieldValue('physicianDeaExpirationDate', stringDate);
  }

  handlePhysicianDeaExpirationDateFocus(value) {
    this.setState({ physicianDeaExpirationDateFocused: value.focused });
  }

  handlePhysicianDeaExpirationDateTouched() {
    this.props.setFieldTouched('physicianDeaExpirationDate', true);
  }

  handleTelephoneBlur() {
    this.props.setFieldTouched('phoneNumber', true);
  }

  handleFaxBlur() {
    this.props.setFieldTouched('faxNumber', true);
  }

  handleCancel() {
    history.goBack();
  }

  handleDiscard() {
    history.goBack();
  }

  // vendors:
  handleVendorNameChange(option, index) {
    this.props.setFieldValue(`vendorAccountNumbers[${index}].vendor`, option);
  }

  handleVendorNumberChange(value, index) {
    this.props.setFieldValue(`vendorAccountNumbers[${index}].accountNumber`, value);
  }

  handleVendorDelete(index) {
    const newVendorsItems = [...this.props.values.vendorAccountNumbers];
    newVendorsItems.splice(index, 1);
    this.props.setFieldValue('vendorAccountNumbers', newVendorsItems);
  }

  handleVendorAdd() {
    const newVendorsItems = [...this.props.values.vendorAccountNumbers];
    newVendorsItems.push({
      vendor: null,
      accountNumber: '',
    });
    this.props.setFieldValue('vendorAccountNumbers', newVendorsItems);
  }

  handleListItemBlur(e) {
    this.props.setFieldTouched(e.target.name, true);
  }

  render() {
    const {
      values,
      errors,
      touched,
      handleChange,
      handleBlur,
      handleSubmit,
    } = this.props;

    return <form noValidate onSubmit={handleSubmit}>
      <Grid>
        <Grid.Body>

          <Grid.Row>
            <Grid.Cell>
              <Field
                required
                type="text"
                name="name"
                label="Name"
                placeholder="Name..."
                value={values.name}
                onChange={handleChange}
                onBlur={handleBlur}
                disabled={this.props.isLoading}
                error={
                  touched.name &&
                  errors.name
                }
              />
            </Grid.Cell>
            <Grid.Cell />
          </Grid.Row>

          <Grid.Row>
            <Grid.Cell>
              <Field
                required
                type="text"
                name="facilityOrLocationCode"
                label="Facility or Location Code"
                placeholder="Facility or Location Code..."
                value={values.facilityOrLocationCode}
                onChange={handleChange}
                onBlur={handleBlur}
                disabled={this.props.isLoading}
                error={
                  touched.facilityOrLocationCode &&
                  errors.facilityOrLocationCode
                }
              />
            </Grid.Cell>
            <Grid.Cell />
          </Grid.Row>

          <Grid.Row>
            <Grid.Cell>
              <Textarea
                required
                name="address1"
                label="Address 1"
                placeholder="Address..."
                value={values.address1}
                onChange={handleChange}
                onBlur={handleBlur}
                disabled={this.props.isLoading}
                error={
                  touched.address1 &&
                  errors.address1
                }
              />
            </Grid.Cell>
          </Grid.Row>

          <Grid.Row>
            <Grid.Cell>
              <Textarea
                name="address2"
                label="Address 2"
                placeholder="Address..."
                value={values.address2}
                onChange={handleChange}
                onBlur={handleBlur}
                disabled={this.props.isLoading}
                error={
                  touched.address2 &&
                  errors.address2
                }
              />
            </Grid.Cell>
          </Grid.Row>

          <Grid.Row>
            <Grid.Cell>
              <Field
                required
                type="text"
                name="city"
                label="City"
                placeholder="City..."
                value={values.city}
                onChange={handleChange}
                onBlur={handleBlur}
                disabled={this.props.isLoading}
                error={
                  touched.city &&
                  errors.city
                }
              />
            </Grid.Cell>
            <Grid.Cell>
              <Select
                required
                name="state"
                label="State"
                placeholder="State..."
                labelKey="displayValue"
                valueKey="id"
                value={values.stateId}
                options={this.state.states.items}
                isLoading={this.state.states.status === 'loading'}
                onChange={this.handleStateSelect}
                disabled={this.props.isLoading}
                error={
                  touched.stateId &&
                  errors.stateId
                }
              />
            </Grid.Cell>
          </Grid.Row>

          <Grid.Row>
            <Grid.Cell>
              <Field
                required
                type="text"
                name="zipCode"
                label="Zip Code"
                placeholder="Zip Code..."
                value={values.zipCode}
                onChange={handleChange}
                onBlur={handleBlur}
                disabled={this.props.isLoading}
                error={
                  touched.zipCode &&
                  errors.zipCode
                }
              />
            </Grid.Cell>
            <Grid.Cell />
          </Grid.Row>

          <Grid.Row>
            <Grid.Cell>
              <Telephone
                required
                reduce
                name="phoneNumber"
                id="phoneNumber"
                label="Phone Number"
                placeholder="Phone Number..."
                value={values.phoneNumber}
                onChange={this.handlePhoneNumberChange}
                onBlur={this.handleTelephoneBlur}
                disabled={this.props.isLoading}
                error={
                  touched.phoneNumber &&
                  errors.phoneNumber
                }
              />
            </Grid.Cell>
            <Grid.Cell />
          </Grid.Row>

          <Grid.Row>
            <Grid.Cell>
              <Telephone
                required
                reduce
                name="faxNumber"
                id="faxNumber"
                label="Fax"
                placeholder="Fax..."
                value={values.faxNumber}
                onChange={this.handleFaxNumberChange}
                onBlur={this.handleFaxBlur}
                disabled={this.props.isLoading}
                error={
                  touched.faxNumber &&
                  errors.faxNumber
                }
              />
            </Grid.Cell>
            <Grid.Cell />
          </Grid.Row>

          <Grid.Row>
            <Grid.Cell>
              <Toggle
                name="isInHospital"
                id="isInHospital"
                label="In hospital: "
                value={values.isInHospital}
                disabled={this.props.isLoading}
                onChange={handleChange}
              />
            </Grid.Cell>
            <Grid.Cell />
          </Grid.Row>

          <Grid.Row>
            <Grid.Cell>
              <Grid.Row>
                <Grid.Cell>
                  <TimePicker
                    required
                    name="operationStartTime"
                    id="operationStartTime"
                    label="Hours of Operation"
                    placeholder="Hours..."
                    suffix="to"
                    value={values.operationStartTime}
                    onChange={this.handleChangeOperationStartTime}
                    onBlur={handleBlur}
                    disabled={this.props.isLoading}
                    error={
                      touched.operationStartTime &&
                      errors.operationStartTime
                    }
                  />
                </Grid.Cell>
                <Grid.Cell>
                  <TimePicker
                    name="operationEndTime"
                    id="operationEndTime"
                    placeholder="Hours..."
                    value={values.operationEndTime}
                    onChange={this.handleChangeOperationEndTime}
                    onBlur={handleBlur}
                    disabled={this.props.isLoading}
                    error={
                      touched.operationEndTime &&
                      errors.operationEndTime
                    }
                  />
                </Grid.Cell>
              </Grid.Row>
            </Grid.Cell>
          </Grid.Row>

          <Grid.Row>
            <Grid.Cell size="25%">
              <Number
                required
                type="text"
                name="taxRate"
                label="Tax Rate"
                placeholder="Tax Rate..."
                value={values.taxRate}
                positiveOnly
                suffix="%"
                onChange={value => this.props.setFieldValue('taxRate', value)}
                onBlur={handleBlur}
                disabled={this.props.isLoading}
                error={
                  touched.taxRate &&
                  errors.taxRate
                }
              />
            </Grid.Cell>
            <Grid.Cell />
          </Grid.Row>

          <Grid.Row>
            <Grid.Cell>
              <Field
                required
                type="text"
                name="physicianName"
                label="Physician Name"
                placeholder="Physician Name..."
                value={values.physicianName}
                onChange={handleChange}
                onBlur={handleBlur}
                disabled={this.props.isLoading}
                error={
                  touched.physicianName &&
                  errors.physicianName
                }
              />
            </Grid.Cell>
            <Grid.Cell />
          </Grid.Row>

          <Grid.Row>
            <Grid.Cell>
              <Field
                required
                type="text"
                name="physicianLicenseNumber"
                label="Physician License Number"
                placeholder="Physician License Number..."
                value={values.physicianLicenseNumber}
                onChange={handleChange}
                onBlur={handleBlur}
                disabled={this.props.isLoading}
                error={
                  touched.physicianLicenseNumber &&
                  errors.physicianLicenseNumber
                }
              />
            </Grid.Cell>
            <Grid.Cell />
          </Grid.Row>

          <Grid.Row>
            <Grid.Cell>
              <DatePicker
                required
                id="physicianLicenseExpirationDate"
                label="Physician License Expiration Date"

                date={values.physicianLicenseExpirationDate && moment(values.physicianLicenseExpirationDate)}
                focused={this.state.physicianLicenseExpirationDateFocused}
                onFocusChange={this.handlePhysicianLicenseExpirationDateFocus}
                onDateChange={this.handlePhysicianLicenseExpirationDateChange}
                onClose={this.handlePhysicianLicenseExpirationDateTouched}

                disabled={this.props.isLoading}
                error={
                  touched.physicianLicenseExpirationDate &&
                  errors.physicianLicenseExpirationDate as any as string
                }
              />
            </Grid.Cell>
            <Grid.Cell />
          </Grid.Row>

          <Grid.Row>
            <Grid.Cell>
              <Field
                required
                type="text"
                name="physicianDeaNumber"
                label="Physician DEA Number"
                placeholder="Physician DEA Number..."
                value={values.physicianDeaNumber}
                onChange={handleChange}
                onBlur={handleBlur}
                disabled={this.props.isLoading}
                error={
                  touched.physicianDeaNumber &&
                  errors.physicianDeaNumber
                }
              />
            </Grid.Cell>
            <Grid.Cell />
          </Grid.Row>

          <Grid.Row>
            <Grid.Cell>
              <DatePicker
                required
                id="physicianDeaExpirationDate"
                label="Physician DEA Expiration Date"

                date={values.physicianDeaExpirationDate && moment(values.physicianDeaExpirationDate)}
                onDateChange={this.handlePhysicianDeaExpirationDateChange}
                focused={this.state.physicianDeaExpirationDateFocused}
                onFocusChange={this.handlePhysicianDeaExpirationDateFocus}
                onClose={this.handlePhysicianDeaExpirationDateTouched}

                disabled={this.props.isLoading}
                error={
                  touched.physicianDeaExpirationDate &&
                  errors.physicianDeaExpirationDate as any as string
                }
              />
            </Grid.Cell>
            <Grid.Cell />
          </Grid.Row>

          <Grid.Row>
            <Grid.Cell>
              <Field
                type="text"
                name="physicianNpiNumber"
                label="NPI Number"
                placeholder="NPI Number..."
                value={values.physicianNpiNumber}
                onChange={handleChange}
                onBlur={handleBlur}
                disabled={this.props.isLoading}
                error={
                  touched.physicianNpiNumber &&
                  errors.physicianNpiNumber
                }
              />
            </Grid.Cell>
            <Grid.Cell />
          </Grid.Row>

          <Grid.Row>
            <Grid.Cell>
              <List
                increasable
                deletable
                label="Vendor Account Numbers"
                value={values.vendorAccountNumbers}
                renderComponent={(value, index) =>
                  <VendorsListItem
                    value={value}
                    index={index}
                    onNameChange={this.handleVendorNameChange}
                    onNumberChange={this.handleVendorNumberChange}
                    error={errors.vendorAccountNumbers && errors.vendorAccountNumbers[index]}
                    onBlur={this.handleListItemBlur}
                  />
                }
                onItemDelete={this.handleVendorDelete}
                onAddItem={this.handleVendorAdd}
                disabled={this.props.isLoading}
              />
            </Grid.Cell>
          </Grid.Row>

        </Grid.Body>
        <Grid.Footer>
          <Grid.FooterElement />
          <Grid.FooterElement>
            {
              this.props.isNew ?
                <Link.Button
                  primary
                  onClick={this.handleCancel}
                  scale="lg"
                >
                  Cancel
                </Link.Button> :
                <Link.Button
                  primary
                  onClick={this.handleDiscard}
                  scale="lg"
                >
                  Discard
                </Link.Button>
            }
            <Button
              type="submit"
              disabled={this.props.isLoading}
              scale="lg"
              preloader={this.props.isLoading}
            >
              {this.props.isNew ? 'Submit' : 'Save'}
            </Button>
          </Grid.FooterElement>
        </Grid.Footer>
      </Grid>
    </form>;
  }
}

export default withMenu(Form, false);

import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const Tab = (props) => {
  const {onClick, tabIndex, label, className} = props;

  return (
    <li
      className={className}
      onClick={(event) => {
        event.preventDefault();
        onClick(tabIndex);
      }}
    >
      {label}
    </li>
  );
};

Tab.propTypes = {
  onClick: PropTypes.func,
  tabIndex: PropTypes.number,
  label: PropTypes.string.isRequired,
  className: PropTypes.string,
};

export default styled(Tab)`
  ${ props => {

  let {
    global,
    lightest,
    light,
  } = props.theme;

  return `
      ${global}

      ${props.isActive
    ?
    `
            border-bottom: .5px solid #FFF;
            transition: all .25s ease-in-out;
          `
    :
    `
            background-color: ${lightest};
            color: ${light};
            border-bottom: .5px solid ${light};

          `
    }

      text-transform: uppercase;
      font-weight: ${props.fontWeight ? props.fontWeight : ''};
      padding: 10px;
      cursor: pointer;
    `;
}}
`;

import * as  React from 'react';
import styled, {StyledComponentClass} from 'styled-components';
import {bindActionCreators} from 'redux';
import { connect } from 'react-redux';
import { SkeletonGroup } from '../../../components';

import {
  Button,
  Field,
  Link,
  PermissionsTree
} from '../../../components';
import { setChildrenNodes } from '../../../_utils/permissions';

import * as actions from '../../../_actions/roles';
import {
  CreateRoleDto,
  EntityDtoOfInt64,
  PermissionNodeDto,
  RoleDto,
  UpdateRoleDto
} from '../../../service-proxies';

type StyledComponentType = StyledComponentClass<{}, React.StatelessComponent<{}>>;

interface IPosition extends StyledComponentType {
  Body?: StyledComponentType;
  Field?: StyledComponentType;
  Footer?: StyledComponentType;
  Wrapper?: StyledComponentType;
  PermissionsTree?: StyledComponentType;
  CancelButton?: StyledComponentType;
  ButtonsWrap?: StyledComponentType;
}

const Position: IPosition = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column; 
`;

Position.Body = styled.div`
  width: 100%; 
  height: 100%; 
`;

Position.Field = styled.div`
  padding: 10px 20px;
  margin-bottom: 8px;
  border-bottom: 1px solid ${({ theme }) => theme.light};
`;

Position.Footer = styled.div`
  text-align: right;
  padding: 20px;
`;

Position.Wrapper = styled.div`
  display: block;
  height: 440px;
`;

Position.PermissionsTree = styled.div`
  height: 300px;
  overflow-y: scroll;
`;

Position.CancelButton = styled.span`
  margin-right: 18px;
`;

Position.ButtonsWrap = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: flex-end;
`;

const TreeLabel = styled.div`
  font-size: 14px;
  margin-left: 20px;
  padding-bottom: 4px;
`;


interface IEditRoleModalProps extends IConnectedActions {
  id?: number | string;
  resolve: (res?: any) => void;
  reject: (res?: any) => void;
}

interface IEditRoleModalState {
  name: string;
  nameError: string;
  permissionsTree: PermissionNodeDto[];
  permissionsCollapsedMap: {[name: string]: true};
  permissionsMap?: {[name: string]: true};
  isLoaded: boolean;
  isSaving?: boolean;
}


class EditRoleModal extends React.Component<IEditRoleModalProps, IEditRoleModalState> {

  constructor(props) {
    super(props);

    this.state = {
      name: '',
      nameError: '',
      permissionsTree: [],
      permissionsCollapsedMap: {},
      isLoaded: false,
    };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleOnChange = this.handleOnChange.bind(this);
    this.handlePermissionChanged = this.handlePermissionChanged.bind(this);
    this.handleCollapseNode = this.handleCollapseNode.bind(this);
    this.handleCancelClick = this.handleCancelClick.bind(this);
  }

  componentDidMount() {

    const promises: [Promise<PermissionNodeDto[]>,Promise<RoleDto>] = [
      this.props.getAllPermissions(),
      this.props.id !== 'new' ?
        this.props.getRole(this.props.id as number)
        :
        Promise.resolve({ id: 'new' as any as number, name: '', permissions: [], creationTime: null }),
    ];

    Promise.all(promises)
      .then(([permissionsTree, role]) => {
        this.setState({
          name: role.name,
          permissionsMap: role.permissions.reduce((ac, ce) => { ac[ce] = true; return ac; }, {}),
          permissionsTree,
          isLoaded: true,
        });
      });
  }

  handleOnChange({ target: { value } }) {
    this.setState({
      name: value,
      nameError: (!value || !value.trim()) ? 'Required field' : '',
    });
  }

  handleSubmit() {
    if (!this.validateForm()) { return; }

    this.setState({ isSaving: true });

    const data: CreateRoleDto | UpdateRoleDto = {
      name: this.state.name.trim(),
      permissions: Object.keys(this.state.permissionsMap),
    };
    let apiMethod: CreateRoleDtoType | UpdateRoleDtoType = this.props.create;
    if (this.props.id !== 'new') {
      (data as UpdateRoleDto).id = this.props.id as number;
      apiMethod = this.props.update;
    }
    // @ts-expect-error change
    apiMethod(data).then((res) => {
      this.setState({ isSaving: false });
      this.props.resolve(res);
    }).catch(err => {
      const newState = { isSaving: false };
      if (err.validationErrors) {
        const fields = { name: 1 };
        err.validationErrors.forEach(validError => {
          if (validError.members) {
            validError.members.forEach(fieldName => {
              if (fields[fieldName]) {
                newState[`${fieldName}Error`] = validError.message || 'Server validation error';
              }
            });
          }
        });
      }
      this.setState(newState);
    });
  }

  validateForm() {
    const newState: Partial<IEditRoleModalState> = {};
    let status = true;
    if (!this.state.name || !this.state.name.trim()) {
      newState.nameError = 'Required field';
      status = false;
    }
    if (!status) {
      this.setState(newState as IEditRoleModalState);
    }
    return status;
  }

  handlePermissionChanged(permission, value, path) {
    const permissionsMap = { ...this.state.permissionsMap };

    if (!value) {
      delete permissionsMap[permission];
    }

    let currentLevel = this.state.permissionsTree;
    path.forEach(index => {
      if (value) { permissionsMap[currentLevel[index].name] = true; }
      currentLevel = currentLevel[index].children;
    });
    if (currentLevel && currentLevel.length) {
      setChildrenNodes(currentLevel, permissionsMap, value);
    }

    this.setState({ permissionsMap });
  }

  handleCollapseNode(permission, value) {
    const permissionsCollapsedMap = { ...this.state.permissionsCollapsedMap };
    if (value) {
      permissionsCollapsedMap[permission] = true;
    } else {
      delete permissionsCollapsedMap[permission];
    }
    this.setState({ permissionsCollapsedMap });
  }

  handleCancelClick() {
    this.props.reject();
  }

  render() {
    const { permissionsMap, permissionsCollapsedMap, isLoaded } = this.state;
    return (
      <React.Fragment>
        <Position.Wrapper>
          {isLoaded ?
            <React.Fragment>
              <Position.Field>
                <Field
                  required
                  type="text"
                  name="name"
                  label="Role name"
                  placeholder=""
                  value={this.state.name}
                  error={this.state.nameError}
                  onChange={this.handleOnChange}
                />
              </Position.Field>
              <TreeLabel>Permissions:</TreeLabel>
              <Position.PermissionsTree>
                <PermissionsTree
                  valuesMap={permissionsMap}
                  collapsedMap={permissionsCollapsedMap}
                  permissionsTree={this.state.permissionsTree}
                  onChanged={this.handlePermissionChanged}
                  onCollapseNode={this.handleCollapseNode}
                />
              </Position.PermissionsTree>
            </React.Fragment>
            :
            <SkeletonGroup rows={10} size="md" />
          }
        </Position.Wrapper>
        <Position.Footer>
          <Position.ButtonsWrap>
            <Position.CancelButton>
              <Link.Button
                primary
                onClick={this.handleCancelClick}
                scale="lg"
              >
                Cancel
              </Link.Button>
            </Position.CancelButton>
            <Button onClick={this.handleSubmit} preloader={this.state.isSaving} disabled={!this.state.isLoaded}>Submit</Button>
          </Position.ButtonsWrap>
        </Position.Footer>
      </React.Fragment>
    );
  }
}

type GetRoleDtoType = (id: number) => Promise<RoleDto>;
type GetAllPermissionType = () => Promise<PermissionNodeDto[]>;
type CreateRoleDtoType = (input: CreateRoleDto) => Promise<EntityDtoOfInt64>;
type UpdateRoleDtoType = (input: UpdateRoleDto) => Promise<EntityDtoOfInt64>;

interface IConnectedActions {
  getRole: GetRoleDtoType;
  create: CreateRoleDtoType;
  update: UpdateRoleDtoType;
  getAllPermissions: GetAllPermissionType;
}

export default connect(
  null,
  dispatch => bindActionCreators({
    getRole: actions.getRole as any as GetRoleDtoType,
    create: actions.create as any as CreateRoleDtoType,
    update: actions.update as any as  UpdateRoleDtoType,
    getAllPermissions: actions.getAllPermissions as any as GetAllPermissionType,
  }, dispatch)
)(EditRoleModal);

import * as React from 'react';
import EditableBlock from './EditableBlock';
import {formatPrice, normalizePrice} from '../_utils/prices';

const inputFilter = (v: string) => v.replace(/[^\d.]/gm, '');

const outputFilter = (v: string) => formatPrice(v).slice(1);

interface IPriceField {
  id: number;
  onChange: (id: number, newPrice: number) => void;
  value: number;
}

class PriceField extends React.Component<IPriceField> {
  constructor(props) {
    super(props);
    this.handlePriceChange = this.handlePriceChange.bind(this);
  }

  handlePriceChange(newPrice) {
    if (this.props.onChange) {
      const parsedPrice = parseFloat(newPrice);
      this.props.onChange(this.props.id, isNaN(parsedPrice) ? 0.0 : normalizePrice(parsedPrice));
    }
  }

  public render () {
    return <EditableBlock
      onChange={this.handlePriceChange}
      exitEditOnBlur
      editOnClick
      inputFilter={inputFilter}
      outputFilter={outputFilter}
      alignment="right"
      prefix={'$'}
      id={this.props.id}
      value={this.props.value.toFixed(2)}
    />;
  }
}

export default PriceField;

import React from 'react';
import styled from 'styled-components';

const LetterIcon = props => {
  return(
    <i {...props}>
      <svg viewBox="443 316 26 18" width="20" height="20">
        <path d=" M 443 316 L 443 334 L 469 334 L 469 316 L 443 316 Z  M 447.313 318 L 464.688 318 L 456 323.781 L 447.313 318 Z  M 445 318.875 L 455.438 325.844 L 456 326.187 L 456.563 325.844 L 467 318.875 L 467 332 L 445 332 L 445 318.875 Z " />
      </svg>
    </i>
  );
};

export default styled(LetterIcon)`
  ${ props => `
    width: 21px;
    height: 21px;
    display: inline-flex;
    justify-content: center;

    svg{
      width: 100%;
      height: 100%;
      path{
        fill: ${props.theme.default}; 
      }
    }
  `}
`;

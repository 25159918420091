import React from 'react';
import styled from 'styled-components';

export default styled(props => {
  return (
    <i {...props}>
      <svg viewBox="-130 -101 20 20" width="20" height="20">
        <path d=" M -116.175 -93.842 L -120 -90.025 L -123.825 -93.842 L -125 -92.667 L -120 -87.667 L -115 -92.667 L -116.175 -93.842 Z " />
      </svg>
    </i>
  );
})`
  ${ props => `
    width: 21px;
    height: 21px;
    display: inline-flex;
    justify-content: center;
    align-items: center;

    svg{
      path{
        fill: ${props.theme.primary}; 
      }
    }
  `}
`;

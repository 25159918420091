import * as React from "react";
import { apiUrl } from "../../../_constants/system";
import styled from "styled-components";

import withGranted, { IWithGrantedProps } from "../../HOC/WithGranted";

import {
  Table,
  Thumbnail,
  Link,
  Counter,
  Article,
  Skeleton
} from "../../../components";

import { TrashIcon, RestartIcon } from "../../../assets/icons";

import { ORDERS_BUYERORDERS_EDIT } from "../../../_constants/permissions";
import { FormikActions, FormikState } from "formik";
import { IViewState } from "./View";
import { formatPrice, roundAndFormatPrice } from "../../../_utils/prices";

interface IDeleteButtonProps {
  id?: number;
  onClick: (id: number) => void;
}

const DeleteButton = function(props: IDeleteButtonProps) {
  function handleClick() {
    props.onClick(props.id);
  }

  return (
    <Link.Button onClick={handleClick}>
      <TrashIcon />
    </Link.Button>
  );
};

interface IRestoreButtonProps {
  id?: number;
  onClick: (id: number) => void;
}

const RestoreButton = function(props: IRestoreButtonProps) {
  function handleClick() {
    props.onClick(props.id);
  }

  return (
    <Link.Button onClick={handleClick}>
      <RestartIcon />
    </Link.Button>
  );
};

interface IItemCounterProps {
  id: number;
  value: number;
  onChange: (id: number, value: number) => void;
  error: string;
}

const ItemCounter = function(props: IItemCounterProps) {
  function handleChange(value) {
    props.onChange(props.id, value);
  }

  return (
    <Counter
      value={props.value}
      onChange={handleChange}
      error={props.error}
      compact
    />
  );
};

const CenteredContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
`;

const RightContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  height: 100%;
  width: 100%;
`;

const Position = class {
  static CenteredContainer = CenteredContainer;
  static RightContainer = RightContainer;
};

interface IViewAllProps
  extends IWithGrantedProps,
    FormikState<IViewState>,
    FormikActions<IViewState> {
  onChange: () => void;
}

interface IViewAll {
  handleCountChange: (id: number, value: number) => void;
  handleDelete: (id: number) => void;
}

class ViewAll extends React.Component<IViewAllProps, any> implements IViewAll {
  constructor(props) {
    super(props);
    this.handleDelete = this.handleDelete.bind(this);
    this.handleRestore = this.handleRestore.bind(this);
    this.handleCountChange = this.handleCountChange.bind(this);
  }

  handleCountChange(id: number, value: number) {
    const order = this.props.values.order;
    const orderItemIndex = order.items.findIndex(
      item => item.product.id === id
    );
    order.items[orderItemIndex].quantity = value > 0 ? value : 1;

    this.props.setFieldValue("order", order);
    this.props.onChange();
  }

  handleChangePoNumber(clientPoNumber: string){
    const order = this.props.values.order;
    order.clientPoNumber = clientPoNumber;
    this.props.setFieldValue("order", order);
    this.props.onChange();
  }

  handleDelete(id: number) {
    const order = this.props.values.order;
    const orderItemIndex = order.items.findIndex(
      item => item.product.id === id
    );
    order.items[orderItemIndex].isActive = false;

    this.props.setFieldValue("order", order);
    this.props.onChange();
  }

  handleRestore(id: number) {
    const order = this.props.values.order;
    const orderItemIndex = order.items.findIndex(
      item => item.product.id === id
    );
    order.items[orderItemIndex].isActive = true;

    this.props.setFieldValue("order", order);
    this.props.onChange();
  }

  renderPreloaderRows(perPage, status) {
    const rows = [];
    const elementsCount = status === "loading" ? perPage - 1 : 0;

    for (let i = 0; i < elementsCount; i++) {
      rows.push(
        <Table.Row
          key={"preloader" + i}
          rows={perPage}
          cells={[
            <Skeleton.Square key="thumb-preloader" />,
            <React.Fragment key="item-preloader">
              <p>
                <Skeleton.Line />
              </p>
              <p>
                <Skeleton.Line />
              </p>
              <p>
                <Skeleton.Line />
              </p>
            </React.Fragment>,
            <Position.CenteredContainer key="price-preloader">
              <Skeleton.Line size="xs" />{" "}
            </Position.CenteredContainer>,
            <Position.CenteredContainer key="count-preloader">
              <Skeleton.Line size="sm" />{" "}
            </Position.CenteredContainer>,
            <Position.CenteredContainer key="sub-price-preloader">
              <Skeleton.Line size="xs" />{" "}
            </Position.CenteredContainer>,
            <Position.CenteredContainer key="action-preloader">
              <Skeleton.Line size="xs" />{" "}
            </Position.CenteredContainer>
          ]}
        />
      );
    }
    return rows;
  }

  render() {
    const { values, errors } = this.props;
    return (
      <Table
        columns={[
          { name: "thumb", label: "", size: "xs" },
          { name: "item", label: "item", size: "xl" },
          { name: "space", label: "", alignment: "right" },
          { name: "space2", label: "", alignment: "right" },
          { name: "price", label: "price", alignment: "right" },
          {
            name: "quantity",
            label: "quantity",
            size: "md",
            alignment: "center"
          },
          { name: "subtotal", label: "subtotal", alignment: "right" },
          { name: "toolbar", label: "", size: "xs" }
        ]}
      >
        <Table.Header />
        <Table.Body>
          {(values.orderStatus === "loaded" ||
            values.orderStatus === "saving" ||
            values.orderStatus === "saved") &&
            values.order.items.map((orderItem, index) => {
              const totalPrice = orderItem.price * orderItem.quantity;

              // const isCanBeManipulated = orderItem

              return (
                <Table.Row
                  disabled={!orderItem.isActive}
                  key={`order-product-${index}`}
                  cells={[
                    <Thumbnail
                      key={`thumbnail-${index}`}
                      image={
                        orderItem.product.picture
                          ? apiUrl + orderItem.product.picture.pictureUrl
                          : null
                      }
                    />,
                    <React.Fragment key={`item-${index}`}>
                      <p>
                        <span style={{ color: "#000" }}>
                          {orderItem.isActive ? (
                            <Link
                              key={`item-${orderItem.product.id}`}
                              to={`/products/product/${orderItem.product.id}`}
                              primary
                            >
                              {orderItem.product.name}
                            </Link>
                          ) : (
                            orderItem.product.name
                          )}
                        </span>
                      </p>
                      <p>
                        <span style={{ color: "#000" }}>
                          {orderItem.product.sku}
                        </span>
                      </p>
                    </React.Fragment>,
                    <Position.RightContainer key={`empty-${index}`}>
                      <p> </p>
                    </Position.RightContainer>,
                    <Position.RightContainer key={`empty2-${index}`}>
                      <p> </p>
                    </Position.RightContainer>,
                    <Position.RightContainer key={`price-${index}`}>
                      {orderItem.isActive ? (
                        formatPrice(orderItem.price)
                      ) : (
                        <p>
                          <span>{formatPrice(orderItem.price)}</span>
                        </p>
                      )}
                    </Position.RightContainer>,
                    <Position.CenteredContainer key={`count-${index}`}>
                      {orderItem.isActive &&
                      this.props.isGranted(ORDERS_BUYERORDERS_EDIT) ? (
                        <ItemCounter
                          id={orderItem.product.id}
                          value={orderItem.quantity}
                          onChange={this.handleCountChange}
                          error={
                            errors.order &&
                            errors.order.items &&
                            errors.order.items[index] &&
                            errors.order.items[index].quantity
                          }
                        />
                      ) : (
                        <p>
                          <span>{orderItem.quantity}</span>
                        </p>
                      )}
                    </Position.CenteredContainer>,
                    <Position.RightContainer key={`total-${index}`}>
                      {orderItem.isActive ? (
                        roundAndFormatPrice(totalPrice)
                      ) : (
                        <p>
                          <span>{roundAndFormatPrice(totalPrice)}</span>
                        </p>
                      )}
                    </Position.RightContainer>,
                    <Position.CenteredContainer key={`toolbar-${index}`}>
                      {this.props.isGranted(ORDERS_BUYERORDERS_EDIT) &&
                        (values.order.shouldBeApproved ||
                          values.order.shouldBeSubmitted) &&
                        (orderItem.isActive ? (
                          <DeleteButton
                            id={orderItem.product.id}
                            onClick={this.handleDelete}
                          />
                        ) : (
                          <RestoreButton
                            id={orderItem.product.id}
                            onClick={this.handleRestore}
                          />
                        ))}
                    </Position.CenteredContainer>
                  ]}
                />
              );
            })}
          {this.renderPreloaderRows(5, values.orderStatus)}
          {(values.orderStatus === "loaded" ||
            values.orderStatus === "saving" ||
            values.orderStatus === "saved") && (
            <Table.Row
              cells={[
                "",
                // "",
                // "",
                <Position.RightContainer key="po-number-label">
                  <Article.P light scale="lg">
                    Client PO#:
                  </Article.P>
                </Position.RightContainer>,
                <Article.P scale="lg" key="po-number">
                  {values.order.shouldBeSubmitted ? (
                      <input key="po-key" value={values.order.clientPoNumber} onChange={e => this.handleChangePoNumber(e.target.value)}/>
                  ): (
                      <span key="po-key">{values.order.clientPoNumber}</span>
                  )}
                </Article.P>,
                "",
                <Position.RightContainer key="total-count-label">
                  <Article.P light scale="lg">
                    TOTAL:
                  </Article.P>
                </Position.RightContainer>,
                <Article.P scale="lg" key="total-count">
                  {roundAndFormatPrice(
                    values.order.items.reduce((result, orderItem) => {
                      const itemPrice = orderItem.isActive
                        ? orderItem.price * orderItem.quantity
                        : 0;
                      return result + itemPrice;
                    }, 0)
                  )}
                </Article.P>,
                // "",
              ]}
            />
          )}
        </Table.Body>
      </Table>
    );
  }
}

export default withGranted(ViewAll);

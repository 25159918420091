import * as React from 'react';
import { Drawer } from '../../components';
import withGranted, { IWithGrantedProps } from '../HOC/WithGranted';

import {
  HomeIcon,
  // LetterIcon,
  GearIcon,
  OrderIcon,
  ReturnRequestIcon,
  InvoiceIcon,
  ReconciliationIcon,
} from '../../assets/icons';

import {
  ADMINISTRATION,
  ORDERS_BUYERORDERS,
  MASTERCATALOGUE,
  CLIENTCATALOGUE,
  ORDERS_UNCOMPARED,
  ORDERS_MYORDERS,
  INVOICES,
  PACKINGSLIPS,
  CLIENTINVOICES,
  COMPANYPACKINGSLIPS,
} from '../../_constants/permissions';

interface IMenuItem {
  route: string;
  name: string;
  icon: JSX.Element;
  contain?: string;
  permission?: string;
  someOfPermissions?: string[];
}

const mainRoutes: IMenuItem[] = [
  {
    route: '/',
    name: 'Home',
    icon: <HomeIcon />,
    someOfPermissions: [MASTERCATALOGUE, CLIENTCATALOGUE],
  },
  /* {
     route: '/messages',
     name: 'Messages',
     icon: <LetterIcon />,
     contain: '6',
   },*/
  {
    route: '/orders',
    name: 'Orders',
    icon: <OrderIcon />,
    someOfPermissions: [ORDERS_MYORDERS, ORDERS_BUYERORDERS],
  },
  {
    route: '/clients-orders',
    name: 'Client orders',
    icon: <ReturnRequestIcon />,
    permission: ORDERS_UNCOMPARED,
  },
  {
    route: '/invoices',
    name: 'Invoices',
    icon: <InvoiceIcon />,
    permission: INVOICES,
  },
  {
    route: '/client-invoices',
    name: 'Client Invoices',
    icon: <InvoiceIcon />,
    permission: CLIENTINVOICES,
  },
  {
    route: '/packing-slip-reconciliation',
    name: 'Packing Slip Reconciliation',
    icon: <ReconciliationIcon />,
    someOfPermissions: [PACKINGSLIPS, COMPANYPACKINGSLIPS],
  },
  {
    route: '/admin',
    name: 'Admin',
    icon: <GearIcon />,
    permission: ADMINISTRATION,
  },
];

class DrawerMenu extends React.Component<{isDrawerOpen: boolean} & IWithGrantedProps> {

  allowedMenuItems: IMenuItem[];

  constructor(props) {
    super(props);
    this.checkIsMainRoute = this.checkIsMainRoute.bind(this);

    this.allowedMenuItems = mainRoutes.filter(
      route => (!route.permission && !route.someOfPermissions)
        || (route.permission && props.isGranted(route.permission))
        || (route.someOfPermissions && props.isSomeGranted(route.someOfPermissions))
    );
  }

  checkIsMainRoute() {
    const isInRoutes = mainRoutes.some(item => {
      if (item.route === '/') {
        return false;
      }
      return location.pathname.indexOf(item.route) === 0;
    });
    return !isInRoutes;
  }

  render() {
    return (
      <Drawer.Menu>
        {
          this.allowedMenuItems.map((item, index) => {
            if (!index) {
              return <Drawer.MenuItem
                key={'drawer-menu-' + index}
                containe={item.contain}
                to={item.route}
                renderIcon={item.icon}
                isActive={this.checkIsMainRoute}
                tooltipLabel={item.name}
                isDrawerOpen={this.props.isDrawerOpen}
              >
                {item.name}
              </Drawer.MenuItem>;
            } else {
              return <Drawer.MenuItem
                key={'drawer-menu-' + index}
                containe={item.contain}
                to={item.route}
                renderIcon={item.icon}
                tooltipLabel={item.name}
                isDrawerOpen={this.props.isDrawerOpen}
              >
                {item.name}
              </Drawer.MenuItem>;
            }

          })
        }
      </Drawer.Menu>
    );
  }
}

export default withGranted(DrawerMenu);

import React from 'react';
import PropTypes from 'prop-types';
import range from 'lodash/range';
import styled from 'styled-components';
import { Line as Skeleton } from './Skeleton';

const SkeletonWrapper = styled.div`
  padding: 12px 20px;
`;

const SkeletonGroup = ({rows, size}) => {
  const lines = range(1, rows + 1);
  return (
    <div>
      {
        lines.map((i) => (
          <SkeletonWrapper key={i}>
            <Skeleton size={size} />
          </SkeletonWrapper>)
        )
      }
    </div>
  );
};

SkeletonGroup.propTypes =  {
  rows: PropTypes.number.isRequired,
  size: PropTypes.string,
};

export default SkeletonGroup;
import React from "react";
import * as Yup from "yup";
import { Formik } from "formik";
import { AddressOverrideListDto } from "../../../../service-proxies";

import AddressOverrideForm from "./Form";
import * as locationApi from "../../../../_api/location";
import { IModalValues } from "../types";

const REQUIRED = "Required field";

const validationSchema = Yup.object().shape({
  address1: Yup.string()
    .max(55, "must contain less than 55 characters")
    .required(),
  address2: Yup.string()
    .nullable()
    .max(55, "must contain less than 55 characters"),
  city: Yup.string()
    .matches(/^.{1,30}$/, { message: "must contain less than 30 characters" })
    .required(REQUIRED),
  stateId: Yup.number().required(REQUIRED),
  name: Yup.string().required(REQUIRED),
  zipCode: Yup.string()
    .matches(/^\d{5}(-\d{4})?$/, "Please enter a valid Zip Code.")
    .required(REQUIRED),
  phoneNumber: Yup.string()
    .matches(/.{10}/, "must contain 11 decimals")
    .required(REQUIRED)
});

interface IAddressOverrideModalProps {
  userId: number;
  addressOverride: AddressOverrideListDto;
  onSuccess: (input: AddressOverrideListDto) => void;
  onDelete: (id: number) => void;
  resolveModal: () => void;
  closeModal: () => void;
}

interface IAddressOverrideModalState {
  isNew: boolean;
  values: IModalValues;
  isLoaded: boolean;
  isSaving: boolean;
}

export class AddressOverrideModal extends React.Component<
  IAddressOverrideModalProps,
  IAddressOverrideModalState
> {
  constructor(props) {
    super(props);

    const values = {
      address1: "",
      address2: "",
      city: "",
      stateId: "",
      zipCode: "",
      phoneNumber: "",
      name: ""
    };

    this.state = {
      values,
      isLoaded: false,
      isSaving: false,
      isNew: !this.props.addressOverride.id
    };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
  }

  componentDidMount() {
    if (!this.state.isNew) {
      locationApi.getAddressOverrideForEdit(
        this.props.addressOverride.id,
        data => {
          this.setState({ values: data, isLoaded: true });
        }
      );
    } else {
      this.setState({ isLoaded: true });
    }
  }

  handleSubmit(values) {
    this.setState({ isSaving: true });
    values.userId = this.props.userId;

    if (this.state.isNew) {
      values.clientUnitId = this.props.addressOverride.clientUnitId;
      locationApi.createAddressOverride(values, data => {
        values.id = data.id;
        if (this.props.onSuccess) {
          this.props.onSuccess(values);
        }
        this.props.resolveModal();
      });
    } else {
      locationApi.updateAddressOverride(values, data => {
        if (this.props.onSuccess) {
          this.props.onSuccess(data);
        }
        this.props.resolveModal();
      });
    }
  }

  handleDelete(id) {
    this.setState({ isSaving: true });
    locationApi.deleteAddressOverride(id, () => {
      if (this.props.onDelete) {
        this.props.onDelete(this.props.addressOverride.clientUnitId);
      }
      this.props.resolveModal();
    });
  }

  render() {
    const { isNew, isLoaded, isSaving } = this.state;
    return (
      <Formik
        enableReinitialize
        initialValues={this.state.values}
        validationSchema={validationSchema}
        onSubmit={this.handleSubmit}
        render={props => (
          <AddressOverrideForm
            {...props}
            isLoading={!isLoaded || isSaving}
            isNew={isNew}
            onDelete={this.handleDelete}
          />
        )}
      />
    );
  }
}

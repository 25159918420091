import * as React from "react";
import { bindActionCreators } from "redux";
import styled, { StyledComponentClass } from "styled-components";
import { connect } from "react-redux";
import moment from "moment";

import { BuyerOrderListDto } from "../../../service-proxies";
import { createStructuredSelector } from "reselect";
import { IGlobalStore } from "../../../_reducers/reducers";
import { IShortLocationInfo } from "../../../_types/common";
import * as actionsSearch from "../../../_actions/search";
import * as layoutActions from "../../../_actions/layout";
import * as ordersApi from "../../../_api/orders";
import * as notifyActions from "../../../_actions/notification";
import { clientUnitFilterOptions } from "../../../_utils/clientUnitFilter";
import { IFlatLocationOption } from "../../../_reducers/current-user";
import {
  saveFilterSettings,
  loadFilterSettings
} from "../../../_utils/userSession";

import {
  Article,
  Link,
  LocationSelectorComponent,
  MainContainer,
  Pagination,
  RunningLineLoader,
  Table,
  Skeleton
} from "../../../components";

const ToolbarLink = styled(Link)`
  margin: 0 0 0 10px;
`;

interface ITwoLevelElement {
  className?: string;
}

const Position = {
  Container: styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
  `,

  Body: styled.div`
    height: 100%;
    flex: 1 1 100%;
  `,

  Footer: styled.div`
    flex: 0 0;
  `,

  Filter: styled.div`
    width: 260px;
  `,

  Header: styled(function({ className, ...props }) {
    return (
      <header className={className}>
        <div>{props.children}</div>
      </header>
    );
  } as React.StatelessComponent<ITwoLevelElement>)`
    padding: 20px;
    height: 40px;
    > div {
      display: flex;
      justify-content: space-between;
      height: 100%;
    }
  ` as StyledComponentClass<ITwoLevelElement, any>,

  HeaderElement: styled.div`
    display: flex;
    align-items: center;
  `,

  CenterdContainer: styled.div`
    display: flex;
    align-items: center;
    height: 100%;
    width: 100%;
  `
};

interface IGetOrdersParams {
  clientUnitId?: number;
  filter?: string;
  sorting?: string;
  skip?: number;
  perPage?: number;
}

interface IAllOrdersListProps extends IConnectedProps, IConnectedActions {}

interface IAllOrdersListState {
  sorting: string;
  skip: number;
  filter?: string;
  ordersStatus: "loading" | "sorting" | "done" | string;
  orders?: BuyerOrderListDto[];
  totalCount: number;
  divisionLocationFilter: number | null;
}

class AllOrdersList extends React.Component<
  IAllOrdersListProps,
  IAllOrdersListState
> {
  static renderSkeletonRows(perPage, status) {
    const rows = [];
    const elementsCount = status === "loading" ? perPage - 1 : 0;

    for (let i = 0; i < elementsCount; i++) {
      rows.push(
        <Table.Row
          key={i}
          rows={perPage}
          cells={[
            <Skeleton.Line key={`order-list-preloader-${i}`} size="sm" />,
            <Skeleton.Line key={`order-list-preloader-${i}`} size="xs" />,
            <Skeleton.Line key={`order-list-preloader-${i}`} size="sm" />,
            <Skeleton.Line key={`order-list-preloader-${i}`} size="sm" />,
            <Skeleton.Line key={`order-list-preloader-${i}`} size="sm" />,
            <Skeleton.Line key={`order-list-preloader-${i}`} size="xs" />,
            <Skeleton.Line key={`order-list-preloader-${i}`} size="sm" />
          ]}
        />
      );
    }
    return rows;
  }

  constructor(props) {
    super(props);

    const filterSettings = loadFilterSettings("allorders");

    this.state = {
      divisionLocationFilter: filterSettings.location || props.locationInfo.id,
      sorting: "status ASC",
      skip: 0,
      ordersStatus: "loading",
      totalCount: 0
    };

    this.handleChangePage = this.handleChangePage.bind(this);
    this.handleSortChanged = this.handleSortChanged.bind(this);
    this.getOrders = this.getOrders.bind(this);
    this.handleFilterChange = this.handleFilterChange.bind(this);

    this.props.setSearch("/orders/all", filter => {
      this.setState({ filter, skip: 0 }, () => {
        this.getOrders();
      });
    });
  }

  componentDidMount() {
    this.getOrders();
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.locationInfo.id !== this.props.locationInfo.id) {
      this.setState(
        { divisionLocationFilter: nextProps.locationInfo.id, skip: 0 },
        () => {
          this.getOrders();
        }
      );
    }
  }

  getOrders(params: IGetOrdersParams = {}) {
    const skip =
      Number.isInteger(params.skip) && params.skip >= 0
        ? params.skip
        : this.state.skip;
    const sort = params.sorting || this.state.sorting;
    const filter = params.filter || this.state.filter;
    const perPage = params.perPage || this.props.perPage;
    const clientUnitId =
      params.clientUnitId || this.state.divisionLocationFilter;

    let statusStartKey = "loading";
    const statusFinnishKey = "done";
    if (params.sorting) {
      statusStartKey = "sorting";
    }

    this.setState({ ordersStatus: statusStartKey });

    ordersApi
      .getAllOrders(clientUnitId, filter, sort, skip, perPage)
      .then(ordersData => {
        this.setState({
          ordersStatus: statusFinnishKey,
          orders: ordersData.items,
          totalCount: ordersData.totalCount
        });
      })
      .catch(e => {
        notifyActions.notify("fgfg", "error");
        notifyActions.notifySwagger(e, "error");
      });
  }

  handleChangePage(pageInfo) {
    const { perPage, skip } = pageInfo;

    this.getOrders({ perPage, skip });
    this.props.setPerPage(perPage);
    this.setState({ skip });
  }

  handleSortChanged(sorting) {
    this.setState({ sorting }, () => {
      this.getOrders({ sorting });
    });
  }

  handleFilterChange(value: IFlatLocationOption) {
    this.setState({ divisionLocationFilter: value.id, skip: 0 }, () => {
      this.getOrders();
      this.setFilterSettings();
    });
  }

  setFilterSettings() {
    const filter = {
      location: this.state.divisionLocationFilter,
      startDate: null,
      dueDate: null
    };

    saveFilterSettings("allorders", filter);
  }

  render() {
    return (
      <MainContainer>
        <Position.Container>
          <Position.Header>
            <Position.HeaderElement>
              <Article.Text scale="lg">
                <strong>All ORDERS</strong>
              </Article.Text>
            </Position.HeaderElement>
            <Position.HeaderElement>
              <Position.Filter>
                <LocationSelectorComponent
                  customFilter={clientUnitFilterOptions}
                  locationOptions={this.props.locationOptions}
                  onLocationChange={this.handleFilterChange}
                  selectedLocation={this.state.divisionLocationFilter}
                />
              </Position.Filter>
            </Position.HeaderElement>
          </Position.Header>
          <Position.Body>
            <RunningLineLoader
              isActive={
                this.state.ordersStatus === "sorting" ||
                this.state.ordersStatus === "loading"
              }
            />
            <Table fullHeight>
              <Table.Header
                columns={[
                  { name: "status", label: "status", sortable: true },
                  { name: "id", label: "order number", sortable: true },
                  { name: "submittingTime", label: "date", sortable: true },
                  { name: "ownerName", label: "buyer", sortable: true },
                  { name: "locationName", label: "location", sortable: true },
                  { name: "subTotalAmount", label: "amount", sortable: true },
                  { name: "toolbar", label: "", size: "md" }
                ]}
                sortBy={this.state.sorting}
                onSortChanged={this.handleSortChanged}
              />
              <Table.Body>
                {this.state.ordersStatus !== "loading" &&
                  (this.state.orders && !!this.state.orders.length ? (
                    this.state.orders.map((item, index) => (
                      <Table.Row
                        key={`${index}-${item.id}`}
                        cells={[
                          item.status,
                          item.number,
                          moment(item.submittingTime).format("MM/DD/YYYY"),
                          item.ownerName,
                          item.locationName,
                          item.subTotalAmount.toFixed(2),
                          <Position.CenterdContainer
                            key={`cell-toolbar-${index}-${item.id}`}
                          >
                            <ToolbarLink to={`/orders/all/${item.id}`}>
                              View Order
                            </ToolbarLink>
                          </Position.CenterdContainer>
                        ]}
                      />
                    ))
                  ) : (
                    <Table.Row
                      key={0}
                      colSpan="7"
                      rows={this.props.perPage}
                      cells={[<span key="0">NO ORDERS FOUND</span>]}
                    />
                  ))}
                {AllOrdersList.renderSkeletonRows(
                  this.props.perPage,
                  this.state.ordersStatus
                )}
              </Table.Body>
            </Table>
          </Position.Body>
          <Pagination
            skip={this.state.skip}
            perPage={this.props.perPage}
            count={this.state.totalCount}
            onChange={this.handleChangePage}
          />
        </Position.Container>
      </MainContainer>
    );
  }
}

interface IConnectedProps {
  locationInfo?: IShortLocationInfo;
  locationOptions: IFlatLocationOption[];
  perPage: number;
}

interface IConnectedActions {
  setSearch: typeof actionsSearch.set;
  setPerPage: typeof layoutActions.setPerPage;
  notifySwagger: typeof notifyActions.notifySwagger;
}

export default connect(
  createStructuredSelector<IGlobalStore, IConnectedProps>({
    perPage: state => state.layout.perPage,
    locationInfo: state => state.currentUser.locationInfo,
    locationOptions: state => state.currentUser.locationOptions
  }),
  dispatch =>
    bindActionCreators(
      {
        setSearch: actionsSearch.set,
        setPerPage: layoutActions.setPerPage,
        notifySwagger: notifyActions.notifySwagger,
        notify: notifyActions.notify
      },
      dispatch
    )
)(AllOrdersList);

import * as React from "react";
import { connect } from "react-redux";
import {
  FormikActions,
  FormikComputedProps,
  FormikHandlers,
  FormikState
} from "formik";
import styled from "styled-components";
import { sendEmail } from "../../_api/resetPassword";
import { Button, Field } from "../../components";
import { AuthenticateModelWithEmail } from "../../service-proxies";
// import { history } from "../../store";
import {
  prompt as openConfirm,
  alert,
  openCustomModalCallback,
  IOpenCustomModalCallback,
  IOpenAlert,
  IOpenConfirmPrompt
} from "../../_actions/modal";
import { getSwaggerErrorResponse } from "src/_utils/errorHandling";
import { history } from "../../store";
import debounce from "lodash.debounce";

const Icon = props => {
  return (
    <i {...props}>
      <svg viewBox="443 316 26 18" width="20" height="20">
        <path d=" M 443 316 L 443 334 L 469 334 L 469 316 L 443 316 Z  M 447.313 318 L 464.688 318 L 456 323.781 L 447.313 318 Z  M 445 318.875 L 455.438 325.844 L 456 326.187 L 456.563 325.844 L 467 318.875 L 467 332 L 445 332 L 445 318.875 Z " />
      </svg>
    </i>
  );
};

const EmailIcon = styled(Icon)`
  width: 21px;
  height: 21px;
  display: inline-flex;
  justify-content: center;

  svg {
    width: 20px;
    height: 20px;
    path {
      fill: #cccccc;
    }
  }
`;

// const LoginError = styled.div`
//   ${({ theme }) => theme.global}
//   color: ${({ theme }) => theme.default};
//   padding-bottom: 16px;
// `;

// const LoginErrorMessage = styled.div`
//   font-size: 18px;
// `;

// const LoginErrorDeatils = styled.div`
//   font-size: 14px;
// `;

const handleSendEmail = (emailAddress: string, modal: any) => {
  const input = {
    emailAddress: emailAddress
  };
  const successMessage =
    "You will receive an email shortly with instructions on how to reset your password.";
  sendEmail(input)
    .then(() => modal(successMessage, "Password Request Sent!"))
    .then(() => history.push("/login"))
    .catch(err => modal(getSwaggerErrorResponse(err).error.message, "Error!"));
};

interface ILoginFormProps
  extends FormikState<AuthenticateModelWithEmail>,
    FormikActions<AuthenticateModelWithEmail>,
    FormikComputedProps<AuthenticateModelWithEmail>,
    FormikHandlers,
    IConnectedProps {
  openModal: IOpenCustomModalCallback;
  openConfirm: IOpenConfirmPrompt;
  alertModal: IOpenAlert;
}

const ForgotPasswordForm: React.SFC<ILoginFormProps> = ({
  values,
  errors,
  touched,
  handleChange,
  handleBlur,
  handleSubmit,
  isSubmitting,
  errorMessage,
  errorDetails,
  isValid,
  ...restProps
}) => (
  <form onSubmit={handleSubmit}>
    <legend>Reset Password</legend>
    <p>
      Enter your email address and we’ll send you a link to reset your password.
    </p>

    {/* {errorMessage && (
      <LoginError>
        <LoginErrorMessage>{errorMessage}</LoginErrorMessage>
        <LoginErrorDeatils>{errorDetails}</LoginErrorDeatils>
      </LoginError>
    )} */}

    <Field
      icon={EmailIcon}
      type="email"
      name="email"
      placeholder="email"
      onChange={handleChange}
      onBlur={handleBlur}
      value={values.email}
      size="lg"
      error={touched.email && errors.email}
    />

    <Button
      onClick={debounce(
        () => handleSendEmail(values.email, restProps.alertModal),
        500
      )}
      disabled={isSubmitting || !isValid}
      scale="lg"
      preloader={isSubmitting}
    >
      Submit
    </Button>
  </form>
);

interface IConnectedProps {
  errorMessage: string | null;
  errorDetails: string | null;
}

export default connect(
  null,
  {
    openModal: openCustomModalCallback,
    openConfirm,
    alertModal: alert
  }
)(ForgotPasswordForm);

import * as React from 'react';
import { withRouter, Switch } from 'react-router-dom';
import RoutesMapper from '../Router/RoutesMapper';
import ClientInvoicesList from './List';
import ClientInvoicesView from './View';
import { IRouteEntry } from '../../_types/common';

import withGranted, { IWithGrantedProps } from '../HOC/WithGranted';
import { CLIENTINVOICES } from '../../_constants/permissions';

class OrderRoutes extends React.Component<IWithGrantedProps> {

  public routes: IRouteEntry[] = [
    {
      path: '/',
      exact: true,
      component: ClientInvoicesList,
      permission: CLIENTINVOICES, 
    },
    {
      path: '/:clientInvoiceId',
      exact: true,
      component: ClientInvoicesView,
      permission: CLIENTINVOICES,
    }
  ];

  constructor(props) {
    super(props);
  }
  render() {
    return (
      <React.Fragment>
        <Switch>
          <RoutesMapper routes={this.routes} addNotFound {...this.props} />
        </Switch>
      </React.Fragment>
    );
  }
}

export default withGranted(withRouter(OrderRoutes));
import {
  CreateLocationDto,
  UpdateLocationDto,
  VendorAccountNumberDto,
} from '../../../service-proxies';
import * as moment from 'moment';

export class CreateLocationClass implements CreateLocationDto {
  parentId: number = null;
  name: string = '';
  physicianName: string = '';
  physicianLicenseNumber: string = '';
  physicianLicenseExpirationDate: moment.Moment = null;
  physicianDeaExpirationDate: moment.Moment = null;
  physicianNpiNumber: string = '';
  physicianDeaNumber: string = '';
  facilityOrLocationCode: string = '';
  isInHospital: boolean = null;
  operationStartTime: string = '';
  operationEndTime: string = '';
  taxRate: number = null;
  phoneNumber: string = '';
  faxNumber: string = '';
  displayAddress: string = '';
  city: string = '';
  zipCode: string = '';
  stateId: number = null;
  address1: string = '';
  address2: string = '';
  vendorAccountNumbers: VendorAccountNumberDto[] = [];

  constructor(input: object & CreateLocationDto) {
    Object.keys(this).forEach(key => {
      this[key] = input[key];
    });
  }
}

export class UpdateLocationClass implements UpdateLocationDto {
  id: number = null;
  parentId: number = null;
  name: string = '';
  physicianName: string = '';
  physicianLicenseNumber: string = '';
  physicianLicenseExpirationDate: moment.Moment = null;
  physicianDeaExpirationDate: moment.Moment = null;
  physicianNpiNumber: string = '';
  physicianDeaNumber: string = '';
  facilityOrLocationCode: string = '';
  isInHospital: boolean = null;
  operationStartTime: string = '';
  operationEndTime: string = '';
  taxRate: number = null;
  phoneNumber: string = '';
  faxNumber: string = '';
  displayAddress: string = '';
  city: string = '';
  zipCode: string = '';
  stateId: number = null;
  address1: string = '';
  address2: string = '';
  vendorAccountNumbers: VendorAccountNumberDto[] = [];

  constructor(input: object & UpdateLocationDto) {
    Object.keys(this).forEach(key => {
      this[key] = input[key];
    });
  }
}

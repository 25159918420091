import styled, {StyledComponentClass} from 'styled-components';
import * as React from 'react';

interface CellProps extends React.HTMLProps<HTMLDivElement> {
  size?: any;
}

interface IFormGridType extends StyledComponentClass<{}, React.StatelessComponent<{}>> {
  Body?: StyledComponentClass<{}, React.StatelessComponent<{}>>;
  Header?: StyledComponentClass<{}, React.StatelessComponent<{}>>;
  TabsHeader?: StyledComponentClass<{}, React.StatelessComponent<{}>>;
  Footer?: StyledComponentClass<{}, React.StatelessComponent<{}>>;
  FooterElement?: StyledComponentClass<{}, React.StatelessComponent<{}>>;
  Cell?: StyledComponentClass<CellProps, React.StatelessComponent<CellProps>>;
  Row?: StyledComponentClass<{}, React.StatelessComponent<{}>>;
}

export const FormGrid: IFormGridType = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  flex: 1 1 100%;
`;

FormGrid.Body = styled.div`
  padding: 30px 50px;
  flex: 1 1 100%;
`;

FormGrid.Header = styled.div`
  text-align: left;
  padding: 24px 20px 20px 50px ;
  display: flex;
  justify-content: space-between;
  flex: 0 1 40px;
  font-size: 20px;
  font-weight: bold;
  color: ${({ theme: { primary } }) => primary};
  border-bottom: 1px solid ${({ theme: { light } }) => light};
`;

FormGrid.TabsHeader = styled.div`
  text-align: left;
  padding: 22px 20px 0 30px;
  font-size: 20px;
  font-weight: bold;
  color: ${({ theme: { primary } }) => primary};
  border-bottom: 1px solid ${({ theme: { light } }) => light};
`;

FormGrid.Footer = styled.footer`
  text-align: right;
  padding: 24px 50px 50px;
  display: flex;
  justify-content: space-between;
  flex: 1 0 40px;
`;

FormGrid.FooterElement = styled.footer`
  margin: 0 -8px;
  > * {
    margin: 0 8px;
  }
`;

FormGrid.Cell = styled.div<CellProps>`
  ${props => {

    const {
      size
    } = props;

    const width = size ? size : '100%';
    
    return `
      display: flex;
      flex: 1 1 ${width};
      margin: 0 10px;
      flex-direction: column;
    `;
  }}
  
  ${FormGrid.Cell} {
    margin: 0 5px;
  }
`;

FormGrid.Row = styled.div`
  ${() => {

    return `
      display: flex;
      flex: 1 1 100%;
      max-width: 700px;
      margin: 0 -10px;

      & & {
        margin: 0 -5px;
      }
    `;
  }}
`;

export default FormGrid;

import * as React from 'react';
import {
  withRouter,
  Switch,
  NavLink,
  Redirect,
} from 'react-router-dom';
import RoutesMapper from '../Router/RoutesMapper';
import BuyerOrders from './Buyer/Orders';
import AllOrders from './All/List';
import BuyerOrderView from './Buyer/View';
import AllOrderView from './All/View';
import OrdersList from './List';
import OrderView from './View';
import PlacingOrder from './Placing/View';
import { IRouteEntry } from '../../_types/common';

import withGranted, { IWithGrantedProps } from '../HOC/WithGranted';

import {
  ORDERS_SUBMIT,
  ORDERS_BUYERORDERS,
  ORDERS_MYORDERS,
  ORDERS_ALLORDERS,
} from '../../_constants/permissions';

import MenuSidebar from '../MenuSidebar/MenuSidebar';

import {
  MainMenu,
  HorizontalMenu,
} from '../../components';
import { RouteComponentProps } from "react-router";

const routes: IRouteEntry[] = [
  {
    path: '/my',
    exact: true,
    permission: ORDERS_MYORDERS,
    component: OrdersList,
  },
  {
    path: '/buyer',
    exact: true,
    component: BuyerOrders,
    permission: ORDERS_BUYERORDERS,
  },
  {
    path: '/all',
    exact: true,
    component: AllOrders,
    permission: ORDERS_ALLORDERS,
  },
  {
    path: '/placing',
    exact: true,
    permission: ORDERS_SUBMIT,
    component: PlacingOrder,
    excludeAsDefaultRoute: true,
  },
  {
    path: '/my/:orderId',
    exact: true,
    permission: ORDERS_MYORDERS,
    component: OrderView,
  },
  {
    path: '/buyer/:orderId?',
    exact: true,
    component: BuyerOrderView,
    permission: ORDERS_BUYERORDERS,
  },
  {
    path: '/all/:orderId?',
    exact: true,
    component: AllOrderView,
    permission: ORDERS_ALLORDERS,
  }
];


class OrderRoutes extends React.Component<IWithGrantedProps & RouteComponentProps<{}>> {

  allowedRoutePath: string;

  constructor(props) {
    super(props);
    const firstAllowedRoute = routes.find(route => (!route.excludeAsDefaultRoute && route.path && props.isGranted(route.permission)));
    this.allowedRoutePath = firstAllowedRoute ? firstAllowedRoute.path : '';
  }

  render() {
    const { match: { url }, isGranted } = this.props;
    return (
      <React.Fragment>
        <MenuSidebar>
          <MainMenu>
            <HorizontalMenu>
              {isGranted(ORDERS_BUYERORDERS) && <NavLink to="/orders/buyer">Buyer Orders</NavLink>}
              {isGranted(ORDERS_MYORDERS) && <NavLink to="/orders/my">My Orders</NavLink>}
              {isGranted(ORDERS_ALLORDERS) && <NavLink to="/orders/all">All Orders</NavLink>}
            </HorizontalMenu>
          </MainMenu>
        </MenuSidebar>
        <Switch>
          <Redirect exact from={`${url}`} to={this.allowedRoutePath ? `${url}${this.allowedRoutePath}` : '/'} />
          <RoutesMapper routes={routes} addNotFound {...this.props} />
        </Switch>
      </React.Fragment>
    );
  }
}


export default withGranted(withRouter(OrderRoutes));

import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

export const Label = styled.div`
  ${({ theme }) => {
    let {
      global,
      primary,
      fs_md,
    } = theme;
    return `
      ${global}
      color: ${primary};
      font-size: ${fs_md};
      margin-right: 5px;
    `;
  }}
`;

export const Body = styled.div`
  ${({ theme, ...props }) => {
    let {
      global,
      lighter,
    } = theme;

    let backgroundColor = props.status ?
      theme.default :
      lighter;

    let height = props.compact ?
      '20px' :
      '28px';

    let width = props.compact ?
      '36px' :
      '58px';

    let padding = props.compact ?
      '3px' :
      '4px';

    return `
      ${global}
      background-color: ${backgroundColor};
      width: ${width};
      height: ${height};
      padding: ${padding};
      overflow: hidden;
      transition: all 0.25s ease-in-out;
      position: relative;
    `;
  }}
`;

export const TriggerContainer = styled.div`
  ${({ theme, ...props }) => {
    let {
      global,
    } = theme;

    let width = props.compact ?
      '16px' :
      '30px';

    let margin = props.status ?
      '0' :
      `0 -${width} 0`;

    return `
      ${global}
      display: flex;
      flex-wrap: nowrap;
      position: relative;
      margin: ${margin};
      transition: all 0.25s ease-in-out;
    `;
  }}
`;

export const Trigger = styled.div`
  ${({ theme, ...props }) => {
    let {
      global,
      main,
    } = theme;

    let proportion = props.compact ?
      '14px' :
      '20px';

    return `
      ${global}
      width: ${proportion};
      height: ${proportion};
      background-color: ${main};
      flex: 0 0 ${proportion};
      box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.15);
      cursor: pointer;
    `;
  }}
`;

export const TriggerInput = styled.input`
  ${({ theme, ...props }) => {
    let {
      global,
    } = theme;

    let height = props.compact ?
      '14px' :
      '20px';

    let width = props.compact ?
      '30px' :
      '50px';

    return `
      ${global}
      opacity: 0;
      top: 0;
      left: 0;
      width: ${width};
      height: ${height};
      position: absolute;
      cursor: pointer;
    `;
  }}
`;

export const Answer = styled.div`
  ${({ theme, ...props }) => {
    let {
      global,
      main,
      primary,
    } = theme;

    let color = props.status ?
      main :
      primary;

    let height = props.compact ?
      '14px' :
      '20px';

    let width = props.compact ?
      '16px' :
      '30px';

    return `
      ${global}
      width: ${width};
      height: ${height};
      flex: 0 0 ${width};
      justify-content: center;
      display: flex;
      align-items: center;
      font-size: 12px;
      color: ${color};
      transition: all 0.25s ease-in-out;
    `;
  }}
`;

export const Toggle = styled(({
  className,
  label,
  value,
  required,
  compact,
  onChange,
  disabled,
  ...inputProps
}) => {
  return <div className={className}>
    {
      label &&
      <Label>
        {
          `${label} ${required ? '*' : ''}`
        }
      </Label>
    }
    <Body compact={compact} status={value}>
      <TriggerContainer status={value} compact={compact} >
        <Answer status={value} compact={compact} >{!compact ? 'Yes' : ''}</Answer>
        <Trigger compact={compact} />
        <Answer status={value} compact={compact} >{!compact ? 'No' : ''}</Answer>
      </TriggerContainer>
      <TriggerInput compact={compact} {...inputProps} type="checkbox" onChange={onChange} checked={value} disabled={disabled} />
    </Body>
  </div>;
})`
${({ theme, ...props }) => {
    let {
      global,
    } = theme;

    return `
    ${global}
    display: flex;
    align-items: center;
    margin: 0 0;
  `;
  }}
`;

Toggle.propTypes = {
  className: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.bool,
  required: PropTypes.bool,
  compact: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
};

Toggle.defaultProps = {
  value: false,
};

export default Toggle;
import React from 'react';
import styled from 'styled-components';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { history } from '../../../store';
import {
  VendorListDto,
} from '../../../service-proxies';

import {
  IMatch
} from '../../../_types/common';

import {
  Table,
  Link,
  MainContainer,
  Button,
  Pagination,
  Checkbox,
} from '../../../components';

import {
  PencilIcon,
  TrashIcon,
  PlusIcon,
} from '../../../assets/icons';

import WithGranted, { IWithGrantedProps } from '../../HOC/WithGranted';
import * as actionsSearch from '../../../_actions/search';
import * as actionsModal from '../../../_actions/modal';
import * as actionsLayout from '../../../_actions/layout';
import * as actionsNotification from '../../../_actions/notification';
import * as vendorsApi from '../../../_api/vendor';
import {
  ADMINISTRATION_VENDORS_CREATE,
  ADMINISTRATION_VENDORS_ARCHIVED,
  ADMINISTRATION_VENDORS_EDIT,
  ADMINISTRATION_VENDORS_ARCHIVED_UNARCHIVE,
  ADMINISTRATION_VENDORS_ARCHIVE,
} from '../../../_constants/permissions';

const Position = {
  Container: styled.div`
    width: 100%;
    display: flex;
    flex-direction: column; 
  `,

  Head: styled<{ className?: string }>(({ className, children }) => <header className={className}>
    <div>
      {children}
    </div>
  </header>)`
    padding: 20px;
    >div{
      margin: -7.5px;
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
    }
  `,

  HeadElement: styled.div`
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    & > * {
      margin: 7.5px;
    }
  `,

  Toolbar: styled.header`
    text-align: right;
    height: 15px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  `,

  Body: styled.div`
    width: 100%; 
    height: 100%; 
  `,

};

interface IEditButtonProps {
  onEdit: (id: number) => void;
  id: number;
}

const EditButton: React.SFC<IEditButtonProps> = ({
  onEdit,
  id,
}) => {

  function handleEdit() {
    onEdit(id);
  }

  return <Link.Button onClick={handleEdit}><PencilIcon /></Link.Button>;
};

interface IArchveButtonProps {
  onArchive: (id: number) => void;
  id: number;
}

const ArchveButton: React.SFC<IArchveButtonProps> = ({
  onArchive,
  id,
}) => {

  function handleDelete() {
    onArchive(id);
  }

  return <Link.Button onClick={handleDelete}><TrashIcon /></Link.Button>;
};

interface IListProps extends IWithGrantedProps {
  setSearch: typeof actionsSearch.set;
  match: IMatch;
  prompt: typeof actionsModal.prompt;
  perPage: number;
  setPerPage: typeof actionsLayout.setPerPage;
  notifySwagger: typeof actionsNotification.notifySwagger;
}

interface IListState {
  vendors?: VendorListDto[];
  totalCount?: number;
  listStatus?: string;
  skip?: number;
  filter?: string;
  sortBy?: string;
  isArchived: boolean;
}

interface IGetVendorsParams {
  filter?: string;
  skip?: number;
  perPage?: number;
  sortBy?: string;
  isArchived?: boolean;
}

class List extends React.Component<IListProps, IListState> {
  constructor(props) {
    super(props);
    this.handleAddNewClick = this.handleAddNewClick.bind(this);
    this.handleChangePage = this.handleChangePage.bind(this);
    this.handleItemEdit = this.handleItemEdit.bind(this);
    this.handleItemArchiveRequest = this.handleItemArchiveRequest.bind(this);
    this.handleSortChanged = this.handleSortChanged.bind(this);
    this.getVendors = this.getVendors.bind(this);
    this.handleTriggerArchived = this.handleTriggerArchived.bind(this);
    this.handleItemUnArchiveRequest = this.handleItemUnArchiveRequest.bind(this);

    this.state = {
      vendors: [],
      totalCount: 0,
      listStatus: '',
      skip: 0,
      sortBy: 'name ASC',
      isArchived: false,
    };

    this.props.setSearch('/admin/vendors', (filter) => {
      this.setState({ filter, skip: 0 }, () => {
        this.getVendors({ filter });
      });
    });

  }

  componentDidMount() {
    this.getVendors();
  }

  getVendors(params: IGetVendorsParams = {}) {
    const skip = (Number.isInteger(params.skip) && params >= 0) ? params.skip : this.state.skip;
    const perPage = params.perPage || this.props.perPage;
    const filter = params.filter || this.state.filter;
    const sortBy = params.sortBy || this.state.sortBy;
    const isArchived = (params.isArchived !== undefined) ? params.isArchived : this.state.isArchived;

    const statusStartKey = 'loading';
    const statusFinnishKey = 'loaded';

    this.setState({ listStatus: statusStartKey });

    vendorsApi.getVendors(perPage, skip, filter, sortBy, isArchived)
      .then(vendorsData => {
        this.setState({
          listStatus: statusFinnishKey,
          vendors: vendorsData.items,
          totalCount: vendorsData.totalCount
        });
      })
      .catch(e => {
        this.props.notifySwagger(e, 'error');
      });
  }

  handleAddNewClick() {
    history.push('/admin/vendors/new');
  }

  handleChangePage(pageInfo) {
    const {
      perPage,
      skip,
    } = pageInfo;

    this.setState({ skip }, () => {
      this.getVendors({ perPage, skip });
      this.props.setPerPage(perPage);
    });
  }

  renderPreloaderRows(perPage, status) {
    const rows = [];
    const elementsCount = (status === 'loading') ? perPage - 1 : 0;

    for (let i = 0; i < elementsCount; i++) {
      rows.push(<Table.Row
        key={i}
        colSpan="4"
        rows={perPage}
        isLoading={status === 'loading'}
        cells={[<span key="0">NO VENDORS FOUND</span>]}
      />);
    }
    return rows;
  }

  handleItemEdit(id) {
    history.push(`/admin/vendors/${id}`);
  }

  handleItemArchiveRequest(id) {
    this.props.prompt({ question: 'Do you really want archive this item?' })
      .then(() => {
        vendorsApi.archiveVendor(id)
          .then(() => {
            this.getVendors();
          })
          .catch((e) => {
            this.props.notifySwagger(e, 'error');
          });
      })
      .catch(() => { });
  }

  handleItemUnArchiveRequest(id) {
    this.props.prompt({ question: 'Do you really want unarchive this item?' })
      .then(() => {
        vendorsApi.unArchiveVendor(id)
          .then(() => {
            this.getVendors();
          })
          .catch((e) => {
            this.props.notifySwagger(e, 'error');
          });
      })
      .catch(() => { });
  }

  handleSortChanged(sortBy) {
    this.setState({ sortBy }, () => {
      this.getVendors();
    });
  }

  handleTriggerArchived(isArchived) {
    this.setState({ isArchived }, () => {
      this.getVendors();
    });
  }

  render() {
    return (
      <MainContainer title="Vendor Management">
        <Position.Container>
          <Position.Head>
            <Position.HeadElement>
              {
                this.props.isGranted(ADMINISTRATION_VENDORS_ARCHIVED) &&
                <Checkbox
                  value={this.state.isArchived}
                  id="archivedVendors"
                  label="Inactive Vendors"
                  onChange={this.handleTriggerArchived}
                />
              }
            </Position.HeadElement>
            <Position.HeadElement>
              {
                this.props.isGranted(ADMINISTRATION_VENDORS_CREATE) &&
                <Button onClick={this.handleAddNewClick} icon={<PlusIcon />}>
                  Add new
                </Button>
              }
            </Position.HeadElement>
          </Position.Head>
          <Position.Body>
            <Table fullHeight>
              <Table.Header
                onSortChanged={this.handleSortChanged}
                sortBy={this.state.sortBy}
                columns={[
                  { name: 'name', label: 'name', sortable: true },
                  { name: 'transmissionType', label: 'transmission type' },
                  { name: 'email', label: 'email', sortable: true },
                  { name: '', label: '' },
                ]}
              />
              <Table.Body>
                {
                  this.state.listStatus !== 'loading' &&
                  (
                    (this.state.vendors && !!this.state.vendors.length) ?
                      this.state.vendors.map((item, index) =>
                        <Table.Row
                          key={`${index}-${item.id}`}
                          cells={[
                            item.name,
                            item.transmissionType,
                            item.email,
                            <>
                              {
                                this.state.isArchived ?
                                  <Position.Toolbar key={`cell-toolbar-${index}-${item.id}`} >
                                    {
                                      this.props.isGranted(ADMINISTRATION_VENDORS_ARCHIVED_UNARCHIVE) &&
                                      <ArchveButton onArchive={this.handleItemUnArchiveRequest} id={item.id} />
                                    }
                                  </Position.Toolbar> :
                                  <Position.Toolbar key={`cell-toolbar-${index}-${item.id}`} >
                                    {
                                      this.props.isGranted(ADMINISTRATION_VENDORS_EDIT) &&
                                      <EditButton onEdit={this.handleItemEdit} id={item.id} />
                                    }
                                    {
                                      this.props.isGranted(ADMINISTRATION_VENDORS_ARCHIVE) &&
                                      <ArchveButton onArchive={this.handleItemArchiveRequest} id={item.id} />
                                    }
                                  </Position.Toolbar>
                              }
                            </>
                          ]}
                        />) :
                      <Table.Row
                        key={0}
                        colSpan="4"
                        rows={this.props.perPage}
                        isLoading={this.state.listStatus === 'loading'}
                        cells={[<span key="0">NO VENDORS FOUND</span>]}
                      />
                  )
                }
                {
                  this.state.listStatus === 'loading' &&
                  this.renderPreloaderRows(this.props.perPage, this.state.listStatus)
                }
              </Table.Body>
            </Table>
          </Position.Body>
          <Pagination
            skip={this.state.skip}
            perPage={this.props.perPage}
            count={this.state.totalCount}
            onChange={this.handleChangePage}
          />
        </Position.Container>
      </MainContainer>
    );
  }
}

export default withRouter(connect(
  state => ({
    perPage: state.layout.perPage,
  }),
  dispatch => bindActionCreators({
    setSearch: actionsSearch.set,
    prompt: actionsModal.prompt,
    setPerPage: actionsLayout.setPerPage,
    notifySwagger: actionsNotification.notifySwagger,
  }, dispatch)
)(WithGranted(List)));
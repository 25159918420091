import * as React from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import {
  Link,
} from '../../components';

import {
  XIcon,
} from '../../assets/icons';
import { createStructuredSelector } from "reselect";
import { IGlobalStore } from "../../_reducers/reducers";
import { INotificationStore } from "../../_reducers/notification";

interface ICloseButton {
  className?: string;
  closeToast?: () => void;
}

const CloseButton = styled<ICloseButton>(({ className, closeToast }) => (
  <Link.Button
    onClick={closeToast}
    className={className}
  >
    <XIcon />
  </Link.Button>
))`
    svg{
      path{
        fill: ${({ theme }) => theme.main};
      }
    }
`;

interface INotificationProps extends IConnectedProps {
  className?: string;
}

const Notification = styled(class extends React.Component<INotificationProps> {
  componentDidUpdate() {
    const message = this.props.notification.message;
    const type = this.props.notification.messageType;
    const options = {
      position: 'bottom-center',
      hideProgressBar: true,
    };

    if (message) {
      toast[type](message, options);
    }
  }

  render() {
    return <ToastContainer
      className={this.props.className}
      toastClassName={this.props.className}
      bodyClassName={this.props.className}
      progressClassName={this.props.className}
      closeButton={<CloseButton />}
    />;
  }
})`
  ${({ theme }) => {
    const {
      global,
      fs_md,
      warning,
      success,
    } = theme;
    return `
      &.Toastify__toast{
        min-height: 34px;
        padding: 8px 10px;
        &-container{
          ${global}
          width: 750px;
  
          &--bottom-center{
            margin-left: -375px;
          }
        }

        &-body{
          ${global}
          font-size: ${fs_md};
        }

        &--error { 
          background-color: ${warning};
        }

        &--info { 
          background-color: ${success};
        }
      }

    `;
  }}
`;

interface IConnectedProps {
  notification: INotificationStore;
}

export default connect(
  createStructuredSelector<IGlobalStore, IConnectedProps>({
    notification: state => state.notification
  }),
  null
)(Notification);

import { combineReducers } from 'redux';
import { routerReducer } from 'react-router-redux';
import currentUser, {ICurrentUserStore} from './current-user';
import category from './category';
import products, {IProductsStore} from './products';
import notification, {INotificationStore} from './notification';
import location, {ILocationStore} from './location';
import clients, {IClientStore} from './clients';
import divisions, {IDivisionsStore} from './divisions';
import users from './users';
import search, {ISearchStore} from './search';
import orders, {IOrdersStore} from './orders';
import modal, {IModalStore} from './modal';
import layout, {ILayoutStore} from './layout';
import roles, {IRolesStore} from './roles';
import cart, {ICartStore} from './cart';

export interface IGlobalStore {
  router: any;
  cart: ICartStore;
  category: any;
  currentUser: ICurrentUserStore;
  products: IProductsStore;
  notification: INotificationStore;
  location: ILocationStore;
  divisions: IDivisionsStore;
  clients: IClientStore;
  users: any;
  search: ISearchStore;
  orders: IOrdersStore;
  modal: IModalStore;
  layout: ILayoutStore;
  roles: IRolesStore;
}

export default combineReducers<IGlobalStore>({
  router: routerReducer,
  cart,
  category,
  currentUser,
  products,
  notification,
  location,
  divisions,
  clients,
  users,
  search,
  modal,
  layout,
  orders,
  roles,
});

import * as React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';
import { bindActionCreators } from 'redux';
import styled from 'styled-components';

import {
  SubMenuPosition,
  // Select,
  MainMenu,
  Skeleton,
  Link,
  HorizontalMenu,
} from '../../components';

import * as actions from '../../_actions/category';

// import {
//   MASTERCATALOGUE,
// } from '../../_constants/permissions';

import WithGranted, { IWithGrantedProps } from '../HOC/WithGranted';
import {
  GetСategoriesDto,
} from '../../service-proxies';
import { createStructuredSelector } from 'reselect';
import { IGlobalStore } from '../../_reducers/reducers';
import { CategoryContextConsumer } from './Contexts/CategoryContext';

const Preloader = styled<{ className?: string }>((props) => (<span className={props.className}>
  <Skeleton.Line size="sm" /><Skeleton.Line size="sm" />
</span>))`
  padding: 0 20px;
  ${Skeleton.Line}{
    margin: 0 10px 0 0;
  } 
`;

interface IRouteParams {
  subCategoryId?: string;
  categoryId?: string;
  catalogId?: string;
}

interface ICategoriesProps extends IConnectedProps, IConnectedActions, IWithGrantedProps, RouteComponentProps<IRouteParams> {
  className?: string;
  catalogId: number;
}

const Categories = styled(class extends React.Component<ICategoriesProps> {
  catalogId?: number;

  constructor(props) {
    super(props);

    this.getUrl = this.getUrl.bind(this);
    this.setCatalogId = this.setCatalogId.bind(this);

  }

  componentDidMount() {
    // this.catalogId = this.setCatalogId();
    // if (this.catalogId) {
    //   this.props.getCategories(this.catalogId);
    // }
  }

  componentDidUpdate(prevProps) {
    // this.catalogId = this.setCatalogId();
    // if (this.props.catalogId) {
    //   if (prevProps.catalogId !== this.props.catalogId) {
    //     this.props.getCategories(this.props.catalogId);
    //   }
    // }
  }

  setCatalogId() {
    const selectorCatalogId = this.props.catalogId;
    const urlCatalogId = this.props.match.params.catalogId;

    if (urlCatalogId) {
      return parseInt(urlCatalogId, 10);
    } else if (selectorCatalogId) {
      return selectorCatalogId;
    }

    return undefined;
  }

  getUrl() {
    const selectorCatalogId = this.props.catalogId;
    const urlCatalogId = this.props.match.params.catalogId;

    if (urlCatalogId) {
      return `/admin/catalog/${urlCatalogId}/products`;
    } else if (selectorCatalogId) {
      return '/products';
    } else {
      return '/master-products';
    }

  }

  render() {
    const url = this.getUrl();
    const delimiter = (url[url.length - 1] === '/') ? '' : '/';
    return (
      <CategoryContextConsumer>
        {(context) =>
          <SubMenuPosition className={this.props.className}>
            {
              // this.props.isGranted(MASTERCATALOGUE) &&
              // <Select
              //   inputProps={{ readOnly: true }}
              //   value="all_products"
              //   clearable={false}
              //   compact
              //   options={[
              //     { label: 'All Products', value: 'all_products' },
              //     { label: 'By Manufacturer', value: 'by_manufacturer' },
              //     { label: 'By Vendor', value: 'by_vendor' },
              //     { label: 'Top Sellers', value: 'top_sellers' },
              //     { label: 'On Backorder', value: 'on_backorder' },
              //     { label: 'Pass-Thoughts', value: 'pass_thoughts' },
              //   ]}
              // />
            }
            {
              (context.status === 'done') &&
              // this.catalogId &&
              <MainMenu compact>
                <HorizontalMenu>
                  {
                    context.categories.map((item, index) => {
                      return <Link.NavLink key={index} to={`${url}${delimiter}${item.id}`}>
                        {item.name}
                      </Link.NavLink>;
                    })
                  }
                </HorizontalMenu>
              </MainMenu>
            }
            {
              context.status === 'loading' &&
              <Preloader />
            }
          </SubMenuPosition>
        }
      </CategoryContextConsumer>
    );
  }
})`
  ${({ ...props }) => {
    const position = !props.isNotPosition ?
      `
      display: flex;
      align-items: center;  
    
      margin: 2px 0 0 0;
      padding: 10px 20px;`:
      '';
    return `
      ${position}
    `;
  }}

`;

interface IConnectedProps {
  categories: GetСategoriesDto[];
  listStatus: string;
  catalogId: number;
}

interface IConnectedActions {
  getCategories: typeof actions.getCountedClientCategories;
}

interface IOuterProps extends RouteComponentProps<IRouteParams> {
  catalogId: number;
  isNotPosition: boolean;
}

export default withRouter<IOuterProps>(connect(
  createStructuredSelector<IGlobalStore, IConnectedProps>({
    categories: state => state.category.categories as GetСategoriesDto[],
    listStatus: state => state.category.listStatus,
    catalogId: state => state.currentUser.catalogId,
  }),
  dispatch => bindActionCreators({
    getCategories: actions.getCountedClientCategories,
  }, dispatch)
)(WithGranted(Categories)));

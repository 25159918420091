import { passwordServiceProxy } from "../servicePool";
import { ResetPasswordDto, ResetPasswordEmailDto } from "../service-proxies";

export function resetPassword(input: ResetPasswordDto): Promise<void> {
  return passwordServiceProxy.resetPassword(input);
}

export function sendEmail(input: ResetPasswordEmailDto): Promise<void> {
  return passwordServiceProxy.sendEmail(input);
}

import * as React from 'react';
import moment from 'moment';

import withMenu from '../../HOC/WithMenu';

import { history } from '../../../store';

import {
  Button,
  Field,
  Number,
  Select,
  Chips,
  DatePicker,
  Textarea,
  FormGrid as Grid,
  Link,
} from '../../../components';

import * as medicalSpecialityApi from '../../../_api/medicalSpeciality';
import { searchSupplyChainManagers } from '../../../_api/user';
import { IEditClientState, ISuppliersEdit } from './Edit';
import { FormikActions, FormikComputedProps, FormikHandlers, FormikState } from 'formik';

interface IFormProps extends
  FormikHandlers,
  FormikState<IEditClientState>,
  FormikActions<IEditClientState>,
  FormikComputedProps<IEditClientState> {
  isLoading: boolean;
  isNew: boolean;
}

interface IFormState {
  startDateFocused?: boolean;
}

class Form extends React.Component<IFormProps, IFormState> {

  constructor(props) {
    super(props);

    this.state = {
      startDateFocused: false,
    };

    this.loadMedicalSpeciality = this.loadMedicalSpeciality.bind(this);
    this.handleMedicalSpecialitySelect = this.handleMedicalSpecialitySelect.bind(this);

    this.loadSupplyChainManagers = this.loadSupplyChainManagers.bind(this);
    this.handleSupplyChainManagerSelect = this.handleSupplyChainManagerSelect.bind(this);

    this.handlePrimaryPreviousSuppliersAdd = this.handlePrimaryPreviousSuppliersAdd.bind(this);
    this.handlePrimaryPreviousSuppliersDelete = this.handlePrimaryPreviousSuppliersDelete.bind(this);
    this.handlePrimaryPreviousSuppliersEdit = this.handlePrimaryPreviousSuppliersEdit.bind(this);

    this.handleStartDateChange = this.handleStartDateChange.bind(this);
    this.handleStartDateFocus = this.handleStartDateFocus.bind(this);
    this.handleStartDateTouched = this.handleStartDateTouched.bind(this);

    this.handleCancel = this.handleCancel.bind(this);
    this.handleDiscard = this.handleDiscard.bind(this);

  }

  loadMedicalSpeciality(text, callback) {
    medicalSpecialityApi.getAll(text, data => {
      const options = data.items;

      callback(null, {
        options,
        complete: true,
      });
    });
  }

  loadSupplyChainManagers(text, callback) {
    searchSupplyChainManagers(10, text).then(data => {
      const options = data.items;
      callback(null, {
        options,
        complete: true,
      });
    }).catch(() => { });
  }

  handleMedicalSpecialitySelect(data) {
    this.props.setFieldValue('medicalSpeciality', data);
  }

  handleSupplyChainManagerSelect(data) {
    this.props.setFieldValue('scManager', data);
  }

  handlePrimaryPreviousSuppliersAdd(suppliers) {
    this.props.setFieldValue('primaryPreviousSuppliers', suppliers);
  }

  handlePrimaryPreviousSuppliersDelete(value) {
    const reducedValues = this.props.values.primaryPreviousSuppliers.filter(premiumSupplier => {
      return premiumSupplier.supplier !== value.supplier;
    });

    this.props.setFieldValue('primaryPreviousSuppliers', reducedValues);
  }

  handlePrimaryPreviousSuppliersEdit(oldValue, value) {
    const updatedValues = this.props.values.primaryPreviousSuppliers.map(premiumSupplier => {
      if (premiumSupplier.supplier === oldValue) {
        const newNameSegment: ISuppliersEdit = {};
        newNameSegment.supplier = value;
        return newNameSegment;
      }
      return premiumSupplier;
    });

    const uniqueValues = updatedValues.filter((entity, index, self) => {
      return self.findIndex(item => item.supplier === entity.supplier) === index;
    });

    this.props.setFieldValue('primaryPreviousSuppliers', uniqueValues);
  }

  handleStartDateChange(value) {
    const stringDate = moment(value, 'DD.MM.YYYY', true).isValid() ? moment(value, 'DD.MM.YYYY', true) : null;
    this.props.setFieldValue('startDate', stringDate);
  }

  handleStartDateFocus(value) {
    this.setState({ startDateFocused: value.focused });
  }

  handleStartDateTouched() {
    this.props.setFieldTouched('startDate', true);
  }

  handleCancel() {
    history.goBack();
  }

  handleDiscard() {
    history.goBack();
  }

  render() {
    const {
      values,
      errors,
      touched,
      handleChange,
      handleBlur,
      handleSubmit,
    } = this.props;

    return <form noValidate onSubmit={handleSubmit}>
      <Grid>
        <Grid.Body>

          <Grid.Row>
            <Grid.Cell>
              <Field
                required
                type="text"
                name="name"
                label="Name"
                placeholder="Name..."
                value={values.name}
                onChange={handleChange}
                onBlur={handleBlur}
                disabled={this.props.isLoading}
                error={
                  touched.name &&
                  errors.name
                }
              />
            </Grid.Cell>
            <Grid.Cell />
          </Grid.Row>

          <Grid.Row>
            <Grid.Cell>

              <Grid.Row>
                <Grid.Cell>
                  <Number
                    integer
                    positiveOnly
                    step={1}
                    name="numberOfDivisions"
                    label="Number of Divisions"
                    placeholder="Number of Divisions..."
                    value={values.numberOfDivisions}
                    onChange={value => this.props.setFieldValue('numberOfDivisions', value)}
                    onBlur={handleBlur}
                    disabled={this.props.isLoading}
                    error={
                      touched.numberOfDivisions &&
                      errors.numberOfDivisions
                    }
                  />
                </Grid.Cell>
                <Grid.Cell>
                  <Number
                    integer
                    positiveOnly
                    step={1}
                    name="numberOfLocations"
                    label="Number of Locations "
                    placeholder="Number of Locations ..."
                    value={values.numberOfLocations}
                    onChange={value => this.props.setFieldValue('numberOfLocations', value)}
                    onBlur={handleBlur}
                    disabled={this.props.isLoading}
                    error={
                      touched.numberOfLocations &&
                      errors.numberOfLocations
                    }
                  />
                </Grid.Cell>
              </Grid.Row>

            </Grid.Cell>
          </Grid.Row>

          <Grid.Row>
            <Grid.Cell>

              <Grid.Row>
                <Grid.Cell>
                  <Number
                    integer
                    positiveOnly
                    step={1}
                    name="numberOfEmployees"
                    label="Number of Employees "
                    placeholder="Number of Employees ..."
                    value={values.numberOfEmployees}
                    onChange={value => this.props.setFieldValue('numberOfEmployees', value)}
                    onBlur={handleBlur}
                    disabled={this.props.isLoading}
                    error={
                      touched.numberOfEmployees &&
                      errors.numberOfEmployees
                    }
                  />
                </Grid.Cell>
                <Grid.Cell>
                  <Number
                    integer
                    positiveOnly
                    step={1}
                    name="numberOfPhysicians"
                    label="Number of Physicians"
                    placeholder="Number of Physicians..."
                    value={values.numberOfPhysicians}
                    onChange={value => this.props.setFieldValue('numberOfPhysicians', value)}
                    onBlur={handleBlur}
                    disabled={this.props.isLoading}
                    error={
                      touched.numberOfPhysicians &&
                      errors.numberOfPhysicians
                    }
                  />
                </Grid.Cell>
              </Grid.Row>

            </Grid.Cell>
          </Grid.Row>

          <Grid.Row>
            <Grid.Cell>
              <Select
                async
                autoload
                required
                name="medicalSpeciality"
                label="Medical Specialty"
                placeholder="Medical Specialty..."
                labelKey="displayValue"
                valueKey="id"
                value={values.medicalSpeciality}
                loadOptions={this.loadMedicalSpeciality}
                onChange={this.handleMedicalSpecialitySelect}
                disabled={this.props.isLoading}
                error={
                  touched.medicalSpeciality &&
                  errors.medicalSpeciality as string
                }
              />
            </Grid.Cell>
            <Grid.Cell />
          </Grid.Row>

          <Grid.Row>
            <Grid.Cell>
              <Number
                positiveOnly
                step={1}
                type="text"
                name="historicalAnnualSpend"
                label="Historical Annual"
                placeholder="Historical Annual..."
                value={values.historicalAnnualSpend}
                onChange={value => this.props.setFieldValue('historicalAnnualSpend', value)}
                onBlur={handleBlur}
                disabled={this.props.isLoading}
                error={
                  touched.historicalAnnualSpend &&
                  errors.historicalAnnualSpend
                }
              />
            </Grid.Cell>
            <Grid.Cell />
          </Grid.Row>

          <Grid.Row>
            <Grid.Cell>
              <Select
                async
                autoload
                name="scManager"
                label="Assigned Supply Chain Manager"
                placeholder="Select a manager.."
                labelKey="displayValue"
                valueKey="id"
                value={values.scManager}
                loadOptions={this.loadSupplyChainManagers}
                onChange={this.handleSupplyChainManagerSelect}
                disabled={this.props.isLoading}
                error={
                  touched.scManager &&
                  errors.scManager as string
                }
              />
            </Grid.Cell>
            <Grid.Cell />
          </Grid.Row>

          <Grid.Row>
            <Grid.Cell>
              <Chips
                required
                name="previousSuppliers"
                label="Previous Suppliers"
                placeholder="Type here..."
                value={values.primaryPreviousSuppliers}
                onChange={this.handlePrimaryPreviousSuppliersAdd}
                onDelete={this.handlePrimaryPreviousSuppliersDelete}
                onEdit={this.handlePrimaryPreviousSuppliersEdit}
                valueKey="supplier"
                disabled={this.props.isLoading}
                error={
                  touched.primaryPreviousSuppliers &&
                  errors.primaryPreviousSuppliers as any as string
                }
              />
            </Grid.Cell>
          </Grid.Row>

          <Grid.Row>
            <Grid.Cell>
              <DatePicker
                id="startDate"
                label="Start Date"
                required
                date={values.startDate && moment(values.startDate)}
                focused={this.state.startDateFocused}
                isOutsideRange={() => false}
                onDateChange={this.handleStartDateChange}
                onFocusChange={this.handleStartDateFocus}
                onClose={this.handleStartDateTouched}
                disabled={this.props.isLoading}
                error={
                  touched.startDate &&
                  errors.startDate as any as string
                }
              />
            </Grid.Cell>
            <Grid.Cell />
          </Grid.Row>

          <Grid.Row>
            <Grid.Cell>
              <Textarea
                required
                type="text"
                name="meetingSchedule"
                label="Required Meeting Schedule"
                placeholder="Required Meeting Schedule..."
                value={values.meetingSchedule}
                onChange={handleChange}
                onBlur={handleBlur}
                disabled={this.props.isLoading}
                error={
                  touched.meetingSchedule &&
                  errors.meetingSchedule
                }
              />
            </Grid.Cell>
          </Grid.Row>

          <Grid.Row>
            <Grid.Cell>
              <Textarea
                required
                type="text"
                name="orderingRestriction"
                label="Ordering Restrictions"
                placeholder="Ordering Restrictions..."
                value={values.orderingRestriction}
                onChange={handleChange}
                onBlur={handleBlur}
                disabled={this.props.isLoading}
                error={
                  touched.orderingRestriction &&
                  errors.orderingRestriction
                }
              />
            </Grid.Cell>
          </Grid.Row>

          <Grid.Row>
            <Grid.Cell>
              <Textarea
                required
                type="text"
                name="approvalRestriction"
                label="Order Approval Restrictions"
                placeholder="Order Approval Restrictions..."
                value={values.approvalRestriction}
                onChange={handleChange}
                onBlur={handleBlur}
                disabled={this.props.isLoading}
                error={
                  touched.approvalRestriction &&
                  errors.approvalRestriction
                }
              />
            </Grid.Cell>
          </Grid.Row>

        </Grid.Body>
        <Grid.Footer>
          <Grid.FooterElement />
          <Grid.FooterElement>
            {
              this.props.isNew ?
                <Link.Button
                  primary
                  onClick={this.handleCancel}
                  scale="lg"
                >
                  Cancel
                </Link.Button> :
                <Link.Button
                  primary
                  onClick={this.handleDiscard}
                  scale="lg"
                >
                  Discard
                </Link.Button>
            }
            <Button
              type="submit"
              disabled={this.props.isLoading}
              scale="lg"
              preloader={this.props.isLoading}
            >
              {this.props.isNew ? 'Submit' : 'Save'}
            </Button>
          </Grid.FooterElement>
        </Grid.Footer>
      </Grid>
    </form>;
  }
}

export default withMenu(Form, false);

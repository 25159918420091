import styled from 'styled-components';
import { Select } from './';

export default styled.div`
  ${props => {
    let {
      global,
      main,
    } = props.theme;
    return `
      ${global}
      background: ${main};
      width: 100%;
      .${Select.styledComponentId}{
        flex: 0 0 300px;
        margin: 0 20px 0 0;
        .Select-control{
          border-width: 2px;
          height: 36px;
        }
        .Select-value{
          line-height: 36px;
        }
        .Select-menu-outer{
          border-width: 2px;
          margin-top: -2px;
        }
      }
    `;
  }}
`;
/**
 * helper functions for client catalog editor
 */

import immer from "immer";
import { SimpleMap } from "../_types/common";
import {
  TreeNode,
  IExGetCategoriesDto,
  IModCategoryProductDto,
  SelectedNode,
  RemoveCategoryNode,
  RemoveSelectionNode,
  NodePathType,
  IObjectWithName
} from "../_types/clientCatalogEditor";
import { CategoryProductsDto } from "../service-proxies";

export function getTreeNodeByPath(
  treeArray: TreeNode[],
  path = [0],
  childrenKey: string = "childCategories"
): TreeNode {
  let i;
  let newLevel: TreeNode[] = treeArray;

  for (i = 0; i < path.length - 1; i++) {
    const node = newLevel[path[i]];
    if (!node) {
      return undefined;
    }
    newLevel = node[childrenKey];
    if (!newLevel) {
      return undefined;
    }
  }
  return newLevel[path[i]];
}

export function collectChildrenInfoRecursive(
  path: number[],
  level: TreeNode[],
  list: Array<number | string>,
  subcategories: number[],
  categoriesToLoad: Array<{ categoryId: number; path: number[] }>
): void {
  level.forEach((node, index) => {
    if (
      !(node as IModCategoryProductDto).noProducts &&
      !(node as IModCategoryProductDto).preloader
    ) {
      list.push(node.id);
    }
    if (
      categoriesToLoad &&
      (node as IExGetCategoriesDto).childCategories &&
      (node as IExGetCategoriesDto).childCategories.length === 0 &&
      !(node as IExGetCategoriesDto).productsLoaded
    ) {
      categoriesToLoad.push({
        categoryId: node.id as number,
        path: [...path, index]
      });
    }
    if (subcategories && (node as IExGetCategoriesDto).childCategories) {
      subcategories.push(node.id as number);
    }
    if (
      (node as IExGetCategoriesDto).childCategories &&
      (node as IExGetCategoriesDto).childCategories.length
    ) {
      collectChildrenInfoRecursive(
        [...path, index],
        (node as IExGetCategoriesDto).childCategories,
        list,
        subcategories,
        categoriesToLoad
      );
    }
  });
}

export function extractSelectionTreeFromCatalog(
  catalog: TreeNode[],
  selectionMap: SimpleMap,
  parentNode: IModCategoryProductDto,
  selectionTree: SelectedNode[]
): number {
  let counter = 0;

  catalog.forEach(node => {
    if (selectionMap[node.id]) {
      const nodeCopy: SelectedNode = {
        id: node.id,
        name: node.name,
        sku: node.sku
      };
      if ((node as IModCategoryProductDto).childCategories) {
        nodeCopy.children = [];
      }

      selectionTree.push(nodeCopy);

      counter++;

      if (
        (node as IModCategoryProductDto).childCategories &&
        (node as IModCategoryProductDto).childCategories.length
      ) {
        counter += extractSelectionTreeFromCatalog(
          (node as IModCategoryProductDto).childCategories,
          selectionMap,
          node as IModCategoryProductDto,
          nodeCopy.children
        );
      }
    } else if (
      !parentNode &&
      (node as IModCategoryProductDto).childCategories &&
      (node as IModCategoryProductDto).childCategories.length
    ) {
      counter += extractSelectionTreeFromCatalog(
        (node as IModCategoryProductDto).childCategories,
        selectionMap,
        parentNode,
        selectionTree
      );
    }
  });

  return counter;
}

export function extractSelectionForRemoving(
  catalog: TreeNode[],
  selectionMap: SimpleMap,
  parentNode: IExGetCategoriesDto,
  selectionTree: RemoveSelectionNode[]
): void {
  const productIds: number[] = [];

  catalog.forEach(node => {
    if (selectionMap[node.id]) {
      if (typeof node.id === "string" && node.id.startsWith("product")) {
        const productId = node.id.split("-")[1];
        productIds.push(parseInt(productId, 10));
      } else {
        const nodeCopy: RemoveCategoryNode = {
          id: (node as IExGetCategoriesDto).id,
          name: node.name
        };
        if ((node as IExGetCategoriesDto).childCategories) {
          nodeCopy.children = [];
        }

        selectionTree.push(nodeCopy);
      }
    } else if (
      (node as IExGetCategoriesDto).childCategories &&
      (node as IExGetCategoriesDto).childCategories.length
    ) {
      extractSelectionForRemoving(
        (node as IExGetCategoriesDto).childCategories,
        selectionMap,
        node as IExGetCategoriesDto,
        selectionTree
      );
    }
  });

  if (productIds.length) {
    selectionTree.push({
      categoryId: parentNode ? parentNode.id : null,
      categoryName: parentNode ? parentNode.name : null,
      productIds
    });
  }
}

export function getSelectionAndExpandedMapFromTree(
  tree: TreeNode[],
  selectedMap: SimpleMap,
  expandedMap: SimpleMap
): void {
  tree.forEach(node => {
    selectedMap[node.id] = true;
    if (!node.id.toString().startsWith("product")) {
      expandedMap[node.id] = true;
    }
    if (
      (node as IExGetCategoriesDto).childCategories &&
      (node as IExGetCategoriesDto).childCategories.length
    ) {
      getSelectionAndExpandedMapFromTree(
        (node as IExGetCategoriesDto).childCategories,
        selectedMap,
        expandedMap
      );
    }
  });
}

export function findNodePath(
  tree: TreeNode[],
  nodeId: number | string,
  path: number[] = []
): NodePathType | null {
  const nodeIndex = tree.findIndex(n => n.id === nodeId);
  if (nodeIndex !== -1) {
    return { node: tree[nodeIndex], path: path.concat(nodeIndex) };
  } else {
    for (let i = 0; i < tree.length; i++) {
      const n = tree[i] as IExGetCategoriesDto;
      if (n.childCategories && n.childCategories.length) {
        const newPath = findNodePath(n.childCategories, nodeId, path.concat(i));
        if (newPath !== null) {
          return newPath;
        }
      }
    }
  }
  return null;
}

export function removeSelectedFromTree(
  tree: TreeNode[],
  selectedMap: SimpleMap
): TreeNode[] {
  const newTree = immer(tree, draft => {
    removeRecursive(draft, selectedMap);
  });

  return newTree;
}

function removeRecursive(tree: TreeNode[], selectedMap: SimpleMap) {
  if (tree.length === 0) {
    return;
  }

  let isAllRemoved = true;

  for (let i = tree.length - 1; i >= 0; i--) {
    const node = tree[i];
    if (selectedMap[node.id]) {
      tree.splice(i, 1);
    } else {
      isAllRemoved = false;
      if (
        (node as IExGetCategoriesDto).childCategories &&
        (node as IExGetCategoriesDto).childCategories.length
      ) {
        removeRecursive(
          (node as IExGetCategoriesDto).childCategories,
          selectedMap
        );
      }
    }
  }
  if (isAllRemoved) {
    tree.push({
      id: `noproducts`,
      name: "No products",
      noProducts: true
    } as IModCategoryProductDto);
  }
}

export function sortNameItems(items: IObjectWithName[]): IObjectWithName[] {
  return items.sort((a, b) => {
    const aName = a.name.toLowerCase();
    const bName = b.name.toLowerCase();
    if (aName < bName) {
      return -1;
    }
    if (aName > bName) {
      return 1;
    }
    return 0;
  });
}

export function cleanMovedProducts(
  tree: TreeNode[],
  movedProducts: CategoryProductsDto[]
) {
  const movedMap: { [id: number]: number[] } = {};

  if (movedProducts.length) {
    movedProducts.forEach(item => {
      if (!movedMap[item.id]) {
        movedMap[item.id] = item.productIds;
      } else {
        movedMap[item.id] = movedMap[item.id].concat(item.productIds);
      }
    });
  }

  Object.keys(movedMap).forEach(categoryId => {
    const nodePath = findNodePath(tree, parseInt(categoryId, 10));
    if (nodePath) {
      if (nodePath.node) {
        if (nodePath.node.childCategories && nodePath.node.childCategories) {
          let newChildren = nodePath.node.childCategories.filter(
            (node: TreeNode) => {
              if (
                typeof node.id === "string" &&
                node.id.startsWith("product")
              ) {
                const productId = parseInt(node.id.split("-")[1], 10);
                return !movedMap[categoryId].some(id => id === productId);
              } else {
                return true;
              }
            }
          );

          if (
            nodePath.node.childCategories.length > 0 &&
            newChildren.length === 0
          ) {
            newChildren = [
              {
                id: "no-products",
                name: "No products",
                noProducts: true
              } as TreeNode
            ];
          }
          nodePath.node.childCategories = newChildren;
        }
      }
    }
  });
}

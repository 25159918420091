import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import Link from './Link';
import { SortableContainer, SortableElement, SortableHandle } from 'react-sortable-hoc';

import {
  XIcon,
  ReorderIcon,
} from '../assets/icons';

import * as Skeleton from './Skeleton';

export const Label = styled.label`
  ${ ({ theme, ...props }) => {

    let {
      global,
      warning,
      primary,
      fs_md,
    } = theme;

    let color = !props.error ?
      primary :
      warning;

    return `
        ${global}
        color: ${color};
        font-size: ${fs_md};
        padding: 0 0 0 27px; 
      `;
  }}
`;

export const UnorderedBody = styled.ul`
  ${({ theme, ...props }) => {

    let {
      global,
    } = theme;

    return `
      ${global}
      padding: 0 0 0 27px;
      margin: 7px 0 3px;
      & li{
        position: relative;
        &:before{
          height: 100%;
          display: flex;
          align-items: center;
          content: '●';
          position: absolute;
          left: -27px;
        } 
      }
    `;
  }}
`;

export const OrderedBody = UnorderedBody.withComponent('ol').extend`
  counter-reset: ol;
  & li{
    position: relative;
    &:before{
      height: 100%;
      display: flex;
      align-items: center;
      counter-increment: ol;
      content: counter(ol) '.'; 
      position: absolute;
      left: -27px;
    } 
  }
`;

export const Item = styled.li`
  ${({ theme, ...props }) => {

    let {
      global,
      fs_md,
      primary,
    } = theme;

    return `
      ${global}
      display: flex;
      font-size: ${fs_md};
      color: ${primary};
      margin: 0 0 20px;
      list-style: none;
      > * {
        flex: 1 1 100%;
      }
    `;
  }}
`;

export const SortableItem = SortableElement(Item);

export const Toolbar = styled.div`
  ${ ({ theme }) => {

    let {
      global,
    } = theme;

    return `
        ${global}
        padding: 0 0 0 27px; 
      `;
  }}
`;

export const Error = styled.div`
  ${ ({ theme }) => {

    let {
      global,
      warning,
      fs_xs,
    } = theme;

    return `
        ${global}
        min-height: 19px;
        margin: 0 0 3px;
        font-weight: 300;
        color: ${warning};
        font-size: ${fs_xs}; 
        padding: 0 0 0 27px; 
      `;
  }}
`;

export const CloseButton = styled(({ className, onClick, index }) => {
  function handleClick() {
    if (onClick) {
      onClick(index);
    }
  }
  return <button type="button" onClick={handleClick} className={className}>
    <XIcon />
  </button>;
})`
  ${ () => {
    return `
      padding: 0 5px;
      line-height: 0;
      margin-left: 13px;
      border: 0;
      flex: 0 0 auto;

      background-color: transparent;
      cursor: pointer;
      outline: none;

      > i {
        width: 10px;
        height: 10px;
      }
    `;
  }}
`;

export const DragHandle = SortableHandle(styled(({ className }) =>
  <span className={className}><ReorderIcon /></span>
)`
  flex: 0 0 auto;
  display: flex;
  align-items: center;
  cursor: move;
`);

export const Preloader = styled(({ className }) => {
  return <UnorderedBody className={className}>
    <Item>
      <Skeleton.Line size="sm" />
      <Skeleton.Line size="md" />
    </Item>
    <Item>
      <Skeleton.Line size="md" />
    </Item>
  </UnorderedBody>;
})`
  ${Item}{
    display: block;
    ${Skeleton.Line}{
      margin: 0 20px 0 0;
    }
  }
`;

export default styled(class extends React.Component {

  static propTypes = {
    sortable: PropTypes.bool,
    className: PropTypes.string.isRequired,
    renderComponent: PropTypes.func,
    onAddItem: PropTypes.func,
    value: PropTypes.array,
    label: PropTypes.string,
    error: PropTypes.string,
    required: PropTypes.bool,
    ordered: PropTypes.bool,
    increasable: PropTypes.bool,
    itemName: PropTypes.string,
    onItemDelete: PropTypes.func,
    deletable: PropTypes.bool,
    onSort: PropTypes.func,
    isLoading: PropTypes.bool,
    disabled: PropTypes.bool,
  };

  constructor(props) {
    super(props);

    this.handleAddItem = this.handleAddItem.bind(this);
  }

  handleAddItem() {
    if (!this.props.disabled) {
      if (this.props.onAddItem) {
        this.props.onAddItem();
      }
    }
  }

  render() {

    let {
      className,
      value,
      label,
      error,
      required,
      renderComponent,
      ordered,
      increasable,
      itemName,
      onItemDelete,
      deletable,
      sortable,
      onSort,
      isLoading,
      disabled,
    } = this.props;

    let Body = ordered ?
      OrderedBody :
      UnorderedBody;

    let SortableBody = SortableContainer(Body);

    return (<div className={className}>
      {
        label &&
        <Label
          error={error}
        >
          {`${label} ${required ? ' * ' : ''}`}
        </Label>
      }
      {isLoading && <Preloader />}
      {
        !isLoading && (
          sortable ?
            <SortableBody onSortEnd={onSort} useDragHandle>
              {
                !!value &&
                !!value.length &&
                value.map((item, index) => {
                  return <SortableItem index={index} key={index} >
                    {renderComponent(item, index)}
                    <DragHandle />
                    {
                      deletable &&
                      <CloseButton index={index} onClick={onItemDelete} />
                    }
                  </SortableItem>;
                })
              }
            </SortableBody> :
            <Body>
              {
                !!value &&
                !!value.length &&
                value.map((item, index) => {
                  return <Item key={index}>
                    {renderComponent(item, index)}
                    {
                      deletable &&
                      <CloseButton index={index} onClick={onItemDelete} />
                    }
                  </Item>;
                })
              }
            </Body>
        )
      }

      <Toolbar>
        {
          increasable &&
          <Link.Button
            disabled={disabled}
            onClick={this.handleAddItem}
          >
            {`Add ${itemName ? itemName : 'Another'}`}
          </Link.Button>
        }
      </Toolbar>

      <Error>
        {error}
      </Error>

    </div>);

  }
})``;

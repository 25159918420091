import React from 'react';
import styled from 'styled-components';

const OkIcon = props => {
  return(
    <i {...props}>
      <svg viewBox="3157 2070 20 20" width="20" height="20">
        <path d=" M 3167 2070 C 3161.486 2070 3157 2074.486 3157 2080 C 3157 2085.514 3161.486 2090 3167 2090 C 3172.514 2090 3177 2085.514 3177 2080 C 3177 2074.486 3172.514 2070 3167 2070 Z  M 3167 2071.539 C 3171.681 2071.539 3175.461 2075.319 3175.461 2080 C 3175.461 2084.681 3171.681 2088.461 3167 2088.461 C 3162.319 2088.461 3158.539 2084.681 3158.539 2080 C 3158.539 2075.319 3162.319 2071.539 3167 2071.539 Z  M 3171.832 2076.37 L 3166.231 2081.971 L 3162.938 2078.678 L 3161.832 2079.784 L 3165.678 2083.63 L 3166.231 2084.159 L 3166.784 2083.63 L 3172.938 2077.476 L 3171.832 2076.37 Z "/>
      </svg>
    </i>
  );
};

export default styled(OkIcon)`
  ${ props => `
    width: 21px;
    height: 21px;
    display: inline-flex;
    justify-content: center;

    svg{
      path{
        fill: ${props.theme.success}; 
      }
    }
  `}
`;

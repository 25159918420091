import { vendorServiceProxy } from '../servicePool';

import {
  ListResultDtoOfSearchOutputDto,
  PagedResultDtoOfVendorListDto,
  EditVendorDto,
  EntityDtoOfInt64,
  CreateVendorDto,
  UpdateVendorDto,
} from '../service-proxies';

export function searchVendors(filter: string = ''): Promise<ListResultDtoOfSearchOutputDto> {
  return vendorServiceProxy.searchVendors({
    maxResultCount: 10,
    filter
  });
}

export function getVendors(
  maxResultCount: number,
  skipCount: number,
  filter: string,
  sorting: string,
  isArchived: boolean,
): Promise<PagedResultDtoOfVendorListDto> {
  return vendorServiceProxy.getVendors(filter, isArchived, sorting, skipCount, maxResultCount);
}

export function archiveVendor(id: number): Promise<void> {
  return vendorServiceProxy.archiveVendor({ id });
}

export function unArchiveVendor(id: number): Promise<void> {
  return vendorServiceProxy.unarchiveVendor({ id });
}

export function getVendorForEdit(id: number): Promise<EditVendorDto> {
  return vendorServiceProxy.getVendorForEdit(id);
}

export function createVendor(input: CreateVendorDto): Promise<EntityDtoOfInt64> {
  return vendorServiceProxy.createVendor(input);
}

export function updateVendor(input: UpdateVendorDto): Promise<EntityDtoOfInt64> {
  return vendorServiceProxy.updateVendor(input);
}

export interface ITheme {
  [propName: string]: string;
}

export interface IThemeProps {
  theme?: ITheme;
  styledComponentId?: any;
}

const theme: ITheme = {
  global: `
    font-family: Montserrat, sans-serif;
    box-sizing: border-box;
  `,
  default: '#F75000',
  active: '#FBA77F',
  defaultRGB: '247,80,0',
  primary: '#424242',
  primaryRGB: '66,66,66',
  main: '#FFFFFF',
  bg: 'f2f2f2',
  warning: '#F71000',
  success: '#46b654',

  lightest: '#F9F9F9',
  lighter: '#E0E0E0',
  light: '#CCCCCC',
  dark: '#B7B7B7',
  darker: '#959595',
  darkest: '#696969',

  fs_xxxl: '28px',
  fs_xxl: '20px',
  fs_xl: '18px',
  fs_lg: '16px',
  fs_md: '14px',
  fs_sm: '13px',
  fs_xs: '12px',
  fs_xxs: '11px',
};

export default theme;

import React from 'react';
import styled from 'styled-components';

export default styled(props => {
  return (
    <i {...props}>
      <svg viewBox="1077 20832 20 4" width="20" height="4">
        <path d=" M 1077 20834 C 1077 20835.105 1077.895 20836 1079 20836 C 1080.105 20836 1081 20835.105 1081 20834 C 1081 20832.895 1080.105 20832 1079 20832 C 1077.895 20832 1077 20832.895 1077 20834 Z  M 1085 20834 C 1085 20835.105 1085.895 20836 1087 20836 C 1088.105 20836 1089 20835.105 1089 20834 C 1089 20832.895 1088.105 20832 1087 20832 C 1085.895 20832 1085 20832.895 1085 20834 Z  M 1093 20834 C 1093 20835.105 1093.895 20836 1095 20836 C 1096.105 20836 1097 20835.105 1097 20834 C 1097 20832.895 1096.105 20832 1095 20832 C 1093.895 20832 1093 20832.895 1093 20834 Z " />
      </svg>
    </i>
  );
})`
  ${ props => `
    width: 21px;
    height: 21px;
    display: inline-flex;
    justify-content: center;
    align-items: center;

    svg{
      path{
        fill: ${props.theme.primary}; 
      }
    }
  `}
`;

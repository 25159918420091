import { types } from '../_actionTypes/divisions';
import { types as typesNotification } from '../_actionTypes/notification';
import { divisionServiceProxy } from '../servicePool';
import {
  getSwaggerErrorResponse,
} from '../_utils/errorHandling';

export function getAll(id, perPage, skip, filter = '') {
  return function (dispatch) {
    dispatch({
      type: types.DIVISION_GET_ALL,
      listStatus: 'loading',
      status: '',
    });
    divisionServiceProxy.getDivisionsByClientId(id, perPage, skip, filter)
      .then(data => {
        const {
          items,
          totalCount,
        } = data;
        dispatch({
          type: types.DIVISION_GET_ALL,
          divisions: items,
          totalCount,
          listStatus: 'loaded',
        });
      })
      .catch(e => {
        dispatch({
          type: types.DIVISION_GET_ALL,
          listStatus: 'error',
        });
        dispatch({
          type: typesNotification.NOTIFICATION_NOTIFY,
          message: getSwaggerErrorResponse(e) && getSwaggerErrorResponse(e).error.message,
          messageType: 'error',
        });
      });
  };
}

export function get(id) {
  return function (dispatch) {
    dispatch({
      type: types.DIVISION_GET,
      status: 'loading',
    });
    divisionServiceProxy.getDivisionForEdit(id)
      .then(division => {
        dispatch({
          type: types.DIVISION_GET,
          division,
          status: 'loaded',
        });
      })
      .catch(e => {
        dispatch({
          type: types.DIVISION_GET,
          status: 'error',
        });
        dispatch({
          type: typesNotification.NOTIFICATION_NOTIFY,
          message: getSwaggerErrorResponse(e).error.message,
          messageType: 'error',
        });
      });
  };
}

export function create(data) {
  return function (dispatch) {
    dispatch({
      type: types.DIVISION_CREATE,
      status: 'loading',
    });
    divisionServiceProxy.createDivision(data)
      .then(() => {
        dispatch({
          type: types.DIVISION_CREATE,
          status: 'loaded',
        });
      })
      .catch(e => {
        dispatch({
          type: types.DIVISION_CREATE,
          status: 'error',
        });
        dispatch({
          type: typesNotification.NOTIFICATION_NOTIFY,
          message: getSwaggerErrorResponse(e).error.message,
          messageType: 'error',
        });
      });
  };
}

export function update(data) {
  return function (dispatch) {
    dispatch({
      type: types.DIVISION_UPDATE,
      status: 'saving',
    });
    divisionServiceProxy.updateDivision(data)
      .then(() => {
        dispatch({
          type: types.DIVISION_UPDATE,
          status: 'saved',
        });
      })
      .catch(e => {
        dispatch({
          type: types.DIVISION_UPDATE,
          status: 'error',
        });
        dispatch({
          type: typesNotification.NOTIFICATION_NOTIFY,
          message: getSwaggerErrorResponse(e).error.message,
          messageType: 'error',
        });
      });
  };
}

export function getName(id) {
  return function (dispatch) {
    divisionServiceProxy.searchDivisionById(id)
      .then(data => {
        const divisionName = data.displayValue;
        dispatch({
          type: types.DIVISION_GET_NAME,
          divisionName,
        });
      })
      .catch(e => {
        dispatch({
          type: types.DIVISION_GET_NAME,
          listStatus: 'error',
        });
        dispatch({
          type: typesNotification.NOTIFICATION_NOTIFY,
          message: getSwaggerErrorResponse(e).error.message,
          messageType: 'error',
        });
      });
  };
}

export function remove(id) {
  return function (dispatch) {
    return divisionServiceProxy.deleteDivision(id)
      .then(() => {
        dispatch({
          type: types.DIVISION_REMOVE,
          listStatus: 'loaded',
        });
      })
      .catch(e => {
        dispatch({
          type: types.DIVISION_REMOVE,
          listStatus: 'error',
        });
        dispatch({
          type: typesNotification.NOTIFICATION_NOTIFY,
          message: getSwaggerErrorResponse(e).error.message,
          messageType: 'error',
        });
      });
  };
}

export function changePage(perPage, skip) {
  return {
    type: types.DIVISION_CHANGE_PAGE,
    skip,
    perPage,
  };
}

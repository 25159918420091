import * as React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { history } from '../../store';

import {
  MainContainer,
} from '../../components';

import * as actionsCatalog from '../../_actions/category';
import * as actionsProduct from '../../_actions/products';
import * as actionsNotification from '../../_actions/notification';
import * as actionsLayout from '../../_actions/layout';

import Form from './Form';

import { updateTree, fillTree } from '../../_utils/data';

import * as apiProducts from '../../_api/products';

import { apiUrl } from '../../_constants/system';
import { createStructuredSelector } from 'reselect';
import {
  EditProductDto,
  SearchOutputDto,
  SearchProductAttributeDto,
  SimilarProductDto,
} from '../../service-proxies';
import { IGlobalStore } from '../../_reducers/reducers';
import { RouteComponentProps } from 'react-router';
import { Omit } from '../../_types/common';
import { CreateProductClass, UpdateProductClass } from './EditPoductClasses';

const REQUIRED = 'required!';
const EXCEED_100_LIMIT = 'must be shorter than 100 characters';
const EXCEED_80_LIMIT = 'must be shorter than 80 characters';
const EXCEED_50_LIMIT = 'must be shorter than 50 characters';
const GREATER_THAN_0 = 'must be greater than or equal to 0';

const productValidationSchema = Yup.object().shape({
  nameSegments: Yup.array().min(1, 'product name must have at least 1 segment').required(REQUIRED),
  description: Yup.string(),
  categoryId: Yup.number().typeError(REQUIRED).required(REQUIRED),
  middleSku: Yup.string().max(50, 'must be less than 50 characters').required(REQUIRED),
  skuPrefix: Yup.object().shape({
    displayValue: Yup.string().max(50, 'must be less than 50 characters'),
    id: Yup.number(),
  }).typeError(REQUIRED),
  skuSuffix: Yup.object().shape({
    displayValue: Yup.string(),
    id: Yup.number(),
  }).typeError(REQUIRED),
  pricesCache: Yup.lazy(some => {
    const pricesShape = {};
    if (some) {
      Object.keys(some).forEach(key => {
        pricesShape[key] = Yup.string().matches(/^[0-9]+(\.[0-9][0-9]?)?$/, `assigned price for catalog with id:${key} must be a number with two decimals`).transform((cv, ov) => {
          return ov === '' ? undefined : cv;
        });
      });
      return Yup.object().shape(pricesShape);
    } else {
      return Yup.mixed().nullable();
    }
  }),
  packageType: Yup.object().shape({
    displayValue: Yup.string(),
    id: Yup.number(),
  }).typeError(REQUIRED),
  deliveryTime: Yup.string().trim().max(50, EXCEED_50_LIMIT).required(REQUIRED),
  hazmatCharge: Yup.number().integer('must be a whole number').min(0, GREATER_THAN_0).required(REQUIRED),
  manufacturer: Yup.string().trim().max(100, EXCEED_100_LIMIT).required(REQUIRED),
  manufacturerNumber: Yup.string().trim().max(100, EXCEED_100_LIMIT).required(REQUIRED),
  vendor: Yup.object().shape({
    displayValue: Yup.string().trim().max(80, EXCEED_80_LIMIT).required(REQUIRED),
    id: Yup.number(),
  }).typeError(REQUIRED),
  itemNumber: Yup.string().trim()
    .required(REQUIRED)
    .max(48, 'must be shorter than 49 characters')
    .test('itemNumber', 'must be alphanumeric value', value => /^([a-zA-Z0-9]){1,48}$/g.test(value)),
  vendorPriceDate: Yup.string().trim().required(REQUIRED).typeError('invalid date format!'),
  vendorPrice: Yup.number().min(0, GREATER_THAN_0).required(REQUIRED),

  isSterile: Yup.boolean().required(REQUIRED).typeError(REQUIRED),
  latexStatus: Yup.number().required(REQUIRED).typeError(REQUIRED),
  stockStatus: Yup.number().required(REQUIRED).typeError(REQUIRED),
  productSectionId: Yup.number().required(REQUIRED).typeError(REQUIRED),
  quantityPerUnitOfMeasure: Yup.number().lessThan(1000000000.00001, 'must be not greater than 1000000000'),
});


interface IRouteParams {
  id: string;
  serviceSlug?: string;
  categoryId?: string;
  subCategoryId?: string;
}

interface IEditProps extends IConnectedProps, IConnectedActions, RouteComponentProps<IRouteParams> {

}

type TabsValues = 'details' | 'prices';

export type TabsOptionsType = Array<{ value: TabsValues, label: string }>;

type ExtraProps = {
  tabsOptions: TabsOptionsType,
  tab: TabsValues,
  attributes: any[],
  similarProducts: Array<(SimilarProductDto & { readOnly: boolean })>,
  categories: any[],
  currentImage: number,
  length: string | number,
  width: string | number,
  height: string | number,
  quantityPerUnitOfMeasure: string | number,
  attributesStatus?: string,
  productId: number | string,
  similarProductsStatus?: string,
  lightboxIsOpen: boolean,
  productSectionId: number,
  deliveryTime: string,
  hazmatCharge: number | '',
  manufacturer: string,
  manufacturerNumber: string,
  vendorPriceDate: string,
  itemNumber: string,
  isSterile: boolean| number,
  latexStatus: number,
  stockStatus: number,
  isTaxable: boolean,
  isReturnable: boolean,
  isForm222: boolean,
  isControlledSubstance: boolean,
  isHazardousMaterial: boolean,
};

/**
 * alter original type EditProductDto in order to change type of some fields
 */
export type EditProductType = Omit<Omit<Omit<Omit<Omit<EditProductDto, 'length'>, 'width'>, 'height'>, 'quantityPerUnitOfMeasure'>, 'nameSegments'>
  & { nameSegments?: Array<{ label: string, segment: string }> } & ExtraProps;

interface IEditState {
  productId: number | string;
  attributes: SearchProductAttributeDto[];
  attributesStatus?: string;
  similarProductsStatus?: string;
  similarProducts: SimilarProductDto[];
  skuPrefixId: string;
  skuSuffixId: number;
  dimensionId: number;
  packageTypeId: number;
  categories: any[];
  currentImage: number;
  lightboxIsOpen: boolean;
  tab: TabsValues;
  tabsOptions: TabsOptionsType;
  catalogsStatus: string;
  catalogs: any;
  pricesCache: any;
  pricesCacheSnapshot: any;
  skip: number;
  perPage: number;
  sortBy: string;
  length: string | number;
  width: string | number;
  height: string | number;
  quantityPerUnitOfMeasure: string | number;
  productSection: SearchOutputDto;
  isPriceChanged: boolean;
}

const initialState = {
  nameSegments: [],
  attributes: [],
  similarProducts: [],
  pictures: [],
  skuPrefixId: null,
  middleSku: '',
  skuSuffixId: null,
  description: '',
  glCategoryNumber: '',
  length: '',
  width: '',
  height: '',
  vendorPrice: '',
  quantityPerUnitOfMeasure: '',
  categoryId: null,
  dimensionId: null,
  packageTypeId: null,
  vendor: null,

  skuPrefix: null,
  skuSuffix: null,
  packageType: null,
  dimension: null,

  categories: [],
  currentImage: 0,
  lightboxIsOpen: false,
  tab: 'details' as TabsValues,
  tabsOptions: [
    { value: 'details' as TabsValues, label: 'product details' },
    { value: 'prices' as TabsValues, label: 'Pricing Management' },
  ],
  catalogsStatus: '',
  catalogs: {},
  pricesCache: {},
  pricesCacheSnapshot: {},
  isPathThrough: false,
  skip: 0,
  perPage: 10,
  sortBy: 'clientName ASC',

  deliveryTime: '',
  hazmatCharge: '',
  manufacturer: '',
  manufacturerNumber: '',
  vendorPriceDate: '',
  itemNumber: '',
  latexStatus: null,
  stockStatus: null,
  productSectionId: null,
  productSection: {
    items: [],
    status: '',
  },

  isSterile: null,
  isTaxable: true,
  isReturnable: true,
  isForm222: false,
  controlledSubstance: false,
  isHazardousMaterial: false,

  isPriceChanged: false,
};

class Edit extends React.Component<IEditProps, IEditState & EditProductType> {

  isNew: boolean;

  constructor(props) {
    super(props);

    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleError = this.handleError.bind(this);

    // @ts-expect-error remove ts-ignore
    this.state = {
      ...initialState,
      productId: props.match.params.id
    };

    this.isNew = this.props.match.params.serviceSlug === 'new';

  }

  componentDidMount() {
    this.props.getCategories(undefined);
    if (!this.isNew) {
      this.props.get(this.state.productId);
      this.setState({
        attributesStatus: 'loading',
        similarProductsStatus: 'loading',
      });
    }
  }

  componentDidUpdate(prevProps) {
    if ((prevProps.listStatus === 'loading') && (this.props.listStatus === 'loaded')) {
      const categories = updateTree(this.props.categories, category => {
        const isChildrenEmpty = !category.childCategories.length;
        if (isChildrenEmpty) {
          delete category.childCategories;
        }
        return category;
      }, 'childCategories');

      this.setState({
        categories,
      });
    }

    if (!this.isNew) {
      if ((prevProps.status === 'loading') && (this.props.status === 'loaded')) {
        this.assignProduct();
        this.loadAttributes(this.state.productId);
        this.loadSimilarProducts(this.state.productId);
        this.setState({ productSectionId: this.props.product.productSection ? this.props.product.productSection.id : null });
      }

      if (this.props.status === 'loaded' && (this.props.listStatus === 'loaded')) {
        if ((prevProps.listStatus === 'loading') || (prevProps.status === 'loading')) {
          // @ts-expect-error remove ts-ignore
          const categoryData = fillTree(
            [...this.props.categories],
            [this.props.product.categoryId],
            null,
            null,
            'id',
            'childCategories',
          );
          this.setState({
            categories: categoryData.nodes,
          });
        }
      }
    }

    if (this.props.status === 'saved') {

      history.push(`/master-products${
        this.props.match.params.categoryId ?
          '/' + this.props.match.params.categoryId :
          ''
        }${
        this.props.match.params.subCategoryId ?
          '/' + this.props.match.params.subCategoryId :
          ''
        }`);
    }

  }

  // editing
  assignProduct() {
    const product = this.props.product;
    const nameSegments = this.unMapNameSegments(this.props.product.nameSegments);
    const length = this.props.product.length ?
      this.props.product.length :
      '';
    const width = this.props.product.width ?
      this.props.product.width :
      '';
    const height = this.props.product.height ?
      this.props.product.height :
      '';
    const quantityPerUnitOfMeasure = this.props.product.quantityPerUnitOfMeasure ?
      this.props.product.quantityPerUnitOfMeasure :
      '';
    const pictures = this.unMapPictures(this.props.product.pictures);
    // @ts-expect-error remove ts-ignore
    this.setState({
      ...product,
      nameSegments,
      length,
      width,
      height,
      quantityPerUnitOfMeasure,
      pictures,
    });
  }

  unMapNameSegments(segments) {
    return segments.map(segment => {
      return {
        label: segment,
        segment,
      };
    });
  }

  unMapPictures(pictures) {
    return pictures.map(picture => {
      picture.src = picture.preview || `${apiUrl}${picture.pictureUrl}`;
      return picture;
    });
  }

  loadAttributes(productId) {
    apiProducts.getAttributesForProduct(productId).then(data => {
      const attributes = data.items;
      this.setState({
        attributesStatus: 'loaded',
        attributes,
      });
    })
      .catch(e => this.setState({ attributesStatus: 'error' }));
  }

  loadSimilarProducts(productId) {
    apiProducts.getSimilarProductsForProduct(productId).then(data => {
      const similarProducts = data.items.map((product: SimilarProductDto & { readOnly: boolean }) => {
        product.readOnly = true;
        return product;
      });
      this.setState({
        similarProductsStatus: 'loaded',
        similarProducts,
      });
    })
      .catch(e => this.setState({ similarProductsStatus: 'error' }));
  }

  // creating
  mapNameSegments(segments) {
    return segments.map(segment => {
      return segment.segment;
    });
  }

  mapAttribute(items) {
    return items.map((item, index) => {
      if (item.attributeValue && item.attributeValue.id) {
        return {
          attributeValueId: item.attributeValue.id,
          priority: index,
          includeIntoName: item.include,
        };
      }
      return null;
    }).filter(attribute => {
      return attribute !== null;
    });
  }

  mapRelatedProducts(items) {
    return items.map(item => {
      if (item.id) {
        return item.id;
      }
      return null;
    }).filter(product => {
      return product !== null;
    });
  }

  mapPictures(items) {
    const picturesQueue = items.map((item, index) => {

      let newItem;

      if (item.preview) {
        newItem = {
          fileName: item.name,
          mimeType: item.type,
          priority: index,
          file: [],
        };

        return new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.onerror = () => {
            reader.abort();
            reject(new DOMException("Problem parsing input file."));
          };
          reader.onload = () => {
            // @ts-expect-error remove ts-ignore
            newItem.file = Array.from(new Uint8Array(reader.result));
            resolve(newItem);
          };
          reader.readAsArrayBuffer(item);
        });

      } else {

        newItem = {
          id: item.id,
          priority: index,
        };

        return new Promise((resolve, reject) => {
          resolve(newItem);
        });

      }

    });

    return Promise.all(picturesQueue);
  }

  handleSubmit(values, { setSubmitting, setErrors }) {

    const newValues = Object.assign({}, values);

    newValues.nameSegments = this.mapNameSegments(values.nameSegments);
    newValues.skuPrefixId = (values.skuPrefix && values.skuPrefix.id) || null;
    newValues.skuSuffixId = (values.skuSuffix && values.skuSuffix.id) || null;
    newValues.attributes = this.mapAttribute(values.attributes);
    newValues.similarProducts = this.mapRelatedProducts(values.similarProducts);
    newValues.dimensionId = (values.dimension && values.dimension.id) || null;
    newValues.packageTypeId = (values.packageType && values.packageType.id) || null;
    newValues.vendorId = (values.vendor && values.vendor.id) || null;
    newValues.itemNumber = values.itemNumber.trim();

    if (values.quantityPerUnitOfMeasure) {
      newValues.quantityPerUnitOfMeasure = Number(Number(values.quantityPerUnitOfMeasure).toFixed(2));
    }

    this.mapPictures(values.pictures).then(resievedValues => {

      newValues.pictures = resievedValues;

      // need to remake as async/awaits
      this.isNew ?
        this.props.create(new CreateProductClass(newValues)) :
        this.props.update(new UpdateProductClass(newValues));

      if (newValues.isPriceChanged) {

        const pricingDetails = [];

        Object.keys(newValues.pricesCache).forEach(catalogId => {
          const isPriceExist = newValues.pricesCache[catalogId];
          if (isPriceExist) {
            pricingDetails.push({
              catalogId,
              price: newValues.pricesCache[catalogId],
              glCode:newValues.glCodesCache[catalogId],
              needsApproval: newValues.approvalsCache[catalogId]
            });
          }
        });

        const newPrices = {
          productId: newValues.productId,
          pricingDetails,
        };

        if (!this.isNew) {
          apiProducts.setPricesForCatalogs(newPrices)
            .catch(error => {
              this.props.notify(error, 'error');
            });
        }
      }

    });

  }

  handleError(error) {
    this.props.notify(error, 'error');
  }

  render() {

    const {
      categories,
    } = this.props;

    return (
      <MainContainer
        scale="md"
        title={this.isNew ? "create new item" : "Edit item"}
        centered
      >
        {
          <Formik
            enableReinitialize
            initialValues={this.state}
            validationSchema={productValidationSchema}
            onSubmit={this.handleSubmit}
            validateOnBlur
            validateOnChange={false}
            render={props => <Form
              {...props}
              categories={categories}
              isLoading={this.props.status === 'loading' || this.props.status === 'saving'}
              isNew={this.isNew}
              onError={this.handleError}
              perPage={this.state.perPage}
              skip={this.state.skip}
              perPageChange={this.props.setPerPage}
            />}
          />
        }
      </MainContainer>
    );
  }
}

interface IConnectedProps {
  categories: any;
  listStatus: any;
  status: string;
  product: EditProductDto;
  perPage: number;
}

interface IConnectedActions {
  create: typeof actionsProduct.create;
  update: typeof actionsProduct.update;
  getCategories: typeof actionsCatalog.getMasterCategories;
  get: typeof actionsProduct.get;
  notify: typeof actionsNotification.notifySwagger;
  setPerPage: typeof actionsLayout.setPerPage;
}

export default connect(
  createStructuredSelector<IGlobalStore, IConnectedProps>({
    categories: state => state.category.categories,
    listStatus: state => state.category.listStatus,
    status: state => state.products.status,
    product: state => state.products.product,
    perPage: state => state.layout.perPage,
  }),
  dispatch => bindActionCreators({
    create: actionsProduct.create,
    update: actionsProduct.update,
    getCategories: actionsCatalog.getMasterCategories,
    get: actionsProduct.get,
    notify: actionsNotification.notifySwagger,
    setPerPage: actionsLayout.setPerPage,
  }, dispatch)
)(Edit);

export function updateBrunch(obj, path = [0], callback, arrayKey) {
  let i;
  let newObj = obj;
  let keyWord = arrayKey ? arrayKey : 'children';

  for (i = 0; i < path.length - 1; i++) {
    newObj = newObj[path[i]][keyWord];
  }

  callback(newObj[path[i]]);

  return obj;
}

export function updateTree(array, callback, arrayKey) {
  let keyWord = arrayKey ? arrayKey : 'children';
  let newArray = array;

  for (let index in newArray) {
    if (newArray[index]) {
      let newItem = callback(newArray[index]);

      if (newItem[keyWord] && !!newItem[keyWord].length) {
        updateTree(newItem[keyWord], callback, keyWord);
      }

      newArray[index] = newItem;
    }

  }

  return newArray;
}

export function inspectBranch(array, callback, arrayKey) {
  let keyWord = arrayKey ? arrayKey : 'children';
  let newArray = array;
  let result;

  for (let index in newArray) {
    if (newArray[index]) {
      result = callback(newArray[index]);

      if (newArray[index][keyWord] && !!newArray[index][keyWord].length) {
        result = inspectBranch(newArray[index][keyWord], callback, keyWord);
      }

      if (result) { return result; }
    }
  }

  return result;
}

export function setParents(node, parent, arrayKey) {
  let array = arrayKey ? arrayKey : 'children';

  node.parent = parent;

  if (node[array] && node[array].length) {
    node[array].forEach((children) => {
      setParents(children, node);
    });
  }
}

/**
 * @param {array} nodes
 *  Checkboxes tree for parsing and filling with active and indeterminate states
 * @param {array} activeIds
 *  Array of checked Ids of tree elements
 * @param {boolean} doUncheckParents
 *  Perform uncheck parents when child active state comes false
 * @param {boolean} doUncheckChildren
 *  Perform uncheck children when parent active state comes false
 * @param {boolean} doCheckChildren
 *  Perform check children when parent active state comes true
 * @param {boolean} doBlockChildren
 *  Block(disable) children of the active parent
 * @param {number} currentElementId
 *  Id of the currently changed checkbox (required when the state changes)
 * @param {boolean} currentValue
 *  Value of the currently changed checkbox (required when the state changes)
 * @param {string} nodeIdKey, childrenKey, activeKey, indeterminateKey, parentKey, blockedKey
 *  Fill those params in case your properties in the node was named in the different way
 */
export function fillTree(
  nodes,
  activeIds,
  currentElementId,
  currentValue,
  nodeIdKey,
  childrenKey,
  doUncheckParents,
  doUncheckChildren,
  doCheckChildren,
  doBlockChildren,
  activeKey,
  indeterminateKey,
  parentKey,
  blockedKey
) {

  nodes.forEach(node => {
    fillBranch(
      node,
      activeIds,
      currentElementId,
      currentValue,
      doUncheckParents,
      doUncheckChildren,
      doCheckChildren,
      doBlockChildren,
      nodeIdKey,
      childrenKey,
      activeKey,
      indeterminateKey,
      parentKey,
      blockedKey
    );

  });

  return { nodes, activeIds };

}

export function fillBranch(
  node,
  activeIds,
  currentElementId,
  currentValue,
  doUncheckParents,
  doUncheckChildren,
  doCheckChildren,
  doBlockChildren,
  nodeIdKey,
  childrenKey,
  activeKey,
  indeterminateKey,
  parentKey,
  blockedKey
) {
  // set defaults for property names
  let nodeId = nodeIdKey ? nodeIdKey : 'id';
  let children = childrenKey ? childrenKey : 'children';
  let parent = parentKey ? parentKey : 'parent';
  let active = activeKey ? activeKey : 'active';
  let indeterminate = indeterminateKey ? indeterminateKey : 'indeterminate';
  let blocked = blockedKey ? blockedKey : 'blocked';

  // set defaults for actions
  doUncheckParents = doUncheckParents === undefined ? true : doUncheckParents;
  doUncheckChildren = doUncheckChildren === undefined ? true : doUncheckChildren;
  doCheckChildren = doCheckChildren === undefined ? true : doCheckChildren;
  doBlockChildren = doBlockChildren === undefined || doBlockChildren === null ? false : doBlockChildren;

  // perform actions
  if (node[nodeId] === currentElementId) {
    if (currentValue) {
      node[active] = true;
      pushToArray(activeIds, node[nodeId]);
      if (doCheckChildren) {
        checkChildren(activeIds, node, nodeId, children, active);
      }
    } else {
      node[active] = false;
      removeFromArray(activeIds, node[nodeId]);
      if (doUncheckParents) {
        uncheckParents(activeIds, node, nodeId, parent, active);
      }
      if (doUncheckChildren) {
        uncheckChildren(activeIds, node, nodeId, children, active);
      }
    }
  }

  // perform recursive filling of the tree with active and indeterminate states
  node[active] = activeIds.some(i => i === node[nodeId]);
  if (node[children] && node[children].length) {
    let markedCount = 0;
    node[children].forEach((childrenEntity) => {

      if (doBlockChildren) {
        childrenEntity[blocked] = node[active] || node[blocked];
      }

      fillBranch(childrenEntity, activeIds,
        currentElementId, currentValue,
        doUncheckParents, doUncheckChildren, doCheckChildren, doBlockChildren,
        nodeIdKey, childrenKey, activeKey, indeterminateKey);

      if (childrenEntity[active] || childrenEntity[indeterminate]) {
        markedCount++;
      }
    });

    node[indeterminate] = markedCount > 0;
  }

  return { node: node, activeIds: activeIds };
}

export function pushToArray(array, element) {
  let index = array.indexOf(element);

  if (index === -1) {
    array.push(element);
  }
}

export function removeFromArray(array, element) {
  let index = array.indexOf(element);

  if (index !== -1) {
    array.splice(index, 1);
  }
}

export function uncheckParents(activeIds, node, nodeIdKey, parentKey, activeKey) {
  let nodeId = nodeIdKey ? nodeIdKey : 'id';
  let parent = parentKey ? parentKey : 'parent';
  let active = activeKey ? activeKey : 'active';

  if (node) {
    node[active] = false;
    removeFromArray(activeIds, node[nodeId]);
    if (node[parent]) {
      uncheckParents(activeIds, node[parent]);
    }
  }
}

export function uncheckChildren(activeIds, node, nodeIdKey, childrenKey, activeKey) {
  let nodeId = nodeIdKey ? nodeIdKey : 'id';
  let children = childrenKey ? childrenKey : 'children';
  let active = activeKey ? activeKey : 'active';

  if (node && node[children]) {
    node[children].forEach((subNode) => {
      subNode[active] = false;
      removeFromArray(activeIds, subNode[nodeId]);
      uncheckChildren(activeIds, subNode);
    });
  }
}

export function checkChildren(activeIds, node, nodeIdKey, childrenKey, activeKey) {
  let nodeId = nodeIdKey ? nodeIdKey : 'id';
  let children = childrenKey ? childrenKey : 'children';
  let active = activeKey ? activeKey : 'active';

  if (node && node[children]) {
    node[children].forEach((subNode) => {
      subNode[active] = true;
      pushToArray(activeIds, subNode[nodeId]);
      checkChildren(activeIds, subNode);
    });
  }
}

export function nullToNon(value) {
  if (value === null) {
    return '';
  }
  return value;
}
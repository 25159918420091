import React from 'react';
import styled from 'styled-components';

export default styled(props => {
  return (
    <i {...props}>
      <svg viewBox="1033 20826 12 16" width="20" height="20">
        <path d=" M 1037.286 20826 L 1036.428 20826.842 L 1033 20826.842 L 1033 20828.525 L 1045 20828.525 L 1045 20826.842 L 1041.572 20826.842 L 1040.714 20826 L 1037.286 20826 Z  M 1033.857 20829.369 Q 1033.857 20840.316 1033.857 20840.316 C 1033.857 20841.244 1034.627 20842 1035.572 20842 C 1035.572 20842 1042.428 20842 1042.428 20842 C 1043.373 20842 1044.143 20841.244 1044.143 20840.316 Q 1044.143 20840.316 1044.143 20829.369 L 1033.857 20829.369 Z  M 1036.428 20831.895 L 1038.143 20831.895 L 1038.143 20839.475 L 1036.428 20839.475 L 1036.428 20831.895 Z  M 1039.857 20831.895 L 1041.572 20831.895 L 1041.572 20839.475 L 1039.857 20839.475 L 1039.857 20831.895 Z " />
      </svg>
    </i>
  );
})`
  ${ props => `
    width: 21px;
    height: 21px;
    display: inline-flex;
    justify-content: center;
    align-items: center;

    svg{
      path{
        fill: ${props.theme.primary}; 
      }
    }
  `}
`;

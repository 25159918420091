import {
  CreateProductDto,
  CreateProductDtoLatexStatus,
  CreateProductDtoStockStatus,
  CreateProductDtoContractStatus,
  FileAttachmentDto,
  ProductAttributeDto,
  UpdateProductDto,
  UpdateProductDtoLatexStatus,
  UpdateProductDtoStockStatus,
  UpdateProductDtoContractStatus
} from "../../service-proxies";
import * as moment from "moment";

export class UpdateProductClass implements UpdateProductDto {
  id: number = null;
  pictures: FileAttachmentDto[] = [];
  skuPrefixId: number = null;
  middleSku: string = "";
  skuSuffixId: number = null;
  description: string = null;
  length: number = null;
  width: number = null;
  height: number = null;
  upc: number = null;
  glCategoryNumber: string = "";
  allocationCode: string = "";
  quantityPerUnitOfMeasure: number = null;
  nameSegments: string[] = [];
  similarProducts: number[] = [];
  attributes: ProductAttributeDto[] = [];
  categoryId: number = null;
  dimensionId: number = null;
  packageTypeId: number = null;
  isPathThrough: boolean = null;
  deliveryTime: string = "";
  manufacturer: string = "";
  manufacturerNumber: string = "";
  // vendor: number = null;
  // sourceVendor: string = '';
  vendorPriceDate: moment.Moment = null;
  vendorPrice: number = null;
  itemNumber: string = null;
  hazmatCharge: number = null;
  isSterile: boolean = null;
  latexStatus: UpdateProductDtoLatexStatus = null;
  stockStatus: UpdateProductDtoStockStatus = null;
  contractStatus: UpdateProductDtoContractStatus = null;
  productSectionId: number = null;
  isHazardousMaterial: boolean = null;
  isControlledSubstance: boolean = null;
  isForm222: boolean = null;
  isReturnable: boolean = null;
  isTaxable: boolean = null;
  vendorId: number = null;

  constructor(input: object & UpdateProductDto) {
    Object.keys(this).forEach(key => {
      this[key] = input[key];
    });
  }
}

export class CreateProductClass implements CreateProductDto {
  pictures: FileAttachmentDto[] = [];
  skuPrefixId: number = null;
  middleSku: string = "";
  skuSuffixId: number = null;
  description: string = null;
  length: number = null;
  width: number = null;
  height: number = null;
  upc: number = null;
  glCategoryNumber: string = "";
  allocationCode: string = "";
  quantityPerUnitOfMeasure: number = null;
  nameSegments: string[] = [];
  similarProducts: number[] = [];
  attributes: ProductAttributeDto[] = [];
  categoryId: number = null;
  dimensionId: number = null;
  packageTypeId: number = null;
  isPathThrough: boolean = null;
  deliveryTime: string = "";
  manufacturer: string = "";
  manufacturerNumber: string = "";
  // sourceVendor: string = '';
  vendorPriceDate: moment.Moment = null;
  vendorPrice: number = null;
  itemNumber: string = null;
  hazmatCharge: number = null;
  isSterile: boolean = null;
  latexStatus: CreateProductDtoLatexStatus = null;
  stockStatus: CreateProductDtoStockStatus = null;
  contractStatus: CreateProductDtoContractStatus = null;
  productSectionId: number = null;
  isHazardousMaterial: boolean = null;
  isControlledSubstance: boolean = null;
  isForm222: boolean = null;
  isReturnable: boolean = null;
  isTaxable: boolean = null;
  vendorId: number = null;

  constructor(input: object & CreateProductDto) {
    Object.keys(this).forEach(key => {
      this[key] = input[key];
    });
  }
}

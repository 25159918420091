import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Link } from '../components';

const Breadcrumbs = ({ className, ...props }) => {
  return <div className={className}>
    {props.children}
  </div>;

};

Breadcrumbs.propTypes = {
  className: PropTypes.string.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.string,
  ])
};

export default styled(Breadcrumbs) `
  ${ ({ theme }) => {

    let {
      light,
      fs_xs,
      primary,
      global,
    } = theme;

    return `
      ${global}
      padding: 25px 20px 19px;
      font-size: ${fs_xs};
      font-weight: 500;
      flex-basis: 100%;
      height: fit-content;
      
      text-transform: uppercase;

      .${Link.styledComponentId} {
        font-size: ${fs_xs};
        font-weight: 500;

        &:after{
          content: ' / ';

          margin: 0 5px;
          
          color: ${light};
        }

      }

      span{
        color: ${primary};
      }

    `;
  }}
`;

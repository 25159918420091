import { attributeServiceProxy } from '../servicePool';

export function create(name, callback) {
  return attributeServiceProxy.createAttribute({ name: name });
}

export function createValue(value, attributeId, callback) {
  return attributeServiceProxy.createAttributeValue({ value: value, attributeId: attributeId });
}

export function getAll(text, callback) {
  return attributeServiceProxy.searchAttributes(5, text);
}

export function getAllValues(nameId, text, callback) {
  return attributeServiceProxy.searchAttributeValues(nameId, 5, text);
}
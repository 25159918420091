import * as React from 'react';
import styled, { StyledComponentClass } from 'styled-components';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { history } from '../../store';

import {
  Link,
  Button,
  MainContainer,
  Breadcrumbs,
  ProductImage,
  Card,
  Table,
  Skeleton,
  Article,
} from '../../components';

import * as actionsCategory from '../../_actions/category';
import * as apiProduct from '../../_api/products';
import { openCart } from '../../_actions/layout';
import { inspectBranch } from '../../_utils/data';
import { addProductToCart } from '../../_actions/cart';
import withGranted, { IWithGrantedProps } from '../../containers/HOC/WithGranted';
import { apiUrl } from '../../_constants/system';
import { ORDERS_SUBMIT } from '../../_constants/permissions';
import { createStructuredSelector } from "reselect";
import {
  ProductDetailsDto,
  SearchProductAttributeDto,
  SimilarProductDto,
  ClientProductDetailsDto,
  ClientSimilarProductDto
} from "../../service-proxies";
import { IGlobalStore } from "../../_reducers/reducers";
import { RouteComponentProps } from "react-router";
import { IWindowWithResize } from '../../_types/common';
import { ResizeObserverEntry } from 'resize-observer/lib/ResizeObserverEntry';
import { formatPrice } from '../../_utils/prices';
import { notifySwagger } from 'src/_actions/notification';

type StyledType<T = {}> = StyledComponentClass<T, React.StatelessComponent<T>>;

interface IPosition extends StyledComponentClass<{}, React.StatelessComponent<{}>> {
  Info?: StyledType<{ isWrapped: boolean }>;
  Image?: StyledType<{ isWrapped: boolean }>;
  InfoBlock?: StyledType<{ isWrapped: boolean, compact: boolean }>;
  Description?: StyledType;
  Details?: StyledType;
  RelatedProducts?: StyledType;
}

const Position: IPosition = styled.div`
  margin: 20px 0 30px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-wrap: wrap;

  h2{
    flex-basis: 100%;
  }
  p{
    margin: 0;
  }
`;

Position.Info = styled<{ isWrapped: boolean }, 'div'>('div')`
  display: flex;
  flex-wrap: ${({ isWrapped }) => isWrapped ? 'wrap' : 'nowrap'};
  h1${Article.P}{
    margin: 8px 0;
    word-break: break-all;
    overflow: visible;
    hyphen: normal;
    white-space: normal;
  }
  ${Article.P}{
    margin: 0;
    white-space: nowrap;
    width: 100%;
  }
  ${Table}{
    margin-top: 21px;
    margin-bottom: 21px;
  }
  ${Article.Big}{
    word-wrap: break-word;
  }
`;
Position.Image = styled<{ isWrapped: boolean }, 'div'>('div')`
  flex-shrink: 0;
  flex-grow: 0;
  margin-bottom: ${({ isWrapped }) => isWrapped ? '18px' : '0'};
`;
Position.InfoBlock = styled<{ isWrapped: boolean, compact: boolean }, 'div'>('div')`

  ${ props => {
    if (props.compact) {
      return `
        margin-left: ${ props.isWrapped ? '0px' : '66px'};
        max-width: ${ props.isWrapped ? 'calc(100% - 0px)' : 'calc(100% - 431px)'};
      `;
    } else {
      return `
        margin-left: ${ props.isWrapped ? '90px' : '66px'};
        max-width: ${ props.isWrapped ? 'calc(100% - 90px)' : 'calc(100% - 521px)'};
      `;
    }
  }}
  flex-shrink: 1;
  flex-grow: 1;
  flex-basis: auto;
  min-width: 266px;
`;

Position.Description = Position;
Position.Details = Position;
Position.RelatedProducts = Position.extend`
  flex-direction: row;
  justify-content: start;
  ${Article.H3 as any}{
    width: 100%;
    margin-bottom: 27px;
  }

  ${Card}{
    margin: 0 46px 0 0;
    &:last-of-type{
      margin: 0;
    }
    ${Skeleton.Square}{
      margin: 0 0 12px;
    }
  }
`;

const BreadcrumbsPreloader = () => <Breadcrumbs>
  <Link.Regular><Skeleton.Line size="sm" /></Link.Regular>
  <span><Skeleton.Line size="sm" /></span>
</Breadcrumbs>;

const InfoBlockPreloader = () => <React.Fragment>
  <Article.P light>
    <Skeleton.Line />
  </Article.P>

  <Article.H1>
    <Skeleton.Line size="lg" />
  </Article.H1>

  <Article.H1>
    <Skeleton.Line size="sm" />
  </Article.H1>
</React.Fragment>;

const DescriptionPreloader = () => {
  const skeletons = [];
  for (let i = 0; i < 20; i++) {
    skeletons.push(<Skeleton.Line randomSize key={i} />);
    skeletons.push(' ');
  }

  return <React.Fragment>
    <Article.P>
      {skeletons}
    </Article.P>
  </React.Fragment>;
};

const DetailsPreloader = () => {
  const skeletons = [];
  for (let i = 0; i < 5; i++) {
    skeletons.push(<Table.Row key={'row' + i} cells={[
      <Article.P key={'attribute' + i}>
        <Skeleton.Line randomSize />
      </Article.P>,
      <Article.P key={'value' + i}>
        <Skeleton.Line randomSize />
      </Article.P>,
    ]} />);
  }

  return <Table fullwidth>
    <Table.Body>
      {skeletons}
    </Table.Body>
  </Table>;
};

const RelatedProductsPreloader = () => {
  const skeletons = [];
  for (let i = 0; i < 4; i++) {
    skeletons.push(<Card key={`related-product-${i}`} >
      <Skeleton.Square size="xl" />
      <Card.Line><Skeleton.Line /></Card.Line>
      <Card.Line><Skeleton.Line /></Card.Line>
      <Card.Line><Skeleton.Line /></Card.Line>
    </Card>);
  }

  return <React.Fragment>
    {skeletons}
  </React.Fragment>;
};

interface IRouteParams {
  productId: string;
  catalogId?: string;
  categoryId?: string;
  subCategoryId?: string;
}

interface IViewProps extends
  IConnectedProps,
  IConnectedActions,
  RouteComponentProps<IRouteParams>,
  IWithGrantedProps {
  className?: string;
}

interface IViewState {
  attributes: SearchProductAttributeDto[];
  attributesStatus: string;
  relatedProducts: SimilarProductDto[] | ClientSimilarProductDto[];
  relatedProductsStatus: string;
  isWrapped: boolean;
  product: ClientProductDetailsDto | ProductDetailsDto;
  status: 'loading' | 'done' | string;
  productId: number;
}

class ViewComponent extends React.Component<IViewProps, IViewState> {

  mainUrl: string;

  isMasterView: boolean;
  private resizeObserver;
  private container;

  static initialState = {
    attributesStatus: 'loading',
    attributes: [],
    relatedProductsStatus: 'loading',
    relatedProducts: [],
    isWrapped: false,
    product: null,
    status: 'loading',
  };

  constructor(props) {
    super(props);

    this.state = {
      ...ViewComponent.initialState,
      productId: parseInt(this.props.match.params.productId, 10),
    };

    this.container = React.createRef();
    const Window: IWindowWithResize = window as IWindowWithResize;
    this.resizeObserver = new Window.ResizeObserver(this.updateDimensions.bind(this));

    this.isMasterView = /\/master-products/.test(props.match.url);
    this.mainUrl = props.match.params.catalogId ?
      `/admin/catalog/${props.match.params.catalogId}/products` :
      (this.isMasterView ? '/master-products' : '/products');

    this.getCrumbs = this.getCrumbs.bind(this);
    this.getDetails = this.getDetails.bind(this);
    this.handleAddProductToCart = this.handleAddProductToCart.bind(this);
    this.getCatalogId = this.getCatalogId.bind(this);
  }

  componentDidMount() {
    this.resizeObserver.observe(this.container);
    this.getProductDetails();
  }

  componentWillUnmount() {
    this.resizeObserver.unobserve(this.container);
  }

  static getDerivedStateFromProps(nextProps: IViewProps, state: IViewState) {
    if (state.productId !== parseInt(nextProps.match.params.productId, 10)) {
      return {
        ...state,
        productId: parseInt(nextProps.match.params.productId, 10),
      };
    }
    return state;
  }

  componentDidUpdate(prevProps: IViewProps, prevState: IViewState) {
    if (prevState.productId !== this.state.productId) {
      this.setState({
        ...ViewComponent.initialState,
        productId: parseInt(this.props.match.params.productId, 10),
      }, () => {
        this.getProductDetails();
      });
    }
  }

  async getProductDetails() {
    const catalogId = this.getCatalogId();

    this.isMasterView ?
      this.props.getMasterCategories() :
      this.props.getClientCategories(this.props.catalogId || parseInt(this.props.match.params.catalogId, 10));

    let product: ProductDetailsDto | ClientProductDetailsDto;
    try {
      product = this.isMasterView ?
        await apiProduct.getProductDetails(this.state.productId) :
        await apiProduct.getClientProductDetails(catalogId, this.state.productId);

      let picturesWithFullAddress;
      if (product.pictures) {
        picturesWithFullAddress = product.pictures.map((picture) => {
          picture.pictureUrl = apiUrl + picture.pictureUrl;
          return picture;
        });
      }

      product.pictures = picturesWithFullAddress;
    } catch (e) {
      history.goBack();
      this.props.notifySwagger(e, 'error');
    }

    let attributeData;
    let relatedProductsData;
    try {
      attributeData = await apiProduct.getAttributesForProduct(this.state.productId);
      relatedProductsData = this.isMasterView ?
        await apiProduct.getSimilarProductsForProduct(this.state.productId) :
        await apiProduct.getSimilarProductsForClientCatalog(catalogId, this.state.productId);
    } catch (e) {
      this.props.notifySwagger(e, 'error');
    }

    this.setState({
      product,
      attributes: attributeData.items,
      relatedProducts: relatedProductsData.items,
      status: 'done',
    });

  }

  updateDimensions(entries: ResizeObserverEntry[]) {
    if (!entries || entries.length === 0) {
      return;
    }
    const oneImageLayout = (this.state.product && this.state.product.pictures && this.state.product.pictures.length) <= 1;
    // min width to trigger wrap info block
    const isWrapped = entries[0].contentRect.width < (oneImageLayout ? 650 : 820);
    if (isWrapped !== this.state.isWrapped) {
      this.setState({ isWrapped });
    }
  }

  getCatalogId() {
    const selectorCatalogId = this.props.catalogId;
    const urlCatalogId = this.props.match.params.catalogId;

    if (urlCatalogId) {
      return parseInt(urlCatalogId, 10);
    } else if (selectorCatalogId) {
      return selectorCatalogId;
    }

    return undefined;
  }

  getCrumbs(categories, { productId, catalogId, ...params }: IRouteParams, url) {
    const crumbs = [];
    inspectBranch(categories, category => {
      const values = Object.values(params);
      if (values.includes(category.id.toString())) {
        const parentPath = this.mainUrl + '/' + ((category.id.toString() === params.categoryId) ? category.id : `${params.categoryId}/${category.id}`);
        crumbs.push(
          <Link key={`${category.name}-${category.id}`} to={parentPath}>
            {category.name}
          </Link>
        );
      }
      return false;
    }, 'childCategories');
    crumbs.push(
      <span key="product-name">
        {this.state.product.name}
      </span>
    );
    return crumbs;
  }

  getDetails() {
    const details = [];
    const unit = this.state.product.dimension ? ` (${this.state.product.dimension.toLowerCase()})` : '';
    if (this.state.product.height) {
      details.push({
        name: 'height',
        value: this.state.product.height + unit,
      });
    }
    if (this.state.product.width) {
      details.push({
        name: 'width',
        value: this.state.product.width + unit,
      });
    }
    if (this.state.product.length) {
      details.push({
        name: 'length',
        value: this.state.product.length + unit,
      });
    }
    if (this.state.product.quantityPerUnitOfMeasure) {
      details.push({
        name: 'quantity',
        value: `${this.state.product.quantityPerUnitOfMeasure} per ${this.state.product.packageType}`,
      });
    }

    if (this.state.product.deliveryTime) {
      details.push({
        name: 'Delivery Time',
        value: this.state.product.deliveryTime,
      });
    }
    if (this.state.product.isHazardousMaterial !== undefined) {
      details.push({
        name: 'Hazardous',
        value: this.state.product.isHazardousMaterial ? 'Yes' : 'No',
      });
    }
    if (this.state.product.hazmatCharge !== undefined) {
      details.push({
        name: 'Hazmat Charge',
        value: `$${this.state.product.hazmatCharge}`,
      });
    }
    if (this.state.product.latexStatus !== undefined) {
      const latexMap = {
        1: 'Latex',
        2: 'Latex-Free',
        3: 'N/A'
      };
      details.push({
        name: 'Latex Status',
        value: latexMap[this.state.product.latexStatus],
      });
    }
    if (this.state.product.isSterile !== undefined) {
      details.push({
        name: 'Sterility',
        value: this.state.product.isSterile ? 'Sterile' : 'Not Sterile',
      });
    }
    if (this.state.product.manufacturer) {
      details.push({
        name: 'Manufacturer',
        value: this.state.product.manufacturer,
      });
    }
    if (this.state.product.manufacturerNumber) {
      details.push({
        name: 'Manufacturer #',
        value: this.state.product.manufacturerNumber,
      });
    }
    if (this.state.product.isControlledSubstance !== undefined) {
      details.push({
        name: 'Controlled Substance',
        value: this.state.product.isControlledSubstance ? 'Yes' : 'No',
      });
    }
    if (this.state.product.isForm222 !== undefined) {
      details.push({
        name: 'Form 222 Required',
        value: this.state.product.isForm222 ? 'Yes' : 'No',
      });
    }
    if (this.state.product.isReturnable !== undefined) {
      details.push({
        name: 'Returnable',
        value: this.state.product.isReturnable ? 'Yes' : 'No',
      });
    }

    this.state.attributes.forEach(attribute => {
      details.push({
        name: attribute.attribute.displayValue,
        value: attribute.attributeValue.displayValue,
      });
    });

    return details;
  }

  handleAddProductToCart() {
    this.props.addProductToCart(this.state.product as ProductDetailsDto, 0, 1);
    this.props.openCart();
  }

  render() {

    const oneImageLayout = (this.state.product && this.state.product.pictures && this.state.product.pictures.length) <= 1;

    return <MainContainer className={this.props.className}>
      {
        (this.state.status === 'loading') &&
        <BreadcrumbsPreloader />
      }
      {
        this.state.status === 'done' &&
        this.props.categories && !!this.props.categories.length &&
        <Breadcrumbs>
          {this.getCrumbs(this.props.categories, this.props.match.params, this.props.location.pathname)}
        </Breadcrumbs>
      }
      <div ref={ref => { this.container = ref; }}>
        <Article>
          <Position.Info isWrapped={this.state.isWrapped}>

            <Position.Image isWrapped={this.state.isWrapped}>
              {
                (this.state.status === 'loading') ?
                  <ProductImage isLoading /> :
                  <ProductImage images={this.state.product.pictures} />
              }
            </Position.Image>

            <Position.InfoBlock compact={oneImageLayout} isWrapped={this.state.isWrapped}>
              {
                this.state.status === 'loading' &&
                <InfoBlockPreloader />
              }
              {
                this.state.status === 'done' &&
                <React.Fragment>
                  <Article.P light>
                    {`SKU: ${this.state.product.sku}`}
                  </Article.P>
                  <Article.H1>
                    <Article.Big>{this.state.product.name}</Article.Big>
                  </Article.H1>
                  {
                    (this.state.status === 'done') &&
                    !this.isMasterView &&
                    <Article.H1>
                      <Article.Big>
                        <strong>
                          {formatPrice((this.state.product as ClientProductDetailsDto).price)}
                        </strong>
                      </Article.Big>
                    </Article.H1>
                  }
                </React.Fragment>
              }
              {
                !this.isMasterView && this.getCatalogId() && this.props.isGranted(ORDERS_SUBMIT) &&
                <Button scale="lg" onClick={this.handleAddProductToCart}>Add To Cart</Button>
              }

            </Position.InfoBlock>

          </Position.Info>

          <Position.Description>
            <Article.H3>PRODUCT DESCRIPTION</Article.H3>
            {
              this.state.status === 'loading' &&
              <DescriptionPreloader />
            }
            {
              this.state.status === 'done' &&
              <Article.P>
                {this.state.product.description}
              </Article.P>
            }
          </Position.Description>

          <Position.Details>
            <Article.H3>PRODUCT DETAILS</Article.H3>
            {
              (this.state.status === 'loading') &&
              <DetailsPreloader />
            }
            {
              (this.state.status === 'done') &&
              <Table fullwidth>
                <Table.Body>
                  {
                    this.getDetails().map((detail, index) => <Table.Row key={`detail-${index}`} cells={[
                      <Article.P light key={'attribute-name'}>
                        {detail.name}
                      </Article.P>,
                      <Article.P key={'attribute-value'}>{detail.value}</Article.P>,
                    ]} />)
                  }
                </Table.Body>
              </Table>
            }
          </Position.Details>

          <Position.RelatedProducts >
            <Article.H3>Related</Article.H3>
            {
              this.state.status === 'loading' &&
              <RelatedProductsPreloader />
            }
            {
              this.state.status === 'done' &&
              (
                !this.isMasterView ?
                  this.state.relatedProducts as ClientSimilarProductDto[] :
                  this.state.relatedProducts as SimilarProductDto[]
              ).map((relatedProduct: ClientSimilarProductDto, index) => {
                return (index < 3) ? <Card key={`related-product-${index}`} >
                  <Link to={`${this.mainUrl}/product/${relatedProduct.id}`}>
                    <Card.Image
                      image={relatedProduct.picture ? (apiUrl + relatedProduct.picture.pictureUrl) : null}
                      name={relatedProduct.picture ? relatedProduct.picture.name : 'no image'}
                    />
                  </Link>
                  <Card.Line>
                    <Link primary to={`${this.mainUrl}/product/${relatedProduct.id}`}>{relatedProduct.name}</Link>
                  </Card.Line>
                  <Card.Line light>SKU: {relatedProduct.sku}</Card.Line>
                  <Card.Line primary><strong>{formatPrice(relatedProduct.price)}</strong></Card.Line>
                </Card> : '';
              })
            }
          </Position.RelatedProducts>

        </Article>
      </div>

    </MainContainer>;
  }
}

const View = styled(ViewComponent)`
  display: block;
  max-width: 1100px;
  padding: 0 0 22px;

  .${(Table.Row as any).styledComponentId}{
    text-transform: uppercase;
  }
`;

interface IConnectedProps {
  categories: any;
  listStatus: string;
  catalogId: number;
}

interface IConnectedActions {
  getClientCategories: typeof actionsCategory.getClientCategories;
  getMasterCategories: typeof actionsCategory.getMasterCategories;
  notifySwagger: typeof notifySwagger;
  openCart: typeof openCart;
  addProductToCart: typeof addProductToCart;
}

export default connect(
  createStructuredSelector<IGlobalStore, IConnectedProps>({
    categories: state => state.category.categories,
    listStatus: state => state.category.listStatus,
    catalogId: state => state.currentUser.catalogId,
  }),
  dispatch => bindActionCreators({
    getClientCategories: actionsCategory.getClientCategories,
    getMasterCategories: actionsCategory.getMasterCategories,
    notifySwagger,
    openCart,
    addProductToCart,
  }, dispatch)
)(withGranted(View));

export enum types {
  CURRENT_USER_LOGIN = 'current_user/login',
  CURRENT_USER_LOGIN_ERROR = 'current_user/login_error',
  CURRENT_USER_LOGOUT = 'current_user/logout',
  CURRENT_USER_RELOGIN = 'current_user/relogin',
  CURRENT_USER_ERROR = 'current_user/error',
  CURRENT_USER_REDIRECT_TO_LOGIN = 'current_user/redirect_to_login',
  CURRENT_USER_REDIRECT_TO_LOGOUT = 'current_user/redirect_to_logout',
  CURRENT_USER_CLEAR_AUTH_DATA = 'current_user/clear_auth_data',
  CURRENT_USER_LOGIN_INFORMATION = 'current_user/login_information',
  CURRENT_USER_LOCATION_SELECTED = 'current_user/location_selected',
}

import {
  unitOfMeasureServiceProxy,
} from '../servicePool';

import {
  ListResultDtoOfSearchOutputDto,
  EntityDtoOfInt32,
} from '../service-proxies';

export function searchPackageTypes(filter?: string): Promise<ListResultDtoOfSearchOutputDto> {
  return unitOfMeasureServiceProxy.searchPackageTypes(5, filter);
}

export function createPackageType(name: string): Promise<EntityDtoOfInt32> {
  return unitOfMeasureServiceProxy.createPackageType({ name });
}

export function deletePackageType(id: number): Promise<void> {
  return unitOfMeasureServiceProxy.deletePackageType(id);
}

export function searchDimensions(filter?: string): Promise<ListResultDtoOfSearchOutputDto> {
  return unitOfMeasureServiceProxy.searchDimensions(5, filter);
}

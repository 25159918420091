import * as React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import keycode from 'keycode';
import Link from './Link';

import {
  XIcon,
  PencilIcon,
  TicIcon,
} from '../assets/icons';
import {RefObject} from 'react';

export const EditableBlockContainer = styled<{negative: boolean}, 'div'>('div')`
  ${({ theme, ...props }) => {
    const {
      global,
      main,
    } = theme;

    const borderColor = props.negative ?
      'transparent' :
      theme.default;

    const backgroundColor = props.negative ?
      'transparent' :
      main;

    return `
      ${global}
      background: ${backgroundColor};
      border: 1px solid ${borderColor};
      margin: -10px -8px;
      padding: 10px 60px 10px 8px;
      position: relative;
    `;
  }}
`;

const alignFlexMap: {[k: string]: string} = {
  left: 'flex-start',
  right: 'flex-end',
  center: 'center',
};

export const Body = styled<{alignment: TextAlignment}, 'div'>('div')`
  ${({ theme, alignment }) => {
    const {
      global,
    } = theme;
    return `
      ${global}
      display: flex;
      flex-direction: row;
      justify-content: ${alignFlexMap[alignment]};
      justify-items: ${alignFlexMap[alignment]};
      align-items: ${alignFlexMap[alignment]};
      text-align: ${alignment};
    `;
  }}
`;

export const Toolbar = styled.div`
  position: absolute;
  height: 15px;
  top: 10px;
  right: 10px;
`;

export const EditableBlockButton = styled(Link.Button)`
  ${({ theme }) => {
    const {
      darkest,
    } = theme;
    return `
      line-height: 0;
      border: 0;
      width: 15px;
      height: 15px;
      padding: 0px;
      margin: 0 0 0 10px;

      background-color: transparent;
      cursor: pointer;

      > i {
        width: 15px;
        height: 15px;
        margin-left: 0;
        svg{
          width: 15px;
          height: 15px;
          path{
            fill: ${darkest};
          }
        }
      }
      .${(TicIcon as any).styledComponentId}{
        svg{
          transform: scale(1.5);
        }
      }
    `;
  }}
`;

export const EditButton: React.SFC<{onClick: (event) => void}>  = ({ onClick }) => {
  return <EditableBlockButton type="button" onClick={onClick}>
    <PencilIcon />
  </EditableBlockButton>;
};

export const CloseButton: React.SFC<{onMouseDown: (event) => void}> = ({ onMouseDown }) => {
  return <EditableBlockButton type="button" onMouseDown={onMouseDown}>
    <XIcon />
  </EditableBlockButton>;
};

export const ApplyButton: React.SFC<{onMouseDown: (event) => void}>  = ({ onMouseDown }) => {
  return <EditableBlockButton type="button" onMouseDown={onMouseDown}>
    <TicIcon />
  </EditableBlockButton>;
};

interface IEditableBlockElementProps {
  active: boolean;
  className?: string;
  onChange: (text: string) => void;
  onEdit?: (oldValue: string, newValue: string) => void;
  value: string;
  onBlur?: (event, text: string) => void;
  inputFilter?: (value: string) => string;
  alignment: TextAlignment;
}

const EditableBlockElement = styled(class extends React.Component<IEditableBlockElementProps> {
  static propTypes = {
    className: PropTypes.string.isRequired,
    active: PropTypes.bool.isRequired,
    id: PropTypes.string,
    value: PropTypes.string.isRequired,

    onClick: PropTypes.func,
    onEdit: PropTypes.func,
    onBlur: PropTypes.func,
    onChange: PropTypes.func,
  };

  private readonly blockRef: RefObject<HTMLSpanElement>;
  private observer: MutationObserver;

  constructor(props) {
    super(props);

    this.handleBlur = this.handleBlur.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);
    this.handleMutations = this.handleMutations.bind(this);
    this.blockRef = React.createRef();
  }

  componentDidMount() {
    this.observer = new MutationObserver(this.handleMutations);
    this.observer.observe(this.blockRef.current, { characterData: true, subtree: true });
    if (this.props.active) {
      const range = document.createRange();
      const sel = window.getSelection();
      const pointIndex = this.blockRef.current.innerHTML.indexOf('.');
      range.setStart(this.blockRef.current.childNodes[0],  pointIndex === -1 ?  this.blockRef.current.innerHTML.length : pointIndex);
      range.collapse(true);
      sel.removeAllRanges();
      sel.addRange(range);
      this.blockRef.current.focus();
    }
  }

  componentDidUpdate(prevProps: IEditableBlockElementProps) {
    if (this.props.active && !prevProps.active) {
      this.blockRef.current.focus();
    }
  }

  componentWillUnmount() {
    if (this.props.onChange) {
      this.props.onChange(this.blockRef.current.innerHTML);
    }
    this.observer.disconnect();
  }

  handleBlur(event) {
    if (event.target.innerHTML !== this.props.value) {
      if (this.props.onEdit) {
        this.props.onEdit(this.props.value, event.target.innerHTML.replace(/<(?:.|\n)*?>/gm, ''));
      }
    }
    if (this.props.onBlur) {
      this.props.onBlur(event, this.blockRef.current.innerHTML);
    }
  }

  handleKeyDown(event) {
    if (keycode(event.which) === 'enter') {
      this.blockRef.current.blur();
    }
    if (keycode(event.which) === 'esc') {
      this.blockRef.current.innerHTML = this.props.value;
      this.blockRef.current.blur();
    }
  }

  handleMutations(mutations: MutationRecord[], observer: MutationObserver) {
    mutations.forEach(mutation => {
      if (mutation.type === 'characterData') {
        if (this.props.inputFilter) {
          const newValue = this.props.inputFilter(mutation.target.textContent);
          if (newValue !== mutation.target.textContent) {
            mutation.target.textContent = newValue;
          }
        }
      }
    });
  }

  render() {
    const { className, value } = this.props;

    return <span
      ref={this.blockRef}
      className={className}
      contentEditable
      dangerouslySetInnerHTML={{ __html: value }}
      onBlur={this.handleBlur}
      onKeyDown={this.handleKeyDown}
    />;

  }
})`
  ${props => {

    const {
      global,
      primary,
    } = props.theme;

    const cursor = props.active ?
      'text' :
      'pointer';

    return `
      ${global}

      cursor: ${cursor};
      color: ${primary};
      text-align: ${({alignment}) => alignment};
      outline: 0;
      min-height: 15px;
      display: inline-block;
    `;
  }}
`;

type TextAlignment = 'right' | 'left' | 'center';

interface IEditableBlockProps {
  className?: string;
  value: string;
  label?: string;
  prefix?: string;
  maxLength?: number;
  onChange: (value: string) => void;
  exitEditOnBlur?: boolean;
  inputFilter?: (value: string) => string;
  outputFilter?: (value: string) => string;
  alignment?: TextAlignment;
  editOnClick?: boolean;
}

interface IEditableBlockState {
  editing: boolean;
}

const EditableBlock = styled(class extends React.Component<IEditableBlockProps, IEditableBlockState> {
  static propTypes = {
    className: PropTypes.string,
    value: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]),
    label: PropTypes.string,
    prefix: PropTypes.string,
    onChange: PropTypes.func,
    maxLength: PropTypes.number,
    alignment: PropTypes.string,
  };

  static defaultProps = {
    alignment: 'left',
  };

  private confirmed: boolean;

  constructor(props) {
    super(props);

    this.handleEdit = this.handleEdit.bind(this);
    this.handleApply = this.handleApply.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleOnBlur = this.handleOnBlur.bind(this);

    this.confirmed = true;

    this.state = {
      editing: false,
    };
  }

  handleEdit() {
    if (this.state.editing) {
      return;
    }
    this.setState({
      editing: true,
    });
  }

  handleCancel() {
    this.confirmed = false;
    this.setState({
      editing: false,
    });
  }

  handleApply() {
    this.confirmed = true;
    this.setState({
      editing: false,
    });
  }

  handleChange(value) {
    if (this.confirmed) {
      this.props.onChange(value);
    }
  }

  handleOnBlur() {
    if (this.state.editing && this.props.exitEditOnBlur) {
      this.confirmed = true;
      this.setState({
        editing: false,
      });
    }
  }

  render() {
    const {
      className,
      value,
      label,
      prefix,
    } = this.props;

    return <div className={className}>
      <EditableBlockContainer negative={!this.state.editing}>
        {
          this.state.editing ?
            <Body alignment={this.props.alignment}>
              {prefix}
              <EditableBlockElement
                value={value}
                active={this.state.editing}
                onChange={this.handleChange}
                alignment={this.props.alignment}
                onBlur={this.handleOnBlur}
                inputFilter={this.props.inputFilter}
              />
            </Body> :
            <Body
              alignment={this.props.alignment}
              onClick={this.props.editOnClick ? this.handleEdit : undefined}>{prefix}{
                label || this.props.outputFilter ? this.props.outputFilter(value) : value
              }</Body>
        }
        {
          !this.state.editing ?
            <Toolbar>
              <EditButton onClick={this.handleEdit} />
            </Toolbar> :
            <Toolbar>
              <ApplyButton onMouseDown={this.handleApply} />
              <CloseButton onMouseDown={this.handleCancel} />
            </Toolbar>
        }
      </EditableBlockContainer>
    </div>;
  }
})`
  margin: 10px;
`;

export default EditableBlock;

import * as React from "react";
import styled, { StyledComponentClass } from "styled-components";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import {
  // Get?ategoriesDto,
  ProductListDto,
  ListResultDtoOfCountDtoOfInt64
} from "../../service-proxies";

import { addProductToCart } from "../../_actions/cart";
import { openCart, setPerPage } from "../../_actions/layout";
import { history } from "../../store";
import {
  MainContainer,
  Button,
  Pagination,
  Checkbox,
  Table,
  Link,
  Thumbnail,
  Skeleton,
  HorizontalMenu,
  Separator
} from "../../components";
import { StyledSideMenu } from "../../components/SideMenu";
import { ORDERS_SUBMIT } from "../../_constants/permissions";
import * as actionsSearch from "../../_actions/search";
import * as actionsNotify from "../../_actions/notification";
import WithGranted, { IWithGrantedProps } from "../HOC/WithGranted";
import SubCategories from "./SubCategories";
import { apiUrl } from "../../_constants/system";
import Categories from "./Categories";
import { IMatch } from "../../_types/common";
import {
  CategoryContextProvider,
  CategoryContextConsumer
} from "./Contexts/CategoryContext";
import {
  getClientCategories,
  getCountForClientCatalog
} from "../../_api/categories";
import { getProductsForClientCatalog } from "src/_api/products";
import { setSearchFilter, getSearchFilter } from "../../_utils/userSession";
import PubSub from "pubsub-js";

interface IPositionHead {
  className?: string;
}

const Position = {
  Container: styled.div`
    width: 100%;
    flex: 1 1 100%;
    display: flex;
    flex-direction: column;
  `,

  Body: styled.div`
    width: 100%;
    display: flex;
    flex: 1 1 100%;
  `,

  Content: styled.div`
    flex: 1 1 100%;
    display: flex;
    flex-direction: column;
    max-width: 100%;
    ${Table} {
      flex: 1 1 100%;
    }
  `,

  Head: styled((({ className, children }) => (
    <header className={className}>
      <div>{children}</div>
    </header>
  )) as React.StatelessComponent<IPositionHead>)`
    width: 100%;
    box-sizing: border-box;
    padding: 0;

    > div {
      display: flex;
      justify-content: space-between;
      height: 60px;
      align-items: center;
    }

    ${HorizontalMenu} {
      padding: 0px 10px 0px 10px;
      width: auto;
      > .container-wrap > .item-wrap > a {
        padding: 21px 0;
      }
    }
  ` as StyledComponentClass<IPositionHead, any>,

  Element: styled.div`
    display: flex;
    align-items: center;
  `,

  Toolbar: styled.div`
    text-align: right;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding-right: 5px;
  `,

  SubCategory: styled.aside`
    flex: 1 1;
  `,

  SelectToolbar: styled(Separator).attrs({
    borders: "1"
  })`
    display: flex;
    justify-content: space-between;
    height: 59px;
    box-sizing: border-box;
    padding: 14px 20px;
  `
};

const MainContainerWithSidebar = styled(MainContainer)`
  ${StyledSideMenu} ~ ${Position.Content}{
    width: calc(100% - 269px);
  }
  ${Position.Content}{
    width: 100%;
  }
`;

interface IMultiselectCheckbox {
  id: number;
  onChange: (id: number, value: boolean) => void;
  value: boolean;
}

const MultiselectCheckbox: React.SFC<IMultiselectCheckbox> = ({
  onChange,
  id,
  ...props
}) => {
  function handleChange() {
    onChange(id, props.value);
  }

  return <Checkbox onChange={handleChange} {...props} />;
};

interface IAddButton {
  // @todo - make propper
  onClick: (item: any) => void;
  // @todo - make propper
  item: any;
}

const AddButton: React.SFC<IAddButton> = ({ onClick, item, ...props }) => {
  function handleClick() {
    if (onClick) {
      onClick(item);
    }
  }

  return (
    <Button primary negative scale="sm" onClick={handleClick}>
      {props.children}
    </Button>
  );
};

interface IMultipleAddButton {
  ids: number[];
  onClick: (ids: number[]) => void;
  count: number;
}

const MultipleAddButton: React.SFC<IMultipleAddButton> = ({
  onClick,
  ids,
  count,
  ...props
}) => {
  function handleClick() {
    if (onClick) {
      onClick(ids);
    }
  }

  return (
    <Button negative primary onClick={handleClick}>
      {`Add Selected (${count})`}
    </Button>
  );
};

interface IGetProductsParams {
  skip?: number;
  searchText?: string;
  perPage?: number;
  categoryId?: number;
  catalogId?: number;
}

export interface ICheckStateChanges {
  isChanged: boolean;
  changes: {};
}

interface IListProps extends IMatch, IConnectedActions, IWithGrantedProps {
  perPage: number;
  // @todo - make propper
  match: any;
  catalogId: number;
}

interface IConnectedActions {
  setSearch: typeof actionsSearch.set;
  setPerPage: typeof setPerPage;
  addProductToCart: typeof addProductToCart;
  openCart: typeof openCart;
  notifySwagger: typeof actionsNotify.notifySwagger;
  notify: typeof actionsNotify.notify;
}

interface IListState {
  selectedIds?: number[];
  catalogNotExist?: boolean;
  skip?: number;
  searchText?: string;
  products?: ProductListDto[];
  status?: "loading" | "done" | string;
  totalCount?: number;
  catalogId?: number;
  categoryId?: number;
}

class List extends React.Component<IListProps, IListState> {
  private categoriesRef;
  constructor(props: IListProps) {
    super(props);
    this.handleChangePage = this.handleChangePage.bind(this);
    this.handleSelectProduct = this.handleSelectProduct.bind(this);
    this.handleAddProductToCart = this.handleAddProductToCart.bind(this);
    this.handleMultipleAddProductToCart = this.handleMultipleAddProductToCart.bind(
      this
    );
    this.renderWarningMessage = this.renderWarningMessage.bind(this);
    this.refreshCategories = this.refreshCategories.bind(this);

    this.categoriesRef = React.createRef();

    this.state = {
      selectedIds: [],
      catalogNotExist: false,
      skip: 0,
      searchText: "",
      products: null,
      totalCount: null,
      catalogId: null,
      categoryId: null,
      status: "loading"
    };
  }

  async componentDidMount() {
    const catalogId = this.setCatalogId();
    const search = getSearchFilter() || "";
    this.setState({ searchText: search });
    PubSub.publish("SEARCH", search);
    if (catalogId) {
      this.getProducts({
        catalogId
      });
      this.props.setSearch("/products", searchText => {
        setSearchFilter(searchText);
        this.setState(
          {
            searchText,
            catalogId,
            skip: 0
          },
          () => {
            this.getProducts({
              searchText,
              catalogId,
              skip: 0
            });
            this.refreshCategories(searchText);
          }
        );
      });
    } else {
      this.setState({ catalogNotExist: false });
    }
  }

  componentDidUpdate(prevProps: IListProps, prevState: IListState) {
    const productsStateChanges = List.checkPropsChanges(prevState, this.state);
    const matchParamsChanges = List.checkPropsChanges(
      this.props.match.params,
      prevProps.match.params
    );
    const productsPropsChanges = List.checkPropsChanges(this.props, prevProps);
    if (productsStateChanges.isChanged) {
      this.getProducts();
    }
    if (matchParamsChanges.isChanged) {
      this.getProducts();
      if (this.state.searchText) {
        this.setState(
          {
            searchText: getSearchFilter() || ""
          },
          () => {
            this.categoriesRef.current.getCategories();
          }
        );
      }
    }
    if (productsPropsChanges.isChanged) {
      const isCatalogIdChanged = Object.prototype.hasOwnProperty.call(productsPropsChanges.changes, "catalogId");
      if (isCatalogIdChanged && productsPropsChanges.changes.catalogId) {
        history.push("/products");
        this.getProducts();
      } else {
        this.getProducts();
      }
    }
  }

  static checkPropsChanges(
    firstObject,
    secondObject
  ): { isChanged: boolean; changes: any } {
    const changedParams = [];
    const changes = {};

    for (const param in secondObject) {
      if (
        secondObject[param] !== firstObject[param] &&
        param !== "status" &&
        typeof secondObject[param] !== "object"
      ) {
        changes[param] = firstObject[param];
        changedParams.push(changes);
      }
    }

    const isChanged = changedParams && !!changedParams.length;

    return {
      isChanged,
      changes
    };
  }

  getProducts(params: IGetProductsParams = {}) {
    const subCategoryId = this.props.match.params.subCategoryId;
    const categoryId = this.props.match.params.categoryId;
    const finalCategoryId = subCategoryId || categoryId;

    const skip =
      Number.isInteger(params.skip) && params >= 0
        ? params.skip
        : this.state.skip;
    const searchText = params.searchText || this.state.searchText;
    const perPage = params.perPage || this.props.perPage;
    const catalogId = params.catalogId || this.setCatalogId();

    this.setState({ status: "loading" }, async () => {
      const productsData = await getProductsForClientCatalog(
        finalCategoryId,
        catalogId,
        perPage,
        skip,
        searchText
      );

      this.setState({
        status: "done",
        products: productsData.items,
        totalCount: productsData.totalCount,
        catalogId
      });
    });
  }

  refreshCategories(searchText: string = "") {
    this.categoriesRef.current.getCategories(searchText);
  }

  setCatalogId() {
    const selectorCatalogId = this.props.catalogId;
    const urlCatalogId = this.props.match.params.catalogId;

    if (urlCatalogId) {
      return urlCatalogId;
    } else if (selectorCatalogId) {
      return selectorCatalogId;
    }

    return null;
  }

  handleAddProductToCart(product) {
    this.props.addProductToCart(product, 0, 1);
    this.props.openCart();
  }

  handleMultipleAddProductToCart() {
    const productsForAdd = this.state.products.reduce((products, product) => {
      if (this.state.selectedIds.indexOf(product.id) >= 0) {
        products.push(product);
      }
      return products;
    }, []);
    productsForAdd.forEach(product => {
      this.props.addProductToCart(product, 0, 1);
    });
    this.props.openCart();
  }

  handleChangePage(pageInfo) {
    const { perPage, skip } = pageInfo;

    this.getProducts({
      skip,
      perPage
    });
    this.props.setPerPage(perPage);
    this.setState({ skip });
  }

  handleSelectProduct(id: number, value) {
    let selectedIds = [];
    if (!value) {
      selectedIds = [...this.state.selectedIds, id];
    } else {
      selectedIds = this.state.selectedIds.filter(item => {
        return id !== item;
      });
    }
    this.setState({ selectedIds });
  }

  renderPreloaderRows(perPage, status) {
    const rows = [];
    const elementsCount = status === "loading" ? perPage - 1 : 0;

    const catalogId = this.setCatalogId();

    for (let i = 0; i < elementsCount; i++) {
      rows.push(
        <Table.Row
          key={"preloader" + i}
          rows={perPage}
          cells={[
            <React.Fragment key={`checkbox-preloader-${i}`}>
              {!catalogId && <Skeleton.Line size="xs" />}
            </React.Fragment>,
            <Skeleton.Square key={`thumbnail-preloader-${i}`} />,
            <React.Fragment key={`info-preloader-${i}`}>
              <p>
                <Skeleton.Line />
              </p>
              <p>
                <Skeleton.Line />
              </p>
              <p>
                <Skeleton.Line />
              </p>
            </React.Fragment>,
            <Skeleton.Line key={`actions-preloader-${i}`} size="sm" />
          ]}
        />
      );
    }
    return rows;
  }

  renderWarningMessage() {
    if (this.state.catalogNotExist) {
      return (
        <Table.Row
          key={"no_poroducts"}
          colSpan="4"
          cells={[
            <span key="0">
              No product catalog yet available. Contact your Supply Chain
              Manager for information.
            </span>
          ]}
        />
      );
    }
    if (!this.state.products || !this.state.products.length) {
      return (
        <Table.Row
          key={"no_poroducts"}
          colSpan="4"
          cells={[<span key="0">NO PRODUCTS FOUND</span>]}
        />
      );
    }
    return null;
  }

  handleGetCategoriesCounts(
    filter: string,
    catalogId: number,
    isArchived: boolean
  ): Promise<ListResultDtoOfCountDtoOfInt64> {
    return getCountForClientCatalog(catalogId, filter);
  }

  render() {
    const catalogId = this.setCatalogId();
    return (
      <CategoryContextProvider
        ref={this.categoriesRef}
        catalogId={this.props.catalogId}
        onGetCategories={getClientCategories}
        onGetCategoriesCounts={this.handleGetCategoriesCounts}
      >
        <CategoryContextConsumer>
          {context => {
            return (
              <>
                <MainContainerWithSidebar>
                  <Position.Container>
                    <Position.Head>
                      <Categories catalogId={catalogId} isNotPosition />
                    </Position.Head>
                    <Position.Body>
                      {context.status === "done" && (
                        <SubCategories
                          match={this.props.match}
                          inner
                          categories={context.categories}
                          totalCount={context.totalCount}
                        />
                      )}
                      <Position.Content>
                        {this.state.selectedIds &&
                          !!this.state.selectedIds.length && (
                            <Position.SelectToolbar>
                              <Position.Element />
                              <Position.Element>
                                <MultipleAddButton
                                  ids={this.state.selectedIds}
                                  onClick={this.handleMultipleAddProductToCart}
                                  count={this.state.selectedIds.length}
                                />
                              </Position.Element>
                            </Position.SelectToolbar>
                          )}
                        {
                          <Table headless fullHeight>
                            <Table.Header
                              columns={[
                                { name: "check", label: "", size: "sm" },
                                { name: "thumbnail", label: "", size: "sm" },
                                { name: "product", label: "product" },
                                { name: "actions", label: "", size: "sm" }
                              ]}
                            />
                            <Table.Body>
                              {this.state.status === "done" &&
                                !this.state.catalogNotExist &&
                                (this.state.products &&
                                  !!this.state.products.length) &&
                                this.state.products.map((item, index) => (
                                  <Table.Row
                                    key={`${index}-${item.id}`}
                                    cells={[
                                      <React.Fragment key={`checkbox-${index}`}>
                                        {this.props.isGranted(
                                          ORDERS_SUBMIT
                                        ) && (
                                          <MultiselectCheckbox
                                            id={item.id}
                                            onChange={this.handleSelectProduct}
                                            value={
                                              this.state.selectedIds.indexOf(
                                                item.id * 1
                                              ) > -1
                                            }
                                          />
                                        )}
                                      </React.Fragment>,

                                      <Link
                                        key={`thumbnail-${index}-${item.id}`}
                                        to={`${this.props.match.url}/product/${
                                          item.id
                                        }`}
                                      >
                                        <Thumbnail
                                          image={
                                            item.picture &&
                                            item.picture.pictureUrl
                                              ? apiUrl + item.picture.pictureUrl
                                              : ""
                                          }
                                          name={
                                            item.picture && item.picture.name
                                              ? item.picture.name
                                              : "thumbnail"
                                          }
                                        />
                                      </Link>,
                                      [
                                        <p key={`name-${index}-${item.id}`}>
                                          <Link
                                            to={`${
                                              this.props.match.url
                                            }/product/${item.id}`}
                                          >
                                            {item.name}
                                          </Link>
                                        </p>,
                                        <p key={`sku-${index}-${item.id}`}>
                                          <span style={{ color: "#000" }}>
                                            {item.sku}
                                          </span>
                                        </p>,
                                        <p key={`desc-${index}-${item.id}`}>
                                          <span style={{ color: "#000" }}>
                                            {item.description}
                                          </span>
                                        </p>
                                      ],

                                      <Position.Toolbar>
                                        <React.Fragment
                                          key={`cell-price-${index}-${item.id}`}
                                        >
                                          {this.props.isGranted(
                                            ORDERS_SUBMIT
                                          ) && item.price !== null
                                            ? `$${item.price.toFixed(2)}`
                                            : null}
                                        </React.Fragment>
                                      </Position.Toolbar>,
                                      <Position.Toolbar>
                                        <React.Fragment
                                          key={`cell-toolbar-${index}-${
                                            item.id
                                          }`}
                                        >
                                          {this.props.isGranted(
                                            ORDERS_SUBMIT
                                          ) && (
                                            <AddButton
                                              key={`cell-toolbar-${index}-${
                                                item.id
                                              }`}
                                              item={item}
                                              onClick={
                                                this.handleAddProductToCart
                                              }
                                            >
                                              Add
                                            </AddButton>
                                          )}
                                        </React.Fragment>
                                      </Position.Toolbar>
                                    ]}
                                  />
                                ))}
                              {this.state.status !== "loading" &&
                                this.renderWarningMessage()}
                              {this.state.status === "loading" &&
                                this.renderPreloaderRows(
                                  this.props.perPage,
                                  this.state.status
                                )}
                            </Table.Body>
                          </Table>
                        }
                        <Pagination
                          skip={this.state.skip}
                          perPage={this.props.perPage}
                          count={this.state.totalCount}
                          onChange={this.handleChangePage}
                        />
                      </Position.Content>
                    </Position.Body>
                  </Position.Container>
                </MainContainerWithSidebar>
              </>
            );
          }}
        </CategoryContextConsumer>
      </CategoryContextProvider>
    );
  }
}

export default connect(
  state => ({
    perPage: state.layout.perPage,
    catalogId: state.currentUser.catalogId
  }),
  dispatch =>
    bindActionCreators(
      {
        setSearch: actionsSearch.set,
        setPerPage,
        addProductToCart,
        openCart,
        notify: actionsNotify.notify,
        notifySwagger: actionsNotify.notifySwagger
      },
      dispatch
    )
)(WithGranted(List));

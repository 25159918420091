import * as React from 'react';
import styled from 'styled-components';
import styledTs from 'styled-components-ts';
import { IThemeProps } from '../assets/theme';

export interface ITabItem extends IThemeProps {
  isActive?: boolean;
  value: string;
  label?: string;
  handleChange?: (value: string) => void;
}

const TabItem = styledTs<ITabItem>(styled(({
  className,
  value,
  label,
  handleChange
}) => {
  return <span className={className} onClick={() => handleChange(value)}>{label}</span>;
}))`
  ${({ theme, ...props }) => {
    const {
      primary,
      fs_xs,
    } = theme;
    return `
      font-size: ${fs_xs};
      font-weight: normal;
      color: ${theme.default};
      cursor: pointer;
      padding: 10px 0;
      margin: 10px;
      text-transform: uppercase;
      color: ${ props.isActive ? theme.default : primary};
      border-bottom: ${props.isActive ? `2px solid ${theme.default}` : '2px solid transparent'};
      border-top: 2px solid transparent;
    `;
  }}
`;

export interface IItem {
  value: string;
  label?: string;
}

export interface ITabsSwitchProps {
  onTabChanged: (value: string) => void;
  className?: string;
  items: IItem[];
}

export interface ITabsSwitchState {
  activeTab: string;
}

const TabsSwitch = styledTs<ITabsSwitchProps>(styled(
  class extends React.Component<ITabsSwitchProps, ITabsSwitchState> {

    constructor(props) {
      super(props);

      this.onTabChanged = this.onTabChanged.bind(this);

      this.state = {
        activeTab: props.items[0].value
      };
    }

    onTabChanged(value) {
      this.setState({ activeTab: value });
      if (this.props.onTabChanged) {
        this.props.onTabChanged(value);
      }
    }

    render() {
      return (
        <div className={this.props.className}>
          {this.props.items && this.props.items.map(item => (
            <TabItem
              key={item.value}
              {...item}
              handleChange={this.onTabChanged}
              isActive={this.state.activeTab === item.value}
            />))
          }
        </div>
      );
    }
  }
))`
  display: block;
  margin: 0 -10px;
`;

export default TabsSwitch;
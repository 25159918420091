import { spendingTermServiceProxy } from '../servicePool';

import {
  ListResultDtoOfSearchOutputDto,
  SearchOutputDto,
} from '../service-proxies';

export function getSpendingTerm(): Promise<ListResultDtoOfSearchOutputDto> {
  return spendingTermServiceProxy.getAllSpendingTerms();
}

export function getDefaultSpendingTerm(): Promise<SearchOutputDto> {
  return spendingTermServiceProxy.getDefaultSpendingTerm();
}



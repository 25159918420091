import * as React from 'react';
import { connect } from "react-redux";
import { createStructuredSelector } from 'reselect';
import styled from 'styled-components';
import { redirectToLogin, getCurrentLoginInfo } from '../../_actions/current-user';
import {IOpenCustomModalCallback, openCustomModalCallback} from '../../_actions/modal';
import { PreloaderSvg } from '../../assets/icons/Preloader';
import {GetCurrentLoginInformationsOutput} from '../../service-proxies';
import {IGlobalStore} from '../../_reducers/reducers';
import {bindActionCreators} from 'redux';
import {IShortLocationInfo} from "../../_types/common";
import LocationSelectionModal from '../Layout/LocationSelectorModal';

const Preloader = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  svg {
   circle{
     fill: ${({theme}) => theme.default}; 
   }
  }
`;

export default function withAuth(Component) {

  class WithAuth extends React.PureComponent<IConnectedProps & IConnectedActions> {

    constructor(props) {
      super(props);
      this.openLocationSelectorModal = this.openLocationSelectorModal.bind(this);
    }


    componentWillMount() {
      if (!this.props.isAuth) {
        this.props.redirectToLogin();
        return;
      }
      if (!this.props.loginInfo) {
        this.props.getCurrentLoginInfo();
      }
      /*if (!this.props.locationInfo || !this.props.locationInfo.id) {
        this.openLocationSelectorModal();
      }*/
    }

    componentWillReceiveProps(nextProps) {
      if (!nextProps.isAuth) {
        this.props.redirectToLogin();
        return;
      }
      if (!nextProps.loginInfo) {
        this.props.getCurrentLoginInfo();
        return;
      }
      if (!nextProps.locationInfo || !nextProps.locationInfo.id) {
        this.openLocationSelectorModal();
      }
    }

    openLocationSelectorModal() {
      this.props.openCustomModalCallback(
        (resolve, reject) => (
          <LocationSelectionModal resolve={resolve} reject={reject} />
        ),
        { title: 'Select Your Location', noBackdrop: true }).then(() => {}).catch(() => {});
    }

    render() {
      const {isAuth, loginInfo, locationInfo, ...rest} = this.props;
      if (!isAuth || !loginInfo) {
        return (
          <Preloader>
           <PreloaderSvg size={50}/>
          </Preloader>
        );
      } else if (!locationInfo || !locationInfo.id) {
        return <div/>;
      } else {
        return <Component {...rest} />;
      }
    }

  }

  interface IConnectedProps {
    isAuth: boolean;
    loginInfo: GetCurrentLoginInformationsOutput;
    locationInfo: IShortLocationInfo;
  }

  interface IConnectedActions {
    getCurrentLoginInfo: typeof getCurrentLoginInfo;
    openCustomModalCallback: IOpenCustomModalCallback; // typeof openCustomModalCallback;
    redirectToLogin: typeof redirectToLogin;
  }

  return connect(
    createStructuredSelector<IGlobalStore, IConnectedProps>({
      isAuth: state => (state.currentUser && state.currentUser.isAuth),
      loginInfo: state => (state.currentUser && state.currentUser.loginInfo),
      locationInfo: state => (state.currentUser && state.currentUser.locationInfo),
    }),
    dispatch => bindActionCreators({
      getCurrentLoginInfo,
      openCustomModalCallback: (openCustomModalCallback as any as IOpenCustomModalCallback),
      redirectToLogin,
    }, dispatch))(WithAuth);
}

import React from 'react';
import styled from 'styled-components';

import {
  MinusIcon,
  PlusIcon,
} from '../assets/icons';

import Error from './Error';

export const InputContainer = styled('div')`
  ${({ theme }) => {
    const {
      global,
    } = theme;
    return `
      ${global}
      position: relative;
      flex: 1 1 100%;
    `;
  }}
`;

interface IMinusButtonProps {
  className?: string;
  compact: boolean;
  onClick: (e) => void;
  disabled: boolean;
}

export const MinusButton = styled<IMinusButtonProps>(({ className, onClick, ...props }) => {
  return (<button className={className} onClick={onClick} {...props}><MinusIcon /></button>);
})`
  ${({ compact, theme }) => {
    const {
      global,
      primary,
      light,
      lightest,
    } = theme;
    return `
      ${global}
      position: absolute;
      left: 0;
      height: 100%;
      background: ${compact ? lightest : 'none'};
      border: ${compact ? `1px solid ${light}` : 'none'};
      outline: none;
      transition: all .5s ease-in-out;
      cursor: pointer;
      i{
        svg{
          path{
            fill: ${primary};
          }
        }
      }
      &:hover{
        transition: all .5s ease-in-out;
        i{
          svg{
            path{
              fill: ${theme.default};
            }
          }
        }
      }
    `;
  }}
`;

interface IPlusButtonProps {
  className?: string;
  compact: boolean;
  onClick: (e) => void;
  disabled: boolean;
}

export const PlusButton = styled<IPlusButtonProps>(({ className, onClick, ...props }) => {
  return (<button className={className} onClick={onClick} {...props}><PlusIcon /></button>);
})`
  ${({ compact, theme }) => {
    const {
      global,
      primary,
      light,
      lightest,
    } = theme;
    return `
      ${global}
      position: absolute;
      right: 0;
      height: 100%;
      background: ${compact ? lightest : 'none'};
      border: ${compact ? `1px solid ${light}` : 'none'};
      outline: none;
      transition: all .5s ease-in-out;
      cursor: pointer;
      i{
        svg{
          path{
            fill: ${primary};
          }
        }
      }
      &:hover{
        transition: all .5s ease-in-out;
        i{
          svg{
            path{
              fill: ${theme.default};
            }
          }
        }
      }
    `;
  }}
`;

interface ICompactLabelProps {
  compact?: boolean;
}

export const CompactLabel = styled<ICompactLabelProps, 'label'>('label')`
  ${({ compact, theme }) => {
    const {
      fs_md,
      fs_sm,
      lightest,
      light,
      primary,
    } = theme;

    if (compact) {
      return `
      padding: 6px 10px 4px 0;
      font-size: ${fs_sm};
      `;
    } else {
      return `
      font-size: ${fs_md};
      padding: 10px 22px;
      border-top-width: 1px;
      border-left-width: 1px;
      border-bottom-width: 1px;

      border-top-style: solid;
      border-left-style: solid;
      border-bottom-style: solid;
      background-color: ${lightest}
      border-color: ${light};
      color: ${primary};
      white-space: nowrap;
    `;
    }
  }}
`;

interface ICounterProps {
  compact?: boolean;
  label?: string;
  onChange: (e: string | number) => void;
  value?: number;
  className?: string;
  error?: string;
  disabled?: boolean; 
}

const Counter = styled(class extends React.PureComponent<ICounterProps> {
  constructor(props) {
    super(props);
    this.handleMinusClick = this.handleMinusClick.bind(this);
    this.handlePlusClick = this.handlePlusClick.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
  }

  handleMinusClick() {
    if (this.props.value.toString() === 'NaN' || this.props.value.toString() === '' || this.props.value.toString() === '0') {
      this.props.onChange(0);
    } else {
      this.props.onChange(parseInt(this.props.value.toString()) - 1);
    }
  }

  handlePlusClick() {
    if (this.props.value.toString() === 'NaN' || this.props.value.toString() === '' || this.props.value.toString() === '0') {
      this.props.onChange(1);
    } else {
      this.props.onChange(parseInt(this.props.value.toString()) + 1);
    }
  }

  handleInputChange(e) {
    if (e.target.value == '') {
      this.props.onChange(e.target.value);
    } else {
      this.props.onChange(parseInt(e.target.value, 10) || 0);
    }
  }

  render() {

    const {
      className,
      compact,
      label,
      value,
      error,
      disabled,
    } = this.props;

    return <div className={className}>
      {
        !!label &&
        <CompactLabel compact={compact}>{label}</CompactLabel>
      }
      <InputContainer>
        <MinusButton compact={compact} onClick={this.handleMinusClick} disabled={disabled}/>
        <PlusButton compact={compact} onClick={this.handlePlusClick} disabled={disabled}/>
        <input type="number" onChange={this.handleInputChange} value={value} disabled={disabled}/>
      </InputContainer>
      <Error>
        {error}
      </Error>
    </div>;
  }
})`
${props => {
  const {
    global,
    light,
    warning,
    active,
  } = props.theme;
  const width = props.compact ? '126px' : '100%';
  const height = props.compact ? '30px' : 'auto';
  const borderColor = props.error ? warning : light;
  return `
    ${global}

    display: inline-flex;
    position: relative;
    
    input[type='number']::-webkit-outer-spin-button,
    input[type='number']::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0; 
    }

    input[type='number']:focus{
      outline-color: ${active}
    }

    input[type='number']:focus-within{
      outline-color: ${active}
    }
    
    input[type='number']{
      ${global}
      -moz-appearance:textfield;
      text-align: center;
      padding: 10px 35px;
      font-size: 14px;
      border-width: 1px;
      border-style: solid;
      border-color: ${borderColor};
      height: ${height};
      width: ${width};
    }      
  `;
}}

${MinusButton}, ${PlusButton}{
  ${props => {
    const {
      warning,
    } = props.theme;
    const warningColor = props.error ? warning : 'inherite';
    return `
      border-color: ${warningColor};     
      svg{
        path{
          fill: ${warningColor};
        }
      }
    `;
  }}
}

${Error}{
  ${({ theme, ...props }) => {

    const errorPosition = props.compact ?
      'absolute' :
      'static';

    return `
        position: ${errorPosition};
        top: 100%;
      `;
  }}
} 
`;

export default Counter;
import {IFlatLocationOption} from '../_reducers/current-user';

/**
 * Custom filter for invoices
 */

export function clientUnitFilterOptions(options: IFlatLocationOption[], inputValue: string): IFlatLocationOption[] {

  const subOptions: IFlatLocationOption[] = options.map(item => ({ ...item, disabled: item.level === 0 }));

  if (!inputValue || !inputValue.trim()) {
    return subOptions;
  }

  const parentLevels: number[] = [null, null];
  const optionsWithScore: IFlatLocationOption[] = [];

  subOptions.forEach((o, index: number) => {
    if (o.level < 2) {
      parentLevels[o.level] = index;
      if (o.level === 0) {
        return;
      }
    }
    const matchIndex = o.name.toLowerCase().search(inputValue.toLowerCase());
    if (matchIndex !== -1) {
      optionsWithScore.push({
        ...o,
        searchScore: 200 - matchIndex,
        parentIndexes: parentLevels.slice(0, o.level),
      });
    }
  });

  const includeMap = {};

  optionsWithScore.forEach(option => {
    includeMap[option.id] = true;
    option.parentIndexes.forEach(index => {
      includeMap[subOptions[index].id] = true;
    });
  });

  const searchResults: IFlatLocationOption[] = subOptions.filter(option => includeMap[option.id]);

  return searchResults;
}

/**
 * Custom filter for orders
 */

export function allClientUnitFilterOptions(options: IFlatLocationOption[], inputValue: string): IFlatLocationOption[] {

  const subOptions: IFlatLocationOption[] = options.map(item => ({ ...item, disabled: false }));

  if (!inputValue || !inputValue.trim()) {
    return subOptions;
  }

  const parentLevels: number[] = [null, null];
  const optionsWithScore: IFlatLocationOption[] = [];

  subOptions.forEach((o, index: number) => {
    if (o.level < 2) {
      parentLevels[o.level] = index;
    }
    const matchIndex = o.name.toLowerCase().search(inputValue.toLowerCase());
    if (matchIndex !== -1) {
      optionsWithScore.push({
        ...o,
        searchScore: 200 - matchIndex,
        parentIndexes: parentLevels.slice(0, o.level),
      });
    }
  });

  const includeMap = {};

  optionsWithScore.forEach(option => {
    includeMap[option.id] = true;
    option.parentIndexes.forEach(index => {
      includeMap[subOptions[index].id] = true;
    });
  });

  const searchResults: IFlatLocationOption[] = subOptions.filter(option => includeMap[option.id]);

  return searchResults;
}

import * as React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import Link from './Link';

import {
  TriangleIcon,
} from '../assets/icons';

import {
  Badge,
  Tooltip,
} from '../components';

export const DrawerContainer = styled.div`
  ${({ theme }) => {
    const {
      main,
    } = theme;
    return `
      margin-top: 0px; 
      height: 100%;
      width: 100%;
      background: ${main};
      overflow: hidden;
      box-shadow: 1px 1px 0px 1px transparent;
    `;
  }}
`;

interface IDrawerProps {
  className?: string;
  onToggle: () => void;
  isOpen: boolean;
}

export const Drawer = styled<IDrawerProps>(({
  className,
  children,
  onToggle,
}) => {

  function handleToggle() {
    if (onToggle) {
      onToggle();
    }
  }

  return <aside className={className}>
    <Link.Button onClick={handleToggle}><TriangleIcon /></Link.Button>
    <DrawerContainer>
      {children}
    </DrawerContainer>
  </aside>;
})`
  ${({ theme, ...props }) => {
    const width = props.isOpen ?
      '300px' :
      '86px';
    const buttonDiraction = props.isOpen ?
      'rotateX(180deg)' :
      'rotateX(0deg)';
    const spoilerVisibility = props.isOpen ?
      'rotateY(0deg)' :
      'rotateY(90deg)';
    const spoilerWidth = props.isOpen ?
      '180px' :
      '0px';
    const spoilerOpacity = props.isOpen ?
      '1' :
      '0';
    const headerPadding = props.isOpen ?
      '22px' :
      '12px';
    const menuItemWaveSize = props.isOpen ?
      'scale(15)' :
      'scale(1)';
    return `
      flex-direction: column;
      position: relative;
      flex: 0 0;
      flex-basis: ${width};
      margin-right: 2px;
      transition: flex-basis .5s ease-in-out;
      
      button.${(Link.Button as any).styledComponentId}{
        position: absolute;
        right: -28px;
        top: 33px;
        transition: all .5s ease-in-out;
        transform: rotate(-90deg) ${buttonDiraction};
        transform-origin: 50% 50%;

        &:hover{
          + .${(DrawerContainer as any).styledComponentId}{
            transition: all .5s ease-in-out;
            box-shadow: 1px 1px 0px 1px rgba(${theme.defaultRGB}, 0.2);
          }
        }
      }

      .${Drawer.Spoiler.styledComponentId}{
        transform: ${spoilerVisibility};
        transition: transform .5s ease-in-out, width .5s ease-in-out, padding-left .5s ease-in-out, opacity .5s ease-in-out;
        width: ${spoilerWidth};
        flex: 1 1;
        padding-left: 0px;
        transform-origin: 0% 50%;
        opacity: ${spoilerOpacity};
      }

      .${(SpoilerContainer as any).styledComponentId}{
        width: 210px;
      }

      .${Drawer.Header.styledComponentId}{
        padding-left: ${headerPadding}; 
        padding-right: 12px;
      }

      .${Drawer.MenuItem.styledComponentId}:hover{
        .${(MenuItemWave as any).styledComponentId}{
          transform: ${menuItemWaveSize};
        }
      }
    `;
  }}
`;

Drawer.propTypes = {
  status: PropTypes.bool,
};


interface IDrawerLogoProps {
  isOpen: boolean;
}

Drawer.Logo = styled<IDrawerLogoProps, 'div'>('div')`
  ${({ theme, ...props }) => {
    const {
      global,
      main,
    } = theme;
    const width = props.isOpen ?
      '300px' :
      '86px';
    return `
      ${global}
      display: flex;
      padding: 2px 18px;
      margin-bottom: 2px;
      flex: 0 0;
      flex-basis: ${width};
      transition: all .5s ease-in-out;
      background: ${main};
      text-align: center;
      align-items: center;
      justify-content: center;
    `;
  }}
`;

Drawer.Header = styled.header`
  ${({ theme }) => {
    const {
      global,
    } = theme;
    return `
      ${global}
      height: 116px;
      padding: 28px 29px 0;
      display: flex;
      align-items: center;
      font-size: 18px;
      transition: all .5s ease-in-out;
      .${(Link.Regular as any).styledComponentId}:first-of-type{
        font-size: 18px;
      }
    `;
  }}
`;

export const SpoilerContainer = styled.div`
  > * {
    display: block;
  }
`;

Drawer.Spoiler = styled<{className?: string}>(({ className, children }) => {
  return <div className={className}>
    <SpoilerContainer>
      {children}
    </SpoilerContainer>
  </div>;
})`
  box-sizing: border-box;
  transition: transform .5s ease-in-out, width .5s ease-in-out, padding-left .5s ease-in-out, opacity .5s ease-in-out;
  opacity: 0;
  width: 100%;
  overflow: hidden;
`;

const TooltipInnerContainer = styled<{isOpen: boolean}, 'div'>('div')`
  align-self: flex-start;
  padding: 12px 10px 12px 30px;
  margin-right: ${({isOpen}) => isOpen ? '0' : '10px'};
  transition: margin-right 0.25s ease-in-out;
`;

Drawer.Menu = styled<{className?: string}>(({ className, children }) => {
  return <ul className={className}>
    {children}
  </ul>;
})`
  padding: 0;
  margin: 0px;
  text-transform: uppercase;
`;

const MenuItemIcon = styled.div`
  ${({ theme }) => {
    const {
      light,
    } = theme;
    return `
      position: relative;
      width: 26px;
      height: 26px;
      i{
        width: 26px;
        height: 26px;
        margin: 0px;
        svg{
          path{
            fill: ${light};
          }
        }
      }
      &:hover{
        i{
          svg{
            path{
              fill: ${light};
            }
          }
        }
      }

      .${(Badge as any).styledComponentId}{
        position: absolute;
        right: -4px;
        top: -1px;
      }
    `;
  }}
`;

export const MenuItemContent = styled.span``;
export const MenuItemWave = styled.span`
  ${({ theme }) => {
    const {
      primary,
    } = theme;
    return `
    position: absolute;
    background: ${primary};
    opacity: 0.05;
    border-radius: 50%;
    width: 46px;
    height: 46px;
    top: 2px;
    bottom: 0px;
    left: 20px;
    right: 0px;
    transform: scale(0);
    transform-origin: 50% 50%;
    transition: transform 0.25s ease-in-out;
    pointer-event: none;
    `;
  }}
`;

Drawer.MenuItem = styled<{
  className,
  containe?: string | number,
  tooltipLabel?: string,
  renderIcon?: React.ReactNode,
  isDrawerOpen: boolean
}>(({
  className,
  children,
  renderIcon,
  containe,
  tooltipLabel,
  isDrawerOpen,
  ...linkProps
}) => {
  return <li className={className}>
    <Link.NavLink primary {...linkProps}>
      <Tooltip
        text={tooltipLabel}
        triggerActionName="hover"
        placement="right"
        appearance="dark"
        disabled={isDrawerOpen}
      >
        <TooltipInnerContainer isOpen={isDrawerOpen}>
          <MenuItemWave />
          <MenuItemIcon>
            {renderIcon && renderIcon}
          </MenuItemIcon>
          {
            containe &&
            <Badge />
          }
        </TooltipInnerContainer>
      </Tooltip>
      <Drawer.Spoiler>
        <MenuItemContent>
          {children}
        </MenuItemContent>
        {
          containe &&
          <Badge negative>{containe}</Badge>
        }
      </Drawer.Spoiler>
    </Link.NavLink>
  </li>;
})`
  ${({ theme }) => {
    const {
      light,
      primary,
    } = theme;
    return `
      list-style: none;
      position: relative;
      overflow: hidden;
      .${(Link.NavLink as any).styledComponentId}{
        padding: 0 10px 0 0;
        display: flex;
        align-items: center;
        font-size: 12px;
        font-weight: bold;
        &:hover{
          color: ${primary};
          i{
            svg{
              path{
                fill: ${light};
              }
            }
          }
        }
        &.active{
          color: ${theme.default};
          i{
            svg{
              path{
                fill: ${theme.default};
              }
            }
          }
        }
      }

      .${(SpoilerContainer as any).styledComponentId}{
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        padding-right: 20px;
      }

      &:hover{
        .${(MenuItemWave as any).styledComponentId}{
          transform: scale(1);
        }
      }
    `;
  }}
`;

Drawer.MenuItem.propTypes = {
  renderIcon: PropTypes.object,
  containe: PropTypes.string,
};

export default Drawer;

import * as React from 'react';
import PropTypes from 'prop-types';
import { connect } from "react-redux";
import { createStructuredSelector } from 'reselect';
import { bindActionCreators } from 'redux';
import * as actions from '../../_actions/layout';
import { IGlobalStore } from '../../_reducers/reducers';

interface IProps extends IConnectedProps, IConnectedActions {
  option: boolean;
}

class MenuHidder extends React.Component<IProps> {
  static propTypes = {
    children: PropTypes.oneOfType([
      PropTypes.array,
      PropTypes.string,
      PropTypes.object,
    ]),
    menuShow: PropTypes.func,
    menuStatus: PropTypes.bool,
    option: PropTypes.bool,
  };

  constructor(props) {
    super(props);
    this.props.menuShow(props.option);
  }

  componentWillUnmount() {
    this.props.menuShow(!this.props.option);
  }

  render() {
    return <React.Fragment>{this.props.children}</React.Fragment>;
  }
}

interface IConnectedProps {
  menuStatus: boolean;
}

interface IConnectedActions {
  menuShow: typeof actions.menuShow;
}

const ConnectedMenuHidder = connect(
  createStructuredSelector<IGlobalStore, IConnectedProps>({
    menuStatus: state => state.layout.menuStatus,
  }),
  dispatch => bindActionCreators({
    menuShow: actions.menuShow,
  }, dispatch))(MenuHidder);

type AllowedType<P> = React.ComponentType<P & IProps> | React.StatelessComponent<P & IProps>;

export default function withMenu<P extends object>(Component: AllowedType<P>, option: boolean) {
  return props => (
    <ConnectedMenuHidder option={option}>
      <Component {...props} />
    </ConnectedMenuHidder>
  );
}

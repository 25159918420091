export function formatPriceToUSLocale(n: number): string {
  return n.toLocaleString('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
}

export function normalizePrice(n: number): number {
  return Math.round(n * 100.0) / 100.0;
}

export function formatPrice(n: number | string): string {
  if (typeof n === 'number') {
    return formatPriceToUSLocale(n);
  } else if (typeof n === 'string') {
    const parsedPrice = parseFloat(n);
    if (!isNaN(parsedPrice)) {
      return formatPriceToUSLocale(parsedPrice);
    } else {
      return n;
    }
  } else {
    return '';
  }
}

export function roundAndFormatPrice(n: number | string): string {
  if (typeof n === 'number') {
    return formatPriceToUSLocale(normalizePrice(n));
  } else if (typeof n === 'string') {
    const parsedPrice = parseFloat(n);
    if (!isNaN(parsedPrice)) {
      return formatPriceToUSLocale(normalizePrice(parsedPrice));
    } else {
      return n;
    }
  } else {
    return '';
  }
}


import * as React from 'react';
import {createStructuredSelector} from 'reselect';
import { connect } from 'react-redux';
import styled from 'styled-components';
import {IGlobalStore} from '../../_reducers/reducers';
import {bindActionCreators} from 'redux';
import {logout, setCurrentUserLocation} from '../../_actions/current-user';
import {IShortLocationInfo} from '../../_types/common';
import {IFlatLocationOption} from '../../_reducers/current-user';
import Link from "../../components/Link";
import Button from "../../components/Button";
import {LocationSelectorComponent} from '../../components';

const PositionFooter = styled.div`
  text-align: right;
  padding: 20px;
  border-top: 1px solid ${({theme}) => theme.lighter};
`;

const PositionWrapper = styled.div`
  padding: 20px;
`;

const PositionCancelButton = styled.span`
  margin-right: 18px;
`;

const PositionButtonsWrap = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
`;

interface IProps extends IConnectedStoreProps, IConnectedActions {
  resolve: (r: any) => void;
  reject: (e: any) => void;
}

interface IState {
  selectedLocation: number | null;
}

class LocationSelectionModal extends React.Component<IProps, IState> {

  constructor(props) {
    super(props);

    this.state = {
      selectedLocation: null
    };

    this.handleLogout = this.handleLogout.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleLocationChange = this.handleLocationChange.bind(this);
  }

  handleLogout() {
    this.props.logout();
    this.props.resolve(null);
  }

  handleSubmit() {
    const locationOption = this.props.locationOptions.find(it => it.id === this.state.selectedLocation);
    if (locationOption) {
      this.props.setCurrentUserLocation(locationOption.id, locationOption.name, locationOption.catalogId);
      this.props.resolve(locationOption.id);
    }
  }

  handleLocationChange(locationOption) {
    this.setState({selectedLocation: locationOption.id});
  }

  render() {
    return (
      <React.Fragment>
        <PositionWrapper>
          <LocationSelectorComponent
            locationOptions={this.props.locationOptions}
            onLocationChange={this.handleLocationChange}
            selectedLocation={this.state.selectedLocation}
          />
        </PositionWrapper>
        <PositionFooter>
          <PositionButtonsWrap>
            <PositionCancelButton>
              <Link.Button
                primary
                onClick={this.handleLogout}
                scale="lg"
              >
                Logout
              </Link.Button>
            </PositionCancelButton>
            <Button type="submit"
              disabled={!this.state.selectedLocation}
              onClick={this.handleSubmit}
            >
              Submit
            </Button>
          </PositionButtonsWrap>
        </PositionFooter>
      </React.Fragment>
    );
  }
}

interface IConnectedStoreProps {
  location: IShortLocationInfo;
  locationOptions: IFlatLocationOption[];
}

interface IConnectedActions {
  setCurrentUserLocation: typeof setCurrentUserLocation;
  logout: typeof logout;
}

export default connect(
  createStructuredSelector<IGlobalStore, IConnectedStoreProps>({
    location: state => state.currentUser.locationInfo,
    locationOptions: state => state.currentUser.locationOptions,
  }),
  dispatch => bindActionCreators({
    logout,
    setCurrentUserLocation,
  }, dispatch)
)(LocationSelectionModal);

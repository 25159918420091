import React from "react";
import { Formik } from "formik";
import * as Yup from "yup";
import { bindActionCreators } from "redux";
import { createStructuredSelector } from "reselect/es";
import { connect } from "react-redux";
import { history } from "../../../store";
import { RouteComponentProps } from "react-router";

import {
  EditUserDto,
  ClientUnitShortDto,
  AddressOverrideListDto
} from "../../../service-proxies";

import withMenu from "../../HOC/WithMenu";
import { MainContainer } from "../../../components";
import Form from "./Form";
import withGranted, { IWithGrantedProps } from "../../HOC/WithGranted";
import {
  ADMINISTRATION_USERS_UPDATE,
  ADMINISTRATION_USERS_ROLES,
  ADMINISTRATION_USERS_ROLES_EDIT,
  ADMINISTRATION_USERS_PERMISSIONS,
  ADMINISTRATION_USERS_PERMISSIONS_EDIT,
  ADMINISTRATION_USERS_SUPERVISORS,
  ADMINISTRATION_USERS_SUPERVISORS_EDIT
} from "../../../_constants/permissions";

import * as actions from "../../../_actions/users";
import * as actionsSearch from "../../../_actions/search";
import * as actionsClients from "../../../_actions/clients";
import * as actionsModal from "../../../_actions/modal";
import { IValues, IUpdateUserExtended } from "./types";

const userValidationScheme = Yup.object().shape({
  name: Yup.string()
    .trim()
    .max(32)
    .required("Required field"),
  clientUnitIds: Yup.array().required(
    "At least one client unit must be specified"
  ),
  surname: Yup.string()
    .trim()
    .max(32)
    .required("Required field"),
  userName: Yup.string()
    .matches(/^[a-zA-Z]/, "must begin with alphabetic character")
    .matches(/^\S[\w\d]+$/, "must contain alphanumeric characters")
    .max(32)
    .required("Required field"),
  spendingTerm: Yup.string().required("Required field"),
  limit: Yup.string()
    .matches(/[0-9]+(\.[0-9][0-9]?)?/, "only decimals allowed")
    .max(14)
    .required("Required field"),
  unlimited: Yup.boolean(),
  emailAddress: Yup.string()
    .email("Invalid E-mail address")
    .max(256)
    .required("Required field"),
  phoneNumber: Yup.string()
    .min(10)
    .max(32)
    .matches(/.{10}/, "must contain 11 decimals")
});

interface IEditUserProps extends IWithGrantedProps, RouteComponentProps<{}> {
  userId: string;
  perPage?: number;
  skip?: number;
  status?: string;
  clientUnitsStatus?: string;
  addressOverridesStatus?: string;
  user?: EditUserDto;
  clientUnits?: ClientUnitShortDto[];
  addressOverrides?: AddressOverrideListDto[];
  getUserById: typeof actions.getUserById;
  getClientsTree: typeof actionsClients.getClientsTree;
  getOverrideLocations: typeof actions.getUserOverrideLocations;
  create: typeof actions.create;
  update: typeof actions.update;
  setSearch: typeof actionsSearch.set;
  openModal: typeof actionsModal.openCustomModalCallback;
  updateUserPermissions: typeof actions.updateUserPermissions;
}

class EditUser extends React.Component<IEditUserProps, IValues> {
  private isNew: boolean;
  constructor(props) {
    super(props);

    this.isNew = this.props.userId === "new";

    const tabsOptions = [{ value: "info", label: "USER INFORMATION" }];

    if (props.isGranted(ADMINISTRATION_USERS_ROLES)) {
      tabsOptions.push({ value: "roles", label: "ROLES" });
    }
    if (!this.isNew && props.isGranted(ADMINISTRATION_USERS_PERMISSIONS)) {
      tabsOptions.push({ value: "permissions", label: "PERMISSIONS" });
    }

    if (props.isGranted(ADMINISTRATION_USERS_SUPERVISORS)) {
      tabsOptions.push({ value: "buyingManagers", label: "BUYING MANAGERS" });
    }

    this.state = {
      id: props.userId,
      name: "",
      surname: "",
      userName: "",
      clientUnitIds: [],
      clientUnits: [],
      addressOverridesStatus: "",
      addressOverrides: [],
      emailAddress: "",
      phoneNumber: "",
      spendingTerm: null,
      location: null,
      limit: 0,
      unlimited: false,
      isActive: true,
      roles: [],
      rolesMap: {},
      permissionsMap: null,
      userSupervisorsMap: null,
      permissionsCollapsedMap: {},
      tab: "info",
      tabsOptions,
      isAllRolesLoaded: false,
      isAllPermissionsLoaded: false,
      isAllSupervisorsLoaded: false
    };

    this.handleSubmitInfo = this.handleSubmitInfo.bind(this);
    this.handleTabChanged = this.handleTabChanged.bind(this);
    this.isSupervisorsValid = this.isSupervisorsValid.bind(this);

    this.props.setSearch(`/admin/users/${this.props.userId}`, text => {
      // do nothing
    });
  }

  componentDidMount() {
    if (!this.isNew) {
      this.props.getUserById(this.props.userId);
      this.props.getOverrideLocations(this.props.userId);
    }

    this.props.getClientsTree();
  }

  componentDidUpdate(prevProps) {
    if (!this.isNew) {
      if (
        prevProps.status !== this.props.status &&
        this.props.status === "loaded"
      ) {
        const rolesMap = {};
        if (this.props.user.roles) {
          this.props.user.roles.forEach(role => {
            rolesMap[role] = true;
          });
        }
        this.setState({
          ...this.props.user,
          spendingTerm: this.props.user.spendingTerm
            ? this.props.user.spendingTerm
            : { id: 1, displayValue: "Weekly" },
          rolesMap
        });
      } else if (this.props.status === "saved") {
        history.push("/admin/users");
      }
    } else {
      if (this.props.status === "saved") {
        history.push("/admin/users");
      }
    }

    if (
      prevProps.clientUnitsStatus !== this.props.clientUnitsStatus &&
      this.props.clientUnitsStatus === "loaded"
    ) {
      this.setState({ clientUnits: this.props.clientUnits });
    }

    if (
      prevProps.addressOverrides !== this.props.addressOverrides &&
      this.props.addressOverridesStatus === "loaded"
    ) {
      this.setState({
        addressOverrides: this.props.addressOverrides,
        addressOverridesStatus: this.props.addressOverridesStatus
      });
    }
  }

  handleSubmitInfo(values) {
    const userData: IUpdateUserExtended = {
      clientUnitIds: values.clientUnitIds,
      userName: values.userName,
      name: values.name,
      surname: values.surname,
      emailAddress: values.emailAddress,
      isActive: true,
      phoneNumber: values.phoneNumber,
      spendingTermId: values.spendingTerm,
      limit: values.limit,
      unlimited: values.unlimited,
      roleNames: [],
      userSupervisors: [],
      id: null,
      buyingManagersLoaded: false
    };

    if (this.props.isGranted(ADMINISTRATION_USERS_ROLES_EDIT)) {
      userData.roleNames = Object.keys(values.rolesMap);
    }

    if (
      values.isAllPermissionsLoaded &&
      this.props.isGranted(ADMINISTRATION_USERS_PERMISSIONS_EDIT)
    ) {
      userData.permissionNames = Object.keys(values.permissionsMap);
    }

    let isValid = true;
    if (
      values.isAllSupervisorsLoaded &&
      this.props.isGranted(ADMINISTRATION_USERS_SUPERVISORS_EDIT)
    ) {
      isValid = this.isSupervisorsValid(values.userSupervisorsMap);
      userData.userSupervisors = [];
      userData.buyingManagersLoaded = true;
      for (const key in values.userSupervisorsMap) {
        if (Object.prototype.hasOwnProperty.call(values.userSupervisorsMap, key)) {
          values.userSupervisorsMap[key].forEach(item => {
            userData.userSupervisors.push(item);
          });
        }
      }
    }

    if (isValid) {
      if (this.isNew) {
        this.props.create(userData);
      } else {
        if (
          !this.props.isGranted(ADMINISTRATION_USERS_UPDATE) &&
          this.props.isGranted(ADMINISTRATION_USERS_PERMISSIONS_EDIT) &&
          userData.permissionNames
        ) {
          this.props.updateUserPermissions(
            this.props.userId,
            userData.permissionNames
          );
        } else {
          this.props.update({
            ...userData,
            id: this.props.userId
          });
        }
      }
    }
  }

  isSupervisorsValid(map) {
    let isValid = true;

    for (const key in map) {
      if (Object.prototype.hasOwnProperty.call(map, key)) {
        map[key].forEach(item => {
          if (item.error) {
            isValid = false;
          }
        });
      }
    }

    return isValid;
  }

  handleTabChanged(value) {
    this.setState({ tab: value });
  }

  render() {
    return (
      <MainContainer
        scale="md"
        title={this.isNew ? "New user setup" : "edit user"}
        subTitle={`Please fill out all the fields below properly to setup ${
          this.isNew ? "a new user" : "the user"
        } for existing client`}
        centered
      >
        <Formik
          enableReinitialize
          initialValues={this.state}
          validationSchema={userValidationScheme}
          validateOnBlur
          validateOnChange={false}
          onSubmit={this.handleSubmitInfo}
          render={props => (
            <Form
              {...props}
              isGranted={this.props.isGranted}
              openModal={this.props.openModal}
              isLoading={
                this.props.status === "loading" ||
                this.props.addressOverridesStatus === "loading" ||
                this.props.clientUnitsStatus === "loading"
              }
              isNew={this.isNew}
            />
          )}
        />
      </MainContainer>
    );
  }
}

const selector = createStructuredSelector({
  clientUnitsStatus: state => state.clients.clientUnitsStatus,
  status: state => state.users.status,
  perPage: state => state.users.perPage,
  skip: state => state.users.skip,
  user: state => state.users.user,
  clientUnits: state => state.clients.clientUnits,
  addressOverrides: state => state.users.addressOverrides,
  addressOverridesStatus: state => state.users.addressOverridesStatus
});

export default withMenu(
  withGranted(
    connect(
      selector,
      dispatch =>
        bindActionCreators(
          {
            create: actions.create,
            update: actions.update,
            updateUserPermissions: actions.updateUserPermissions,
            getUserById: actions.getUserById,
            getClientsTree: actionsClients.getClientsTree,
            getOverrideLocations: actions.getUserOverrideLocations,
            setSearch: actionsSearch.set,
            openModal: actionsModal.openCustomModalCallback
          },
          dispatch
        )
    )(EditUser)
  ),
  false
);

import * as React from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import { Option } from "react-select";
import { Field as FieldFormik, FieldArray } from "formik";

import withGranted, { IWithGrantedProps } from "../HOC/WithGranted";
import { apiUrl } from "../../_constants/system";
import { getNotes } from "../../_api/notes";
import { sendClientInvoice } from "../../_api/invoices";
import { searchPackageTypes } from "../../_api/unitOfMeasure";
import {
  SearchOutputDto,
  EditClientInvoiceItemDto,
  InvoiceManualEntryDto,
  NoteSearchOutputDto
} from "../../service-proxies";
import { notifySwagger } from "../../_actions/notification";
import {
  openCustomModalCallback,
  IOpenCustomModalCallback
} from "../../_actions/modal";
import { history } from "../../store";
import {
  Table,
  Thumbnail,
  Link,
  Article,
  Counter,
  Field,
  Select,
  Button,
  Skeleton,
  Toggle
} from "../../components";
import {
  CLIENTINVOICES_EDIT,
  CLIENTINVOICES_SEND
} from "../../_constants/permissions";
import ClientInvoicesModal from "./parts/ClientInvoicesModal";
import { normalizePrice, roundAndFormatPrice } from "../../_utils/prices";
import { IPrices, IValues } from "./types";
import { IMatch } from "src/_types/common";
import { ISelectProps } from "src/components/Select";
import ManualEntry from "../../sharedComponents/ManualEntry";
// import ReadOnlyNote from "./../../components/ReadOnlyNote";
import ManualEntryView from "./../../sharedComponents/ManualEntryView";

const CenteredContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  position: relative;
`;

const RightContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  height: 100%;
  width: 100%;
`;

const Body = styled.div`
  height: 100%;
`;

const Footer = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 20px 20px 30px;
`;

const Info = styled.div`
  text-align: right;
  padding: 0 20px 20px;
`;

const FooterElement = styled.div`
  > * {
    margin-left: 10px;
  }
`;

const LeftSidedContent = styled.div`
  width: 100%;
  text-align: right;
`;

const Position = class {
  static CenteredContainer = CenteredContainer;
  static RightContainer = RightContainer;
  static Body = Body;
  static Footer = Footer;
  static Info = Info;
  static FooterElement = FooterElement;
  static LeftSidedContent = LeftSidedContent;
};

interface IErrors {
  [props: string]: any;
}

interface INoteDto extends InvoiceManualEntryDto {
  displayValue: string;
  isNew?: boolean;
  invalid?: boolean;
}

interface IListSelect extends ISelectProps {
  creatable: boolean;
  productId: number;
  editable: boolean;
  compact: boolean;
  onChangeNote: (productId: number, newValue: Option<INoteDto> | null) => void;
  onDeleteNote: (productId: number, newValue: Option<INoteDto> | null) => void;
}

const ListSelect = styled<IListSelect>(({ productId, className, ...props }) => {
  function handleChange(options) {
    props.onChangeNote(productId, options);
  }
  function handleDelete(options) {
    props.onDeleteNote(productId, options);
  }
  return (
    <div className={className}>
      <Select {...props} onChange={handleChange} onDelete={handleDelete} />
    </div>
  );
})`
  height: 40px;
  ${Select} {
    position: absolute;
    margin: 20px, 0, 20px, 20px;
    width: 900px;
  }
`;

interface IViewFormProps extends IWithGrantedProps {
  match: IMatch;
  notifySwagger: typeof notifySwagger;
  pricesMap?: IPrices;
  productEntries?: EditClientInvoiceItemDto[];
  setFieldValue: (fieldName: string, newData: any) => void;
  setValues: (values: IValues) => void;
  handleBlur: any;
  handleSubmit: any;
  onNoteChange: (note: NoteSearchOutputDto, id: number) => void;
  onNoteDelete: (id: number, options: SearchOutputDto) => void;
  onUpdateManualEntry: (
    entry: InvoiceManualEntryDto,
    noteId,
    productId
  ) => void;
  values: IValues;
  errors: IErrors;
  openModal: IOpenCustomModalCallback;
  onSaveEdits: () => void;
  onFormChange: (type: string, id: number, data?: any) => void;
  hasBeenSent: boolean;
}

interface IViewFormState {
  notesStatus: string;
  notes: SearchOutputDto[];
  status: string;
}

interface IListToggleWrapper {
  id: number;
  invoiceId: number;
  onZeroChange: (id: number, invoiceId: number) => void;
  value: boolean;
  disabled: boolean;
}

const ListToggleWrapper: React.SFC<IListToggleWrapper> = ({
  id,
  invoiceId,
  onZeroChange,
  children,
  ...props
}) => {
  function handleChange() {
    onZeroChange(id, invoiceId);
  }

  return (
    <Toggle onChange={handleChange} {...props}>
      {children}
    </Toggle>
  );
};

class ViewForm extends React.Component<IViewFormProps, IViewFormState> {
  constructor(props) {
    super(props);
    this.getData = this.getData.bind(this);
    this.handleZeroPrice = this.handleZeroPrice.bind(this);
    this.handleNotesChange = this.handleNotesChange.bind(this);
    this.handleNotesDelete = this.handleNotesDelete.bind(this);
    this.handleClickBack = this.handleClickBack.bind(this);
    this.handleCreateSave = this.handleCreateSave.bind(this);
    this.getManualEntrySubTotal = this.getManualEntrySubTotal.bind(this);
    this.handleSaveEdits = this.handleSaveEdits.bind(this);
    this.handleFormChange = this.handleFormChange.bind(this);
    this.handleUpdateManualEntry = this.handleUpdateManualEntry.bind(this);
    this.handleAdditionalChargeIsTaxable = this.handleAdditionalChargeIsTaxable.bind(this);

    this.state = {
      notesStatus: "",
      notes: [],
      status: ""
    };
  }

  componentDidMount() {
    this.getData();
  }

  async getData(): Promise<any> {
    this.setState({ status: "loading" });
    try {
      const notes = await getNotes();
      const aNotes = notes.items.sort((a, b) =>
        a.displayValue > b.displayValue ? 1 : -1
      );
      //This is not maintainable and must be replaced using the Active Column in the Notes table of the DB.
      const filteredNotes = aNotes.filter(n => {
        return (
          n.displayValue !== "Non-Stock – Ships Separately" &&
          n.displayValue !==
            "Overshipment - Pending client decision; if kept, separate invoice to be generated"
        );
      });
      this.setState({
        notes: filteredNotes,
        status: "loaded"
      });
    } catch (e) {
      this.setState({ status: "error" });
      this.props.notifySwagger(e, "error");
    }
  }

  async handleZeroPrice(id: number, invoiceId: number) {
    try {
      if (!this.props.values.productEntries[id].isZeroPrice) {
        await this.props.openModal(
          (resolve, reject) => {
            return (
              <ClientInvoicesModal
                item={this.props.values.productEntries[id]}
                onClickReject={() => {
                  reject();
                }}
                onClickResolve={zeroPriceNote => {
                  if (!this.props.values.isChanged) {
                    this.props.setFieldValue("isChanged", true);
                  }
                  this.props.setFieldValue(
                    `productEntries.${id}.isZeroPrice`,
                    !this.props.values.productEntries[id].isZeroPrice
                  );
                  this.handleFormChange("zeroPrice", invoiceId);
                  this.props.setFieldValue(
                    `productEntries.${id}.zeroPriceNote`,
                    zeroPriceNote
                  );
                  resolve();
                }}
              />
            );
          },
          {
            title: "ZERO PRICE"
          }
        );
      } else {
        if (!this.props.values.isChanged) {
          this.props.setFieldValue("isChanged", true);
        }
        this.props.setFieldValue(
          `productEntries.${id}.isZeroPrice`,
          !this.props.values.productEntries[id].isZeroPrice
        );
        this.handleFormChange("zeroPrice", invoiceId);
        this.props.setFieldValue(`productEntries.${id}.zeroPriceNote`, null);
      }
    } catch (e) {
      if (e) {
        this.props.notifySwagger(e, "error");
      }
    }
  }

handleAdditionalChargeIsTaxable() {
  this.handleFormChange(
    "additionalChargeIsTaxable",
    null,
    null
  );
}

  handleClickBack(): void {
    history.goBack();
  }

  async handleCreateSave() {
    this.setState({ status: "saving" });
    try {
      if (!this.props.values.isChanged) {
        await this.handleSaveEdits();
      }
      await sendClientInvoice(this.props.values.id);
      history.push("/client-invoices");
    } catch (e) {
      this.setState({ status: "error" });
      this.props.notifySwagger(e, "error");
    }
  }

  renderPreloaderRows(perPage: number, status?: string): React.ReactNode {
    const rows = [];
    const elementsCount = status === "loading" ? perPage - 1 : 0;

    for (let i = 0; i < elementsCount; i++) {
      rows.push(
        <Table.Row
          key={"preloader" + i}
          rows={perPage}
          cells={[
            <Skeleton.Square key="thumb-preloader" />,
            <React.Fragment key="item-preloader">
              <p>
                <Skeleton.Line />
              </p>
              <p>
                <Skeleton.Line />
              </p>
              <p>
                <Skeleton.Line />
              </p>
            </React.Fragment>,
            <Skeleton.Line key="price-preloader" size="xs" />,
            <Skeleton.Line key="count-preloader" size="sm" />,
            <Skeleton.Line key="sub-price-preloader" size="xs" />,
            <Skeleton.Line key="action-preloader" size="xs" />,
            <Skeleton.Line key="action-preloader" size="xs" />
          ]}
        />
      );
    }
    return rows;
  }

  getOrderItemSubTotal(invoiceItem: EditClientInvoiceItemDto) {
    const isItemChanged = this.props.values.isChanged;
    if (!isItemChanged) {
      return invoiceItem.subTotalAmount;
    }
    return invoiceItem.isZeroPrice
      ? 0
      : normalizePrice(invoiceItem.price) * invoiceItem.quantity;
  }

  getOrderItemManualEntrySubTotal(invoiceItem: EditClientInvoiceItemDto) {
    return normalizePrice(
      invoiceItem.manualEntry.price * invoiceItem.manualEntry.quantity
    );
  }

  getManualEntrySubTotal() {
    const myReturn = this.props.values.productEntries.reduce(
      (result, invoiceItem) => {
        return (
          result +
          (invoiceItem.manualEntry &&
          invoiceItem.manualEntry.isAmountIncludedToInvoice
            ? this.getOrderItemManualEntrySubTotal(invoiceItem)
            : 0)
        );
      },
      0
    );

    return myReturn;
  }

  getManualEntryTaxes() {
    return this.props.values.taxRate
      ? (this.props.values.taxRate *
          this.props.values.productEntries.reduce((result, invoiceItem) => {
            if (invoiceItem.manualEntry) {
              return (
                result +
                (invoiceItem.manualEntry.isTaxable &&
                invoiceItem.manualEntry.isAmountIncludedToInvoice
                  ? this.getOrderItemManualEntrySubTotal(invoiceItem)
                  : 0)
              );
            }
            return result;
          }, 0)) /
          100
      : 0;
  }

  getSubTotal() {
    const isItemChanged = this.props.values.isChanged;
    if (!isItemChanged) {
      return this.props.values.subTotalAmount;
    }
    return (
      this.props.values.productEntries.reduce((result, invoiceItem) => {
        return result + this.getOrderItemSubTotal(invoiceItem);
      }, 0) + this.getManualEntrySubTotal()
    );
  }

  getAdditionalChargeTaxes() {
    return this.props.values.additionalChargeIsTaxable
    ? (this.props.values.taxRate *
        this.props.values.additionalChargeAmount) / 100
    : 0;
  }

  getTaxes() {
    const isItemChanged = this.props.values.isChanged;
    if (!isItemChanged) {
      return this.props.values.taxesTotal;
    }

    return this.props.values.taxRate
      ? (this.props.values.taxRate *
          this.props.values.productEntries.reduce((result, invoiceItem) => {
            return (
              result +
              (!invoiceItem.isTaxable
                ? 0
                : this.getOrderItemSubTotal(invoiceItem))
            );
          }, 0)) /
          100 +
          this.getManualEntryTaxes()
      : 0;
  }

  getAdditionalCharge() {
      return normalizePrice(this.props.values.additionalChargeAmount);
  }

  getTotal() {
    const taxRate = this.getTaxes();
    const additionalChargeTax = this.getAdditionalChargeTaxes();
    const isItemChanged = this.props.values.isChanged;
    if (!isItemChanged) {
      return this.props.values.totalAmount;
    }
    return (
      this.props.values.productEntries.reduce((result, invoiceItem) => {
        return result + this.getOrderItemSubTotal(invoiceItem);
      }, 0) +
      taxRate +
      additionalChargeTax +
      this.getAdditionalCharge() +
      this.getManualEntrySubTotal()
    );
  }

  loadPackageTypes(value, callback) {
    searchPackageTypes(value)
      .then(({ items }) => {
        callback(null, {
          options: items,
          complete: true
        });
      })
      .catch(e => {});
  }

  handleNotesChange(id, value: NoteSearchOutputDto) {
    this.props.onNoteChange(value, id);
  }

  handleUpdateManualEntry(entry: InvoiceManualEntryDto, noteId, productId) {
    this.props.onUpdateManualEntry(entry, noteId, productId);
  }

  handleNotesDelete(id, options) {
    this.props.onNoteDelete(options, id);
  }

  handleSaveEdits() {
    this.props.onSaveEdits();
  }

  handleFormChange(type: string, id: number, data?: any) {
    this.props.onFormChange(type, id, data);
  }

  render() {
    const { values, errors } = this.props;

      return (
    <form onSubmit={this.props.handleSubmit}>
        <Position.Body>
          {
            <Table
              columns={[
                { name: "thumb", label: "", size: "xs" },
                { name: "item", label: "item", size: "xl" },
                {
                  name: "price",
                  label: "price",
                  size: "md",
                  alignment: "left"
                },
                { name: "quantity", label: "quantity", alignment: "center" },
                { name: "subtotal", label: "subtotal", alignment: "right" },
                { name: "uom", label: "uom", size: "lg", alignment: "left" },
                { name: "toolbar", label: "Zero Price", size: "xl" }
              ]}
            >
              <Table.Header />
              <Table.Body>
                {(values.status === "loaded" ||
                  values.status === "saving" ||
                  values.status === "done") && (
                  <FieldArray
                    name="productEntries"
                    render={arrayHelpers =>
                      values.productEntries &&
                      !!values.productEntries.length &&
                      values.productEntries.map((invoiceItem, index) => {
                        return (
                          <React.Fragment
                            key={`container-order-product-${index}`}
                          >
                            <Table.Row
                              key={`order-product-${index}`}
                              className="print-list-item"
                              cells={[
                                <Thumbnail
                                  image={
                                    invoiceItem.product.picture
                                      ? apiUrl +
                                        invoiceItem.product.picture.pictureUrl
                                      : null
                                  }
                                  key={`thumbnail-${index}`}
                                />,
                                <React.Fragment key={`item-${index}`}>
                                  <p>
                                    <Link
                                      key={`item-${index}`}
                                      primary
                                      to={`/products/product/${index}`}
                                    >
                                      <span style={{ color: "#000" }}>
                                        {invoiceItem.product.name}
                                      </span>
                                    </Link>
                                  </p>
                                  <p>
                                    <span style={{ color: "#000" }}>
                                      {invoiceItem.product.sku}
                                    </span>
                                  </p>
                                </React.Fragment>,
                                <Position.CenteredContainer
                                  key={`productEntries.${index}.price`}
                                >
                                  {this.props.isGranted(CLIENTINVOICES_EDIT) ? (
                                    <FieldFormik
                                      name={`productEntries.${index}.price`}
                                      render={props => (
                                        <Field
                                          {...props.field}
                                          value={
                                            invoiceItem.isZeroPrice
                                              ? "0.00"
                                              : props.field.value
                                          }
                                          compact
                                          id={index}
                                          label="$"
                                          scale="sm"
                                          disabled={
                                            values.isVisibleToClient ||
                                            invoiceItem.isZeroPrice
                                          }
                                          error={
                                            errors.productEntries &&
                                            errors.productEntries[index] &&
                                            errors.productEntries[index].price
                                          }
                                          onChange={e => {
                                            if (!values.isChanged) {
                                              props.form.setFieldValue(
                                                "isChanged",
                                                true
                                              );
                                            }
                                            props.form.setFieldValue(
                                              props.field.name,
                                              e.target.value
                                            );
                                            this.handleFormChange(
                                              "price",
                                              invoiceItem.id,
                                              e.target.value
                                            );
                                          }}
                                        />
                                      )}
                                    />
                                  ) : (
                                    `$${values.productEntries[index] &&
                                      values.productEntries[index].price}`
                                  )}
                                </Position.CenteredContainer>,
                                <Position.CenteredContainer
                                  key={`productEntries.${index}.quantity`}
                                >
                                  {this.props.isGranted(CLIENTINVOICES_EDIT) ? (
                                    <FieldFormik
                                      name={`productEntries.${index}.quantity`}
                                      render={props => (
                                        <Counter
                                          {...props.field}
                                          compact
                                          disabled={values.isVisibleToClient}
                                          error={
                                            errors.productEntries &&
                                            errors.productEntries[index] &&
                                            errors.productEntries[index]
                                              .quantity
                                          }
                                          onChange={e => {
                                            if (!values.isChanged) {
                                              props.form.setFieldValue(
                                                "isChanged",
                                                true
                                              );
                                            }
                                            props.form.setFieldValue(
                                              props.field.name,
                                              e
                                            );
                                            this.handleFormChange(
                                              "quantity",
                                              invoiceItem.id,
                                              e
                                            );
                                          }}
                                        />
                                      )}
                                    />
                                  ) : (
                                    `${values.productEntries[index] &&
                                      values.productEntries[index].quantity}`
                                  )}
                                </Position.CenteredContainer>,
                                <Position.RightContainer
                                  key={`productEntries.${index}.quantity`}
                                >
                                  {roundAndFormatPrice(
                                    this.getOrderItemSubTotal(invoiceItem)
                                  )}
                                </Position.RightContainer>,
                                <Position.CenteredContainer
                                  key={`productEntries.${index}.packageType`}
                                >
                                  {this.props.isGranted(CLIENTINVOICES_EDIT) ? (
                                    <React.Fragment>
                                      <FieldFormik
                                        name={`productEntries.${index}.packageType`}
                                        render={props => (
                                          <Select
                                            {...props.field}
                                            async
                                            loadOnFocus
                                            autoload={false}
                                            loadOptions={this.loadPackageTypes}
                                            compact
                                            labelKey={"displayValue"}
                                            error={
                                              errors.productEntries &&
                                              errors.productEntries[index] &&
                                              errors.productEntries[index]
                                                .packageType
                                            }
                                            onChange={e => {
                                              if (!values.isChanged) {
                                                props.form.setFieldValue(
                                                  "isChanged",
                                                  true
                                                );
                                              }
                                              props.form.setFieldValue(
                                                props.field.name,
                                                e
                                              );
                                              this.handleFormChange(
                                                "uom",
                                                invoiceItem.id,
                                                e
                                              );
                                            }}
                                            disabled={values.isVisibleToClient}
                                            value={invoiceItem.packageType}
                                            valueKey={"id"}
                                          />
                                        )}
                                      />
                                      {errors.productEntries &&
                                        errors.productEntries[index] &&
                                        errors.productEntries[index].uom}
                                    </React.Fragment>
                                  ) : (
                                    `${values.productEntries[index] &&
                                      values.productEntries[index].packageType
                                        .displayValue}`
                                  )}
                                </Position.CenteredContainer>,
                                <Position.CenteredContainer
                                  key={`productEntries.${index}.isZeroPrice`}
                                >
                                  <Position.LeftSidedContent>
                                    <ListToggleWrapper
                                      id={index}
                                      invoiceId={invoiceItem.id}
                                      onZeroChange={this.handleZeroPrice}
                                      value={invoiceItem.isZeroPrice}
                                      disabled={values.isVisibleToClient}
                                    />
                                  </Position.LeftSidedContent>
                                </Position.CenteredContainer>
                              ]}
                            />
                            {invoiceItem.zeroPriceNote && (
                              <Table.Row
                                key={`order-product-zero-price-${index}`}
                                colSpan="7"
                                cells={[
                                  <React.Fragment
                                    key={`order-product-zero-price-control-${index}`}
                                  >
                                    <Article.Text scale="lg">
                                      {invoiceItem.zeroPriceNote}
                                    </Article.Text>
                                  </React.Fragment>
                                ]}
                              />
                            )}
                            {this.props.isGranted(CLIENTINVOICES_EDIT) &&
                            !this.props.hasBeenSent ? (
                              <React.Fragment>
                                <Table.Row
                                  key={`list-select-drop-down-${index}`}
                                  colSpan="7"
                                  cells={[
                                    <ListSelect
                                      className={
                                        invoiceItem.note
                                          ? ""
                                          : "print-empty-note"
                                      }
                                      productId={invoiceItem.product.id}
                                      creatable={false}
                                      options={this.state.notes}
                                      value={
                                        invoiceItem.note
                                          ? invoiceItem.note.id
                                          : null
                                      }
                                      onChangeNote={this.handleNotesChange}
                                      onDeleteNote={this.handleNotesDelete}
                                      isLoading={
                                        this.state.notesStatus === "loading"
                                      }
                                      disabled={
                                        this.state.notesStatus === "loading"
                                      }
                                      valueKey="id"
                                      labelKey="displayValue"
                                      placeholder={`Add your notes to ${invoiceItem.product.name}...`}
                                      editable={false}
                                      compact
                                    />
                                  ]}
                                />
                                {invoiceItem.note &&
                                invoiceItem.note.isManualEntryRequired ? (
                                  <Table.Row
                                    key={`manual-entry-fields${index}`}
                                    colSpan="7"
                                    cells={[
                                      <ManualEntry
                                        key={invoiceItem.note.id}
                                        entry={invoiceItem.manualEntry}
                                        noteId={invoiceItem.note.id}
                                        validationType={
                                          invoiceItem.note.manualEntryType
                                        }
                                        description={
                                          invoiceItem.note.displayValue
                                        }
                                        productId={invoiceItem.product.id}
                                        onChange={this.handleUpdateManualEntry}
                                        isNew={(invoiceItem.note as any).isNew}
                                        isInvalid={
                                          (invoiceItem.note as any).invalid
                                        }
                                      />
                                    ]}
                                  />
                                ) : null}
                              </React.Fragment>
                            ) : (
                              this.props.isGranted(CLIENTINVOICES_EDIT) &&
                              this.props.hasBeenSent &&
                              invoiceItem.note && (
                                <ManualEntryView
                                  colSpan={7}
                                  description={invoiceItem.note.displayValue}
                                  entry={invoiceItem.manualEntry}
                                />
                              )
                            )}
                          </React.Fragment>
                        );
                      })
                    }
                  />
                )}
                {values.status === "loading" &&
                  this.renderPreloaderRows(6, values.status)}
                {values.productEntries && (
                  <>
                    <Table.Row
                      cells={[
                        "",
                        "",
                        "",
                        <Position.RightContainer
                          key={`order-product-price-total-label`}
                        >
                          <Article.Text light scale="sm">
                            SUB TOTAL:
                          </Article.Text>
                        </Position.RightContainer>,
                        <Article.Text
                          scale="sm"
                          key={`order-product-price-total-label`}
                        >
                          {roundAndFormatPrice(this.getSubTotal())}
                        </Article.Text>,
                        "",
                        ""
                      ]}
                    />
                    <Table.Row
                      cells={[
                        "",
                        "",
                        "",
                        <Position.RightContainer
                          key={`order-product-price-total-label`}
                        >
                          <Article.Text light scale="sm">
                            TAXES ({values.taxRate}%):
                          </Article.Text>
                        </Position.RightContainer>,
                        <Article.Text
                          scale="sm"
                          key={`order-product-price-total-label`}
                        >
                          {roundAndFormatPrice(this.getTaxes())}
                        </Article.Text>,
                        "",
                        ""
                      ]}
                    />
                    <Table.Row
                      cells={[
                        "",
                        "",
                        "",
                        <Position.RightContainer
                          key={`order-product-price-total-label`}
                        >
                          <Article.Text light scale="sm">
                            Additional Charge:
                          </Article.Text>
                        </Position.RightContainer>,
                        <Position.CenteredContainer
                        key={`additionalChargeAmount`}
                      >
                        {this.props.isGranted(CLIENTINVOICES_EDIT) ? (
                          <FieldFormik
                            name={`additionalChargeAmount`}
                            render={props => (
                              <Field
                                {...props.field}
                                value={
                                  props.field.value
                                }
                                compact
                                label="$"
                                scale="sm"
                                disabled={
                                  values.isVisibleToClient
                                }
                                error={
                                  false
                                }
                                onChange={e => {
                                  if (!values.isChanged) {
                                    props.form.setFieldValue(
                                      "isChanged",
                                      true
                                    );
                                  }
                                  props.form.setFieldValue(
                                    props.field.name,
                                    e.target.value
                                  );
                                  this.handleFormChange(
                                    "additionalChargeAmount",
                                    null,
                                    e.target.value
                                  );
                                }}
                              />
                            )}
                          />
                        ) : (
                          `$${values.additionalChargeAmount}`
                        )}
                      </Position.CenteredContainer>,
                      <Position.RightContainer
                      key={`order-product-price-total-label`}
                    >
                      <Article.Text light scale="sm">
                        Taxable:
                      </Article.Text>
                    </Position.RightContainer>,
                      <Position.CenteredContainer
                      key={`additionalChargeIsTaxable`}
                    >
                      <Position.LeftSidedContent>
                        <ListToggleWrapper
                          id={0}
                          invoiceId={0}
                          onZeroChange={this.handleAdditionalChargeIsTaxable}
                          value={values.additionalChargeIsTaxable}
                          disabled={values.isVisibleToClient}
                        />
                      </Position.LeftSidedContent>
                    </Position.CenteredContainer>
                      ]}
                    />
                    <Table.Row
                      key={`additionalChargeReson-text`}
                      colSpan="7"
                      cells={[
                        <Position.RightContainer
                      key={`additionalChargeReason`}
                    >
                      {this.props.isGranted(CLIENTINVOICES_EDIT) ? (
                        <FieldFormik
                          name={`additionalChargeReason`}
                          render={props => (
                            <Field
                              {...props.field}
                              placeholder="Additional Charge Reason"
                              value={
                                props.field.value
                              }
                              scale="md"
                              disabled={
                                values.isVisibleToClient
                              }
                              error={
                                false
                              }
                              onChange={e => {
                                if (!values.isChanged) {
                                  props.form.setFieldValue(
                                    "isChanged",
                                    true
                                  );
                                }
                                props.form.setFieldValue(
                                  props.field.name,
                                  e.target.value
                                );
                                this.handleFormChange(
                                  "additionalChargeReason",
                                  null,
                                  e.target.value
                                );
                              }}
                            />
                          )}
                        />
                      ) : (
                        `$${values.additionalChargeReason}`
                      )}
                    </Position.RightContainer>
                      ]}
                    />
                    {values.additionalChargeIsTaxable ? <Table.Row
                      cells={[
                        "",
                        "",
                        "",
                        <Position.RightContainer
                          key={`order-product-price-total-label`}
                        >
                          <Article.Text light scale="sm">
                            Additional Charge TAXES ({values.taxRate}%):
                          </Article.Text>
                        </Position.RightContainer>,
                        <Article.Text
                          scale="sm"
                          key={`order-product-price-total-label`}
                        >
                          {roundAndFormatPrice(this.getAdditionalChargeTaxes())}
                        </Article.Text>,
                        "",
                        ""
                      ]}
                    /> : ""}
                    <Table.Row
                      cells={[
                        "",
                        "",
                        "",
                        <Position.RightContainer
                          key={`order-product-price-total-label`}
                        >
                          <Article.P light scale="lg">
                            TOTAL:
                          </Article.P>
                        </Position.RightContainer>,
                        <Article.P
                          scale="lg"
                          key={`order-product-price-total-label`}
                        >
                          {roundAndFormatPrice(this.getTotal())}
                        </Article.P>,
                        "",
                        ""
                      ]}
                    />
                  </>
                )}
              </Table.Body>
            </Table>
          }
          <Position.Info>
            {(values.status === "loaded" ||
              values.status === "saving" ||
              values.status === "done") &&
            values.location ? (
              <Article.P scale="sm">
                Facility Location: {values.location.displayValue}
              </Article.P>
            ) : null}
          </Position.Info>
        </Position.Body>
        <Position.Footer>
          <Position.FooterElement />
          <Position.FooterElement>
            <Link.Button
              onClick={this.handleClickBack}
              preloader={
                values.status === "loading" || values.status === "saving"
              }
            >
              Back
            </Link.Button>
            {this.props.isGranted(CLIENTINVOICES_EDIT) &&
              values.isChanged &&
              !values.isVisibleToClient && (
                <Button
                  onClick={this.handleSaveEdits}
                  preloader={
                    values.status === "loading" || values.status === "saving"
                  }
                  disabled={
                    (errors.productEntries && !!errors.productEntries.length) ||
                    values.status === "loading" ||
                    values.status === "saving"
                  }
                >
                  Save Edits
                </Button>
              )}
            {this.props.isGranted(CLIENTINVOICES_SEND) &&
              !values.isVisibleToClient && (
                <Button
                  onClick={this.handleCreateSave}
                  preloader={
                    values.status === "loading" || values.status === "saving"
                  }
                  disabled={
                    (errors.productEntries && !!errors.productEntries.length) ||
                    values.status === "loading" ||
                    values.status === "saving" ||
                    values.isChanged
                  }
                  primary={values.isChanged}
                >
                  Create and Send Invoice
                </Button>
              )}
          </Position.FooterElement>
        </Position.Footer>
      </form>
    );
  }
}

export default withGranted(
  connect(null, {
    notifySwagger,
    openModal: openCustomModalCallback
  })(ViewForm)
);

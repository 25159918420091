import React from 'react';
import styled from 'styled-components';

const SearchIcon = props => {
  return(
    <i {...props}>
      <svg viewBox="-2647 -668 17.29 17.29" width="17.29" height="17.29">
        <path d=" M -2639.8 -668 C -2643.768 -668 -2647 -664.768 -2647 -660.8 C -2647 -656.832 -2643.768 -653.6 -2639.8 -653.6 C -2638.074 -653.6 -2636.49 -654.213 -2635.248 -655.23 C -2635.248 -655.23 -2630.949 -650.931 -2630.949 -650.931 C -2630.768 -650.743 -2630.5 -650.667 -2630.248 -650.733 C -2629.996 -650.799 -2629.799 -650.996 -2629.733 -651.248 C -2629.667 -651.5 -2629.743 -651.768 -2629.931 -651.949 C -2629.931 -651.949 -2634.23 -656.248 -2634.23 -656.248 C -2633.213 -657.49 -2632.6 -659.075 -2632.6 -660.8 C -2632.6 -664.768 -2635.832 -668 -2639.8 -668 Z  M -2639.8 -666.56 C -2636.61 -666.56 -2634.04 -663.99 -2634.04 -660.8 C -2634.04 -657.61 -2636.61 -655.04 -2639.8 -655.04 C -2642.99 -655.04 -2645.56 -657.61 -2645.56 -660.8 C -2645.56 -663.99 -2642.99 -666.56 -2639.8 -666.56 Z "/>
      </svg>
    </i>
  );
};

export default styled(SearchIcon)`
  ${ props => `
    width: 21px;
    height: 21px;
    display: inline-flex;
    justify-content: center;

    svg{
      path{
        fill: ${props.theme.light}; 
      }
    }
  `}
`;

import { types } from '../_actionTypes/current-user';
import * as userSession from '../_utils/userSession';
import { IShortLocationInfo, SimpleMap } from '../_types/common';
import { GetCurrentLoginInformationsOutput } from '../service-proxies';

export interface ICurrentUserStore {
  isAuth: boolean;
  loginInfo?: GetCurrentLoginInformationsOutput;
  accessToken?: string;
  encryptedAccessToken?: string;
  loginRedirect?: string;
  expireInSeconds?: number;
  locationInfo?: IShortLocationInfo;
  catalogId?: number;
  userId?: number;
  locationOptions?: IFlatLocationOption[];
  permissions?: SimpleMap;
  loginError?: {
    message: string;
    details: string;
  };
}

export interface IFlatLocationOption {
  id: number;
  name: string;
  level: number;
  disabled: boolean;
  path: string[];
  catalogId: number | null;
  searchScore?: number;
  parentIndexes?: number[];
}

const initialState: ICurrentUserStore = {
  isAuth: !!userSession.getToken(),
  loginInfo: null,
  accessToken: userSession.getToken(),
  encryptedAccessToken: '',
  loginRedirect: '/',
  expireInSeconds: null,
  locationInfo: {
    id: null,
    name: null,
  },
  catalogId: null,
  userId: null,
  loginError: null,
};

interface IActionLogin {
  type: types.CURRENT_USER_LOGIN;
  data: Partial<ICurrentUserStore>;
}

interface IActionLoginError {
  type: types.CURRENT_USER_LOGIN_ERROR;
  message: string;
  details: string;
}

interface IActionLogout {
  type: types.CURRENT_USER_LOGOUT;
}

interface IActionError {
  type: types.CURRENT_USER_ERROR;
}

export interface IActionClearAuthData {
  type: types.CURRENT_USER_CLEAR_AUTH_DATA;
}

interface IActionRedirectToLogin {
  type: types.CURRENT_USER_REDIRECT_TO_LOGIN;
  currentRoute: string;
}

interface IActionLoginInformation {
  type: types.CURRENT_USER_LOGIN_INFORMATION;
  loginInfo: GetCurrentLoginInformationsOutput;
  locationInfo: IShortLocationInfo;
  locationOptions: IFlatLocationOption[];
  catalogId: number | null;
  permissionsMap: SimpleMap;
}

interface IActionLocationSelected {
  type: types.CURRENT_USER_LOCATION_SELECTED;
  location: IShortLocationInfo;
  catalogId: number | undefined;
}

type IAction = IActionLogin
  | IActionLogin
  | IActionLoginError
  | IActionLogout
  | IActionError
  | IActionClearAuthData
  | IActionRedirectToLogin
  | IActionLoginInformation
  | IActionLocationSelected;

export default function (state: ICurrentUserStore = initialState, action: IAction): ICurrentUserStore {
  switch (action.type) {
    case types.CURRENT_USER_LOGIN:
      return ({
        ...state,
        isAuth: true,
        loginError: null,
        ...action.data,
      });
    case types.CURRENT_USER_LOGIN_ERROR:
      return ({
        ...state,
        loginError: {
          message: action.message,
          details: action.details,
        }
      });
    case types.CURRENT_USER_LOGOUT:
      return ({
        ...initialState,
        isAuth: false,
        accessToken: null,
      });
    case types.CURRENT_USER_ERROR:
      return {
        isAuth: false,
      };

    case types.CURRENT_USER_CLEAR_AUTH_DATA:
      return {
        isAuth: false,
      };
    case types.CURRENT_USER_REDIRECT_TO_LOGIN:
      return ({
        isAuth: false,
        loginRedirect: action.currentRoute,
      });
    case types.CURRENT_USER_LOGIN_INFORMATION:
      return {
        ...state,
        loginInfo: action.loginInfo,
        locationInfo: action.locationInfo,
        locationOptions: action.locationOptions,
        catalogId: action.catalogId,
        permissions: action.permissionsMap,
      };
    case types.CURRENT_USER_LOCATION_SELECTED:
      return {
        ...state,
        locationInfo: {
          id: action.location.id,
          name: action.location.name,
        },
        catalogId: action.catalogId,
      };
    default:
      return state;
  }
}

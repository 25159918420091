import React from 'react';
import styled from 'styled-components';

export default styled(props => {
  return (
    <i {...props}>
      <svg viewBox="3124 10202 20.953 14.967" width="20.953" height="14.967">
        <path d=" M 3134.477 10202 C 3132.495 10202 3130.773 10202.927 3129.542 10204.292 C 3129.44 10204.28 3129.349 10204.245 3129.238 10204.245 C 3127.593 10204.245 3126.245 10205.593 3126.245 10207.238 C 3124.95 10208.027 3124 10209.36 3124 10210.979 C 3124 10213.45 3126.02 10215.47 3128.49 10215.47 Q 3128.49 10215.47 3132.231 10215.47 L 3132.231 10213.973 Q 3128.49 10213.973 3128.49 10213.973 C 3126.83 10213.973 3125.497 10212.641 3125.497 10210.979 C 3125.497 10209.737 3126.251 10208.677 3127.321 10208.221 Q 3127.321 10208.221 3127.835 10208.01 Q 3127.765 10207.448 3127.765 10207.448 C 3127.75 10207.326 3127.742 10207.256 3127.742 10207.238 C 3127.742 10206.402 3128.402 10205.741 3129.238 10205.741 C 3129.344 10205.741 3129.46 10205.756 3129.589 10205.788 Q 3129.589 10205.788 3130.057 10205.905 Q 3130.337 10205.531 3130.337 10205.531 C 3131.296 10204.301 3132.793 10203.497 3134.477 10203.497 C 3136.929 10203.497 3138.975 10205.184 3139.551 10207.448 Q 3139.551 10207.448 3139.691 10208.033 Q 3140.323 10208.01 3140.323 10208.01 C 3140.48 10208.001 3140.516 10207.986 3140.463 10207.986 C 3142.123 10207.986 3143.456 10209.319 3143.456 10210.979 C 3143.456 10212.641 3142.123 10213.973 3140.463 10213.973 Q 3140.463 10213.973 3136.721 10213.973 L 3136.721 10215.47 Q 3140.463 10215.47 3140.463 10215.47 C 3142.933 10215.47 3144.953 10213.45 3144.953 10210.979 C 3144.953 10208.51 3143.091 10206.727 3140.767 10206.561 C 3139.852 10203.927 3137.414 10202 3134.477 10202 Z  M 3134.477 10207.986 L 3131.483 10210.979 L 3133.728 10210.979 L 3133.728 10216.967 L 3135.225 10216.967 L 3135.225 10210.979 L 3137.47 10210.979 L 3134.477 10207.986 Z "/>
      </svg>
    </i>
  );
})`
  ${ props => `
    width: 21px;
    height: 21px;
    display: inline-flex;
    justify-content: center;
    align-items: center;

    svg{
      path{
        fill: ${props.theme.primary}; 
      }
    }
  `}
`;

import { locationServiceProxy } from '../servicePool';

export function getAddressOverrideForEdit(userId, callback) {
  locationServiceProxy.getAddressOverrideForEdit(userId).then(data => {
    callback(data);
  });
}

export function updateAddressOverride(addressOverride, callback) {
  locationServiceProxy.updateAddressOverride(addressOverride).then(data => {
    callback(data);
  });
}

export function createAddressOverride(addressOverride, callback) {
  locationServiceProxy.createAddressOverride(addressOverride).then(data => {
    callback(data);
  });
}

export function deleteAddressOverride(id, callback) {
  locationServiceProxy.deleteAddressOverride(id).then(() => {
    callback();
  });
}

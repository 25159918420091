import * as React from 'react';
import { MainContainer } from '../../components';
import { NotFound } from '../../components';


export default function(props) {
  return (
    <MainContainer>
      <NotFound {...props}/>
    </MainContainer>
  );
}

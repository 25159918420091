import * as React from "react";
import styled, { StyledComponentClass } from "styled-components";
import { Formik } from "formik";
import * as Yup from "yup";
import { createStructuredSelector } from "reselect";
import { RouteComponentProps, withRouter } from "react-router";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import moment from "moment";

import { EditAllOrderDto } from "../../../service-proxies";
import {
  ORDERS_ALLORDERS_SUBMITORAPPROVE,
  ORDERS_ALLORDERS_EDIT,
  ORDERS_ALLORDERS_CANCEL
} from "../../../_constants/permissions";

import { IGlobalStore } from "../../../_reducers/reducers";
import withGranted, { IWithGrantedProps } from "../../HOC/WithGranted";
import { history } from "../../../store";
import {
  prompt as openConfirm,
  IOpenConfirmPrompt
} from "../../../_actions/modal";
import { notifySwagger } from "../../../_actions/notification";
import {
  approveAllOrder,
  submitAllOrder,
  cancelAllOrder,
  updateAllOrder
} from "../../../_api/orders";
import {
  MainContainer,
  Button,
  Article,
  Link,
  Skeleton,
  MessageBar
} from "../../../components";
import Title from "../../../sharedComponents/Title";
import * as ordersApi from "../../../_api/orders";
import withMenu from "../../../containers/HOC/WithMenu";
import { INTEGER_SIZE } from "../../../_constants/data";
import { removeHashSign } from "../../../_utils/misc";

import ViewForm from "./ViewForm";
import { IValues } from "./types";

// positions
interface IPosition extends StyledComponentClass<{}, React.SFC<{}>> {
  Body?: StyledComponentClass<{}, React.SFC<{}>>;
  Table?: StyledComponentClass<{}, React.SFC<{}>>;
  Footer?: StyledComponentClass<{}, React.SFC<{}>>;
  Info?: StyledComponentClass<{}, React.SFC<{}>>;
  FooterElement?: StyledComponentClass<{}, React.SFC<{}>>;
  Comment?: StyledComponentClass<{}, React.SFC<{}>>;
}

const Position: IPosition = styled(
  class extends React.Component<{}> {
    static Body = styled.div`
      height: 100%;
    `;

    static Table = styled.div`
      height: 100%;
    `;

    static Footer = styled.div`
      display: flex;
      justify-content: space-between;
      padding: 20px 20px 30px;
    `;

    static Info = styled.div`
      text-align: right;
      padding: 0 20px;
    `;

    static FooterElement = styled.div`
      > * {
        margin-left: 10px;
      }
    `;

    static Comment = styled.div`
      padding: 0 20px;
      height: 100%;
    `;

    render() {
      return <div {...this.props} />;
    }
  }
)`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

interface IViewProps
  extends IWithGrantedProps,
    IConnectedProps,
    IConnectedActions,
    RouteComponentProps<{ orderId: string }> {
  handleTabChange: (tab: string) => void;
}

const ordersValidationSchema = Yup.object().shape({
  order: Yup.object().shape({
    items: Yup.array().of(
      Yup.object().shape({
        quantity: Yup.number()
          .typeError("must be a number")
          .integer("must be a number")
          .min(0, "can't be negative")
          .max(INTEGER_SIZE, `can't be grater than ${INTEGER_SIZE}`)
      })
    )
  })
});

class View extends React.Component<IViewProps, IValues> {
  public orderId: number;
  public form: Formik<IValues>;

  constructor(props) {
    super(props);

    this.orderId = parseInt(this.props.match.params.orderId, 10);

    this.getOrder = this.getOrder.bind(this);
    this.setChanged = this.setChanged.bind(this);
    this.handleClickCancel = this.handleClickCancel.bind(this);
    this.handleApprove = this.handleApprove.bind(this);
    this.handleSubmitOrder = this.handleSubmitOrder.bind(this);
    this.handleClickBack = this.handleClickBack.bind(this);
    this.handleSaveEdits = this.handleSaveEdits.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);

    this.state = {
      orderStatus: "",
      changed: false,
      isApproveAllowed: false,
      errorMessages: []
    };
  }

  componentDidMount() {
    this.getOrder();
  }

  getOrder(): void {
    this.setState({
      orderStatus: "loading"
    });

    ordersApi
      .getAllOrderForEdit(this.orderId)
      .then((orderData: EditAllOrderDto) => {
        this.setState({
          order: orderData,
          orderStatus: "done"
        });

        this.setApproveAllowed(orderData);
      })
      .catch(e => {
        this.props.notifySwagger(e, "error");
      });
  }

  countTotal(orderItems): number {
    return orderItems.reduce((result, orderItem) => {
      const itemPrice = orderItem.isActive
        ? orderItem.price * orderItem.quantity
        : 0;
      return result + itemPrice;
    }, 0);
  }

  setChanged(): void {
    this.setState({ changed: true });
    this.setApproveAllowed(this.state.order);
  }

  setApproveAllowed(orderData) {
    this.setState({
      isApproveAllowed:
        orderData.approvalLimit.unlimited ||
        orderData.approvalLimit.limit > this.countTotal(orderData.items)
    });
  }

  handleClickCancel(): void {
    this.setState({ orderStatus: "saving" });
    cancelAllOrder(this.state.order.id)
      .then(response => {
        this.isStatusSucced(response);
        history.push("/orders/all");
      })
      .catch(e => {
        this.props.notifySwagger(e, "error");
      });
  }

  async handleApprove() {
    if (Object.keys(this.form.getFormikBag().errors).length) {
      return;
    }

    this.setState({ orderStatus: "saving" });

    if (this.state.changed) {
      try {
        const response = await this.saveEdits(this.form.state.values);
        this.isStatusSucced(response);
      } catch (e) {
        this.setState({ orderStatus: "done" }, () => {
          this.props.notifySwagger(e, "error");
        });
      }
    }

    try {
      const response = await approveAllOrder(this.state.order.id);
      this.setState({ orderStatus: "done" }, () => {
        if (this.isStatusSucced(response)) {
          history.push("/orders/all");
        }
      });
    } catch (e) {
      this.setState({ orderStatus: "done" }, () => {
        this.props.notifySwagger(e, "error");
      });
    }
  }

  validateCanceledOrder(): Promise<void> {
    if (
      this.state.order.items.every(item => !item.isActive) ||
      this.state.order.items.every(item => !item.quantity)
    ) {
      return this.props.openConfirm({
        title: "Warning!",
        question:
          "You are fully cancelling this order and it will not process. Please confirm that that is your intent."
      });
    } else {
      return Promise.resolve();
    }
  }

  async handleSubmitOrder() {
    if (Object.keys(this.form.getFormikBag().errors).length) {
      return;
    }

    await this.validateCanceledOrder();
    try {
      this.setState({ orderStatus: "saving" });

      if (this.state.changed) {
        await this.saveEdits(this.form.state.values);
      }

      const response = await submitAllOrder(this.state.order.id);

      this.setState({ orderStatus: "done" }, () => {
        if (this.isStatusSucced(response)) {
          history.push("/orders/all");
        }
      });
    } catch (e) {
      this.setState({ orderStatus: "done" }, () => {
        this.props.notifySwagger(e, "error");
      });
    }
  }

  handleClickBack(): void {
    history.goBack();
  }

  handleSaveEdits(): void {
    this.form.submitForm();
  }

  handleSubmit(values: IValues): void {
    this.setState({ orderStatus: "saving" }, () => {
      this.saveEdits(values)
        .then(response => {
          this.isStatusSucced(response);
          this.setState({ orderStatus: "done", changed: false });
        })
        .catch(e => {
          this.setState({ orderStatus: "done" }, () => {
            this.props.notifySwagger(e, "error");
          });
        });
    });
  }

  saveEdits(values: IValues): Promise<void> {
    const updatedOrderItems = values.order.items.map(item => {
      const newItem = {
        isActive: item.isActive,
        quantity: item.quantity,
        productId: item.product.id,
      };
      return newItem;
    });

    return updateAllOrder(values.order.id, updatedOrderItems, values.order.clientPoNumber);
  }

  isStatusSucced(response): boolean {
    if (response) {
      this.setState({
        errorMessages: response.errors
      });
      return response.success;
    }
    return null;
  }

  render() {
    return (
      <MainContainer
        centered
        scale={"md"}
        renderTitle={
          <>
            {!!this.state.errorMessages.length &&
              this.state.errorMessages.map(errorMessage => {
                return (
                  <p>
                    <MessageBar type="warning">{errorMessage}</MessageBar>
                  </p>
                );
              })}
            {this.state.orderStatus === "done" &&
              !this.state.isApproveAllowed && (
                <MessageBar type="warning">
                  Your approval limit is below this order. Please contact an
                  administrator for this order.
                </MessageBar>
              )}
            <Title label="">
              {this.state.orderStatus === "loading" && (
                <React.Fragment>
                  <Title.Element>
                    <Skeleton.Line />
                  </Title.Element>
                  <Title.Element>
                    <Skeleton.Line />
                  </Title.Element>
                  <Title.Element>
                    <Skeleton.Line />
                  </Title.Element>
                </React.Fragment>
              )}
              {this.state.orderStatus === "done" && (
                <React.Fragment>
                  <Title.Element>
                    Buyer: {this.state.order.creatorUser.displayValue}
                  </Title.Element>
                  <Title.Element>
                    Order #: {removeHashSign(this.state.order.number)}
                  </Title.Element>
                  <Title.Element>
                    Date/Time:{" "}
                    {moment(this.state.order.creationTime).format(
                      "MM/DD/YYYY LT"
                    )}
                  </Title.Element>
                </React.Fragment>
              )}
            </Title>
          </>
        }
      >
        <Position>
          <Position.Body>
            {
              <Formik
                enableReinitialize
                initialValues={this.state}
                validationSchema={ordersValidationSchema}
                onSubmit={this.handleSubmit}
                ref={node => (this.form = node)}
                render={props => {
                  return <ViewForm {...props} onChange={this.setChanged} />;
                }}
              />
            }
            <Position.Info>
              {this.state.orderStatus === "done" && (
                <Article.P scale="sm">
                  Facility Location: {this.state.order.location.displayValue}
                </Article.P>
              )}
            </Position.Info>
            <Position.Comment>
              <Article.P>
                {this.state.order && this.state.order.comment}
              </Article.P>
            </Position.Comment>
          </Position.Body>
          <Position.Footer>
            <Position.FooterElement />
            <Position.FooterElement>
              <Link.Button
                onClick={this.handleClickBack}
                preloader={
                  this.state.orderStatus === "loading" ||
                  this.state.orderStatus === "saving"
                }
              >
                Back
              </Link.Button>
              {this.props.isGranted(ORDERS_ALLORDERS_CANCEL) &&
                this.state.order &&
                (this.state.order.shouldBeApproved ||
                  this.state.order.shouldBeSubmitted) && (
                  <Button
                    primary
                    onClick={this.handleClickCancel}
                    preloader={
                      this.state.orderStatus === "loading" ||
                      this.state.orderStatus === "saving"
                    }
                  >
                    Cancel Order
                  </Button>
                )}
              {this.props.isGranted(ORDERS_ALLORDERS_EDIT) &&
                this.state.changed &&
                this.state.order &&
                (this.state.order.shouldBeApproved ||
                  this.state.order.shouldBeSubmitted) && (
                  <Button
                    onClick={this.handleSaveEdits}
                    preloader={
                      this.state.orderStatus === "loading" ||
                      this.state.orderStatus === "saving"
                    }
                  >
                    Save Edits
                  </Button>
                )}
              {this.props.isGranted(ORDERS_ALLORDERS_SUBMITORAPPROVE) &&
                this.state.order &&
                (this.state.order.shouldBeApproved ||
                  this.state.order.shouldBeSubmitted) &&
                (this.state.order.shouldBeSubmitted ? (
                  <Button
                    onClick={this.handleSubmitOrder}
                    preloader={
                      this.state.orderStatus === "loading" ||
                      this.state.orderStatus === "saving"
                    }
                    disabled={
                      !this.state.isApproveAllowed ||
                      (this.state.orderStatus === "loading" ||
                        this.state.orderStatus === "saving")
                    }
                  >
                    Submit Order
                  </Button>
                ) : (
                  <Button
                    onClick={this.handleApprove}
                    preloader={
                      this.state.orderStatus === "loading" ||
                      this.state.orderStatus === "saving"
                    }
                    disabled={
                      !this.state.isApproveAllowed ||
                      (this.state.orderStatus === "loading" ||
                        this.state.orderStatus === "saving")
                    }
                  >
                    Approve Order
                  </Button>
                ))}
            </Position.FooterElement>
          </Position.Footer>
        </Position>
      </MainContainer>
    );
  }
}

interface IConnectedProps {
  locationName: string;
  catalogId: number;
}

interface IConnectedActions {
  notifySwagger: typeof notifySwagger;
  openConfirm: IOpenConfirmPrompt;
}

export default withRouter(
  withMenu(
    connect(
      createStructuredSelector<IGlobalStore, IConnectedProps>({
        locationName: state => state.currentUser.locationInfo.name,
        catalogId: state => state.currentUser.catalogId
      }),
      dispatch =>
        bindActionCreators(
          {
            notifySwagger,
            openConfirm: (openConfirm as any) as IOpenConfirmPrompt
          },
          dispatch
        )
    )(withGranted(View)),
    false
  )
);

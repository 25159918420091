import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

function MenuSidebar(props) {
  return <Fragment>{props.isShow && props.children}</Fragment>;
}

MenuSidebar.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.array,
    PropTypes.string,
  ]),
  isShow: PropTypes.bool,
};

export default connect(
  createStructuredSelector({
    isShow: (state => state.layout.menuStatus),
  }),
  null
)(MenuSidebar);

import { types } from '../_actionTypes/notification';
import {
  getSwaggerErrorResponse,
} from '../_utils/errorHandling';

export function notify(message: string, messageType: string) {
  return {
    type: types.NOTIFICATION_NOTIFY,
    message,
    messageType,
  };
}

export function notifySwagger(message: any, messageType: string) {
  return {
    type: types.NOTIFICATION_NOTIFY,
    message: getSwaggerErrorResponse(message).error.message,
    messageType,
  };
}

import * as React from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import styled from 'styled-components';
import { history } from '../../store';

import withGranted from '../HOC/WithGranted';
import CartEntry from './CartEntry';
import { saveOrder } from '../../_api/orders';
import { ICartProductEntry } from '../../_reducers/cart';
import { SimpleMap } from '../../_types/common';
import {
  Button,
  // Link, 
  Separator,
} from '../../components';
import { clearCart, multiRemoveProductsFromCart, } from '../../_actions/cart';
import { IGlobalStore } from '../../_reducers/reducers';
import { Action, ActionCreatorsMapObject, bindActionCreators } from 'redux';
import { CartItemDto } from '../../service-proxies';
import { closeRightSlider } from '../../_actions/layout';

import {
  ORDERS_SUBMIT,
} from '../../_constants/permissions';

const ContentHeader = styled<{ className?: string; }>((props) => {
  return <Separator borders="0 0 1" className={props.className}>
    {
      props.children
    }
  </Separator>;
})`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  justify-items: center;
  padding: 0 20px;
  flex: 0 0 59px;
  ${Button} {
    width: 164px;
    padding: 7px;
  }
`;

const CartContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  max-height: 100%;
`;

const ContentHeaderElement = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const Content = styled.div`
  flex: 0 1 auto;
  overflow: auto;
`;

const ContentFooter = styled.div`
  padding: 20px 19px 20px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex: 0 0 auto;
  ${Button} {
    margin-left: 20px;
  }
`;

interface IProps extends IConnectedProps, IConnectedActions {
  isCartOpen: boolean;
  isGranted: (permission: string) => boolean;
  className?: string;
}

interface IState {
  selectedMap: SimpleMap;
  selectedCount: number;
  isLoading: boolean;
}

const Cart = styled(class extends React.PureComponent<IProps, IState> {

  constructor(props) {
    super(props);
    this.state = {
      selectedMap: {},
      selectedCount: 0,
      isLoading: false,
    };
    this.handleSelectProduct = this.handleSelectProduct.bind(this);
    this.handleMultiRemove = this.handleMultiRemove.bind(this);
    this.handleSaveOrder = this.handleSaveOrder.bind(this);
    this.handleSubmitOrder = this.handleSubmitOrder.bind(this);
    this.handleRemoveAll = this.handleRemoveAll.bind(this);
    this.createOrder = this.createOrder.bind(this);
  }

  handleSelectProduct(productId: number, value: boolean) {
    const selectedMap = { ...this.state.selectedMap };
    if (value) {
      selectedMap[productId] = true;
    } else {
      delete selectedMap[productId];
    }
    this.setState({
      selectedMap,
      selectedCount: Object.keys(selectedMap).length
    });
  }

  handleMultiRemove() {
    this.props.multiRemoveProductsFromCart(this.state.selectedMap);
    this.setState({
      selectedMap: {},
      selectedCount: 0
    });
  }

  handleRemoveAll() {
    this.props.clearCart();
    this.setState({ selectedMap: {}, selectedCount: 0 });
  }

  handleSaveOrder() {
    this.setState({ isLoading: true });
    this.createOrder();
  }

  handleSubmitOrder() {
    history.push('/orders/placing');
  }

  createOrder() {
    const cartItems: CartItemDto[] = this.props.productEntries.map(e => ({ productId: e.product.id, count: e.count }));
    saveOrder(this.props.locationId, cartItems).then(res => {
      this.setState({ isLoading: false });
    }).catch(() => {
      this.setState({ isLoading: false });
    });
  }

  render() {
    const { productEntries } = this.props;

    return (
      <div className={this.props.className}>
        <CartContainer>
          <ContentHeader>
            <ContentHeaderElement>
              <Button
                primary
                negative
                disabled={productEntries.length === 0 || this.state.isLoading}
                scale="sm"
                onClick={this.handleRemoveAll}
              >
                Remove All
            </Button>
            </ContentHeaderElement>
            <ContentHeaderElement>
              <Button
                primary
                negative
                disabled={this.state.selectedCount === 0 || this.state.isLoading}
                scale="sm"
                onClick={this.handleMultiRemove}
              >
                Remove selected ({this.state.selectedCount})
            </Button>
            </ContentHeaderElement>
          </ContentHeader>
          <Content>
            {
              productEntries.map(entry => <CartEntry
                key={entry.product && entry.product.id}
                entry={entry}
                selected={!!this.state.selectedMap[entry.product && entry.product.id]}
                onSelect={this.handleSelectProduct}
              />)
            }
          </Content>
          <ContentFooter>
            {/* <Link.Button
              primary
              onClick={this.handleSaveOrder}
              preloader={this.state.isLoading}
              disabled={productEntries.length === 0 || this.state.isLoading}
              scale="lg"
            >
              Save Order
            </Link.Button> */}
            <Button
              onClick={this.handleSubmitOrder}
              preloader={this.state.isLoading}
              disabled={(productEntries.length === 0) || !this.props.isGranted(ORDERS_SUBMIT)}
            >
              Submit Order
          </Button>
          </ContentFooter>
        </CartContainer>
      </div>
    );
  }
})`
  height: 100%;

  ${ContentHeader}{
    flex-direction: row;
  }
`;

interface IConnectedProps {
  productEntries: ICartProductEntry[];
  locationId: number;
}

interface IConnectedActions {
  clearCart: typeof clearCart;
  closeRightSlider: typeof closeRightSlider;
  multiRemoveProductsFromCart: typeof multiRemoveProductsFromCart;
}

export default connect(
  createStructuredSelector<IGlobalStore, IConnectedProps>({
    productEntries: state => state.cart.productEntries,
    locationId: state => state.currentUser.locationInfo.id,
  }),
  dispatch => bindActionCreators<IConnectedActions & ActionCreatorsMapObject, ActionCreatorsMapObject<Action>>({
    clearCart,
    closeRightSlider,
    multiRemoveProductsFromCart,
  }, dispatch)
)(withGranted(Cart));

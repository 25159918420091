import * as React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';

import { bindActionCreators } from 'redux';
import { createStructuredSelector } from 'reselect';
import withAuth from '../HOC/WithAuth';
import Search from '../Search/Search';
import MainView from '../MainView/MainView';
import DrawerMenu from './DrawerMenu';
import RightSideSlider from './RightSideSlider';
import DrawerLocationSelector from './DrawerLocationSelector';

import * as actions from '../../_actions/layout';
import * as actionsCurrentUser from '../../_actions/current-user';
import { IGlobalStore } from '../../_reducers/reducers';
import { GetCurrentLoginInformationsOutput } from '../../service-proxies';
import { history } from '../../store';

import {
  Article,
  Avatar,
  Button,
  Drawer,
  Link,
  Popover,
  Tooltip,
} from '../../components';

import {
  CartIcon,
  UserIcon,
} from '../../assets/icons';

const PositionHeader = styled.header`
  display: flex;

  > div:nth-of-type(2) {
    flex: 1 1 100%;
    width: 100%;
  }
`;

const PositionBody = styled.div`
  width: auto;
  height: 100%;
  display: flex;
  flex: 1 1 auto;
`;

const PositionContent = styled.section`
  width: 100%;
  height: 100%;
  flex: 1 1 100%;
  min-width: 0;
`;
const PositionAvatarDatails = styled.div`
  padding-left: 14px;
  display: flex;
  flex-direction: column;
`;

const PositionAvatarWrapper = styled.div`
  padding: 0 8px;
  cursor: pointer;
`;

const MainButton = styled(Button)`
  border: 0;
  margin: 0 0 2px 2px;
  padding: 0px;
  width: 70px;
  min-width: 70px;
  flex: 1 1 70px;
  box-sizing: border-box;
  
  &:hover:enabled{
    border: 0;
  }
`;

const UserMenuContainer = styled.div`
  width: 100px;
  margin: -10px 0;
  ${Link.Button}{
    text-transform: uppercase;
    margin: 5px 0;
  }
`;

function getRoleName(roles: string[]): string {
  if (roles.length === 0) {
    return '';
  }
  if (roles.some(role => role.toLowerCase() === 'admin')) {
    return 'Admin';
  }
  const adminRole = roles.find(role => role.toLowerCase().includes('admin'));

  return adminRole ? adminRole : roles[0];
}

class AuthLayout extends React.Component<IConnectedStoreProps & IConnectedActions> {

  constructor(props) {
    super(props);
    this.handleToggleDrawer = this.handleToggleDrawer.bind(this);
    this.handleCartClick = this.handleCartClick.bind(this);
    this.handleLogOut = this.handleLogOut.bind(this);
  }

  handleToggleDrawer() {
    this.props.drawerToggle(!this.props.drawerStatus);
  }

  handleCartClick() {
    if (this.props.rightSliderOpen) {
      this.props.closeCart();
    } else {
      this.props.openCart();
    }
  }

  handleLogOut() {
    this.props.logout();
    history.push('/login');
  }

  render() {
    const userInfo = this.props.loginInfo.user;
    const role = getRoleName(this.props.loginInfo.assignedRoleNames || []);

    return (
      <React.Fragment>
        <PositionHeader>
          <Drawer.Logo
            isOpen={this.props.drawerStatus}
          >
            <Link to="/">
              <img src="/img/otto-logo.svg" height="100%" alt="Otto logo" />
            </Link>
          </Drawer.Logo>
          <Search />
          <Popover
            placement="bottom-end"
            triggerActionName="click"
            renderTarget={(props) => <MainButton negative {...props}><UserIcon /></MainButton>}
            renderBubble={(props) => <UserMenuContainer innerRef={props.parentRef}>
              {/* <Link.Button primary><Article.Text scale="xs">my profile</Article.Text></Link.Button> */}
              {/* <Link.Button primary><Article.Text scale="xs">contact us</Article.Text></Link.Button> */}
              <Link.Button primary onClick={this.handleLogOut}><Article.Text scale="xs">Log out</Article.Text></Link.Button>
            </UserMenuContainer>}
          />
          <MainButton negative onClick={this.handleCartClick}><CartIcon /></MainButton>
        </PositionHeader>
        <PositionBody>
          {
            this.props.menuStatus &&
            <Drawer
              isOpen={this.props.drawerStatus}
              onToggle={this.handleToggleDrawer}
            >
              <Drawer.Header>
                <Tooltip text={`${userInfo.name} ${userInfo.surname}`} disabled={this.props.drawerStatus}>
                  <PositionAvatarWrapper onClick={this.handleToggleDrawer}>
                    <Avatar size={this.props.drawerStatus ? 'lg' : 'md'} />
                  </PositionAvatarWrapper>
                </Tooltip>
                <Drawer.Spoiler>
                  <PositionAvatarDatails>
                    <Link.Regular primary>{`${userInfo.name} ${userInfo.surname}`}</Link.Regular>
                    <Link.Regular>{role}</Link.Regular>
                  </PositionAvatarDatails>
                </Drawer.Spoiler>
              </Drawer.Header>
              <DrawerLocationSelector isDrawerOpen={this.props.drawerStatus} />
              <DrawerMenu isDrawerOpen={this.props.drawerStatus} />
            </Drawer>
          }
          <PositionContent>
            <MainView />
          </PositionContent>
          <RightSideSlider />
        </PositionBody>
      </React.Fragment>
    );
  }
}

interface IConnectedStoreProps {
  drawerStatus: boolean;
  menuStatus: boolean;
  loginInfo: GetCurrentLoginInformationsOutput;
  rightSliderOpen: boolean;
}

interface IConnectedActions {
  drawerToggle: typeof actions.drawerToggle;
  openCart: typeof actions.openCart;
  closeCart: typeof actions.closeRightSlider;
  logout: typeof actionsCurrentUser.logout;
}

export default withAuth(connect(
  createStructuredSelector<IGlobalStore, IConnectedStoreProps>({
    loginInfo: state => state.currentUser.loginInfo,
    drawerStatus: state => state.layout.drawerStatus,
    menuStatus: state => state.layout.menuStatus,
    rightSliderOpen: state => state.layout.rightSliderOpen,
  }),
  dispatch => bindActionCreators({
    drawerToggle: actions.drawerToggle,
    openCart: actions.openCart,
    closeCart: actions.closeRightSlider,
    logout: actionsCurrentUser.logout,
  }, dispatch)
)(AuthLayout));




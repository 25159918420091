import * as React from "react";
import styled from "styled-components";
import { Option } from "react-select";

import withGranted, { IWithGrantedProps } from "../../HOC/WithGranted";

import { ORDERS_UNCOMPARED_EDIT } from "../../../_constants/permissions";

import { apiUrl } from "../../../_constants/system";

import * as notesApi from "../../../_api/notes";

import {
  ManualEntryDto,
  SearchOutputDto,
  UncomparedOrderItemDto,
  NoteSearchOutputDto
} from "../../../service-proxies";

import {
  Table,
  Thumbnail,
  Link,
  Article
  // Chips,
} from "../../../components";
import Select from "../../../components/Select";
import { ISelectProps } from "../../../components/Select";
import ManualEntry from "../../../sharedComponents/ManualEntry";
import { formatPrice, roundAndFormatPrice } from "../../../_utils/prices";
import ManualEntryViewForOrder from "./../../../sharedComponents/ManualEntryViewForOrder";

const CenterdContainer = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
  width: 100%;
`;

const RightContainer = styled.div`
  text-align: right;
  height: 100%;
  width: 100%;
`;

const Position = class {
  static CenterdContainer = CenterdContainer;
  static RightContainer = RightContainer;
};

interface INoteDto extends ManualEntryDto {
  displayValue: string;
  isNew?: boolean;
  invalid?: boolean;
}

interface IListSelect extends ISelectProps {
  creatable: boolean;
  productId: number;
  editable: boolean;
  compact: boolean;
  onChangeNote: (productId: number, newValue: Option<INoteDto> | null) => void;
  onDeleteNote: (productId: number, newValue: Option<INoteDto> | null) => void;
}

const ListSelect = styled<IListSelect>(({ productId, className, ...props }) => {
  function handleChange(options) {
    props.onChangeNote(productId, options);
  }
  function handleDelete(options) {
    props.onDeleteNote(productId, options);
  }
  return (
    <div className={className}>
      <Select {...props} onChange={handleChange} onDelete={handleDelete} />
    </div>
  );
})`
  height: 40px;
  ${Select} {
    position: absolute;
    width: calc(100% - 40px);
  }
`;

interface IViewFormProps extends IWithGrantedProps {
  productEntries: UncomparedOrderItemDto[];
  notesIds: number[];
  setFieldValue: (fieldName: string, newData: any) => void;
  onResetWarning: () => void;
  onNoteChange: (note: NoteSearchOutputDto, id: number) => void;
  onNoteDelete: (id: number, options: SearchOutputDto) => void;
  onUpdateManualEntry: (entry: ManualEntryDto, noteId, productId) => void;
  value: INoteDto;
  isApproved: boolean;
}

interface IViewFormState {
  notesStatus: string;
  notes: SearchOutputDto[];
}

class ViewForm extends React.Component<IViewFormProps, IViewFormState> {
  constructor(props) {
    super(props);
    this.handleNotesChange = this.handleNotesChange.bind(this);
    this.handleNotesDelete = this.handleNotesDelete.bind(this);
    this.getNotes = this.getNotes.bind(this);

    this.state = {
      notesStatus: "",
      notes: []
    };
  }

  componentDidMount() {
    this.getNotes();
  }

  getNotes() {
    this.setState({ notesStatus: "loading" });
    notesApi
      .getNotes()
      .then(notesData => {
        const notes = notesData.items.sort((a, b) =>
          a.displayValue > b.displayValue ? 1 : -1
        );
        //This is not maintainable and must be replaced using the Active Column in the Notes table of the DB.
        const filteredNotes = notes.filter(n => {
          return (
            n.displayValue !== "Pass Through Item - Invoiced From Vendor" &&
            n.displayValue !== "Controlled Substance - Invoiced From Vendor" &&
            n.displayValue !== "Final Price Based on Quantity" &&
            n.displayValue !== "Non-Stock – Ships Separately" &&
            n.displayValue !==
              "Overshipment - Pending client decision; if kept, separate invoice to be generated"
          );
        });
        this.setState({
          notesStatus: "loaded",
          notes: filteredNotes
        });
      })
      .catch(e => {
        console.log(e);
      });
  }

  handleNotesChange(id, value: NoteSearchOutputDto) {
    this.props.onNoteChange(value, id);
  }

  handleNotesDelete(id, options) {
    this.props.onNoteDelete(options, id);
  }

  render() {
    const { productEntries } = this.props;
    return (
      <Table
        columns={[
          { name: "thumb", label: "", size: "xs" },
          { name: "item", label: "item", size: "xl" },
          { name: "price", label: "price", alignment: "right" },
          { name: "quantity", label: "quantity", alignment: "right" },
          { name: "subtotal", label: "subtotal", alignment: "right" }
        ]}
      >
        <Table.Header />
        <Table.Body>
          {productEntries &&
            !!productEntries.length &&
            productEntries.map((orderItem, index) => {
              return (
                <React.Fragment key={`container-order-product-${index}`}>
                  <Table.Row
                    key={`order-product-${index}`}
                    className="print-list-item"
                    cells={[
                      <Thumbnail
                        key={`thumbnail-${index}`}
                        image={
                          orderItem.product.picture
                            ? apiUrl + orderItem.product.picture.pictureUrl
                            : null
                        }
                      />,
                      <React.Fragment key={`item-${index}`}>
                        <p>
                          <Link
                            key={`item-${orderItem.product.id}`}
                            to={`/products/product/${orderItem.product.id}`}
                            primary
                          >
                            <span style={{ color: "#000" }}>
                              {orderItem.product.name}
                            </span>
                          </Link>
                        </p>
                        <p>
                          <span style={{ color: "#000" }}>
                            {orderItem.product.sku}
                          </span>
                        </p>
                      </React.Fragment>,
                      formatPrice(orderItem.price),
                      orderItem.quantity,
                      roundAndFormatPrice(orderItem.price * orderItem.quantity)
                    ]}
                  />

                  {this.props.isGranted(ORDERS_UNCOMPARED_EDIT) &&
                  !this.props.isApproved ? (
                    <Table.Row
                      key={`order-product-notes-${index}`}
                      colSpan="7"
                      cells={[
                        <React.Fragment
                          key={`order-product-notes-control-${index}`}
                        >
                          <React.Fragment>
                            <ListSelect
                              className={
                                orderItem.note ? "" : "print-empty-note"
                              }
                              productId={orderItem.product.id}
                              creatable={false}
                              options={this.state.notes}
                              value={orderItem.note ? orderItem.note.id : null}
                              onChangeNote={this.handleNotesChange}
                              onDeleteNote={this.handleNotesDelete}
                              isLoading={this.state.notesStatus === "loading"}
                              disabled={this.state.notesStatus === "loading"}
                              valueKey="id"
                              labelKey="displayValue"
                              placeholder={`Add your notes to ${orderItem.product.name}...`}
                              editable={false}
                              compact
                            />
                            {orderItem.note &&
                            orderItem.note.isManualEntryRequired ? (
                              <ManualEntry
                                key={orderItem.note.id}
                                entry={orderItem.manualEntry}
                                noteId={orderItem.note.id}
                                validationType={orderItem.note.manualEntryType}
                                description={orderItem.note.displayValue}
                                productId={orderItem.product.id}
                                onChange={this.props.onUpdateManualEntry}
                                isNew={(orderItem.note as any).isNew}
                                isInvalid={(orderItem.note as any).invalid}
                              />
                            ) : null}
                          </React.Fragment>
                        </React.Fragment>
                      ]}
                    />
                  ) : null}
                  {this.props.isGranted(ORDERS_UNCOMPARED_EDIT) &&
                  this.props.isApproved &&
                  orderItem.note ? (
                    <React.Fragment>
                      <ManualEntryViewForOrder
                        colSpan={7}
                        description={orderItem.note.displayValue}
                        entry={orderItem.manualEntry}
                      />
                    </React.Fragment>
                  ) : null}
                </React.Fragment>
              );
            })}
          <Table.Row
            cells={[
              "",
              "",
              "",
              <Position.RightContainer key={`order-product-price-total-label`}>
                <Article.P light scale="lg">
                  TOTAL:
                </Article.P>
              </Position.RightContainer>,
              <Article.P scale="lg" key={`order-product-price-total-label`}>
                {roundAndFormatPrice(
                  productEntries.reduce(
                    (result, orderItem) =>
                      result + orderItem.price * orderItem.quantity,
                    0
                  )
                )}
              </Article.P>
            ]}
          />
        </Table.Body>
      </Table>
    );
  }
}

export default withGranted(ViewForm);

import * as React from "react";
import styled from "styled-components";
import {
  ManualEntryDto,
  NoteSearchOutputDtoManualEntryType
} from "../service-proxies";
import { PencilIcon, TicIcon } from "../assets/icons";
import keycode from "keycode";
import Toggle from "../components/Toggle";
import { validationFieldList, validators } from "./ManualEntryValidators";
import {
  formatPrice,
  normalizePrice,
  roundAndFormatPrice
} from "../_utils/prices";
import { DECIMAL_MAX, DECIMAL_MIN } from "src/_constants/validation";

const PositionWrapper = styled.div`
  display: flex;
  flex-direction: row;
  padding: 9px 0;
  input[type="number"]::-webkit-outer-spin-button,
  input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  input[type="number"] {
    -moz-appearance: textfield;
  }
`;

const PositionSKU = styled<{ editMode: boolean; isInvalid: boolean }, "div">(
  "div"
)`
  flex: 1.2;
  padding: ${({ editMode }) => (editMode ? "0" : "4px")} 14px 0 0;
  line-height: 18px;
  cursor: text;

  span {
    margin-left: 4px;
  }
  input {
    width: 100%;
    height: 26px;
    padding: 0 4px;
    outline: none;
    font-size: 12px;
    border: 1px solid ${({ theme }) => theme.lighter};
    ${({ isInvalid, theme }) =>
      isInvalid ? `border-bottom: 1px solid ${theme.warning};` : ""}
  }
`;

const HelperText = styled<{ editMode: boolean }, "div">("div")`
  font-size: 10px;
  height: 12px;
  padding-left: 2px;
  color: ${({ theme }) => theme.warning};
  ${({ editMode, theme }) =>
    editMode ? "" : `border-top: 1px solid ${theme.warning};`}
`;

const PositionDescription = styled<
  { editMode: boolean; isInvalid: boolean },
  "div"
>("div")`
  flex: 6;
  padding: ${({ editMode }) => (editMode ? "0" : "4px")} 14px 0 0;
  line-height: 18px;
  cursor: text;

  input {
    width: 100%;
    height: 26px;
    padding: 0 4px;
    outline: none;
    font-size: 12px;
    border: 1px solid ${({ theme }) => theme.lighter};
    ${({ isInvalid, theme }) =>
      isInvalid ? `border-bottom: 1px solid ${theme.warning};` : ""}
  }
`;

const PositionPrice = styled.div`
  flex: 1;
  padding: 0 14px 0 0;
  height: 26px;
  line-height: 26px;
  cursor: text;
  input {
    width: 100%;
    height: 26px;
    padding: 0 4px;
    outline: none;
    font-size: 12px;
    text-align: left;
    border: 1px solid ${({ theme }) => theme.lighter};
  }
`;

const PositionQuantity = styled.div`
  flex: 1;
  height: 26px;
  line-height: 26px;
  padding: 0 9px 0 0;
  cursor: text;
  input {
    width: 100%;
    height: 26px;
    padding: 0 4px;
    outline: none;
    text-align: left;
    font-size: 12px;
    border: 1px solid ${({ theme }) => theme.lighter};
  }
`;

const PositionSubtotal = styled.div`
  flex: 1;
  padding: 0 14px 0 10px;
  height: 26px;
  text-align: left;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const PositionTaxable = styled.div`
  flex: 1;
  padding: 0 10px;
`;

const PositionAction = styled<{ editMode: boolean }, "div">("div")`
  flex: 0;
  height: 26px;
  padding-top: 4px;
  svg {
    cursor: pointer;
    width: ${props => (props.editMode ? "26px" : "18px")};
    height: ${props => (props.editMode ? "26px" : "18px")};
    path {
      fill: ${({ theme }) => theme.light};
    }
  }
`;

const EntryText = styled<{ isEmpty: boolean }, "span">("span")`
  line-height: 190%;
  color: ${({ isEmpty, theme }) => (isEmpty ? theme.light : theme.primary)};
`;

interface IPriceField extends React.InputHTMLAttributes<{}> {}

const PriceField = styled<IPriceField>(props => {
  return (
    <span className={props.className}>
      <input {...props} />
    </span>
  );
})`
  display: flex;
  &:before {
    content: "$";
    flex: 0 0 auto;
    margin: 0 5px 0 0;
  }
  input {
    flex: 1 1 100%;
  }
`;

interface IManualEntryProps {
  entry: ManualEntryDto;
  noteId: number;
  description: string;
  productId: number;
  validationType: NoteSearchOutputDtoManualEntryType;
  onChange: (
    entry: ManualEntryDto,
    noteId: number,
    productId: number,
    disableNew?: boolean
  ) => void;
  isNew?: boolean;
  isInvalid?: boolean;
}

type FocusFields = "sku" | "description" | "price" | "quantity";

interface IState {
  editMode: boolean;
  editEntry: ManualEntryDto | null;
  focusField: FocusFields;
  invalidMap: {
    [key: string]: string;
  };
}

class ManualEntry extends React.Component<IManualEntryProps, IState> {
  state: IState = {
    editMode: false,
    editEntry: null,
    focusField: "sku",
    invalidMap: {}
  };

  timeoutId: any = null;

  changeTaxableFlag: boolean = false;

  constructor(props) {
    super(props);
    this.handleEditToggle = this.handleEditToggle.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleInputBlur = this.handleInputBlur.bind(this);
    this.handleInputFocus = this.handleInputFocus.bind(this);
    this.handleTextClick = this.handleTextClick.bind(this);
    this.handleInputKeyDown = this.handleInputKeyDown.bind(this);
    this.handleNumberInputChange = this.handleNumberInputChange.bind(this);
    this.handleTaxableChange = this.handleTaxableChange.bind(this);
  }

  componentDidMount() {
    const invalidMap = this.getInvalidMap();
    this.setState({ invalidMap });
  }

  componentWillReceiveProps(nextProps) {
    if (!this.props.isInvalid && nextProps.isInvalid) {
      const invalidMap = this.getInvalidMap();
      this.setState({ invalidMap });
    } else if (this.props.isInvalid && !nextProps.isInvalid) {
      this.setState({ invalidMap: {} });
    }
  }

  getInvalidMap(): { [k: string]: string } {
    const invalidMap: { [k: string]: string } = {};

    const entry = this.state.editMode ? this.state.editEntry : this.props.entry;

    const validator = validators[this.props.validationType];
    validationFieldList.forEach(field => {
      const validationError = validator(field, entry[field]);
      if (validationError) {
        invalidMap[field] = validationError;
      }
    });
    return invalidMap;
  }

  handleEditToggle() {
    if (this.state.editMode && this.props.onChange) {
      this.props.onChange(
        {
          ...this.state.editEntry,
          price: normalizePrice(this.state.editEntry.price),
          isTaxable: this.changeTaxableFlag
            ? !this.props.entry.isTaxable
            : this.props.entry.isTaxable
        },
        this.props.noteId,
        this.props.productId
      );
      if (this.changeTaxableFlag) {
        this.changeTaxableFlag = false;
      }
    }
    if (this.state.editMode && this.timeoutId) {
      clearTimeout(this.timeoutId);
      this.timeoutId = null;
    }

    const invalidMap = this.getInvalidMap();

    this.setState({
      editMode: !this.state.editMode,
      editEntry: this.state.editMode ? null : this.props.entry,
      focusField: "sku",
      invalidMap
    });
  }

  handleInputChange(event: React.ChangeEvent<HTMLInputElement>) {
    const editEntry = { ...this.state.editEntry };
    editEntry[event.target.name] = event.target.value;
    const invalidMap = { ...this.state.invalidMap };
    const validationError = this.validateField(
      event.target.name,
      event.target.value
    );
    if (validationError) {
      invalidMap[event.target.name] = validationError;
    } else if (invalidMap[event.target.name]) {
      delete invalidMap[event.target.name];
    }
    this.setState({
      editEntry,
      invalidMap
    });
  }

  handleNumberInputChange(event: React.ChangeEvent<HTMLInputElement>) {
    const editEntry = { ...this.state.editEntry };
    let value = Number(event.target.value);
    if (event.target.name === "quantity") {
      if (value < 0) {
        value = 0;
      }
      if (value > 2000000000) {
        value = 2000000000;
      } else {
        value = Math.floor(value);
      }
    }

    // TODO: Adjust to be negative
    if (event.target.name === "price") {
      if (value < DECIMAL_MIN) {
        value = DECIMAL_MIN;
      }
      if (value > DECIMAL_MAX) {
        value = DECIMAL_MAX;
      } else {
        value = parseFloat(value.toFixed(10));
      }
      if (value >= 0) {
        this.props.validationType === 6 ? (value *= -1) : value;
      }
    }
    const invalidMap = { ...this.state.invalidMap };
    const validationError = this.validateNumberField(event.target.name, value);
    if (validationError) {
      invalidMap[event.target.name] = validationError as string;
    } else if (invalidMap[event.target.name]) {
      delete invalidMap[event.target.name];
    }
    editEntry[event.target.name] = value;
    this.setState({
      editEntry,
      invalidMap
    });
  }

  handleInputBlur() {
    this.timeoutId = setTimeout(() => {
      this.timeoutId = null;
      this.handleEditToggle();
    }, 100);
  }

  handleInputFocus() {
    if (this.timeoutId) {
      clearTimeout(this.timeoutId);
    }
  }

  handleTextClick(field: FocusFields) {
    if (this.state.editMode) {
      return;
    }
    this.setState({
      editMode: true,
      editEntry: { ...this.props.entry },
      focusField: field
    });
  }

  handleInputKeyDown(event) {
    if (keycode(event.which) === "enter") {
      this.handleEditToggle();
    }
    if (keycode(event.which) === "esc") {
      this.handleEditToggle();
    }
  }

  handleTaxableChange() {
    if (this.timeoutId) {
      this.changeTaxableFlag = true;
    } else {
      this.props.onChange(
        {
          ...this.props.entry,
          isTaxable: !this.props.entry.isTaxable
        },
        this.props.noteId,
        this.props.productId
      );
    }
  }

  validateField(field: string, value: string): string | null {
    return validators[this.props.validationType](field, value);
  }

  validateNumberField(field: string, value: number): string | boolean {
    return validators[this.props.validationType](field, value);
  }

  render() {
    return (
      <React.Fragment>
        <PositionWrapper className="print-list-item">
          <PositionSKU
            editMode={this.state.editMode}
            isInvalid={!!this.state.invalidMap.sku}
            onClick={() => this.handleTextClick("sku")}
          >
            {!this.state.editMode ? (
              <EntryText isEmpty={!this.props.entry.sku}>
                {this.props.entry.sku ||
                  `SKU${this.validateField("sku", "") ? "*" : ""}`}
              </EntryText>
            ) : (
              <input
                type="text"
                name="sku"
                placeholder="SKU"
                autoFocus={this.state.focusField === "sku"}
                onChange={this.handleInputChange}
                onBlur={this.handleInputBlur}
                onFocus={this.handleInputFocus}
                onKeyDown={this.handleInputKeyDown}
                value={this.state.editEntry.sku}
              />
            )}
            {this.state.invalidMap.sku && (
              <HelperText editMode={this.state.editMode}>
                {this.state.invalidMap.sku}
              </HelperText>
            )}
          </PositionSKU>
          <PositionDescription
            editMode={this.state.editMode}
            isInvalid={!!this.state.invalidMap.description}
            onClick={() => this.handleTextClick("description")}
          >
            {!this.state.editMode ? (
              <EntryText isEmpty={!this.props.entry.description}>
                {this.props.entry.description ||
                  `Description${
                    this.validateField("description", "") ? "*" : ""
                  }`}
              </EntryText>
            ) : (
              <input
                type="text"
                name="description"
                autoFocus={this.state.focusField === "description"}
                placeholder="Description"
                onChange={this.handleInputChange}
                onBlur={this.handleInputBlur}
                onFocus={this.handleInputFocus}
                onKeyDown={this.handleInputKeyDown}
                value={this.state.editEntry.description}
              />
            )}
            {this.state.invalidMap.description && (
              <HelperText editMode={this.state.editMode}>
                {this.state.invalidMap.description}
              </HelperText>
            )}
          </PositionDescription>
          <PositionPrice onClick={() => this.handleTextClick("price")}>
            {!this.state.editMode ? (
              <EntryText isEmpty={!this.props.entry.price}>
                {`${formatPrice(this.props.entry.price || 0.0)}${
                  this.validateNumberField("price", 0) ? "*" : ""
                }`}
              </EntryText>
            ) : (
              <PriceField
                type="number"
                name="price"
                placeholder="Price"
                autoFocus={this.state.focusField === "price"}
                onChange={this.handleNumberInputChange}
                onBlur={this.handleInputBlur}
                onFocus={this.handleInputFocus}
                onKeyDown={this.handleInputKeyDown}
                value={this.state.editEntry.price}
              />
            )}
            {this.state.invalidMap.price && (
              <HelperText editMode={this.state.editMode}>
                {this.state.invalidMap.price}
              </HelperText>
            )}
          </PositionPrice>
          <PositionQuantity onClick={() => this.handleTextClick("quantity")}>
            {!this.state.editMode ? (
              <EntryText isEmpty={!this.props.entry.quantity}>
                {`Qty:${this.validateNumberField("quantity", 0) ? "*" : ""} ${
                  this.props.entry.quantity
                }`}
              </EntryText>
            ) : (
              <input
                type="number"
                name="quantity"
                placeholder="Qty"
                autoFocus={this.state.focusField === "quantity"}
                onChange={this.handleNumberInputChange}
                onBlur={this.handleInputBlur}
                onFocus={this.handleInputFocus}
                onKeyDown={this.handleInputKeyDown}
                value={this.state.editEntry.quantity}
              />
            )}
            {this.state.invalidMap.quantity && (
              <HelperText editMode={this.state.editMode}>
                {this.state.invalidMap.quantity}
              </HelperText>
            )}
          </PositionQuantity>
          <PositionSubtotal>
            {!this.state.editMode
              ? roundAndFormatPrice(
                  normalizePrice(this.props.entry.price) *
                    this.props.entry.quantity
                )
              : roundAndFormatPrice(
                  normalizePrice(this.state.editEntry.price) *
                    this.state.editEntry.quantity
                )}
          </PositionSubtotal>
          <PositionTaxable>
            {
              <Toggle
                name="isTaxable"
                id="isTaxable"
                label="Taxable: "
                value={this.props.entry.isTaxable}
                onChange={this.handleTaxableChange}
                //to disable toggle on specific note type
                // disabled={this.props.validationType === 6 ? true : false}
              />
            }
          </PositionTaxable>
          <PositionAction editMode={this.state.editMode}>
            {!this.state.editMode ? (
              <PencilIcon onClick={this.handleEditToggle} />
            ) : (
              <TicIcon onClick={this.handleEditToggle} />
            )}
          </PositionAction>
        </PositionWrapper>
      </React.Fragment>
    );
  }
}

export default ManualEntry;

import { invoicesServiceProxy, excelExportServiceProxy } from "../servicePool";
import { apiUrl } from "../_constants/system";
import {
  PagedResultDtoOfInvoceListDto,
  EditClientInvoiceDto,
  UpdateClientInvoiceDto,
  PagedResultDtoOfClientInvoiceListDto
} from "../service-proxies";
import * as moment from "moment";
import { UpdateInvoicePaidStatusDto } from "./../service-proxies";

export function getInvoices(
  clientUnitId: number,
  filter: string,
  startDate: moment.Moment,
  dueDate: moment.Moment,
  archived: boolean,
  sorting: string,
  skipCount: number,
  maxResultCount: number
): Promise<PagedResultDtoOfInvoceListDto> {
  return invoicesServiceProxy.getInvoices(
    clientUnitId,
    filter,
    startDate,
    dueDate,
    archived,
    sorting,
    skipCount,
    maxResultCount
  );
}

export function getClientInvoices(
  clientUnitId: number,
  filter: string,
  startDate: moment.Moment,
  dueDate: moment.Moment,
  sorting: string,
  skipCount: number,
  maxResultCount: number
): Promise<PagedResultDtoOfClientInvoiceListDto> {
  return invoicesServiceProxy.getClientInvoices(
    clientUnitId,
    filter,
    startDate,
    dueDate,
    false,
    sorting,
    skipCount,
    maxResultCount
  );
}

export function getClientInvoiceForEdit(
  id: number
): Promise<EditClientInvoiceDto> {
  return invoicesServiceProxy.getClientInvoiceForEdit(id);
}

export function getInvoiceForEdit(id: number): Promise<EditClientInvoiceDto> {
  return invoicesServiceProxy.getClientInvoiceForEdit(id);
}

export function getInvoiceDetails(id: number) {
  return invoicesServiceProxy.getInvoiceDetails(id);
}

export function updateInvoicePaidStatus(
  input: UpdateInvoicePaidStatusDto
): Promise<void> {
  return invoicesServiceProxy.updateInvoicePaidStatus(input);
}

export function updateClientInvoice(
  input: UpdateClientInvoiceDto
): Promise<void> {
  return invoicesServiceProxy.updateClientInvoice(input);
}

export function sendClientInvoice(id: number): Promise<void> {
  return invoicesServiceProxy.sendClientInvoice({ id });
}

export function sendInvoices(invoices): Promise<void> {
  return invoicesServiceProxy.sendClientInvoices({ invoices });
}

export function setIsArchived(id: number, archived: boolean) {
  return invoicesServiceProxy.setInvoiceArchive(id, archived);
}

export function downloadInvoice(id: number): Promise<string> {
  return new Promise((resolve, reject) => {
    const xhr = new XMLHttpRequest();
    xhr.open("GET", `${apiUrl}/api/ExcelExport/ExportInvoice?Id=${id}`, true);
    xhr.setRequestHeader("Content-Type", "application/json");
    xhr.setRequestHeader(
      "Authorization",
      `Bearer ${(excelExportServiceProxy as any)._token}`
    );
    xhr.responseType = "blob";

    xhr.onreadystatechange = function(e) {
      if (xhr.readyState === 4) {
        if (xhr.status === 200) {
          const blob = xhr.response;

          // extract filename from HTTP headers
          const disposition = xhr.getResponseHeader("Content-Disposition");

          if (!disposition) {
            return reject("Access control error");
          }

          const match = disposition.match(/\sfilename="([^"]+)";/);
          const fileName =
            match && match.length > 1 ? match[1] : "unknown_invoice.pdf";

          // Simulate download
          const a = document.createElement("a");
          a.href = window.URL.createObjectURL(blob);
          a.download = fileName;
          a.dispatchEvent(
            new MouseEvent(`click`, {
              bubbles: true,
              cancelable: true,
              view: window
            })
          );
          // a.click();

          setTimeout(function() {
            a.remove();
            resolve("ok");
          }, 500);

          // resolve('ok');
        } else {
          console.error("Error while downloading an invoice ", xhr.statusText);
          reject(xhr.statusText);
        }
      }
    };

    xhr.send();
  });
}

export function downloadClientInvoice(id: number): Promise<string> {
  return new Promise((resolve, reject) => {
    const xhr = new XMLHttpRequest();
    xhr.open(
      "GET",
      `${apiUrl}/api/ExcelExport/ExportClientInvoice?Id=${id}`,
      true
    );
    xhr.setRequestHeader("Content-Type", "application/json");
    xhr.setRequestHeader(
      "Authorization",
      `Bearer ${(excelExportServiceProxy as any)._token}`
    );
    xhr.responseType = "blob";

    xhr.onreadystatechange = function(e) {
      if (xhr.readyState === 4) {
        if (xhr.status === 200) {
          const blob = xhr.response;

          // extract filename from HTTP headers
          const disposition = xhr.getResponseHeader("Content-Disposition");

          if (!disposition) {
            return reject("Access control error");
          }

          const match = disposition.match(/\sfilename="([^"]+)";/);
          const fileName =
            match && match.length > 1 ? match[1] : "unknown_invoice.pdf";

          // Simulate download
          const a = document.createElement("a");
          a.href = window.URL.createObjectURL(blob);
          a.download = fileName;
          a.dispatchEvent(
            new MouseEvent(`click`, {
              bubbles: true,
              cancelable: true,
              view: window
            })
          );
          // a.click();

          setTimeout(function() {
            a.remove();
            resolve("ok");
          }, 500);
        } else {
          console.error("Error while downloading an invoice ", xhr.statusText);
          reject(xhr.statusText);
        }
      }
    };

    xhr.send();
  });
}

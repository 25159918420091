import * as React from 'react';
import {matchPath} from 'react-router';
import {Switch, Route} from 'react-router-dom';
import {RouteComponentProps} from 'react-router';
import {history} from '../../store';
import withGranted, {IWithGrantedProps} from '../HOC/WithGranted';
import NotFound from '../NotFound/NotFound';
import {IRouteEntry} from '../../_types/common';

const notFoundRoute: IRouteEntry = {
  path: '/*',
  component: NotFound,
};

interface IProps extends RouteComponentProps<{}>, IWithGrantedProps {
  addNotFound?: boolean;
  routes: IRouteEntry[];
}

class RoutesMapper extends React.Component<IProps> {
  allowedRoutes: IRouteEntry[];

  constructor(props) {
    super(props);
    this.allowedRoutes = props.routes.filter(route => (!route.permission && !route.someOfPermissions) || route.keepForbiddenRoute
      || ((route.permission && props.isGranted(route.permission)) || (route.someOfPermissions && props.isSomeGranted(route.someOfPermissions))));
    if (props.addNotFound) {
      this.allowedRoutes.push(notFoundRoute);
    }
  }

  render() {
    const url = this.props.match.url;

    return (
      <Switch>
        {
          this.allowedRoutes.map(({absolutePath, path, exact, render, component, keepForbiddenRoute, permission}) => {

            const fullPath = absolutePath ? absolutePath : `${url}${path}`;

            if (keepForbiddenRoute && !this.props.isGranted(permission)) {
              return <Route
                key={fullPath}
                exact={exact}
                path={fullPath}
                render={() => <NotFound title="Forbidden" message="You don't have rights to view this page!" />}
              />;
            }

            const match = component ? null : matchPath(history.location.pathname, {
              path: fullPath,
              exact,
            });

            return component ?
              <Route
                key={fullPath}
                exact={exact}
                path={fullPath}
                component={component}
              />
              :
              <Route
                key={fullPath}
                exact={exact}
                path={fullPath}
                render={match && ((props) => render({
                  ...props,
                  isGranted: this.props.isGranted,
                  isSomeGranted: this.props.isSomeGranted,
                  isEveryGranted: this.props.isEveryGranted
                }, match))}
              />;
          })
        }
      </Switch>
    );
  }
}

export default withGranted(RoutesMapper);

import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { CaretIcon } from '../assets/icons';

export const Label = styled.label`
  ${ props => {

    let {
      global,
      warning,
      primary,
      fs_md,
    } = props.theme;

    let color = !props.error ?
      primary :
      warning;

    return `
        ${global}
        color: ${color};
        font-size: ${fs_md}; 
      `;
  }}
`;

export const Suffix = styled.div`
  ${ ({ theme }) => {
    let {
      global,
      fs_md,
    } = theme;
    return `
      ${global}
      font-size: ${fs_md};
      padding: 0 0 0 10px;
      
      font-weight: 300;

      display: flex;
      align-items: center;
    `;
  }}
`;

export const Buttons = styled.div`
  width: 30px;
  max-height: 40px;
`;

export const ButtonUp = styled(props => {
  let {
    className,
    onClick,
    disabled,
  } = props;

  return <button
    type="button"
    onClick={onClick}
    disabled={disabled}
    className={className}
  >
    <CaretIcon />
  </button>;
})`
  ${props => {

    let {
      main,
      light,
      primary,
    } = props.theme;

    let iconColor = props.disabled ?
      light :
      primary;

    return `
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      padding: 0px;
      height: 20px;
      border-width: 1px;
      border-right-width: 0px;
      border-bottom-width: 0px;
      
      border-style: solid;
      border-color: ${light};
      background-color: ${main};
      outline: none;

      i {
        transform: rotate(180deg);
        svg {
          width: 100%;
          height: 100%;
          path {
            fill: ${iconColor};
          }
        }
      }
    `;
  }}
`;

export const ButtonDown = styled(props => {
  let {
    className,
    onClick,
    ...restProps
  } = props;

  return <button type="button"
    onClick={onClick}
    className={className}
    {...restProps}
  >
    <CaretIcon />
  </button>;
})`
  ${({ theme, ...restProps }) => {

    let {
      main,
      light,
      primary,
    } = theme;

    let iconColor = restProps.disabled ?
      light :
      primary;

    return `
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      padding: 0px;
      height: 20px;
      border-width: 1px;
      border-right-width: 0px;

      border-style: solid;
      border-color: ${light};
      background-color: ${main};
      outline: none;

      i {
        svg {
          width: 100%;
          height: 100%;
          path {
            fill: ${iconColor};
          }
        }
      }
    `;
  }}
`;

export const Error = styled.div`
  ${ props => {

    let {
      global,
      warning,
      fs_xs,
    } = props.theme;

    return `
        ${global}
        min-height: 19px;
        margin: 0 0 3px;
        font-weight: 300;
        color: ${warning};
        font-size: ${fs_xs}; 
      `;
  }}
`;

export const Body = styled.div`
  ${ props => {

    let paddingIcon;
    switch (props.size) {
      case 'small':
      case 'large':
        paddingIcon = '50px';
        break;
      default:
        paddingIcon = '40px';
    }

    let margin = props.compact ?
      '0' :
      '7px 0 3px';
    let isIconShow = !!props.iconShow;

    return `
      position: relative;
      margin: ${margin};
      display: flex;

      ${
        isIconShow ? `
          > .${props.iconShow.styledComponentId} {
            position: absolute;
            display: flex;
            justify-content: center;
            align-items: center;
            height: auto;
            width: ${paddingIcon};
            top: 0px;
            left: 0px;
            bottom: 0px;
            pointer-events: none;
          }`
        : ''
      } 
    `;
  }}
`;

export const Input = styled.input``;

export const NumberField = props => {

  let {
    icon,
    className,
    label,
    error,
    required,
    onChange,
    onBlur,
    suffix,
    positiveOnly,
    controls,
    compact,
    integer,
    disabled,
    ...inputProps
  } = props;

  let Icon = icon;

  function handleIncrement() {
    let newValue = inputProps.value;
    if (onChange) {
      onChange(++newValue, true);
    }
  }

  function handleDecrement() {
    let newValue = inputProps.value;
    if (onChange) {
      onChange(--newValue, true);
    }
  }

  function parseValue(value) {
    let valueWithDot = value.replace(/[.](?=.*?\.)/g, '');
    let result = valueWithDot.replace(/[^0-9.]/g, '').replace(/^[00]+/g, '0');
    if (integer) {
      result = result.replace('.', '');
    }

    return result;
  }

  function handleChange(event) {
    if (onChange) {
      onChange(parseValue(event.target.value));
    }
  }

  function handleBlur(event) {
    if (onBlur) {
      onBlur(event);
    }
  }

  let isDownDisabled = ((parseInt(inputProps.value, 10) === 0) || (inputProps.value === '')) && positiveOnly;

  return (
    <div className={className}>

      {
        label &&
        <Label
          error={error}
          htmlFor={inputProps.id ? inputProps.id : null}
        >
          {`${label} ${required ? ' * ' : ''}`}
        </Label>
      }

      <Body compact={compact} iconShow={icon} size={props.size}>
        {
          controls &&
          <Buttons>
            <ButtonUp
              onClick={handleIncrement}
              disabled={disabled} />
            <ButtonDown
              onClick={handleDecrement}
              disabled={isDownDisabled || disabled}
            />
          </Buttons>
        }
        <Input
          disabled={disabled}
          {...inputProps}
          onChange={handleChange}
          onBlur={handleBlur}
          min={`${positiveOnly ? '0' : ''}`}
          className={
            `${icon ? ' icon' : ''}`
          }
        />
        {
          icon && <Icon />
        }
        {
          suffix && <Suffix>{suffix}</Suffix>
        }
      </Body>
      {
        !compact &&
        <Error>
          {error}
        </Error>
      }

    </div>
  );
};

NumberField.propTypes = {
  className: PropTypes.string,
  label: PropTypes.string,
  size: PropTypes.string,
  error: PropTypes.string,
  placeholder: PropTypes.string,
  value: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]),
  required: PropTypes.bool,
  icon: PropTypes.func,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  suffix: PropTypes.string,
  positiveOnly: PropTypes.bool,
  controls: PropTypes.bool,
  compact: PropTypes.bool,
  integer: PropTypes.bool,
  disabled: PropTypes.bool,
};

NumberField.defaultProps = {
  controls: true,
};

export default styled(NumberField)`
${props => {

    let {
      global,
      warning,
      light,
      main,
      primary,
      fs_md,
      dark,
      defaultRGB,
      lightest,
    } = props.theme;

    let borderColor;
    if (props.error) {
      borderColor = warning;
    } else {
      borderColor = light;
    }

    let padding;
    let paddingIcon;
    switch (props.size) {
      case 'small':
      case 'large':
        padding = '15px';
        paddingIcon = '50px';
        break;
      default:
        padding = '10px';
        paddingIcon = '40px';
    }

    let background = props.disabled ?
      lightest :
      main;

    return `
    input[type='number']::-webkit-outer-spin-button,
    input[type='number']::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    input[type='number']{
      ${global}
      -moz-appearance:textfield;
    }

    input{

      ${global}
      border: none;
      background: ${background};
      border: 1px solid ${borderColor};
      color: ${primary};

      padding: ${padding} ${padding} ${padding} ${padding};
      font-size: ${fs_md};

      font-weight: 300;
      width: 100%;

      &:focus{
        border: 1px solid rgba(${defaultRGB},0.5);
        outline: none;
      }
      
      &:active{
        border: 1px solid rgba(${defaultRGB},0.5);
      }
      
      &.icon{
        padding: ${padding} ${padding} ${padding} ${paddingIcon};
      }

      ::-webkit-input-placeholder { 
        color: ${dark};
      }
      ::-moz-placeholder { 
        color: ${dark};
      }
      :-ms-input-placeholder { 
        color: ${dark};
      }
      :-moz-placeholder { 
        color: ${dark};
      }
    }

  `;
  }}
`;
import * as React from 'react';
import styled from 'styled-components';
import {
  Field,
  Checkbox,
  Button,
  Link,
} from '../../components';

const ContainerSection = styled.section`
  ${ props => {
      return `
        width: 100%;
        max-width: 940px;
        background-color: ${ props.theme.main };
        background-image: url(/img/login.jpeg);
        background-repeat: no-repeat;
        background-size: cover;
        background-position: top right;
        box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.07);
      `;
    }
  }
`;

const Container = ({className, children}) => {
  return (
    <div className={className}>
      <ContainerSection>
        <div className={`${className}_form`}>
          {children}
        </div>
      </ContainerSection>
    </div>
  );
};

const StyledContainer = styled<{className?: string; children: any}>(Container)`
  ${props => {

    // regular
    
    return `
      ${props.theme.global}
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;

      &_form{
        
        width: 100%;
        max-width: 330px;
        padding: 75px 70px 96px;
        background: ${props.theme.main};

        .logo{
          margin: 0 0 16px;
          color: ${props.theme.default};
          text-align: center;
        }

        form{

          legend{
            font-size: 20px;
            text-transform: uppercase;
            font-weight: 800;
            margin: 43px 0;
          }

        }
      }

      > .${(Field as any).styledComponentId}{
        margin: 0px 0 8px;
      }

      .${(Checkbox as any).styledComponentId}{
        margin: 20px 0;
      }

      .${(Button as any).styledComponentId}{
        margin: 43px auto 56px;
        display: block;
        padding: 15px 0;
        min-width: 218px;
      }

      .${(Link as any).styledComponentId}{
        display: flex;
        text-align: center;
        margin: 0 auto;
        width: fit-content;
      }

    `;}
  }
`;

export default StyledContainer;

const descriptionValidator = (field: string, value) => {
  if (!value || !value.trim()) {
    return 'Required';
  } else if (value.length >= 300) {
    return 'Must be shorter than 300 characters';
  }
  return null;
};

const skuValidator = (field: string, value) => {
  if (!value || !value.trim()) {
    return 'Required';
  } else if (value.length >= 150) {
    return 'SKU is too long';
  }
  return null;
};

export const validationFieldList = ['sku', 'description', 'price', 'quantity'];

export const validators: {[i: number]: (field: string, value) => string | null} = {

  0: () => {
    return null;
  },

  1: (field: string, value) => {
    return field === 'description' ? descriptionValidator(field, value) : null;
  },

  2: (field: string, value) => {
    if (field === 'description') {
      return descriptionValidator(field, value);
    } else if (field === 'sku') {
      return skuValidator(field, value);
    } else {
      return null;
    }
  },

  3: (field: string, value) => {
    if (field === 'price') {
      if (!value || !parseFloat(value)) {
        return 'Required';
      }
    }
    return null;
  },

  4: (field: string, value) => {
    if (field === 'quantity') {
      if (!value || !parseInt(value, 10)) {
        return 'Required';
      }
    }
    return null;
  },

  5: (field: string, value) => {
    return null;
  },

  6: (field: string, value) => {
    if (field === 'price') {
      if (!value || !parseFloat(value)) {
        return 'Required';
      }
    } else if (field === 'quantity') {
      if (!value || !parseInt(value, 10)) {
        return 'Required';
      }
    }
    return null;
  },
};

import * as React from 'react';
import styled from 'styled-components';

interface IIconTriggerProps {
  className?: string;
  status?: boolean;
  icons: any[];
}

const IconTrigger = styled<IIconTriggerProps>(({ className, status, icons }) => {
  const Icon = status ?
    icons[0] :
    icons[1];
  return <Icon className={className} />;
}) ``;


export default IconTrigger;

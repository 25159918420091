import * as React from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import { Switch, Route } from "react-router-dom";
import { createStructuredSelector } from "reselect";

import Login from "../Login/Login";
import AuthLayout from "./AuthLayout";

import { Modal } from "../../components";
import { IGlobalStore } from "../../_reducers/reducers";
import { IModalStore } from "../../_reducers/modal";
import ChangePassword from "../ChangePassword/ChangePassword";
import ForgotPassword from "../ForgotPassword/ForgotPassword";

interface IProps {
  className: string;
  modal: IModalStore;
}

class Layout extends React.PureComponent<IProps> {
  constructor(props) {
    super(props);

    this.handleConfirm = this.handleConfirm.bind(this);
    this.handleDecline = this.handleDecline.bind(this);
  }

  handleConfirm() {
    this.props.modal.confirm();
  }

  handleDecline() {
    this.props.modal.decline();
  }

  render() {
    return (
      <div className={this.props.className}>
        <Switch>
          <Route exact path="/login" component={Login} />
          <Route path="/forgot-password" component={ForgotPassword} />
          <Route
            path="/change-password/:uid/:code"
            component={ChangePassword}
          />
          <AuthLayout />
        </Switch>
        <Modal
          isOpen={this.props.modal.status}
          options={this.props.modal.options}
          preloader={this.props.modal.preloader}
          onConfirm={this.handleConfirm}
          onDecline={this.handleDecline}
        >
          {this.props.modal.content}
        </Modal>
      </div>
    );
  }
}

export default connect(
  createStructuredSelector({
    modal: (state: IGlobalStore) => state.modal
  })
)(styled(Layout)`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`);

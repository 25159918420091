import React from 'react';
import styled from 'styled-components';
import Swiper from 'react-id-swiper';
import PropTypes from 'prop-types';

import Thumbnail from './Thumbnail';

import {
  PreloaderIcon,
} from '../assets/icons';

export const Thumbnails = styled(({ className, children, ...swiperProps }) => {
  const optionalProps = {};
  if (children.length > 4) {
    optionalProps.navigation = {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    };
  }
  return <Swiper
    slidesPerView={'auto'}
    noSwiping
    spaceBetween={20}
    containerClass={className}
    {...optionalProps}
    {...swiperProps}
  >
    {children}
  </Swiper>;

})`
  ${ ({ theme }) => {

    let {
      global,
      lighter,
      main,
    } = theme;

    return `
      ${global}
      width: 72px;
      height: 405px;
      margin: -20px 20px 0 0;
      
      overflow: hidden;
      position: relative;

      .swiper-wrapper{
        padding: 23px 0px;
      }

      .swiper-button-prev{
        background: ${main};
        position: absolute;
        top: 0;
        padding: 0 0 10px;
        &:before{
          content: '';
          transition: all .5s ease-in-out;    
          display: block;
          border-style: solid;
          border-bottom-color: ${lighter};
          border-right-color: ${main};
          border-left-color: ${main};
          border-top-color: ${main};
          border-bottom-width: 10px;
          border-left-width: 36px;
          border-right-width: 36px;
        }
        &:hover{
          &:before{
            border-bottom-color: ${theme.default};
          }
        }
      }
      
      .swiper-button-next{
        background: ${main};
        position: absolute;
        bottom: 0;
        padding: 10px 0 0;
        &:before{
          content: '';
          transition: all .5s ease-in-out;    
          display: block;
          border-style: solid;
          border-top-color: ${lighter};
          border-right-color: ${main};
          border-left-color: ${main};
          border-bottom-color: ${main};
          border-top-width: 10px;
          border-left-width: 36px;
          border-right-width: 36px;
        }
        &:hover{
          &:before{
            border-top-color: ${theme.default};
          }
        }
      }
    `;
  }}
`;

export const Preview = styled(Thumbnail)`
  ${ ({ theme }) => {

    let {
      global,
      lighter,
    } = theme;

    return `
      ${global}
      width: 365px;
      height: 365px;
      
      border: 1px solid ${lighter};

      display: flex;
      justify-content: center;
      align-items: center;
      overflow: hidden;

      img{
        width: 365px;
        height: 365px;
      }
      `;
  }}
  ${PreloaderIcon}{
    position: absolute;
    margin-left: -10px;
    width: 100px;
    height: 100px;
    svg{
      width: 100px;
      height: 100px;
    }
  }
`;

const ImageContainer = styled.div`
  ${props => {

    let visibility = props.isLoaded ?
      'visible' :
      'hidden';

    return `
      > img{
        object-fit: cover;
        visibility: ${visibility};
      }
    `;
  }}
`;

class ProductImage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      currentImage: 0,
    };

    this.handleClick = this.handleClick.bind(this);
  }

  handleClick(currentImage) {
    this.setState({ currentImage });
  }

  render() {

    let {
      className,
      images,
      isLoading,
    } = this.props;

    return (
      <div className={className}>
        {
          images &&
          (images.length > 1) &&
          <Thumbnails
            direction="vertical"
          >
            {
              images.map((image, index) =>
                <Thumbnail
                  key={'ProductImage' + index}
                  onThumbnailClick={this.handleClick}
                  id={index}
                  image={image.pictureUrl && image.pictureUrl}
                  name={image.name}
                />
              )
            }
          </Thumbnails>
        }
        <ImageContainer>
          {
            isLoading ?
              <Preview image={[]} /> :
              <Preview
                image={images && images[this.state.currentImage] && images[this.state.currentImage].pictureUrl}
                name={images && images[this.state.currentImage] && images[this.state.currentImage].name}
              />
          }
        </ImageContainer>
      </div>
    );
  }
}

ProductImage.propTypes = {
  className: PropTypes.string.isRequired,
  images: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.array,
  ]),
  isLoading: PropTypes.bool,
};

export default styled(ProductImage)`
  ${props => {
    const width = (props.children && (props.children.length > 1)) ? '457px' : 'auto';
    return `
      width: ${width};
      height: 365px;
      
      display: flex;
      justify-content: flex-end;
    `;
  }}
`;
import * as React from "react";
import styled, { StyledComponentClass } from "styled-components";
import { Formik } from "formik";
import * as Yup from "yup";
import { Action, ActionCreatorsMapObject, bindActionCreators } from "redux";
import { connect } from "react-redux";
import moment from "moment";

import { history } from "../../../store";

import {
  REQUIRED_MESSAGE,
  INT32_MIN,
  INT32_MAX,
  NON_NEGATIVE_INT_MESSAGE,
  TIME_LESS_THEN_PREIVOUS,
  DUPLICATE
} from "../../../_constants/validation";

import {
  getMaxTextLengthMessage,
  getMaxNumberLengthMessage,
  getMinNumberLengthMessage,
  // getNumberLengthMessage,
  getMaxNumberValue,
  getMinNumberValue
} from "../../../_utils/validation";

import { MainContainer } from "../../../components";

import Form from "./Form";

import * as actions from "../../../_actions/location";
import * as actionsDivision from "../../../_actions/divisions";
import {
  EditLocationDto,
  CreateLocationDto,
  UpdateLocationDto
} from "../../../service-proxies";
import { createStructuredSelector } from "reselect";
import { IGlobalStore } from "../../../_reducers/reducers";
import {
  CreateLocationClass,
  UpdateLocationClass
} from "./LocationEditClasses";
import { IMatch, Omit } from "../../../_types/common";
import { JSONMapper } from "src/_utils/misc";

type StyledType<T = {}> = StyledComponentClass<T, React.StatelessComponent<T>>;
interface IPosition extends StyledType {
  Body?: StyledType;
  Head?: StyledType;
}

const Position: IPosition = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

Position.Body = styled.div`
  width: 100%;
  height: 100%;
`;

Position.Head = styled.header`
  text-align: right;
  padding: 20px;
`;

const validationSchema = Yup.object().shape({
  name: Yup.string()
    .trim()
    .max(128, getMaxTextLengthMessage(128))
    .required(REQUIRED_MESSAGE),
  facilityOrLocationCode: Yup.string()
    .trim()
    .max(15, getMaxTextLengthMessage(15))
    .required(REQUIRED_MESSAGE),
  address1: Yup.string()
    .trim()
    .max(55, getMaxTextLengthMessage(55))
    .required(REQUIRED_MESSAGE),
  address2: Yup.string()
    .trim()
    .nullable()
    .max(55, getMaxTextLengthMessage(55)),
  city: Yup.string()
    .trim()
    .max(30, getMaxTextLengthMessage(30))
    .required(REQUIRED_MESSAGE),
  stateId: Yup.number()
    .moreThan(INT32_MIN, getMinNumberValue(INT32_MIN))
    .lessThan(INT32_MAX, getMaxNumberValue(INT32_MAX))
    .required(REQUIRED_MESSAGE),
  zipCode: Yup.string()
    .trim()
    .matches(/^\d{5}(-\d{4})?$/, "Please enter a valid Zip Code.")
    .required(REQUIRED_MESSAGE),
  phoneNumber: Yup.string()
    .trim()
    .min(10, getMinNumberLengthMessage(10))
    .max(11, getMaxNumberLengthMessage(11))
    .required(REQUIRED_MESSAGE),
  faxNumber: Yup.string()
    .trim()
    .min(10, getMinNumberLengthMessage(10))
    .max(11, getMaxNumberLengthMessage(11))
    .required(REQUIRED_MESSAGE),
  taxRate: Yup.number()
    .moreThan(0, getMinNumberValue(0))
    .lessThan(100, getMaxNumberValue(100))
    .positive(NON_NEGATIVE_INT_MESSAGE)
    .required(REQUIRED_MESSAGE),
  physicianName: Yup.string()
    .trim()
    .max(128, getMaxTextLengthMessage(128))
    .required(REQUIRED_MESSAGE),
  physicianLicenseNumber: Yup.string()
    .trim()
    .max(25, getMaxTextLengthMessage(25))
    .required(REQUIRED_MESSAGE),
  physicianDeaNumber: Yup.string()
    .trim()
    .max(20, getMaxTextLengthMessage(20))
    .required(REQUIRED_MESSAGE),
  physicianNpiNumber: Yup.string()
    .trim()
    .max(10, getMaxTextLengthMessage(10)),
  operationStartTime: Yup.string()
    .trim()
    .required(REQUIRED_MESSAGE),
  operationEndTime: Yup.string()
    .trim()
    .test("operationEndTime", TIME_LESS_THEN_PREIVOUS, function(
      operationEndTime
    ) {
      const { operationStartTime } = this.parent;
      return operationStartTime < operationEndTime;
    }),
  physicianLicenseExpirationDate: Yup.string()
    .trim()
    .required(REQUIRED_MESSAGE)
    .typeError(REQUIRED_MESSAGE),
  physicianDeaExpirationDate: Yup.string()
    .trim()
    .required(REQUIRED_MESSAGE)
    .typeError(REQUIRED_MESSAGE),
  vendorAccountNumbers: Yup.array().of(
    Yup.object()
      .shape({
        vendor: Yup.object()
          .shape({
            id: Yup.number(),
            displayValue: Yup.string().trim()
          })
          .typeError(REQUIRED_MESSAGE),
        accountNumber: Yup.string()
          .trim()
          .max(80, getMaxTextLengthMessage(80))
          .required(REQUIRED_MESSAGE)
      })
      .test("match", DUPLICATE, function(vendorItem) {
        try {
          const uniqueItems = this.parent.filter((value, index, self) => {
            return (
              self.findIndex(item => item.vendor.id === value.vendor.id) ===
              index
            );
          });
          const nonUniqueItems = this.parent.filter(function(i) {
            return uniqueItems.indexOf(i) < 0;
          });
          const isCurrentNonUnique = nonUniqueItems.some(
            item => item.vendor.id === vendorItem.vendor.id
          );

          const uniqueItemsCount = (uniqueItems && uniqueItems.length) || 0;
          const itemsCount = (this.parent && this.parent.length) || 0;
          return !(isCurrentNonUnique && uniqueItemsCount !== itemsCount);
        } catch (e) {
          return true;
        }
      })
  )
});

export interface ILocationEditState
  extends Omit<Omit<EditLocationDto, "parentId">, "taxRate"> {
  stateId: number | string;
  parentId: number | string;
  taxRate: number | string;
}

const initialState: ILocationEditState = {
  parentId: "",
  name: "",
  facilityOrLocationCode: "",
  address1: "",
  address2: "",
  city: "",
  stateId: "",
  zipCode: "",
  operationStartTime: "",
  operationEndTime: "",
  phoneNumber: "",
  faxNumber: "",
  isInHospital: false,
  taxRate: "",
  physicianName: "",
  physicianLicenseNumber: "",
  physicianLicenseExpirationDate: null,
  physicianDeaNumber: "",
  physicianDeaExpirationDate: null,
  physicianNpiNumber: "",
  state: null,
  id: null,
  vendorAccountNumbers: []
};

class TrimMapper extends JSONMapper<ILocationEditState> {
  map(node) {
    if (typeof node === "string") {
      node = node.trim();
    }
    return node;
  }
}

interface IEditLocationProps extends IConnectedProps, IConnectedActions {
  isNew?: boolean;
  locationId?: string;
  match: IMatch<{ clientId: number; divisionId: number }>;
}

class Edit extends React.Component<IEditLocationProps, ILocationEditState> {
  private readonly isNew: boolean;

  constructor(props) {
    super(props);

    this.state = {
      ...initialState,
      parentId: this.props.match.params.divisionId
    };

    this.isNew = this.props.locationId === "new";

    this.handleSubmit = this.handleSubmit.bind(this);
    this.mapDateToString = this.mapDateToString.bind(this);
  }

  componentDidMount() {
    this.props.getDivisionName(this.props.match.params.divisionId);

    if (!this.isNew) {
      this.props.get(this.props.locationId);
    }
  }

  componentDidUpdate(prevProps) {
    if (!this.isNew) {
      if (
        prevProps.status !== this.props.status &&
        this.props.status === "loaded"
      ) {
        this.setState({
          ...this.props.location,
          stateId: this.props.location.state && this.props.location.state.id,
          operationStartTime: this.unMapTime(
            this.props.location.operationStartTime
          ),
          operationEndTime: this.unMapTime(this.props.location.operationEndTime)
        });
      } else if (this.props.status === "saved") {
        history.push(
          `/admin/clients/${this.props.match.params.clientId}/divisions/${this.props.match.params.divisionId}/locations`
        );
      }
    } else {
      if (this.props.status === "saved") {
        history.push(
          `/admin/clients/${this.props.match.params.clientId}/divisions/${this.props.match.params.divisionId}/locations`
        );
      }
    }
  }

  unMapTime(time) {
    return moment(time, "HH:mm:ss").format("HH:mm");
  }

  mapDateToString(date) {
    return moment(date).format("YYYY-MM-DD");
  }

  handleSubmit(values) {
    const newVendorAccountNumbers = values.vendorAccountNumbers.map(item => ({
      vendorId: item.vendor.id,
      accountNumber: item.accountNumber
    }));

    const newValues = {
    ...new TrimMapper(values).toJSON(),
    physicianLicenseExpirationDate: moment(values.physicianLicenseExpirationDate),
    physicianDeaExpirationDate: moment(values.physicianDeaExpirationDate),
    vendorAccountNumbers: newVendorAccountNumbers
    };

    this.isNew
      ? this.props.create(
          new CreateLocationClass(newValues as object & CreateLocationDto)
        )
      : this.props.update(
          new UpdateLocationClass(newValues as object & UpdateLocationDto)
        );
  }

  render() {
    return (
      <MainContainer
        scale="md"
        title={`${this.isNew ? "create new" : "edit"} location for ${
          this.props.divisionName
        }`}
        subTitle={`Please fill out all the fields below properly to ${
          this.isNew ? "create a new" : "edit a"
        } location`}
        centered
      >
        {
          <Formik
            enableReinitialize
            initialValues={this.state}
            validationSchema={validationSchema}
            onSubmit={this.handleSubmit}
            validateOnChange
            render={props => (
              <Form
                {...props}
                isLoading={
                  this.props.status === "loading" ||
                  this.props.status === "saving"
                }
                isNew={this.isNew}
              />
            )}
          />
        }
      </MainContainer>
    );
  }
}

interface IConnectedProps {
  status: string;
  divisionName: string;
  location: EditLocationDto;
}

interface IConnectedActions {
  create: typeof actions.create;
  update: typeof actions.update;
  getDivisionName: typeof actionsDivision.getName;
  get: typeof actions.get;
}

export default connect(
  createStructuredSelector<IGlobalStore, IConnectedProps>({
    status: state => state.location.status,
    divisionName: state => state.divisions.divisionName,
    location: state => state.location.location
  }),
  dispatch =>
    bindActionCreators<IConnectedActions & ActionCreatorsMapObject, ActionCreatorsMapObject<Action>>(
      {
        create: actions.create,
        update: actions.update,
        getDivisionName: actionsDivision.getName,
        get: actions.get
      },
      dispatch
    )
)(Edit);

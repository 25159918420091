import React from 'react';
import { withRouter } from 'react-router';
import List from './List';
import Edit from './Edit';
import Divisions from '../Division';
import {
  ADMINISTRATION_CLIENTS,
  ADMINISTRATION_CLIENTS_CREATE,
  ADMINISTRATION_CLIENTS_EDIT,
  ADMINISTRATION_CLIENTS_DIVISIONS,
} from '../../../_constants/permissions';
import RoutesMapper from '../../Router/RoutesMapper';
import { IRouteEntry } from '../../../_types/common';

const routes: IRouteEntry[] = [
  {
    path: '',
    exact: true,
    component: List,
    permission: ADMINISTRATION_CLIENTS,
  },
  {
    path: '/new',
    exact: true,
    render: (props) => <Edit {...props} />,
    permission: ADMINISTRATION_CLIENTS_CREATE,
    keepForbiddenRoute: true,
  },
  {
    path: '/:id',
    exact: true,
    render: (props, match) => <Edit {...props} />,
    permission: ADMINISTRATION_CLIENTS_EDIT,
  },
  {
    path: '/:id/divisions',
    component: Divisions,
    permission: ADMINISTRATION_CLIENTS_DIVISIONS,
  },
];

const Client = (props) => {
  return <RoutesMapper routes={routes} addNotFound {...props} />;
};

export default withRouter(Client);
import { types } from '../_actionTypes/products';
import {
  EditProductDto,
  ProductListDto,
  CountDtoOfInt64,
} from "../service-proxies";

export interface IProductsStore {
  products: ProductListDto[];
  productsCounts: CountDtoOfInt64[];
  product?: EditProductDto;
  totalCount: number | null;
  skip: number;
  perPage: number;
  status: string;
  listStatus: string;
  isInactive: boolean;
}

const initialState: IProductsStore = {
  products: [],
  productsCounts: [],
  totalCount: null,
  skip: 0,
  perPage: 10,
  status: '',
  listStatus: '',
  isInactive: false,
};

export default (state: IProductsStore = initialState, action) => {

  const {
    status,
    listStatus,
    products,
    product,
    totalCount,
    skip,
    perPage,
    isInactive,
  } = action;

  switch (action.type) {
    case types.PRODUCTS_NEW:
      return ({
        ...state,
        status,
      });

    case types.PRODUCTS_SAVING:
      return ({
        ...state,
        status,
      });

    case types.PRODUCTS_CREATE:
      return ({
        ...state,
        status,
      });

    case types.PRODUCTS_UPDATE:
      return ({
        ...state,
        status,
      });

    case types.PRODUCTS_GET_ALL:
      return ({
        ...state,
        products,
        product,
        totalCount,
        listStatus,
        status,
        perPage,
        skip,
      });

    case types.PRODUCTS_GET:
      return ({
        ...state,
        product,
        status,
      });

    case types.PRODUCTS_GET_DETAILS:
      return ({
        ...state,
        product,
        status,
      });

    case types.PRODUCTS_ARCHIVE:
      return ({
        ...state,
        status,
      });

    case types.PRODUCTS_UNARCHIVE:
      return ({
        ...state,
        status,
      });

    case types.PRODUCTS_CHANGE_PAGE:
      return ({
        ...state,
        perPage,
        skip,
      });

    case types.PRODUCTS_TRIGGER_INACTIVE:
      return ({
        ...state,
        isInactive,
      });

    case types.PRODUCTS_SET_DONE: 
      return ({
        ...state,
        status: '',
      });

    default:
      return state;
  }

};

import { push } from 'react-router-redux';
import { types } from '../_actionTypes/current-user';
import { types as cartTypes } from '../_actionTypes/cart';
import {
  AuthenticateModel,
  ClientUnitShortDto,
  GetCurrentLoginInformationsOutput,
  TokenAuthServiceProxy
} from '../service-proxies';
import { sessionServiceProxy } from '../servicePool';
import * as userSession from '../_utils/userSession';
import { apiUrl } from '../_constants/system';
import httpProxy from '../httpProxy';
import {IFlatLocationOption} from '../_reducers/current-user';
import {getSwaggerErrorResponse} from "../_utils/errorHandling";
import { cartServiceProxy } from '../servicePool';

export function initHttpProxy() {
  return function(dispatch) {
    httpProxy.setDispatch(dispatch);
  };
}

export function login(data: AuthenticateModel) {
  const authentication = new TokenAuthServiceProxy(null, apiUrl);
  return function (dispatch, getState) {
    return authentication.authenticate(data)
      .then(response => {

        userSession.setToken(response.accessToken, response.expireInSeconds, data.rememberClient);

        dispatch({
          type: types.CURRENT_USER_LOGIN,
          data: response,
        });

        const currentUserState = getState().currentUser;

        userSession.updateAPIServicesWithToken(response.accessToken);

        dispatch(push((currentUserState && currentUserState.loginRedirect) ? currentUserState.loginRedirect : '/'));

      }, (err) => {
        const swaggerError = getSwaggerErrorResponse(err);
        if (swaggerError && swaggerError.error) {
          dispatch({
            type: types.CURRENT_USER_LOGIN_ERROR,
            message: swaggerError.error.message || 'Login failed!',
            details: swaggerError.error.details || 'Unknown error.',
          });
        } else {
          throw err;
        }
      })
      .catch(() => {
        dispatch({
          type: types.CURRENT_USER_ERROR,
        });
      });
  };
}

export function logout() {
  return function (dispatch, getState) {
    userSession.removeToken();
    userSession.updateAPIServicesWithToken(null); // clear token in services
    dispatch({
      type: types.CURRENT_USER_LOGOUT,
    });
  };
}

export function redirectToLogin() {
  return function (dispatch, getState) {

    userSession.removeToken();
    userSession.updateAPIServicesWithToken(null); // clear token in services

    dispatch({
      type: types.CURRENT_USER_REDIRECT_TO_LOGIN,
      currentRoute: getState().router.location.pathname,
    });

    dispatch(push('/login'));
  };

}

export function getCurrentLoginInfo() {
  return function (dispatch) {
    return sessionServiceProxy.getCurrentLoginInformations()
      .then((loginInfo: GetCurrentLoginInformationsOutput) => {
        if (!loginInfo || !loginInfo.user || !loginInfo.user.id || !loginInfo.grantedPermissionNames) {
          throw new Error('Invalid loginInfo!');
        }
        const permissionsMap = loginInfo.grantedPermissionNames.reduce((ac, ce) => {ac[ce] = true; return ac;}, {});
        const locationOptions: IFlatLocationOption[] = flattenLocationOptions(loginInfo.availableLocations);
        const locationInfo = userSession.getUserLocation(loginInfo.user.id);
        let selectedLocationOption: IFlatLocationOption = null;
        if (locationInfo) {
          selectedLocationOption = locationOptions.find(option => option.id === locationInfo.id);
        }

        dispatch({
          type: types.CURRENT_USER_LOGIN_INFORMATION,
          loginInfo,
          locationInfo: (selectedLocationOption ?
            {id: selectedLocationOption.id, name: selectedLocationOption.name}
              :
            {id: null, name: null}
            ),
          locationOptions,
          catalogId: (selectedLocationOption && selectedLocationOption.catalogId),
          permissionsMap,
        });

        let cart = null;

        if (selectedLocationOption && selectedLocationOption.catalogId) {
          cartServiceProxy.getCart().then(function(data){
            cart = data;
            dispatch({
              type: cartTypes.CART_LOAD_CART_FROM_CACHE,
              cart,
            });
          });
        }



      }).catch(() => {
        dispatch({
          type: types.CURRENT_USER_CLEAR_AUTH_DATA,
        });
      });
  };
}


export function setCurrentUserLocation(locationId: number, locationName: string, catalogId: number | null) {
  return function (dispatch, getState) {

    userSession.setUserLocation(getState().currentUser.loginInfo.user.id, {id: locationId, name: locationName});

    dispatch({
      type: types.CURRENT_USER_LOCATION_SELECTED,
      location: {
        id: locationId,
        name: locationName,
      },
      catalogId,
    });

    let cart = null;

    if (catalogId) {
      cartServiceProxy.getCart().then(function(data){
        cart = data;
        dispatch({
          type: cartTypes.CART_LOAD_CART_FROM_CACHE,
          cart,
        });
      });
    }
  };
}

function flattenLocationOptions(children: ClientUnitShortDto[], level = 0, path = [], catalogId = null): IFlatLocationOption[] {
  let resArr: IFlatLocationOption[] = [];

  children.forEach(unit => {
    resArr.push({
      id: unit.id,
      name: unit.name,
      level,
      disabled: level < 2,
      path,
      catalogId: (unit.catalogId || catalogId)
    });

    if (unit.children && unit.children.length) {
      resArr = resArr.concat(flattenLocationOptions(unit.children, level + 1, path.concat([unit.name]), unit.catalogId));
    }
  });
  return resArr;
}

import * as React from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';

import { ICartProductEntry } from '../../_reducers/cart';
import { Counter, Article } from '../../components';
import {
  productCountChange,
  removeProductFromCart,
} from '../../_actions/cart';
import { Checkbox, Link, Separator } from '../../components';
import { IPProps } from '../../components/Article';
import {
  TrashIcon,
} from '../../assets/icons';

const DivParagraph = Article.P.withComponent('div');

const CartEntryContainer = styled<{ className?: string; }>((props) => {
  return <Separator borders="0 0 1" className={props.className}>
    {
      props.children
    }
  </Separator>;
})`
  padding: 14px 20px 14px 20px;
  display: flex;
`;

const CartEntryCheckboxContainer = styled.div`
  flex: 0 0 auto;
`;

const ProductSection = styled.div`
  color: ${({theme}) => theme.default};
`;

const SkuContainer = styled<IPProps>((props) => {
  return <DivParagraph {...props}>
    {
      props.children
    }
  </DivParagraph>;
})``;

const CartEntryContentContainer = styled.div`
  padding: 0 12px;
  flex: 1 1 100%;
  ${DivParagraph}{
    line-height: 19px;
    margin: 0px 0 8px;
  }
  ${SkuContainer}{
    margin: -4px 0 8px;
  }
`;

const CartEntryButtonContainer = styled.div`
  flex: 0 0 auto;
`;

interface IProps extends IConnectedActions {
  entry: ICartProductEntry;
  selected: boolean;
  onSelect: (productId: number, value: boolean) => void;
}

class CartEntry extends React.PureComponent<IProps> {

  constructor(props) {
    super(props);

    this.handleOnChange = this.handleOnChange.bind(this);
    this.handleOnSelect = this.handleOnSelect.bind(this);
    this.handleDeleteSingleEntry = this.handleDeleteSingleEntry.bind(this);
  }

  handleOnChange(value) {
    this.props.productCountChange(this.props.entry.product && this.props.entry.product.id, value);
  }

  handleOnSelect(value) {
    this.props.onSelect(this.props.entry.product && this.props.entry.product.id, value);
  }

  handleDeleteSingleEntry() {
    if (this.props.selected) {
      // if entry is selected - remove from selectedMap
      this.props.onSelect(this.props.entry.product && this.props.entry.product.id, false);
    }
    this.props.removeProductFromCart(this.props.entry.product && this.props.entry.product.id);
  }

  render() {
    const { entry, selected } = this.props;
    return (
      <CartEntryContainer>
        <CartEntryCheckboxContainer>
          <Checkbox
            value={selected}
            onChange={this.handleOnSelect}
          />
        </CartEntryCheckboxContainer>
        <CartEntryContentContainer>
          <DivParagraph scale="sm">
            <Link to={`/products/product/${entry.product.id}`} primary>{entry.product.name}</Link>
          </DivParagraph>
          <DivParagraph scale="xs">
            <ProductSection>
              {{1: 'MEDICAL', 2: 'OFFICE'}[entry.product.productSectionId]}
            </ProductSection>
          </DivParagraph>
          <SkuContainer light scale="sm">SKU: {entry.product && entry.product.sku}</SkuContainer>
          <DivParagraph>
            <Counter
              compact
              label="QTY:"
              value={entry.count}
              onChange={this.handleOnChange}
            />
          </DivParagraph>
        </CartEntryContentContainer>
        <CartEntryButtonContainer>
          <Link.Button onClick={this.handleDeleteSingleEntry}>
            <TrashIcon />
          </Link.Button>
        </CartEntryButtonContainer>
      </CartEntryContainer>
    );
  }
}

interface IConnectedActions {
  productCountChange: typeof productCountChange;
  removeProductFromCart: typeof removeProductFromCart;
}

export default connect(null, {
  productCountChange,
  removeProductFromCart,
})(CartEntry);

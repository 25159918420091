export const apiUrl = getCurrentApiUrl();

function getCurrentApiUrl() {
  const hostname = window.location.hostname;

  if (hostname === 'otto.v2.staging.drs4drs.com') {
    return "https://api.v2.staging.drs4drs.com";
  }

  if (hostname === 'otto.staging.drs4drs.com') {
    return 'https://api.staging.drs4drs.com';
  }

  if (hostname === 'otto.v2.drs4drs.com') {
    return 'https://api.v2.drs4drs.com';
  }

  if (hostname === 'otto.drs4drs.com') {
    return 'https://otto-api.drs4drs.com';
  }

  return 'http://localhost:21021';
}
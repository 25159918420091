import * as React from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import moment, { Moment } from 'moment';
import { createStructuredSelector } from 'reselect';
import { bindActionCreators } from 'redux';

import { PackingSlipOrdersListDto } from '../../service-proxies';

import * as oredrsApi from '../../_api/orders';
import * as notifyActions from '../../_actions/notification';
import * as searchActions from '../../_actions/search';
import * as layoutActions from '../../_actions/layout';
import { IGlobalStore } from '../../_reducers/reducers';
import withGranted, { IWithGrantedProps } from '../HOC/WithGranted';
import { IFlatLocationOption } from '../../_reducers/current-user';
import { IShortLocationInfo } from '../../_types/common';
import { clientUnitFilterOptions } from '../../_utils/clientUnitFilter';
import {saveFilterSettings, loadFilterSettings} from '../../_utils/userSession';

import {
  DatePicker,
  LocationSelectorComponent,
  MainContainer,
  Table,
  Pagination,
  Link,
  RunningLineLoader,
  Skeleton,
  Article,
} from '../../components';

interface ITwoLevelElement {
  className?: string;
}

const Position = {
  Container: styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
  `,

  Content: styled.div`
    height: 100%;
    flex: 1 1 100%;
  `,

  Footer: styled.div`
    flex: 0 0;
  `,

  Filter: styled.div`
  width: 260px;
`,

  Toolbar: styled.div`
    text-align: right;
    > * {
      margin: 0 0 0 10px;
    }
  `,

  Header: styled<ITwoLevelElement>(function ({ className, ...props }) {
    return <header className={className}>
      <div>
        {props.children}
      </div>
    </header>;
  })`
    padding: 20px;
    >div{
      height: 40px;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  `,

  HeaderElement: styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  & > * {
    margin: 7.5px;
  }
  `,

};

interface IListProps extends IWithGrantedProps {
  locationId: number;
  perPage: number;
  notifySwagger: typeof notifyActions.notifySwagger;
  setSearch: typeof searchActions.set;
  setPerPage: typeof layoutActions.setPerPage;
  locationOptions: IFlatLocationOption[];
  locationInfo?: IShortLocationInfo;
}

interface IListState {
  ordersStatus: string;
  orders?: PackingSlipOrdersListDto[];
  totalCount: number;
  filter: string;
  sorting?: string;
  skip?: number;
  perPage?: number;
  focusedInput?: string;
  locationId?: number;
  divisionLocationFilter: number | null;
  startDate: Moment | null;
  dueDate: Moment | null;
}

interface IGetOrdersParams {
  skip?: number;
  perPage?: number;
  sorting?: string;
  filter?: string;
  startDate?: moment.Moment;
  dueDate?: moment.Moment;
}

class List extends React.Component<IListProps, IListState> {
  constructor(props) {
    super(props);

    const filterOpts = loadFilterSettings('clientpackingslip');
    

    this.state = {
      ordersStatus: '',
      totalCount: 0,
      filter: '',
      sorting: 'submittingTime ASC',
      skip: 0,
      perPage: this.props.perPage || 10,
      divisionLocationFilter: filterOpts.location || props.locationInfo.id,
      startDate: filterOpts.startDate != null ? moment(filterOpts.startDate) :  null,
      dueDate: filterOpts.dueDate != null ? moment(filterOpts.dueDate) : null,
    };
    this.getOrders = this.getOrders.bind(this);
    this.handleSortChange = this.handleSortChange.bind(this);
    this.handlePaginate = this.handlePaginate.bind(this);
    this.handleDateChanged = this.handleDateChanged.bind(this);
    this.handleFilterChange = this.handleFilterChange.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.locationInfo.id !== this.props.locationInfo.id) {
      this.setState({ divisionLocationFilter: nextProps.locationInfo.id }, () => {
        this.getOrders();
      });
    }
  }

  componentDidMount() {
    this.props.setSearch('/packing-slip-reconciliation/company-packing-slips/', (text) => {
      this.setState({ skip: 0 }, () => {
        this.getOrders({ filter: text });
      });
    });
    this.getOrders();
  }

  static renderPreloader(perPage: number, status: string): React.Component[] {
    const rows = [];
    const elementsCount = (status === 'loading') ? perPage : 0;

    for (let i = 0; i < elementsCount; i++) {
      rows.push(<Table.Row
        key={i}
        rows={perPage}
        cells={[
          <Skeleton.Line key={`order-list-preloader-${i}`} size="sm" />,
          <Skeleton.Line key={`order-list-preloader-${i}`} size="sm" />,
          <Skeleton.Line key={`order-list-preloader-${i}`} size="sm" />,
          <Skeleton.Line key={`order-list-preloader-${i}`} size="sm" />,
          <Skeleton.Line key={`order-list-preloader-${i}`} size="sm" />,
        ]}
      />);
    }
    return rows;
  }

  handleFilterChange(value: IFlatLocationOption) {
    this.setState({ divisionLocationFilter: value.id, skip: 0 }, () => {
      this.setClientPackingSlipFilter();
      this.getOrders();
    });
  }

  getOrders(
    params: IGetOrdersParams = {}
  ): void {

    let statusStartKey = 'loading';
    let statusFinnishKey = 'loaded';
    const skip = (Number.isInteger(params.skip) && (params.skip >= 0)) ? params.skip : this.state.skip;
    const startDate = params.startDate || this.state.startDate;
    const dueDate = params.dueDate || this.state.dueDate;

    if (params.sorting) {
      statusStartKey = 'sorting';
      statusFinnishKey = 'sorted';
    }

    this.setState({ ordersStatus: statusStartKey });
    oredrsApi.getCompanyPackingSlips(
      this.state.divisionLocationFilter || this.props.locationInfo.id,
      params.filter || this.state.filter,
      startDate,
      dueDate,
      params.sorting || this.state.sorting,
      skip,
      params.perPage || this.props.perPage,
    )
      .then(({ items, totalCount }) => {
        this.setState({ totalCount, orders: items, ordersStatus: statusFinnishKey });
      })
      .catch(e => {
        this.setState({ ordersStatus: 'error' });
        this.props.notifySwagger(e, 'error');
      });
  }

  handleSortChange(sorting: string): void {
    this.getOrders({ sorting });
    this.setState({ sorting });
  }

  handleDateChanged(range: { startDate: Moment, endDate: Moment }) {

    const startDate = range.startDate ? range.startDate.startOf('day') : undefined;
    const dueDate = range.endDate ? range.endDate.startOf('day') : undefined;

    this.setState({ startDate, dueDate, skip: 0 }, () => {
      this.setClientPackingSlipFilter();
      this.getOrders();
    });
  }

  setClientPackingSlipFilter(){
    const filter = {
      location: this.state.divisionLocationFilter,
      startDate: this.state.startDate,
      dueDate: this.state.dueDate
    };

    saveFilterSettings('clientpackingslip', filter);
  }


  handlePaginate(pageInfo: { perPage?: number, skip: number }): void {
    const {
      perPage,
      skip,
    } = pageInfo;

    this.getOrders({ perPage, skip });
    this.props.setPerPage(perPage);
    this.setState({ skip });
  }

  render() {
    return <React.Fragment>
      <MainContainer>
        <Position.Container>
          <Position.Header>
            <Position.HeaderElement>
              <Article.Text scale="lg"><strong>COMPANY PACKING SLIPS</strong></Article.Text>
            </Position.HeaderElement>
            <Position.HeaderElement >
            <Position.Filter>
                <LocationSelectorComponent
                  customFilter={clientUnitFilterOptions}
                  locationOptions={this.props.locationOptions}
                  onLocationChange={this.handleFilterChange}
                  selectedLocation={this.state.divisionLocationFilter}
                />
              </Position.Filter>
              <DatePicker
                compact
                type="range"
                startDateId="startDate"
                endDateId="endDate"
                isOutsideRange={() => false}
                startDate={this.state.startDate}
                endDate={this.state.dueDate}
                onDatesChange={this.handleDateChanged}
                focusedInput={this.state.focusedInput}
                keepOpenOnDateSelect
                showPeriods
                onFocusChange={(focusedInput) => {
                  this.setState({ focusedInput });
                }}
              />

            </Position.HeaderElement>
          </Position.Header>
          <RunningLineLoader isActive={this.state.ordersStatus === 'sorting'} />
          <Position.Content>
            <Table fullHeight>
              <Table.Header
                columns={[
                  { name: 'submittingTime', label: 'date', sortable: true },
                  { name: 'id', label: 'number', sortable: true },
                  { name: 'packingSlipStatus', label: 'Status' },
                  { name: 'address', label: 'location', sortable: true },
                  { name: 'toolbar', label: '', size: "lg" },
                ]}
                sortBy={this.state.sorting}
                onSortChanged={this.handleSortChange}
              />
              <Table.Body>
                {
                  (
                    (this.state.ordersStatus === 'loaded') ||
                    (this.state.ordersStatus === 'sorting') ||
                    (this.state.ordersStatus === 'sorted')
                  ) &&
                  (
                    this.state.orders.length ?
                      this.state.orders.map((item, index) =>
                        <Table.Row
                          key={`order-list-${index}-${item.id}`}
                          cells={[
                            moment(item.submittingTime).format('MM/DD/YYYY'),
                            item.number,
                            item.packingSlipStatus,
                            item.address,
                            <Position.Toolbar key={`order-list-approve-${index}-${item.id}`}>
                              {
                                (item.packingSlipStatus === 'Processed') &&
                                <Link key={`order-list-id-${index}-${item.id}`} to={`/packing-slip-reconciliation/company-packing-slips/${item.id}`}>View Order</Link>
                              }
                            </Position.Toolbar>,
                          ]}
                        />) :
                      <Table.Row
                        key={0}
                        colSpan="5"
                        rows={this.props.perPage}
                        cells={[<span key="0">NO ORDERS FOUND</span>]}
                      />
                  )
                }
                {
                  List.renderPreloader(this.props.perPage, this.state.ordersStatus)
                }
              </Table.Body>
            </Table>
          </Position.Content>
          <Position.Footer>
            <Pagination
              count={this.state.totalCount}
              skip={this.state.skip}
              perPage={this.props.perPage}
              onChange={this.handlePaginate}
            />
          </Position.Footer>
        </Position.Container>
      </MainContainer>
    </React.Fragment>;
  }
}

interface IConnectedProps {
  locationId: number;
  locationOptions: IFlatLocationOption[];
  perPage: number;
  locationInfo?: IShortLocationInfo;
}

export default connect(
  createStructuredSelector<IGlobalStore, IConnectedProps>({
    locationId: state => state.currentUser.locationInfo.id,
    perPage: state => state.layout.perPage,
    locationOptions: state => state.currentUser.locationOptions,
    locationInfo: state => state.currentUser.locationInfo,
  }),
  dispatch => bindActionCreators({
    notifySwagger: notifyActions.notifySwagger,
    setSearch: searchActions.set,
    setPerPage: layoutActions.setPerPage,
  }, dispatch)
)(withGranted(List));

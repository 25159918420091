import * as React from "react";
import styled from "styled-components";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import immer from "immer";
import moment from "moment";
import {
  ManualEntryDto,
  NoteSearchOutputDto,
  SearchOutputDto,
  UncomparedOrderItemDto,
  UpdateUncomparedOrderItem
} from "../../../service-proxies";

import withGranted, { IWithGrantedProps } from "../../HOC/WithGranted";

import {
  ORDERS_UNCOMPARED_EDIT,
  ORDERS_UNCOMPARED_APPROVE
} from "../../../_constants/permissions";

import { history } from "../../../store";
import { IGlobalStore } from "../../../_reducers/reducers";
import { ProductPriceDto } from "../../../service-proxies";

import {
  getUncomparedOrderForEdit,
  updateUncomparedOrder,
  approveUncomparedOrder
} from "../../../_api/orders";

import { notify, notifySwagger } from "../../../_actions/notification";

import {
  MainContainer,
  Button,
  Article,
  Link,
  Table,
  Skeleton
} from "../../../components";

import { IMatch } from "../../../_types/common";
import Form from "./ViewForm";
import Title from "../../../sharedComponents/Title";
import {
  validationFieldList,
  validators
} from "../../../sharedComponents/ManualEntryValidators";
import withMenu from "../../../containers/HOC/WithMenu";
import { removeHashSign } from "src/_utils/misc";

// positions
const Tabs = styled.div`
  display: flex;
  width: 100%;
  padding: 20px;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const Body = styled.div`
  height: 100%;
  position: relative;
`;

const TableBody = styled.div`
  height: 100%;
`;

const Footer = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 20px 20px 30px;
`;

const Info = styled.div`
  text-align: right;
  padding: 0 20px 20px;
`;

const FooterElement = styled.div`
  > * {
    margin-left: 10px;
  }
`;

const Subtitle = styled.div`
  display: flex;
  flex-direction: row;
  div {
    margin-right: 16px;
  }
`;

const Position = {
  Tabs,
  TableBody,
  Content,
  Body,
  Footer,
  Info,
  FooterElement,
  Subtitle
};

// interfaces
interface INoteDto extends NoteSearchOutputDto {
  displayValue: string;
  invalid?: boolean;
}

interface IViewProps extends IConnectedActions, IWithGrantedProps {
  match: IMatch;
  productEntries: UncomparedOrderItemDto[];
  locationId: number;
  locationName: string;
  catalogId: number;
  handleTabChange: (tab: string) => void;
}

interface IPrices {
  [props: number]: number;
}

interface IViewState {
  orderStatus: string;
  productEntries?: UncomparedOrderItemDto[];
  prices: IPrices;
  message?: string;
  changed?: boolean;
  location?: SearchOutputDto;
  creatorUser?: SearchOutputDto;
  creationTime?: string;
  number?: string;
  isApproved: boolean;
}

class View extends React.Component<IViewProps, IViewState> {
  constructor(props) {
    super(props);

    this.state = {
      orderStatus: "loading",
      prices: null,
      message: "",
      creatorUser: null,
      isApproved: false
    };

    this.getOrder = this.getOrder.bind(this);
    this.handleClickBack = this.handleClickBack.bind(this);
    this.handleSubmitOrder = this.handleSubmitOrder.bind(this);
    this.handleApproveOrder = this.handleApproveOrder.bind(this);
    this.handleResetWarning = this.handleResetWarning.bind(this);
    this.handleNoteChange = this.handleNoteChange.bind(this);
    this.handleNoteDelete = this.handleNoteDelete.bind(this);
    this.handleSaveEdits = this.handleSaveEdits.bind(this);
    this.handleUpdateManualEntry = this.handleUpdateManualEntry.bind(this);
  }

  componentDidMount() {
    this.getOrder();
  }

  getOrder(): void {
    this.setState({ orderStatus: "loading" });

    getUncomparedOrderForEdit(this.props.match.params.orderId)
      .then(orderData => {
        const productEntries = orderData.items;

        this.setState({
          orderStatus: "loaded",
          productEntries: productEntries as UncomparedOrderItemDto[],
          location: orderData.location,
          creatorUser: orderData.creatorUser,
          creationTime: moment(orderData.creationTime).format("MM/DD/YYYY LT"),
          number: removeHashSign(orderData.number),
          isApproved: orderData.isApproved
        });
      })
      .catch(e => {
        this.props.notifySwagger(e, "error");
      });
  }

  handleClickBack(): void {
    history.goBack();
  }

  handleSubmitOrder(): void {
    const productsPrices = [];

    for (const key in this.state.prices) {
      if (Object.prototype.hasOwnProperty.call(this.state.prices, key)) {
        productsPrices.push({
          productId: key,
          price: this.state.prices[key]
        });
      }
    }
  }

  handleApproveOrder(): void {
    this.setState({ orderStatus: "saving" });
    if (this.state.changed) {
      setTimeout(() => {
        if (this.validateNotes()) {
          this.setState({ orderStatus: "loaded", changed: true });
          this.props.notify("Validation error", "error");
          return;
        }

        this.saveEdits()
          .then(() => {
            approveUncomparedOrder({ id: this.props.match.params.orderId })
              .then(() => {
                this.setState({ orderStatus: "saved" });
                history.push("/clients-orders/dashboard");
              })
              .catch(e => {
                this.props.notifySwagger(e, "error");
              });
          })
          .catch(e => {
            this.props.notifySwagger(e, "error");
          });
      }, 200);
    } else {
      approveUncomparedOrder({ id: this.props.match.params.orderId })
        .then(() => {
          this.setState({ orderStatus: "saved" });
          history.push("/clients-orders/dashboard");
        })
        .catch(e => {
          this.props.notifySwagger(e, "error");
        });
    }
  }

  handleResetWarning(): void {
    this.setState({ message: "" });
  }

  setChangedPrices(changedPrices: ProductPriceDto[]): void {
    const prices = { ...this.state.prices };

    changedPrices.forEach(priceEntity => {
      if (prices[priceEntity.productId]) {
        prices[priceEntity.productId] = priceEntity.price;
      }
    });

    this.setState({
      prices
    });
  }

  handleNoteChange(
    note: NoteSearchOutputDto,
    productId: number,
    disableNew?: boolean
  ): void {
    if (this.props.isGranted(ORDERS_UNCOMPARED_EDIT)) {
      this.setState(
        immer((draft: IViewState) => {
          const productEntry = draft.productEntries.find(
            item => item.product.id === productId
          );
          if (productEntry) {
            productEntry.note = (note as any) as NoteSearchOutputDto;
            productEntry.manualEntry = {
              sku: "",
              description: "",
              price: 0,
              quantity: 0,
              isTaxable: true
            };
            draft.changed = true;
          }
        })
      );
    }
  }

  handleNoteDelete(options: INoteDto, id: number): void {
    if (this.props.isGranted(ORDERS_UNCOMPARED_EDIT)) {
      this.setState(
        immer((draft: IViewState) => {
          const productEntry = draft.productEntries.find(
            item => item.product.id === id
          );
          if (productEntry) {
            productEntry.note = null;
            draft.changed = true;
          }
        })
      );
    }
  }

  handleSaveEdits(): void {
    this.setState({ orderStatus: "saving" });

    setTimeout(() => {
      if (this.validateNotes()) {
        this.setState({ orderStatus: "loaded", changed: true });
        this.props.notify("Validation error", "error");
        return;
      }

      this.saveEdits()
        .then(() => {
          this.setState({ orderStatus: "saved", changed: false });
        })
        .catch(e => {
          this.props.notifySwagger(e, "error");
          this.setState({ orderStatus: "loaded", changed: true });
        });
    }, 200); // this is needed if some manual entry is in edit mode
  }

  saveEdits(): Promise<void> {
    const productEntries: UpdateUncomparedOrderItem[] = this.state.productEntries.map(
      orderItem => {
        return {
          id: orderItem.id,
          noteId: orderItem.note ? orderItem.note.id : null,
          manualEntry: orderItem.manualEntry
        };
      }
    );

    return updateUncomparedOrder({
      id: this.props.match.params.orderId,
      items: productEntries
    });
  }

  validateNotes(): boolean {
    let isInvalid = false;
    this.setState(
      immer((draft: IViewState) => {
        draft.productEntries.forEach((orderItem: UncomparedOrderItemDto) => {
          if (orderItem.note) {
            const validator = validators[orderItem.note.manualEntryType];
            const isValidationFailed = validationFieldList.some(field => {
              const fieldValue =
                orderItem &&
                orderItem.manualEntry &&
                orderItem.manualEntry[field];
              const validationStatus = !!validator(field, fieldValue);
              return validationStatus;
            });

            if (isValidationFailed) {
              (orderItem.note as any).invalid = true;
              isInvalid = true;
            }
          }
        });
      })
    );
    return isInvalid;
  }

  renderPreloaderRows(perPage: number, status?: string): React.ReactNode {
    const rows = [];
    const elementsCount = status === "loading" ? perPage - 1 : 0;

    for (let i = 0; i < elementsCount; i++) {
      rows.push(
        <Table.Row
          key={"preloader" + i}
          rows={perPage}
          cells={[
            <Skeleton.Square key="thumb-preloader" />,
            <React.Fragment key="item-preloader">
              <p>
                <Skeleton.Line />
              </p>
              <p>
                <Skeleton.Line />
              </p>
              <p>
                <Skeleton.Line />
              </p>
            </React.Fragment>,
            <Skeleton.Line key="price-preloader" size="xs" />,
            <Skeleton.Line key="count-preloader" size="sm" />,
            <Skeleton.Line key="sub-price-preloader" size="xs" />,
            <Skeleton.Line key="action-preloader" size="xs" />
          ]}
        />
      );
    }

    const table = (
      <Table>
        <Table.Header
          columns={[
            { name: "thumb", label: "", size: "xs" },
            { name: "item", label: "item", size: "xl" },
            { name: "price", label: "price" },
            { name: "quantity", label: "quantity" },
            { name: "subtotal", label: "subtotal" },
            { name: "toolbar", label: "", size: "xs" }
          ]}
        />
        <Table.Body>{rows}</Table.Body>
      </Table>
    );

    return table;
  }

  handleUpdateManualEntry(entry: ManualEntryDto, noteId, productId) {
    this.setState(
      immer((draft: IViewState) => {
        const productEntry = draft.productEntries.find(
          pe => pe.product.id === productId
        );
        if (productEntry) {
          productEntry.manualEntry = entry;
          draft.changed = true;
        }
      })
    );
  }

  render() {
    return (
      <MainContainer
        centered
        scale="md"
        renderTitle={
          <Title label="ORDER COMPARISON">
            {this.state.orderStatus === "loading" && (
              <Position.Subtitle>
                <Title.Element>
                  <Skeleton.Line size="lg" />
                </Title.Element>
              </Position.Subtitle>
            )}
            {this.state.orderStatus !== "loading" && (
              <Position.Subtitle>
                <Title.Element>
                  Buyer:{" "}
                  {this.state.creatorUser &&
                    this.state.creatorUser.displayValue}
                </Title.Element>
                <Title.Element>
                  Order #: {this.props.match.params.orderId}
                </Title.Element>
                <Title.Element>
                  Date/Time: {this.state.creationTime}
                </Title.Element>
              </Position.Subtitle>
            )}
          </Title>
        }
      >
        <Position.Content>
          <Position.Body>
            {(this.state.orderStatus === "loaded" ||
              this.state.orderStatus === "saving" ||
              this.state.orderStatus === "saved") && (
              <Form
                productEntries={this.state.productEntries}
                onResetWarning={this.handleResetWarning}
                onNoteChange={this.handleNoteChange}
                onNoteDelete={this.handleNoteDelete}
                onUpdateManualEntry={this.handleUpdateManualEntry}
                isApproved={this.state.isApproved}
              />
            )}
            {this.state.orderStatus === "loading" &&
              this.renderPreloaderRows(5, this.state.orderStatus)}
            <Position.Info>
              {(this.state.orderStatus === "loaded" ||
                this.state.orderStatus === "saving" ||
                this.state.orderStatus === "saved") && (
                <Article.P scale="sm">
                  Facility Location: {this.state.location.displayValue}
                </Article.P>
              )}
            </Position.Info>
          </Position.Body>
          <Position.Footer>
            <Position.FooterElement />
            <Position.FooterElement>
              <Link.Button
                onClick={this.handleClickBack}
                preloader={
                  this.state.orderStatus === "loading" ||
                  this.state.orderStatus === "saving"
                }
              >
                Back
              </Link.Button>
              {this.props.isGranted(ORDERS_UNCOMPARED_EDIT) &&
                !this.state.isApproved &&
                this.state.changed && (
                  <Button
                    onClick={this.handleSaveEdits}
                    preloader={
                      this.state.orderStatus === "loading" ||
                      this.state.orderStatus === "saving"
                    }
                  >
                    Save Edits
                  </Button>
                )}
              {this.props.isGranted(ORDERS_UNCOMPARED_APPROVE) &&
                !this.state.isApproved && (
                  <Button
                    onClick={this.handleApproveOrder}
                    preloader={
                      this.state.orderStatus === "loading" ||
                      this.state.orderStatus === "saving"
                    }
                    disabled={
                      this.state.productEntries &&
                      !this.state.productEntries.length
                    }
                  >
                    Approve Order
                  </Button>
                )}
            </Position.FooterElement>
          </Position.Footer>
        </Position.Content>
      </MainContainer>
    );
  }
}

interface IConnectedProps {
  locationId: number;
  locationName: string;
  catalogId: number;
}

interface IConnectedActions {
  notify: typeof notify;
  notifySwagger: typeof notifySwagger;
}

export default withMenu(
  connect(
    createStructuredSelector<IGlobalStore, IConnectedProps>({
      locationId: state => state.currentUser.locationInfo.id,
      locationName: state => state.currentUser.locationInfo.name,
      catalogId: state => state.currentUser.catalogId
    }),
    {
      notify,
      notifySwagger
    }
  )(withGranted(View)),
  false
);

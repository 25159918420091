import styled from 'styled-components';

import Thumbnail from './Thumbnail';
import Article from './Article';

export const Card = styled.div`
  ${props => {
    let {
      global,
    } = props.theme;
    return `
      ${global}
      width: 230px;
      font-size: 14px;
      overflow: hidden;
      text-overflow: ellipsis;

      display: inline-block;
      .${Card.Line.styledComponentId}{
        margin: 3px 0;
      }
  `;}}
`;

Card.Image = styled(Thumbnail).attrs({
  size: 'xl',
})`
  margin: 0 0 12px;
`;

Card.Line = Article.P.extend`
  line-height: 22px;
`;

export default Card;
import * as React from "react";
import PropTypes from "prop-types";

import { history } from "../../../store";

import {
  Button,
  Field,
  Select,
  FormGrid as Grid,
  Link,
  Textarea
} from "../../../components";

import withMenu from "../../HOC/WithMenu";

import * as stateApi from "../../../_api/state";
import {
  FormikActions,
  FormikComputedProps,
  FormikHandlers,
  FormikState
} from "formik";
import { IEditDivisionState } from "./Edit";
import { SearchOutputDto } from "../../../service-proxies";

interface IEditFormProps
  extends FormikHandlers,
    FormikState<IEditDivisionState>,
    FormikActions<IEditDivisionState>,
    FormikComputedProps<IEditDivisionState> {
  isLoading: boolean;
  isNew: boolean;
}

interface IEditFormState {
  states: {
    items?: SearchOutputDto[];
    status: string;
  };
}

class Form extends React.Component<IEditFormProps, IEditFormState> {
  static propTypes = {
    values: PropTypes.object,
    errors: PropTypes.object,
    touched: PropTypes.object,
    handleChange: PropTypes.func,
    handleBlur: PropTypes.func,
    handleSubmit: PropTypes.func,
    isSubmitting: PropTypes.bool,
    setFieldValue: PropTypes.func,
    setValues: PropTypes.func,
    setFieldError: PropTypes.func,
    isLoading: PropTypes.bool,
    isNew: PropTypes.bool
  };

  constructor(props) {
    super(props);

    this.state = {
      states: {
        items: [],
        status: ""
      }
    };

    this.loadState = this.loadState.bind(this);
    this.handleStateSelect = this.handleStateSelect.bind(this);

    this.handleCancel = this.handleCancel.bind(this);
    this.handleDiscard = this.handleDiscard.bind(this);
  }

  componentDidMount() {
    this.loadState();
  }

  loadState() {
    this.setState({
      states: {
        status: "loading"
      }
    });
    stateApi
      .getAll()
      .then(({ items }) => {
        this.setState({
          states: {
            items,
            status: "loaded"
          }
        });
      })
      .catch(e => {
        this.setState({
          states: {
            status: "error"
          }
        });
      });
  }

  handleStateSelect(data) {
    if (data) {
      this.props.setValues({
        ...this.props.values,
        stateId: data.id,
        state: data
      });
    } else {
      this.props.setValues({
        ...this.props.values,
        stateId: null,
        state: null
      });
    }
  }

  handleCancel() {
    history.goBack();
  }

  handleDiscard() {
    history.goBack();
  }

  render() {
    const {
      values,
      errors,
      touched,
      handleChange,
      handleBlur,
      handleSubmit
    } = this.props;

    const isLoaded = values.status === "loading";

    return (
      <form noValidate onSubmit={handleSubmit}>
        <Grid>
          <Grid.Body>
            <Grid.Row>
              <Grid.Cell>
                <Field
                  required
                  type="text"
                  name="name"
                  label="Name"
                  placeholder="Name..."
                  value={values.name}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  disabled={isLoaded}
                  error={touched.name && errors.name}
                />
              </Grid.Cell>
              <Grid.Cell />
            </Grid.Row>

            <Grid.Row>
              <Grid.Cell>
                <Textarea
                  required
                  name="address1"
                  label="Billing Address 1"
                  placeholder="Billing Address..."
                  value={values.address1}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  disabled={isLoaded}
                  error={touched.address1 && errors.address1}
                />
              </Grid.Cell>
            </Grid.Row>

            <Grid.Row>
              <Grid.Cell>
                <Textarea
                  name="address2"
                  label="Billing Address 2"
                  placeholder="Billing Address..."
                  value={values.address2}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  disabled={isLoaded}
                  error={touched.address2 && errors.address2}
                />
              </Grid.Cell>
            </Grid.Row>

            <Grid.Row>
              <Grid.Cell>
                <Field
                  required
                  type="text"
                  name="city"
                  label="Billing City"
                  placeholder="Billing City..."
                  value={values.city}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  disabled={isLoaded}
                  error={touched.city && errors.city}
                />
              </Grid.Cell>
              <Grid.Cell>
                <Select
                  required
                  name="state"
                  label="Billing State"
                  placeholder="Billing State..."
                  labelKey="displayValue"
                  valueKey="id"
                  value={values.state}
                  options={this.state.states.items}
                  isLoading={this.state.states.status === "loading"}
                  onChange={this.handleStateSelect}
                  disabled={isLoaded}
                  error={touched.state && (errors.state as string)}
                />
              </Grid.Cell>
            </Grid.Row>

            <Grid.Row>
              <Grid.Cell>
                <Field
                  required
                  type="text"
                  name="zipCode"
                  label="Billing Zip Code"
                  placeholder="Billing Zip Code..."
                  value={values.zipCode}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  disabled={isLoaded}
                  error={touched.zipCode && errors.zipCode}
                />
              </Grid.Cell>
              <Grid.Cell />
            </Grid.Row>
          </Grid.Body>
          <Grid.Footer>
            <Grid.FooterElement />
            <Grid.FooterElement>
              {this.props.isNew ? (
                <Link.Button primary onClick={this.handleCancel} scale="lg">
                  Cancel
                </Link.Button>
              ) : (
                <Link.Button primary onClick={this.handleDiscard} scale="lg">
                  Discard
                </Link.Button>
              )}
              <Button
                type="submit"
                disabled={isLoaded}
                scale="lg"
                preloader={isLoaded}
              >
                {this.props.isNew ? "Submit" : "Save"}
              </Button>
            </Grid.FooterElement>
          </Grid.Footer>
        </Grid>
      </form>
    );
  }
}

export default withMenu(Form, false);

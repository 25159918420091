import { Reducer } from 'redux';
import { types } from '../_actionTypes/cart';
import { ProductListDto } from '../service-proxies';
import { SimpleMap } from '../_types/common';
import immer from 'immer';
import { INTEGER_SIZE } from '../_constants/data';


export interface ICartProductEntry {
  product: ProductListDto;
  price: number;
  count: number | string;
}

export interface ICartStore {
  productEntries: ICartProductEntry[];
  totalPrice: number;
  savedAt: number;
}

export interface ICartActionAddProduct {
  type: types.CART_ADD_PRODUCT;
  product: ProductListDto;
  price: number;
  count: number | string;
}

export interface ICartActionProductCountChanged {
  type: types.CART_PRODUCT_COUNT_CHANGED;
  productId: number;
  count: number | string;
}

export interface ICartActionRemove {
  type: types.CART_REMOVE_PRODUCT;
  productId: number;
}

export interface ICartActionMultiRemove {
  type: types.CART_MULTI_REMOVE_PRODUCTS;
  selectedMap: SimpleMap;
}

export interface ICartActionClearCart {
  type: types.CART_CLEAR_CART;
}

export interface ICartActionCartToBeSaved {
  type: types.CART_CART_IS_TO_BE_SAVED;
  savedAt: number;
}

export interface ICartActionLoadCart {
  type: types.CART_LOAD_CART_FROM_CACHE;
  cart: ICartStore | null;
}

const initialState: ICartStore = {
  productEntries: [],
  totalPrice: 0,
  savedAt: 0,
};

type ActionType = ICartActionAddProduct
  | ICartActionAddProduct
  | ICartActionProductCountChanged
  | ICartActionRemove
  | ICartActionMultiRemove
  | ICartActionClearCart
  | ICartActionCartToBeSaved
  | ICartActionLoadCart;

const reducer: Reducer<ICartStore> = (state: ICartStore = initialState, action: ActionType) => {
  switch (action.type) {

    case types.CART_ADD_PRODUCT:
      return immer(state, draft => {
        const index = draft.productEntries.findIndex(entry => entry.product.id === action.product.id);
        if (index === -1) {
          draft.productEntries.push({
            count: action.count,
            price: action.price,
            product: action.product,
          });
          draft.totalPrice += action.price * parseInt(action.count.toString());
        } else {
          const productEntry = draft.productEntries[index];

          productEntry.count = parseInt(productEntry.count.toString()) + parseInt(action.count.toString());

          draft.totalPrice += productEntry.price * parseInt(action.count.toString());
        }
      });

    case types.CART_PRODUCT_COUNT_CHANGED:
      return immer(state, draft => {
        const productEntry = draft.productEntries.find(entry => entry.product.id === action.productId);
        if (productEntry) {
          let itemsCount = action.count.toString();
          if (action.count < 0) {
            itemsCount = '';
          } else if (action.count > INTEGER_SIZE) {
            itemsCount = INTEGER_SIZE.toString();
          }
          const priceChange = (parseInt(itemsCount.toString()) - parseInt(productEntry.count.toString())) * productEntry.price;
          productEntry.count = itemsCount;
          draft.totalPrice = Math.round((draft.totalPrice + priceChange) * 100) / 100;
        }
      });

    case types.CART_REMOVE_PRODUCT:
      return immer(state, draft => {
        const index = draft.productEntries.findIndex(entry => entry.product.id === action.productId);
        if (index !== -1) {
          const priceChange = draft.productEntries[index].price * parseInt(draft.productEntries[index].count.toString());
          draft.productEntries.splice(index, 1);
          draft.totalPrice -= priceChange;
        }
      });

    case types.CART_MULTI_REMOVE_PRODUCTS:
      return immer(state, draft => {
        const productsEntries = [];
        let totalPrice = 0;
        draft.productEntries.forEach(entry => {
          if (!action.selectedMap[entry.product.id]) {
            productsEntries.push(entry);
            totalPrice += entry.price * parseInt(entry.count.toString());
          }
        });
        draft.productEntries = productsEntries;
        draft.totalPrice = totalPrice;
      });

    case types.CART_CART_IS_TO_BE_SAVED:
      return immer(state, draft => {
        draft.savedAt = action.savedAt;
      });

    case types.CART_LOAD_CART_FROM_CACHE:
      if (action.cart) {
        return action.cart;
      } else {
        return initialState;
      }

    case types.CART_CLEAR_CART:
      return initialState;

    default:
      return state;
  }
};

export default reducer;
import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { history } from '../../../store';

import {
  Button,
  Link,
  TabsSwitch,
  FormGrid as Grid,
} from '../../../components';

import FormInfo from './FormInfo';
import FormPermissions from './FormPermissions';
import FormRoles from './FormRoles';
import FormSupervisors from './FormSupervisors';
import {
  ADMINISTRATION_USERS_ROLES_EDIT,
  ADMINISTRATION_USERS_PERMISSIONS_EDIT,
  ADMINISTRATION_USERS_SUPERVISORS_EDIT,
} from '../../../_constants/permissions';

const Position = {
  ButtonsWrap: styled.div`
    display: flex;
    align-items: center;
    flex-direction: row;
  `,
  CancelButton: styled.span`
    margin-right: 18px;
  `,
  Tabs: styled.div`
    padding: 10px 0 9px 10px;
  `,
};

class UserForm extends React.Component {
  static propTypes = {
    values: PropTypes.object.isRequired,
    errors: PropTypes.object.isRequired,
    touched: PropTypes.object.isRequired,
    handleChange: PropTypes.func.isRequired,
    handleBlur: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    isSubmitting: PropTypes.bool.isRequired,
    setFieldValue: PropTypes.func.isRequired,
    setFieldError: PropTypes.func.isRequired,
    setFieldTouched: PropTypes.func.isRequired,
    openModal: PropTypes.func.isRequired,
    isLoading: PropTypes.bool,
    isNew: PropTypes.bool,
    isGranted: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);

    this.permissionsEditEnabled = props.isGranted(ADMINISTRATION_USERS_PERMISSIONS_EDIT);
    this.roleEditEnabled = props.isGranted(ADMINISTRATION_USERS_ROLES_EDIT);
    this.buyingManagersEditEnabled = props.isGranted(ADMINISTRATION_USERS_SUPERVISORS_EDIT);

    this.handleCancel = this.handleCancel.bind(this);
    this.handleTabChanged = this.handleTabChanged.bind(this);
  }

  handleCancel() {
    history.goBack();
  }

  handleTabChanged(value) {
    this.props.setFieldValue('tab', value);
  }

  render() {
    let {
      values,
      handleSubmit,
    } = this.props;

    let disabled = this.props.isLoading || !values.isActive;

    return (
      <form formNoValidate noValidate onSubmit={handleSubmit}>
        <Grid>
          <Grid.TabsHeader>
            <Position.Tabs>
              <TabsSwitch onTabChanged={this.handleTabChanged} items={values.tabsOptions} />
            </Position.Tabs>
          </Grid.TabsHeader>
          {values.tab === 'info' && <FormInfo {...this.props} openModal={this.props.openModal} disabled={disabled} />}
          {values.tab === 'roles' && <FormRoles {...this.props} disabled={disabled || !this.roleEditEnabled} />}
          {values.tab === 'permissions' &&
          <FormPermissions {...this.props} disabled={disabled || !this.permissionsEditEnabled} />}
          {values.tab === 'buyingManagers' && <FormSupervisors
            {...this.props}
            disabled={disabled || !this.buyingManagersEditEnabled}
          />}
          <Grid.Footer>
            <div />
            <Position.ButtonsWrap>
              <Position.CancelButton>
                <Link.Button
                  primary
                  onClick={this.handleCancel}
                  scale="lg"
                >
                  Cancel
                </Link.Button>
              </Position.CancelButton>
              <Button
                type="submit"
                disabled={this.props.isLoading || !values.isActive}
                scale="lg"
                preloader={this.props.isLoading}
              >
                {this.props.isNew ? 'Submit' : 'Save'}
              </Button>
            </Position.ButtonsWrap>
          </Grid.Footer>
        </Grid>
      </form>
    );
  }
}

export default UserForm;

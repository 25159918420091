import * as React from 'react';
import { ThemeProvider } from 'styled-components';
import { ConnectedRouter } from 'react-router-redux';
import { Route } from 'react-router-dom';
import { connect } from 'react-redux';
import { install as installResizeObserverPolyfill } from 'resize-observer';

import { initHttpProxy } from '../../_actions/current-user';
import { history } from '../../store';
import Layout from '../Layout/Layout';
import Notification from '../Notification/Notification';
import theme from '../../assets/theme';
import { Modal } from '../../components';

interface IProps {
  initHttpProxy: () => void;
}

class App extends React.PureComponent<IProps> {
  constructor(props) {
    super(props);
    props.initHttpProxy();

    if (!('ResizeObserver' in window)) {
      installResizeObserverPolyfill();
    }
  }

  render() {
    return (
      <ConnectedRouter history={history}>
        <ThemeProvider theme={theme}>
          <React.Fragment>
            <Route component={Layout} />
            <Notification />
            <Modal.Portal id="modalPlace" />
          </React.Fragment>
        </ThemeProvider>
      </ConnectedRouter>
    );
  }
}

export default connect(
  null,
  {
    initHttpProxy
  }
)(App);

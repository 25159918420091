import { types } from '../_actionTypes/location';
import {types as typesNotification} from '../_actionTypes/notification';
import { locationServiceProxy } from '../servicePool';
import {
  getSwaggerErrorResponse,
} from '../_utils/errorHandling';
import {CreateLocationDto, UpdateLocationDto} from '../service-proxies';

export function getAll(id, perPage, skip, filter = '') {
  return function (dispatch) {
    dispatch({
      type: types.LOCATION_GET_ALL,
      listStatus: 'loading',
      status: '',
    });
    locationServiceProxy.getLocationsByDivisionId(id, perPage, skip, filter)
      .then(data => {
        const {
          items,
          totalCount,
        } = data;
        const locations = items;
        dispatch({
          type: types.LOCATION_GET_ALL,
          locations,
          totalCount,
          listStatus: 'loaded',
          perPage,
          skip,
        });
      })
      .catch(e => {
        dispatch({
          type: types.LOCATION_GET_ALL,
          listStatus: 'error',
        });
        dispatch({
          type: typesNotification.NOTIFICATION_NOTIFY,
          message: getSwaggerErrorResponse(e).error.message,
          messageType: 'error',
        });
      });
  };
}

export function create(data: CreateLocationDto) {
  return function (dispatch) {
    dispatch({
      type: types.LOCATION_CREATE,
      status: 'saving',
    });
    locationServiceProxy.createLocation(data)
      .then(() => {
        dispatch({
          type: types.LOCATION_CREATE,
          status: 'saved',
        });
      })
      .catch(e => {
        dispatch({
          type: types.LOCATION_CREATE,
          listStatus: 'error',
        });
        dispatch({
          type: typesNotification.NOTIFICATION_NOTIFY,
          message: getSwaggerErrorResponse(e).error.message,
          messageType: 'error',
        });
      });
  };
}

export function get(id) {
  return function (dispatch) {
    dispatch({
      type: types.LOCATION_GET,
      status: 'loading',
    });
    locationServiceProxy.getLocationForEdit(id)
      .then(location => {
        dispatch({
          type: types.LOCATION_GET,
          location,
          status: 'loaded',
        });
      })
      .catch(e => {
        dispatch({
          type: types.LOCATION_GET,
          status: 'error',
        });
        dispatch({
          type: typesNotification.NOTIFICATION_NOTIFY,
          message: getSwaggerErrorResponse(e).error.message,
          messageType: 'error',
        });
      });
  };
}

export function update(data: UpdateLocationDto) {
  return function (dispatch) {
    dispatch({
      type: types.LOCATION_UPDATE,
      status: 'saving',
    });
    locationServiceProxy.updateLocation(data)
      .then(() => {
        dispatch({
          type: types.LOCATION_UPDATE,
          status: 'saved',
        });
      })
      .catch(e => {
        dispatch({
          type: types.LOCATION_UPDATE,
          listStatus: 'error',
        });
        dispatch({
          type: typesNotification.NOTIFICATION_NOTIFY,
          message: getSwaggerErrorResponse(e).error.message,
          messageType: 'error',
        });
      });
  };
}

export function remove(id) {
  return function (dispatch) {
    return locationServiceProxy.deleteLocation(id)
      .then(() => {
        dispatch({
          type: types.LOCATION_REMOVE,
          listStatus: 'loaded',
        });
      })
      .catch(e => {
        dispatch({
          type: types.LOCATION_REMOVE,
          listStatus: 'error',
        });
        dispatch({
          type: typesNotification.NOTIFICATION_NOTIFY,
          message: getSwaggerErrorResponse(e).error.message,
          messageType: 'error',
        });
      });
  };
}


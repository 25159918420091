import React from 'react';
import styled from 'styled-components';

import {
  PreloaderIcon,
} from '../assets/icons';

const ButtonIcon = styled.span`
  margin: 0 0 0 -5px;
  i{
    height: auto;
  }
`;

export const Preloader = styled<{ className?: string }>(({ className }) => {
  return <div className={className}>
    <PreloaderIcon />
  </div>;
})`
  height: 18px;
  overflow: hidden;
  position: absolute;
  top: 0px;
  left: 0;
  right: 0;
  bottom: 0;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  ${PreloaderIcon} {
    ${({ theme }) => {
    const {
      main,
    } = theme;

    return `
        overflow: hidden;
        height: 18px;
        width: 50px;
        svg{
          height: 36px;
          width: 100%;
          circle{
            fill: ${main};
          }
        }
      `;
  }}
  }
`;

interface IButtonProps {
  type?: 'button' | 'submit';
  children?: React.ReactChildren | React.ReactElement<any> | string | React.ReactText[] | number | boolean | React.ReactNode;
  preloader?: boolean;
  primary?: boolean;
  negative?: boolean;
  parentRef?: React.Ref<any>;
  icon?: React.ReactElement<any>;
  disabled?: boolean;
  onClick?: (e) => void;
  scale?: 'sm' | 'lg' | 'md';
  default?: boolean;
}

const Button = styled<IButtonProps>(({
  type,
  children,
  preloader,
  primary,
  negative,
  parentRef,
  icon,
  ...buttonProps
}) => {
  return <button ref={parentRef}
    type={type ? type : 'button'}
    {...buttonProps}
  >
    {
      icon &&
      <ButtonIcon>
        {icon}
      </ButtonIcon>
    }
    {
      children
    }
    {
      preloader && <Preloader />
    }
  </button>;
})`
  ${({ theme, ...props }) => {

    const {
      lightest,
      primary,
      primaryRGB,
      main,
      light,
      defaultRGB,
      fs_md,
      fs_sm,
      global,
      active,
    } = theme;

    // regular
    let backgroundColor;
    if (props.primary) {

      backgroundColor = props.negative ?
        lightest :
        primary;

      if (props.disabled) {
        backgroundColor = props.negative ?
          lightest :
          `rgba(${primaryRGB}, 0.8)`;
      }

    } else {

      backgroundColor = props.negative ?
        main :
        theme.default;

      if (props.disabled) {
        backgroundColor = props.negative ?
          main :
          `rgba(${defaultRGB}, 0.8)`;
      }
    }

    let paddingTop;
    let paddingLeft;
    let width;
    switch (props.scale) {
      case 'sm':
        paddingTop = '7px';
        paddingLeft = '14px';
        width = 'auto';
        break;
      case 'lg':
        paddingTop = '15px';
        paddingLeft = '16px';
        width = '106px';
        break;
      case 'md':
        paddingTop = '10px';
        paddingLeft = '14px';
        width = '106px';
        break;
      default:
        paddingTop = '10px';
        paddingLeft = '14px';
        width = '106px';
    }
    const paddingBottom = paddingTop;
    const paddingRight = paddingLeft;

    let fontColor;
    if (props.primary) {

      fontColor = props.disabled ?
        (props.negative ? light : main) :
        (props.negative ? primary : main);

    } else {

      fontColor = props.negative ?
        primary :
        main;

    }

    let borderColor;
    if (props.primary) {

      borderColor = props.negative ?
        light :
        primary;

    } else {

      borderColor = props.negative ?
        theme.default :
        theme.default;

    }

    // hover
    let backgroundColorHover;
    if (props.primary) {

      backgroundColorHover = props.negative ?
        lightest :
        `rgba(${primaryRGB}, 0.8)`;

    } else {

      backgroundColorHover = props.negative ?
        main :
        `rgba(${defaultRGB}, 0.8)`;

    }

    let fontColorHover;
    if (props.primary) {

      fontColorHover = props.negative ?
        theme.default :
        main;

    } else {

      fontColorHover = props.negative ?
        theme.default :
        main;

    }

    let borderColorHover;
    if (props.primary) {

      borderColorHover = props.negative ?
        light :
        `rgba(${primaryRGB}, 0.8)`;

    } else {

      borderColorHover = props.negative ?
        `rgba(${defaultRGB}, 0.8)` :
        `rgba(${defaultRGB}, 0.8)`;

    }

    const cursor = props.disabled ?
      'default' :
      'pointer';

    const fontSize = props.scale === 'sm' ?
      fs_sm :
      fs_md;

    // active
    const backgroundColorActive = backgroundColor;
    const fontColorActive = fontColor;

    return `
      ${global}

      padding: ${paddingTop} ${paddingRight} ${paddingBottom} ${paddingLeft};
      font-size: ${fontSize};
      font-weight: 500;  
      min-width: ${width};
      border: 1px solid ${borderColor};
      position: relative;
      
      background: ${backgroundColor};
      color: ${fontColor};

      cursor: ${cursor};
      transition: all .1s ease-in-out;

      svg{
        path{
          fill: ${fontColor};
        }
      }
      
      &:hover:enabled {
        background: ${backgroundColorHover};
        color: ${fontColorHover};
        border: 1px solid ${borderColorHover};

        svg{
          path{
            fill: ${fontColorHover};
          }
        }
      }

      &:active:enabled {
        background: ${backgroundColorActive};
        color: ${fontColorActive};
        box-shadow: none;
        outline-color: ${active};

        svg{
          path{
            fill: ${fontColorActive};
          }
        }
      }

      &:active{
        outline-color: ${active};
      }

      &:focus{
        outline-color: ${active};
      }

      &:focus-within{
        outline-color: ${active};
      }

    `;
  }}
  ${Preloader} {
    ${({ theme, ...props }) => {

    const {
      primary,
      main,
      lightest,
      primaryRGB,
      defaultRGB,
    } = theme;

    let backgroundColor;
    if (props.primary) {

      backgroundColor = props.negative ?
        lightest :
        primary;

      if (props.disabled) {
        backgroundColor = props.negative ?
          lightest :
          `rgba(${primaryRGB}, 0.8)`;
      }

    } else {

      backgroundColor = props.negative ?
        main :
        theme.default;

      if (props.disabled) {
        backgroundColor = props.negative ?
          main :
          `rgba(${defaultRGB}, 0.8)`;
      }
    }
    return `
        background: ${backgroundColor};
      `;
  }}
  }
  ${PreloaderIcon}{
    ${({ theme, ...props }) => {
    const {
      primary,
      main
    } = theme;
    let preloaderColor;
    if (props.primary) {

      preloaderColor = props.negative ?
        primary :
        main;

    } else {

      preloaderColor = props.negative ?
        theme.default :
        main;

    }

    return `
        svg{
          circle{
            fill: ${preloaderColor};
          }
        }
      `;
  }}
  }
`;

export default Button;
import React from 'react';
import styled from 'styled-components';

export const Shape = styled.path``;

const LockIcon = props => {
  return (
    <i {...props}>
      <svg viewBox="-2547 -2744 22 25" width="18.563" height="25">
        <path d=" M -2530.031 -2734.9 Q -2530.031 -2738 -2530.031 -2738 C -2530.031 -2741.3 -2532.816 -2744 -2536.219 -2744 C -2536.219 -2744 -2536.219 -2744 -2536.219 -2744 C -2539.622 -2744 -2542.406 -2741.3 -2542.406 -2738 Q -2542.406 -2738 -2542.406 -2734.9" />
        <path d=" M -2536.219 -2719 C -2531.093 -2719 -2526.937 -2723.029 -2526.937 -2728 C -2526.937 -2732.971 -2531.093 -2737 -2536.219 -2737 C -2541.345 -2737 -2545.5 -2732.971 -2545.5 -2728 C -2545.5 -2723.029 -2541.345 -2719 -2536.219 -2719 Z "/>
        <Shape d=" M -2536.219 -2726 C -2535.08 -2726 -2534.156 -2726.895 -2534.156 -2728 C -2534.156 -2729.105 -2535.08 -2730 -2536.219 -2730 C -2537.358 -2730 -2538.281 -2729.105 -2538.281 -2728 C -2538.281 -2726.895 -2537.358 -2726 -2536.219 -2726 Z " />
        <Shape d=" M -2537.25 -2728 L -2535.187 -2728 L -2535.187 -2723 L -2537.25 -2723 L -2537.25 -2728 Z " />
      </svg>
    </i>
  );
};

export default styled(LockIcon) `
  ${ props => `
    width: 21px;
    height: 21px;
    display: inline-flex;
    justify-content: center;
  
    path{
      fill-rule:evenodd;
      fill:none;
      vector-effect:non-scaling-stroke;
      stroke-width:2;
      stroke:${props.theme.light};
      stroke-linejoin:miter;
      stroke-linecap:square;
      stroke-miterlimit:10;
    }

    .${Shape.styledComponentId}{
      fill:${props.theme.light};
      stroke-width:0;
    }
  `}
`;

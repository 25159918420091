import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import keycode from 'keycode';

import {
  Label,
} from '../components';

import Error from './Error';

export const CompactLabel = styled.label`
  ${({ theme, ...props }) => {

    let {
      global,
      warning,
      primary,
      fs_md,
      lightest,
      light,
    } = theme;

    let borderColor = props.error ?
      warning :
      light;

    let color = props.error ?
      warning :
      primary;

    let borderWidth = props.primary ?
      '2px' :
      '1px';

    return `
      ${global}
      display: inline-flex;
      white-space: nowrap;
      flex-wrap: wrap;
      flex: 0 0 auto;
      min-height: 18px;

      font-size: ${fs_md};
      border-top-width: ${borderWidth};
      border-left-width: ${borderWidth};
      border-bottom-width: ${borderWidth};
      margin: 0;

      border-top-style: solid;
      border-left-style: solid;
      border-bottom-style: solid;

      color: ${color};
      background-color: ${lightest};
      border-color: ${borderColor};
    `;
  }}
`;

export const Body = styled.div`
  ${ ({ scale, compact, iconShow }) => {

    let paddingIcon;
    switch (scale) {
      case 'sm':
      case 'md':
        paddingIcon = '40px';
        break;
      case 'lg':
        paddingIcon = '50px';
        break;
      default:
        paddingIcon = '40px';
    }

    let margin = compact ?
      '0' :
      '7px 0 3px';

    return `
      position: relative;
      margin: ${margin};
      flex: 1 1 100%;

      ${
      !!iconShow && `
        > .${iconShow.styledComponentId} {
          position: absolute;
          display: flex;
          justify-content: center;
          align-items: center;
          height: auto;
          width: ${paddingIcon};
          top: 0px;
          left: 0px;
          bottom: 0px;
          pointer-events: none;
        }`
      }

    `;
  }}
`;

export const Field = styled(({
  icon,
  className,
  label,
  error,
  required,
  compact,
  prohibitKeys,
  scale,
  ...inputProps
}) => {

  let Icon = icon;

  function handleKeyDown(e) {
    if (prohibitKeys && prohibitKeys.includes(keycode(e.which))) {
      e.preventDefault();
    }
  }

  return (
    <div className={className} >

      {
        label &&
        (
          !compact ?
            <Label
              error={error}
              htmlFor={inputProps.id ? inputProps.id : null}
            >
              {`${label} ${required ? ' * ' : ''}`}
            </Label> :
            <CompactLabel
              error={error}
              htmlFor={inputProps.id ? inputProps.id : null}
            >
              {`${label} ${required ? ' * ' : ''}`}
            </CompactLabel>
        )
      }

      <Body compact={compact} iconShow={icon} scale={scale}>
        <input
          {...inputProps}
          onKeyDown={handleKeyDown}
          className={
            `${icon ? ' icon' : ''}`
          }
        />
        {
          icon && <Icon />
        }
      </Body>

      <Error>
        {error}
      </Error>

    </div>
  );
})`
  ${({ theme, ...props }) => {

    let {
      global,
      warning,
      light,
      main,
      primary,
      fs_md,
      fs_sm,
      dark,
      defaultRGB,
      lightest,
    } = theme;

    let background = props.disabled ?
      lightest :
      main;

    let borderColor;
    if (props.error) {
      borderColor = warning;
    } else {
      borderColor = light;
    }

    let padding;
    let paddingIcon;
    let fontSize;
    let width;
    switch (props.scale) {
      case 'sm':
        padding = '6px';
        paddingIcon = '30px';
        fontSize = fs_sm;
        width = '60px';
        break;
      case 'md':
        padding = '10px';
        paddingIcon = '40px';
        fontSize = fs_md;
        width = '100%';
        break;
      case 'lg':
        padding = '15px';
        paddingIcon = '50px';
        fontSize = fs_md;
        width = '100%';
        break;
      default:
        padding = '10px';
        paddingIcon = '40px';
        fontSize = fs_md;
        width = '100%';
    }

    let display = props.compact ?
      'flex' :
      'block';

    return `

    display: ${display};
    width: 100%;
    position: relative;
    
    input{
      
      ${global}
      border: none;
      background: ${background};
      border: 1px solid ${borderColor};
      color: ${primary};
      
      padding: ${padding} ${padding} ${padding} ${padding};
      font-size: ${fontSize};
      width: ${width};
      
      font-weight: 300;

      &:focus{
        outline: none;
        border: 1px solid rgba(${defaultRGB},0.5);
      }
      
      &:active{
        border: 1px solid rgba(${defaultRGB},0.5);
      }
      
      &.icon{
        padding: ${padding} ${padding} ${padding} ${paddingIcon};
      }

      ::-webkit-input-placeholder { 
        color: ${dark};
      }
      ::-moz-placeholder { 
        color: ${dark};
      }
      :-ms-input-placeholder { 
        color: ${dark};
      }
      :-moz-placeholder { 
        color: ${dark};
      }
    }

  `;
  }}

  ${CompactLabel}{
    ${({ theme, ...props }) => {
      let {
        fs_md,
        fs_sm,
      } = theme;
    let paddingHorizontal;
    let paddingVertical;
    let fontSize;
    switch (props.scale) {
      case 'sm':
        paddingHorizontal = '6px';
        paddingVertical = '11px';
        fontSize = fs_sm;
        break;
      case 'md':
        paddingHorizontal = '10px';
        paddingVertical = '22px';
        fontSize = fs_md;
        break;
      case 'lg':
        paddingHorizontal = '15px';
        paddingVertical = '33px';
        fontSize = fs_md;
        break;
      default:
        paddingHorizontal = '10px';
        paddingVertical = '22px';
        fontSize = fs_md;
    }
    return `
      padding: ${paddingHorizontal} ${paddingVertical};
      font-size: ${fontSize};
    `;
  }}
  }
  
  ${Error}{
    ${({ theme, ...props }) => {

    let errorPosition = props.compact ?
      'absolute' :
      'static';

    return `
        position: ${errorPosition};
        top: 100%;
      `;
  }}
  } 
`;

Field.propTypes = {
  className: PropTypes.string,
  label: PropTypes.string,
  error: PropTypes.string,
  placeholder: PropTypes.string,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  required: PropTypes.bool,
  icon: PropTypes.func,
  compact: PropTypes.bool,
  prohibitKeys: PropTypes.array,
  scale: PropTypes.string,
  name: PropTypes.string,
};

export default Field;
import * as React from "react";
import { Action, ActionCreatorsMapObject, bindActionCreators } from "redux";
import styled from "styled-components";
import { connect } from "react-redux";
import moment, { Moment } from "moment";
import { withRouter } from "react-router";
import { createStructuredSelector } from "reselect";
import {
  saveFilterSettings,
  loadFilterSettings
} from "../../_utils/userSession";

import withGranted, { IWithGrantedProps } from "../HOC/WithGranted";
import { ClientInvoiceListDto } from "../../service-proxies";
import { IGlobalStore } from "../../_reducers/reducers";
import { IShortLocationInfo } from "../../_types/common";
import * as actionsSearch from "../../_actions/search";
import * as layoutActions from "../../_actions/layout";
import * as invoicesApi from "../../_api/invoices";
import { notifySwagger, notify } from "../../_actions/notification";
import {
  CLIENTINVOICES_SEND,
  CLIENTINVOICES_EXPORT_EXCEL
} from "../../_constants/permissions";

import {
  DatePicker,
  Table,
  Link,
  LocationSelectorComponent,
  Checkbox,
  Pagination,
  MainContainer,
  RunningLineLoader,
  Skeleton,
  Button,
  Article
} from "../../components";
import { IFlatLocationOption } from "../../_reducers/current-user";
import { formatPrice } from "../../_utils/prices";
import { OkIcon } from "../../assets/icons/index";

interface IMultiselectCheckbox {
  id: number;
  onChange: (id: number, value: boolean) => void;
  value: boolean;
}

const MultiselectCheckbox: React.SFC<IMultiselectCheckbox> = ({
  onChange,
  id,
  ...props
}) => {
  function handleChange() {
    onChange(id, props.value);
  }

  return <Checkbox onChange={handleChange} {...props} />;
};

const Position = {
  Container: styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
  `,

  Body: styled.div`
    height: 100%;
    flex: 1 1 100%;
  `,

  Footer: styled.div`
    flex: 0 0;
  `,

  Head: styled<{ className?: string }>(({ className, children }) => (
    <header className={className}>
      <div>{children}</div>
    </header>
  ))`
    padding: 20px;
    > div {
      margin: -7.5px;
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
    }
  `,

  HeadElement: styled.div`
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    & > * {
      margin: 7.5px;
    }
  `,

  Filter: styled.div`
    width: 260px;
  `,

  Toolbar: styled.div`
    display: flex;
    align-items: center;
    height: 100%;
    width: 100%;
    justify-content: flex-end;
    > * {
      margin-left: 10px;
    }
  `
};

interface IGetClientInvoicesParams {
  filter?: string;
  startDate?: moment.Moment;
  dueDate?: moment.Moment;
  sorting?: string;
  skip?: number;
  perPage?: number;
}

interface IClientInvoicesListProps
  extends IWithGrantedProps,
    IConnectedProps,
    IConnectedActions {}

interface IInvoicesListState {
  sorting: string;
  skip: number;
  filter?: string;
  clientInvoicesStatus: string;
  selectedClientInvoicesStatus: string;
  clientInvoices?: ClientInvoiceListDto[];
  totalCount: number;
  divisionLocationFilter: number | null;
  selectedMap: {
    [id: number]: boolean;
  };
  startDate: Moment | null;
  dueDate: Moment | null;
  focusedInput: string;
  isExporting: boolean;
}

class ClientInvoicesList extends React.Component<
  IClientInvoicesListProps,
  IInvoicesListState
> {
  isSelectionAllowed: boolean;

  static renderPreloader(perPage: number, status: string): React.Component[] {
    const rows = [];
    const elementsCount = status === "loading" ? perPage : 0;

    for (let i = 0; i < elementsCount; i++) {
      rows.push(
        <Table.Row
          key={i}
          rows={perPage}
          cells={[
            <Skeleton.Line key={`order-list-preloader-${i}`} size="xs" />,
            <Skeleton.Line key={`order-list-preloader-${i}`} size="sm" />,
            <Skeleton.Line key={`order-list-preloader-${i}`} size="sm" />,
            <Skeleton.Line key={`order-list-preloader-${i}`} size="sm" />,
            <Skeleton.Line key={`order-list-preloader-${i}`} size="sm" />,
            <Skeleton.Line key={`order-list-preloader-${i}`} size="sm" />,
            <Skeleton.Line key={`order-list-preloader-${i}`} size="sm" />
          ]}
        />
      );
    }
    return rows;
  }

  constructor(props) {
    super(props);

    const filterSettings = loadFilterSettings("clientinvoice");

    this.state = {
      sorting: "number ASC",
      skip: 0,
      clientInvoicesStatus: "",
      selectedClientInvoicesStatus: "",
      totalCount: 0,
      divisionLocationFilter: filterSettings.location || props.locationInfo.id,
      selectedMap: {},
      startDate:
        filterSettings.startDate != null
          ? moment(filterSettings.startDate)
          : null,
      dueDate:
        filterSettings.dueDate != null ? moment(filterSettings.dueDate) : null,
      focusedInput: null,
      isExporting: false
    };

    this.isSelectionAllowed = props.isSomeGranted([
      CLIENTINVOICES_SEND,
      CLIENTINVOICES_EXPORT_EXCEL
    ]);

    this.handleChangePage = this.handleChangePage.bind(this);
    this.handleSortChanged = this.handleSortChanged.bind(this);
    this.getClientInvoices = this.getClientInvoices.bind(this);
    this.handleFilterChange = this.handleFilterChange.bind(this);
    this.handleSelectClientInvoce = this.handleSelectClientInvoce.bind(this);
    this.handleExportClick = this.handleExportClick.bind(this);
    this.handleDateChanged = this.handleDateChanged.bind(this);
    this.handleSendClick = this.handleSendClick.bind(this);
    this.handleIndividualExport = this.handleIndividualExport.bind(this);

    this.props.setSearch("/client-invoices", filter => {
      this.setState({ filter, skip: 0 }, () => {
        this.getClientInvoices({ filter });
      });
    });
  }

  componentDidMount() {
    this.getClientInvoices();
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.locationInfo.id !== this.props.locationInfo.id) {
      this.setState(
        { divisionLocationFilter: nextProps.locationInfo.id },
        () => {
          this.getClientInvoices();
        }
      );
    }
  }

  getClientInvoices(params: IGetClientInvoicesParams = {}) {
    const skip =
      Number.isInteger(params.skip) && params >= 0
        ? params.skip
        : this.state.skip;
    const sort = params.sorting || this.state.sorting;
    const filter = params.filter || this.state.filter;
    const startDate = params.startDate || this.state.startDate;
    const dueDate = params.dueDate || this.state.dueDate;
    const perPage = params.perPage || this.props.perPage;

    let statusStartKey = "loading";
    let statusFinnishKey = "loaded";
    if (params.sorting) {
      statusStartKey = "sorting";
      statusFinnishKey = "sorted";
    }

    this.setState({ clientInvoicesStatus: statusStartKey });

    invoicesApi
      .getClientInvoices(
        this.state.divisionLocationFilter || this.props.locationInfo.id,
        filter,
        startDate,
        dueDate,
        sort,
        skip,
        perPage
      )
      .then(clientInvoicesData => {
        this.setState({
          clientInvoicesStatus: statusFinnishKey,
          clientInvoices: clientInvoicesData.items,
          totalCount: clientInvoicesData.totalCount
        });
      })
      .catch(e => {
        this.props.notifySwagger(e, "error");
      });
  }

  handleChangePage(pageInfo) {
    const { perPage, skip } = pageInfo;

    this.setState({ skip, selectedMap: {} }, () => {
      this.getClientInvoices({ perPage, skip });
      this.props.setPerPage(perPage);
    });
  }

  handleSortChanged(sorting) {
    this.setState({ sorting });
    this.getClientInvoices({ sorting });
  }

  handleExportClick() {
    const stateMap = this.state.selectedMap;

    Object.keys(stateMap).forEach((key) => {
      if (stateMap[key]) {
        this.setState({ isExporting: true });
        invoicesApi
          .downloadClientInvoice(parseInt(key, 10))
          .then(status => {
            this.setState({ isExporting: false });
          })
          .catch(err => {
            this.setState({ isExporting: false });
            if (typeof err === "string") {
              this.props.notify(err, "error");
            }
          });
      }
    });

    this.setState({ selectedMap: {} });
  }
  handleIndividualExport(id) {
    this.setState({ isExporting: true });
    invoicesApi
      .downloadClientInvoice(id)
      .then(status => {
        this.setState({ isExporting: false });
      })
      .catch(err => {
        this.setState({ isExporting: false });
        if (typeof err === "string") {
          this.props.notify(err, "error");
        }
      });
  }

  handleFilterChange(value: IFlatLocationOption) {
    this.setState({ divisionLocationFilter: value.id, skip: 0 }, () => {
      this.setInvoiceFilter();
      this.getClientInvoices();
    });
  }

  handleSelectClientInvoce(value) {
    const temp = this.state.selectedMap;
    if (temp[value]) {
      temp[value] = !temp[value];
    } else {
      temp[value] = true;
    }

    this.setState({ selectedMap: temp });
  }

  handleDateChanged(range: { startDate: Moment; endDate: Moment }) {
    const startDate = range.startDate
      ? range.startDate.startOf("day")
      : undefined;
    const dueDate = range.endDate ? range.endDate.startOf("day") : undefined;

    this.setState({ startDate, dueDate, skip: 0 }, () => {
      this.setInvoiceFilter();
      this.getClientInvoices();
    });
  }

  handleSendClick() {
    const selectedIds = Object.keys(this.state.selectedMap);
    this.setState({ selectedClientInvoicesStatus: "sending" });
    invoicesApi
      .sendInvoices(selectedIds)
      .then(() => {
        this.setState({
          selectedClientInvoicesStatus: "",
          selectedMap: {}
        });
        this.getClientInvoices();
      })
      .catch(e => {
        this.setState({ selectedClientInvoicesStatus: "" });
        this.props.notifySwagger(e, "error");
      });
  }

  setInvoiceFilter() {
    const filter = {
      location: this.state.divisionLocationFilter,
      startDate: this.state.startDate,
      dueDate: this.state.dueDate
    };

    saveFilterSettings("clientinvoice", filter);
  }

  render() {
    const isInvoicesSelected = Object.keys(this.state.selectedMap).length === 0;
    return (
      <MainContainer>
        <Position.Container>
          <Position.Head>
            <Position.HeadElement>
              <Article.Text>
                <strong>CLIENT INVOICES</strong>
              </Article.Text>
            </Position.HeadElement>
            <Position.HeadElement>
              <Position.Filter>
                <LocationSelectorComponent
                  customFilter={filterOptions}
                  locationOptions={this.props.locationOptions}
                  onLocationChange={this.handleFilterChange}
                  selectedLocation={this.state.divisionLocationFilter}
                />
              </Position.Filter>
              <DatePicker
                compact
                type="range"
                startDateId="startDate"
                endDateId="endDate"
                isOutsideRange={() => false}
                startDate={this.state.startDate}
                endDate={this.state.dueDate}
                onDatesChange={this.handleDateChanged}
                focusedInput={this.state.focusedInput}
                keepOpenOnDateSelect
                showPeriods
                onFocusChange={focusedInput => {
                  this.setState({ focusedInput });
                }}
              />
              {this.props.isGranted(CLIENTINVOICES_EXPORT_EXCEL) && (
                <Button
                  onClick={this.handleExportClick}
                  primary
                  disabled={isInvoicesSelected}
                  preloader={this.state.isExporting}
                >
                  Export
                </Button>
              )}
              {/* {
                this.props.isGranted(CLIENTINVOICES_SEND) &&
                <Button
                  onClick={this.handleSendClick}
                  disabled={isInvoicesSelected}
                  preloader={this.state.selectedClientInvoicesStatus === 'sending'}
                >
                  Send Invoices
                </Button>
              } */}
            </Position.HeadElement>
          </Position.Head>
          <Position.Body>
            <RunningLineLoader
              isActive={
                this.state.clientInvoicesStatus === "sorting" ||
                this.state.clientInvoicesStatus === "loading"
              }
            />
            <Table
              fullHeight
              columns={(this.isSelectionAllowed
                ? [{ name: "", label: "", size: "sm" } as any]
                : []
              ).concat([
                { name: "creationTime", label: "date", sortable: true },
                { name: "number", label: "number", sortable: true },
                { name: "divisionName", label: "division", sortable: true },
                { name: "locationName", label: "location", sortable: true },
                {
                  name: "totalAmount",
                  label: "total",
                  sortable: true,
                  alignment: "right"
                },
                { name: "hasBeenPaid", label: "", sortable: true },
                { name: "toolbar", label: "", size: "lg" }
              ])}
            >
              <Table.Header
                sortBy={this.state.sorting}
                onSortChanged={this.handleSortChanged}
              />
              <Table.Body>
                {(this.state.clientInvoicesStatus === "loaded" ||
                  this.state.clientInvoicesStatus === "sorting" ||
                  this.state.clientInvoicesStatus === "sorted") &&
                  (this.state.clientInvoices &&
                  !!this.state.clientInvoices.length ? (
                    this.state.clientInvoices.map((item, index) => (
                      <Table.Row
                        key={`${index}-${item.id}`}
                        disabled={item.hasBeenSent}
                        cells={(this.isSelectionAllowed
                          ? [
                              (
                                <MultiselectCheckbox
                                  id={item.id}
                                  onChange={this.handleSelectClientInvoce}
                                  value={!!this.state.selectedMap[item.id]}
                                />
                              ) as any
                            ]
                          : []
                        ).concat([
                          moment(item.creationTime).format("MM/DD/YYYY"),
                          item.number,
                          item.divisionName,
                          item.locationName,
                          formatPrice(item.totalAmount),
                          item.hasBeenPaid ? <OkIcon /> : null,
                          <Position.Toolbar
                            key={`cell-toolbar-${index}-${item.id}`}
                          >
                            <Link to={`/client-invoices/${item.id}`}>View</Link>
                            <Link.Button
                              onClick={() =>
                                this.handleIndividualExport(item.id)
                              }
                              id={String(item.id)}
                            >
                              Export
                            </Link.Button>
                          </Position.Toolbar>
                        ])}
                      />
                    ))
                  ) : (
                    <Table.Row
                      key={0}
                      colSpan="7"
                      rows={this.props.perPage}
                      cells={[<span key="0">NO INVOICES FOUND</span>]}
                    />
                  ))}
                {this.state.clientInvoicesStatus === "loading" &&
                  ClientInvoicesList.renderPreloader(
                    this.props.perPage,
                    this.state.clientInvoicesStatus
                  )}
              </Table.Body>
            </Table>
          </Position.Body>
          <Pagination
            skip={this.state.skip}
            perPage={this.props.perPage}
            count={this.state.totalCount}
            onChange={this.handleChangePage}
          />
        </Position.Container>
      </MainContainer>
    );
  }
}

interface IConnectedProps {
  locationInfo?: IShortLocationInfo;
  locationOptions: IFlatLocationOption[];
  perPage: number;
}

interface IConnectedActions {
  setSearch: typeof actionsSearch.set;
  setPerPage: typeof layoutActions.setPerPage;
  notifySwagger: typeof notifySwagger;
  notify: typeof notify;
}

export default withRouter(
  connect(
    createStructuredSelector<IGlobalStore, IConnectedProps>({
      perPage: state => state.layout.perPage,
      locationInfo: state => state.currentUser.locationInfo,
      locationOptions: state => state.currentUser.locationOptions
    }),
    dispatch =>
      bindActionCreators<IConnectedActions & ActionCreatorsMapObject, ActionCreatorsMapObject<Action>>(
        {
          setSearch: actionsSearch.set,
          setPerPage: layoutActions.setPerPage,
          notifySwagger,
          notify
        },
        dispatch
      )
  )(withGranted(ClientInvoicesList))
);

/**
 * Custom filter for LocationSelector
 */
function filterOptions(options: IFlatLocationOption[], inputValue: string) {
  const subOptions: IFlatLocationOption[] = options.map(item => ({
    ...item,
    disabled: item.level === 0
  }));

  if (!inputValue || !inputValue.trim()) {
    return subOptions;
  }

  const parentLevels: number[] = [null, null];
  const optionsWithScore: IFlatLocationOption[] = [];

  subOptions.forEach((o, index: number) => {
    if (o.level < 2) {
      parentLevels[o.level] = index;
      if (o.level === 0) {
        return;
      }
    }
    const matchIndex = o.name.toLowerCase().search(inputValue.toLowerCase());
    if (matchIndex !== -1) {
      optionsWithScore.push({
        ...o,
        searchScore: 200 - matchIndex,
        parentIndexes: parentLevels.slice(0, o.level)
      });
    }
  });

  const includeMap = {};

  optionsWithScore.forEach(option => {
    includeMap[option.id] = true;
    option.parentIndexes.forEach(index => {
      includeMap[subOptions[index].id] = true;
    });
  });

  const searchResults: IFlatLocationOption[] = subOptions.filter(
    option => includeMap[option.id]
  );

  return searchResults;
}

import {
  catalogServiceProxy,
  categoryServiceProxy,
  productServiceProxy
} from "../servicePool";
import {
  AddProductsOutput,
  CategoryProductDto,
  CategoryProductsDto,
  EditCatalogDto,
  EntityDtoOfInt64,
  GetСategoriesDto
} from "../service-proxies";
import { sortNameItems } from "../_utils/clientCatalogEditor";
import { IObjectWithName } from "../_types/clientCatalogEditor";

export function getCatalogForEdit(catalogId: number): Promise<EditCatalogDto> {
  return catalogServiceProxy.getCatalogForEdit(catalogId);
}

export function createClientCatalog(
  divisionId: number,
  name: string
): Promise<EntityDtoOfInt64> {
  return catalogServiceProxy.createCatalog({ name, divisionId });
}

export function getClientCatalog(
  catalogId: number
): Promise<GetСategoriesDto[]> {
  return categoryServiceProxy
    .getCategoriesForClientCatalog(catalogId)
    .then(res => {
      return sortCatalogItemsRecursive(res.items);
    });
}

export function getMasterCatalog(): Promise<GetСategoriesDto[]> {
  return categoryServiceProxy.getCategoriesForMasterCatalog().then(res => {
    return sortCatalogItemsRecursive(res.items);
  });
}

export function getProductsByCategory(
  categoryId: number
): Promise<CategoryProductDto[]> {
  return productServiceProxy
    .getCategoryProducts(categoryId)
    .then(res => res.items);
}

export function createCategoryInClientCatalog(
  parentCategoryId: number,
  categoryName: string,
  catalogId: number,
  sortingPriority: number = 0
): Promise<EntityDtoOfInt64> {
  return categoryServiceProxy.createCategoryForClientCatalog({
    parentCategoryId,
    name: categoryName,
    sortingPriority,
    catalogId
  });
}

export function addProductsIntoCategory(
  categoryId: number,
  catalogId: number,
  productIds: number[]
): Promise<AddProductsOutput> {
  return catalogServiceProxy.addProductsToCategory({
    categoryId,
    catalogId,
    productIds
  });
}

export function removeProductsFromCategory(
  categoryId: number,
  catalogId: number,
  productIds: number[]
): Promise<void> {
  return catalogServiceProxy.removeProductsFromCategory(
    categoryId,
    catalogId,
    productIds
  );
}

export function removeClientCategory(
  categoryId: number,
  catalogId: number
): Promise<void> {
  return categoryServiceProxy.deleteCategoryFromClientCatalog(
    catalogId,
    categoryId
  );
}

export function renameClientCategory(
  categoryId: number,
  categoryName: string,
  sortId: number = 0
): Promise<EntityDtoOfInt64> {
  return categoryServiceProxy.updateCategoryForClientCatalog({
    id: categoryId,
    name: categoryName,
    sortingPriority: sortId
  });
}

export interface IAddTreeToClientResultItem {
  id: number | string;
  name: string;
  sku: string;
  childCategories?: IAddTreeToClientResultItem[];
}

export async function addSelectedTreeToClientCatalog(
  clientCategoryId: number,
  catalogId: number,
  tree,
  movedProducts: CategoryProductsDto[]
): Promise<IAddTreeToClientResultItem[]> {
  const resTree: IAddTreeToClientResultItem[] = [];
  const productIds = [];
  const productNodes = [];

  for (const node of tree) {
    if (node.id.toString().startsWith("product")) {
      // product
      const productId = node.id.split("-")[1];
      productIds.push(parseInt(productId, 10));
      productNodes.push(Object.assign({}, node));
    } else {
      // category
      const { id } = await createCategoryInClientCatalog(
        clientCategoryId,
        node.name,
        catalogId
      );
      let childCategories = [];
      if (node.children && node.children.length) {
        childCategories = await addSelectedTreeToClientCatalog(
          id,
          catalogId,
          node.children,
          movedProducts
        );
      }
      resTree.push({ id, name: node.name, sku: node.sku, childCategories });
    }
  }

  if (productIds.length) {
    const removedItems = await addProductsIntoCategory(
      clientCategoryId,
      catalogId,
      productIds
    );
    if (removedItems && removedItems.removedProducts && removedItems.removedProducts.length) {
      removedItems.removedProducts.forEach(item => {
        movedProducts.push(item);
      });
    }
    productNodes.forEach(node => {
      const productId = node.id.split("-")[1];
      resTree.push({
        id: `product-${productId}-${clientCategoryId}`,
        name: node.name,
        sku: node.sku
      });
    });
  }

  return resTree;
}

export async function removeSelectedTreeFromClientCatalog(
  nodes: Array<{ categoryId: number; productIds: number[]; id: number }>,
  catalogId: number
): Promise<void> {
  for (const node of nodes) {
    if (node.productIds) {
      // product
      await removeProductsFromCategory(
        node.categoryId,
        catalogId,
        node.productIds
      );
    } else {
      // category
      await removeClientCategory(node.id, catalogId);
    }
  }
}

function sortCatalogItemsRecursive(
  items: GetСategoriesDto[]
): GetСategoriesDto[] {
  items.forEach(item => {
    if (item.childCategories && item.childCategories.length) {
      item.childCategories = sortCatalogItemsRecursive(item.childCategories);
    }
  });

  return sortNameItems(items as IObjectWithName[]) as GetСategoriesDto[];
}

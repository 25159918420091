import * as React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const Style = styled.div`
${({ theme }) => {
    const { primary, warning } = theme;
    return `
    margin-left: 20px;
    h1 {
      color: ${warning};
      font-size: 30px;
    }
    p {
      font-size: 18px;
      color: ${primary};
    }
  `;
  }}
`;

interface INotFoundProps {
  title?: string;
  message?: string;
}

const NotFound: React.SFC<INotFoundProps> = ({title, message}) => (
  <Style>
    <h1>{title}</h1>
    <p>{message}</p>
  </Style>
);

NotFound.propTypes = {
  title: PropTypes.string,
  message: PropTypes.string,
};

NotFound.defaultProps = {
  title: '404',
  message: 'Not found!',
};

export default NotFound;

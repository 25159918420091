import { clientServiceProxy } from '../servicePool';

import {
  ListResultDtoOfSearchOutputDto,
  PagedResultDtoOfClientListDto,
  EditClientDto,
  CreateClientDto,
  EntityDtoOfInt64,
  UpdateClientDto,
  SearchOutputDto,
} from '../service-proxies';

export function searchClients(text: string = ''): Promise<ListResultDtoOfSearchOutputDto> {
  return clientServiceProxy.searchClients(10, text);
}

export function getClients(maxResultCount: number, skipCount: number, filter: string): Promise<PagedResultDtoOfClientListDto> {
  return clientServiceProxy.getClients(maxResultCount, skipCount, filter);
}

export function removeClient(id: number): Promise<void> {
  return clientServiceProxy.deleteClient(id);
}

export function getClientForEdit(id: number): Promise<EditClientDto> {
  return clientServiceProxy.getClientForEdit(id);
}

export function createClient(input: CreateClientDto): Promise<EntityDtoOfInt64> {
  return clientServiceProxy.createClient(input);
}

export function updateClient(input: UpdateClientDto): Promise<EntityDtoOfInt64> {
  return clientServiceProxy.updateClient(input);
}

export function getClientName(id: number): Promise<SearchOutputDto> {
  return clientServiceProxy.searchClientById(id);
}
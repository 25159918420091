import * as React from 'react';
import { RouteComponentProps, withRouter } from 'react-router';

import RoutesMapper from '../../Router/RoutesMapper';
import OtherPackingSlipReconciliationList from './List';
import { IRouteEntry } from '../../../_types/common';
import {
  PACKINGSLIPS,
} from '../../../_constants/permissions';


const routes: IRouteEntry[] = [
  {
    path: '/',
    permission: PACKINGSLIPS,
    component: OtherPackingSlipReconciliationList,
  },
];

class OrderRoutes extends React.Component<RouteComponentProps<{}>> {
  constructor(props) {
    super(props);
  }
  render() {
    return <React.Fragment>
      <RoutesMapper routes={routes} addNotFound {...this.props} />
    </React.Fragment>;
  }
}

export default withRouter(OrderRoutes);
import React from 'react';
import styled from 'styled-components';

const PlusIcon = props => {
  return(
    <i {...props}>
      <svg viewBox="-520 4393 10 10" width="10" height="10">
        <path d=" M -516 4393 L -516 4397 L -520 4397 L -520 4399 L -516 4399 L -516 4403 L -514 4403 L -514 4399 L -510 4399 L -510 4397 L -514 4397 L -514 4393 L -516 4393 Z " />
      </svg>
    </i>
  );
};

export default styled(PlusIcon)`
  ${ props => `
    width: 21px;
    height: 21px;
    display: inline-flex;
    justify-content: center;
    align-items: center;

    svg{
      path{
        fill: ${props.theme.primary}; 
      }
    }
  `}
`;

import { types } from '../_actionTypes/clients';
import { EditClientDto } from '../service-proxies';

// @TODO - check and remove

export interface IClientStore {
  clientUnits: any[];
  clients: any[];
  totalCount: number | null;
  listStatus: string;
  client: EditClientDto;
  clientName: string;
  status: string;
  clientUnitsStatus: string;
  perPage: number;
  skip: number;
}

const initialState: IClientStore = {
  clientUnits: [],
  clients: [],
  totalCount: null,
  listStatus: '',
  client: null,
  clientName: '',
  status: '',
  clientUnitsStatus: '',
  perPage: 10,
  skip: 0,
};

export default (state: IClientStore = initialState, action) => {
  const {
    clientUnits,
    clients,
    listStatus,
    client,
    clientName,
    status,
    clientUnitsStatus,
    totalCount,
    perPage,
    skip,
  } = action;

  switch (action.type) {
    case types.CLIENT_GET_ALL:
      return ({
        ...state,
        clients,
        totalCount,
        listStatus,
        status,
      });
    case types.CLIENT_CREATE:
      return ({
        ...state,
        status,
      });
    case types.CLIENT_UPDATE:
      return ({
        ...state,
        status,
      });
    case types.CLIENT_GET_NAME:
      return ({
        ...state,
        clientName,
      });
    case types.CLIENT_GET:
      return ({
        ...state,
        client,
        status,
      });
    case types.CLIENT_REMOVE:
      return ({
        ...state,
        status,
      });
    case types.CLIENT_CHANGE_PAGE:
      return ({
        ...state,
        perPage,
        skip,
      });
    case types.CLIENT_GET_CLIENTS_TREE:
      return ({
        ...state,
        clientUnits,
        clientUnitsStatus,
      });
    default:
      return state;
  }
};

import * as React from 'react';
import styled from 'styled-components';
import RadioButton, {IRadioProps} from './RadioButton';
import { Line as Skeleton } from './Skeleton';

const RadioGroupInnerWrapper = styled<{isInvalid: boolean}, 'div'>('div')`
  ${({theme}) => theme.global}
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  justify-items: center;
  & > label {
    font-size: 14px;
    margin-right: 10px;
    align-self: center;
    color: ${({isInvalid, theme}) => isInvalid ? theme.default : theme.primary};
  }
  ${RadioButton}:not(:last-child) {
    margin-right: 10px;
  }
`;

const RadioGroupOuterWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const ErrorMessage = styled.div`
  ${({theme}) => theme.global}
  color: ${({theme}) => theme.default};
  font-size: 12px;
  border-top: 1px solid ${({theme}) => theme.default};
  margin-top: 4px;
`;

interface IRadioGroupProps {
  label?: string;
  children: Array<React.ReactElement<IRadioProps>> | React.ReactElement<IRadioProps>;
  className?: string;
  errors?: string | false;
  required?: boolean;
  isLoading?: boolean;
}

const RadioGroup = (props: IRadioGroupProps) => {
  return (
    <RadioGroupOuterWrapper>
      <RadioGroupInnerWrapper isInvalid={!!props.errors}>
        {props.label && <label>{props.label + (props.required ? ' *': '')}</label>}
        {props.isLoading ?  <Skeleton /> : props.children}
      </RadioGroupInnerWrapper>
      {props.errors && props.errors.length && <ErrorMessage>{props.errors}</ErrorMessage>}
    </RadioGroupOuterWrapper>
  );
};

export default RadioGroup;

import * as React from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router';
import { Formik } from 'formik';

import {
    Article,
    EditableBlock,
    Error,
    Pagination,
    Separator,
    Skeleton,
    Table,
    Toggle,
    PriceField,
    // GLCodeField,
    // NeedsApprovalField
} from '../../components';

import * as actionsSearch from '../../_actions/search';
import * as apiCatalog from '../../_api/catalog';
import * as apiProducts from '../../_api/products';
import { IMatch } from '../../_types/common';
import {formatPrice} from '../../_utils/prices';

const Position = {
  Table: styled.div`
    flex: 1 1 100%;
  
    ${EditableBlock}{
      margin: -10px 0;
    }
  `,

  Head: styled<{ className?: string }>(({ className, children }) => <header className={className}>
    <div>
      {children}
    </div>
  </header>)`
    padding: 10px 20px;
    >div{
      margin: -7.5px;
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
    }
  `,

  HeadElement: styled.div`
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    & > * {
      margin: 7.5px;
    }
  `,

  Errors: styled.div`
    padding: 0 20px;
  `,

  PriceHeader: styled.span`
    padding-right: 54px;
  `,
};

interface IFormPriceProps extends Formik, IMatch {
  values: any;
  errors: any;
  onError: (error: any) => void;
  isNew: boolean;
  getProductCatalogsPrices: (perPage: number, skip: number, sortBy: string, filter: string, initial: boolean) => void;
}

class FormPrice extends React.Component<IFormPriceProps> {

  public searchText;


  constructor(props) {
    super(props);

    this.handlePriceTypeToggle = this.handlePriceTypeToggle.bind(this);
    this.handlePageChange = this.handlePageChange.bind(this);
    this.getProductCatalogsPrices = this.getProductCatalogsPrices.bind(this);
    this.handlePriceChange = this.handlePriceChange.bind(this);
    this.handleCodeChange = this.handleCodeChange.bind(this);
    this.handleSort = this.handleSort.bind(this);
    this.handleApprovalChange = this.handleApprovalChange.bind(this);

    this.searchText = '';

  }

  componentDidMount() {
    if (
      (this.props.values.catalogsStatus !== 'loaded') &&
      (this.props.values.catalogsStatus !== 'loading')
    ) {
      this.getProductCatalogsPrices(
        this.props.values.perPage,
        this.props.values.skip,
        this.props.values.sortBy,
        this.searchText,
        this.props.isNew,
      );
    }
  }

  async getProductCatalogsPrices(perPage, skip, sortBy, filter = '', initialCondition = false) {
    this.setState({
      catalogsStatus: 'loading',
    });

    this.props.setFieldValue('catalogsStatus', 'loading');

    apiCatalog.getCatalogsByProductId(
      this.props.values.productId,
      perPage,
      skip,
      filter,
      sortBy,
    )
      .then(catalogs => {

        if (initialCondition) {
          this.props.setValues(
            {
              ...this.props.values,
              catalogs,
              catalogsStatus: 'loaded',
              perPage,
              skip,
              sortBy,
            }
          );
        } else {
          apiProducts.getCatalogsProductPrices(this.props.values.productId)
            .then(prices => {
              const pricesCache = {};
              const glCodesCache = {};
              const approvalsCache = {};
              const isPricesExists = !!prices.items.length;

              if (isPricesExists) {
                prices.items.forEach(priceData => {
                  if (priceData.catalogId) {
                    pricesCache[priceData.catalogId] = priceData.price;
                    glCodesCache[priceData.catalogId] = priceData.glCode;
                    approvalsCache[priceData.catalogId] = priceData.needsApproval;
                  }
                });
              }

              const isStatePriceCache = Object.keys(this.props.values.pricesCache).length;

              this.props.setValues(
                {
                  ...this.props.values,
                  catalogs,
                  catalogsStatus: 'loaded',
                  pricesCache: isStatePriceCache ?
                    this.props.values.pricesCache :
                    pricesCache,
                  glCodesCache: isStatePriceCache?
                      this.props.values.glCodesCache :
                      glCodesCache,
                  approvalsCache: isStatePriceCache?
                        this.props.values.approvalsCache:
                        approvalsCache,
                  perPage,
                  skip,
                  sortBy,
                }
              );

            })
            .catch(error => {
              this.props.onError(error);
            });
        }

      })
      .catch(error => {
        this.props.onError(error);
        this.props.setValues(
          {
            ...this.props.values,
            catalogsStatus: 'error',
          }
        );
      });

  }

  // price
  handlePriceTypeToggle() {
    this.props.setFieldValue('isPathThrough', !this.props.values.isPathThrough);
  }

  handlePriceChange(catalogId, newPrice) {
    const newPricesCache = { ...this.props.values.pricesCache };
    newPricesCache[catalogId] = newPrice;
    this.props.setFieldValue('pricesCache', newPricesCache);
    this.props.setFieldValue('isPriceChanged', true);
  }

  handleCodeChange(catalogId, newCode){
      const newGlCodesCache = {...this.props.values.glCodesCache};
      newGlCodesCache[catalogId] = newCode;
      this.props.setFieldValue('glCodesCache', newGlCodesCache);
      this.props.setFieldValue('isPriceChanged', true);
  }

  handleApprovalChange(catalogId, newApproval){
      const newApprovalsCache = {...this.props.values.approvalsCache}
      newApprovalsCache[catalogId] = newApproval;
      this.props.setFieldValue('approvalsCache', newApprovalsCache);
      this.props.setFieldValue('isPriceChanged', true);
  }


  // pagination
  handlePageChange(pageInfo) {
    const {
      perPage,
      skip,
    } = pageInfo;

    // if (this.props.perPage !== perPage)
    //   this.props.perPageChange(perPage);
    this.getProductCatalogsPrices(perPage, skip, this.props.values.sortBy, this.searchText);

  }



  displayApproval(type: number) {
      switch (type){
          case 0:
              return 'None'
          case 1:
              return 'For Cost'
          case 2:
              return 'For Attribute'
          case 3:
              return 'For Both'
          default:
              return 'Not Selected'
      }
  }

  // sorting
  handleSort(sortValue) {
    this.props.setFieldValue('sortBy', sortValue);
    this.getProductCatalogsPrices(this.props.values.perPage, this.props.values.skip, sortValue, this.searchText);
  }

  renderPreloaderRows(perPage, status) {
    const rows = [];
    const elementsCount = (status === 'loading') ? perPage - 1 : 0;

    for (let i = 0; i < elementsCount; i++) {
      rows.push(<Table.Row
        key={'preloader' + i}
        rows={perPage}
        cells={[
          <Skeleton.Line key={`preloader-client`} />,
          <Skeleton.Line key={`preloader-division`} />,
          <Skeleton.Line key={`price-preloader-${i}`} />,
        ]}
      />);
    }
    return rows;
  }

  render() {
    const {
      values,
      errors,
    } = this.props;

      const approvals = [0,1,2,3];

      // const [approvalState, setApprovalState] = useState('');

      const onChange = (id, value) => {
          this.handleApprovalChange(id, value)
      }

    return (
      <React.Fragment>
        {
          !this.props.isNew &&
          <Position.Head>
            <Position.HeadElement>
              <Toggle
                label="Pass through"
                onChange={this.handlePriceTypeToggle}
                value={values.isPathThrough}
                disabled={false}
              />
            </Position.HeadElement>
            <Position.HeadElement>
              {
                values.vendorPrice &&
                <Article.P>
                  {`Vendor Price: ${formatPrice(values.vendorPrice)}`}
                </Article.P>
              }
            </Position.HeadElement>
          </Position.Head>
        }
        <Position.Table>
          <Table columns={[
              { name: 'clientName', label: 'client', sortable: true },
              { name: 'divisionName', label: 'division', sortable: true },
              { name: 'needsApproval', label: 'Approval', size: 'md', alignment: 'left' },
              { name: 'glCode', label: 'GL code', size: 'md', alignment: 'left'},
              { name: 'price', label: <Position.PriceHeader>price</Position.PriceHeader>, size: 'md', alignment: 'right' },
            ]}>
            <Table.Header
              sortBy={values.sortBy}
              onSortChanged={this.handleSort}
            />
            <Table.Body>
              {
                (values.catalogsStatus === 'loaded') &&
                (
                  values.catalogs.items.length ?
                    values.catalogs.items.map((item, index) => <Table.Row
                      key={`price-${index}`}
                      cells={[
                        item.clientName,
                        item.divisionName,
                          <div>
                              <select name="approvals" id={`approval-${item.catalogId}`} key={`price-${index}`} onChange={e => onChange(item.catalogId, e.target.value)} value={values.approvalsCache[item.catalogId]}>
                                  {approvals.map(approval =>
                                      <option key={`price-${index}-${approval}`} value={`${approval}`}>{this.displayApproval(approval)}</option>)}
                              </select>
                          </div>,
                          // <GLCodeField
                          //     key={`price-${index}`}
                          //     id={item.catalogId}
                          //     value={values.glCodesCache[item.catalogId] !== undefined ?  values.glCodesCache[item.catalogId] : item.glCode}
                          //     onChange={e => this.handleCodeChange(item.catalogId, e.target.value)}
                          // />,
                          <input key={`price-${index}`} id={item.catalogId} onChange={e => this.handleCodeChange(item.catalogId, e.target.value)} value={values.glCodesCache[item.catalogId] !== undefined ?  values.glCodesCache[item.catalogId] : item.glCode}/>,
                        <PriceField
                          key={`price-${index}`}
                          id={item.catalogId}
                          value={values.pricesCache[item.catalogId] !== undefined ?  values.pricesCache[item.catalogId] : (item.price || 0.0)}
                          onChange={this.handlePriceChange}
                        />,
                      ]}
                    />) :
                    <Table.Row
                      key={`price-0`}
                      colSpan="4"
                      cells={[
                        'This product is not a part of a client catalog. Please add this item to a client catalog before assigning a price.'
                      ]}
                    />
                )
              }
              {
                this.renderPreloaderRows(values.perPage, values.catalogsStatus)
              }
            </Table.Body>
          </Table>
        </Position.Table>
        <Position.Errors>
          {
            errors.pricesCache && errors.pricesCache.reduce((scope, error, index) => {
              if (error) {
                scope.push(<Error key={index}>{error}</Error>);
              }
              return scope;
            }, [])
          }
        </Position.Errors>
        <Separator borders="0 0 1">
          <Pagination
            perPage={values.perPage}
            skip={values.skip}
            count={values.catalogs.totalCount}
            onChange={this.handlePageChange}
          />
        </Separator>
      </React.Fragment>
    );
  }
}

export default withRouter(connect(
  null,
  dispatch => bindActionCreators({
    setSearch: actionsSearch.set,
  }, dispatch)
)(FormPrice));

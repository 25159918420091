import * as React from 'react';
import styled from 'styled-components';
import { Select } from './Select';
import { IFlatLocationOption } from '../_reducers/current-user';

const ValueContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const ValueClientDivision = styled.div`
  font-size: 11px;
  color: ${({ theme }) => theme.light};
  line-height: 130%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

const ValueLocation = styled.div`
  font-size: 12px;
  line-height: 130%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

const Option = styled<{ level: number }, 'span'>('span')`
  margin-left: ${({ level }) => (level * 18)}px;
`;


function filterOptions(options: IFlatLocationOption[], inputValue: string) {
  if (!inputValue || !inputValue.trim()) {
    return options;
  }

  const parentLevels: number[] = [null, null];
  const optionsWithScore: IFlatLocationOption[] = [];

  options.forEach((o, index: number) => {
    if (o.level < 2) {
      parentLevels[o.level] = index;
      return;
    }
    const matchIndex = o.name.toLowerCase().search(inputValue.toLowerCase());
    if (matchIndex !== -1) {
      optionsWithScore.push({
        ...o,
        searchScore: 200 - matchIndex,
        parentIndexes: parentLevels.slice(),
      });
    }
  });
  const sortedOptions: IFlatLocationOption[] = optionsWithScore.sort((a, b) => {
    if (a.searchScore > b.searchScore) { return -1; }
    if (a.searchScore < b.searchScore) { return 1; }
    return 0;
  });

  const searchResults: IFlatLocationOption[] = [];
  const usedParentsMap: { [k: number]: true } = {};

  sortedOptions.forEach(o => {
    let insertToEnd = false;
    o.parentIndexes.forEach((parentIndex, level) => {
      if (!usedParentsMap[parentIndex]) {
        if (level === 0) { // client
          searchResults.push(options[parentIndex]);
          insertToEnd = true;
        } else if (level === 1) { // division
          if (insertToEnd) {
            searchResults.push(options[parentIndex]);
          } else { // find client and index to insert division into
            const clientIndex = searchResults.findIndex(or => or.level === 0 && or.id === options[o.parentIndexes[0]].id);

            let insertIndex = -1;
            for (let i = clientIndex + 1; i < searchResults.length; i++) {
              if (searchResults[i].level === 0) {
                insertIndex = i;
                break;
              }
            }
            if (insertIndex !== -1) {
              searchResults.splice(insertIndex, 0, options[parentIndex]);
            } else {
              searchResults.push(options[parentIndex]);
              insertToEnd = true;
            }
          }
        }

        usedParentsMap[parentIndex] = true;

      } else {
        insertToEnd = false;
      }
    });

    if (insertToEnd) {
      searchResults.push(o);
    } else { // find division index to insert location into
      const divIndex = searchResults.findIndex(or => or.level === 0 && or.id === options[o.parentIndexes[1]].id);
      let insertIndex = -1;
      for (let i = divIndex + 1; i < searchResults.length; i++) {
        if (searchResults[i].level < 2) {
          insertIndex = i;
          break;
        }
      }

      if (insertIndex !== -1) {
        searchResults.splice(insertIndex, 0, o);
      } else {
        searchResults.push(o);
      }

    }
  });

  return searchResults;
}

interface IProps {
  className?: string;
  withoutBorder?: boolean;
  customFilter?: (options: IFlatLocationOption[], inputValue: string) => void;
  selectedLocation: number | null;
  onLocationChange: (id: number) => void;
  locationOptions: IFlatLocationOption[];
}

class LocationSelectorComponent extends React.Component<IProps> {

  constructor(props) {
    super(props);
  }

  renderOption(option) {
    return <Option level={option.level}>{option.name}</Option>;
  }

  renderValue(option) {

    if (option.path && option.path.length === 0) { // client selected
      return (
        <ValueContainer>
          <ValueClientDivision>&nbsp;</ValueClientDivision>
          <ValueLocation>{option.name}</ValueLocation>
        </ValueContainer>
      );
    }

    const clientDivisionNames = [option.path[0]];
    if (option.path[1]) {
      clientDivisionNames.push(option.path[1]);
    }
    return (
      <ValueContainer>
        <ValueClientDivision>{clientDivisionNames.join(', ')}</ValueClientDivision>
        <ValueLocation>{option.name}</ValueLocation>
      </ValueContainer>
    );
  }

  render() {
    return (
      <div className={this.props.className}>
        <Select
          compact
          labelKey="name"
          valueKey="id"
          clearable={false}
          placeholder="Select Location..."
          onChange={this.props.onLocationChange}
          value={this.props.selectedLocation}
          options={this.props.locationOptions}
          optionRenderer={this.renderOption}
          valueRenderer={this.renderValue}
          filterOptions={this.props.customFilter || filterOptions}
        />
      </div>
    );
  }
}

const StyledSelector = styled(LocationSelectorComponent)`

  .Select-control:hover {
    box-shadow: none;
  }

  .Select-menu {
    padding: 0;
    box-shadow: 2px 2px 5px 0 rgba(0, 0, 0, 0.07);
  }

  .Select-control {
    border: ${({ withoutBorder, theme }) => withoutBorder ? 'none' : `1px solid ${theme.lighter}`};
  }

  .Select-option{
    height: 24px;
    white-space: nowrap;
    &.is-disabled {
      font-weight: bold;
    }
  }
`;


export default StyledSelector;

import moment from 'moment';
import { types } from '../_actionTypes/roles';
import { types as typesNotification } from '../_actionTypes/notification';
import { roleServiceProxy, permissionsServiceProxy } from '../servicePool';
import {
  getSwaggerErrorResponse,
} from '../_utils/errorHandling';
import { IListRoleMod } from '../_reducers/roles';

export function getRoles(filter = '', sortBy = 'name ASC', skip, perPage, allStatus = 'loading') {
  return function (dispatch, getState) {
    dispatch({
      type: types.ROLE_GET_ALL,
      allStatus,
      roles: allStatus === 'sorting' ? getState().roles.roles : undefined,
      status: '',
    });
    return roleServiceProxy.getRoles(perPage, skip, filter, sortBy)
      .then(data => {
        const {
          items,
          totalCount,
        } = data;

        const modItems: IListRoleMod[] = items.map(role => ({
          ...role,
          createdAt: moment(role.creationTime).format('MM/DD/YYYY, hh:mm A'),
        }));

        dispatch({
          type: types.ROLE_GET_ALL,
          roles: modItems,
          totalCount,
          allStatus: 'loaded',
        });

        return items;
      })
      .catch(e => {
        dispatch({
          type: types.ROLE_GET_ALL,
          allStatus: 'error',
        });
        dispatch({
          type: typesNotification.NOTIFICATION_NOTIFY,
          message: getSwaggerErrorResponse(e).error.message,
          messageType: 'error',
        });
      });
  };
}

export function getRole(roleId) {
  return function (dispatch) {
    return roleServiceProxy.getRole(roleId)
      .then(data => {

        dispatch({
          type: types.ROLE_GET_BY_ID,
          role: data,
          status: 'loaded',
        });

        return data;
      })
      .catch(e => {
        dispatch({
          type: types.ROLE_GET_BY_ID,
          status: 'error',
        });
        dispatch({
          type: typesNotification.NOTIFICATION_NOTIFY,
          message: getSwaggerErrorResponse(e).error.message,
          messageType: 'error',
        });
      });
  };
}


export function deleteRole(userId) {
  return function (dispatch) {
    dispatch({
      type: types.ROLE_DELETE,
      status: 'loading',
    });
    return roleServiceProxy.deleteRole(userId)
      .then(data => {
        dispatch({
          type: types.ROLE_DELETE,
          status: 'loaded',
        });
      })
      .catch(e => {
        dispatch({
          type: types.ROLE_DELETE,
          allStatus: 'error',
        });
        dispatch({
          type: typesNotification.NOTIFICATION_NOTIFY,
          message: getSwaggerErrorResponse(e).error.message,
          messageType: 'error',
        });
      });
  };
}

export function getAllPermissions() {
  return function (dispatch) {
    dispatch({
      type: types.ROLE_GET_ALL_PERMISSIONS,
    });
    return permissionsServiceProxy.getAllPermissions()
      .then(data => {

        const { items } = data;

        dispatch({
          type: types.ROLE_GET_ALL_PERMISSIONS,
          permissionsTree: items,
        });

        return items;
      })
      .catch(e => {
        dispatch({
          type: typesNotification.NOTIFICATION_NOTIFY,
          message: getSwaggerErrorResponse(e).error.message,
          messageType: 'error',
        });
      });
  };
}

export function create(input) {
  return function (dispatch) {
    return roleServiceProxy.createRole(input).catch(e => {
      dispatch({
        type: typesNotification.NOTIFICATION_NOTIFY,
        message: getSwaggerErrorResponse(e).error.message,
        messageType: 'error',
      });
      throw getSwaggerErrorResponse(e).error;
    });
  };
}

export function update(input) {
  return function (dispatch) {
    return roleServiceProxy.updateRole(input).catch(e => {
      dispatch({
        type: typesNotification.NOTIFICATION_NOTIFY,
        message: getSwaggerErrorResponse(e).error.message,
        messageType: 'error',
      });
      throw getSwaggerErrorResponse(e).error;
    });
  };

}


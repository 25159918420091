import {SimpleMap} from '../_types/common';

export function setChildrenNodes(level: any[], permissionsMap: SimpleMap, value: boolean): void { // TODO: fix any type
  level.forEach(node => {
    if (value) {
      permissionsMap[node.name] = true;
    } else if (permissionsMap[node.name]) {
      delete permissionsMap[node.name];
    }
    if (node.children && node.children.length) {
      setChildrenNodes(node.children, permissionsMap, value);
    }
  });
}

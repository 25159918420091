import * as React from 'react';
import styled, {StyledComponentClass} from 'styled-components';

import {
  Button,
  Field,
  Link,
} from './index';

type StyledComponent = StyledComponentClass<{}, React.StatelessComponent<{}>>;

interface IPosition extends StyledComponent {
  Body?: StyledComponent;
  Head?: StyledComponent;
  Field?: StyledComponent;
  Footer?: StyledComponent;
  Wrapper?: StyledComponent;
  CancelButton?: StyledComponent;
  ButtonsWrap?: StyledComponent;
}

const Position: IPosition = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column; 
`;

Position.Body = styled.div`
  width: 100%; 
  height: 100%; 
`;

Position.Field = styled.div`
  padding: 10px 20px;
  margin-bottom: 8px;
  border-bottom: 1px solid ${({ theme }) => theme.light};
`;

Position.Footer = styled.div`
  text-align: right;
  padding: 20px;
`;

Position.Wrapper = styled.div`
  display: block;
`;

Position.CancelButton = styled.span`
  margin-right: 18px;
`;

Position.ButtonsWrap = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: flex-end;
`;

interface IPromptModalProps {
  value?: string;
  label?: string;
  required?: boolean;
  saveButtonText?: string;
  maxLength?: number;
  onSubmit: (name: string) => Promise<any>;
  resolve: (res: any) => void;
  reject: () => void;
}

interface IPromptModalState {
  name: string;
  nameError: string;
  touched: boolean;
  saving?: boolean;
}

class PromptModal extends React.Component<IPromptModalProps, IPromptModalState> {

  static defaultProps = {
    saveButtonText: 'Save',
  };

  constructor(props) {
    super(props);

    this.state = {
      name: props.value || '',
      nameError: '',
      touched: false,
    };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleOnChange = this.handleOnChange.bind(this);
    this.handleCancelButtonClick = this.handleCancelButtonClick.bind(this);
  }

  handleOnChange({ target: { value } }) {
    let error = '';
    if (!value || !value.trim()) {
      error = 'Required field';
    }
    else if (this.props.maxLength && value.length > this.props.maxLength) {
      error = `The value must be at most ${this.props.maxLength} characters.`;
    }
    this.setState({
      name: value,
      nameError: error,
      touched: true,
    });
  }

  handleSubmit(e) {
    e.preventDefault();
    if (!this.validateForm()) { return; }
    this.setState({ saving: true });

    this.props.onSubmit(this.state.name.trim()).then(res => {
      this.setState({ saving: false });
      res.name = this.state.name.trim();
      this.props.resolve(res);
    }).catch(() => {
      this.setState({ saving: false });
    });
  }

  validateForm() {
    const newState = {} as IPromptModalState;
    let status = true;
    if (!this.state.name || !this.state.name.trim()) {
      newState.nameError = 'Required field';
      status = false;
    }
    if (this.props.maxLength && this.state.name.length > this.props.maxLength) {
      newState.nameError = `The value must be at most ${this.props.maxLength} characters.`;
      status = false;
    }
    if (!status) { this.setState(newState); }
    return status;
  }

  handleCancelButtonClick() {
    this.props.reject();
  }

  render() {
    return (
      <form noValidate onSubmit={this.handleSubmit}>
        <Position.Wrapper>
          <React.Fragment>
            <Position.Field>
              <Field
                required={this.props.required}
                autoFocus
                disabled={this.state.saving}
                type="text"
                name="name"
                label={this.props.label}
                placeholder=""
                value={this.state.name}
                error={this.state.nameError}
                onChange={this.handleOnChange}
              />
            </Position.Field>
          </React.Fragment>
        </Position.Wrapper>
        <Position.Footer>
          <Position.ButtonsWrap>
            <Position.CancelButton>
              <Link.Button
                primary
                onClick={this.handleCancelButtonClick}
                scale="md"
              >
                Cancel
              </Link.Button>
            </Position.CancelButton>
            <Button type="submit"
              preloader={this.state.saving}
              disabled={this.state.saving || (this.props.required && !this.state.touched) || !!this.state.nameError}>
              {this.props.saveButtonText}
            </Button>
          </Position.ButtonsWrap>
        </Position.Footer>
      </form>
    );
  }
}

export default PromptModal;

import React from 'react';
import styled from 'styled-components';

const OrderIcon = props => {
  return (
    <i {...props}>
      <svg viewBox="0 0 24 22" width="24" height="22">
        <path d=" M 22.001 2.001 L 2.001 2.001 L 2.001 4.001 L 22.001 4.001 L 22.001 2.001 Z  M 5 10 L 5 8 L 19 8 L 19 10 L 5 10 Z  M 5 12 L 5 14 L 7 14 L 7 12 L 5 12 Z  M 9 14 L 9 12 L 19 12 L 19 14 L 9 14 Z  M 5 16 L 5 18 L 7 18 L 7 16 L 5 16 Z  M 9 18 L 9 16 L 19 16 L 19 18 L 9 18 Z  M 0 22 L 0 0 L 24 0 L 24 22 L 0 22 Z  M 2.001 6 L 22.001 6 L 22.001 20 L 2.001 20 L 2.001 6 Z " fillRule="evenodd" />
      </svg>
    </i>
  );
};

export default styled(OrderIcon)`
  ${ props => `
    width: 21px;
    height: 21px;
    display: inline-flex;
    justify-content: center;

    svg{
      width: 100%;
      height: 100%;
      path{
        fill: ${props.theme.default}; 
      }
    }
  `}
`;

import { types } from '../_actionTypes/location';
import {EditLocationDto, LocationListDto} from '../service-proxies';

export interface ILocationStore {
  locations: LocationListDto[];
  totalCount: number;
  listStatus: string;
  location: EditLocationDto;
  status: string;
  perPage: number;
  skip: number;
}

const initialState: ILocationStore = {
  locations: [],
  totalCount: null,
  listStatus: '',
  location: {} as EditLocationDto,
  status: '',
  perPage: 10,
  skip: 0,
};

export default (state: ILocationStore = initialState, action) => {
  const {
    locations,
    totalCount,
    listStatus,
    location,
    status,
    perPage,
    skip,
  } = action;

  switch (action.type) {
    case types.LOCATION_GET_ALL:
      return ({
        ...state,
        locations,
        totalCount,
        listStatus,
        status,
        location,
        perPage,
        skip,
      });
    case types.LOCATION_GET:
      return ({
        ...state,
        location,
        status,
      });
    case types.LOCATION_CREATE:
      return ({
        ...state,
        status,
      });
    case types.LOCATION_UPDATE:
      return ({
        ...state,
        status,
      });
    case types.LOCATION_REMOVE:
      return ({
        ...state,
        status,
      });
    default:
      return state;
  }
};

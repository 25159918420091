import React from 'react';
import styled from 'styled-components';

export default styled(props => {
  return (
    <i {...props}>
      <svg viewBox="6 4 20 24" width="20" height="24">
        <path d=" M 19 20 L 19 22 L 22 22 L 22 20 L 19 20 Z  M 10 20 L 10 22 L 17 22 L 17 20 L 10 20 Z  M 19 16 L 19 18 L 22 18 L 22 16 L 19 16 Z  M 10 16 L 10 18 L 17 18 L 17 16 L 10 16 Z  M 10 10 L 10 12 L 22 12 L 22 10 L 10 10 Z  M 6 4 L 6 28 L 26 28 L 26 4 L 6 4 Z  M 8 6 L 24 6 L 24 26 L 8 26 L 8 6 Z " />
      </svg>
    </i>
  );
}) `
  ${ props => `
    width: 21px;
    height: 21px;
    display: inline-flex;
    justify-content: center;
    align-items: center;

    svg{
      path{
        fill: ${props.theme.primary}; 
      }
    }
  `}
`;

import { skuSegmentServiceProxy } from '../servicePool';

import {
  ListResultDtoOfSearchOutputDtoOfInt64,
  EntityDtoOfInt64,
} from '../service-proxies';

export function getAllPrefixes(value: string): Promise<ListResultDtoOfSearchOutputDtoOfInt64> {
  return skuSegmentServiceProxy.searchSkuPrefixes(5, value);
}

export function createPrefix(value: string): Promise<EntityDtoOfInt64> {
  return skuSegmentServiceProxy.createSkuPrefix({ name: value });
}

export function deletePrefix(id: number): Promise<void> {
  return skuSegmentServiceProxy.deleteSkuPrefix(id);
}

export function getAllSuffixes(value): Promise<ListResultDtoOfSearchOutputDtoOfInt64> {
  return skuSegmentServiceProxy.searchSkuSuffixes(100, value);
}
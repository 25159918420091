import {ProductDetailsDto, ProductListDto} from '../service-proxies';
// import {saveCartToLS} from '../_utils/userSession';
import {SimpleMap} from '../_types/common';
import {types} from '../_actionTypes/cart';
import {cartServiceProxy} from '../servicePool';

export function clearCart() {
    return function (dispatch, getState) {
        dispatch({
            type: types.CART_CLEAR_CART,
        });
        cartServiceProxy.clearCart();
        // saveCartToLocalStorage(dispatch, getState);
    };
}

export function addProductToCart(product: ProductListDto | ProductDetailsDto, price: number, count: number = 0) {
    return function (dispatch, getState) {
        dispatch({
            type: types.CART_ADD_PRODUCT,
            count,
            price,
            product,
        });
        saveCartToLocalStorage(dispatch, getState);
    };
}

export function addProductsToCart(products: ProductListDto[], prices: number, count: number = 0) {
    return function (dispatch, getState) {
        dispatch({
            type: types.CART_ADD_PRODUCTS,
            count,
            prices,
            products,
        });
        saveCartToLocalStorage(dispatch, getState);
    };
}

export function removeProductFromCart(productId: number) {
    return function (dispatch, getState) {
        dispatch({
            type: types.CART_REMOVE_PRODUCT,
            productId,
        });
        saveCartToLocalStorage(dispatch, getState);
    };
}

export function multiRemoveProductsFromCart(selectedMap: SimpleMap) {
    return function (dispatch, getState) {
        dispatch({
            type: types.CART_MULTI_REMOVE_PRODUCTS,
            selectedMap,
        });
        saveCartToLocalStorage(dispatch, getState);
    };
}

export function productCountChange(productId: number, count: number) {
    return function (dispatch, getState) {
        dispatch({
            type: types.CART_PRODUCT_COUNT_CHANGED,
            productId,
            count,
        });
        saveCartToLocalStorage(dispatch, getState);
    };
}

/*
 * do not remove this!
 */
let timeoutId = null;

function saveCartToLocalStorage(dispatch, getState) {

    const savingLag = 500;

    if (timeoutId) {
        window.clearTimeout(timeoutId);
    }

    timeoutId = window.setTimeout(() => {
        timeoutId = null;
        dispatch({
            type: types.CART_CART_IS_TO_BE_SAVED,
            savedAt: Date.now(),
        });

        cartServiceProxy.saveCart(getState().cart);

        // saveCartToLS(getState().currentUser.loginInfo.user.id, getState().currentUser.catalogId, getState().cart);
    }, savingLag);

}

import React, { Fragment } from "react";
import styled from "styled-components";
import PropTypes from "prop-types";

import Link from "./Link";
import Checkbox from "./Checkbox";
import IconTrigger from "./IconTrigger";
import { TrashIcon } from "../assets/icons";

import {
  TriangleIcon,
  PencilIcon,
  FolderIcon,
  FolderOpenIcon
} from "../assets/icons";

const Label = styled.div`
  ${({ theme }) => {
    let { global } = theme;
    return `
      ${global}
      display: flex;
      flex-wrap: nowrap;
      align-items: center;
      justify-content: space-between;
      margin-left: 5px;
      flex: 1;
      min-width: 0;
    `;
  }}
`;

const LabelWrapper = styled.div`
  flex: 1;
  min-width: 0;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  margin-top: -4px;
  margin-left: 7px;
`;

const DropdownToggle = styled.div`
  width: 30px;
  text-align: center;
  font-size: 20px;
  font-weight: bold;
  color: ${({ theme }) => theme.dark};
  display: block;
  cursor: pointer;
  white-space: nowrap;
  position: relative;
  & > i svg {
    path {
      fill: ${({ theme }) => theme.dark};
    }
  }
`;

const Body = styled.div`
  padding: 8px 10px;
`;

const ExpandButton = Link.Button.extend`
  ${({ theme }) => {
    let { primary } = theme;
    return `  
      margin-left: -20px;
      i{
        margin: 0;
        svg{
          path{
            fill: ${primary};
          }
        }
      }
    `;
  }}
`;

const ProductLabel = styled.span`
  font-size: 12px;
  font-weight: normal;
  cursor: pointer;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const ProductSku = styled.div`
  font-size: 12px;
  font-weight: normal;
  cursor: pointer;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: gray;
`;

class Branch extends React.PureComponent {
  constructor(props) {
    super(props);
    this.handleExpand = this.handleExpand.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.handleRemoveProduct = this.handleRemoveProduct.bind(this);
    this.handleRenameCategory = this.handleRenameCategory.bind(this);
  }

  handleExpand() {
    if (this.props.onExpand) {
      this.props.onExpand(
        !this.props.expanded,
        this.props.path,
        this.props.node
      );
    }
  }

  handleClick() {
    if (this.props.onClick) {
      this.props.onClick(
        !this.props.selected,
        this.props.path,
        this.props.node
      );
    }
  }

  handleRemoveProduct() {
    if (this.props.onRemoveProduct) {
      this.props.onRemoveProduct(this.props.node);
    }
  }

  handleRenameCategory() {
    if (this.props.onRenameCategory) {
      this.props.onRenameCategory(this.props.node, this.props.path);
    }
  }

  render() {
    let {
      className,
      children,
      node,
      selected,
      expanded,
      catalog,
      onExpand,
      onClick,
      label,
      to,
      isDisabled,
      showDropDown
    } = this.props;

    let nodeName = node.name || "";
    let nodeSku = node.sku || "";

    return (
      <Fragment>
        <Body className={className}>
          {onExpand && (
            <ExpandButton onClick={this.handleExpand}>
              <TriangleIcon />
            </ExpandButton>
          )}
          {onClick && (
            <Checkbox
              disabled={isDisabled}
              value={selected}
              indeterminate={node.indeterminate}
              onChange={this.handleClick}
            />
          )}
          {catalog && (
            <Link.Button onClick={this.handleExpand}>
              <IconTrigger
                status={!expanded}
                icons={[FolderIcon, FolderOpenIcon]}
              />
            </Link.Button>
          )}
          {label ? (
            label
          ) : to ? (
            <Label>
              <Link.NavLink primary to={to}>
                {nodeName}
              </Link.NavLink>
            </Label>
          ) : (
            <Label>
              <LabelWrapper>
                {catalog ? (
                  <Link.Button primary onClick={this.handleClick}>
                    {nodeName}
                  </Link.Button>
                ) : (
                  <div>
                    <ProductLabel onClick={this.handleClick}>
                      {nodeName}
                    </ProductLabel>
                    <ProductSku onClick={this.handleClick}>
                      {nodeSku}
                    </ProductSku>
                  </div>
                )}
              </LabelWrapper>
              <DropdownToggle>
                {showDropDown &&
                  !node.noProducts &&
                  (catalog ? (
                    <PencilIcon onClick={this.handleRenameCategory} />
                  ) : (
                    <TrashIcon onClick={this.handleRemoveProduct} />
                  ))}
              </DropdownToggle>
            </Label>
          )}
        </Body>
        {expanded && children}
      </Fragment>
    );
  }
}

Branch.propTypes = {
  path: PropTypes.array.isRequired,
  onExpand: PropTypes.func,
  onClick: PropTypes.func,
  onRemoveProduct: PropTypes.func,
  onRenameCategory: PropTypes.func,
  node: PropTypes.object.isRequired,
  showDropDown: PropTypes.bool,
  selected: PropTypes.bool,
  expanded: PropTypes.bool,
  children: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.array,
    PropTypes.string
  ]),
  className: PropTypes.string,
  activeId: PropTypes.number,
  catalog: PropTypes.number,
  label: PropTypes.string,
  to: PropTypes.string,
  isDisabled: PropTypes.bool
};

Branch.defaultProps = {
  expandElement: "triangle"
};

const StyledBranch = styled(Branch)`
  ${({ theme, ...props }) => {
    let { primary, dark, lighter } = theme;

    const globalIndent = 40;

    let triangleRotate = !props.expanded ? "rotate(-90deg)" : "rotate(0deg)";

    let indent = `${(props.depth - 1) * 26 + globalIndent}px`;

    let folderColor = props.depth === 1 ? theme.default : dark;

    return `
      display: flex;
      flex-wrap: nowrap;
      list-style: none;
      padding-left: ${indent}; 
      position: relative;
      ${props.selected ? `background-color: ${lighter} !important;` : ""}
      
      .${TriangleIcon.styledComponentId}{
          transform: ${triangleRotate};
          path{
            fill: ${primary};
          }
        }
        
      > .${Link.Button.styledComponentId}{

        > .${FolderIcon.styledComponentId}{
          margin: 0 0 0 5px;
          path{
            fill: ${folderColor};
          }
        }

        > .${FolderOpenIcon.styledComponentId}{
          margin: 0 0 0 5px;
          path{
            fill: ${folderColor};
          }
        }
      }

      > .${Checkbox.styledComponentId}{
        margin: 0 0 0 5px;
      }

    `;
  }}
`;

export default StyledBranch;

import * as React from 'react';
import styled from 'styled-components';

import { history } from '../../store';

import {
  Button,
  FormGrid as Grid,
  TabsSwitch,
  Link,
  Separator,
  Skeleton,
} from '../../components';

import withMenu from '../HOC/WithMenu';

import FormDetails from './FormDetails';
import FormPrice from './FormPrice';
import { FormikActions, FormikState, FormikHandlers } from 'formik';
import { EditProductType } from './Edit';

const Position: { [key: string]: any } = {};

Position.Head = styled<{ className: string, open: boolean }>(({ className, open, children }) => <header className={className}>
  <Separator borders="0 0 1">
    {children}
  </Separator>
</header>)`
  >div{
    padding: 20px;
    display: flex;
    justify-content: space-between;
  }
`;

Position.HeadElement = styled.div`
  display: flex;
  align-items: center;
`;

interface IFormProps extends FormikActions<EditProductType>, FormikState<EditProductType>, FormikHandlers {
  handleBlur: any;
  isSaving: boolean;
  categories: any;
  onError: (error: any, msg: string) => void;
  isLoading: boolean;
  isNew: boolean;
}

class Form extends React.Component<IFormProps> {

  constructor(props) {
    super(props);

    this.handleTabChange = this.handleTabChange.bind(this);
    this.handleCancel = this.handleCancel.bind(this);

  }

  handleTabChange(value) {
    this.props.setFieldValue('tab', value);
  }

  handleCancel() {
    history.goBack();
  }

  renderTabsPreloader() {
    return <React.Fragment>
      <Skeleton.Line size="sm" key={`preloader-tab-1`} />
      {' '}
      <Skeleton.Line size="sm" key={`preloader-tab-2`} />
    </React.Fragment>;
  }

  render() {
    const {
      values,
      handleSubmit,
    } = this.props;

    return (
      <form noValidate onSubmit={handleSubmit}>
        <Grid>
          {
            !this.props.isNew &&
            (
              !this.props.isLoading ?
                <Position.Head>
                  <Position.HeadElement>
                    <TabsSwitch
                      items={values.tabsOptions}
                      onTabChanged={this.handleTabChange}
                    />
                  </Position.HeadElement>
                </Position.Head> :
                <Position.Head>
                  <Position.HeadElement>
                    {
                      this.renderTabsPreloader()
                    }
                  </Position.HeadElement>
                </Position.Head>
            )
          }
          {
            values.tab === 'details' &&
            <FormDetails {...this.props} />
          }
          {
            values.tab === 'prices' &&
            <FormPrice {...this.props} />
          }
          <Grid.Footer>
            <Grid.FooterElement />
            <Grid.FooterElement>
              <Link.Button
                primary
                onClick={this.handleCancel}
                scale="lg"
              >
                Cancel
              </Link.Button>
              <Button
                type="submit"
                disabled={this.props.isLoading}
                scale="lg"
                preloader={this.props.isLoading}
              >
                Submit
              </Button>
            </Grid.FooterElement>
          </Grid.Footer>
        </Grid>
      </form>
    );
  }
}

export default withMenu(Form, false);

import React from 'react';
import styled from 'styled-components';

const TicIcon = props => {
  return(
    <i {...props}>
      <svg viewBox="-1020 -2685 20 20" width="20" height="20"> 
        <path d=" M -1010.665 -2670.519 C -1010.61 -2670.571 -1010.556 -2670.623 -1010.506 -2670.682 C -1010.506 -2670.682 -1004.28 -2678.045 -1004.28 -2678.045 C -1003.85 -2678.553 -1003.923 -2679.306 -1004.444 -2679.726 C -1004.964 -2680.146 -1005.734 -2680.075 -1006.164 -2679.567 C -1006.164 -2679.567 -1011.019 -2673.825 -1011.662 -2673.065 C -1012.317 -2673.84 -1013.837 -2675.637 -1013.837 -2675.637 C -1014.266 -2676.145 -1015.036 -2676.216 -1015.557 -2675.796 C -1016.076 -2675.376 -1016.15 -2674.624 -1015.72 -2674.115 C -1015.72 -2674.115 -1012.607 -2670.433 -1012.607 -2670.433 C -1012.178 -2669.925 -1011.408 -2669.854 -1010.887 -2670.274 C -1010.798 -2670.346 -1010.722 -2670.428 -1010.665 -2670.519 Z " /> 
      </svg> 
    </i>
  );
};

export default styled(TicIcon)`
  ${ props => `
    width: 21px;
    height: 21px;
    display: inline-flex;
    justify-content: center;

    svg{
      path{
        fill: ${props.theme.default}; 
      }
    }
  `}
`;

import * as React from "react";
import styled from "styled-components";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import debounce from "lodash.debounce";
import * as Yup from "yup";

import withGranted, { IWithGrantedProps } from "../../HOC/WithGranted";
import { history } from "../../../store";
import { IGlobalStore } from "../../../_reducers/reducers";
import { ICartProductEntry } from "../../../_reducers/cart";

import {
  getCatalogsProductsPrices,
  comparePrices
} from "../../../_api/products";

import { checkSpendingLimit } from "../../../_api/user";

import { submitOrder } from "../../../_api/orders";

import { closeRightSlider } from "../../../_actions/layout";

import { clearCart } from "../../../_actions/cart";

import { notify, notifySwagger } from "../../../_actions/notification";

import {
  MainContainer,
  Button,
  Textarea,
  Article,
  Link,
  Table,
  Skeleton,
  MessageBar
} from "../../../components";

import { IMatch } from "../../../_types/common";

import withMenu from "../../../containers/HOC/WithMenu";

import Form from "./Form";

import Title from "./fields/Title";
import { ORDERS_SUBMIT } from "../../../_constants/permissions";

// positions
const Tabs = styled.div`
  display: flex;
  width: 100%;
  padding: 20px;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const Body = styled.div`
  height: 100%;
`;

const TableBody = styled.div`
  height: 100%;
`;

const Footer = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 20px 20px 30px;
`;

const Info = styled.div`
  text-align: right;
  padding: 0 20px 20px;
`;

const FooterElement = styled.div`
  > * {
    margin-left: 10px;
  }
`;

const Position = class {
  static Tabs = Tabs;
  static TableBody = TableBody;
  static Content = Content;
  static Body = Body;
  static Footer = Footer;
  static Info = Info;
  static FooterElement = FooterElement;
};

// interfaces
interface IViewProps extends IConnectedActions {
  match: IMatch;
  productEntries: ICartProductEntry[];
  locationId: number;
  locationName: string;
  catalogId: number;
  handleTabChange: (tab: string) => void;
}

interface IPrices {
  [props: number]: number;
}

interface IViewState {
  status: "loading" | "done" | string;
  productEntries?: ICartProductEntry[];
  prices: IPrices;
  warningMessages: string[];
  canBeSubmitedd?: boolean;
  comment?: string;
  commentError?: string;
}

class View extends React.Component<IViewProps, IViewState> {
  public orderId: number;
  public catalogId: number;

  constructor(props) {
    super(props);

    this.orderId = this.props.match.params.orderId;

    this.state = {
      status: "loading",
      prices: null,
      comment: "",
      commentError: "",
      canBeSubmitedd: true,
      warningMessages: []
    };

    this.getPrices = this.getPrices.bind(this);
    this.handleClickBack = this.handleClickBack.bind(this);
    this.handleSubmitedOrder = this.handleSubmitedOrder.bind(this);
    this.handleSendToApproval = this.handleSendToApproval.bind(this);
    this.handleResetWarning = this.handleResetWarning.bind(this);
    this.isPlacingButtonsDisabled = this.isPlacingButtonsDisabled.bind(this);
    this.handleChangeComment = this.handleChangeComment.bind(this);

    if (props.productEntries.length) {
      this.getPrices();
    }

    props.closeRightSlider();
  }

  componentDidUpdate(prevProps) {
    const oldProductsCount = !!prevProps.productEntries.length;
    const newProductsCount = !!this.props.productEntries.length;

    if (oldProductsCount !== newProductsCount) {
      this.getPrices();
    }
  }

  validateComment(value) {
    const shcema = Yup.string().max(
      500,
      "must contain less than 500 characters"
    );
    return shcema.validate(value);
  }

  async handleChangeComment(e) {
    const fieldValue = e.target.value;
    try {
      await this.validateComment(fieldValue);
      this.setState({
        comment: fieldValue,
        commentError: ""
      });
    } catch (e) {
      this.setState({
        comment: fieldValue,
        commentError: e.message ? e.message : ""
      });
    }
  }

  getPrices() {
    const productIds = this.props.productEntries.map(entrie => {
      return entrie.product.id;
    });

    getCatalogsProductsPrices(this.props.locationId, productIds)
      .then(prices => {
        const restructeredPrices = prices.items.reduce(
          (result, productPrice) => {
            result[productPrice.productId] = productPrice.price;
            return result;
          },
          {}
        );

        this.setState({
          status: "done",
          prices: restructeredPrices
        });
      })
      .catch(e => {
        this.props.notifySwagger(e, "error");
        this.setState({
          status: "done"
        });
      });
  }

  handleClickBack() {
    history.goBack();
  }

  handleSubmitedOrder() {
    const productsPrices = [];
    const totalPrice = this.props.productEntries.reduce((result, orderItem) => {
      const price = this.state.prices[orderItem.product.id]
        ? this.state.prices[orderItem.product.id]
        : 0;
      return result + price * parseInt(orderItem.count.toString());
    }, 0);

    for (const key in this.state.prices) {
      if (Object.prototype.hasOwnProperty.call(this.state.prices, key)) {
        productsPrices.push({
          productId: key,
          price: this.state.prices[key]
        });
      }
    }

    if (this.props.catalogId) {
      Promise.all([
        comparePrices(this.props.catalogId, productsPrices),
        checkSpendingLimit(totalPrice, this.props.locationId)
      ])
        .then(values => {
          const isChangedPrices = !!values[0].items.length;
          const changedPrices = values[0].items;
          const canBeSubmitedd = values[1].canBeSubmitted;
          const messages = values[1].warningMessages;

          if (isChangedPrices) {
            this.setChangedPrices(changedPrices);
            this.props.notify("Some prices was changed!", "info");
          }

          this.setState({ canBeSubmitedd, warningMessages: messages });

          if (!isChangedPrices && canBeSubmitedd && !messages.length) {
            const orderProducts = this.props.productEntries.map(entry => {
              return {
                price: this.state.prices[entry.product.id],
                quantity: entry.count,
                productId: entry.product.id
              };
            });

            this.setState({ status: "saving" });

            submitOrder(
              this.props.locationId,
              orderProducts,
              this.state.comment
            )
              .then(() => {
                this.props.clearCart();
                this.setState({ status: "done" });
                history.push("/");
              })
              .catch(e => {
                this.props.notifySwagger(e, "error");
                this.setState({
                  status: "done"
                });
              });
          }
        })
        .catch(e => {
          this.props.notifySwagger(e, "error");
          this.setState({
            status: "done"
          });
        });
    }
  }

  handleSendToApproval() {
    const productsPrices = [];

    for (const key in this.state.prices) {
      if (Object.prototype.hasOwnProperty.call(this.state.prices, key)) {
        productsPrices.push({
          productId: key,
          price: this.state.prices[key]
        });
      }
    }

    if (this.props.catalogId) {
      comparePrices(this.props.catalogId, productsPrices)
        .then(values => {
          const isChangedPrices = !!values.items.length;
          const changedPrices = values.items;

          if (isChangedPrices) {
            this.setChangedPrices(changedPrices);
            this.props.notify("Some prices was changed!", "info");
          }

          if (!isChangedPrices) {
            const orderProducts = this.props.productEntries.map(entry => {
              return {
                price: this.state.prices[entry.product.id],
                quantity: entry.count,
                productId: entry.product.id
              };
            });

            this.setState({ status: "saving" });

            submitOrder(
              this.props.locationId,
              orderProducts,
              this.state.comment
            )
              .then(() => {
                this.props.clearCart();
                this.setState({ status: "done" });
                history.push("/");
              })
              .catch(e => {
                this.props.notifySwagger(e, "error");
                this.setState({
                  status: "done"
                });
              });
          }
        })
        .catch(e => {
          this.props.notifySwagger(e, "error");
          this.setState({
            status: "done"
          });
        });
    }
  }

  handleResetWarning() {
    this.setState({ warningMessages: [], canBeSubmitedd: true });
  }

  setChangedPrices(changedPrices) {
    const prices = { ...this.state.prices };

    changedPrices.forEach(priceEntity => {
      if (prices[priceEntity.productId]) {
        prices[priceEntity.productId] = priceEntity.price;
      }
    });

    this.setState({
      prices
    });
  }

  renderPreloaderRows(perPage: number, status?: string) {
    const rows = [];
    const elementsCount =
      status === "loading" || status === "saving" ? perPage - 1 : 0;

    for (let i = 0; i < elementsCount; i++) {
      rows.push(
        <Table.Row
          key={"preloader" + i}
          rows={perPage}
          cells={[
            <Skeleton.Square key="thumb-preloader" />,
            <React.Fragment key="item-preloader">
              <p>
                <Skeleton.Line />
              </p>
              <p>
                <Skeleton.Line />
              </p>
              <p>
                <Skeleton.Line />
              </p>
            </React.Fragment>,
            <Skeleton.Line key="price-preloader" size="xs" />,
            <Skeleton.Line key="count-preloader" size="sm" />,
            <Skeleton.Line key="sub-price-preloader" size="xs" />,
            <Skeleton.Line key="action-preloader" size="xs" />
          ]}
        />
      );
    }

    const table = (
      <Table>
        <Table.Header
          columns={[
            { name: "thumb", label: "", size: "xs" },
            { name: "item", label: "item", size: "xl" },
            { name: "price", label: "price" },
            { name: "quantity", label: "quantity" },
            { name: "subtotal", label: "subtotal" },
            { name: "toolbar", label: "", size: "xs" }
          ]}
        />
        <Table.Body>{rows}</Table.Body>
      </Table>
    );

    return table;
  }

  isPlacingButtonsDisabled() {
    if (this.props.productEntries && !this.props.productEntries.length) {
      return true;
    }

    if (!this.state.canBeSubmitedd) {
      return true;
    }

    if (this.state.commentError) {
      return true;
    }

    return false;
  }

  render() {
    return (
      <MainContainer
        centered
        scale="md"
        renderTitle={
          <React.Fragment>
            {this.state.warningMessages &&
              !!this.state.warningMessages.length &&
              this.state.warningMessages.map((message, index) => {
                return (
                  <MessageBar key={`message-${index}`}>{message}</MessageBar>
                );
              })}
            <Title key="main-title" label="ORDER CONFIRMATION" />
          </React.Fragment>
        }
      >
        <Position.Content>
          <Position.Body>
            {this.state.status === "done" && (
              <Form
                productEntries={this.props.productEntries}
                pricesMap={this.state.prices}
                onResetWarning={this.handleResetWarning}
              />
            )}
            {(this.state.status === "loading" ||
              this.state.status === "saving") &&
              this.renderPreloaderRows(5, this.state.status)}
            <Position.Info>
              <Article.P scale="sm">
                Facility Location: {this.props.locationName}
              </Article.P>
              {this.state.warningMessages &&
                !!this.state.warningMessages.length && (
                  <Textarea
                    placeholder="Write your comments to approver…"
                    compact
                    onChange={this.handleChangeComment}
                    onBlur={this.handleChangeComment}
                    value={this.state.comment}
                    error={this.state.commentError}
                  />
                )}
            </Position.Info>
          </Position.Body>
          <Position.Footer>
            <Position.FooterElement />
            <Position.FooterElement>
              <Link.Button
                onClick={this.handleClickBack}
                preloader={
                  this.state.status === "loading" ||
                  this.state.status === "saving"
                }
              >
                Back
              </Link.Button>
              {this.props.isGranted(ORDERS_SUBMIT) &&
              (!this.state.warningMessages ||
                !this.state.warningMessages.length) ? (
                <Button
                  onClick={debounce(this.handleSubmitedOrder, 500)}
                  preloader={
                    this.state.status === "loading" ||
                    this.state.status === "saving"
                  }
                  disabled={
                    this.isPlacingButtonsDisabled() ||
                    this.state.status === "loading" ||
                    this.state.status === "saving"
                  }
                >
                  Submit Order
                </Button>
              ) : (
                <Button
                  onClick={debounce(this.handleSendToApproval, 500)}
                  preloader={
                    this.state.status === "loading" ||
                    this.state.status === "saving"
                  }
                  disabled={
                    this.isPlacingButtonsDisabled() ||
                    this.state.status === "loading" ||
                    this.state.status === "saving"
                  }
                >
                  Send Order For Approval
                </Button>
              )}
            </Position.FooterElement>
          </Position.Footer>
        </Position.Content>
      </MainContainer>
    );
  }
}

interface IConnectedProps {
  productEntries: ICartProductEntry[];
  locationId: number;
  locationName: string;
  catalogId: number;
}

interface IConnectedActions extends IWithGrantedProps {
  closeRightSlider: typeof closeRightSlider;
  notify: typeof notify;
  notifySwagger: typeof notifySwagger;
  clearCart: typeof clearCart;
}

export default withMenu(
  connect(
    createStructuredSelector<IGlobalStore, IConnectedProps>({
      productEntries: state => state.cart.productEntries,
      locationId: state => state.currentUser.locationInfo.id,
      locationName: state => state.currentUser.locationInfo.name,
      catalogId: state => state.currentUser.catalogId
    }),
    {
      closeRightSlider,
      notify,
      notifySwagger,
      clearCart
    }
  )(withGranted(View)),
  false
);

import React from 'react';
import styled from 'styled-components';

const RectIcon = props => {
  return(
    <i {...props}>
      <svg viewBox="0 0 20 20" width="20" height="20">
        <path d=" M 5 4 L 15 4 C 15.552 4 16 4.448 16 5 L 16 15 C 16 15.552 15.552 16 15 16 L 5 16 C 4.448 16 4 15.552 4 15 L 4 5 C 4 4.448 4.448 4 5 4 Z " />
      </svg>
    </i>
  );
};

export default styled(RectIcon)`
  ${ props => `
    width: 21px;
    height: 21px;

    svg{
      rect{
        fill: ${props.theme.default}; 
      }
    }
  `}
`;
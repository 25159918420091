import * as React from 'react';
import { Fragment } from 'react';
import styled from 'styled-components';
import OriginalSelect, { AsyncCreatable, Async, Creatable } from 'react-select';
import PropTypes from 'prop-types';
import uniq from 'lodash/uniq';
import { XIcon } from '../assets/icons';
import keycode from 'keycode';

export const Label = styled<{ error?: string, compact?: boolean }, 'label'>('label')`
  ${ props => {

    const {
      global,
      warning,
      primary,
      fs_md,
      lightest,
      light,
    } = props.theme;

    const color = !props.error ?
      primary :
      warning;

    let wrap = 'nowrap';

    let paddingHorizontal = '0px';
    let paddingVertical = '0px';
    let borderWidth = '0px';
    let fontSize = '14px';
    let margin = '0';

    let backgroundColor = 'transparent';
    let borderColor = 'transparent';

    if (props.compact) {
      wrap = 'wrap';

      paddingHorizontal = '22px';
      paddingVertical = '10px';
      borderWidth = '1px';
      fontSize = fs_md;
      margin = '0px';

      backgroundColor = lightest;
      borderColor = props.error ?
        warning :
        light;
    }

    return `
      ${global}
      display: inline-flex;
      white-space: nowrap;
      flex-wrap: ${wrap};
      align-items: center;

      font-size: ${fontSize};
      padding: ${paddingVertical} ${paddingHorizontal};
      border-top-width: ${borderWidth};
      border-left-width: ${borderWidth};
      border-right-width: ${borderWidth};
      border-bottom-width: ${borderWidth};
      margin: ${margin};

      border-top-style: solid;
      border-left-style: solid;
      border-bottom-style: solid;
      border-right-style: solid;

      color: ${color};
      background-color: ${backgroundColor};
      border-color: ${borderColor};
    `;
  }}
`;

export const Error = styled.div`
  ${ props => {

    const {
      global,
      warning,
      fs_xs
    } = props.theme;

    return `
      ${global}
      min-height: 19px;
      margin: 0 0 3px;
      font-weight: 300;
      color: ${warning};
      font-size: ${fs_xs}; 
    `;
  }}
`;

interface IChipBodyProps {
  className?: string;
  active?: boolean;
  id: string;
  value: string;
  editable?: boolean;
  onChipClick: (event: any, id: string) => void;
  onEdit: (value: string, newValue: string) => void;
  onBlur: (e: any, value: string) => void;
}

export const ChipBody = styled<IChipBodyProps>(class extends React.Component<IChipBodyProps> {

  chip: any;

  constructor(props) {
    super(props);

    this.handleClick = this.handleClick.bind(this);
    this.handleBlur = this.handleBlur.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);
  }

  componentDidUpdate() {
    if (this.props.active) { this.chip.focus(); }
  }

  handleClick(event) {
    this.props.onChipClick(event, this.props.id);
  }

  handleBlur(event) {

    if (event.target.innerHTML !== this.props.value) {
      this.props.onEdit(this.props.value, event.target.innerHTML.replace(/<(?:.|\n)*?>/gm, ''));
    }
    this.props.onBlur(event, '');

  }

  handleKeyDown(event) {
    if (keycode(event.which) === 'enter') { this.chip.blur(); }
  }

  render() {
    const { className, value, editable } = this.props;

    return <div
      ref={(r) => { this.chip = r; }}
      className={className}
      contentEditable={editable}
      // eslint-disable-next-line react/no-danger
      dangerouslySetInnerHTML={{ __html: value }}
      onClick={this.handleClick}
      onBlur={this.handleBlur}
      onKeyDown={this.handleKeyDown}
    />;

  }
})`
  ${props => {

    const {
      global,
      primary,
    } = props.theme;

    const cursor = props.active ?
      'text' :
      'pointer';

    return `
      ${global}
      font-size: 13px;

      cursor: ${cursor};
      color: ${primary};
      display: flex;
      outline: 0;
    `;
  }}
`;

export const ChipButton = styled<{ className?: string, onClick: (e: any) => void }>(({ className, onClick }) => {
  function handleClick(event) {
    onClick(event);
  }
  return <button type="button" onClick={handleClick} className={className}>
    <XIcon />
  </button>;
})`
  ${ () => {
    return `
      padding: 0 5px;
      line-height: 0;
      margin-left: 13px;
      border: 0;

      background-color: transparent;
      cursor: pointer;
      outline: none;

      > i {
        width: 10px;
        height: 10px;
      }
    `;
  }}
`;

interface IChipProps {
  value: string;
  className?: string;
  onChipClick: (e: any) => void;
  onBlur: (e: any, v: string) => void;
  onDelete: (v: string) => void;
  onEdit: (v: string, nv: string) => string;
  id?: string;
  activeId: string;
  valueKey: string;
  editable: boolean;
  maxLength: string;
}

export const Chip = styled<IChipProps>(({
  value,
  className,
  onChipClick,
  onBlur,
  onDelete,
  onEdit,
  id,
  activeId,
  valueKey,
  editable,
}) => {

  function handleDelete(e) {
    if (!activeId || activeId === id) {
      onDelete(value);
    }
  }

  function handlePrventOpening(e) {
    e.stopPropagation();
  }

  return <div
    className={className}
    onMouseDown={handlePrventOpening}
  >
    <ChipBody
      editable={editable && activeId === id}
      onBlur={onBlur}
      active={!!onChipClick && activeId === id}
      id={id}
      onChipClick={onChipClick}
      onEdit={onEdit}
      value={value[valueKey || 'value']}
    />
    <ChipButton onClick={handleDelete} />
  </div>;
})`
  ${props => {

    const {
      global,
      lighter,
      active,
    } = props.theme;

    const borderColor = (props.activeId === props.id) ?
      active :
      lighter;

    return `
      ${global}
      display: flex;
      padding: 5px 3px 5px 10px;
      border: 1px solid ${borderColor};
      align-items: center;
      margin: 0 10px 10px 0;
    `;
  }}
`;

interface IChipValue {
  segment: string;
  label: string;
}

interface ISelectDelegatorProps {
  loadOnFocus?: boolean;
  value: IChipValue[];
  loadOptions?: (value, cb: (e: any, data: { options: IChipValue[] }) => void) => void;
  searchable?: boolean;
  creatable?: boolean;
  async?: boolean;
  setSelectRef?: (ref: any) => void;
  splitNewOption?: boolean;
  disabled?: boolean;

  backspaceRemoves?: boolean;
  deleteRemoves: boolean;
  arrowRenderer: boolean | null;
  promptTextCreator: (v: string) => string;
  clearable: boolean;
  valueKey: string;
  onChange: (v: IChipValue[]) => void;
  onInputChange: (input: string) => void;
  noResultsText?: string;
  onInputKeyDown: (e: any) => void;
  valueComponent: (props: any) => any;
  placeholder?: string;
  inputProps?: { [name: string]: any };
  isValidNewOption?: (value: { label: string }) => boolean;
}

interface ISelectDelegatorState {
  optionsLoaded: boolean;
  isLoading: boolean;
  options: IChipValue[];
}

class SelectDelegator extends React.Component<ISelectDelegatorProps, ISelectDelegatorState> {
  static propTypes = {
    loadOnFocus: PropTypes.bool,
    loadOptions: PropTypes.func,
    onChange: PropTypes.func,
    searchable: PropTypes.bool,
  };

  static defaultProps = {
    searchable: true,
  };

  constructor(props) {
    super(props);

    if (props.loadOnFocus) {
      this.state = {
        optionsLoaded: false,
        options: [],
        isLoading: false
      };
    }

    this.onNewOptionClick = this.onNewOptionClick.bind(this);
  }

  onNewOptionClick(option) {
    let segments = option.segment.split(/[\s\t\n]+/).filter(segment => (segment.length > 0));
    if (segments.length === 1) {
      this.props.onChange((this.props.value || []).concat({ ...option, label: option.label.trim(), segment: option.label.trim() }));
    } else if (segments.length > 1) {
      const segmentsMap = (this.props.value || []).reduce((ac, seg) => { ac[seg.segment] = true; return ac; }, {});
      segments = uniq(segments);
      const newOptions = segments.filter(segment => !segmentsMap[segment]).map(segment => ({ ...option, label: segment, segment }));
      this.props.onChange((this.props.value || []).concat(newOptions));
    }
  }

  renderSelectComponent = ({
    creatable,
    async,
    setSelectRef,
    splitNewOption,
    ...restProps
  }) => {

    if (creatable && async) {
      // @ts-expect-error change
      return <AsyncCreatable {...restProps} />;
    } else if (async) {
      // @ts-expect-error change
      return setSelectRef ? <Async ref={setSelectRef} {...restProps} /> : <Async {...restProps} />;
    } else if (creatable) {
      if (splitNewOption) {
        restProps.onNewOptionClick = this.onNewOptionClick;
      }
      return <Creatable {...restProps} />;
    } else {
      return <OriginalSelect {...restProps} />;
    }
  };

  unRequiredLoadOptions = (event) => {
    if (!this.state.optionsLoaded) {
      this.setState({ isLoading: true });

      this.props.loadOptions(event.target.value, (_, data) => {
        this.setState({
          optionsLoaded: true,
          options: data.options,
          isLoading: false,
        });
      });
    }
  };

  handleInputChange = (value) => {
    if (value) {
      this.setState({ isLoading: true });
      this.props.loadOptions(value, (_, data) => {
        this.setState({
          optionsLoaded: true,
          options: data.options,
          isLoading: false,
        });
      });
    }
    return value;
  };

  resetSelectState = (event) => {
    this.setState({
      optionsLoaded: false,
      isLoading: false,
    });
  };

  render() {
    return <Fragment>
      {
        this.props.loadOnFocus ?
          // @ts-expect-error change
          this.renderSelectComponent({
            ...this.props,
            onFocus: this.unRequiredLoadOptions,
            onBlur: this.resetSelectState,
            onInputChange: this.handleInputChange,
            searchable: this.props.searchable,
            onBlurResetsInput: false,
            onCloseResetsInput: false,
            autoload: false,
            isLoading: this.state && this.state.isLoading,
            options: this.state && this.state.options,
          }) :
          // @ts-expect-error change
          this.renderSelectComponent({
            ...this.props,
            onBlurResetsInput: false,
          })
      }
    </Fragment>;
  }
}

interface IChipsProps {
  splitNewOption?: boolean;
  addChipText?: string;
  value: IChipValue[];
  editable: boolean;
  prohibitKeys: string[];
  onChange: (value: IChipValue[]) => void;
  onDelete: (value: IChipValue) => void;
  onEdit: (oldValue: IChipValue, newValue: IChipValue) => void;
  className?: string;
  label?: string;
  error?: string;
  required?: boolean;
  compact?: boolean;
  placeholder?: string;
  valueKey: string;
  disabled?: boolean;
  id?: string;
  maxChipLength?: number;
}

interface IChipsState {
  disabled: boolean;
  activeId: string;
  noResultsText: string;
}

export default styled(class extends React.Component<IChipsProps, IChipsState> {

  valueKey: string;
  previousInput: string | undefined;

  static propTypes = {
    className: PropTypes.string,
    label: PropTypes.string,
    error: PropTypes.string,
    required: PropTypes.bool,
    compact: PropTypes.bool,
    valueKey: PropTypes.string,
    addChipText: PropTypes.string,
    onChange: PropTypes.func,
    onDelete: PropTypes.func,
    onEdit: PropTypes.func,
    value: PropTypes.array,
    placeholder: PropTypes.string,
    prohibitKeys: PropTypes.array,
    noResultsText: PropTypes.string,
    disabled: PropTypes.bool,
    editable: PropTypes.bool,
    splitNewOption: PropTypes.bool,
  };

  static defaultProps = {
    placeholder: '',
    addChipText: 'add',
    valueKey: 'value',
    multi: true,
    creatable: true,
    editable: true,
  };

  constructor(props) {
    super(props);

    this.state = {
      disabled: false,
      activeId: '',
      noResultsText: 'Start typing',
    };

    this.valueKey = props.valueKey;

    this.createPromptText = this.createPromptText.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
    this.handleEdit = this.handleEdit.bind(this);
    this.handleChipClick = this.handleChipClick.bind(this);
    this.handleChipBlur = this.handleChipBlur.bind(this);
    this.handleInputKeyDown = this.handleInputKeyDown.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.isValidNewOption = this.isValidNewOption.bind(this);

  }

  createPromptText(value) {
    if (this.props.splitNewOption) {
      let segments = value.split(/[\s\t\n]+/).filter(segment => (segment.length > 0));
      if (segments.length === 1) {
        return `${this.props.addChipText} "${segments[0]}"`;
      } else if (segments.length > 1) {
        const segmentsMap = this.props.value.reduce((ac, seg) => { ac[seg.segment] = true; return ac; }, {});
        segments = uniq(segments);
        const newOptions = segments.filter(segment => !segmentsMap[segment]).map(segment => `"${segment}"`);
        if (newOptions.length) {
          return `${this.props.addChipText} ${newOptions.join(', ')}`;
        } else {
          return 'Duplicate entry - try again';
        }
      } else {
        return 'Duplicate entry - try again';
      }
    } else {
      return `${this.props.addChipText} "${value}"`;
    }

  }

  handleChipClick(event, activeId) {
    if (this.props.editable) {
      if (!this.state.disabled) {
        this.setState({
          disabled: true,
          activeId,
        });
      }
    }
  }

  handleChipBlur(event, activeId) {
    if (
      this.props.editable &&
      this.state.disabled &&
      this.isValidNewOption({ label: event.target.innerText })
    ) {
      this.setState({
        disabled: false,
        activeId,
      });
    }
  }

  handleChange(values) {
    if (this.props.onChange) {
      const cleanedValues = values.map(value => {
        const cleanedValue = {};
        cleanedValue[this.valueKey] = value[this.valueKey];
        return cleanedValue;
      });

      this.props.onChange(cleanedValues);
    }
  }

  handleInputKeyDown(e) {
    if (this.props.prohibitKeys && this.props.prohibitKeys.includes(keycode(e.which))) {
      e.preventDefault();
    }
  }

  handleDelete(value) {
    if (this.props.onDelete) {
      this.props.onDelete(value);
    }
  }

  handleEdit(oldVlaue, newValue) {
    if (this.props.onEdit) {

      const isValueSizeCorrect = this.props.maxChipLength ?
        newValue.length <= this.props.maxChipLength :
        true;

      if (isValueSizeCorrect) {
        this.props.onEdit(oldVlaue, newValue);
      }
    }
  }

  handleInputChange(value) {
    if (!value || !value.trim()) {
      this.setState({ noResultsText: 'Start typing' });
    } else if (this.props.maxChipLength && value.length > this.props.maxChipLength) {
      const segments = value.split((/[\s\t\n]+/));
      if (segments.some(segment => segment.length > this.props.maxChipLength)) {
        this.setState({ noResultsText: 'Chip is too long' });
      }
    } else if (Array.isArray(this.props.value) && this.props.value.some(v => v.label === value)) {
      this.setState({ noResultsText: 'Duplicate Entry - Try Again' });
    } else if (!this.previousInput) {
      this.setState({ noResultsText: 'Not found' });
    }

    this.previousInput = value;

    return value;
  }

  isValidNewOption(value: { label: string }): boolean {
    if (!value || !value.label || !value.label.trim()) {
      return false;
    }
    if (this.props.maxChipLength && value.label.length > this.props.maxChipLength) {
      const segments = value.label.split((/[\s\t\n]+/));
      if (segments.some(segment => segment.length > this.props.maxChipLength)) {
        return false;
      }
    }
    return true;
  }

  render() {
    const {
      className,
      label,
      error,
      required,
      compact,
      placeholder,
      value,
      valueKey,
      ...selectProps
    } = this.props;

    return <div className={className}>
      {
        label &&
        <Label
          error={error}
          htmlFor={selectProps.id ? selectProps.id : null}
          compact={compact}
        >
          {`${label} ${required ? ' * ' : ''}`}
        </Label>
      }
      <SelectDelegator
        disabled={this.state.disabled || this.props.disabled}
        backspaceRemoves={false}
        deleteRemoves={false}
        arrowRenderer={null}
        promptTextCreator={this.createPromptText}
        clearable={false}
        value={value}
        valueKey={valueKey}
        onChange={this.handleChange}
        onInputChange={this.handleInputChange}
        noResultsText={this.state.noResultsText}
        onInputKeyDown={this.handleInputKeyDown}
        isValidNewOption={this.props.maxChipLength ? this.isValidNewOption : undefined}
        valueComponent={props =>
          <Chip
            editable={this.props.editable}
            onChipClick={this.handleChipClick}
            activeId={this.state.activeId}
            onBlur={this.handleChipBlur}
            onDelete={this.handleDelete}
            onEdit={this.handleEdit}
            valueKey={valueKey}
            maxLength={this.props.maxChipLength}
            {...props}
          />
        }

        {...selectProps}
        placeholder={placeholder}
        inputProps={{ placeholder }}
      />
      <Error>{error}</Error>
    </div>;

  }

})`
  ${({ theme, ...props }) => {

    const {
      global,
      main,
      primary,
      primaryRGB,
      fs_md,
      dark,
      defaultRGB,
      light,
      warning,
    } = theme;

    const width = props.width ?
      `${props.width}px` :
      '100%';

    const display = props.compact ?
      'flex' :
      'block';

    const margin = props.compact ?
      '0' :
      '7px 0 3px';

    const borderColor = props.error ?
      warning :
      light;

    return `
      ${global}
      display: ${display};
      width: ${width};

      .Select{
        display: block;
        box-sizing: content-box;
        flex: 1 1 100%;
        margin: ${margin};
      }

      .Select-input{
        height: 30px;
        min-width: 100px;
        margin: 0 0 10px;
        font-size: ${fs_md};

        input{
          ${global}
          ::-webkit-input-placeholder {
            color: ${dark};
          }
          ::-moz-placeholder { 
            color: ${dark};
          }
          :-ms-input-placeholder { 
            color: ${dark};
          }
          :-moz-placeholder { 
            color: ${dark};
          }
        }

      }

      .Select-placeholder{
        display: none;
      }

      .Select-control{
        border-top: 0;
        border-left: 0;
        border-right: 0;
        border-bottom-width: 1px;
        border-radius: 0px;
        height: auto;
        padding: 0 0 0px;
        border-color: ${borderColor};
      }

      .Select.is-disabled {
        > .Select-control {
          background-color: ${main};
        }
      } 

      .Select.is-focused:not(.is-open) {
        > .Select-control{
          border-color: rgba(${defaultRGB},0.5);
          box-shadow: none;
        }
      } 

      .Select--multi {
        .Select-multi-value-wrapper {
          width: 100%;

          flex-wrap: wrap;
          display: inline-flex;
        }
      } 

      .Select-menu-outer {
        border-radiuse: 0;
        font-size: 13px;

        color: ${main};
        border-color: ${primary};
        background: rgba(${primaryRGB}, 0.8);
      }

      .Select-option {
        color: ${main};
        &.is-focused {
          color: ${main};
        }
      }

      .Select-menu-outer{
        border-radius: 0;
        
        border-width: 1px;
        margin-top: -1px;
        max-height: 700px;

        border-color: ${borderColor};
        
        background: ${main};

        .Select-menu{
          padding: 8px 0;
          max-height: 300px;
          .Select-option{
            font-size: 13px;
            color: ${primary};
            background: ${main};

            &:hover{
              background: ${main};
              color: ${theme.default};            
            }
            
            &.is-selected{
              background: ${main};
              color: ${theme.default};   
            }
            
            &.is-disabled {
              background: ${main};
              color: ${theme.dark};
            }
  
          }
        }

      }

    `;
  }}
`;

// Auto generated permissions constants. Do not edit this file! 

export const ADMINISTRATION = 'Administration';
export const ADMINISTRATION_USERS = 'Administration.Users';
export const ADMINISTRATION_USERS_CREATE = 'Administration.Users.Create';
export const ADMINISTRATION_USERS_UPDATE = 'Administration.Users.Update';
export const ADMINISTRATION_USERS_DELETE = 'Administration.Users.Delete';
export const ADMINISTRATION_USERS_PERMISSIONS = 'Administration.Users.Permissions';
export const ADMINISTRATION_USERS_PERMISSIONS_EDIT = 'Administration.Users.Permissions.Edit';
export const ADMINISTRATION_USERS_ROLES = 'Administration.Users.Roles';
export const ADMINISTRATION_USERS_ROLES_EDIT = 'Administration.Users.Roles.Edit';
export const ADMINISTRATION_USERS_SUPERVISORS = 'Administration.Users.Supervisors';
export const ADMINISTRATION_USERS_SUPERVISORS_EDIT = 'Administration.Users.Supervisors.Edit';
export const ADMINISTRATION_USERS_INACTIVE = 'Administration.Users.Inactive';
export const ADMINISTRATION_USERS_INACTIVE_REACTIVATE = 'Administration.Users.Inactive.Reactivate';
export const ADMINISTRATION_ROLES = 'Administration.Roles';
export const ADMINISTRATION_ROLES_CREATE = 'Administration.Roles.Create';
export const ADMINISTRATION_ROLES_UPDATE = 'Administration.Roles.Update';
export const ADMINISTRATION_ROLES_DELETE = 'Administration.Roles.Delete';
export const ADMINISTRATION_CLIENTS = 'Administration.Clients';
export const ADMINISTRATION_CLIENTS_CREATE = 'Administration.Clients.Create';
export const ADMINISTRATION_CLIENTS_EDIT = 'Administration.Clients.Edit';
export const ADMINISTRATION_CLIENTS_DIVISIONS = 'Administration.Clients.Divisions';
export const ADMINISTRATION_CLIENTS_DIVISION_CREATE = 'Administration.Clients.Division.Create';
export const ADMINISTRATION_CLIENTS_DIVISIONS_EDIT = 'Administration.Clients.Divisions.Edit';
export const ADMINISTRATION_CLIENTS_DIVISIONS_LOCATIONS = 'Administration.Clients.Divisions.Locations';
export const ADMINISTRATION_CLIENTS_DIVISIONS_LOCATIONS_CREATE = 'Administration.Clients.Divisions.Locations.Create';
export const ADMINISTRATION_CLIENTS_DIVISIONS_LOCATIONS_EDIT = 'Administration.Clients.Divisions.Locations.Edit';
export const ADMINISTRATION_CLIENTS_DIVISIONS_LOCATIONS_DELETE = 'Administration.Clients.Divisions.Locations.Delete';
export const ADMINISTRATION_CLIENTS_DIVISIONS_DELETE = 'Administration.Clients.Divisions.Delete';
export const ADMINISTRATION_CLIENTS_DELETE = 'Administration.Clients.Delete';
export const ADMINISTRATION_CLIENTCATALOGUE = 'Administration.ClientCatalogue';
export const ADMINISTRATION_CLIENTCATALOGUE_CREATE = 'Administration.ClientCatalogue.Create';
export const ADMINISTRATION_CLIENTCATALOGUE_EDIT = 'Administration.ClientCatalogue.Edit';
export const ADMINISTRATION_CLIENTCATALOGUE_DELETE = 'Administration.ClientCatalogue.Delete';
export const ADMINISTRATION_VENDORS = 'Administration.Vendors';
export const ADMINISTRATION_VENDORS_CREATE = 'Administration.Vendors.Create';
export const ADMINISTRATION_VENDORS_EDIT = 'Administration.Vendors.Edit';
export const ADMINISTRATION_VENDORS_ARCHIVE = 'Administration.Vendors.Archive';
export const ADMINISTRATION_VENDORS_ARCHIVED = 'Administration.Vendors.Archived';
export const ADMINISTRATION_VENDORS_ARCHIVED_UNARCHIVE = 'Administration.Vendors.Archived.Unarchive';
export const CLIENTCATALOGUE = 'ClientCatalogue';
export const MASTERCATALOGUE = 'MasterCatalogue';
export const MASTERCATALOGUE_MANAGE = 'MasterCatalogue.Manage';
export const MASTERCATALOGUE_PRODUCTS = 'MasterCatalogue.Products';
export const MASTERCATALOGUE_PRODUCTS_CREATE = 'MasterCatalogue.Products.Create';
export const MASTERCATALOGUE_PRODUCTS_EDIT = 'MasterCatalogue.Products.Edit';
export const MASTERCATALOGUE_PRODUCTS_ARCHIVE = 'MasterCatalogue.Products.Archive';
export const MASTERCATALOGUE_PRODUCTS_UNARCHIVE = 'MasterCatalogue.Products.Unarchive';
export const MASTERCATALOGUE_CATEGORY_ADD = 'MasterCatalogue.Category.Add';
export const MASTERCATALOGUE_CATEGORY_DELETE = 'MasterCatalogue.Category.Delete';
export const ORDERS = 'Orders';
export const ORDERS_SUBMIT = 'Orders.Submit';
export const ORDERS_MYORDERS = 'Orders.MyOrders';
export const ORDERS_BUYERORDERS = 'Orders.BuyerOrders';
export const ORDERS_BUYERORDERS_SUBMITORAPPROVE = 'Orders.BuyerOrders.SubmitOrApprove';
export const ORDERS_BUYERORDERS_EDIT = 'Orders.BuyerOrders.Edit';
export const ORDERS_BUYERORDERS_CANCEL = 'Orders.BuyerOrders.Cancel';
export const ORDERS_ALLORDERS = 'Orders.AllOrders';
export const ORDERS_ALLORDERS_SUBMITORAPPROVE = 'Orders.AllOrders.SubmitOrApprove';
export const ORDERS_ALLORDERS_EDIT = 'Orders.AllOrders.Edit';
export const ORDERS_ALLORDERS_CANCEL = 'Orders.AllOrders.Cancel';
export const ORDERS_UNCOMPARED = 'Orders.Uncompared';
export const ORDERS_UNCOMPARED_EDIT = 'Orders.Uncompared.Edit';
export const ORDERS_UNCOMPARED_APPROVE = 'Orders.Uncompared.Approve';
export const PACKINGSLIPS = 'PackingSlips';
export const COMPANYPACKINGSLIPS = 'CompanyPackingSlips';
export const COMPANYPACKINGSLIPS_EDITORRECONCILE = 'CompanyPackingSlips.EditOrReconcile';
export const CLIENTINVOICES = 'ClientInvoices';
export const CLIENTINVOICES_EDIT = 'ClientInvoices.Edit';
export const CLIENTINVOICES_INCLUDENOTEAMOUNT = 'ClientInvoices.IncludeNoteAmount';
export const CLIENTINVOICES_SEND = 'ClientInvoices.Send';
export const CLIENTINVOICES_EXPORT_EXCEL = 'ClientInvoices.Export.Excel';
export const INVOICES = 'Invoices';
export const INVOICES_EXPORT_EXCEL = 'Invoices.Export.Excel';
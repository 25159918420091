import React from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { arrayMove } from 'react-sortable-hoc';
import { createStructuredSelector } from 'reselect/es';

import { UserSupervisorDto } from '../../../service-proxies';

import * as actions from '../../../_actions/users';
import * as actionsModal from '../../../_actions/modal';
import * as userApi from '../../../_api/user';
import {
  FormikState,
  FormikActions,
  FormikHandlers,
} from 'formik';

import {
  FormGrid as Grid,
  SkeletonGroup,
  Select,
} from '../../../components';

import {
  IValues,
} from './types';

import { inspectBranch } from "../../../_utils/data";

import SupervisorsList from './Fields/SupervisorsList';

const Position = {
  Container: styled.div``,

  WhiteSpace: styled.div`
    height: 30px;
  `,
};

interface IEditUserSupervisorsProps extends FormikState<IValues>, FormikActions<IValues>, FormikHandlers {
  alert: typeof actionsModal.alert;
  getUserSupervisors: typeof actions.getUserSupervisors;
  isLoading?: boolean;
  disabled: boolean;
  isNew: boolean;
  userSupervisors: UserSupervisorDto[];
}

class EditUserSupervisors extends React.Component<IEditUserSupervisorsProps> {

  constructor(props) {
    super(props);

    this.handleSort = this.handleSort.bind(this);
    this.handleAdd = this.handleAdd.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
    this.handleItemChange = this.handleItemChange.bind(this);
    this.reorganizeValues = this.reorganizeValues.bind(this);
    this.validateArray = this.validateArray.bind(this);
    this.validateItem = this.validateItem.bind(this);
    this.loadUsers = this.loadUsers.bind(this);
    this.loadLocations = this.loadLocations.bind(this);
    this.handleLocationSelect = this.handleLocationSelect.bind(this);
    this.isSupervisorsValid = this.isSupervisorsValid.bind(this);
  }

  componentDidMount() {
    if (!this.props.values.isAllSupervisorsLoaded) {
      if (!this.props.values.userSupervisorsMap) {
        if (this.props.isNew) {
          this.props.setFieldValue('userSupervisorsMap', {});
          this.props.setFieldValue('isAllSupervisorsLoaded', true);
        } else {
          // @ts-expect-error changed
          this.props.getUserSupervisors(this.props.values.id).then(supervisors => {
            const userSupervisorsMap = {};
            supervisors.forEach(s => {
              if (!userSupervisorsMap[s.locationId]) {
                userSupervisorsMap[s.locationId] = [];
              }
              userSupervisorsMap[s.locationId].push(s);
            });

            for (const key in userSupervisorsMap) {
              if (Object.prototype.hasOwnProperty.call(userSupervisorsMap, key)) {
                userSupervisorsMap[key] = this.reorganizeValues(userSupervisorsMap[key]);
              }
            }

            this.props.setFieldValue('userSupervisorsMap', userSupervisorsMap);
            this.props.setFieldValue('isAllSupervisorsLoaded', true);
          });
        }
      }
    }
  }

  loadUsers(text, callback) {
    const locationId = this.props.values.location.id;

    userApi.getUsers(locationId, text).then(data => {
      const options = data.items.filter((user) => {
        return (user.id !== this.props.values.id);
      });

      callback(null, {
        options,
        complete: true,
      });
    });
  }

  loadLocations(value, callback) {
    const clientUnits = this.props.values.clientUnits;
    const clientUnitIds = this.props.values.clientUnitIds;
    const locations = [];

    inspectBranch(clientUnits, clientUnit => {

      if (clientUnitIds.includes(clientUnit.id) || (clientUnit.parent && clientUnit.parent.fillInside)) {
        clientUnit.fillInside = true;
      }

      if (clientUnit && (clientUnit.type === 2 || clientUnit.type === 3) && (clientUnit.fillInside || clientUnit.parent.fillInside)) {
        locations.push({
          id: clientUnit.id,
          displayValue: clientUnit.name,
        });
      }
    }, 'children');

    callback(null, {
      options: locations.filter(l => l.displayValue.includes(value ? value : '')),
      complete: true,
    });
  }

  handleLocationSelect(data) {
    if (this.isSupervisorsValid(this.props.values.userSupervisorsMap)) {
      this.props.setFieldValue('location', data);
    } else {
      this.props.alert("Please resolve the validation errors");
    }
  }

  handleSort({ oldIndex, newIndex }) {
    const locationId = this.props.values.location.id;
    const newSupervisorsMap = { ...this.props.values.userSupervisorsMap };

    newSupervisorsMap[locationId] = arrayMove(newSupervisorsMap[locationId], oldIndex, newIndex);

    newSupervisorsMap[locationId] = this.reorganizeValues(newSupervisorsMap[locationId]);
    this.validateArray(newSupervisorsMap[locationId]);

    this.props.setFieldValue('userSupervisorsMap', newSupervisorsMap);
  }

  handleAdd() {
    if (this.props.values.location && this.props.values.location.id) {
      const locationId = this.props.values.location.id;
      let newSupervisorsMap = {};

      if (this.props.values.userSupervisorsMap) {
        newSupervisorsMap = { ...this.props.values.userSupervisorsMap };
      }

      if (!newSupervisorsMap[locationId]) {
        newSupervisorsMap[locationId] = [];
      }

      let pushUnderUnlimited = false;
      if (newSupervisorsMap[locationId].some((val) => val.unlimited)) {
        pushUnderUnlimited = true;
      }

      newSupervisorsMap[locationId].push({
        order: null,
        supervisorId: null,
        supervisorName: null,
        approvalLimit: undefined,
        unlimited: false,
        locationId,
      });

      if (pushUnderUnlimited) {
        newSupervisorsMap[locationId] = arrayMove(
          newSupervisorsMap[locationId], newSupervisorsMap[locationId].length - 1, newSupervisorsMap[locationId].length - 2
        );
      }

      newSupervisorsMap[locationId] = this.reorganizeValues(newSupervisorsMap[locationId]);
      this.validateArray(newSupervisorsMap[locationId]);

      this.props.setFieldValue('userSupervisorsMap', newSupervisorsMap);
    }
  }

  handleItemChange(item, index) {
    const newSupervisorsMap = { ...this.props.values.userSupervisorsMap };
    const locationId = this.props.values.location.id;

    newSupervisorsMap[locationId][index] = item;

    if (newSupervisorsMap[locationId][index].unlimited
      && (newSupervisorsMap[locationId].filter(s => s.unlimited).length <= 1)
      && index !== newSupervisorsMap[locationId].length - 1) {

      newSupervisorsMap[locationId] = arrayMove(newSupervisorsMap[locationId], index, newSupervisorsMap[locationId].length - 1);
    }

    newSupervisorsMap[locationId] = this.reorganizeValues(newSupervisorsMap[locationId]);
    this.validateArray(newSupervisorsMap[locationId]);

    this.props.setFieldValue('userSupervisorsMap', newSupervisorsMap);
  }

  handleDelete(index) {
    const newSupervisorsMap = { ...this.props.values.userSupervisorsMap };
    const locationId = this.props.values.location.id;

    newSupervisorsMap[locationId].splice(index, 1);
    newSupervisorsMap[locationId] = this.reorganizeValues(newSupervisorsMap[locationId]);
    this.validateArray(newSupervisorsMap[locationId]);

    this.props.setFieldValue('userSupervisorsMap', newSupervisorsMap);
  }

  reorganizeValues(array: UserSupervisorDto[]): UserSupervisorDto[] {
    let newArray = [];

    if (array && array.length) {
      newArray = [...array];

      newArray.forEach((item, index) => {
        item.order = index + 1;

        if (this.props.isNew) {
          item.userId = 0;
        } else {
          item.userId = this.props.values.id;
        }

        if (!item.approvalLimit && item.approvalLimit !== 0) { item.approvalLimit = 0; }
      });
    }

    return array;
  }

  validateArray(array) {
    array.forEach((value) => {
      value.error = '';
    });

    array.forEach((value, index) => {
      this.validateItem(value, array, index);
    });
  }

  validateItem(suspect, values, index) {
    if (suspect.unlimited && values.some((val) => val.unlimited && val.supervisorId !== suspect.supervisorId)) {
      suspect.error += 'There can be only one unlimited supervisor; ';
    }

    if (values.filter((val) => val.supervisorId === suspect.supervisorId).length > 1) {
      suspect.error += 'Please do not duplicate supervisors; ';
    }

    if (!suspect.supervisorId) {
      suspect.error += 'Buying Manager field is required; ';
    }

    if (suspect.unlimited && suspect.order !== values.length) {
      suspect.error += 'Unlimited supervisor should be at the end of the list; ';
    }

    if (!suspect.approvalLimit && !suspect.unlimited) {
      suspect.error += 'Approval limit is required; ';
    }

    if (values[index - 1]
      && !suspect.unlimited
      && suspect.approvalLimit
      && parseInt(values[index - 1].approvalLimit, 10) > parseInt(suspect.approvalLimit, 10)) {

      suspect.error += 'Approval limit must be greater than previous tier limit; ';
    }
  }

  isSupervisorsValid(map) {
    let isValid = true;

    for (const key in map) {
      if (Object.prototype.hasOwnProperty.call(map, key)) {
        map[key].forEach((item) => {
          if (item.error) { isValid = false; }
        });
      }
    }

    return isValid;
  }

  render() {
    const locationId = this.props.values.location ? this.props.values.location.id : 0;
    const supervisors: UserSupervisorDto[] = this.props.values.userSupervisorsMap && this.props.values.userSupervisorsMap[locationId]
      ? this.props.values.userSupervisorsMap[locationId] : [];

    return (
      <Grid.Body>
        <Grid.Row>
          <Grid.Cell>
            <Select
              async
              loadOnFocus
              autoload={false}
              clearable={false}
              label="Location"
              name="location"
              placeholder="Select location"
              labelKey="displayValue"
              valueKey="id"
              value={this.props.values.location}
              loadOptions={this.loadLocations}
              onChange={this.handleLocationSelect}
              compact
            />
          </Grid.Cell>
        </Grid.Row>
        <Position.WhiteSpace />
        <Grid.Row>
          <Grid.Cell>
            {
              this.props.values.isAllSupervisorsLoaded ?
                <SupervisorsList
                  label="Buying Manager"
                  disabled={this.props.disabled}
                  values={supervisors}
                  onSort={this.handleSort}
                  onAdd={this.handleAdd}
                  onDelete={this.handleDelete}
                  onItemChange={this.handleItemChange}
                  loadUsers={this.loadUsers}
                /> :
                <Grid.Row>
                  <Grid.Cell>
                    <SkeletonGroup rows={4} size="md" />
                  </Grid.Cell>
                  <Grid.Cell>
                    <SkeletonGroup rows={4} size="md" />
                  </Grid.Cell>
                  <Grid.Cell>
                    <SkeletonGroup rows={4} size="md" />
                  </Grid.Cell>
                </Grid.Row>
            }
          </Grid.Cell>
        </Grid.Row>
      </Grid.Body>
    );
  }
}

export default connect(
  createStructuredSelector({
    userSupervisors: state => (state.users.user && state.users.user.supervisors ? state.users.user.supervisors : []),
  }),
  {
    alert: actionsModal.alert,
    getUserSupervisors: actions.getUserSupervisors,
  }
)(EditUserSupervisors);

import * as React from 'react';
import EditableBlock from './EditableBlock';

const inputFilter = (v: string) => v;

const outputFilter = (v: string) => v;

interface IGLCodeField {
    id: number;
    onChange: (id: number, newCode: string) => void;
    value: string;
}

class GLCodeField extends React.Component<IGLCodeField> {
    constructor(props) {
        super(props);
        this.handleCodeChange = this.handleCodeChange.bind(this);
    }

    handleCodeChange(newCode) {
        if (this.props.onChange) {
            this.props.onChange(this.props.id, newCode ? newCode : '');
        }
    }

    render() {
        return <EditableBlock
            onChange={this.handleCodeChange}
            exitEditOnBlur
            editOnClick
            inputFilter={inputFilter}
            outputFilter={outputFilter}
            alignment="left"
            id={this.props.id}
            value={this.props.value}
        />;
    }
}

export default GLCodeField;

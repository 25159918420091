import React from 'react';
import styled from 'styled-components';

export default styled(props => {
  return (
    <i {...props}>
      <svg viewBox="0 0 20 22" width="20" height="22">
        <path d=" M 10 0 C 6.145 0 3 3.145 3 7 C 3 9.41 4.23 11.551 6.094 12.813 C 2.527 14.344 0 17.883 0 22 C 0 22 2 22 2 22 C 2 19.109 3.527 16.594 5.813 15.188 C 6.484 16.836 8.121 18 10 18 C 11.879 18 13.516 16.836 14.188 15.188 C 16.473 16.594 18 19.109 18 22 C 18 22 20 22 20 22 C 20 17.883 17.473 14.344 13.906 12.813 C 15.77 11.551 17 9.41 17 7 C 17 3.145 13.855 0 10 0 Z  M 10 2 C 12.773 2 15 4.227 15 7 C 15 9.773 12.773 12 10 12 C 7.227 12 5 9.773 5 7 C 5 4.227 7.227 2 10 2 Z  M 10 14 C 10.82 14 11.602 14.117 12.344 14.344 C 11.996 15.309 11.09 16 10 16 C 8.91 16 8.004 15.309 7.656 14.344 C 8.398 14.117 9.18 14 10 14 Z " />
      </svg>
    </i>
  );
}) `
  ${ props => `
    width: 21px;
    height: 21px;
    display: inline-flex;
    justify-content: center;
    align-items: center;

    svg{
      path{
        fill: ${props.theme.primary}; 
      }
    }
  `}
`;

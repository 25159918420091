import React from 'react';
import styled from 'styled-components';
import { Manager, Reference, Popper } from 'react-popper';
import PropTypes from 'prop-types';

function getSecondAxis(placement) {
  return placement.split('-')[0];
}

export const Arrow = styled.div`
  ${({ theme, ...props }) => {
    const {
      global,
      main,
      lighter,
    } = theme;

    const position = `${getSecondAxis(props.placement)}: -12px;`;

    return `
      ${global};
      width: 15px;
      height: 15px;
      overflow: hidden;
      &:before{
        content: '';
        width: 15px;
        height: 15px;
        background: ${main};
        border: 1px solid ${lighter};
        display: block;
        transform: rotate(45deg);
        position: relative;
        ${position}
      }
    `;
  }}
  
`;

export const BubbleContainer = styled.div`
  ${({ theme, ...props }) => {
    const {
      global,
      main,
      lighter,
    } = theme;

    const position = `${getSecondAxis(props.placement)}: -5px;`;

    return `
      ${global};
      background: ${main};
      box-shadow: 0 0 10px 0 rgba(0,0,0,0.10);
      padding: 20px;
      border: 1px solid ${lighter};
      position: relative;
      ${position}
    `;
  }}
`;

export default styled(class extends React.Component {

  static propTypes = {
    className: PropTypes.string,
    renderTarget: PropTypes.func.isRequired,
    renderBubble: PropTypes.func.isRequired,
    triggerActionName: PropTypes.string,
    isActive: PropTypes.bool,
    placement: PropTypes.string,
    positionFixed: PropTypes.bool,
  };

  static defaultProps = {
    triggerActionName: 'hover',
    isActive: false,
    placement: 'top-center'
  };

  constructor(props) {
    super(props);
    this.state = {
      active: props.isActive,
      outside: true,
    };
    this.handleClick = this.handleClick.bind(this);
    this.handleMouseEnter = this.handleMouseEnter.bind(this);
    this.handleMouseLeave = this.handleMouseLeave.bind(this);
    this.handleClickOutside = this.handleClickOutside.bind(this);

    this.arrowCustomStyles = {
      'zIndex': 1,
    };
    this.arrowCustomStyles[getSecondAxis(props.placement)] = 'calc(100% - 6px)';
  }

  handleClick() {
    document.addEventListener('mousedown', this.handleClickOutside, true);
    this.setState({ active: !this.state.active });
  }

  handleMouseEnter() {
    if (this.props.triggerActionName === 'hover') {
      if (!this.state.active) {
        this.setState({ active: true, outside: false });
      }
    } else if (this.props.triggerActionName === 'click') {
      this.setState({ outside: false });
    }
  }

  handleMouseLeave() {
    if (this.props.triggerActionName === 'hover') {
      if (this.state.active) {
        this.setState({ active: false, outside: true });
      }
    } else if (this.props.triggerActionName === 'click') {
      this.setState({ outside: true });
    }
  }

  handleClickOutside(e) {
    if (this.bubbleRef && !this.bubbleRef.contains(e.target)) {
      if (this.state.outside) {
        this.setState({ active: false });
      }
      document.removeEventListener('mousedown', this.handleClickOutside, true);
    }
  }

  render() {
    const {
      className,
      renderTarget,
      renderBubble,
      triggerActionName,
    } = this.props;

    const TargetElement = renderTarget;
    const TargetBubble = renderBubble;

    return <Manager>
      <Reference>
        {({ ref }) => (
          (triggerActionName === 'click') ?
            <TargetElement
              parentRef={ref}
              onMouseDown={this.handleClick}
              onMouseEnter={this.handleMouseEnter}
              onMouseLeave={this.handleMouseLeave}
            /> :
            <TargetElement
              parentRef={ref}
              onMouseEnter={this.handleMouseEnter}
              onMouseLeave={this.handleMouseLeave}
            />
        )}
      </Reference>
      {
        this.state.active &&
        <Popper
          placement={this.props.placement}
          positionFixed={this.props.positionFixed}
          modifiers={{
            keepTogether: { enabled: true },
          }}
        >
          {({ ref, style, placement, arrowProps }) => (
            <div
              className={className}
              ref={ref}
              style={style}
              data-placement={placement}
            >
              <BubbleContainer placement={this.props.placement}>
                <TargetBubble parentRef={el => { this.bubbleRef = el; }} />
              </BubbleContainer>
              <div
                ref={arrowProps.ref}
                style={{ ...arrowProps.style, position: 'absolute', ...this.arrowCustomStyles }}
              >
                <Arrow placement={this.props.placement} />
              </div>
            </div>
          )}
        </Popper>
      }
    </Manager>;
  }
})`
  box-sizing: border-box;
  z-index: 1;
`;

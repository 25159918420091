import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getAllPermissions } from '../../../_actions/roles';
import { getUserPermissions } from '../../../_actions/users';
import { createStructuredSelector } from 'reselect/es';

import {
  FormGrid as Grid,
  PermissionsTree,
  SkeletonGroup,
} from '../../../components';

import { setChildrenNodes } from '../../../_utils/permissions';

class EditUserPermissions extends React.Component {

  static propTypes = {
    values: PropTypes.object.isRequired,
    errors: PropTypes.object.isRequired,
    touched: PropTypes.object.isRequired,
    handleChange: PropTypes.func.isRequired,
    handleBlur: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    isSubmitting: PropTypes.bool.isRequired,
    setFieldValue: PropTypes.func.isRequired,
    setFieldError: PropTypes.func.isRequired,
    setFieldTouched: PropTypes.func.isRequired,
    isLoading: PropTypes.bool,
    disabled: PropTypes.bool,
    isNew: PropTypes.bool,
    getAllPermissions: PropTypes.func,
    getUserPermissions: PropTypes.func,
    permissionsTree: PropTypes.arrayOf(PropTypes.object),
  };

  constructor(props) {
    super(props);

    this.handlePermissionChanged = this.handlePermissionChanged.bind(this);
    this.handleCollapseNode = this.handleCollapseNode.bind(this);
  }

  componentDidMount() {
    if (!this.props.values.isAllPermissionsLoaded) {
      let promises = [this.props.getAllPermissions()];
      if (!this.props.values.permissionsMap) {
        promises.push(this.props.getUserPermissions(this.props.values.id).then(permissions => {
          this.props.setFieldValue('permissionsMap', permissions.reduce((ac, ce) => {
            ac[ce] = true;
            return ac;
          }, {}));
        }));
      }
      Promise.all(promises).then(() => {
        this.props.setFieldValue('isAllPermissionsLoaded', true);
      });
    }
  }

  handlePermissionChanged(permission, value, path) {
    let permissionsMap = { ...this.props.values.permissionsMap };

    if (!value) {
      delete permissionsMap[permission];
    }

    let currentLevel = this.props.permissionsTree;
    path.forEach(index => {
      if (value) { permissionsMap[currentLevel[index].name] = true; }
      currentLevel = currentLevel[index].children;
    });
    if (currentLevel && currentLevel.length) {
      setChildrenNodes(currentLevel, permissionsMap, value);
    }

    this.props.setFieldValue('permissionsMap', permissionsMap);
  }

  handleCollapseNode(permission, value) {
    let permissionsCollapsedMap = { ...this.props.values.permissionsCollapsedMap };
    if (value) {
      permissionsCollapsedMap[permission] = true;
    } else {
      delete permissionsCollapsedMap[permission];
    }
    this.props.setFieldValue('permissionsCollapsedMap', permissionsCollapsedMap);
  }

  render() {
    return (
      <Grid.Body>
        <Grid.Row>
          <Grid.Cell>
            {this.props.values.isAllPermissionsLoaded ?
              <PermissionsTree
                disabled={this.props.disabled}
                valuesMap={this.props.values.permissionsMap}
                collapsedMap={this.props.values.permissionsCollapsedMap}
                permissionsTree={this.props.permissionsTree}
                onChanged={this.handlePermissionChanged}
                onCollapseNode={this.handleCollapseNode}
              />
              :
              <SkeletonGroup rows={10} size="lg" />
            }
          </Grid.Cell>
        </Grid.Row>
      </Grid.Body>
    );
  }
}

export default connect(
  createStructuredSelector({
    permissionsTree: state => (state.roles.permissionsTree || [])
  }),
  {
    getAllPermissions,
    getUserPermissions,
  }
)(EditUserPermissions);

import React from 'react';
import styled from 'styled-components';

import {
  VendorAccountNumberOutput,
} from '../../../../service-proxies';

import {
  Select,
  Field,
} from '../../../../components';

import * as miscellaneousApi from '../../../../_api/miscellaneous';

interface IError {
  vendor?: string;
  accountNumber?: string;
}

interface IVendorListProps {
  className: string;
  value: VendorAccountNumberOutput;
  index: number;
  error?: IError | string;
  touched?: IError | string;
  onNameChange: (text: string, index: number) => void;
  onNumberChange: (text: string, index: number) => void;
  onBlur: (e: any) => any;
}

export default styled(class extends React.PureComponent<IVendorListProps> {
  constructor(props) {
    super(props);
    this.handleNameChange = this.handleNameChange.bind(this);
    this.handleNumberChange = this.handleNumberChange.bind(this);
    this.handleBlur = this.handleBlur.bind(this);
  }

  loadVendorNames(text, callback) {
    miscellaneousApi.searchVendors(text)
      .then(data => {
        const options = data.items;
        callback(null, {
          options,
          complete: true,
        });
      });
  }

  handleNameChange(text) {
    this.props.onNameChange(text, this.props.index);
  }

  handleNumberChange(e) {
    const value = e.target.value;
    this.props.onNumberChange(value, this.props.index);
  }

  handleBlur(e){
    this.props.onBlur(e);
  }

  render() {
    const {
      className,
      value,
      index,
    } = this.props;

    let vendorIdValidation = '';
    if (!!this.props.error && typeof this.props.error === 'string') {
      vendorIdValidation = this.props.error;
    }

    if (!!this.props.error && typeof this.props.error === 'object') {
      vendorIdValidation = this.props.error.vendor;
    }

    return <div className={className}>
      <Select
        compact
        async
        loadOnFocus
        autoload={false}
        name={`vendorAccountNumbers.${index}.vendor`}
        labelKey="displayValue"
        placeholder={'Name...'}
        valueKey="id"
        onChange={this.handleNameChange}
        value={value.vendor}
        loadOptions={this.loadVendorNames}
        error={vendorIdValidation}
        onBlur={this.props.onBlur}
      />
      {
        !!value.vendor &&
        !!value.vendor.id &&
        <Field
          compact
          value={value.accountNumber}
          onChange={this.handleNumberChange}
          name={`vendorAccountNumbers.${index}.accountNumber`}
          error={(!!this.props.error && typeof this.props.error !== 'string') ? this.props.error.accountNumber : ''}
          onBlur={this.props.onBlur}
        />
      }
    </div>;
  }
})`
  display: flex;
  ${Select}{
    flex: 0 0 158px;
    margin: 0 10px 0 0;
  }
`;
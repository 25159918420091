import React from 'react';
import styled from 'styled-components';
import 'react-dates/initialize';
import { DateRangePicker, SingleDatePicker } from 'react-dates';
import 'react-dates/lib/css/_datepicker.css';
import moment, { Moment } from 'moment';

import {
  CaretIcon,
  CalendarIcon,
} from '../assets/icons';
import Button from './Button';

const ArrowIcon = () => {
  return <div />;
};

const periodOptions = [
  { id: 'day', label: 'Day' },
  { id: 'week', label: 'Week' },
  { id: 'month', label: 'Month' },
  { id: 'year', label: 'Year' },
  { id: 'all_time', label: 'All Time' },
];

export const Label = styled<{ error?: string }, 'label'>('label')`
  ${ props => {

    const {
      global,
      warning,
      primary,
      fs_md,
    } = props.theme;

    const color = !props.error ?
      primary :
      warning;

    return `
        ${global}
        color: ${color};
        font-size: ${fs_md}; 
      `;
  }}
`;

export const Error = styled<{ compact?: boolean }, 'div'>('div')`
  ${ ({ theme, compact }) => {

    const {
      global,
      warning,
      fs_xs
    } = theme;

    const position = compact ?
      'absolute' :
      'static';

    return `
        ${global}
        position: ${position};
        min-height: 19px;
        margin: 0 0 3px;
        font-weight: 300;
        color: ${warning};
        font-size: ${fs_xs}; 
      `;
  }}
`;

export const Body = styled<{ size?: string, compact?: boolean, iconShow?: any }, 'div'>('div')`
  ${ ({ size, compact, iconShow }) => {

    let paddingIcon;
    switch (size) {
      case 'small':
      case 'large':
        paddingIcon = '50px';
        break;
      default:
        paddingIcon = '40px';
    }

    const margin = compact ?
      '0' :
      '7px 0 3px';

    return `
      position: relative;
      margin: ${margin};

      ${
      !!iconShow && `
        > .${iconShow.styledComponentId} {
          position: absolute;
          display: flex;
          justify-content: center;
          align-items: center;
          height: auto;
          width: ${paddingIcon};
          top: 0px;
          left: 0px;
          bottom: 0px;
          pointer-events: none;
        }`
      }

    `;
  }}
`;

function definePeriod(startDate: Moment, endDate: Moment): string {
  const duration = moment.duration(endDate.diff(startDate));
  if (Math.abs(duration.asDays() - 1) < 0.1) {
    return 'day';
  } else if (Math.abs(duration.asWeeks() - 1) < 0.01) {
    return 'week';
  } else if (Math.abs(duration.asMonths() - 1) < 0.05) {
    return 'month';
  } else if (Math.abs(duration.asYears() - 1) < 0.01) {
    return 'year';
  } else {
    return '';
  }
}

const DatePickerFooter = styled.div`
  border-top: 1px solid ${({ theme }) => theme.lighter};
  height: 38px;
  margin-top: -14px;
  padding: 16px 24px 16px 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  ${Button} {
    align-self: flex-end;
    margin-bottom: 2px;
  }
`;

const PeriodsWrapper = styled.div`
  align-self: flex-start;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
`;

const PeriodItem = styled<{ active?: boolean, disabled?: boolean }, 'div'>('div')`
  font-size: 12px;
  font-family: Montserrat, sans-serif;
  font-weight: bolder;
  padding: 8px 0;
  margin: 0 12px;
  text-transform: uppercase;
  color: ${({ disabled, active, theme }) => disabled ? theme.light : (active ? theme.default : theme.primary)};
  ${({ disabled }) => disabled ? '' : 'cursor: pointer;'}
  border-bottom: ${({ active, theme }) => active ? `2px solid ${theme.default}` : 'none'};
`;

interface IPeriodSelectorProps {
  startDate: Moment;
  endDate: Moment;
  onDatesChange: (range: { startDate: Moment, endDate: Moment }) => void;
  onFocusChange: (input: any) => void;
}

interface IPeriodSelectorState {
  activePeriod: string | null;
}

class PeriodSelector extends React.Component<IPeriodSelectorProps, IPeriodSelectorState> {

  static expectedUpdate: boolean;

  constructor(props) {
    super(props);

    this.handlePeriodClick = this.handlePeriodClick.bind(this);
    this.handleClose = this.handleClose.bind(this);

    PeriodSelector.expectedUpdate = false;

    if (!props.startDate && !props.endDate) {
      this.state = { activePeriod: 'all_time' };
    } else if (!props.startDate || !props.endDate) {
      this.state = { activePeriod: '' };
    } else {
      this.state = { activePeriod: definePeriod(props.startDate, props.endDate) };
    }

  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (PeriodSelector.expectedUpdate) {
      PeriodSelector.expectedUpdate = false;
      return {};
    }

    if (nextProps.startDate !== prevState.startDate || nextProps.endDate !== prevState.endDate) {
      if (!nextProps.startDate && !nextProps.endDate) {
        return { activePeriod: 'all_time' };
      } else if (nextProps.startDate && nextProps.endDate) {
        return { activePeriod: definePeriod(nextProps.startDate, nextProps.endDate) };
      } else {
        return { activePeriod: '' };
      }
    }

    return {};
  }

  handlePeriodClick(periodId) {

    this.setState({ activePeriod: periodId });

    PeriodSelector.expectedUpdate = true;

    let day: Moment;
    if (!this.props.startDate && !this.props.endDate) {
      day = moment().startOf('day');
    } else {
      day = this.props.startDate ? this.props.startDate : this.props.endDate;
    }

    switch (periodId) {
      case 'day':
        this.props.onDatesChange({ startDate: day, endDate: day.clone().add(1, 'days') });
        break;
      case 'week':
        this.props.onDatesChange({
          startDate: this.props.startDate ? day : day.clone().subtract(7, 'days'),
          endDate: this.props.startDate ? day.clone().add(7, 'days') : day,
        });
        break;
      case 'month':
        this.props.onDatesChange({
          startDate: this.props.startDate ? day : day.clone().subtract(1, 'months'),
          endDate: this.props.startDate ? day.clone().add(1, 'months') : day
        });
        break;
      case 'year':
        this.props.onDatesChange({
          startDate: this.props.startDate ? day : day.clone().subtract(1, 'years'),
          endDate: this.props.startDate ? day.clone().add(1, 'years') : day,
        });
        break;
      case 'all_time':
        this.props.onDatesChange({ startDate: null, endDate: null });
        break;
    }
  }

  handleClose() {
    this.props.onFocusChange(null);
  }

  render() {
    return (
      <DatePickerFooter>
        <PeriodsWrapper>
          {periodOptions.map(option => <PeriodItem
            key={option.id}
            onClick={() => this.handlePeriodClick(option.id)}
            active={option.id === this.state.activePeriod}>
            {option.label}
          </PeriodItem>
          )}
        </PeriodsWrapper>
        <Button default onClick={this.handleClose}>Close</Button>
      </DatePickerFooter>
    );
  }
}

function renderDatePicker(options) {
  const {
    type,
    customArrowIcon,
    ...restOptions
  } = options;

  switch (type) {
    case 'single': return <SingleDatePicker numberOfMonths={1} {...restOptions} />;
    case 'range': return <DateRangePicker customArrowIcon={customArrowIcon} {...restOptions} />;
    default: return <SingleDatePicker numberOfMonths={1} {...restOptions} />;
  }
}

interface IDatePickerProps {
  type?: string;
  className?: string;
  label?: string;
  required?: boolean;
  error?: string;
  compact?: boolean;
  size?: string;
  id?: string;
  startDateId?: string;
  endDateId?: string;
  date?: Moment;
  startDate?: Moment;
  endDate?: Moment;
  isOutsideRange?: () => any;
  onDateChange?: (date: Moment) => void;
  onDatesChange?: (range: { startDate: Moment, endDate: Moment }) => void;
  onClose?: () => void;
  focusedInput?: string;
  focused?: boolean;
  onFocusChange?: (input: any) => void;
  keepOpenOnDateSelect?: boolean;
  showPeriods?: boolean;
  disabled?: boolean;
}

export default styled<IDatePickerProps>(({
  type,
  className,
  label,
  required,
  error,
  compact,
  size,
  showPeriods,
  ...props
}) => {
  const options = {
    type,
    navPrev: <CaretIcon />,
    navNext: <CaretIcon />,
    customInputIcon: <CalendarIcon />,
    customArrowIcon: <ArrowIcon />,
    displayFormat:'MM.DD.YYYY',
    verticalSpacing: 9,
    hideKeyboardShortcutsPanel: true,
    renderCalendarInfo: undefined,
    calendarInfoPosition: undefined,
    ...props,
  };

  if (type === 'range' && showPeriods) {
    options.calendarInfoPosition = "bottom";
    options.renderCalendarInfo = () => <PeriodSelector
      startDate={props.startDate}
      endDate={props.endDate}
      onDatesChange={props.onDatesChange}
      onFocusChange={props.onFocusChange}
    />;
  }

  return <div className={className}>
    {
      label &&
      <Label
        error={error}
        htmlFor={props.id ? props.id : null}
      >
        {`${label} ${required ? ' * ' : ''}`}
      </Label>
    }
    <Body compact={compact} size={size}>
      {
        renderDatePicker(options)
      }
    </Body>
    <Error compact={compact}>
      {error}
    </Error>
  </div>;

})`
  ${({ theme, ...props }) => {

    const {
      global,
      light,
      main,
      primary,
      dark,
      lightest,
      fs_md,
      warning,
      active,
    } = theme;

    const borderColor = props.error ?
      warning :
      light;

    return `
      & .DayPicker{
        &_focusRegion{
          ${global}
        }

        &Navigation{
          &_container{

          }
          
          &_button{
            ${global}
            height: 38px;
            width: 40px;
            border: 1px solid ${light};
            border-radius: 0;
            background-color: ${main}; 
            transition: all .25s ease-in-out;
            i{
              svg{
                path{
                }
              }
            }

            &:focus{
              outline-color: ${active}
            }
  
            &:focus-within{
              outline-color: ${active}
            }

            &:hover{
              transition: all .25s ease-in-out;
              i{
                svg{
                  path{
                    fill: ${theme.default};
                  }
                }
              }
            }

            &:first-of-type{
              i{
                transform: rotate(90deg);
              }
            }

            &:last-of-type{
              i{
                transform: rotate(-90deg);
              }
            }
          }
        }

        &_weekHeader{
          &_ul{
            margin: 10px 0;
          }

          &_li{
            small{
              ${global}
              font-size: 12px;
              font-weight: bold;
              color: ${primary};
            }
          }
        }

        border-radius: 0;
        box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
        border: 1px solid ${light};

        .CalendarMonthGrid{
          &_month{
            &__horizontal{

            }
          }

          .CalendarMonth{
            &_caption{
              font-size: 12px;
              padding-top: 31px;
              padding-bottom: 55px;
              color: ${primary};
            }
          }

        }

        .CalendarDay{
          outline: none;
          &__default{
            color: ${primary};
            border: none;
          }

          &__blocked_out_of_range{
            color: ${dark};
          }

          &__selected{
            background-color: ${theme.default};
            color: ${main};
            &:hover{
              background-color: ${theme.default};
              color: ${main};
            }
            &:active{
              background-color: ${theme.default};
              color: ${main};
            }

            &_span{
              background-color: ${lightest};
              color: ${primary};
              &:hover{
                background-color: ${lightest};
                color: ${primary};
              }
              &:active{
                background-color: ${lightest};
                color: ${primary};
              }
            }
          }

          &__hovered{
            background-color: ${theme.default};
            color: ${main};
            &:hover{
              background-color: ${theme.default};
              color: ${main};
            }
            &:active{
              background-color: ${theme.default};
              color: ${main};
            }

            &_span{
              background-color: ${lightest};
              color: ${primary};
              &:hover{
                background-color: ${lightest};
                color: ${primary};
              }
              &:active{
                background-color: ${lightest};
                color: ${primary};
              }
            }
          }
        }

      }

      .DateInput{
        width: 100px;
        &_fang{
          display: none;
        }
        &_input{
          ${global}
          font-size: ${fs_md};
          line-height: 22px;
          color: ${primary};
          padding: 7px 12px 6px;

          &__focused{
            border-color: transparent;
          }
        }
      }
      .SingleDatePickerInput{
        &__withBorder{
          border: 1px solid ${borderColor};
          border-radius: 0px;
        }
        &_calendarIcon{
          ${global}
          padding: 9px;
          height: 38px;
          width: 40px;
          margin: 0;
          background-color: ${lightest};
          border-right: 1px solid ${borderColor}; 
          &:focus{
            outline-color: ${active}
          }

          &:focus-within{
            outline-color: ${active}
          }
        }
      }

      .DateRangePickerInput{
        &__withBorder{
          border: 1px solid ${borderColor};
          border-radius: 0px;
        }
        & > button {
         outline: none;
        }
        &_calendarIcon{
          ${global}
          padding: 9px;
          height: 38px;
          width: 40px;
          margin: 0;
          background-color: ${lightest};
          border-right: 1px solid ${borderColor}; 
          &:focus{
            outline-color: ${active}
          }

          &:focus-within{
            outline-color: ${active}
          }
        }
      }
    `;
  }}
`;

import * as React from "react";
import styled from "styled-components";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { Formik } from "formik";
import * as Yup from "yup";

import withGranted, { IWithGrantedProps } from "../HOC/WithGranted";
import { IGlobalStore } from "../../_reducers/reducers";
import { notify, notifySwagger } from "../../_actions/notification";
import { history } from "../../store";
import { MainContainer, Toggle } from "../../components";
import { IMatch } from "../../_types/common";
import withMenu from "../../containers/HOC/WithMenu";
import Form from "./ViewForm";
import Title from "../../sharedComponents/Title";
import {
  getClientInvoiceForEdit,
  updateClientInvoice
} from "../../_api/invoices";
import { INTEGER_SIZE } from "../../_constants/data";
import * as Skeleton from "../../components/Skeleton";
import moment from "moment";
import { removeHashSign } from "../../_utils/misc";
import { IValues } from "./types";
import {
  EditClientInvoiceItemDto,
  NoteSearchOutputDto,
  InvoiceManualEntryDto,
  UpdateClientInvoiceItemDto
  // UpdateClientInvoiceDto,
  // UpdateClientInvoiceItemDto
} from "src/service-proxies";
import { CLIENTINVOICES_EDIT } from "src/_constants/permissions";
import immer from "immer";
import { updateInvoicePaidStatus } from "../../_api/invoices";
import { UpdateInvoicePaidStatusDto } from "./../../service-proxies";
// import { updateUncomparedOrder } from "src/_api/orders";

const Tabs = styled.div`
  display: flex;
  width: 100%;
  padding: 20px;
`;

const Content = styled.div`
  form {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
  }
`;

const Position = class {
  static Tabs = Tabs;
  static Content = Content;
};

interface INoteDto extends NoteSearchOutputDto {
  displayValue: string;
  invalid?: boolean;
}

interface IViewProps extends IConnectedActions, IWithGrantedProps {
  match: IMatch;
  productEntries: EditClientInvoiceItemDto[];
  taxRate: number;
  locationId: number;
  locationName: string;
  catalogId: number;
  handleTabChange: (tab: string) => void;
}

const clientInvoiceValidationSchema = Yup.object().shape({
  productEntries: Yup.array().of(
    Yup.object().shape({
      price: Yup.string().matches(
        /^([1-9][0-9]*(\.[0-9]{0,2})?)|(0\.[0-9]{0,2})$/,
        "must be a number with two decimals"
      ),
      quantity: Yup.number()
        .typeError("must be a number")
        .integer("must be a number")
        .min(0, "can't be negative")
        .max(INTEGER_SIZE, `can't be grater than ${INTEGER_SIZE}`),
      packageType: Yup.object()
        .shape({
          displayValue: Yup.string(),
          id: Yup.number()
        })
        .typeError("required!"),
      zeroPriceNote: Yup.string()
        .max(250, "must contain less than 250 characters")
        .nullable()
    })
  )
});

interface IExtendedValues extends IValues {
  isPaid: boolean;
}

class View extends React.Component<IViewProps, IExtendedValues> {
  public form: Formik;
  constructor(props) {
    super(props);

    this.state = {
      status: "loading",
      prices: null,
      message: "",
      isChanged: false,
      owner: null,
      creationTime: null,
      taxRate: null,
      subTotalAmount: null,
      taxesTotal: null,
      totalAmount: null,
      isVisibleToClient: false,
      isPaid: false,
      additionalChargeAmount: 0,
      additionalChargeReason: "",
      additionalChargeIsTaxable: false,
    };

    this.getClientInvoice = this.getClientInvoice.bind(this);
    this.handleClickBack = this.handleClickBack.bind(this);
    this.handleSubmitInvoice = this.handleSubmitInvoice.bind(this);
    this.handleResetWarning = this.handleResetWarning.bind(this);
    this.handleNoteChange = this.handleNoteChange.bind(this);
    this.handleNoteDelete = this.handleNoteDelete.bind(this);
    this.handleSaveEdits = this.handleSaveEdits.bind(this);
    this.handleUpdateManualEntry = this.handleUpdateManualEntry.bind(this);
    this.handleFormChange = this.handleFormChange.bind(this);
    this.handlePaidStatusChange = this.handlePaidStatusChange.bind(this);
  }

  componentDidMount() {
    this.getClientInvoice();
  }

  getClientInvoice(): void {
    this.setState({ status: "loading" });

    getClientInvoiceForEdit(this.props.match.params.clientInvoiceId)
      .then(clientInvoiceData => {
        this.setState({
          status: "done",
          productEntries: clientInvoiceData.items as EditClientInvoiceItemDto[],
          location: clientInvoiceData.location,
          owner: clientInvoiceData.owner,
          creationTime: clientInvoiceData.creationTime,
          id: this.props.match.params.clientInvoiceId,
          number: clientInvoiceData.number,
          taxRate: clientInvoiceData.taxRate,
          subTotalAmount: clientInvoiceData.subTotalAmount,
          taxesTotal: clientInvoiceData.taxesTotal,
          totalAmount: clientInvoiceData.totalAmount,
          isVisibleToClient: clientInvoiceData.isVisibleToClient,
          isPaid: clientInvoiceData.hasBeenPaid,
          additionalChargeAmount: clientInvoiceData.additionalChargeAmount,
          additionalChargeReason: clientInvoiceData.additionalChargeReason,
          additionalChargeIsTaxable: clientInvoiceData.additionalChargeIsTaxable
        });
      })
      .catch(e => {
        this.props.notifySwagger(e, "error");
        history.push("/notFound");
      });
  }

  handleClickBack(): void {
    history.goBack();
  }

  handleResetWarning(): void {
    this.setState({ message: "" });
  }

  handleSubmitInvoice(): void {
    const productsPrices = [];

    for (const key in this.state.prices) {
      if (Object.prototype.hasOwnProperty.call(this.state.prices, key)) {
        productsPrices.push({
          productId: key,
          price: this.state.prices[key]
        });
      }
    }
  }

  handleSaveEdits(): void {
    this.setState({ status: "saving" });

    setTimeout(() => {
      this.saveEdits()
        .then(() => {
          this.setState({ status: "saved", isChanged: false });
          this.getClientInvoice();
        })
        .catch(e => {
          this.props.notifySwagger(e, "error");
          this.setState({ status: "loaded", isChanged: true });
        });
    }, 200); // this is needed if some manual entry is in edit mode
  }

  saveEdits(): Promise<void> {
    const productEntries: UpdateClientInvoiceItemDto[] = this.state.productEntries.map(
      invoiceItem => {
        const noteId = invoiceItem.note ? invoiceItem.note.id : null;
        return {
          price: invoiceItem.price,
          packageTypeId: invoiceItem.packageType.id,
          isAmountIncludedToInvoice: true,
          quantity: invoiceItem.quantity,
          isZeroPrice: invoiceItem.isZeroPrice,
          zeroPriceNote: invoiceItem.zeroPriceNote,
          noteId: noteId,
          manualEntry: invoiceItem.manualEntry,
          id: invoiceItem.id
        };
      }
    );
    return updateClientInvoice({
      items: productEntries,
      itemsWithAmountIncludedToInvoice: productEntries.map(pe => pe.id),
      id: this.state.id,
      additionalChargeAmount: this.state.additionalChargeAmount,
      additionalChargeReason: this.state.additionalChargeReason,
      additionalChargeIsTaxable: this.state.additionalChargeIsTaxable,
    });
  }

  handleNoteChange(
    note: NoteSearchOutputDto,
    productId: number,
    disableNew?: boolean
  ): void {
    if (this.props.isGranted(CLIENTINVOICES_EDIT)) {
      this.setState(
        immer((draft: IValues) => {
          const productEntry = draft.productEntries.find(
            item => item.product.id === productId
          );
          if (productEntry) {
            productEntry.note = (note as any) as NoteSearchOutputDto;
            productEntry.manualEntry = {
              isAmountIncludedToInvoice: true,
              subTotalAmount: 0,
              sku: "",
              description: "",
              price: 0,
              quantity: 0,
              isTaxable: true
            };
            draft.isChanged = true;
          }
        })
      );
    }
  }

  handleNoteDelete(options: INoteDto, id: number): void {
    if (this.props.isGranted(CLIENTINVOICES_EDIT)) {
      this.setState(
        immer((draft: IValues) => {
          const productEntry = draft.productEntries.find(
            item => item.product.id === id
          );
          if (productEntry) {
            productEntry.note = null;
            draft.isChanged = true;
          }
        })
      );
    }
  }

  handleUpdateManualEntry(entry: InvoiceManualEntryDto, noteId, productId) {
    this.setState(
      immer((draft: IValues) => {
        const productEntry = draft.productEntries.find(
          pe => pe.product.id === productId
        );
        if (productEntry) {
          !entry.isTaxable
            ? (productEntry.manualEntry.isTaxable = false)
            : (productEntry.manualEntry.isTaxable = true);
          productEntry.manualEntry = entry;
          //THIS WAS ADDED TO AUTOMATICALLY CALCULATE MANUAL ENTRY LINE WITH SUBTOTAL, TAXES, AND TOTAL.
          productEntry.manualEntry.isAmountIncludedToInvoice = true;
          draft.isChanged = true;
        }
      })
    );
  }

  handleFormChange(type: string, id: number, data?: any) {
    if (type === "price") {
      try {
        this.setState(
          immer((draft: IValues) => {
            const productEntry = draft.productEntries.find(pe => pe.id === id);
            if (productEntry) {
              productEntry.price = data;
            }
          })
        );
        this.setState({ isChanged: true });
      } catch {
        console.log("Conflict between manual entry field and price.");
      }
    } else if (type === "quantity") {
      try {
        this.setState(
          immer((draft: IValues) => {
            const productEntry = draft.productEntries.find(pe => pe.id === id);
            if (productEntry) {
              productEntry.quantity = data;
            }
          })
        );
        this.setState({ isChanged: true });
      } catch {
        console.log("Conflict between manual entry field and quantity.");
      }
    } else if (type === "uom") {
      try {
        this.setState(
          immer((draft: IValues) => {
            const productEntry = draft.productEntries.find(pe => pe.id === id);
            if (productEntry) {
              productEntry.packageType = data;
            }
          })
        );
        this.setState({ isChanged: true });
      } catch {
        console.log("Conflict between manual entry field and UOM.");
      }
    } else if (type === "zeroPrice") {
      try {
        this.setState(
          immer((draft: IValues) => {
            const productEntry = draft.productEntries.find(pe => pe.id === id);
            if (productEntry) {
              productEntry.isZeroPrice = !productEntry.isZeroPrice;
            }
          })
        );
        this.setState({ isChanged: true });
      } catch {
        console.log("Conflict between manual entry field and zeroPrice.");
      }
    } else if (type === "additionalChargeAmount"){
      try{
        this.setState(
          immer((draft: IValues) => {
            draft.additionalChargeAmount = data;
          })
        );
        this.setState({ isChanged: true });
      }
      catch{
        console.log("Conflict setting the additional charge")
      }
    } else if (type === "additionalChargeReason"){
      try{
        this.setState(
          immer((draft: IValues) => {
            draft.additionalChargeReason = data;
          })
        );
        this.setState({ isChanged: true });
      }
      catch{
        console.log("Conflict setting the additional charge reason")
      }
    } else if (type === "additionalChargeIsTaxable"){
      try{
        this.setState(
          immer((draft: IValues) => {
            draft.additionalChargeIsTaxable = !draft.additionalChargeIsTaxable;
          })
        );
        this.setState({ isChanged: true });
      }
      catch{
        console.log("Conflict setting the additional charge is taxabbble flag")
      }
    }
  }

  handlePaidStatusChange() {
    const paidStatusDetails: UpdateInvoicePaidStatusDto = {
      id: this.state.id,
      hasBeenPaid: !this.state.isPaid
    };

    updateInvoicePaidStatus(paidStatusDetails)
      .then(() => {
        this.setState({
          isPaid: paidStatusDetails.hasBeenPaid
        });
      })
      .catch(err => {
        console.log(err);
      });
  }

  render() {
    return (
      <MainContainer
        centered
        scale="md"
        renderTitle={
          <Title label="Client Invoice">
            {this.state.status === "loading" && (
              <React.Fragment>
                <Title.Element>
                  <Skeleton.Line />
                </Title.Element>
                <Title.Element>
                  <Skeleton.Line />
                </Title.Element>
                <Title.Element>
                  <Skeleton.Line />
                </Title.Element>
              </React.Fragment>
            )}
            {this.state.status === "done" && (
              <React.Fragment>
                <Title.Element>
                  Buyer: {this.state.owner && this.state.owner.displayValue}
                </Title.Element>
                <Title.Element>
                  Invoice #: {removeHashSign(this.state.number)}
                </Title.Element>
                <Title.Element>
                  Date/Time:{" "}
                  {moment(this.state.creationTime).format("MM/DD/YYYY LT")}
                </Title.Element>
                <Title.Element>
                  <div style={{ padding: "0 25px", display: "inline-block" }}>
                    <Toggle
                      label={"Paid:"}
                      value={this.state.isPaid}
                      onChange={this.handlePaidStatusChange}
                    />
                  </div>
                </Title.Element>
              </React.Fragment>
            )}
          </Title>
        }
      >
        <Position.Content>
          <Formik
            enableReinitialize
            initialValues={this.state}
            validationSchema={clientInvoiceValidationSchema}
            onSubmit={() => {}}
            ref={node => (this.form = node)}
            render={props => (
              <Form
                {...props}
                // productEntries={this.state.productEntries}
                onResetWarning={this.handleResetWarning}
                onNoteChange={this.handleNoteChange}
                onNoteDelete={this.handleNoteDelete}
                onUpdateManualEntry={this.handleUpdateManualEntry}
                onSaveEdits={this.handleSaveEdits}
                onFormChange={this.handleFormChange}
                hasBeenSent={this.state.isVisibleToClient}
              />
            )}
          />
        </Position.Content>
      </MainContainer>
    );
  }
}

interface IConnectedProps {
  locationId: number;
  locationName: string;
  catalogId: number;
}

interface IConnectedActions {
  notify: typeof notify;
  notifySwagger: typeof notifySwagger;
}

export default withMenu(
  connect(
    createStructuredSelector<IGlobalStore, IConnectedProps>({
      locationId: state => state.currentUser.locationInfo.id,
      locationName: state => state.currentUser.locationInfo.name,
      catalogId: state => state.currentUser.catalogId
    }),
    {
      notify,
      notifySwagger
    }
  )(withGranted(View)),
  false
);

import React from 'react';
import styled from 'styled-components';

import * as productsApi from '../../../_api/products';

import {
  Select,
} from '../../../components';

const RelatedProductReadOnly = styled.div`
  ${props => {

    let {
      global,
    } = props.theme;

    return `
      ${global}
      font-weight: 300;

      span{
        font-weight: 400;
      }
    `;
  }}
`;

const RelatedProductOption = styled(({
  className,
  option,
  onFocus,
  onSelect,
  isFocused,
}) => {

  function handleMouseDown(event) {
    event.preventDefault();
    event.stopPropagation();
    onSelect(option, event);
  }
  function handleMouseEnter(event) {
    onFocus(option, event);
  }
  function handleMouseMove(event) {
    if (isFocused) { return; }
    onFocus(option, event);
  }

  return <div
    className={className}
    onMouseDown={handleMouseDown}
    onMouseEnter={handleMouseEnter}
    onMouseMove={handleMouseMove}
  >
    {option.name}
    <span>SKU: {option.sku}</span>
  </div>;
})`
  font-weight: 300;
  span{
    display: block;
    text-align: right;
  }
`;

export default styled((props) => {

  const {
    className,
    value,
    index,
    onChange,
    elementId,
  } = props;

  function handleChange(changedValue) {
    onChange(changedValue, index);
  }

  function load(text, callback) {
    productsApi.getSimilarProducts(text, elementId).then(data => {
      let options = data.items;
      callback(null, {
        options,
        complete: true,
      });
    });
  }

  return <div className={className}>
    {
      value.readOnly ?
        <RelatedProductReadOnly>

          {value.name}
          <span> (SKU: {value.sku})</span>

        </RelatedProductReadOnly> :

        <Select
          compact
          async
          autoload
          labelKey="name"
          valueKey="sku"
          placeholder="Select Product..."
          onChange={handleChange}
          value={value}
          loadOptions={load}
          optionComponent={RelatedProductOption}
        />
    }
  </div>;
})``;

import React from 'react';
import styled from 'styled-components';

const ActorIcon = props => {
  return(
    <i {...props}>
      <svg viewBox="-2548 -2808 22 25" width="18" height="21">
        <path d=" M -2537 -2794 C -2533.686 -2794 -2531 -2796.686 -2531 -2800 C -2531 -2803.314 -2533.686 -2806 -2537 -2806 C -2540.314 -2806 -2543 -2803.314 -2543 -2800 C -2543 -2796.686 -2540.314 -2794 -2537 -2794 Z "/>
        <path d=" M -2546 -2785 C -2546 -2789.971 -2541.971 -2794 -2537 -2794 C -2532.029 -2794 -2528 -2789.971 -2528 -2785" />
      </svg>
    </i>
  );
};

export default styled(ActorIcon)`
  ${ props => {
    let {
      light,
    } = props.theme;
    return `
      width: 21px;
      height: 21px;
      display: inline-flex;
      justify-content: center;
      align-items: center;
    
      path{
        fill:none; 
        vector-effect:non-scaling-stroke; 
        stroke-width:2; 
        stroke:${light};
        stroke-linejoin:miter;
        stroke-linecap:square;
        stroke-miterlimit:10;
      }
    `;
  }}
`;

import * as types from '../_actionTypes/category';
import {
  CountDtoOfInt64,
  GetСategoriesDto,
} from '../service-proxies';

export interface IGetCategories extends GetСategoriesDto {
  expanded?: boolean;
  count?: number;
}

export interface ICategoryStore {
  categories: IGetCategories[];
  productsCounts: CountDtoOfInt64[];
  status: string;
  listStatus: string;
  totalCount: number;
}

export interface ICategoryGetCategories {
  type: types.TYPE_CATEGORY_GET_CATEGORIES;
  categories: IGetCategories[];
  status: string;
  listStatus: string;
  expandSubCategoryId?: number;
}

export interface ICategoryGetCategoryProductsCounts {
  type: types.TYPE_CATEGORY_GET_CATEGORY_PRODUCTS_COUNTS;
  categories: IGetCategories[];
  productsCounts: CountDtoOfInt64[];
}

type ActionType = ICategoryGetCategories
  | ICategoryGetCategoryProductsCounts;

const initialState: ICategoryStore = {
  categories: [],
  productsCounts: [],
  status: '',
  listStatus: '',
  totalCount: 0,
};

export default (state: ICategoryStore = initialState, action: ActionType): ICategoryStore => {

  let countedCategories;
  let sortedCategories;

  switch (action.type) {

    case types.CATEGORY_GET_CATEGORIES: {
      sortedCategories = action.categories && action.categories.sort((a: IGetCategories, b: IGetCategories): number => {
        if (a.sortingPriority < b.sortingPriority) {
          return 1;
        }
        if (a.sortingPriority > b.sortingPriority) {
          return -1;
        }
        return 0;
      });

      const categories =  sortedCategories ? sortedCategories : state.categories;

      const map = getExpandedMap(state.categories);

      preserveExpandedCategories(categories, map, parseInt(action.expandSubCategoryId as any as string, 10));

      return ({
        ...state,
        categories,
        status: action.status,
        listStatus: action.listStatus,
        totalCount: getTotalCategoriesCount(categories),
      });
    }

    case types.CATEGORY_GET_CATEGORY_PRODUCTS_COUNTS: {
      countedCategories = state.categories.map(category => {

        const count = action.productsCounts.find(productsCount => {
          return productsCount.id === category.id;
        });
        if (count) {
          category.count = count.count;
          return Object.assign({}, {...category, count: count.count});
        } else {
          return Object.assign({}, {...category, count: 0});
        }
      });

      return ({
        ...state,
        categories: countedCategories,
        productsCounts: action.productsCounts,
      });
    }

    default:
      return state;
  }

};

function getExpandedMap(categories: IGetCategories[]): {[id: number]: true} {
  const map = {};
  for(const category of categories) {
    if (category.expanded) {
      map[category.id] = true;
    }
    if (category.childCategories && category.childCategories.length) {
      Object.assign(map, getExpandedMap(category.childCategories));
    }
  }
  return map;
}

function preserveExpandedCategories(categories: IGetCategories[], expandedMap: {[id: number]: true}, expandSubCategoryId: number): boolean {
  let someFound = false;
  for(const category of categories) {
    if (expandedMap[category.id]) {
      category.expanded = true;
    }
    if (expandSubCategoryId && category.id === expandSubCategoryId) {
      category.expanded = true;
      someFound = true;
    }
    if (category.childCategories && category.childCategories.length) {
      if (preserveExpandedCategories(category.childCategories, expandedMap, expandSubCategoryId)) {
        category.expanded = true;
        someFound = true;
      }
    }
  }
  return someFound;
}

function getTotalCategoriesCount(categories: IGetCategories[]): number {
  let count = categories.length;
  for(const category of categories){
    if (category.childCategories && category.childCategories.length) {
      count += getTotalCategoriesCount(category.childCategories);
    }
  }
  return count;
}

import * as React from "react";
import styled, { StyledComponentClass } from "styled-components";
import { Formik } from "formik";
import * as Yup from "yup";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { match } from "react-router";

import Form from "./Form";

import { MainContainer } from "../../../components";
import { history } from "../../../store";
import * as clientApi from "../../../_api/client";
import * as divisionApi from "../../../_api/division";
import * as actionsNotification from "../../../_actions/notification";
import {
  EditDivisionDto,
  CreateDivisionDto,
  UpdateDivisionDto
} from "../../../service-proxies";
import { REQUIRED_MESSAGE } from "../../../_constants/validation";
import {
  getMaxTextLengthMessage
  // getNumberLengthMessage
} from "../../../_utils/validation";
import { JSONMapper } from "src/_utils/misc";

type StyledComponent = StyledComponentClass<{}, React.StatelessComponent<{}>>;

interface IPosition extends StyledComponent {
  Body?: StyledComponent;
  Head?: StyledComponent;
}

const Position: IPosition = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

Position.Body = styled.div`
  width: 100%;
  height: 100%;
`;

Position.Head = styled.header`
  text-align: right;
  padding: 20px;
`;

const validationSchema = Yup.object().shape({
  name: Yup.string()
    .trim()
    .max(128, getMaxTextLengthMessage(128))
    .required(REQUIRED_MESSAGE),
  address1: Yup.string()
    .trim()
    .max(55, getMaxTextLengthMessage(55))
    .required(REQUIRED_MESSAGE),
  address2: Yup.string()
    .trim()
    .nullable()
    .max(55, getMaxTextLengthMessage(55)),
  city: Yup.string()
    .trim()
    .max(30, getMaxTextLengthMessage(30))
    .required(REQUIRED_MESSAGE),
  state: Yup.object()
    .shape({
      displayValue: Yup.string().trim(),
      id: Yup.number()
    })
    .required(REQUIRED_MESSAGE)
    .typeError(REQUIRED_MESSAGE),
  zipCode: Yup.string()
    .matches(/^\d{5}(-\d{4}){0,1}$/, "Please enter a valid Zip Code.")
    .required(REQUIRED_MESSAGE)
});

export interface IEditDivisionState extends EditDivisionDto {
  stateId: number;
  clientName?: string;
  id: number;
  status: "loading" | "done";
}

const initialState: IEditDivisionState = {
  id: null,
  parentId: null,
  name: "",
  address1: "",
  address2: "",
  city: "",
  state: null,
  stateId: null,
  zipCode: "",
  status: "loading"
};

interface IEditDivisionProps extends IConnectedActions {
  divisionId: number | string;
  match: match<{
    clientId: string;
    id: string;
  }>;
}

class ConvertEmptyToNullAndTrimMapper extends JSONMapper<IEditDivisionState> {
  map(node) {
    if (typeof node === "string") {
      node = node.trim();
    }
    if (!node && node !== 0) {
      node = null;
    }
    return node;
  }
}

class Edit extends React.Component<IEditDivisionProps, IEditDivisionState> {
  isNew: boolean;

  constructor(props) {
    super(props);

    this.state = {
      ...initialState,
      id:
        typeof this.props.match.params.id === "string"
          ? parseInt(this.props.match.params.id, 10)
          : this.props.match.params.id,
      parentId: parseInt(this.props.match.params.clientId),
      clientName: ""
    };

    this.isNew = !this.props.match.params.id;
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    this.getClientName();
    !this.isNew ? this.getDivision() : this.setState({ status: "done" });
  }

  handleSubmit(values) {
    const newValues = new ConvertEmptyToNullAndTrimMapper({
      ...values,
      stateId: values.state.id
    }).toJSON();

    this.isNew
      ? this.createDivision(newValues)
      : this.updateDivision(newValues);
  }

  getDivision(): void {
    this.setState({ status: "loading" }, () => {
      const divisionId =
        typeof this.state.id === "string"
          ? parseInt(this.state.id, 10)
          : this.state.id;

      divisionApi
        .getDivisionForEdit(divisionId)
        .then(divisionData => {
          this.setState({
            ...divisionData,
            status: "done"
          });
        })
        .catch(e => {
          this.props.notify(e, "error");
        });
    });
  }

  getClientName(): void {
    clientApi
      .getClientName(parseInt(this.props.match.params.clientId))
      .then(clientData => {
        this.setState({
          clientName: clientData.displayValue
        });
      })
      .catch(e => {
        this.props.notify(e, "error");
      });
  }

  createDivision(values: CreateDivisionDto): void {
    this.setState({ status: "loading" }, () => {
      divisionApi
        .createDivision(values)
        .then(() => {
          history.push(
            `/admin/clients/${this.props.match.params.clientId}/divisions`
          );
        })
        .catch(e => {
          this.props.notify(e, "error");
        });
    });
  }

  updateDivision(values: UpdateDivisionDto): void {
    this.setState({ status: "loading" }, () => {
      divisionApi
        .updateDivision(values)
        .then(() => {
          history.push(
            `/admin/clients/${this.props.match.params.clientId}/divisions`
          );
        })
        .catch(e => {
          this.props.notify(e, "error");
        });
    });
  }

  render() {
    return (
      <MainContainer
        scale="md"
        title={`${this.isNew ? "create new" : "edit"} division for ${
          this.state.clientName
        }`}
        subTitle={`Please fill out all the fields below properly to ${
          this.isNew ? "create a new" : "edit a"
        } division`}
        centered
      >
        <Formik
          enableReinitialize
          initialValues={this.state}
          validationSchema={validationSchema}
          validateOnChange={false}
          onSubmit={this.handleSubmit}
          render={props => <Form {...props} isNew={this.isNew} />}
        />
      </MainContainer>
    );
  }
}

interface IConnectedActions {
  notify: typeof actionsNotification.notifySwagger;
}

export default connect(
  null,
  dispatch =>
    bindActionCreators(
      {
        notify: actionsNotification.notifySwagger
      },
      dispatch
    )
)(Edit);

import { types } from '../_actionTypes/roles';
import { types as currentUserTypes } from '../_actionTypes/current-user';
import {ListRoleDto, PermissionNodeDto, RoleDto} from '../service-proxies';

export interface IListRoleMod extends ListRoleDto {
  createdAt: string;
}

export interface IRolesStore {
  roles: IListRoleMod[];
  totalCount: number | null;
  allStatus: string;
  role: RoleDto;
  status: string;
  permissionsTree: PermissionNodeDto[];
}

const initialState: IRolesStore = {
  roles: [],
  totalCount: null,
  allStatus: '',
  role: null,
  status: '',
  permissionsTree: [],
};

interface IActionRoleGetAll {
  type: types.ROLE_GET_ALL;
  roles?: IListRoleMod[];
  totalCount?: number | null;
  allStatus?: string;
  status?: string;
}

interface IActionGetRoleById {
  type: types.ROLE_GET_BY_ID;
  role: RoleDto;
  status?: string;
}

interface IActionDeleteRole {
  type: types.ROLE_DELETE;
  status?: string;
  allStatus?: string;
}

interface IActionGetAllPermissions {
  type: types.ROLE_GET_ALL_PERMISSIONS;
  permissionsTree: PermissionNodeDto[];
}

interface IActionClearState {
  type: currentUserTypes.CURRENT_USER_CLEAR_AUTH_DATA;
}

type IActions =  IActionRoleGetAll | IActionGetRoleById | IActionDeleteRole | IActionGetAllPermissions | IActionClearState;

export default (state: IRolesStore = initialState, action: IActions) => {

  switch (action.type) {

    case types.ROLE_GET_ALL:
      return ({
        ...state,
        roles: action.roles,
        totalCount: action.totalCount,
        allStatus: action.allStatus,
        status: action.status,
      });

    case types.ROLE_GET_BY_ID:
      return ({
        ...state,
        role: action.role,
        status: action.status,
      });

    /*case types.ROLE_CREATE:
      return ({
        ...state,
        role: action.,
        status,
      });
    case types.ROLE_UPDATE:
      return ({
        ...state,
        role,
        status,
      });*/

    case types.ROLE_GET_ALL_PERMISSIONS:
      return ({
        ...state,
        permissionsTree: action.permissionsTree,
      });

    case currentUserTypes.CURRENT_USER_CLEAR_AUTH_DATA:
      return initialState;

    default:
      return state;
  }
};

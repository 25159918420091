import * as React from 'react';
import { RouteComponentProps } from 'react-router';
import { Switch, Redirect } from 'react-router-dom';
import { NavLink } from 'react-router-dom';

import {
  ADMINISTRATION_USERS,
  ADMINISTRATION_ROLES,
  ADMINISTRATION_CLIENTS,
  ADMINISTRATION_CLIENTCATALOGUE,
  ADMINISTRATION_VENDORS,
} from '../../_constants/permissions';

import User from './User/Routes';
import RoleList from './Role/List';
import Clients from './Client';
import Vendors from './Vendor';
import Catalog from './Catalog/Catalog';
import ProductView from '../Products/View';
import ProductList from '../Products/ListBuyer';
import RoutesMapper from '../Router/RoutesMapper';

import {
  MainMenu, HorizontalMenu,
} from '../../components';

import MenuSidebar from '../MenuSidebar/MenuSidebar';
import withGranted, {IWithGrantedProps} from '../HOC/WithGranted';
import {IRouteEntry} from '../../_types/common';

const routes: IRouteEntry[] = [
  {
    path: '/users',
    component: User,
    permission: ADMINISTRATION_USERS,
  },
  {
    path: '/roles',
    component: RoleList,
    permission: ADMINISTRATION_ROLES,
  },
  {
    path: '/clients',
    component: Clients,
    permission: ADMINISTRATION_CLIENTS,
  },
  {
    path: '/vendors',
    component: Vendors,
    permission: ADMINISTRATION_VENDORS,
  },
  {
    exact: true,
    path: '/catalog/:catalogId',
    component: Catalog,
    permission: ADMINISTRATION_CLIENTCATALOGUE,
  },
  {
    exact: true,
    path: '/catalog/:catalogId/products/:categoryId?/:subCategoryId?/:serviceSlug(product)/:productId',
    component: ProductView,
    permission: ADMINISTRATION_CLIENTCATALOGUE,
  },
  {
    exact: true,
    path: '/catalog/:catalogId/products/:categoryId?/:subCategoryId?',
    component: ProductList ,
    permission: ADMINISTRATION_CLIENTCATALOGUE,
  }
];

type IProps = RouteComponentProps<{}> & IWithGrantedProps;

class Admin extends React.Component<IProps> {

  allowedRoutePath: string;

  constructor(props) {
    super(props);
    const firstAllowedRoute = routes.find(route => route.path && props.isGranted(route.permission));
    this.allowedRoutePath = firstAllowedRoute ? firstAllowedRoute.path : '';
  }

  render () {
    const {match: {url}, isGranted} = this.props;
    return(
      <React.Fragment>
        <MenuSidebar>
          <MainMenu>
            <HorizontalMenu>
              {isGranted(ADMINISTRATION_USERS) && <NavLink to="/admin/users">Users</NavLink>}
              {isGranted(ADMINISTRATION_ROLES) && <NavLink to="/admin/roles">Roles</NavLink>}
              {isGranted(ADMINISTRATION_CLIENTS) && <NavLink to="/admin/clients">Clients</NavLink>}
              {isGranted(ADMINISTRATION_VENDORS) && <NavLink to="/admin/vendors">Vendors</NavLink>}
            </HorizontalMenu>
          </MainMenu>
        </MenuSidebar>
        <Switch>
          <Redirect exact from={`${url}`} to={this.allowedRoutePath ? `${url}${this.allowedRoutePath}` : '/'} />
          <RoutesMapper routes={routes} addNotFound {...this.props} />
        </Switch>
      </React.Fragment>
    );
  }
}

export default withGranted(Admin);

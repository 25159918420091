import {
  productServiceProxy,
  pricingDetailsServiceProxy,
  purchaseHistoryServiceProxy,
} from '../servicePool';
import {
  CategoryProductDto,
  ListResultDtoOfSearchSimilarProductDto,
  ListResultDtoOfSimilarProductDto,
  ListResultDtoOfSearchProductAttributeDto,
  SetPricesForCatalogsInput,
  ListResultDtoOfPricingDetailDto,
  ListResultDtoOfProductPriceDto,
  PagedResultDtoOfPurchasedProductsListOutput,
  ClientProductDetailsDto,
  ProductDetailsDto,
  ListResultDtoOfClientSimilarProductDto,
  PagedResultDtoOfProductListDto,
} from '../service-proxies';

export function getProductsByCategory(categoryId: number): Promise<CategoryProductDto[]> {
  return productServiceProxy.getCategoryProducts(categoryId).then(res => {
    return res.items;
  });
}

export function getSimilarProducts(text: string, id: number): Promise<ListResultDtoOfSearchSimilarProductDto> {
  return productServiceProxy.searchSimilarProducts(id, 5, text);
}

export function getSimilarProductsForProduct(productId: number): Promise<ListResultDtoOfSimilarProductDto> {
  return productServiceProxy.getSimilarProducts(productId);
}

export function getSimilarProductsForClientCatalog(catalogId: number, id: number): Promise<ListResultDtoOfClientSimilarProductDto> {
  return productServiceProxy.getSimilarProductsForClientCatalog(catalogId, id);
}

export function getAttributesForProduct(productId: number): Promise<ListResultDtoOfSearchProductAttributeDto> {
  return productServiceProxy.getProductAttributes(productId);
}

export function setPricesForCatalogs(prices: SetPricesForCatalogsInput): Promise<void> {
  return pricingDetailsServiceProxy.setPricesForCatalogs(prices);
}

export function getCatalogsProductPrices(productId: number): Promise<ListResultDtoOfPricingDetailDto> {
  return pricingDetailsServiceProxy.getPricesForProduct(productId);
}

export function getCatalogsProductsPrices(locationId: number, productIds: number[]): Promise<ListResultDtoOfProductPriceDto> {

  const count = productIds.length;
  const chunkCount = 20;
  const productIdsChuncks = [];

  for (let i = 0; i < count; i += chunkCount) {
    const tempArray = productIds.slice(i, i + chunkCount);
    productIdsChuncks.push(tempArray);
  }

  const productIdsChuncksLoadQuery = productIdsChuncks.map(chunck => {
    return pricingDetailsServiceProxy.getPricesForLocation(locationId, chunck);
  });

  const getChuncksOfPrices = Promise.all(productIdsChuncksLoadQuery).then(pricesChuncks => {
    return {
      items: pricesChuncks.reduce((allPrices, priceChunck) => {
        allPrices = [...allPrices, ...priceChunck.items];
        return allPrices;
      }, [])
    };
  });

  return getChuncksOfPrices;
}

export function comparePrices(catalogId: number, productPrices: any[]): Promise<ListResultDtoOfProductPriceDto> {
  return pricingDetailsServiceProxy.comparePrices(catalogId, productPrices);
}

export function getPurchasedProducts(
  catalogId: number,
  maxResultCount: number,
  skipCount: number,
  filter: string
): Promise<PagedResultDtoOfPurchasedProductsListOutput> {
  return purchaseHistoryServiceProxy.getPurchasedProducts(catalogId, maxResultCount, skipCount, filter);
}

export function getClientProductDetails(catalogId: number, id: number): Promise<ClientProductDetailsDto> {
  return productServiceProxy.getClientProductDetails(catalogId, id);
}

export function getProductDetails(id: number): Promise<ProductDetailsDto> {
  return productServiceProxy.getProductDetails(id);
}

export function getProductsForClientCatalog(
  categoryId: number,
  catalogId: number,
  maxResultCount: number,
  skipCount: number,
  filter: string
): Promise<PagedResultDtoOfProductListDto> {
  return productServiceProxy.getProductsForClientCatalog(
    categoryId,
    catalogId,
    maxResultCount,
    skipCount,
    filter,
  );
}

export function getProductsForMasterCatalog(
  categoryId: number,
  isArchived: boolean,
  maxResultCount: number,
  skipCount: number,
  filter: string
): Promise<PagedResultDtoOfProductListDto> {
  return productServiceProxy.getProductsForMasterCatalog(
    categoryId,
    isArchived,
    maxResultCount,
    skipCount,
    filter,
  );
}

export function archiveProduct(id: number): Promise<void> {
  return productServiceProxy.archiveProduct(id);
}

export function unArchiveProduct(id: number): Promise<void> {
  return productServiceProxy.unArchiveProduct({ id });
}
import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import {
  PreloaderIcon,
} from '../assets/icons';

const ImageContainer = styled.div`
  ${props => {

    let visibility = props.isLoaded ?
      'visible' :
      'hidden';

    return `
      > img{
        object-fit: cover;
        visibility: ${visibility};
      }
    `;
  }}
`;

const Thumbnail = styled(class extends React.PureComponent {

  static propTypes = {
    onThumbnailClick: PropTypes.func,
    className: PropTypes.string,
    image: PropTypes.string,
    name: PropTypes.string,
    id: PropTypes.number,
  };

  constructor(props) {
    super(props);
    this.state = {
      isLoaded: false,
    };

    this.handleLoad = this.handleLoad.bind(this);
    this.handleClick = this.handleClick.bind(this);
  }

  handleLoad() {
    this.setState({
      isLoaded: true,
    });
  }

  handleClick() {
    if (this.props.onThumbnailClick) {
      this.props.onThumbnailClick(this.props.id);
    }
  }

  render() {
    let {
      className,
      image,
      name,
    } = this.props;
    return <div className={className}>
      {
        !this.state.isLoaded &&
        <PreloaderIcon />
      }
      <ImageContainer isLoaded={this.state.isLoaded}>
        {
          image ?
            <img src={image} alt={name || 'thumbnail'} onLoad={this.handleLoad} onClick={this.handleClick} /> :
            <img src="/img/404.jpg" alt="thumbnail not found" onLoad={this.handleLoad} onClick={this.handleClick} />
        }
      </ImageContainer>
    </div>;

  }
}) `
  ${({ theme, ...props }) => {
    let {
      global,
      lighter,
      lightest,
    } = theme;

    let size;

    switch (props.size) {
      case 'xs':
        size = '34px';
        break;
      case 'sm':
        size = '50px';
        break;
      case 'md':
        size = '72px';
        break;
      case 'lg':
        size = '75px';
        break;
      case 'xl':
        size = '230px';
        break;
      default:
        size = '72px';
        break;
    }

    return `
      ${global}
      width: ${size};
      height: ${size};
      background-color: ${lightest};  
      border:1px solid ${lighter};  
      overflow: hidden;
      transition: all .5s ease-in-out;    
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      img {
        width: ${size};
        height: ${size};
      }
      .${PreloaderIcon.styledComponentId}{
        position: absolute;
        left: 50%;
        margin-left: -10px;
      }
    `;
  }}
`;

Thumbnail.propTypes = {
  className: PropTypes.string,
};

export default Thumbnail;
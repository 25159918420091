import { types } from '../_actionTypes/products';
import { types as typesNotification } from '../_actionTypes/notification';
import { productServiceProxy } from '../servicePool';
import {apiUrl} from '../_constants/system';

import {
  getSwaggerErrorResponse,
} from '../_utils/errorHandling';

export function create(data) {
  return function (dispatch) {

    dispatch({
      type: types.PRODUCTS_CREATE,
      status: 'saving',
    });

    productServiceProxy.createProduct(data)
      .then(id => {

        dispatch({
          type: types.PRODUCTS_CREATE,
          id,
          status: 'saved',
        });

      })
      .catch(e => {
        dispatch({
          type: types.PRODUCTS_CREATE,
          status: 'error',
        });
        dispatch({
          type: typesNotification.NOTIFICATION_NOTIFY,
          message: getSwaggerErrorResponse(e).error.message,
          messageType: 'error',
        });
      });

  };
}

export function update(data) {
  return function (dispatch) {

    dispatch({
      type: types.PRODUCTS_UPDATE,
      status: 'saving',
    });

    productServiceProxy.updateProduct(data)
      .then(id => {

        dispatch({
          type: types.PRODUCTS_UPDATE,
          id,
          status: 'saved',
        });

      })
      .catch(e => {
        dispatch({
          type: types.PRODUCTS_UPDATE,
          status: 'error',
        });
        dispatch({
          type: typesNotification.NOTIFICATION_NOTIFY,
          message: getSwaggerErrorResponse(e).error.message,
          messageType: 'error',
        });
      });

  };
}

export function getAll(categoryId, сlientUnitId = null, isInactive = false, perPage, skip, filter = '') {
  if (сlientUnitId === null){
    сlientUnitId = undefined;
  }
  return function (dispatch) {
    dispatch({
      type: types.PRODUCTS_GET_ALL,
      listStatus: 'loading',
      products: [],
      product: {},
      status: '',
      perPage,
      skip,
    });
    productServiceProxy.getProductsForClientCatalog(categoryId, сlientUnitId, perPage, skip, filter)
      .then(data => {
        let {
          items,
          totalCount,
        } = data;
        let products = items;
        dispatch({
          type: types.PRODUCTS_GET_ALL,
          products,
          totalCount,
          listStatus: 'loaded',
          perPage,
          skip,
        });
        return items;
      })
      .catch(e => {
        dispatch({
          type: types.PRODUCTS_GET_ALL,
          listStatus: 'error',
          perPage,
          skip,
        });
        dispatch({
          type: typesNotification.NOTIFICATION_NOTIFY,
          message: getSwaggerErrorResponse(e).error.message,
          messageType: 'error',
        });
      });
  };
}

export function getAllMaster(categoryId, сlientUnitId = null, isInactive = false, perPage, skip, filter = '') {
  if (сlientUnitId === null){
    сlientUnitId = undefined;
  }
  return function (dispatch) {
    dispatch({
      type: types.PRODUCTS_GET_ALL,
      listStatus: 'loading',
      products: [],
      product: {},
      status: '',
      perPage,
      skip,
    });
    productServiceProxy.getProductsForMasterCatalog(categoryId, isInactive, perPage, skip, filter)
      .then(data => {
        let {
          items,
          totalCount,
        } = data;
        let products = items;
        dispatch({
          type: types.PRODUCTS_GET_ALL,
          products,
          totalCount,
          listStatus: 'loaded',
          perPage,
          skip,
        });
        return items;
      })
      .catch(e => {
        dispatch({
          type: types.PRODUCTS_GET_ALL,
          listStatus: 'error',
          perPage,
          skip,
        });
        dispatch({
          type: typesNotification.NOTIFICATION_NOTIFY,
          message: getSwaggerErrorResponse(e).error.message,
          messageType: 'error',
        });
      });
  };
}

export function get(productId) {
  return function (dispatch) {
    dispatch({
      type: types.PRODUCTS_GET,
      product: {},
      status: 'loading',
    });
    productServiceProxy.getProductForEdit(productId)
      .then(product => {
        dispatch({
          type: types.PRODUCTS_GET,
          product,
          status: 'loaded',
        });
      })
      .catch(e => {
        dispatch({
          type: types.PRODUCTS_GET,
          status: 'error',
        });
        dispatch({
          type: typesNotification.NOTIFICATION_NOTIFY,
          message: getSwaggerErrorResponse(e).error.message,
          messageType: 'error',
        });
      });
  };
}

export function getProductDetails(productId) {
  return function (dispatch) {
    dispatch({
      type: types.PRODUCTS_GET_DETAILS,
      product: {},
      status: 'loading',
    });
    productServiceProxy.getProductDetails(productId)
      .then(product => {

        if (product.pictures) {
          product.pictures.forEach(picture => {
            picture.pictureUrl = apiUrl + picture.pictureUrl;
          });
        }

        dispatch({
          type: types.PRODUCTS_GET_DETAILS,
          product,
          status: 'loaded',
        });
      })
      .catch(e => {
        dispatch({
          type: types.PRODUCTS_GET_DETAILS,
          status: 'error',
        });
        dispatch({
          type: typesNotification.NOTIFICATION_NOTIFY,
          message: getSwaggerErrorResponse(e).error.message,
          messageType: 'error',
        });
      });
  };
}

export function archive(id) {
  return function (dispatch) {
    return productServiceProxy.archiveProduct(id)
      .then(data => {
        dispatch({
          type: types.PRODUCTS_ARCHIVE,
        });
      })
      .catch(e => {
        dispatch({
          type: types.PRODUCTS_ARCHIVE,
          listStatus: 'error',
        });
        dispatch({
          type: typesNotification.NOTIFICATION_NOTIFY,
          message: getSwaggerErrorResponse(e).error.message,
          messageType: 'error',
        });
      });
  };
}

export function unArchive(id) {
  return function (dispatch) {
    return productServiceProxy.unArchiveProduct({ id: id })
      .then(data => {
        dispatch({
          type: types.PRODUCTS_UNARCHIVE,
          listStatus: 'loaded',
        });
      })
      .catch(e => {
        dispatch({
          type: types.PRODUCTS_UNARCHIVE,
          listStatus: 'error',
        });
        dispatch({
          type: typesNotification.NOTIFICATION_NOTIFY,
          message: getSwaggerErrorResponse(e).error.message,
          messageType: 'error',
        });
      });
  };
}

export function changePage(perPage, skip) {
  return function (dispatch) {
    dispatch({
      type: types.PRODUCTS_CHANGE_PAGE,
      skip,
      perPage,
    });
  };
}

export function triggerInactive(status) {
  return function (dispatch) {
    dispatch({
      type: types.PRODUCTS_TRIGGER_INACTIVE,
      isInactive: status,
    });
  };
}

export function productsSetDone() {
  return function (dispatch) {
    dispatch({
      type: types.PRODUCTS_SET_DONE,
    });
  };
}
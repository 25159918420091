import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import {
  Tree,
} from '../components';

import ClientUnitBranch from "../containers/Admin/User/Fields/ClientUnitBranch";
import { updateBrunch, fillTree, setParents, updateTree } from "../_utils/data";

export default styled(class extends React.Component {
  static propTypes = {
    label: PropTypes.string,
    className: PropTypes.string,
    nodes: PropTypes.arrayOf(PropTypes.object).isRequired,
    addressOverrides: PropTypes.arrayOf(PropTypes.object),
    addressOverridesStatus: PropTypes.string,
    activeIds: PropTypes.arrayOf(PropTypes.number).isRequired,
    onActiveIdsChange: PropTypes.func.isRequired,
    onAddressOverrideClick: PropTypes.func.isRequired,
    blockActiveChildren: PropTypes.bool,
    isLoading: PropTypes.bool,
    disabled: PropTypes.bool,
    error: PropTypes.string,
    isNew: PropTypes.bool,
  };

  constructor(props) {
    super(props);

    this.updateTree = this.updateTree.bind(this);
    this.handleClientUnitBranchTrigger = this.handleClientUnitBranchTrigger.bind(this);
    this.handleClientUnitBranchSelect = this.handleClientUnitBranchSelect.bind(this);
    this.handleAddressOverrideClick = this.handleAddressOverrideClick.bind(this);

    this.state = this.initState(this.props.nodes, this.props.activeIds, this.props.addressOverrides);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.nodes !== this.props.nodes
      || prevProps.activeIds !== this.props.activeIds
      || (prevProps.addressOverrides !== this.props.addressOverrides && this.props.addressOverridesStatus === 'loaded')
    ) {
      this.setState(this.initState(this.props.nodes, this.props.activeIds, this.props.addressOverrides));
    }
  }

  initState(nodes, activeIds, addressOverrides) {
    nodes.forEach((node) => {
      setParents(node, null);
    });

    nodes = updateTree(nodes, (node) => {
      if (addressOverrides) {
        var override = addressOverrides.find(a => a.clientUnitId === node.id);
        if (override) {
          node.addressOverride = override;
        } else if (node.type === 3) {
          node.addressOverride = {
            name: "+ add location override",
            clientUnitId: node.id
          };
        }
      }

      return node;
    });

    return this.updateTree([...nodes], [...activeIds], null, null, false);
  }

  updateTree(nodes, activeIds, currentElementId, currentValue, fireActiveIdsChangeEvent = true) {
    let nodesData = fillTree(nodes, activeIds, currentElementId, currentValue, 'id', 'children', false, true, true, this.props.blockActiveChildren);

    if (fireActiveIdsChangeEvent) {
      this.props.onActiveIdsChange(nodesData.activeIds);
    }

    return {nodes: nodesData.nodes, activeIds: nodesData.activeIds};
  }

  handleAddressOverrideClick(node) {
    this.props.onAddressOverrideClick(node.addressOverride);
  }

  handleClientUnitBranchTrigger(e, path) {
    let newClientUnits = updateBrunch(
      [...this.state.nodes],
      path,
      object => object.expanded = !object.expanded,
      'children'
    );

    this.setState({nodes: newClientUnits});
  }

  handleClientUnitBranchSelect(e, id) {
    let activeIds = this.state.activeIds;
    let activeIdIndex = this.state.activeIds.indexOf(id);

    if (activeIdIndex !== -1) {
      activeIds.splice(activeIdIndex, 1);
    } else {
      activeIds.push(id);
    }

    this.setState(this.updateTree([...this.state.nodes], [...activeIds], id, e));
  }

  render() {

    let {
      className,
      error,
      disabled,
      label,
      isLoading
    } = this.props;

    let {nodes} = this.state;

    return <div className={className}>
      <Tree
        striped
        isLoading={isLoading}
        label={label}
        nodes={nodes}
        disabled={disabled}
        onChange={() => {return true;}}
        error={error}
        renderParent={props => <ClientUnitBranch
          onSelect={this.handleClientUnitBranchSelect}
          disabled={disabled}
          onTrigger={this.handleClientUnitBranchTrigger}
          {...props}
        />}
        renderChild={props => <ClientUnitBranch
          isNew={this.props.isNew}
          disabled={disabled}
          addressOverridesStatus={this.props.addressOverridesStatus}
          onSelect={this.handleClientUnitBranchSelect}
          onTrigger={this.handleClientUnitBranchTrigger}
          onAddressOverrideClick={this.handleAddressOverrideClick}
          {...props}
        />}
      />
    </div>;
  }

})`
  ${({theme}) => {

  let {
    global,
  } = theme;

  return `
      ${global}
    `;
}}
`;

import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Tree, Checkbox } from './index';

const Position = {
  TreeNodeLabel: styled.span`
  display: inline-block;
  vertical-align: middle;
  padding: 4px 0 4px ${({depth, isLeaf}) => ((depth - 1) * 26 + 10 + (isLeaf ? 50 : 0))}px;
`,
  TreeNodeSpan: styled.span`
  display: inline-block;
  width: 30px;
  height: 38px;
  padding-left: 20px;
  vertical-align: middle;
`
};

const ToggleIcon = styled(({className, expanded, onToggle}) => {
  return (
    <div className={className}>
      <svg viewBox="0 0 26 26" width="26" height="26" onClick={onToggle}>
        <circle cx="13" cy="13" r="10" strokeWidth="1" />
        <line  x1="7" y1="13" x2="19" y2="13" strokeWidth="2" />
        {!expanded && <line  x1="13" y1="7" x2="13" y2="19" strokeWidth="2" />}
      </svg>
    </div>
  );
})`
  ${ ({theme, expanded}) => `
    width: 24px;
    height: 38px;
    padding: 6px 0;
    vertical-align: middle;
    svg {
      cursor: pointer;
      circle {
        fill:  ${expanded ? theme.main : theme.default};
        stroke: ${expanded ? theme.darker :  theme.default};
      }
      line {
        stroke: ${expanded ? theme.primary : theme.main};
      }
    }
  `}
`;

const TreeToggle = ({expanded, onToggle}) => {
  return (
    <Position.TreeNodeSpan>
      <ToggleIcon expanded={expanded} onToggle={onToggle} />
    </Position.TreeNodeSpan>
  );
};

TreeToggle.propTypes = {
  onToggle: PropTypes.func,
  expanded: PropTypes.bool,
};

const TreeNode = styled(({className, depth, children, node, checked, onChanged, collapsed, onCollapseNode, path, disabled}) => {
  return (
    <React.Fragment>
      <div className={className}>
        {children && <TreeToggle expanded={!collapsed} onToggle={() => onCollapseNode(node.name, !collapsed)}/>}
        <Position.TreeNodeLabel depth={depth} isLeaf={!children}>
          <Checkbox
            disabled={disabled}
            id={node.name}
            label={node.displayName}
            value={checked}
            onChange={value => {onChanged(node.name,  value, path);}}
          />
        </Position.TreeNodeLabel>
      </div>
      {!collapsed && children}
    </React.Fragment>
  );
})`
  border-bottom: 1px solid ${({theme}) => theme.light};
`;

class PermissionsTree extends React.Component {

  constructor(props) {
    super(props);

    let tree = props.permissionsTree;

    this.state = {
      permissionsTree: tree,
    };
  }

  render() {
    let { permissionsTree } = this.state;

    return (
      <div className={this.props.className}>
        <Tree
          onChange={() => true}
          required={this.props.required}
          nodes={permissionsTree}
          renderParent={({node, depth, children, path}) => (
            <TreeNode
              disabled={this.props.disabled}
              checked={this.props.valuesMap[node.name]}
              collapsed={this.props.collapsedMap[node.name]}
              depth={depth}
              node={node}
              path={path}
              onCollapseNode={this.props.onCollapseNode}
              onChanged={this.props.onChanged}>
                {children}
            </TreeNode>)
          }
          renderChild={({node, depth, path}) => (
            <TreeNode
              disabled={this.props.disabled}
              checked={this.props.valuesMap[node.name]}
              collapsed={this.props.collapsedMap[node.name]}
              depth={depth}
              node={node}
              path={path}
              onChanged={this.props.onChanged}
              onCollapseNode={this.props.onCollapseNode}
            />)
          }
          childrenKey="children"
        />
      </div>
    );
  }
}

PermissionsTree.propTypes = {
  valuesMap: PropTypes.object.isRequired,
  collapsedMap: PropTypes.object.isRequired,
  permissionsTree: PropTypes.arrayOf(PropTypes.object).isRequired,
  onChanged: PropTypes.func.isRequired,
  onCollapseNode: PropTypes.func.isRequired,
  onToggle: PropTypes.func,
  className: PropTypes.string,
  required: PropTypes.bool,
  expanded: PropTypes.bool,
  disabled: PropTypes.bool,
};

PermissionsTree.defaultProps = {
  required: false,
};

export default styled(PermissionsTree)`
  label {
    margin-left: 20px;
  }
`;

import * as React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { Formik } from "formik";
import * as Yup from "yup";

import * as actions from "../../_actions/current-user";
import Container from "./LoginContainer";
import LoginForm from "./LoginForm";
import { AuthenticateModel } from "../../service-proxies";
import { createStructuredSelector } from "reselect";
import { IGlobalStore } from "../../_reducers/reducers";
import { Link } from "src/components";

const loginValidationSchema = Yup.object().shape({
  userNameOrEmailAddress: Yup.string().required(
    "User name or email is required!"
  ),
  password: Yup.string().required("Password is required!")
});

const loginInitialValues: AuthenticateModel = {
  userNameOrEmailAddress: "",
  password: "",
  rememberClient: false
};

interface ILoginProps extends IConnectedActions, IConnectedProps {}

class Login extends React.Component<ILoginProps> {
  constructor(props) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleSubmit(values, { setSubmitting, setErrors }) {
    setSubmitting(true);
    this.props.login(values).then(() => {
      setSubmitting(false);
    });
  }

  render() {
    return (
      <Container>
        <div className="logo">
          <img src="/img/otto-logo.svg" width="140" alt="Otto logo" />
        </div>

        <Formik
          initialValues={loginInitialValues}
          validationSchema={loginValidationSchema}
          onSubmit={this.handleSubmit}
          render={props => (
            <LoginForm
              {...props}
              errorMessage={this.props.errorMessage}
              errorDetails={this.props.errorDetails}
            />
          )}
        />
        <Link primary to="/forgot-password">
          Reset Password
        </Link>
      </Container>
    );
  }
}

interface IConnectedProps {
  errorMessage: string | null;
  errorDetails: string | null;
}

interface IConnectedActions {
  login: (data: AuthenticateModel) => Promise<void>;
}

export default connect(
  createStructuredSelector<IGlobalStore, IConnectedProps>({
    errorMessage: state =>
      state.currentUser.loginError
        ? state.currentUser.loginError.message
        : null,
    errorDetails: state =>
      state.currentUser.loginError ? state.currentUser.loginError.details : null
  }),
  dispatch =>
    bindActionCreators(
      {
        login: (actions.login as any) as (
          data: AuthenticateModel
        ) => Promise<void>
      },
      dispatch
    )
)(Login);

import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import styled from 'styled-components';
import withGranted from '../../../containers/HOC/WithGranted';
import { getRoles } from '../../../_actions/roles';
import { getUserPermissions } from '../../../_actions/users';
import { createStructuredSelector } from 'reselect/es';
import { Checkbox, SkeletonGroup } from '../../../components';

import {
  FormGrid as Grid,
} from '../../../components';

import {
  ADMINISTRATION_USERS_PERMISSIONS_EDIT,
} from "../../../_constants/permissions";


const Position = {
  RoleWrapper: styled.div`
    padding: 10px;
`
};

class EditUserRoles extends React.Component {
  static propTypes = {
    values: PropTypes.object.isRequired,
    errors: PropTypes.object.isRequired,
    touched: PropTypes.object.isRequired,
    handleChange: PropTypes.func.isRequired,
    handleBlur: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    isSubmitting: PropTypes.bool.isRequired,
    setFieldValue: PropTypes.func.isRequired,
    setFieldError: PropTypes.func.isRequired,
    setFieldTouched: PropTypes.func.isRequired,
    isLoading: PropTypes.bool,
    disabled: PropTypes.bool,
    isNew: PropTypes.bool,
    getRoles: PropTypes.func.isRequired,
    getUserPermissions: PropTypes.func,
    allRoles: PropTypes.array,
  };

  constructor(props) {
    super(props);

    this.state = {
      rolesMap: {}
    };

    this.handleRoleToggle = this.handleRoleToggle.bind(this);
  }

  componentDidMount() {
    if (!this.props.values.isAllRolesLoaded) {
      this.props.getRoles('', 'name ASC', 0, 100) // get all roles
        .then(() => {
          this.props.setFieldValue('isAllRolesLoaded', true);
        });
    }

    if (this.props.isGranted(ADMINISTRATION_USERS_PERMISSIONS_EDIT)) {
      if (!this.props.values.permissionsMap) {
        if (this.props.values.id === 'new') {
          this.props.setFieldValue('permissionsMap', {});
        } else {
          this.props.getUserPermissions(this.props.values.id).then(permissions => {
            this.props.setFieldValue('permissionsMap', permissions.reduce((ac, ce) => {
              ac[ce] = true;
              return ac;
            }, {}));
          });
        }
      }
    }
  }

  handleRoleToggle(role, value) {

    let rolesMap = {
      ...this.props.values.rolesMap,
    };

    let permissionsMap = {...this.props.values.permissionsMap};

    if (value === true) {
      rolesMap[role.name] = true;
      role.permissions.forEach(permissionName => {
        permissionsMap[permissionName] = true;
      });
    } else {
      /**
       * logic for removing permissions if it's not included in any other roles.
       */
      delete rolesMap[role.name];

      role.permissions.forEach(permissionName => {
        if (!this.props.allRoles.some(r => (rolesMap[r.name] &&
            r.permissions.some(permission => permission === permissionName)))) {
          delete permissionsMap[permissionName];
        }
      });
    }
    this.props.setFieldValue('rolesMap', rolesMap);

    if (this.props.isGranted(ADMINISTRATION_USERS_PERMISSIONS_EDIT)) {
      this.props.setFieldValue('permissionsMap', permissionsMap);
    }
  }

  render() {
    return (
      <Grid.Body>
        <Grid.Row>
          <Grid.Cell>
            {this.props.values.isAllRolesLoaded ?
              this.props.allRoles.map(role => (
                <Position.RoleWrapper key={role.name}>
                  <Checkbox
                    id={role.name}
                    disabled={this.props.disabled}
                    label={role.name}
                    value={!!this.props.values.rolesMap[role.name]}
                    onChange={(v) => this.handleRoleToggle(role, v)} />
                </Position.RoleWrapper>
              ))
              :
              <SkeletonGroup rows={10} size="lg" />
            }
          </Grid.Cell>
        </Grid.Row>
      </Grid.Body>
    );
  }
}

export default connect(
  createStructuredSelector({
    allRoles: state => state.roles.roles,
  }),
  {
    getRoles,
    getUserPermissions,
  }
)(withGranted(EditUserRoles));

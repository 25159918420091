import { categoryServiceProxy, productServiceProxy } from '../servicePool';
import {
  ListResultDtoOfCountDtoOfInt64,
  ListResultDtoOfGetСategoriesDto,
} from '../service-proxies';

export function getMasterCategories() {
  return categoryServiceProxy.getCategoriesForMasterCatalog();
}

export function getClientCategories(catalogId: number): Promise<ListResultDtoOfGetСategoriesDto> {
  return categoryServiceProxy.getCategoriesForClientCatalog(catalogId);
}

export function getCountForClientCatalog(catalogId: number, filter: string): Promise<ListResultDtoOfCountDtoOfInt64> {
  return productServiceProxy.getCountForClientCatalog(catalogId, filter);
}

export function getCountForMasterCatalog(isArchived: boolean, filter: string): Promise<ListResultDtoOfCountDtoOfInt64> {
  return productServiceProxy.getCountForMasterCatalog(isArchived, filter);
}

export function createMasterCategory(name: string, parentCategoryId: number, ) {
  return categoryServiceProxy.createCategoryForMasterCatalog({
    parentCategoryId,
    name,
    sortingPriority: 0,
  });
}

export function deleteCategory(categoryId: number) {
  return categoryServiceProxy.deleteCategoryFromMasterCatalog(categoryId);
}

import * as React from "react";
import styled from "styled-components";
import { Formik } from "formik";
import * as Yup from "yup";
import { createStructuredSelector } from "reselect";
import { RouteComponentProps, withRouter } from "react-router";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import moment from "moment";

import {
  ORDERS_BUYERORDERS_CANCEL,
  ORDERS_BUYERORDERS_EDIT,
  ORDERS_BUYERORDERS_SUBMITORAPPROVE
} from "../../../_constants/permissions";

import { IGlobalStore } from "../../../_reducers/reducers";
import withGranted, { IWithGrantedProps } from "../../HOC/WithGranted";
import { history } from "../../../store";
import {
  prompt as openConfirm,
  IOpenConfirmPrompt
} from "../../../_actions/modal";

import { notifySwagger } from "../../../_actions/notification";

import {
  approveBuyerOrder,
  submitBuyerOrder,
  cancelBuyerOrder,
  updateBuyerOrder
} from "../../../_api/orders";

import {
  MainContainer,
  TabsSwitch,
  Button,
  Article,
  Link,
  Skeleton
} from "../../../components";

import Title from "./fields/Title";
import ViewAll from "./ViewAll";

import * as ordersApi from "../../../_api/orders";

import { EditBuyerOrderDto } from "../../../service-proxies";

import withMenu from "../../../containers/HOC/WithMenu";

import { INTEGER_SIZE } from "../../../_constants/data";
import { removeHashSign } from "src/_utils/misc";

// positions
const Tabs = styled.div`
  display: flex;
  width: 100%;
  padding: 20px;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const Body = styled.div`
  height: 100%;
`;

const Table = styled.div`
  height: 100%;
`;

const Footer = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 20px 20px 30px;
`;

const Info = styled.div`
  text-align: right;
  padding: 0 20px;
`;

const FooterElement = styled.div`
  > * {
    margin-left: 10px;
  }
`;

const Comment = styled.div`
  padding: 0 20px;
`;

const Position = class {
  static Tabs = Tabs;
  static Table = Table;
  static Content = Content;
  static Body = Body;
  static Footer = Footer;
  static Info = Info;
  static FooterElement = FooterElement;
  static Comment = Comment;
};

// interfaces
interface IViewProps
  extends IWithGrantedProps,
    IConnectedProps,
    IConnectedActions,
    RouteComponentProps<{ orderId: string }> {
  handleTabChange: (tab: string) => void;
}

interface ITabItem {
  value: string;
  label: string;
}

export interface IViewState {
  tabs: ITabItem[];
  tab: string;
  orderStatus: string;
  order?: EditBuyerOrderDto;
  changed: boolean;
}

const ordersValidationSchema = Yup.object().shape({
  order: Yup.object().shape({
    items: Yup.array().of(
      Yup.object().shape({
        quantity: Yup.number()
          .typeError("must be a number")
          .integer("must be a number")
          .min(0, "can't be negative")
          .max(INTEGER_SIZE, `can't be grater than ${INTEGER_SIZE}`)
      })
    )
  })
});

class View extends React.Component<IViewProps, IViewState> {
  public orderId: number;
  public form: Formik<IViewState>;

  constructor(props) {
    super(props);

    this.orderId = parseInt(this.props.match.params.orderId, 10);

    this.handleTabChange = this.handleTabChange.bind(this);
    this.getOrder = this.getOrder.bind(this);
    this.setChanged = this.setChanged.bind(this);
    this.handleClickCancel = this.handleClickCancel.bind(this);
    this.handleApprove = this.handleApprove.bind(this);
    this.handleSubmitOrder = this.handleSubmitOrder.bind(this);
    this.handleClickBack = this.handleClickBack.bind(this);
    this.handleSaveEdits = this.handleSaveEdits.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);

    this.state = {
      tab: "all",
      tabs: [
        { value: "all", label: "View All" }
        /* { value: 'edited', label: 'Edited' },*/
      ],
      orderStatus: "",
      changed: false
    };
  }

  componentDidMount() {
    this.getOrder();
  }

  getOrder(): void {
    this.setState({
      orderStatus: "loading"
    });

    ordersApi
      .getBuyerOrderForEdit(this.orderId)
      .then(orderData => {
        const orderItemsCount = orderData.items.length;
        const tabs = this.state.tabs;
        tabs[0].label = `${tabs[0].label} (${orderItemsCount})`;

        this.setState({
          order: orderData,
          orderStatus: "loaded"
        });
      })
      .catch(e => {
        this.props.notifySwagger(e, "error");
      });
  }

  setChanged(): void {
    this.setState({ changed: true });
  }

  handleTabChange(tab: string): void {
    this.setState({ tab });
  }

  handleClickCancel(): void {
    this.setState({ orderStatus: "saving" });
    cancelBuyerOrder(this.state.order.id)
      .then(() => {
        history.push("/orders/buyer");
      })
      .catch(e => {
        this.props.notifySwagger(e, "error");
      });
  }

  handleApprove(): void {
    if (Object.keys(this.form.getFormikBag().errors).length) {
      return;
    }

    this.setState({ orderStatus: "saving" });

    if (this.state.changed) {
      this.saveEdits(this.form.state.values)
        .then(() => {
          approveBuyerOrder(this.state.order.id)
            .then(() => {
              history.push("/orders/buyer");
              this.setState({ orderStatus: "saved" });
            })
            .catch(e => {
              this.props.notifySwagger(e, "error");
            });
        })
        .catch(e => {
          this.props.notifySwagger(e, "error");
          this.setState({ orderStatus: "saved" });
        });
    } else {
      approveBuyerOrder(this.state.order.id)
        .then(() => {
          history.push("/orders/buyer");
          this.setState({ orderStatus: "saved" });
        })
        .catch(e => {
          this.props.notifySwagger(e, "error");
          this.setState({ orderStatus: "saved" });
        });
    }
  }

  validateCanceledOrder(): Promise<void> {
    if (
      this.state.order.items.every(item => !item.isActive) ||
      this.state.order.items.every(item => !item.quantity)
    ) {
      return this.props.openConfirm({
        title: "Warning!",
        question:
          "You are fully cancelling this order and it will not process. Please confirm that that is your intent."
      });
    } else {
      return Promise.resolve();
    }
  }

  handleSubmitOrder(): void {
    if (Object.keys(this.form.getFormikBag().errors).length) {
      return;
    }

    this.validateCanceledOrder().then(
      () => {
        this.setState({ orderStatus: "saving" });

        if (this.state.changed) {
          this.saveEdits(this.form.state.values)
            .then(() => {
              submitBuyerOrder(this.state.order.id)
                .then(() => {
                  history.push("/orders/buyer");
                  this.setState({ orderStatus: "saved" });
                })
                .catch(e => {
                  this.props.notifySwagger(e, "error");
                });
            })
            .catch(e => {
              this.props.notifySwagger(e, "error");
              this.setState({ orderStatus: "saved" });
            });
        } else {
          submitBuyerOrder(this.state.order.id)
            .then(() => {
              history.push("/orders/buyer");
              this.setState({ orderStatus: "saved" });
            })
            .catch(e => {
              this.props.notifySwagger(e, "error");
              this.setState({ orderStatus: "saved" });
            });
        }
      },
      () => {}
    );
  }

  handleClickBack(): void {
    history.goBack();
  }

  handleSaveEdits(): void {
    this.form.submitForm();
  }

  handleSubmit(values: IViewState): void {
    this.setState({ orderStatus: "saving" });
    this.saveEdits(values)
      .then(() => {
        this.setState({ orderStatus: "saved", changed: false });
      })
      .catch(e => {
        this.props.notifySwagger(e, "error");
        this.setState({ orderStatus: "saved" });
      });
  }

  saveEdits(values: IViewState): Promise<void> {
    const updatedOrderItems = values.order.items.map(item => {
      const newItem = {
        isActive: item.isActive,
        quantity: item.quantity,
        productId: item.product.id
      };
      return newItem;
    });

    return updateBuyerOrder(values.order.id, updatedOrderItems, values.order.clientPoNumber);
  }

  render() {
    return (
      <MainContainer
        centered
        scale={"md"}
        renderTitle={
          <Title label="Please review this order for buyer">
            {this.state.orderStatus === "loading" && (
              <React.Fragment>
                <Title.Element>
                  <Skeleton.Line />
                </Title.Element>
                <Title.Element>
                  <Skeleton.Line />
                </Title.Element>
                <Title.Element>
                  <Skeleton.Line />
                </Title.Element>
              </React.Fragment>
            )}
            {this.state.orderStatus === "loaded" && (
              <React.Fragment>
                <Title.Element>
                  Buyer: {this.state.order.creatorUser.displayValue}
                </Title.Element>
                <Title.Element>
                  Order #: {removeHashSign(this.state.order.number)}
                </Title.Element>
                <Title.Element>
                  Date/Time:{" "}
                  {moment(this.state.order.creationTime).format(
                    "MM/DD/YYYY LT"
                  )}
                </Title.Element>
              </React.Fragment>
            )}
          </Title>
        }
      >
        <Position.Content>
          <Position.Body>
            <Position.Tabs>
              <TabsSwitch
                items={this.state.tabs}
                onTabChanged={this.handleTabChange}
              />
            </Position.Tabs>
            {
              <Formik
                enableReinitialize
                initialValues={this.state}
                validationSchema={ordersValidationSchema}
                onSubmit={this.handleSubmit}
                ref={node => (this.form = node)}
                render={props => {
                  if (this.state.tab === "all") {
                    return <ViewAll {...props} onChange={this.setChanged} />;
                  }
                  return null;
                }}
              />
            }
            <Position.Info>
              {this.state.orderStatus === "loaded" && (
                <Article.P scale="sm">
                  Facility Location: {this.state.order.location.displayValue}
                </Article.P>
              )}
            </Position.Info>
            <Position.Comment>
              <Article.P>
                {this.state.order && this.state.order.comment}
              </Article.P>
            </Position.Comment>
          </Position.Body>
          <Position.Footer>
            <Position.FooterElement />
            <Position.FooterElement>
              <Link.Button
                onClick={this.handleClickBack}
                preloader={
                  this.state.orderStatus === "loading" ||
                  this.state.orderStatus === "saving"
                }
              >
                Back
              </Link.Button>
              {this.props.isGranted(ORDERS_BUYERORDERS_CANCEL) &&
                this.state.order &&
                (this.state.order.shouldBeApproved ||
                  this.state.order.shouldBeSubmitted) && (
                  <Button
                    primary
                    onClick={this.handleClickCancel}
                    preloader={
                      this.state.orderStatus === "loading" ||
                      this.state.orderStatus === "saving"
                    }
                  >
                    Cancel Order
                  </Button>
                )}
              {this.state.changed &&
                this.state.order &&
                this.props.isGranted(ORDERS_BUYERORDERS_EDIT) &&
                (this.state.order.shouldBeApproved ||
                  this.state.order.shouldBeSubmitted) && (
                  <Button
                    onClick={this.handleSaveEdits}
                    preloader={
                      this.state.orderStatus === "loading" ||
                      this.state.orderStatus === "saving"
                    }
                  >
                    Save Edits
                  </Button>
                )}
              {this.state.order &&
                this.props.isGranted(ORDERS_BUYERORDERS_SUBMITORAPPROVE) &&
                this.state.order.shouldBeSubmitted && (
                  <Button
                    onClick={this.handleSubmitOrder}
                    preloader={
                      this.state.orderStatus === "loading" ||
                      this.state.orderStatus === "saving"
                    }
                    disabled={
                      this.state.orderStatus === "loading" ||
                      this.state.orderStatus === "saving"
                    }
                  >
                    Submit Order
                  </Button>
                )}
              {this.state.order &&
                this.props.isGranted(ORDERS_BUYERORDERS_SUBMITORAPPROVE) &&
                this.state.order.shouldBeApproved && (
                  <Button
                    onClick={this.handleApprove}
                    preloader={
                      this.state.orderStatus === "loading" ||
                      this.state.orderStatus === "saving"
                    }
                    disabled={
                      this.state.orderStatus === "loading" ||
                      this.state.orderStatus === "saving"
                    }
                  >
                    Approve Order
                  </Button>
                )}
            </Position.FooterElement>
          </Position.Footer>
        </Position.Content>
      </MainContainer>
    );
  }
}

interface IConnectedProps {
  locationName: string;
  catalogId: number;
}

interface IConnectedActions {
  notifySwagger: typeof notifySwagger;
  openConfirm: IOpenConfirmPrompt;
}

export default withRouter(
  withMenu(
    connect(
      createStructuredSelector<IGlobalStore, IConnectedProps>({
        locationName: state => state.currentUser.locationInfo.name,
        catalogId: state => state.currentUser.catalogId
      }),
      dispatch =>
        bindActionCreators(
          {
            notifySwagger,
            openConfirm: (openConfirm as any) as IOpenConfirmPrompt
          },
          dispatch
        )
    )(withGranted(View)),
    false
  )
);

import React, { Fragment } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import {
  Link,
  Checkbox,
} from '../../../../components';

import {
  TriangleIcon,
} from '../../../../assets/icons';

export const Label = styled.div`${({theme}) => {
  let {
    global,
  } = theme;
  return `
      ${global}
      display: flex;
      align-items: center;
    `;
}}`;

export default styled(class extends React.Component {
  static propTypes = {
    path: PropTypes.array.isRequired,
    onTrigger: PropTypes.func,
    onSelect: PropTypes.func.isRequired,
    onAddressOverrideClick: PropTypes.func,
    node: PropTypes.object.isRequired,
    className: PropTypes.string.isRequired,
    children: PropTypes.any,
    isNew: PropTypes.bool,
    disabled: PropTypes.bool,
    addressOverridesStatus: PropTypes.string
  };

  constructor(props) {
    super(props);

    this.isGroup = this.isGroup.bind(this);
    this.handleAddressOverrideClick = this.handleAddressOverrideClick.bind(this);
    this.handleTrigger = this.handleTrigger.bind(this);
    this.handleSelect = this.handleSelect.bind(this);
  }

  handleAddressOverrideClick() {
    if(this.props.onAddressOverrideClick){
      this.props.onAddressOverrideClick(this.props.node);
    }
  }

  handleTrigger(e) {
    if (this.isGroup()) {
      if(this.props.onTrigger){
        this.props.onTrigger(e, this.props.path);
      }
    }
  }

  handleSelect(e) {
    if(this.props.onSelect){
      this.props.onSelect(e, this.props.node.id, this.props.path);
    }
  }

  isGroup() {
    return this.props.node.children && this.props.node.children.length > 0;
  }

  render() {
    let {
      className,
      children,
      node,
      disabled,
      isNew,
      addressOverridesStatus
    } = this.props;

    let valueChecked = node.active || node.blocked;
    let isAddressOverrideVisible = !isNew && addressOverridesStatus === 'loaded' && node.addressOverride && valueChecked;

    return <Fragment>
      <li className={className}>

        <Link.Button
          onClick={this.handleTrigger}
        >
          <TriangleIcon/>
        </Link.Button>

        <Checkbox
          disabled={node.blocked || disabled}
          value={valueChecked}
          indeterminate={node.indeterminate}
          onChange={this.handleSelect}
        />
        <Label>
          <Link.Button onClick={this.handleTrigger}>
            {node.name}
          </Link.Button>
        </Label>
        {
          isAddressOverrideVisible && <Label>
            <Link.Button
              disabled={disabled}
              onClick={this.handleAddressOverrideClick}
            >
              <span>
                {node.addressOverride.name}
              </span>
            </Link.Button>
          </Label>
        }
      </li>
      {
        node.expanded &&
        children
      }
    </Fragment>;
  }
})`${({theme, ...props}) => {

  let {
    primary,
    lighter,
  } = theme;

  const globalIndent = 20;

  let triangleRotate = !props.node.expanded ?
    'rotate(-90deg)' :
    'rotate(0deg)';

  let isGroup = props.node.children && props.node.children.length > 0;
  
  let triangleStyles = isGroup ? '' : 'visibility: hidden;';
  let buttonStyles = isGroup ? '' : 'cursor: default;';

  let indent = `${((props.depth - 1) * 26) + globalIndent}px`;

  return `
      display: flex;
      flex-wrap: wrap;
      list-style: none;
      border-bottom: 1px solid ${lighter};
      padding: 8px 10px;
      padding-left: ${indent}; 
      > .${Link.Button.styledComponentId} {
        ${buttonStyles}
        margin: 0 3px;
        > .${TriangleIcon.styledComponentId} {
          ${triangleStyles}
          transform: ${triangleRotate};
        }
      }

      > .${Checkbox.styledComponentId} {
        margin: 0 3px;
      }

      > .${Label.styledComponentId} {
        width: 265px;
        
        > .${Link.Button.styledComponentId} {
          ${buttonStyles}
          color: ${primary};
          width: 100%;
          font-weight: 300;
          margin-left: 7px;
         
          > span {
            color: ${theme.default};
            width: 100%;
            text-align: center;
            cursor: pointer;
          }
        }
      }

    `;
}}`;

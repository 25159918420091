import React from 'react';
import styled from 'styled-components';

const OrderIcon = props => {
  return(
    <i {...props}>
      <svg viewBox="0 0 26 26" width="20" height="20">
        <path d=" M 13 0 C 5.832 0 0 5.832 0 13 C 0 20.168 5.832 26 13 26 C 20.168 26 26 20.168 26 13 C 26 5.832 20.168 0 13 0 Z  M 13 2 C 15.181 2 17.212 2.631 18.92 3.721 C 21.977 5.673 24 9.095 24 13 C 24 19.086 19.086 24 13 24 C 6.914 24 2 19.086 2 13 C 2 6.914 6.914 2 13 2 Z  M 10 9 L 6 13 L 10 17 L 10 14 L 20 14 L 20 12 L 10 12 L 10 9 Z "/>
      </svg>
    </i>
  );
};

export default styled(OrderIcon)`
  ${ props => `
    width: 21px;
    height: 21px;
    display: inline-flex;
    justify-content: center;

    svg{
      width: 100%;
      height: 100%;
      path{
        fill: ${props.theme.default}; 
      }
    }
  `}
`;

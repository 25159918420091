import styled from 'styled-components';
import { IThemeProps } from '../assets/theme';

export interface IMessageBarProps extends IThemeProps {
  type?: 'default' | 'warning' | 'success';
}

export default styled<IMessageBarProps, 'div'>('div')`
    ${({ theme, ...props }) => {

    const {
      global,
      fs_md,
      main,
    } = theme;

    let bgColor;

    switch (props.type) {
      case 'default': bgColor = theme.default;
        break;
      case 'warning': bgColor = theme.warning;
        break;
      case 'success': bgColor = theme.success;
        break;
      default: bgColor = theme.default;
    }

    return `
        ${global}
        background: ${bgColor};
        padding: 8px;
        font-size: ${fs_md};
        color: ${main};
        text-align: center;
      `;
  }}
`;
import * as React from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { IGlobalStore } from '../../_reducers/reducers';
import {
  Article,
} from '../../components';

const HeaderWrapper = styled.div`
  flex: 1;
  padding-left: 16px;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

class CartHeader extends React.PureComponent<IConnectedProps> {
  render() {
    return (
      <HeaderWrapper>
        <Article.Text scale="lg"><strong>NEW ORDER</strong> / {this.props.locationName}</Article.Text>
      </HeaderWrapper>
    );
  }
}

interface IConnectedProps {
  locationName?: string;
}

export default connect(
  createStructuredSelector<IGlobalStore, IConnectedProps>({
    locationName: state => state.currentUser.locationInfo && state.currentUser.locationInfo.name
  })
)(CartHeader);
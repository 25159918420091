import * as React from "react";
import styled from "styled-components";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { Formik } from "formik";
import moment from "moment";
import { updateInvoicePaidStatus } from "../../_api/invoices";
import { UpdateInvoicePaidStatusDto } from "./../../service-proxies";

import { InvoiceDetailsDto } from "../../service-proxies";

import withGranted, { IWithGrantedProps } from "../HOC/WithGranted";
import { IGlobalStore } from "../../_reducers/reducers";
import { notify, notifySwagger } from "../../_actions/notification";
import { history } from "../../store";
import { MainContainer } from "../../components";
import { IMatch } from "../../_types/common";
import withMenu from "../../containers/HOC/WithMenu";
import Form from "./ViewForm";
import Title from "../../sharedComponents/Title";
import { getInvoiceDetails } from "../../_api/invoices";
import * as Skeleton from "../../components/Skeleton";
import { removeHashSign } from "../../_utils/misc";

// positions
const Tabs = styled.div`
  display: flex;
  width: 100%;
  padding: 20px;
`;

const Content = styled.div`
  form {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
  }
`;

const Position = class {
  static Tabs = Tabs;
  static Content = Content;
};

interface IViewProps extends IConnectedActions, IWithGrantedProps {
  match: IMatch;
  locationId: number;
  locationName: string;
  catalogId: number;
  taxRate: number;
  subTotalAmount: number;
  totalAmount: number;
  taxesTotal: number;
}

export interface IViewInvoiceState extends Partial<InvoiceDetailsDto> {
  invoiceStatus: string;
  taxRate: number;
  subTotalAmount: number;
  totalAmount: number;
  taxesTotal: number;
  isPaid: boolean;
}

class View extends React.Component<IViewProps, IViewInvoiceState> {
  public form: Formik;
  constructor(props) {
    super(props);

    this.state = {
      invoiceStatus: "loading",
      taxRate: null,
      subTotalAmount: null,
      totalAmount: null,
      taxesTotal: null,
      isPaid: false
    };

    this.getInvoice = this.getInvoice.bind(this);
    this.handlePaidStatusChange = this.handlePaidStatusChange.bind(this);
  }

  componentDidMount() {
    this.getInvoice();
  }

  getInvoice(): void {
    this.setState({ invoiceStatus: "loading" });

    getInvoiceDetails(this.props.match.params.invoiceId)
      .then(invoiceData => {
        this.setState({
          invoiceStatus: "loaded",
          isPaid: invoiceData.hasBeenPaid,
          ...invoiceData,
          id: this.props.match.params.invoiceId
        });
      })
      .catch(e => {
        this.props.notifySwagger(e, "error");
        history.push("/notFound");
      });
  }

  handlePaidStatusChange(paidStatusDetails: UpdateInvoicePaidStatusDto) {
    updateInvoicePaidStatus(paidStatusDetails)
      .then(() => {
        this.setState({
          isPaid: paidStatusDetails.hasBeenPaid
        });
      })
      .catch(err => {
        console.log(err);
      });
  }

  render() {
    return (
      <MainContainer
        centered
        scale="md"
        renderTitle={
          <Title label="Invoice">
            {this.state.invoiceStatus === "loading" && (
              <React.Fragment>
                <Title.Element>
                  <Skeleton.Line />
                </Title.Element>
                <Title.Element>
                  <Skeleton.Line />
                </Title.Element>
                <Title.Element>
                  <Skeleton.Line />
                </Title.Element>
              </React.Fragment>
            )}
            {this.state.invoiceStatus === "loaded" && (
              <React.Fragment>
                <Title.Element>
                  Buyer: {this.state.owner && this.state.owner.displayValue}
                </Title.Element>
                <Title.Element>
                  Invoice #: {removeHashSign(this.state.number)}
                </Title.Element>
                <Title.Element>
                  Date/Time:{" "}
                  {moment(this.state.creationTime).format("MM/DD/YYYY LT")}
                </Title.Element>
              </React.Fragment>
            )}
          </Title>
        }
      >
        <Position.Content>
          <Formik
            enableReinitialize
            initialValues={this.state}
            onSubmit={() => {}}
            ref={node => (this.form = node)}
            render={props => (
              <Form
                {...props}
                handlePaidStatusChange={this.handlePaidStatusChange}
                isPaid={this.state.isPaid}
              />
            )}
          />
        </Position.Content>
      </MainContainer>
    );
  }
}

interface IConnectedProps {
  locationId: number;
  locationName: string;
  catalogId: number;
}

interface IConnectedActions {
  notify: typeof notify;
  notifySwagger: typeof notifySwagger;
}

export default withMenu(
  connect(
    createStructuredSelector<IGlobalStore, IConnectedProps>({
      locationId: state => state.currentUser.locationInfo.id,
      locationName: state => state.currentUser.locationInfo.name,
      catalogId: state => state.currentUser.catalogId
    }),
    {
      notify,
      notifySwagger
    }
  )(withGranted(View)),
  false
);

import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const MainMenuContainer = props => {
  return (
    <nav className={props.className}>
      {props.children}
    </nav>
  );
};

export default styled(MainMenuContainer)`
  ${({ theme, ...props }) => {

    let {
      global,
      main,
      primary,
      fs_xs,
      fs_sm,
      active,
    } = theme;

    const paddings = !props.compact ?
      '20px 20px 20px 14px' :
      '0';
    const weight = !props.compact ?
      'bold' :
      'normal';

    const fontSize = !props.compact ?
      fs_sm :
      fs_xs;

    const height = !props.compact ?
      '77px' :
      'auto';

    return `
      ${global}
      padding: ${paddings};
      background: ${main};
      height: ${height};

      a{

        ${props.compact ? `
          border-top-style: solid;
          border-bottom-style: solid;
          border-top-width: 2px;
          border-bottom-width: 2px;
          transition: all 0.25s ease-in-out 0s;
          border-color: transparent;
          margin: 0px 10px;

          &.active{
            border-bottom-color: ${theme.default};
          }

          &:hover{
            border-bottom-color: ${theme.default};
          }
          
        ` : ''}

        font-weight: ${weight};
        text-transform: uppercase;
        text-decoration: none;
        display: inline-block;

        font-size: ${fontSize};
        padding: 10px 0;
        margin: 0 10px 1px 10px;

        color: ${primary};

        &:focus{
          outline-color: ${active}
        }

        &:focus-within{
          outline-color: ${active}
        }

        &:hover{
          color: ${theme.default};
        }

        &.active{
          color: ${theme.default};
        }
      }
    `;
  }}
`;

MainMenuContainer.propTypes = {
  className: PropTypes.string,
  children: PropTypes.any,
};

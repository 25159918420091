import React from 'react';
import styled from 'styled-components';

export default styled(props => {
  return (
    <i {...props}>
      <svg viewBox="948 20825 20 16" width="20" height="16">
        <path d=" M 966 20827 Q 966 20827 957 20827 L 955 20825 Q 950 20825 950 20825 C 948.898 20825 948 20825.898 948 20827 C 948 20827 948 20839 948 20839 C 948 20840.102 948.898 20841 950 20841 C 950 20841 966 20841 966 20841 C 967.102 20841 968 20840.102 968 20839 C 968 20839 968 20829 968 20829 C 968 20827.898 967.102 20827 966 20827 Z " />
      </svg>
    </i>
  );
})`
  ${ props => `
    width: 21px;
    height: 21px;
    display: inline-flex;
    justify-content: center;
    align-items: center;

    svg{
      path{
        fill: ${props.theme.primary}; 
      }
    }
  `}
`;

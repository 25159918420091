import * as React from 'react';
import List from './List';
import Edit from './Edit';
import {
  ADMINISTRATION_CLIENTS_DIVISIONS_LOCATIONS,
  ADMINISTRATION_CLIENTS_DIVISIONS_LOCATIONS_CREATE,
  ADMINISTRATION_CLIENTS_DIVISIONS_LOCATIONS_EDIT,
} from '../../../_constants/permissions';
import RoutesMapper from '../../Router/RoutesMapper';
import {IRouteEntry} from '../../../_types/common';

const routes: IRouteEntry[] = [
  {
    absolutePath: '/admin/clients/:clientId/divisions/:divisionId/locations',
    exact: true,
    component: List,
    permission: ADMINISTRATION_CLIENTS_DIVISIONS_LOCATIONS,
  },
  {
    absolutePath: '/admin/clients/:clientId/divisions/:divisionId/locations/new',
    exact: true,
    render: (props, match) => <Edit {...props} match={match} locationId="new" />,
    permission: ADMINISTRATION_CLIENTS_DIVISIONS_LOCATIONS_CREATE,
    keepForbiddenRoute: true,
  },
  {
    absolutePath: '/admin/clients/:clientId/divisions/:divisionId/locations/:id',
    exact: true,
    render: (props, match) => <Edit {...props} match={match} locationId={match.params.id} />,
    permission: ADMINISTRATION_CLIENTS_DIVISIONS_LOCATIONS_EDIT,
  },
];

const Location: React.SFC = (props) => {
  return <RoutesMapper routes={routes} addNotFound {...props} />;
};

export default Location;
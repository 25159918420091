import * as React from "react";

import RoutesMapper from "../Router/RoutesMapper";
import ClientOrdersList from "./List";
import ClientOrdersView from "./View";
import DashboardList from "./Dashboard/List";
import DashboardView from "./Dashboard/View";
import { IRouteEntry } from "../../_types/common";
import { ORDERS_UNCOMPARED } from "../../_constants/permissions";
import withGranted from "../HOC/WithGranted";
import MenuSidebar from "../MenuSidebar/MenuSidebar";
import { MainMenu, HorizontalMenu } from "src/components";
import {
  RouteComponentProps,
  withRouter,
  Redirect,
  NavLink
} from "react-router-dom";

const routes: IRouteEntry[] = [
  {
    path: "/client-orders",
    exact: true,
    permission: ORDERS_UNCOMPARED,
    component: ClientOrdersList
  },
  {
    path: "/client-orders/:orderId",
    exact: true,
    permission: ORDERS_UNCOMPARED,
    component: ClientOrdersView
  },
  {
    path: "/dashboard",
    exact: true,
    permission: ORDERS_UNCOMPARED,
    component: DashboardList
  },
  {
    path: "/dashboard/:orderId",
    exact: true,
    permission: ORDERS_UNCOMPARED,
    component: DashboardView
  }
];

class OrderRoutes extends React.Component<RouteComponentProps<{}>> {
  allowedRoutePath: string;

  constructor(props) {
    super(props);
    const firstAllowedRoute = routes.find(
      route => route.path && props.isGranted(route.permission)
    );
    this.allowedRoutePath = firstAllowedRoute ? firstAllowedRoute.path : "";
  }
  render() {
    const {
      match: { url },
      isGranted
    } = this.props;

    const isNeedToRedirect = url === this.props.location.pathname;
    return (
      <React.Fragment>
        <MenuSidebar>
          <MainMenu>
            <HorizontalMenu>
              {isGranted(ORDERS_UNCOMPARED) && (
                <NavLink to="/clients-orders/client-orders">
                  Client Orders
                </NavLink>
              )}
              {isGranted(ORDERS_UNCOMPARED) && (
                <NavLink to="/clients-orders/dashboard">
                  SCM Order Dashboard
                </NavLink>
              )}
            </HorizontalMenu>
          </MainMenu>
        </MenuSidebar>
        {isNeedToRedirect && (
          <Redirect
            exact
            from={`${url}`}
            to={this.allowedRoutePath ? `${url}${this.allowedRoutePath}` : "/"}
          />
        )}
        <RoutesMapper routes={routes} addNotFound {...this.props} />
      </React.Fragment>
    );
  }
}

export default withGranted(withRouter(OrderRoutes));

import { catalogServiceProxy } from '../servicePool';
import {
  PagedResultDtoOfClientCatalogDto,
} from '../service-proxies';

export function getCatalogsByProductId(
  productId: number, 
  perPage: number, 
  skip: number, 
  filter?: string, 
  sort?: string
): Promise<PagedResultDtoOfClientCatalogDto> {
  return catalogServiceProxy.getCatalogsByProductId(productId, perPage, skip, filter, sort);
}
import React from 'react';
import styled from 'styled-components';

import { UserSupervisorDto } from '../../../../service-proxies';

import Error from '../../../../components/Error';

import {
  Number,
  Select,
  FormGrid as Grid,
  Checkbox,
} from '../../../../components';

const Position = {
  Container: styled.div`
  `,

  Checkbox: styled.div`
    padding-top: 9px;
  `,
};

const AbsoluteError = Error.extend`
  position: absolute;
`;

interface IUserSupervisorWithError extends UserSupervisorDto {
  error: string;
}

interface IOptionsData {
  options: any;
  complete: boolean;
}

interface ISupervisorsItemProps {
  onSupervisorItemChange: (item: any, index: any) => void;
  value: IUserSupervisorWithError;
  values: IUserSupervisorWithError[];
  index: number;
  isLoading: boolean;
  disabled: boolean;
  loadUsers: (text: string, callback: (argument: any, optionsData: IOptionsData) => any) => void;
}

interface ISupervisorsItemState {
  supervisorId: number;
  supervisorName: string;
  approvalLimit: number;
  locationId: number;
  unlimited: boolean;
  error: string;
}

export default styled(class extends React.Component<ISupervisorsItemProps, ISupervisorsItemState> {

  constructor(props) {
    super(props);

    this.state = {
      supervisorId: this.props.value.supervisorId,
      supervisorName: this.props.value.supervisorName,
      approvalLimit: this.props.value.approvalLimit,
      locationId: this.props.value.locationId,
      unlimited: this.props.value.unlimited,
      error: this.props.value.error,
    };

    this.handleUserSelect = this.handleUserSelect.bind(this);
    this.handleApprovalLimitChange = this.handleApprovalLimitChange.bind(this);
    this.handleApprovalLimitBlur = this.handleApprovalLimitBlur.bind(this);
    this.handleUnlimitedSelect = this.handleUnlimitedSelect.bind(this);
    this.handleItemChange = this.handleItemChange.bind(this);
  }

  handleUserSelect(value) {
    const newValue = { ...this.state };

    if (value) {
      newValue.supervisorId = value.id;
      newValue.supervisorName = value.displayValue;
    } else {
      newValue.supervisorId = null;
      newValue.supervisorName = null;
    }

    this.handleItemChange(newValue);
  }

  handleApprovalLimitChange(value, triggeredByButtons) {
    const newValue = { ...this.state };
    newValue.approvalLimit = value;

    if (triggeredByButtons) {
      this.handleItemChange(newValue);
    } else {
      this.setState(newValue);
    }
  }

  handleApprovalLimitBlur() {
    const newValue = { ...this.state };
    this.handleItemChange(newValue);
  }

  handleUnlimitedSelect(value) {
    const newValue = { ...this.state };
    newValue.unlimited = value;

    this.handleItemChange(newValue);
  }

  handleItemChange(newValue) {
    if (this.props.value.supervisorId !== newValue.supervisorId
      || this.props.value.approvalLimit !== newValue.approvalLimit
      || this.props.value.unlimited !== newValue.unlimited
      || this.props.value.locationId !== newValue.locationId
      || this.props.value.error !== newValue.error) {

      this.props.onSupervisorItemChange(newValue, this.props.index);
    }
  }

  render() {

    const {
      isLoading,
      disabled,
    } = this.props;

    const {
      supervisorId,
      supervisorName,
      approvalLimit,
      unlimited,
      error,
    } = this.state;

    return <Position.Container>
      <Grid.Row>
        <Grid.Cell size="55%">
          <Select
            async
            loadOnFocus
            autoload={false}
            clearable={false}
            name="user"
            placeholder="Buying Manager"
            labelKey="displayValue"
            valueKey="id"
            compact
            value={{
              id: supervisorId,
              displayValue: supervisorName,
            }}
            loadOptions={this.props.loadUsers}
            onChange={this.handleUserSelect}
            disabled={isLoading || disabled}
          />
        </Grid.Cell>
        <Grid.Cell size="35%">
          <Number
            positiveOnly
            integer
            name="limit"
            compact
            placeholder="Approval limit"
            value={approvalLimit}
            step={1}
            disabled={isLoading || unlimited || disabled}
            onChange={this.handleApprovalLimitChange}
            onBlur={this.handleApprovalLimitBlur}
          />
        </Grid.Cell>
        <Grid.Cell size="20%">
          <Position.Checkbox>
            <Checkbox
              value={unlimited}
              label="Unlimited"
              disabled={isLoading || disabled}
              onChange={this.handleUnlimitedSelect}
              name="unlimited"
              id="unlimited"
            />
          </Position.Checkbox>
        </Grid.Cell>
      </Grid.Row>
      {
        error && <AbsoluteError>{error}</AbsoluteError>
      }
    </Position.Container>;
  }

})``;

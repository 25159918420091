import * as React from "react";
import styled, { StyledComponentClass } from "styled-components";
import styledTs from "styled-components-ts";
import { IThemeProps } from "../assets/theme";

import * as Sekeleton from "./Skeleton";

type CellAlignment = "left" | "right" | "center";

export interface IHeaderCell {
  isFirst: boolean;
  isStuck: boolean;
  isLast: boolean;
  alignment: CellAlignment;
}

const HeaderCell = styledTs<IHeaderCell>(styled.th)`
  ${({ ...props }) => {
    const leftPadding = props.isFirst && props.isStuck ? "0" : "20px";
    const rightPadding = props.isLast && props.isStuck ? "0" : "20px";
    return `
      padding: 25px ${props.alignment === "left" ? "19px" : "8px"} 25px 8px;
      text-align: ${props.alignment};
      font-size: 11px;
      text-transform: uppercase;
      font-weight: 500;
      position: relative;
      white-space: nowrap;
      &:first-of-type{
        padding-left: ${leftPadding};
      }
      &:last-of-type{
        padding-right: ${rightPadding};
      }
    `;
  }}
`;

export interface IColumn {
  name: string;
  size?: string;
  stuck?: boolean;
  sortable?: boolean;
  label: string | JSX.Element;
  alignment?: CellAlignment;
}

export interface IHeaderProps extends IThemeProps {
  columns?: IColumn[];
  sortBy?: string;
  onSortChanged?: (sortBy: string) => void;
}

const Header = styledTs<IHeaderProps>(
  styled(({ className, columns, sortBy, onSortChanged }) => {
    return (
      <TableContext.Consumer>
        {context => {
          const cols = columns || context.columns || [];
          return (
            <thead className={className}>
              <tr>
                {cols &&
                  !!cols.length &&
                  cols.map((column, index) => {
                    return (
                      <HeaderCell
                        key={column.name}
                        width={getColumnWidth(column.size)}
                        isFirst={index === 0}
                        isLast={index === cols.length}
                        isStuck={column.stuck}
                        alignment={column.alignment ? column.alignment : "left"}
                      >
                        {column.sortable ? (
                          <SortableColumnHeader
                            sortBy={sortBy}
                            onSortChanged={onSortChanged}
                            {...column}
                            alignment={
                              column.alignment ? column.alignment : "left"
                            }
                          />
                        ) : (
                          column.label
                        )}
                      </HeaderCell>
                    );
                  })}
              </tr>
            </thead>
          );
        }}
      </TableContext.Consumer>
    );
  })
)`
  ${({ theme, ...props }) => {
    const { global, lighter, lightest } = theme;

    const stuckIndexes = [];
    if (props.columns && !!props.columns.length) {
      props.columns.forEach((item, index) => {
        if (item.stuck) {
          stuckIndexes.push(index);
        }
      });
    }

    return `
      ${global}

      border-top-width: 1px;
      border-bottom-width: 1px;
      border-left-width: 0px;
      border-right-width: 0px;
      
      background: ${lightest};
      border-color: ${lighter};
      border-style: solid;

      ${stuckIndexes.reduce((a, b) => {
        return `
          + tbody td:nth-of-type(${b + 1}){
            ${b === 0 ? `padding-left: 0;` : `padding-right: 0;`}
          }
        `;
      }, "")}

    `;
  }}
`;

export interface ICellProps extends IThemeProps {
  colSpan: any;
  children: any;
  key: any;
  isFirst?: boolean;
  isStuck?: boolean;
  isLast?: boolean;
  alignment: CellAlignment;
  disabled: boolean;
}

const Cell = styledTs<ICellProps>(styled.td)`
${({ theme, ...props }) => {
  const { dark } = theme;

  const leftPadding = props.isFirst && props.isStuck ? "0" : "20px";
  const rightPadding = props.isLast && props.isStuck ? "0" : "20px";

  const color = props.disabled ? dark : "initial";

  return `
      padding: 25px 8px;
      text-align: ${props.alignment};
      font-size: 11px;
      font-weight: 500;
      color: ${color};
      &:first-of-type{
        padding-left: ${leftPadding};
      }
      &:last-of-type{
        padding-right: ${rightPadding};
      }
    `;
}}
`;

const Body = styled.tbody``;

export interface IRowProps extends IThemeProps {
  className?: string;
  cells?: any[];
  colSpan?: string;
  isLoading?: boolean;
  rows?: number;
  disabled?: boolean;
}

const Row = styled(function({
  className,
  cells,
  colSpan,
  isLoading,
  disabled
}) {
  return (
    <TableContext.Consumer>
      {context => {
        return (
          <tr className={className}>
            {!isLoading
              ? !!cells.length &&
                cells.map((cell, index) => {
                  return (
                    <Cell
                      disabled={disabled}
                      colSpan={colSpan}
                      key={`table-cell-${index}`}
                      alignment={
                        context.columns &&
                        context.columns[index] &&
                        context.columns[index].alignment
                          ? context.columns[index].alignment
                          : "left"
                      }
                    >
                      {cell}
                    </Cell>
                  );
                })
              : generatePreloader(colSpan)}
          </tr>
        );
      }}
    </TableContext.Consumer>
  );
} as React.StatelessComponent<IRowProps>)`
  ${({ theme, ...props }) => {
    const { global, lightest, lighter, dark, fs_xs } = theme;

    const background = props.disabled ? lightest : "transpaernt";

    return `
      ${global}
    
      border-top-width: 0px;
      border-bottom-width: 1px;
      border-left-width: 0px;
      border-right-width: 0px;
      
      border-style: solid;
      border-color: ${lighter};
      height: 1px;
      background: ${background};

      td{
        padding: 17px 8px;
        font-size: 12px;
        vertical-align: top;
        height: inherit;
        &:first-of-type{
          padding-left: 20px;
        }
        &:last-of-type{
          padding-right: 20px;
        }
        >p:not([class]){
          font-size: ${fs_xs};
          font-weight: 300;
          margin: 0;
          line-height: 24px;
          > a{
            font-size: ${fs_xs};
            font-weight: 400;
          }
          span{
            color: ${dark};
            font-weight: 400;
          }
        }

        font-size: ${fs_xs};
        
        > a {
          font-size: ${fs_xs};
        }
        
      }
    `;
  }}
`;

export interface ITableProps extends IThemeProps {
  className?: string;
  fullwidth?: boolean;
  leftFullwidth?: boolean;
  rightFullwidth?: boolean;
  headless?: boolean;
  fullHeight?: boolean;
  columns?: IColumn[];
}

export interface ITable extends StyledComponentClass<ITableProps, any> {
  Header?: StyledComponentClass<IHeaderProps, any, IHeaderProps>;
  HeaderCell?: StyledComponentClass<IHeaderCell, any, IHeaderCell>;
  Cell?: StyledComponentClass<ICellProps, any, ICellProps>;
  Body?: StyledComponentClass<any, any, any>;
  Row?: React.StatelessComponent<IRowProps>;
  headless?: boolean;
}

export interface ITableContext {
  columns: IColumn[] | null;
}

export const TableContext: React.Context<ITableContext> = React.createContext({
  columns: null
});

const Table: ITable = styled(
  class extends React.Component<ITableProps> {
    static Header = Header;
    static HeaderCell = HeaderCell;
    static Cell = Cell;
    static Body = Body;
    static Row = Row;

    constructor(props) {
      super(props);
    }

    render() {
      const { className, children } = this.props;
      return (
        <div className={className}>
          <table>
            <TableContext.Provider
              value={{ columns: this.props.columns || null }}
            >
              {children}
            </TableContext.Provider>
          </table>
        </div>
      );
    }
  }
)`
  ${({ theme, ...props }) => {
    const { lighter } = theme;

    const headerVisibility = props.headless ? "collapse" : "visible";

    const headerDisplay = props.headless
      ? "table-footer-group"
      : "table-header-group";

    let orientation = props.fullwidth ? "0 -20px" : "";

    const height = props.fullHeight ? "100%" : "auto";

    if (props.leftFullwidth) {
      orientation = "0 -20px 0 0";
    }

    if (props.rightFullwidth) {
      orientation = "0 0 0 -20px";
    }

    return `
    margin: ${orientation};
    width: auto;
    overflow-x: auto;
    height: ${height};
    table{
      min-width: 650px;
      width: 100%;
      border-top-width: 1px;
      border-bottom-width: 0px;
      border-left-width: 0px;
      border-right-width: 0px;
      
      border-color: ${lighter};
      border-style: solid;
      border-collapse: collapse;

      thead{
        visibility: ${headerVisibility};
        display: ${headerDisplay};
      }
    }
  `;
  }}
` as StyledComponentClass<ITableProps, any>;

export interface ISortArrowIconProps extends IThemeProps {
  className?: string;
  sortDirection: string;
  alignment: CellAlignment;
}

const SortArrowIcon = styledTs<ISortArrowIconProps>(
  styled(({ className }) => (
    <span className={className}>
      <svg viewBox="859 20829 10 5" width="10" height="5">
        <path d=" M 859 20829 L 864 20834 L 869 20829 L 859 20829 Z " />
      </svg>
    </span>
  ))
)`
  ${({ theme, sortDirection, alignment }) => `
    width: 21px;
    height: 21px;
    display: inline-block;
    margin-left: ${alignment === "left" ? "8px" : "-4px"};
    position: absolute;

    svg {
      transform: rotate(${
        sortDirection === "DESC" ? "0" : "180"
      }deg) translate(0px, ${sortDirection === "DESC" ? "-1" : "2"}px);
      path {
        fill: ${theme.default}; 
      }
    }
  `}
`;

const SortableSpan = styled.span`
  cursor: pointer;
  height: 21px;
  line-height: 21px;
  user-select: none;
`;

export interface ISortableColumnHeaderProps {
  name: string;
  sortBy: string;
  onSortChanged: (sortBy: string) => void;
  label: string | JSX.Element;
  alignment: CellAlignment;
}

class SortableColumnHeader extends React.PureComponent<
  ISortableColumnHeaderProps
> {
  constructor(props) {
    super(props);
    this.handleClick = this.handleClick.bind(this);
  }

  handleClick() {
    const { name, sortBy } = this.props;
    const [oldName, sortDirection] = sortBy.split(" ");
    this.props.onSortChanged(
      `${name} ${
        oldName !== name ? "ASC" : sortDirection === "ASC" ? "DESC" : "ASC"
      }`
    );
  }

  render() {
    const { name, label, sortBy } = this.props;
    const [sortField, sortDirection] = sortBy.split(" ");
    return (
      <SortableSpan onClick={this.handleClick}>
        {label}
        {sortField === name && (
          <SortArrowIcon
            sortDirection={sortDirection}
            alignment={this.props.alignment}
          />
        )}
      </SortableSpan>
    );
  }
}

function getColumnWidth(size) {
  let width;

  switch (size) {
    case "xs":
      width = "2px";
      break;
    case "sm":
      width = "50px";
      break;
    case "md":
      width = "100px";
      break;
    case "lg":
      width = "200px";
      break;
    case "xl":
      width = "400px";
      break;
    default:
      width = "";
  }

  return width;
}

function generatePreloader(count) {
  const preloader = [];
  for (let i = 0; i < count; i++) {
    preloader.push(
      <td key={`preloader-${i}`}>
        <Sekeleton.Line />
      </td>
    );
  }
  return preloader;
}

export default Table;

import * as React from 'react';
import {
  RouteComponentProps,
  withRouter,
  Redirect,
  NavLink,
} from 'react-router-dom';

import RoutesMapper from '../Router/RoutesMapper';
import PackingSlipsReconciliationList from './List';
import PackingSlipsReconciliationView from './View';
import OtherPackingSlipsReconciliationView from './OtherPachingSlips/View';
import OtherPackingSlipsReconciliation from './OtherPachingSlips/Routes';

import { IRouteEntry } from '../../_types/common';
import {
  PACKINGSLIPS,
  COMPANYPACKINGSLIPS,
} from '../../_constants/permissions';
import {
  MainMenu,
  HorizontalMenu,
} from '../../components';
import MenuSidebar from '../MenuSidebar/MenuSidebar';
import withGranted, { IWithGrantedProps } from '../HOC/WithGranted';

const routes: IRouteEntry[] = [
  {
    path: '/other-packing-slips',
    exact: true,
    permission: PACKINGSLIPS,
    component: OtherPackingSlipsReconciliation,
  },
  {
    path: '/company-packing-slips',
    exact: true,
    permission: COMPANYPACKINGSLIPS,
    component: PackingSlipsReconciliationList,
  },
  {
    path: '/company-packing-slips/:orderId',
    exact: true,
    permission: COMPANYPACKINGSLIPS,
    component: PackingSlipsReconciliationView,
  },
  {
    path: '/other-packing-slips/:orderId',
    exact: true,
    permission: PACKINGSLIPS,
    component: OtherPackingSlipsReconciliationView,
  },
];

class PackingSlipReconciliationRoutes extends React.Component<IWithGrantedProps & RouteComponentProps<{}>> {

  allowedRoutePath: string;

  constructor(props) {
    super(props);
    const firstAllowedRoute = routes.find(route => (route.path && props.isGranted(route.permission)));
    this.allowedRoutePath = firstAllowedRoute ? firstAllowedRoute.path : '';
  }

  render() {
    const {
      match: { url },
      isGranted
    } = this.props;

    const isNeedToRedirect = url === this.props.location.pathname;

    return (
      <React.Fragment>
        <MenuSidebar>
          <MainMenu>
            <HorizontalMenu>
              {
                isGranted(COMPANYPACKINGSLIPS) &&
                <NavLink to="/packing-slip-reconciliation/company-packing-slips">
                  Company Packing Slips
                </NavLink>
              }
              {
                isGranted(PACKINGSLIPS) &&
                <NavLink to="/packing-slip-reconciliation/other-packing-slips">
                  See all packing slips
                </NavLink>
              }
            </HorizontalMenu>
          </MainMenu>
        </MenuSidebar>
        {
          isNeedToRedirect &&
          <Redirect exact from={`${url}`} to={this.allowedRoutePath ? `${url}${this.allowedRoutePath}` : '/'} />
        }
        <RoutesMapper routes={routes} addNotFound {...this.props} />
      </React.Fragment>
    );
  }
}

export default withGranted(withRouter(PackingSlipReconciliationRoutes));
import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

export const PreloaderSvg = (props) => (
  <svg width={props.size} height={props.size} viewBox="0 0 30 30">
    <circle transform="translate(8 0)" cx="0" cy="16" r="0">
      <animate attributeName="r" values="0; 4; 0; 0" dur="1.2s" repeatCount="indefinite" begin="0"
               keyTimes="0;0.2;0.7;1" keySplines="0.2 0.2 0.4 0.8;0.2 0.6 0.4 0.8;0.2 0.6 0.4 0.8" calcMode="spline" />
    </circle>
    <circle transform="translate(16 0)" cx="0" cy="16" r="0">
      <animate attributeName="r" values="0; 4; 0; 0" dur="1.2s" repeatCount="indefinite" begin="0.3"
               keyTimes="0;0.2;0.7;1" keySplines="0.2 0.2 0.4 0.8;0.2 0.6 0.4 0.8;0.2 0.6 0.4 0.8" calcMode="spline" />
    </circle>
    <circle transform="translate(24 0)" cx="0" cy="16" r="0">
      <animate attributeName="r" values="0; 4; 0; 0" dur="1.2s" repeatCount="indefinite" begin="0.6"
               keyTimes="0;0.2;0.7;1" keySplines="0.2 0.2 0.4 0.8;0.2 0.6 0.4 0.8;0.2 0.6 0.4 0.8" calcMode="spline" />
    </circle>
  </svg>
);

PreloaderSvg.propTypes = {
  size: PropTypes.number,
};

PreloaderSvg.defaultProps = {
  size: 20,
};

const PreloaderIcon = props => {
  return(
    <i {...props}>
      <PreloaderSvg />
    </i>
  );
};

export default styled(PreloaderIcon)`
  ${ props => `
    width: 21px;
    height: 21px;
    display: inline-flex;
    justify-content: center;
    align-items: center;

    svg{
      circle{
        fill: ${props.theme.primary}; 
      }
    }
  `}
`;
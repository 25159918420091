import * as React from 'react';
import styled, { StyledComponentClass } from 'styled-components';
import { connect } from 'react-redux';
import { LocationSelectorComponent, Tooltip } from '../../components';
import { setCurrentUserLocation } from '../../_actions/current-user';
import { drawerToggle } from '../../_actions/layout';
import { createStructuredSelector } from 'reselect';
import { IShortLocationInfo } from '../../_types/common';
import { IGlobalStore } from '../../_reducers/reducers';
import { IFlatLocationOption } from '../../_reducers/current-user';
import { bindActionCreators } from 'redux';
import { MenuItemWave } from '../../components/Drawer';

const CollapsedLocation = styled.div`
  ${({theme}) => theme.global}
  font-size: 20px;
  line-height: 200%;
  color: ${({ theme }) => theme.light};
  text-transform: uppercase;
  text-align: center;
  height: 40px;
  padding-top: 2px; 
  cursor: pointer;
  box-sizing: border-box;
  position: relative;
  .${(MenuItemWave as any).styledComponentId}{
    left: 10px;
    top: -2px;
  }
  &:hover{
    .${(MenuItemWave as any).styledComponentId}{
      transform: scale(1.2);
    }
  }
`;

const TooltipContainer = styled.div`
  padding: 0 10px 10px 10px;
`;

const SelectorWrapper = styled.div`
  height: 40px;
  padding: 0 18px 10px 20px;
`;


interface IEmptyLocationProps {
  className?: string;
  onClick: () => void;
}

const EmptyLocation: StyledComponentClass<IEmptyLocationProps, React.StatelessComponent<IEmptyLocationProps>> = styled(({ className, onClick }: IEmptyLocationProps) => (
  <span className={className} onClick={onClick}>
    <svg viewBox="0 0 32 32" width="32" height="32">
      <line x1="5" x2="27" y1="8" y2="8" />
      <line x1="5" x2="27" y1="17" y2="17" />
      <line x1="5" x2="27" y1="26" y2="26" />
    </svg>
  </span>
))`
  svg {
    line {
        stroke: ${({ theme }) => theme.light};
        stroke-width: 3;
     }
  }
`;

function makeShortLocationName(name: string): string {
  if (!name) { return ''; }
  return name.split(/\s+/).map(s => s.slice(0, 1)).join('').slice(0, 4);
}

interface IProps extends IConnectedStoreProps, IConnectedActions {
  className: string;
  isDrawerOpen: boolean;
}

interface IState {
  selectedLocationId: number | null;
  selectedLocationName: string | null;
  collapsedLocationName: string | null;
}

class DrawerLocationSelector extends React.PureComponent<IProps, IState> {

  constructor(props) {
    super(props);

    this.state = {
      selectedLocationId: props.location.id,
      selectedLocationName: props.location.name,
      collapsedLocationName: makeShortLocationName(props.location.name),
    };

    this.handleLocationChange = this.handleLocationChange.bind(this);
    this.handleOpenDrawer = this.handleOpenDrawer.bind(this);
  }

  handleLocationChange(value) {
    if (!value) { return; }
    this.setState({
      selectedLocationId: value.id,
      selectedLocationName: value.name,
      collapsedLocationName: makeShortLocationName(value.name),
    });

    this.props.setCurrentUserLocation(value.id, value.name, value.catalogId);
  }

  handleOpenDrawer() {
    this.props.drawerToggle(true);
  }

  render() {
    return (
      <React.Fragment>
        {this.props.isDrawerOpen ?
          <SelectorWrapper>
            <LocationSelectorComponent
              withoutBorder
              locationOptions={this.props.locationOptions}
              onLocationChange={this.handleLocationChange}
              selectedLocation={this.state.selectedLocationId}
            />
          </SelectorWrapper>
          :
          <TooltipContainer>
            <Tooltip text={this.props.location.name.toUpperCase()}>
              <CollapsedLocation onClick={this.handleOpenDrawer}>
                <MenuItemWave/>
                {this.state.collapsedLocationName ?
                  this.state.collapsedLocationName
                  :
                  <EmptyLocation onClick={this.handleOpenDrawer} />
                }
              </CollapsedLocation>
            </Tooltip>
          </TooltipContainer>
        }
      </React.Fragment>
    );
  }
}

interface IConnectedStoreProps {
  location: IShortLocationInfo;
  locationOptions: IFlatLocationOption[];
}

interface IConnectedActions {
  setCurrentUserLocation: typeof setCurrentUserLocation;
  drawerToggle: typeof drawerToggle;
}

export default connect(
  createStructuredSelector<IGlobalStore, IConnectedStoreProps>({
    location: state => state.currentUser.locationInfo,
    locationOptions: state => state.currentUser.locationOptions,
  }),
  dispatch => bindActionCreators({
    setCurrentUserLocation,
    drawerToggle
  }, dispatch)
)(DrawerLocationSelector);

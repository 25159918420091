import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

// @TODO - need to remove from project, isGranted - better to use 

const WithPermission = ({permissionsMap, permission, children, fallback}) => {
  return (permissionsMap && permissionsMap[permission]) ? children : fallback;
};

WithPermission.propTypes = {
  permission: PropTypes.string.isRequired,
  children: PropTypes.any,
  fallback: PropTypes.any,
};

WithPermission.defaultProps = {
  fallback: null,
};

export default connect(
  createStructuredSelector({
    permissionsMap: state => (state.currentUser && state.currentUser.permissions),
  }),
  null,
)(WithPermission);

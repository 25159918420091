import * as React from "react";
import styled from "styled-components";
import { Table } from "../components";
import { formatPrice, roundAndFormatPrice } from "../_utils/prices";
import { ManualEntryDto, InvoiceManualEntryDto } from "src/service-proxies";

const DescriptionWrapper = styled.div``;

const PositionSKU = styled<{}, "div">("div")`
  flex: 1.2;
  padding: 4px 14px 0 0;
  line-height: 18px;

  span {
    margin-left: 4px;
  }
`;

const PositionDescription = styled<{}, "div">("div")`
  flex: 6;
  flex-basis: 100%;
  padding: 4px 14px 0 0;
  min-height: 26px;
  line-height: 18px;
`;

const PositionPrice = styled.div`
  flex: 1;
  padding: 0 14px 0 0;
  height: 26px;
  line-height: 26px;
`;

const PositionQuantity = styled.div`
  flex: 1;
  height: 26px;
  line-height: 26px;
  padding: 0 9px 0 0;
`;

const PositionSubtotal = styled.div`
  flex: 1;
  padding: 0 14px 0 10px;
  height: 26px;
  text-align: left;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const PositionTaxable = styled.div`
  flex: 1;
  height: 26px;
  line-height: 26px;
  padding: 0 9px 0 0;
`;

// const PositionToolbar = styled.div``;

const EntryText = styled<{ isEmpty: boolean }, "span">("span")`
  color: ${({ isEmpty, theme }) => (isEmpty ? theme.light : theme.primary)};
`;

interface IManualEntryProps {
  entry: InvoiceManualEntryDto | ManualEntryDto;
  description: string;
  colSpan?: number;
  id?: number;
  onToggleTaxes?: (id: number) => void;
}

class ManualEntryViewForOrder extends React.Component<IManualEntryProps> {
  private isTaxesAddable: boolean = false;

  static defaultProps = {
    colSpan: 6
  };

  constructor(props) {
    super(props);
    this.isTaxesAddable = typeof props.onToggleTaxes === "function";
    this.handleToggleTaxes = this.handleToggleTaxes.bind(this);
  }

  handleToggleTaxes() {
    this.props.onToggleTaxes(this.props.id);
  }

  getManualCells() {
    let manualEntry;

    if (this.isTaxesAddable) {
      manualEntry = this.props.entry as InvoiceManualEntryDto;
    }
    manualEntry = this.props.entry as ManualEntryDto;

    const cells: Array<JSX.Element | string> = [
      <PositionSKU>
        {
          <EntryText isEmpty={!manualEntry.sku}>
            {manualEntry.sku || ""}
          </EntryText>
        }
      </PositionSKU>,
      <PositionDescription>
        {
          <EntryText isEmpty={!manualEntry.description}>
            {manualEntry.description || ""}
          </EntryText>
        }
      </PositionDescription>,
      <PositionPrice>
        {
          <EntryText isEmpty={!manualEntry.price}>
            {formatPrice(manualEntry.price || 0.0)}
          </EntryText>
        }
      </PositionPrice>,
      <PositionQuantity>
        {
          <EntryText isEmpty={!manualEntry.quantity}>
            {`Qty: ${manualEntry.quantity}`}
          </EntryText>
        }
      </PositionQuantity>,
      <PositionSubtotal>
        {roundAndFormatPrice(manualEntry.price * manualEntry.quantity)}
      </PositionSubtotal>,
      <PositionTaxable>
        {manualEntry.isTaxable ? "taxable" : "non taxable"}
      </PositionTaxable>
      // <>
      //   {this.isTaxesAddable && (
      //     <PositionToolbar>
      //       {
      //         <Button
      //           primary={manualEntry.isAmountIncludedToInvoice}
      //           scale="sm"
      //           onClick={this.handleToggleTaxes}
      //         >
      //           {!manualEntry.isAmountIncludedToInvoice
      //             ? "Add Amount to Invoice"
      //             : "Remove Amount from Invoice"}
      //         </Button>
      //       }
      //     </PositionToolbar>
      //   )}
      // </>
    ];

    if (cells.length < this.props.colSpan) {
      const cellsToAdd = this.props.colSpan - cells.length;
      for (let i = 0; i < cellsToAdd; i++) {
        cells.push("");
      }
    }

    return cells;
  }

  render() {
    return (
      <React.Fragment>
        <Table.Row
          key={`order-note-description`}
          colSpan={this.props.colSpan.toString()}
          cells={[
            <DescriptionWrapper>
              {this.props.description || ""}
            </DescriptionWrapper>
          ]}
        />
        {this.props.entry && (
          <Table.Row key={`order-manual-entry`} cells={this.getManualCells()} />
        )}
      </React.Fragment>
    );
  }
}

export default ManualEntryViewForOrder;

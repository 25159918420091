import * as React from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import { Switch } from 'react-router-dom';
import withGranted, { IWithGrantedProps } from '../../HOC/WithGranted';
import { IRouteEntry } from '../../../_types/common';
import RoutesMapper from '../../Router/RoutesMapper';
import BuyerList from './List'; 
import BuyerView from './View';

import {
  ORDERS_BUYERORDERS,
} from '../../../_constants/permissions';

const routes: IRouteEntry[] = [
  {
    path: '/',
    component: BuyerList,
    permission: ORDERS_BUYERORDERS,
  },
  {
    path: '/:orderId',
    component: BuyerView,
    permission: ORDERS_BUYERORDERS,
  },
];

interface IOrdersProps extends IWithGrantedProps, RouteComponentProps<{}> {
}

class Orders extends React.Component<IOrdersProps, any> {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <Switch>
        <RoutesMapper routes={routes} addNotFound {...this.props} />
      </Switch>
    );
  }
}

export default withRouter(withGranted(Orders));

import * as React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';
import { bindActionCreators } from 'redux';
import styled from 'styled-components';
import { createStructuredSelector } from 'reselect';

import {
  GetСategoriesDto,
} from '../../service-proxies';

import {
  SubMenuPosition,
  Select,
  Link,
  Skeleton,
  MainMenu,
  HorizontalMenu,
} from '../../components';
import * as actions from '../../_actions/category';
import {
  MASTERCATALOGUE,
} from '../../_constants/permissions';
import WithGranted, { IWithGrantedProps } from '../HOC/WithGranted';
import { IGlobalStore } from '../../_reducers/reducers';
import { CategoryContextConsumer } from './Contexts/CategoryContext';

const Preloader = () => (<span>
  <Skeleton.Line size="sm" /><Skeleton.Line size="sm" />
</span>);

interface IRouteParams {
  subCategoryId?: string;
  categoryId?: string;
}

interface ICategoriesProps extends IConnectedProps, IConnectedActions, IWithGrantedProps, RouteComponentProps<IRouteParams> {
  className?: string;
  master: boolean;
}

const Categories = styled(class CategoriesMaster extends React.Component<ICategoriesProps> {

  constructor(props) {
    super(props);
  }

  componentDidMount() {
    this.props.getCategories(this.props.isInactive, '', this.props.match.params.subCategoryId);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.isInactive !== this.props.isInactive) {
      this.props.getCategories(this.props.isInactive, '', this.props.match.params.subCategoryId);
    }
  }

  render() {
    const url = '/master-products';
    const delimiter = '/';
    return (
      <CategoryContextConsumer>
        {
          (context) => {
            return <SubMenuPosition className={this.props.className}>
              {
                this.props.isGranted(MASTERCATALOGUE) &&
                this.props.master &&
                <Select
                  inputProps={{ readOnly: true }}
                  value="all_products"
                  clearable={false}
                  compact
                  options={[
                    { label: 'All Products', value: 'all_products' },
                    { label: 'By Manufacturer', value: 'by_manufacturer' },
                    { label: 'By Vendor', value: 'by_vendor' },
                    { label: 'Top Sellers', value: 'top_sellers' },
                    { label: 'On Backorder', value: 'on_backorder' },
                    { label: 'Pass-Thoughts', value: 'pass_thoughts' },
                  ]}
                />
              }
              {
                (context.status === 'done') &&
                (
                  <MainMenu compact>
                    <HorizontalMenu>
                      {
                        context.status === 'done' &&
                        context.categories.map((item, index) => {
                          return <Link.NavLink key={index} to={`${url}${delimiter}${item.id}`}>
                            {item.name}
                          </Link.NavLink>;
                        })
                      }
                    </HorizontalMenu>
                  </MainMenu>
                )
              }
              {
                context.status === 'loading' &&
                <Preloader />
              }
            </SubMenuPosition>;
          }
        }
      </CategoryContextConsumer>
    );
  }
})`
  ${({ ...props }) => {
    const position = !props.isNotPosition ?
      `
      align-items: center;    
      width: 100%;  
    
      margin: 2px 0 0 0;
      padding: 10px 20px;`:
      '';
    return `
      ${position}
    `;
  }}

`;

interface IConnectedProps {
  categories: GetСategoriesDto[];
  listStatus: string;
  isInactive: boolean;
  catalogId: number;
}

interface IConnectedActions {
  getCategories: typeof actions.getCountedMasterCategories;
}

export default withRouter(connect(
  createStructuredSelector<IGlobalStore, IConnectedProps>({
    categories: state => state.category.categories as GetСategoriesDto[],
    listStatus: state => state.category.listStatus,
    isInactive: state => state.products.isInactive,
    catalogId: state => state.currentUser.catalogId,
  }),
  dispatch => bindActionCreators({
    getCategories: actions.getCountedMasterCategories,
  }, dispatch)
)(WithGranted(Categories)));

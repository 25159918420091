import { types } from '../_actionTypes/notification';

export interface INotificationStore {
  message: string;
  messageType: string;
}

const initialState: INotificationStore = {
  message: '',
  messageType: 'default',
};

interface INotificationAction {
  type: types.NOTIFICATION_NOTIFY;
  message: string;
  messageType: string;
}

export default (state: INotificationStore = initialState, action: INotificationAction): INotificationStore => {
  const {
    message,
    messageType,
  } = action;

  switch (action.type) {
    case types.NOTIFICATION_NOTIFY:
      return ({
        ...state,
        message,
        messageType,
      });
    default:
      return state;
  }
};

export const USER_GET_ALL = 'user/getAll';
export const USER_GET_BY_ID = 'user/getById';
export const USER_CREATE = 'user/create';
export const USER_DELETE = 'user/delete';
export const USER_REACTIVATE = 'user/reactivate';
export const USER_UPDATE = 'user/update';
export const USER_UPDATE_PERMISSIONS = 'user/updatePermissions';
export const USER_GET_PERMISSIONS = 'user/getPermissions';
export const USER_GET_OVERRIDE_LOCATIONS = 'user/getOverrideLocations';
export const USER_GET_SUPERVISORS = 'user/getUserSupervisors';

import * as React from 'react';
import { bindActionCreators } from "redux";
import styled from 'styled-components';
import { connect } from 'react-redux';
import moment, { Moment } from "moment";
import { withRouter } from 'react-router';
import { createStructuredSelector } from 'reselect';

import { BuyerOrderListDto } from "../../../service-proxies";

import { ordersServiceProxy } from '../../../servicePool';
import withGranted, { IWithGrantedProps } from '../../HOC/WithGranted';
import { IGlobalStore } from '../../../_reducers/reducers';
import { IShortLocationInfo } from "../../../_types/common";
import * as ordersApi from '../../../_api/orders';
import * as actionsSearch from '../../../_actions/search';
import * as actionsModal from '../../../_actions/modal';
import * as notifyActions from '../../../_actions/notification';
import { saveFilterSettings, loadFilterSettings } from '../../../_utils/userSession';

import {
  Table,
  Link,
  Pagination,
  MainContainer,
  DatePicker,
  RunningLineLoader,
  Article,
} from '../../../components';

import {
  ORDERS_BUYERORDERS_CANCEL,
  ORDERS_BUYERORDERS_SUBMITORAPPROVE,
} from '../../../_constants/permissions';
import { formatPrice } from '../../../_utils/prices';
import { setPerPage } from 'src/_actions/layout';

const ToolbarLink = styled(Link)`
  margin: 0 0 0 10px;
`;

const ToolbarButton = styled(Link.Button)`
  margin: 0 0 0 10px;
`;

const Position = {
  Container: styled.div`
    width: 100%;
    display: flex;
    flex-direction: column; 
  `,

  Body: styled.div`
    width: 100%; 
    height: 100%; 
  `,

  Head: styled<{ className?: string }>(({ className, children }) => <header className={className}>
    <div>
      {children}
    </div>
  </header>)`
    padding: 20px;
    >div{
      margin: -7.5px;
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
    }
  `,

  HeadElement: styled.div`
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    & > * {
      margin: 7.5px;
    }
  `,

  Toolbar: styled.header`
    text-align: right;
    height: 15px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  `,

};

interface IUnapprovedOrdersListProps extends IConnectedProps, IConnectedActions, IWithGrantedProps {

}

interface IUnapprovedOrdersListState {
  sortBy: string;
  focusedInput: string;
  startDate: Moment;
  dueDate: Moment;
  filter?: string;
  skip: number;
  status: string | 'loading' | 'sorting' | 'done';
  orders: BuyerOrderListDto[];
  totalCount: number;
}

interface IGetOrdersParams {
  startDate?: moment.Moment;
  dueDate?: moment.Moment;
  locationId?: number;
  filter?: string;
  sortBy?: string;
  skip?: number;
  perPage?: number;
}

class UnapprovedOrdersList extends React.Component<IUnapprovedOrdersListProps, IUnapprovedOrdersListState> {
  static renderSkeletonRows(perPage, status) {
    const rows = [];
    const elementsCount = (status === 'loading') ? perPage - 1 : 0;

    for (let i = 0; i < elementsCount; i++) {
      rows.push(<Table.Row
        key={i}
        colSpan="6"
        rows={perPage}
        isLoading={status === 'loading'}
        cells={[<span key="0">NO CLIENTS FOUND</span>]}
      />);
    }
    return rows;
  }

  constructor(props) {
    super(props);

    const filterSettings = loadFilterSettings('buyerorders');

    this.state = {
      sortBy: 'submittingTime DESC',
      focusedInput: null,
      startDate: filterSettings.startDate != null ? moment(filterSettings.startDate) : null,
      dueDate: filterSettings.dueDate != null ? moment(filterSettings.dueDate) : null,
      status: 'loading',
      skip: 0,
      orders: [],
      totalCount: 0,
    };

    this.handleApprove = this.handleApprove.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
    this.handleChangePage = this.handleChangePage.bind(this);
    this.handleSortChanged = this.handleSortChanged.bind(this);
    this.getUnapprovedOrders = this.getUnapprovedOrders.bind(this);
    this.handleDateChanged = this.handleDateChanged.bind(this);

    this.props.setSearch('/orders/buyer', (filter) => {
      this.setState({ skip: 0, filter }, () => {
        this.getUnapprovedOrders({ filter, sortBy: this.state.sortBy });
      });
    });
  }

  componentDidMount() {
    this.getUnapprovedOrders();
  }

  componentDidUpdate(prevProps) {
    const prevLocationId = prevProps.locationInfo ? prevProps.locationInfo.id : 0;
    const currLocationId = this.props.locationInfo ? this.props.locationInfo.id : 0;

    if (currLocationId !== prevLocationId) {
      this.getUnapprovedOrders();
    }
  }

  getUnapprovedOrders(params: IGetOrdersParams = {}) {
    const startDate = params.startDate || this.state.startDate;
    const dueDate = params.dueDate || this.state.dueDate;
    const locationId = params.locationId || this.props.locationInfo && this.props.locationInfo.id ? this.props.locationInfo.id : 0;
    const filter = params.filter || this.state.filter;
    const sortBy = params.sortBy || this.state.sortBy;
    const skip = (Number.isInteger(params.skip) && params.skip >= 0) ? params.skip : this.state.skip;
    const perPage = params.perPage || this.props.perPage;

    let statusStartKey = 'loading';
    const statusFinnishKey = 'done';
    if (params.sortBy) {
      statusStartKey = 'sorting';
    }

    this.setState({ status: statusStartKey });

    ordersApi.getBuyerOrders(startDate, dueDate, locationId, filter, sortBy, skip, perPage)
      .then(ordersData => {
        this.setState({ status: statusFinnishKey, orders: ordersData.items, totalCount: ordersData.totalCount });
      })
      .catch(e => {
        this.props.notifySwagger(e, 'error');
      });
  }

  handleApprove(orderId: number) {
    this.props.prompt({
      title: 'Approve order',
      question: 'Do you really want to approve order #' + orderId + '?'
    })
      .then(() => {
        ordersServiceProxy.approveBuyerOrder({ id: orderId })
          .then(() => {
            this.getUnapprovedOrders();
          });
      })
      .catch((e) => {
      });
  }

  handleSubmit(orderId: number) {
    this.props.prompt({
      title: 'Submit order',
      question: 'Do you really want to submit order #' + orderId + '?'
    })
      .then(() => {
        ordersServiceProxy.submitBuyerOrder({ id: orderId })
          .then(() => {
            this.getUnapprovedOrders();
          });
      })
      .catch(() => {
      });
  }

  handleCancel(orderId: number) {
    this.props.prompt({
      title: 'Cancel order',
      question: 'Do you really want to cancel order #' + orderId + '?'
    })
      .then(() => {
        ordersServiceProxy.cancelBuyerOrder({ id: orderId }).then(() => {
          this.getUnapprovedOrders();
        });
      })
      .catch(() => {
      });
  }

  handleChangePage(pageInfo) {
    const {
      perPage,
      skip,
    } = pageInfo;

    this.props.setPerPage(perPage);
    this.setState({ skip }, () => {
      this.getUnapprovedOrders({ filter: this.state.filter, sortBy: this.state.sortBy, perPage, skip });
    });

  }

  handleSortChanged(sortBy) {
    this.setState({ sortBy }, () => {
      this.getUnapprovedOrders({ filter: this.state.filter, sortBy });
    });
  }

  handleDateChanged(range: { startDate: Moment, endDate: Moment }) {
    const startDate = range.startDate ? range.startDate : undefined;
    const dueDate = range.endDate ? range.endDate : undefined;

    this.setState({ startDate, dueDate }, () => {
      this.setFilterSettings();
    });


    this.getUnapprovedOrders({
      startDate,
      dueDate,
    });
  }

  setFilterSettings() {
    const filter = {
      location: null,
      startDate: this.state.startDate,
      dueDate: this.state.dueDate
    };

    saveFilterSettings('buyerorders', filter);
  }

  isCanBeManipulated(statusId: number): boolean {
    return statusId === 1;
  }

  render() {
    return (
      <MainContainer>
        <Position.Container>
          <Position.Head>
            <Position.HeadElement>
              <Article.Text scale="lg"><strong>BUYER ORDERS</strong></Article.Text>
            </Position.HeadElement>
            <Position.HeadElement>
              <DatePicker
                type="range"
                startDateId="startDate"
                endDateId="endDate"
                isOutsideRange={() => false}
                startDate={this.state.startDate}
                endDate={this.state.dueDate}
                onDatesChange={this.handleDateChanged}
                focusedInput={this.state.focusedInput}
                onFocusChange={(focusedInput) => {
                  this.setState({ focusedInput });
                }}
                compact
              />
            </Position.HeadElement>
          </Position.Head>
          <Position.Body>
            <RunningLineLoader
              isActive={this.state.status === 'sorting' || this.state.status === 'loading'}
            />
            <Table fullHeight
              columns={[
                { name: 'id', label: 'order number', sortable: true },
                { name: 'submittingTime', label: 'date', sortable: true },
                { name: 'ownerName', label: 'buyer', sortable: true },
                { name: 'subTotalAmount', label: 'amount', sortable: true },
                { name: 'status', label: 'status' },
                { name: '', label: '' },
              ]}>
              <Table.Header
                sortBy={this.state.sortBy}
                onSortChanged={this.handleSortChanged}
              />
              <Table.Body>
                {
                  (this.state.orders && !!this.state.orders.length) ?
                    this.state.orders.map((item, index) =>
                      <Table.Row
                        key={`${index}-${item.id}`}
                        disabled={!this.isCanBeManipulated(item.statusId)}
                        cells={[
                          item.number,
                          moment(item.submittingTime).format('MM/DD/YYYY'),
                          item.ownerName,
                          formatPrice(item.subTotalAmount),
                          item.status,
                          <Position.Toolbar key={`cell-toolbar-${index}-${item.id}`}>
                            <ToolbarLink to={`/orders/buyer/${item.id}`}>View Order</ToolbarLink>
                            {
                              (
                                this.props.isGranted(ORDERS_BUYERORDERS_SUBMITORAPPROVE) &&
                                this.isCanBeManipulated(item.statusId) &&
                                item.shouldBeSubmitted
                              ) &&
                              <ToolbarButton
                                onClick={() => {
                                  this.handleSubmit(item.id);
                                }}
                              >
                                Submit
                              </ToolbarButton>
                            }
                            {
                              (
                                this.props.isGranted(ORDERS_BUYERORDERS_SUBMITORAPPROVE) &&
                                this.isCanBeManipulated(item.statusId) &&
                                item.shouldBeApproved
                              ) &&
                              <ToolbarButton
                                onClick={() => {
                                  this.handleApprove(item.id);
                                }}
                              >
                                Approve
                              </ToolbarButton>
                            }
                            {
                              (
                                this.props.isGranted(ORDERS_BUYERORDERS_CANCEL) &&
                                this.isCanBeManipulated(item.statusId)
                              ) &&
                              <ToolbarButton
                                onClick={() => {
                                  this.handleCancel(item.id);
                                }}
                              >
                                Cancel
                              </ToolbarButton>

                            }
                          </Position.Toolbar>
                        ]}
                      />) :
                    <Table.Row
                      key={0}
                      colSpan="6"
                      rows={this.props.perPage}
                      isLoading={this.state.status === 'loading'}
                      cells={[<span key="0">NO ORDERS FOUND</span>]}
                    />
                }
                {
                  (this.state.orders && !!this.state.orders.length) ||
                  UnapprovedOrdersList.renderSkeletonRows(this.props.perPage, this.state.status)
                }
              </Table.Body>
            </Table>
          </Position.Body>
          <Pagination
            skip={this.state.skip}
            perPage={this.props.perPage}
            count={this.state.totalCount}
            onChange={this.handleChangePage}
          />
        </Position.Container>
      </MainContainer>
    );
  }
}


interface IConnectedProps {
  perPage: number;
  locationInfo?: IShortLocationInfo;
}

interface IConnectedActions {
  openModal: typeof actionsModal.openCustomModalCallback;
  prompt: typeof actionsModal.prompt;
  setSearch: typeof actionsSearch.set;
  notifySwagger: typeof notifyActions.notifySwagger;
  setPerPage: typeof setPerPage;
}

export default withRouter(connect(
  createStructuredSelector<IGlobalStore, IConnectedProps>({
    perPage: state => state.layout.perPage,
    locationInfo: state => state.currentUser.locationInfo,
  }),
  dispatch => bindActionCreators({
    openModal: actionsModal.openCustomModalCallback,
    prompt: actionsModal.prompt,
    setSearch: actionsSearch.set,
    notifySwagger: notifyActions.notifySwagger,
    setPerPage,
  }, dispatch)
)(withGranted(UnapprovedOrdersList)));

import { types } from '../_actionTypes/search';

export interface ISearchStore {
  callback: (text: string) => void | null;
  url: string;
}

const initialState: ISearchStore = {
  callback: null,
  url: '',
};

interface ISearchAction {
  type: types.SEARCH_SET;
  callback: (text: string) => void | null;
  url: string;
}

export default (state: ISearchStore = initialState, action: ISearchAction): ISearchStore => {
  const {
    callback,
    url,
  } = action;

  switch (action.type) {
    case types.SEARCH_SET:
      return ({
        callback,
        url,
      });

    default:
      return state;
  }
};

import { types } from '../_actionTypes/clients';
import {types as typesNotification} from '../_actionTypes/notification';
import { clientServiceProxy } from '../servicePool';
import {
  getSwaggerErrorResponse,
} from '../_utils/errorHandling';

// @TODO - check and remove

export function getAll(perPage, skip, filter = '') {
  return function (dispatch) {
    dispatch({
      type: types.CLIENT_GET_ALL,
      listStatus: 'loading',
      status: '',
    });
    clientServiceProxy.getClients(perPage, skip, filter)
      .then(data => {
        const {
          items,
          totalCount,
        } = data;
        dispatch({
          type: types.CLIENT_GET_ALL,
          clients: items,
          totalCount,
          listStatus: 'loaded',
          status: '',
        });
      })
      .catch(e => {
        dispatch({
          type: types.CLIENT_GET_ALL,
          listStatus: 'error',
        });
        dispatch({
          type: typesNotification.NOTIFICATION_NOTIFY,
          message: getSwaggerErrorResponse(e).error.message,
          messageType: 'error',
        });
      });
  };
}

export function get(id) {
  return function (dispatch) {
    dispatch({
      type: types.CLIENT_GET,
      status: 'loading',
    });
    clientServiceProxy.getClientForEdit(id)
      .then(client => {
        dispatch({
          type: types.CLIENT_GET,
          client,
          status: 'loaded',
        });
      })
      .catch(e => {
        dispatch({
          type: types.CLIENT_GET,
          status: 'error',
        });
        dispatch({
          type: typesNotification.NOTIFICATION_NOTIFY,
          message: getSwaggerErrorResponse(e).error.message,
          messageType: 'error',
        });
      });
  };
}

export function create(data) {
  return function (dispatch) {
    dispatch({
      type: types.CLIENT_CREATE,
      status: 'saving',
    });
    clientServiceProxy.createClient(data)
      .then(() => {
        dispatch({
          type: types.CLIENT_CREATE,
          status: 'saved',
        });
      })
      .catch(e => {
        dispatch({
          type: types.CLIENT_CREATE,
          status: 'error',
        });
        dispatch({
          type: typesNotification.NOTIFICATION_NOTIFY,
          message: getSwaggerErrorResponse(e).error.message,
          messageType: 'error',
        });
      });
  };
}

export function update(data) {
  return function (dispatch) {
    dispatch({
      type: types.CLIENT_UPDATE,
      status: 'saving',
    });
    clientServiceProxy.updateClient(data)
      .then(() => {
        dispatch({
          type: types.CLIENT_UPDATE,
          status: 'saved',
        });
      })
      .catch(e => {
        dispatch({
          type: types.CLIENT_UPDATE,
          listStatus: 'error',
        });
        dispatch({
          type: typesNotification.NOTIFICATION_NOTIFY,
          message: getSwaggerErrorResponse(e).error.message,
          messageType: 'error',
        });
      });
  };
}

export function getName(id) {
  return function (dispatch) {
    clientServiceProxy.searchClientById(id)
      .then(data => {
        const clientName = data.displayValue;
        dispatch({
          type: types.CLIENT_GET_NAME,
          clientName,
        });
      })
      .catch(e => {
        dispatch({
          type: types.CLIENT_GET_NAME,
          listStatus: 'error',
        });
        dispatch({
          type: typesNotification.NOTIFICATION_NOTIFY,
          message: getSwaggerErrorResponse(e).error.message,
          messageType: 'error',
        });
      });
  };
}

export function remove(id) {
  return function (dispatch) {
    return clientServiceProxy.deleteClient(id)
      .then(data => {
        dispatch({
          type: types.CLIENT_REMOVE,
          listStatus: 'loaded',
        });
      })
      .catch(e => {
        dispatch({
          type: types.CLIENT_REMOVE,
          listStatus: 'error',
        });
        dispatch({
          type: typesNotification.NOTIFICATION_NOTIFY,
          message: getSwaggerErrorResponse(e).error.message,
          messageType: 'error',
        });
      });
  };
}

export function changePage(perPage, skip) {
  return function (dispatch) {
    dispatch({
      type: types.CLIENT_CHANGE_PAGE,
      skip,
      perPage,
    });
  };
}

export function getClientsTree() {
  return function (dispatch) {
    dispatch({
      type: types.CLIENT_GET_CLIENTS_TREE,
      clientUnitsStatus: 'loading',
    });
    clientServiceProxy.getClientsTree()
      .then(data => {
        const clientUnits = data ? data.items : [];
        dispatch({
          type: types.CLIENT_GET_CLIENTS_TREE,
          clientUnits,
          clientUnitsStatus: 'loaded',
        });
      })
      .catch(e => {
        dispatch({
          type: types.CLIENT_GET_CLIENTS_TREE,
          clientUnitsStatus: 'error',
        });
        dispatch({
          type: typesNotification.NOTIFICATION_NOTIFY,
          message: getSwaggerErrorResponse(e).error.message,
          messageType: 'error',
        });
      });
  };
}


import * as React from "react";
import styled from "styled-components";
import { Tree, CatalogBranch, Skeleton } from "../../../components";
import { SimpleMap } from "../../../_types/common";
import {
  IExGetCategoriesDto,
  IModCategoryProductDto,
  TreeNode
} from "../../../_types/clientCatalogEditor";

const TreeWrapper = styled.div`
  height: 524px;
  overflow-y: scroll;
  border-top: 1px solid ${({ theme }) => theme.lighter};
`;

interface IProps {
  categories: TreeNode[];
  isLoading: boolean;
  selectedMap: SimpleMap;
  onSelect: (value: boolean, path: number[], node: TreeNode) => void;
  expandedMap: SimpleMap;
  onExpand: (value: boolean, path: number[], node: TreeNode) => void;
  onRemoveProduct?: (node: IModCategoryProductDto) => void;
  onRenameCategory?: (node: IExGetCategoriesDto, path: number[]) => void;
  showDropDown?: boolean;
}

class CatalogTree extends React.PureComponent<IProps> {
  render() {
    const {
      categories,
      isLoading,
      selectedMap,
      onSelect,
      expandedMap,
      onExpand,
      showDropDown,
      onRemoveProduct,
      onRenameCategory
    } = this.props;

    return (
      <TreeWrapper>
        <Tree
          required
          striped
          nodes={categories}
          isLoading={isLoading}
          onChange={() => {
            return true;
          }}
          expandChildless
          borderless
          renderParent={props => (
            <CatalogBranch
              catalog
              {...props}
              onClick={onSelect}
              onExpand={onExpand}
              expanded={!!expandedMap[props.node.id]}
              selected={!!selectedMap[props.node.id]}
              onRenameCategory={onRenameCategory}
              showDropDown={showDropDown}
            />
          )}
          renderChild={props => (
            <CatalogBranch
              label={
                props.node.preloader ? <Skeleton.Line size="md" /> : undefined
              }
              onClick={
                props.node.preloader || props.node.noProducts
                  ? undefined
                  : onSelect
              }
              selected={!!selectedMap[props.node.id]}
              showDropDown={showDropDown}
              onRemoveProduct={onRemoveProduct}
              {...props}
            />
          )}
          childrenKey="childCategories"
        />
      </TreeWrapper>
    );
  }
}

export default CatalogTree;

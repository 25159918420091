import * as React from 'react';
import styled, { StyledComponentClass } from 'styled-components';
import { Action, ActionCreatorsMapObject, bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { RunningLineLoader } from '../../../components';
import EditRoleModal from './EditModal';
import WithPermission from '../../HOC/WithPermission';

import {
  ADMINISTRATION_ROLES_CREATE,
  ADMINISTRATION_ROLES_UPDATE,
  ADMINISTRATION_ROLES_DELETE
} from '../../../_constants/permissions';

import {
  Table,
  Link,
  MainContainer,
  Button,
  Pagination,
} from '../../../components';

import {
  PencilIcon,
  TrashIcon,
  PlusIcon,
} from '../../../assets/icons';

import * as actions from '../../../_actions/roles';
import * as actionsSearch from '../../../_actions/search';
import * as actionsModal from '../../../_actions/modal';
import { IGlobalStore } from '../../../_reducers/reducers';
import { IOpenConfirmPrompt, IOpenCustomModalCallback } from '../../../_actions/modal';
import { IListRoleMod } from '../../../_reducers/roles';

interface IPosition extends StyledComponentClass<{}, React.StatelessComponent<{}>> {
  Body?: StyledComponentClass<{}, React.StatelessComponent<{}>>;
  Toolbar?: StyledComponentClass<{}, React.StatelessComponent<{}>>;
  Head?: StyledComponentClass<{}, React.StatelessComponent<{}>>;
  HeadElement?: StyledComponentClass<{}, React.StatelessComponent<{}>>;
}

const Position: IPosition = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column; 
`;

Position.Body = styled.div`
  width: 100%; 
  height: 100%; 
`;

Position.Toolbar = styled.header`
  text-align: right;
  height: 15px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
`;

Position.Head = styled<{ className?: string }>(({ className, children }) => <header className={className}>
  <div>
    {children}
  </div>
</header>)`
  padding: 20px;
  >div{
    margin: -7.5px;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }
`;

Position.HeadElement = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  & > * {
    margin: 7.5px;
  }
`;

interface IRoleListProps extends IConnectedProps, IConnectedActions {
}

interface IRoleListState {
  skip: number;
  perPage: number;
  sortBy: string;
  filterText: string;
}

class RolesList extends React.Component<IRoleListProps, IRoleListState> {
  constructor(props) {

    super(props);

    this.handleAddNewClick = this.handleAddNewClick.bind(this);
    this.handleChangePage = this.handleChangePage.bind(this);
    //  this.handleTabChanged = this.handleTabChanged.bind(this);
    this.handleSortChanged = this.handleSortChanged.bind(this);
    this.refreshRoleList = this.refreshRoleList.bind(this);

    this.state = {
      skip: 0,
      perPage: 10,
      sortBy: 'name ASC',
      filterText: '',
    };

    this.props.setSearch('/admin/roles', (text) => {
      this.setState({ skip: 0, filterText: text });
      this.props.getRoles(text, this.state.sortBy, 0, this.state.perPage);
    });

  }

  componentDidMount() {
    this.props.getRoles(this.state.filterText, this.state.sortBy, this.state.skip, this.state.perPage);
  }

  handleChangePage(pageInfo) {
    const {
      perPage,
      skip,
    } = pageInfo;

    this.props.getRoles(this.state.filterText, this.state.sortBy, skip, perPage);

    this.setState({
      skip,
      perPage,
    });
  }

  renderPreloaderRows(perPage, status) {
    const rows = [];
    const elementsCount = (status === 'loading') ? perPage - 1 : 0;

    for (let i = 0; i < elementsCount; i++) {
      rows.push(<Table.Row
        key={i}
        colSpan="3"
        rows={perPage}
        isLoading={status === 'loading'}
        cells={[<span key={0}>NO ROLES FOUND</span>]}
      />);
    }
    return rows;
  }

  handleDelete(roleId) {
    this.props.confirmPrompt().then(() => {
      this.props.deleteRole(roleId).then(() => {
        this.props.getRoles(this.state.filterText, this.state.sortBy, this.state.skip, this.state.perPage);
      });
    }).catch(() => { });
  }

  handleSortChanged(sortValue) {
    this.setState({ sortBy: sortValue });
    this.props.getRoles(this.state.filterText, sortValue, this.state.skip, this.state.perPage, 'sorting');
  }

  handleAddNewClick() {
    this.handleEditClick('new');
  }

  handleEditClick(roleId) {
    this.props.openModal((resolve, reject) => (
      <EditRoleModal id={roleId}
        resolve={resolve}
        reject={reject}
      />
    ),
      { title: `${roleId === 'new' ? 'NEW' : 'EDIT'} ROLE` }
    ).then(res => {
      this.refreshRoleList();
    }).catch(e => {

    });
  }

  refreshRoleList() {
    this.props.getRoles(this.state.filterText, this.state.sortBy, this.state.skip, this.state.perPage, 'sorting');
  }

  render() {
    return (
      <MainContainer title="Roles and permissions" marginTop={20}>
        <Position>
          <Position.Head>
            <Position.HeadElement />
            <Position.HeadElement>
              <WithPermission permission={ADMINISTRATION_ROLES_CREATE}>
                <Button onClick={this.handleAddNewClick} icon={<PlusIcon/>}> New Role</Button>
              </WithPermission>
            </Position.HeadElement>
          </Position.Head>
          <Position.Body>
            <RunningLineLoader isActive={this.props.allStatus === 'sorting' || this.props.allStatus === 'loading'} />
            <Table fullHeight>
              <Table.Header columns={[
                { name: 'name', label: 'name', sortable: true },
                { name: 'creationTime', label: 'Created On', sortable: true },
                { name: '', label: '' }]
              }
                sortBy={this.state.sortBy}
                onSortChanged={this.handleSortChanged}
              />
              <Table.Body>
                {
                  (this.props.roles && !!this.props.roles.length) ?
                    this.props.roles.map((item, index) => {

                      const actionButtons = <Position.Toolbar>
                        <WithPermission permission={ADMINISTRATION_ROLES_UPDATE}>
                          <Link.Button onClick={() => this.handleEditClick(item.id)}><PencilIcon /></Link.Button>
                        </WithPermission>
                        <WithPermission permission={ADMINISTRATION_ROLES_DELETE}>
                          <Link.Button onClick={() => this.handleDelete(item.id)}><TrashIcon /></Link.Button>
                        </WithPermission>
                      </Position.Toolbar>;

                      return (
                        <Table.Row
                          key={`${index}-${item.id}`}
                          cells={[
                            item.name,
                            item.createdAt,
                            actionButtons
                          ]}
                        />);
                    }) :
                    <Table.Row
                      key={0}
                      colSpan="3"
                      rows={this.state.perPage}
                      isLoading={this.props.allStatus === 'loading'}
                      cells={[<span key={0}>NO ROLES FOUND</span>]}
                    />
                }
                {
                  (this.props.roles && !!this.props.roles.length) ||
                  this.renderPreloaderRows(this.state.perPage, this.props.allStatus)
                }
              </Table.Body>
            </Table>
          </Position.Body>
          {
            <Pagination
              skip={this.state.skip}
              perPage={this.state.perPage}
              count={this.props.totalCount}
              onChange={this.handleChangePage}
            />
          }
        </Position>
      </MainContainer>
    );
  }
}

interface IConnectedProps {
  roles: IListRoleMod[];
  allStatus: string;
  totalCount: number;
}

interface IConnectedActions {
  getRoles: typeof actions.getRoles;
  deleteRole: TDeleteRoleType;
  confirmPrompt: IOpenConfirmPrompt;
  openModal: IOpenCustomModalCallback;
  setSearch: typeof actionsSearch.set;
}

type TDeleteRoleType = (id: number) => Promise<void>;

export default connect(
  createStructuredSelector<IGlobalStore, IConnectedProps>({
    roles: (state) => state.roles.roles,
    allStatus: (state) => state.roles.allStatus,
    totalCount: (state) => state.roles.totalCount,
  }),
  dispatch => bindActionCreators<IConnectedActions & ActionCreatorsMapObject, ActionCreatorsMapObject<Action>>({
    getRoles: actions.getRoles,
    deleteRole: actions.deleteRole as any as TDeleteRoleType,
    confirmPrompt: actionsModal.prompt as any as IOpenConfirmPrompt,
    openModal: actionsModal.openCustomModalCallback as any as IOpenCustomModalCallback,
    setSearch: actionsSearch.set,
  }, dispatch)
)(RolesList);

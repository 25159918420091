import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { withRouter } from 'react-router';
import { history } from '../../store';
import { ILocation } from '../../_types/common';

import {
  SearchIcon
} from '../../assets/icons';

import {
  Field,
  Button,
} from '../../components';

import {
  Body as FieldBody,
} from '../../components/Field';
import { createStructuredSelector } from "reselect";
import { IGlobalStore } from "../../_reducers/reducers";
import {clearSearchFilter} from '../../_utils/userSession';
import PubSub from 'pubsub-js';

interface ISearchProps extends IConnectedProps, ILocation {
  className: string;
}

interface ISearchState {
  searchText: string;
  token: '';
}


const Search = styled(class extends React.Component<ISearchProps, ISearchState> {

  public lastSearch;

  constructor(props) {
    super(props);

    this.state = {
      searchText: '',
      token: ''
    };

    this.lastSearch = '';

    this.handleChange = this.handleChange.bind(this);
    this.handleKeyUp = this.handleKeyUp.bind(this);
    this.handleBlur = this.handleBlur.bind(this);
    this.handleClick = this.handleClick.bind(this);
  }

  handleChange(event) {
    this.setState({ searchText: event.target.value });
  }

  handleKeyUp(event) {
    if (event.key === 'Enter') {
      this.lastSearch = this.state.searchText;
      history.push(this.props.url);
      this.props.callback(this.state.searchText);
    } else if (event.key === 'Escape') {
      this.setState({ searchText: '' });
      this.lastSearch = '';
      this.props.callback('');
    }
  }

  handleBlur() {
    if (this.lastSearch !== this.state.searchText) {
      this.props.callback(this.state.searchText);
      history.push(this.props.url);
      this.lastSearch = this.state.searchText;
    }
  }

  handleClick(e) {
    this.lastSearch = this.state.searchText;
    history.push(this.props.url);
    this.props.callback(this.state.searchText);
  }

  componentWillMount(){
    const token = PubSub.subscribe('SEARCH', (msg, data) => {
      this.setState({searchText: data});
    });
    this.setState({token});
  }

  componentWillUnmount(){
    PubSub.unsubscribe(this.state.token);
  }

  componentDidUpdate(prevProps) {
    
    const pages =['/products', 'master-products'];

    if (new RegExp(pages.join("|")).test(this.props.location.pathname)) {
      if(!this.props.location.pathname.startsWith(prevProps.url)){
        clearSearchFilter();
      }
    }



    if (prevProps.location.pathname !== this.props.location.pathname) {
      const isParentDiraction = this.props.location.pathname === prevProps.url;
      if (!isParentDiraction && this.state.searchText) {
        this.setState({ searchText: '' });
      }
    }
  }

  render() {
    return (
      <div
        className={this.props.className}
      >
        <Button
          onClick={this.handleClick}
          negative
        >
          <SearchIcon />
        </Button>
        <Field
          placeholder={'Search'}
          value={this.state.searchText}
          onChange={this.handleChange}
          onKeyUp={this.handleKeyUp}
          onBlur={this.handleBlur}
          compact
        />
      </div>
    );
  }
})`
  ${props => {

    const {
      global,
    } = props.theme;

    return `
      ${global}
      display: flex;
    `;
  }}

  ${FieldBody}{
    ${props => {

    const {
      darkest,
    } = props.theme;

    return `
        max-width: 100%;
        width: 100%;
        
        input{
          max-width: 100%;
          padding: 27px 24px 24px 0;
          margin-bottom: 2px;
          box-shadow: none;
          border: 0;
    
          &:focus{
            border: 0;
            outline: 0;
          }
          
          &:active{
            border: 0;
            outline: 0;
          }
    
          ::-webkit-input-placeholder { 
            color: ${darkest};
          }
          ::-moz-placeholder { 
            color: ${darkest};
          }
          :-ms-input-placeholder { 
            color: ${darkest};
          }
          :-moz-placeholder { 
            color: ${darkest};
          }
        }
    
      `;
  }}
  }

  ${Button}{
    ${props => {
      return `
        border: 0;
        margin-bottom: 2px;
        padding: 17px 0px 10px 14px;
        margin-right: 0px;
        min-width: 57px;
        margin-left: 2px;

        &:hover{
          border: 0;
        }
      `;
    }}
  }

`;



interface IConnectedProps {
  url: string;
  callback: (text: string) => void | null;
}

export default withRouter(connect(
  createStructuredSelector<IGlobalStore, IConnectedProps>({
    url: state => state.search.url,
    callback: state => state.search.callback
  }),
  null)(Search));

import React from 'react';
import { withRouter } from 'react-router';
import List from './List';
import Edit from './Edit';
import {
  ADMINISTRATION_VENDORS,
  ADMINISTRATION_VENDORS_CREATE,
  ADMINISTRATION_VENDORS_EDIT,
} from '../../../_constants/permissions';
import RoutesMapper from '../../Router/RoutesMapper';
import { IRouteEntry } from '../../../_types/common';

const routes: IRouteEntry[] = [
  {
    path: '',
    exact: true,
    component: List,
    permission: ADMINISTRATION_VENDORS,
  },
  {
    path: '/new',
    exact: true,
    render: (props) => <Edit {...props} />,
    permission: ADMINISTRATION_VENDORS_CREATE,
    keepForbiddenRoute: true,
  },
  {
    path: '/:id',
    exact: true,
    render: (props, match) => <Edit {...props} />,
    permission: ADMINISTRATION_VENDORS_EDIT,
  },
];

const Vendor = (props) => {
  return <RoutesMapper routes={routes} addNotFound {...props} />;
};

export default withRouter(Vendor);
import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import OriginalDropzone from 'react-dropzone';
import { SortableContainer, SortableElement, SortableHandle } from 'react-sortable-hoc';

import {
  Link,
} from '../components';

import {
  UploadIcon,
  XIcon,
  ReorderIcon,
  PreloaderIcon,
} from '../assets/icons';

export const Label = styled.label`
  ${ props => {

    let {
      global,
      warning,
      primary,
      fs_md,
    } = props.theme;

    let color = !props.error ?
      primary :
      warning;

    return `
        ${global}
        color: ${color};
        font-size: ${fs_md}; 
      `;
  }}
`;

export const Error = styled.div`
  ${ ({ theme, compact }) => {

    let {
      global,
      warning,
      fs_xs,
    } = theme;

    let position = compact ?
      'absolute' :
      'static';

    return `
        ${global}
        position: ${position};
        min-height: 19px;
        margin: 0 0 3px;
        font-weight: 300;
        color: ${warning};
        font-size: ${fs_xs}; 
      `;
  }}
`;

export const Body = styled.div`
  ${ ({ compact, theme }) => {

    let {
      global,
    } = theme;

    let margin = compact ?
      '0' :
      '7px 0 3px';

    return `
      ${global}
      position: relative;
      margin: ${margin};
    `;
  }}
`;

export const Toolbar = styled.div`
  ${({ theme }) => {

    let {
      global,
      primary,
      fs_md,
      light,
    } = theme;

    return `
      ${global}
      color: ${primary};
      font-size: ${fs_md};
      text-align: center;

      i{
        path{
          fill: ${light};
        }
      }
    `;
  }}
`;

export const Dropzone = styled(class extends React.Component {
  static defaultProps = {
    type: 'image',
  };
  static propTypes = {
    className: PropTypes.string,
    children: PropTypes.oneOfType([
      PropTypes.object,
      PropTypes.array,
    ]),
    nestRef: PropTypes.func,
    type: PropTypes.string,
  };
  render() {

    let {
      className,
      children,
      nestRef,
      type,
      ...restProps
    } = this.props;

    let fileTypes = {
      image: 'image/jpeg, image/png, image/bmp, image/tiff',
    };

    return <OriginalDropzone
      accept={fileTypes[type] || ''}
      className={className}
      ref={node => nestRef(node)}
      {...restProps}
    >
      {children}
    </OriginalDropzone>;
  }
}).attrs({
  activeClassName: "active",
  acceptClassName: "accept",
  rejectClassName: "reject",
  disabledClassName: "disabled",
  style: {},
})`
  ${({ theme }) => {

    let {
      global,
      primary,
      success,
      defaultRGB,
      warning,
      light,
    } = theme;

    return `
      ${global}
      border: 1px dashed ${primary};
      padding: 10px 20px;

      &.active{
        border: 1px dashed rgba(${defaultRGB}, 0.5);
      }

      &.accept{
        border: 1px dashed ${success};
      }

      &.reject{
        border: 1px dashed ${warning};
      }

      &.disabled{
        border: 1px dashed ${light};
      }
    `;
  }}
`;

export const Items = SortableContainer(styled.div`
  margin: -10px -10px 10px ;
  display: flex;
  flex-wrap: wrap;
`);

export const Item = styled(SortableElement(class extends React.Component {

  static propTypes = {
    onDelete: PropTypes.func,
    number: PropTypes.number,
    className: PropTypes.string,
    data: PropTypes.object,
    onClick: PropTypes.func,
    urlkey: PropTypes.string,
    urlPrefix: PropTypes.string,
  };

  constructor(props) {
    super(props);

    this.urlkey = props.urlkey || 'pictureUrl';
    this.urlPrefix = props.urlPrefix;

    this.state = {
      isLoaded: false,
    };

    this.handleDelete = this.handleDelete.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.handleLoad = this.handleLoad.bind(this);
  }

  handleDelete() {
    if (this.props.onDelete) {
      this.props.onDelete(this.props.number);
    }
  }

  handleClick(e) {
    if (this.props.onClick) {
      this.props.onClick(this.props.number, e);
    }
  }

  handleLoad(e) {
    this.setState({
      isLoaded: true,
    });
  }

  render() {
    let {
      className,
      data,
    } = this.props;

    return <div className={className}>
      <ItemTool>
        <DragHandle />
        <Link.Button onClick={this.handleDelete}><XIcon /></Link.Button>
      </ItemTool>
      <ItemBody loaded={this.state.isLoaded}>
        {
          !this.state.isLoaded &&
          <PreloaderIcon />
        }
        {
          data.pictureUrl &&
          <img src={this.urlPrefix + data[this.urlkey]} onClick={this.handleClick} onLoad={this.handleLoad} alt={this.urlPrefix + data[this.urlkey]} />
        }
        {
          data.preview &&
          <img src={data.preview} onClick={this.handleClick} onLoad={this.handleLoad} alt={data.preview} />
        }
      </ItemBody>
    </div>;
  }
}))`
  ${({ theme }) => {

    let {
      global,
    } = theme;

    return `
      ${global}
      display: inline-flex;
      padding: 20px 0 0;
      margin: 0 10px;
      height: 70px;
      position: relative;
      overflow: hidden;
      &:hover{
        > .${ItemTool.styledComponentId}{
          opacity: 1;
        }
      }

      .${PreloaderIcon.styledComponentId}{
        position: absolute;
        left: 50%;
        margin-left: -10px;
      }
    `;
  }}
`;

const ItemBody = styled.div`
  ${({ theme, ...props }) => {

    let {
      global,
      light,
    } = theme;

    let visibility = props.loaded ?
      'visible' :
      'hidden';

    return `
      ${global}
      width: 50px;
      height: 50px;
      overflow: hidden;
      display: inline-flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      border: 1px solid ${light};
      img{
        height: 100%;
        width: 100%;
        object-fit: cover;
        display: inline;
        visibility: ${visibility}; 
      }  
    `;
  }}
`;

const ItemTool = styled.div`
  ${({ theme }) => {

    let {
      global,
    } = theme;

    return `
      ${global}
      position: absolute;
      top: 0;
      width: 100%;
      height: 20px;
      align-items: center;
      display: flex;
      justify-content: space-between;
      opacity: 0;
      svg{
        width: 10px;
      }
      .${Link.Button.styledComponentId}{
        height: 20px;
      }
    `;
  }}
`;

const DragHandle = SortableHandle(styled(({ className }) =>
  <span className={className}><ReorderIcon /></span>
)`
  flex: 0 0 auto;
  display: flex;
  align-items: center;
  cursor: move;
`);

export default styled(class extends React.Component {
  static propTypes = {
    className: PropTypes.string.isRequired,
    label: PropTypes.string,
    error: PropTypes.string,
    required: PropTypes.bool,
    compact: PropTypes.bool,
    value: PropTypes.array,
    onDrop: PropTypes.func,
    onDelete: PropTypes.func,
    sortable: PropTypes.bool,
    onSort: PropTypes.func,
    onClick: PropTypes.func,
    urlkey: PropTypes.string,
    urlPrefix: PropTypes.string,
  };

  constructor(props) {
    super(props);

    this.handleDrop = this.handleDrop.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
    this.handleSort = this.handleSort.bind(this);
    this.handleClick = this.handleClick.bind(this);
  }

  handleDrop(acceptedFiles) {
    if (this.props.onDrop) {
      this.props.onDrop(acceptedFiles);
    }
  }

  handleDelete(index) {
    if (this.props.onDelete) {
      this.props.onDelete(index);
    }
  }

  handleSort(items) {
    if (this.props.onSort) {
      this.props.onSort(items);
    }
  }

  handleClick(index) {
    if (this.props.onClick) {
      this.props.onClick(index);
    }
  }

  render() {

    let {
      className,
      label,
      error,
      required,
      compact,
      value,
      ...restProps
    } = this.props;

    let dropzoneRef;


    return <div className={className}>
      {
        label &&
        <Label
          error={error}
          htmlFor={restProps.id ? restProps.id : null}
        >
          {`${label} ${required ? ' * ' : ''}`}
        </Label>
      }
      <Body>
        <Dropzone
          onDrop={this.handleDrop}
          disableClick
          nestRef={node => {
            dropzoneRef = node;
          }}
        >
          {
            (value && !!value.length) &&
            <Items
              axis="xy"
              useDragHandle
              onSortEnd={this.handleSort}
            >
              {
                value.map((item, index) => <Item
                  key={index}
                  data={item}
                  number={index}
                  index={index}
                  onDelete={this.handleDelete}
                  onSort={this.handleSort}
                  onClick={this.handleClick}
                  urlkey={this.props.urlkey}
                  urlPrefix={this.props.urlPrefix}
                />)
              }
            </Items>
          }
          <Toolbar>
            <UploadIcon /> Drop files to attach, or <Link.Button onClick={() => { dropzoneRef.open(); }}>browse</Link.Button>
          </Toolbar>
        </Dropzone>
      </Body>
      <Error compact={compact}>
        {error}
      </Error>
    </div>;
  }
})``;
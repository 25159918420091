import {types} from '../_actionTypes/layout';

export function drawerToggle(drawerStatus: boolean) {
  return {
    type: types.LAYOUT_DRAWER_TOGGLE,
    drawerStatus,
  };
}

export function openCart() {
  return {
    type: types.LAYOUT_OPEN_CART,
  };
}

export function closeRightSlider() {
  return {
    type: types.LAYOUT_CLOSE_RIGHT_SLIDER,
  };
}

export function menuShow(menuStatus: boolean) {
  return {
    type: types.LAYOUT_MENU_SHOW,
    menuStatus,
  };
}

export function setPerPage(perPage: number) {
  return {
    type: types.LAYOUT_SET_PER_PAGE,
    perPage,
  };
}
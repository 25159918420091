import * as types from '../_actionTypes/users';
import { types as currentUserTypes } from '../_actionTypes/current-user';

const initialState = {
  users: [],
  totalCount: null,
  allStatus: '',
  user: {
    buyingManagers: null,
  },
  userName: '',
  addressOverrides: [],
  addressOverridesStatus: '',
  userSupervisorsStatus: '',
  status: '',
};

export default (state = initialState, action) => {
  let {
    users,
    allStatus,
    user,
    status,
    totalCount,
  } = action;

  switch (action.type) {
    case types.USER_GET_ALL:
      return ({
        ...state,
        users,
        totalCount,
        allStatus,
        status,
      });
    case types.USER_GET_BY_ID:
      return ({
        ...state,
        user,
        status,
      });
    case types.USER_CREATE:
      return ({
        ...state,
        user,
        status,
      });
    case types.USER_UPDATE:
      return ({
        ...state,
        user,
        status,
      });
    case types.USER_REACTIVATE:
      return ({
        ...state,
        allStatus,
      });
    case types.USER_UPDATE_PERMISSIONS:
      return ({
        ...state,
        user,
        status,
      });
    case types.USER_GET_PERMISSIONS:
      return ({
        ...state,
        user: {
          ...state.user,
          permissions: action.permissions,
        }
      });
    case types.USER_GET_SUPERVISORS:
      return ({
        ...state,
        userSupervisorsStatus: action.userSupervisorsStatus,
        user: {
          ...state.user,
          buyingManagers: action.buyingManagers,
        }
      });
    case types.USER_GET_OVERRIDE_LOCATIONS:
      return ({
        ...state,
        addressOverrides: action.addressOverrides,
        addressOverridesStatus: action.addressOverridesStatus,
      });
    case currentUserTypes.CURRENT_USER_CLEAR_AUTH_DATA:
      return initialState;
    default:
      return state;
  }
};

import * as React from "react";
import styled from "styled-components";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { RouteComponentProps } from "react-router";
import { PurchasedProductsListOutput } from "../../service-proxies";

import { addProductToCart } from "../../_actions/cart";
import { openCart } from "../../_actions/layout";
import {
  MainContainer,
  Button,
  Pagination,
  Checkbox,
  Table,
  Link,
  Thumbnail,
  Skeleton,
  Separator
} from "../../components";
import { ORDERS_SUBMIT } from "../../_constants/permissions";
import * as productsApi from "../../_api/products";
import * as actionsSearch from "../../_actions/search";
import * as actionsNotify from "../../_actions/notification";
import WithGranted, { IWithGrantedProps } from "../HOC/WithGranted";
import { apiUrl } from "../../_constants/system";
import { IGlobalStore } from "../../_reducers/reducers";

const Position = {
  Container: styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
  `,

  Body: styled.div`
    width: 100%;
    display: flex;
    flex: 1 1 100%;
  `,

  Content: styled.div`
    flex: 1 1 100%;
    display: flex;
    flex-direction: column;
    ${Table} {
      flex: 1 1 100%;
    }
  `,

  Element: styled.div`
    display: flex;
    align-items: center;
  `,

  Toolbar: styled.div`
    text-align: right;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding-right: 5px;
  `,

  SelectToolbar: styled(Separator).attrs({
    borders: "1"
  })`
    display: flex;
    justify-content: space-between;
    height: 59px;
    box-sizing: border-box;
    padding: 14px 20px;
  `
};

interface IMultiselectCheckbox {
  id: number;
  onChange: (id: number, value: boolean) => void;
  value: boolean;
}

const MultiselectCheckbox: React.SFC<IMultiselectCheckbox> = ({
  onChange,
  id,
  ...props
}) => {
  function handleChange() {
    onChange(id, props.value);
  }

  return <Checkbox onChange={handleChange} {...props} />;
};

interface IAddButton {
  onClick: (item: PurchasedProductsListOutput) => void;
  item: PurchasedProductsListOutput;
}

const AddButton: React.SFC<IAddButton> = ({ onClick, item, ...props }) => {
  function handleClick() {
    if (onClick) {
      onClick(item);
    }
  }

  return (
    <Button primary negative scale="sm" onClick={handleClick}>
      {props.children}
    </Button>
  );
};

interface IMultipleAddButton {
  ids: number[];
  onClick: (ids: number[]) => void;
  count: number;
}

const MultipleAddButton: React.SFC<IMultipleAddButton> = ({
  onClick,
  ids,
  count
}) => {
  function handleClick() {
    if (onClick) {
      onClick(ids);
    }
  }

  return (
    <Button negative primary onClick={handleClick}>
      {`Add Selected (${count})`}
    </Button>
  );
};

function getProductThumbnail(item: PurchasedProductsListOutput) {
  return (
    <Thumbnail
      image={
        item.picture && item.picture.pictureUrl
          ? apiUrl + item.picture.pictureUrl
          : ""
      }
      name={item.picture && item.picture.name ? item.picture.name : "thumbnail"}
    />
  );
}

interface IListProps
  extends IConnectedProps,
    RouteComponentProps<{}>,
    IConnectedActions,
    IWithGrantedProps {}

interface IConnectedState {
  catalogId?: number;
  perPage?: number;
}

interface IListState extends IConnectedState {
  selectedIds: number[];
  catalogNotExist: boolean;
  products?: PurchasedProductsListOutput[];
  skip: number;
  filter: string;
  status: string | "loading" | "done";
  totalCount?: number;
}

interface IGetPurchasedProductsParams extends IConnectedProps {
  skip?: number;
  filter?: string;
}

class List extends React.Component<IListProps, IListState> {
  constructor(props) {
    super(props);
    this.getPurchasedProducts = this.getPurchasedProducts.bind(this);
    this.handleChangePage = this.handleChangePage.bind(this);
    this.handleSelectProduct = this.handleSelectProduct.bind(this);
    this.handleAddProductToCart = this.handleAddProductToCart.bind(this);
    this.handleMultipleAddProductToCart = this.handleMultipleAddProductToCart.bind(
      this
    );

    this.state = {
      selectedIds: [],
      catalogNotExist: false,
      status: "loading",
      skip: 0,
      filter: ""
    };
  }

  componentDidMount() {
    const catalogId = this.props.catalogId;
    const perPage = this.props.perPage;

    if (!catalogId) {
      this.setState({ catalogNotExist: true });
      return;
    }

    this.setState({
      catalogId,
      perPage
    });

    this.getPurchasedProducts();
    this.props.setSearch("/purchase-history", text => {
      this.setState({ skip: 0 }, () => {
        this.getPurchasedProducts({
          catalogId,
          perPage: this.props.perPage,
          skip: 0,
          filter: text
        });
      });
    });
  }

  getPurchasedProducts(params: IGetPurchasedProductsParams = {}) {
    const catalogId = params.catalogId || this.props.catalogId;
    const skip =
      Number.isInteger(params.skip) && params >= 0
        ? params.skip
        : this.state.skip;
    const filter = params.filter || this.state.filter;
    const perPage = params.perPage || this.props.perPage;

    const statusStartKey = "loading";
    const statusFinnishKey = "done";

    this.setState({ status: statusStartKey });

    productsApi
      .getPurchasedProducts(catalogId, perPage, skip, filter)
      .then(productsData => {
        this.setState({
          status: statusFinnishKey,
          products: productsData.items,
          totalCount: productsData.totalCount
        });
      })
      .catch(e => {
        this.props.notify(e, "error");
      });
  }

  static getDerivedStateFromProps(props: IConnectedProps, state: IListState) {
    const newState: {
      catalogNotExist?: boolean;
      selectedIds?: number[];
    } & IConnectedState = {};
    if (state.perPage !== props.perPage) {
      newState.perPage = props.perPage;
      newState.selectedIds = [];
    }
    if (state.catalogId !== props.catalogId) {
      newState.catalogId = props.catalogId;
      newState.catalogNotExist = !props.catalogId;
      newState.selectedIds = [];
    }

    return newState;
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevState.catalogId !== this.state.catalogId ||
      prevState.perPage !== this.state.perPage
    ) {
      this.setState({
        catalogNotExist: !this.props.catalogId,
        selectedIds: []
      });
      if (this.props.catalogId) {
        this.props.setSearch("/purchase-history", text => {
          this.setState({ skip: 0 }, () => {
            this.getPurchasedProducts({
              catalogId: this.state.catalogId,
              perPage: this.state.perPage,
              skip: 0,
              filter: text
            });
          });
        });
        this.getPurchasedProducts();
      }
    }
  }

  handleAddProductToCart(product) {
    this.props.addProductToCart(product, 0, 1);
    this.props.openCart();
  }

  handleMultipleAddProductToCart() {
    const productsForAdd = this.state.products.reduce((products, product) => {
      if (this.state.selectedIds.indexOf(product.id) >= 0) {
        products.push(product);
      }
      return products;
    }, []);
    productsForAdd.forEach(product => {
      this.props.addProductToCart(product, 0, 1);
    });
    this.props.openCart();
  }

  handleChangePage(pageInfo) {
    const { perPage, skip } = pageInfo;

    const catalogId = this.state.catalogId;

    this.setState({ skip }, () => {
      this.getPurchasedProducts({ catalogId, perPage, skip });
    });
  }

  handleSelectProduct(id, value) {
    let selectedIds = [];
    if (!value) {
      selectedIds = [...this.state.selectedIds, id];
    } else {
      selectedIds = this.state.selectedIds.filter(item => {
        return id !== item;
      });
    }
    this.setState({ selectedIds });
  }

  renderPreloaderRows(perPage, status) {
    const rows = [];
    const elementsCount = status === "loading" ? perPage - 1 : 0;

    const catalogId = this.state.catalogId;

    for (let i = 0; i < elementsCount; i++) {
      rows.push(
        <Table.Row
          key={"preloader" + i}
          rows={perPage}
          cells={[
            <React.Fragment key={`checkbox-preloader-${i}`}>
              {!catalogId && <Skeleton.Line size="xs" />}
            </React.Fragment>,
            <Skeleton.Square key={`thumbnail-preloader-${i}`} />,
            <React.Fragment key={`info-preloader-${i}`}>
              <p>
                <Skeleton.Line />
              </p>
              <p>
                <Skeleton.Line />
              </p>
              <p>
                <Skeleton.Line />
              </p>
            </React.Fragment>,
            <Skeleton.Line key={`actions-preloader-${i}`} size="sm" />
          ]}
        />
      );
    }
    return rows;
  }

  render() {
    return (
      <MainContainer>
        <Position.Container>
          <Position.Body>
            <Position.Content>
              {!!this.state.selectedIds.length && (
                <Position.SelectToolbar>
                  <Position.Element />
                  <Position.Element>
                    <MultipleAddButton
                      ids={this.state.selectedIds}
                      onClick={this.handleMultipleAddProductToCart}
                      count={this.state.selectedIds.length}
                    />
                  </Position.Element>
                </Position.SelectToolbar>
              )}
              {
                <Table headless fullHeight>
                  <Table.Header
                    columns={[
                      { name: "check", label: "", size: "sm" },
                      { name: "thumbnail", label: "", size: "sm" },
                      { name: "product", label: "product" },
                      { name: "actions", label: "", size: "sm" }
                    ]}
                  />
                  <Table.Body>
                    {this.state.status === "done" &&
                      !this.state.catalogNotExist &&
                      (this.state.products && !!this.state.products.length) &&
                      this.state.products.map((item, index) => (
                        <Table.Row
                          key={`${index}-${item.id}`}
                          cells={[
                            <React.Fragment key={`checkbox-${index}`}>
                              {this.props.isGranted(ORDERS_SUBMIT) &&
                                !item.isDiscontinued && (
                                  <MultiselectCheckbox
                                    id={item.id}
                                    onChange={this.handleSelectProduct}
                                    value={
                                      this.state.selectedIds.indexOf(
                                        item.id * 1
                                      ) > -1
                                    }
                                  />
                                )}
                            </React.Fragment>,

                            item.isDiscontinued ? (
                              getProductThumbnail(item)
                            ) : (
                              <Link
                                key={`thumbnail-${index}-${item.id}`}
                                to={`${this.props.match.url}/product/${
                                  item.id
                                }`}
                              >
                                {getProductThumbnail(item)}
                              </Link>
                            ),
                            [
                              <p key={`name-${index}-${item.id}`}>
                                {item.isDiscontinued ? (
                                  item.name
                                ) : (
                                  <Link
                                    to={`${this.props.match.url}/product/${
                                      item.id
                                    }`}
                                  >
                                    {item.name}
                                  </Link>
                                )}
                              </p>,
                              <p key={`sku-${index}-${item.id}`}>
                                <span style={{ color: "#000" }}>
                                  {item.sku}
                                </span>
                              </p>,
                              <p key={`desc-${index}-${item.id}`}>
                                <span style={{ color: "#000" }}>
                                  {item.description}
                                </span>
                              </p>
                            ],
                            <Position.Toolbar>
                              <React.Fragment
                                key={`cell-price-${index}-${item.id}`}
                              >
                                {!item.isDiscontinued
                                  ? this.props.isGranted(ORDERS_SUBMIT) &&
                                    `$${item.price.toFixed(2)}`
                                  : "Discontinued"}
                              </React.Fragment>
                            </Position.Toolbar>,

                            <Position.Toolbar>
                              <React.Fragment
                                key={`cell-toolbar-${index}-${item.id}`}
                              >
                                {!item.isDiscontinued
                                  ? this.props.isGranted(ORDERS_SUBMIT) && (
                                      <AddButton
                                        key={`cell-toolbar-${index}-${item.id}`}
                                        item={item}
                                        onClick={this.handleAddProductToCart}
                                      >
                                        Add
                                      </AddButton>
                                    )
                                  : "Discontinued"}
                              </React.Fragment>
                            </Position.Toolbar>
                          ]}
                        />
                      ))}
                    {this.state.products &&
                      !this.state.products.length &&
                      this.state.status !== "loading" && (
                        <Table.Row
                          key={"no_poroducts"}
                          colSpan="4"
                          cells={[
                            <span key="0">
                              {this.state.catalogNotExist
                                ? "This location's Division does not have a catalog that has been setup. Please contact your administrator for further assistance."
                                : "NO ORDERS YET PLACED / NO PURCHASE HISTORY"}
                            </span>
                          ]}
                        />
                      )}
                    {this.state.status === "loading" &&
                      !this.state.catalogNotExist &&
                      this.renderPreloaderRows(
                        this.state.perPage,
                        this.state.status
                      )}
                  </Table.Body>
                </Table>
              }
              <Pagination
                skip={this.state.skip}
                perPage={this.state.perPage}
                count={this.state.totalCount}
                onChange={this.handleChangePage}
              />
            </Position.Content>
          </Position.Body>
        </Position.Container>
      </MainContainer>
    );
  }
}

interface IConnectedProps {
  perPage?: number;
  catalogId?: number;
}

interface IConnectedActions {
  setSearch: typeof actionsSearch.set;
  addProductToCart: typeof addProductToCart;
  openCart: typeof openCart;
  notify: typeof actionsNotify.notifySwagger;
}

export default connect(
  createStructuredSelector<IGlobalStore, IConnectedProps>({
    perPage: state => state.layout.perPage,
    catalogId: state => state.currentUser.catalogId
  }),
  dispatch =>
    bindActionCreators(
      {
        setSearch: actionsSearch.set,
        addProductToCart,
        openCart,
        notify: actionsNotify.notifySwagger
      },
      dispatch
    )
)(WithGranted(List));

import * as React from 'react';
import styled, { StyledComponentClass } from 'styled-components';

import { IThemeProps } from '../assets/theme';

export interface IPProps extends IThemeProps {
  primary?: boolean;
  light?: boolean;
  bold?: boolean;
  thin?: boolean;
  scale?: string;
}

export const P = styled<{ primary?: boolean; light?: boolean; thin?: boolean; scale?: string; bold?: boolean; }, 'p'>('p')`
  ${({ theme, ...props }) => {
    const {
      global, primary, dark, fs_xxxl, fs_xxl, fs_xl, fs_lg, fs_md, fs_sm, fs_xs, fs_xxs,
    } = theme;

    let color = props.primary ?
      theme.default :
      primary;

    if (props.light) {
      color = dark;
    }

    let weight = 400;

    if (props.bold) {
      weight = 600;
    }

    if (props.thin) {
      weight = 300;
    }

    let size;
    let lineHeight;

    switch (props.scale) {
      case 'xxxl':
        size = fs_xxxl;
        lineHeight = '38px';
        break;
      case 'xxl':
        size = fs_xxl;
        lineHeight = '30px';
        break;
      case 'xl':
        size = fs_xl;
        lineHeight = '28px';
        break;
      case 'lg':
        size = fs_lg;
        lineHeight = '26px';
        break;
      case 'md':
        size = fs_md;
        lineHeight = '24pxpx';
        break;
      case 'sm':
        size = fs_sm;
        lineHeight = '23px';
        break;
      case 'xs':
        size = fs_xs;
        lineHeight = '22px';
        break;
      case 'xxs':
        size = fs_xxs;
        lineHeight = '21px';
        break;
    }

    return `
      ${global}
      > * {
        font-size: ${size};
      }
      font-size: ${size};
      font-weight: ${weight};
      color: ${color};
      line-height: ${lineHeight};
    `;
  } }
` as unknown as StyledComponentClass<IPProps, any>;

const H1 = styled(P.withComponent('h1'))`
  ${({ theme }) => {
    const {
      fs_xxl,
    } = theme;
    return `
      font-size: ${fs_xxl};
      line-height: 30px;
      text-transform: uppercase;
      font-weight: 600;
    `;
  }}
`;
const H2 = styled(P.withComponent('h2'))`
  ${({ theme }) => {
    const {
      fs_xl,
    } = theme;
    return `
      font-size: ${fs_xl};
      line-height: 27px;      
      text-transform: uppercase;
      font-weight: 600;
    `;
  }}
`;
const H3 = styled(P.withComponent('h3'))`
  ${({ theme }) => {
    const {
      fs_lg,
    } = theme;
    return `
      font-size: ${fs_lg};
      line-height: 24px;      
      text-transform: uppercase;
      font-weight: 600;
    `;
  }}
`;
const H4 = styled(P.withComponent('h4'))`
  ${({ theme }) => {
    const {
      fs_md,
    } = theme;
    return `
      font-size: ${fs_md};
      line-height: 21px;      
      text-transform: uppercase;
      font-weight: 600;
    `;
  }}
`;
const H5 = styled(P.withComponent('h5'))`
  ${({ theme }) => {
    const {
      fs_sm,
    } = theme;
    return `
      font-size: ${fs_sm};
      line-height: 20px;      
      text-transform: uppercase;
      font-weight: 600;
    `;
  }}
`;
const H6 = styled(P.withComponent('h6'))`
  ${({ theme }) => {
    const {
      fs_xs,
    } = theme;
    return `
      font-size: ${fs_xs};
      line-height: 19px;      
      text-transform: uppercase;
      font-weight: 600;
    `;
  }}
`;

const Text = styled(P.withComponent('span'))`` as StyledComponentClass<IPProps, any>;

const Big = styled(Text)`
  ${({ theme }) => {
    const {
      fs_xxxl,
    } = theme;
    return `
      font-size: ${fs_xxxl};
    `;
  }}
`;

const Small = styled(Text)`
  ${({ theme }) => {
    const {
      fs_xxs,
    } = theme;
    return `
      font-size: ${fs_xxs};
    `;
  }}
`;

interface IArticleProps extends IThemeProps {
  children: JSX.Element;
  className?: string;
}

interface IArticle extends StyledComponentClass<IArticleProps, any, IThemeProps> {
  P?: StyledComponentClass<IPProps, any>;
  H1?: React.StatelessComponent;
  H2?: React.StatelessComponent;
  H3?: React.StatelessComponent;
  H4?: React.StatelessComponent;
  H5?: React.StatelessComponent;
  H6?: React.StatelessComponent;
  Text?: StyledComponentClass<IPProps, any>;
  Big?: StyledComponentClass<{}, any>;
  Small?: React.StatelessComponent;
}

const Article: IArticle = styled(class extends React.Component<IArticleProps>{
  static P = P;
  static H1 = H1;
  static H2 = H2;
  static H3 = H3;
  static H4 = H4;
  static H5 = H5;
  static H6 = H6;
  static Text = Text;
  static Big = Big;
  static Small = Small;
  render() {
    return <article className={this.props.className}>
      {this.props.children}
    </article>;
  }
})`
  ${({ theme, ...props }) => {
    return `
      padding: 20px;
    `;
  }}
`;



export default Article;

import React from 'react';
import styled from 'styled-components';
import moment from 'moment';

import {
  Select,
} from '../components';

import {
  ClockIcon,
} from '../assets/icons';

export default styled(class extends React.Component {
  constructor(props) {
    super(props);
    this.generateOptions = this.generateOptions.bind(this);
    this.timeList = this.generateOptions();
  }

  generateOptions(step = 30) {
    let options = [];

    for (let j = 0; j < 24; j++) {

      let hours = j.toString();

      for (let k = 0; k < 60; k += step) {

        let minuts = k.toString();
        let time = `${hours}:${minuts}`;

        options.push({
          label: moment(time, 'HH:mm').format('hh:mm a'),
          value: moment(time, 'HH:mm').format('HH:mm'),
        });

      }
    }

    return options;
  }

  render() {
    return <Select
      {...this.props}
      options={this.timeList}
      icon={ClockIcon}
      iconStatic
    />;
  }
}) ``;

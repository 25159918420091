import React from 'react';
import styled from 'styled-components';
import PropTypes from "prop-types";

class Tabs extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      activeTabIndex: 0
    };

    this.handleTabClick = this.handleTabClick.bind(this);
  }

  handleTabClick(tabIndex) {
    this.setState({
      activeTabIndex: tabIndex
    });
  }

  renderChildrenWithTabsApiAsProps() {
    return React.Children.map(this.props.children, (child, index) => {
      return React.cloneElement(child, {
        onClick: this.handleTabClick,
        tabIndex: index,
        isActive: index === this.state.activeTabIndex
      });
    });
  }

  renderActiveTabContent() {
    const {children} = this.props;
    const {activeTabIndex} = this.state;

    if (children[activeTabIndex]) {
      return children[activeTabIndex].props.children;
    }
  }

  render() {
    const {className} = this.props;

    return (
      <div className={className}>
        <ul>
          {this.renderChildrenWithTabsApiAsProps()}
        </ul>
        <div>
          {this.renderActiveTabContent()}
        </div>
      </div>
    );
  }
}

Tabs.propTypes = {
  className: PropTypes.string.isRequired,
  defaultActiveTabIndex: PropTypes.number,
  children: PropTypes.array
};

export default styled(Tabs)`
  ${ props => {

  let {
    global,
    light
  } = props.theme;

  return `
    ${global}

        ${props.width ? `width: ${props.width};` : ''}
    
    ${Tabs} ul {
        display: flex;
        text-align: center;
        list-style: none;
        padding: 0;
        line-height: 24px;
        display: flex;
        border-bottom: .5px solid ${light};
        margin-top: -1px;

        li {
            flex-grow: 1;s
            padding-right: 25px;
            padding-left: 25px;
            position: relative;
            bottom: -.5px;
            border-left: .5px solid ${light}
        }
        li:first-of-type {
            border-left: none;
        }
    }
    `;
}}
`;

import * as React from "react";
import { connect } from "react-redux";
import styled from "styled-components";

import { ICartProductEntry } from "../../../_reducers/cart";
import {
  productCountChange,
  removeProductFromCart
} from "../../../_actions/cart";

import { apiUrl } from "../../../_constants/system";

import { Table, Thumbnail, Link, Counter, Article } from "../../../components";

import { TrashIcon } from "../../../assets/icons";
import { formatPrice, roundAndFormatPrice } from "../../../_utils/prices";

interface IDeleteButtonProps {
  id?: number;
  onClick: (id: number) => void;
}

const DeleteButton = function(props: IDeleteButtonProps) {
  function handleClick() {
    props.onClick(props.id);
  }

  return (
    <Link.Button onClick={handleClick}>
      <TrashIcon />
    </Link.Button>
  );
};

interface IItemCounterProps {
  id: number;
  value: number | string;
  onChange: (id: number, value: number) => void;
}

const ItemCounter = function(props: IItemCounterProps) {
  function handleChange(value) {
    props.onChange(props.id, value);
  }

  return <Counter value={props.value} onChange={handleChange} compact />;
};

const CenteredContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
`;

const RightFlexContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  height: 100%;
  width: 100%;
`;

const RightContainer = styled.div`
  text-align: right;
  height: 100%;
  width: 100%;
`;

const Position = class {
  static CenteredContainer = CenteredContainer;
  static RightContainer = RightContainer;
  static RightFlexContainer = RightFlexContainer;
};

interface IPrices {
  [props: number]: number;
}

interface IFormProps extends IConnectedActions {
  productEntries: ICartProductEntry[];
  pricesMap: IPrices;
  setFieldValue: (fieldName: string, newData: any) => void;
  onResetWarning: () => void;
}

interface IForm {
  handleCountChange: (id: number, value: number) => void;
  handleDelete: (id: number) => void;
}

class Form extends React.Component<IFormProps, any> implements IForm {
  constructor(props) {
    super(props);
    this.handleDelete = this.handleDelete.bind(this);
    this.handleCountChange = this.handleCountChange.bind(this);
  }

  handleCountChange(id: number, value: number) {
    this.props.productCountChange(id, value);
    this.props.onResetWarning();
  }

  handleDelete(id: number) {
    this.props.removeProductFromCart(id);
    this.props.onResetWarning();
  }

  render() {
    const { productEntries, pricesMap } = this.props;
    return (
      <Table
        columns={[
          { name: "thumb", label: "", size: "xs" },
          { name: "item", label: "item", size: "xl" },
          { name: "price", label: "price", alignment: "right", size: "md" },
          {
            name: "quantity",
            label: "quantity",
            alignment: "center",
            size: "md"
          },
          {
            name: "subtotal",
            label: "subtotal",
            alignment: "right",
            size: "md"
          },
          { name: "toolbar", label: "", size: "xs" }
        ]}
      >
        <Table.Header />
        <Table.Body>
          {productEntries.map((orderItem, index) => {
            const price = pricesMap[orderItem.product.id]
              ? pricesMap[orderItem.product.id]
              : 0;

            return (
              <Table.Row
                key={`order-product-${index}`}
                cells={[
                  <Thumbnail
                    key={`thumbnail-${index}`}
                    image={
                      orderItem.product.picture
                        ? apiUrl + orderItem.product.picture.pictureUrl
                        : null
                    }
                  />,
                  <React.Fragment key={`item-${index}`}>
                    <p>
                      <span style={{ color: "#000" }}>
                        <Link
                          key={`item-${orderItem.product.id}`}
                          to={`/products/product/${orderItem.product.id}`}
                          primary
                        >
                          {orderItem.product.name}
                        </Link>
                      </span>
                    </p>
                    <p>
                      <span style={{ color: "#000" }}>
                        {orderItem.product.sku}
                      </span>
                    </p>
                  </React.Fragment>,
                  <Position.RightFlexContainer key={`price-${index}`}>
                    {formatPrice(price)}
                  </Position.RightFlexContainer>,
                  <Position.CenteredContainer key={`count-${index}`}>
                    <ItemCounter
                      id={orderItem.product.id}
                      value={orderItem.count}
                      onChange={this.handleCountChange}
                    />
                  </Position.CenteredContainer>,
                  <Position.RightFlexContainer key={`total-${index}`}>
                    {roundAndFormatPrice(
                      price * parseInt(orderItem.count.toString())
                    )}
                  </Position.RightFlexContainer>,
                  <Position.CenteredContainer key={`toolbar-${index}`}>
                    <DeleteButton
                      id={orderItem.product.id}
                      onClick={this.handleDelete}
                    />
                  </Position.CenteredContainer>
                ]}
              />
            );
          })}
          <Table.Row
            cells={[
              "",
              "",
              "",
              <Position.RightContainer key={`order-product-price-total-label`}>
                <Article.P light scale="lg">
                  TOTAL:
                </Article.P>
              </Position.RightContainer>,
              <Article.P scale="lg" key={`order-product-price-total-label`}>
                {roundAndFormatPrice(
                  productEntries.reduce(
                    (result, orderItem) =>
                      result +
                      (pricesMap[orderItem.product.id] || 0.0) *
                        parseInt(orderItem.count.toString()),
                    0
                  )
                )}
              </Article.P>,
              ""
            ]}
          />
        </Table.Body>
      </Table>
    );
  }
}

interface IConnectedActions {
  productCountChange: typeof productCountChange;
  removeProductFromCart: typeof removeProductFromCart;
}

export default connect(
  null,
  {
    productCountChange,
    removeProductFromCart
  }
)(Form);

import * as React from "react";
import { SCMDashboardOrderListDto } from "src/service-proxies";
import moment from "moment";
import { XIcon } from "../../../assets/icons";
import { Article } from "src/components";
import styled from "styled-components";

const HeaderWrapper = styled.div``;

const Partition = styled.div`
  border-top: 2px solid #f2f2f2;
  margin-top: 10px;
  margin-bottom: 10px;
  margin-left: calc(-100vw / 2 + 500px / 2);
  margin-right: calc(-100vw / 2 + 500px / 2);
`;
const SmallOrderDetails = styled.div`
  font-size: 14px;
  font-weight: bold;
  display: flex;
  margin-top: 5px;
  margin-bottom: 5px;
`;

const ApprovedCircle = styled.div`
  background-color: #079f12;
  border-radius: 50%;
  width: 15px;
  height: 15px;
  margin: 0 5px;
`;

const UnApprovedCircle = styled.div`
  background-color: #cf2a28;
  border-radius: 50%;
  width: 15px;
  height: 15px;
  margin: 0 5px;
`;

const CircleContainer = styled.div`
  display: inline-block;
`;

const BuyingManagerWrapper = styled.div`
  margin: 5px 0;
  display: inline-block;
`;

const ApproverWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const VendorWrapper = styled.div`
  margin: 5px 0;
`;

interface IDrawerProps {
  order: SCMDashboardOrderListDto;
  onExit: () => void;
}

class DashboardDrawer extends React.Component<IDrawerProps> {
  constructor(props) {
    super(props);

    this.generateVendors = this.generateVendors.bind(this);
    this.generateBuyingManagers = this.generateBuyingManagers.bind(this);
    this.handleCloseDetails = this.handleCloseDetails.bind(this);
  }

  generateBuyingManagers(): any[] {
    const details = this.props.order.orderDetails;
    if (details) {
      if (details.approvers) {
        return details.approvers.map(a => (
          <ApproverWrapper>
            {a.approved ? (
              <CircleContainer>
                <ApprovedCircle />
              </CircleContainer>
            ) : (
              <CircleContainer>
                <UnApprovedCircle />
              </CircleContainer>
            )}
            <BuyingManagerWrapper>{a.fullName}</BuyingManagerWrapper>
          </ApproverWrapper>
        ));
      }
    }
    return [];
  }

  generateVendors(): any[] {
    const details = this.props.order.orderDetails;
    if (details) {
      if (details.vendors) {
        return details.vendors.map(v => <VendorWrapper>{v}</VendorWrapper>);
      }
    }
    return [];
  }

  handleCloseDetails() {
    this.props.onExit();
  }

  render() {
    return (
      <React.Fragment>
        {this.props.order !== null ? (
          <div
            style={{
              position: "absolute",
              width: "100%",
              overflow: "hidden",
              display: "flex",
              flexDirection: "column",
              height: "100%",
              padding: "20px"
            }}
          >
            <HeaderWrapper>
              <Article.Text scale="lg">
                <strong>Order Details</strong>
              </Article.Text>
              <span
                style={{
                  float: "right",
                  cursor: "pointer",
                  marginRight: "50px"
                }}
                onClick={this.handleCloseDetails}
              >
                <XIcon />
              </span>
            </HeaderWrapper>
            <Partition />
            <div>
              <SmallOrderDetails>
                Order #: {this.props.order.orderNumber}
              </SmallOrderDetails>
              <SmallOrderDetails>
                Order Date:{" "}
                {moment(this.props.order.submittingTime).format("MM/DD/YYYY")}
              </SmallOrderDetails>
              <SmallOrderDetails>
                Buyer: {this.props.order.buyerName}
              </SmallOrderDetails>
              <SmallOrderDetails>
                Location: {this.props.order.locationName}
              </SmallOrderDetails>
            </div>
            <Partition />
            <div>
              <Article.Text scale="lg">
                <strong>Approval Status:</strong>{" "}
                {this.props.order.approvalStatus}
              </Article.Text>
            </div>
            <div>{this.generateBuyingManagers()}</div>
            <Partition />
            <div>
              <Article.Text scale="lg">
                <strong>Vendors</strong>
              </Article.Text>
              <div>{this.generateVendors()}</div>
            </div>
          </div>
        ) : null}
      </React.Fragment>
    );
  }
}

export default DashboardDrawer;

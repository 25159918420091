import styled from 'styled-components';
import * as React from 'react';


interface SideMenuProps {
  inner: boolean;
}

interface LabelProps {
  expanded: boolean;
  active?: boolean;
  onClick?: () => void;
}

export const StyledSideMenu = styled.nav<SideMenuProps>`
  ${({ theme, ...props }) => {
    const {
      global,
      fs_xs,
      primary,
      lighter,
    } = theme;

    const topBorders = props.inner ?
      `
      border-top-style: solid;
        
      border-top-width: 1px;` :
      '';

    return `
      ${global}

      border-right-style: solid;
      
      border-right-width: 1px;

      ${topBorders}

      padding: 8px 0px 0 8px;

      flex: 0 0 269px;

      border-color: ${lighter};
      > a{ 
 
        text-transform: uppercase; 
        text-decoration: none; 
        display: block; 
        transition: all .25s ease-in-out; 
 
        font-size: ${fs_xs}; 
         
        line-height: 40px; 
 
        color: ${primary}; 
 
        &:hover{ 
          color: ${theme.default}; 
        } 
         
        &.active{ 
          color: ${theme.default}; 
        } 
 
      } 
    `;
  }}
`;

const StyledLabel  = styled.span<LabelProps>`
  ${({ theme, ...props }) => {

    const {
      primary,
    } = theme;

    const color = (props.active) ?
      theme.default :
      primary;

    return `
      display: flex;
      cursor: pointer;
      align-items: center;
      transition: all .5s ease-in-out;
      margin-left: 5px;
      color: ${color};
    `;
  }}
`;

interface SideMenuType extends React.ComponentClass<SideMenuProps> {
  Label: React.ComponentClass<LabelProps>;
}

const SideMenu = StyledSideMenu as unknown as SideMenuType;
SideMenu.Label = StyledLabel as React.ComponentClass<LabelProps>;

export default SideMenu;

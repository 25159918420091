import * as React from 'react';
import { Fragment } from 'react';
import styled, {StyledComponentClass} from 'styled-components';
import {Action, ActionCreatorsMapObject, bindActionCreators} from 'redux';
import { connect } from 'react-redux';
import withGranted, {IWithGrantedProps} from '../../HOC/WithGranted';
import { history } from '../../../store';

import {
  Table,
  Link,
  MainContainer,
  Button,
  Pagination,
  PromptModal,
} from '../../../components';

import {
  PencilIcon,
  TrashIcon,
  CaretLeftIcon,
  PlusIcon,
} from '../../../assets/icons';

import * as actions from '../../../_actions/divisions';
import * as actionsSearch from '../../../_actions/search';
import * as actionsModal from '../../../_actions/modal';
import * as actionsLayout from '../../../_actions/layout';

import { createClientCatalog } from '../../../_api/clientCatalog';
import {
  ADMINISTRATION_CLIENTS_DIVISION_CREATE,
  ADMINISTRATION_CLIENTS_DIVISIONS_EDIT,
  ADMINISTRATION_CLIENTS_DIVISIONS_DELETE,
  ADMINISTRATION_CLIENTS_DIVISIONS_LOCATIONS,
  ADMINISTRATION_CLIENTCATALOGUE,
  ADMINISTRATION_CLIENTCATALOGUE_CREATE,
} from '../../../_constants/permissions';
import {createStructuredSelector} from 'reselect';
import {DivisionListDto} from '../../../service-proxies';
import {IGlobalStore} from '../../../_reducers/reducers';
import {RouteComponentProps} from 'react-router';
import {IOpenConfirmPrompt, IOpenCustomModalCallback} from '../../../_actions/modal';

type StyledComponentType = StyledComponentClass<{}, React.StatelessComponent<{}>>;

interface IPosition extends StyledComponentType {
  Head?: StyledComponentClass<{className?: string}, React.StatelessComponent<{className?: string}>>;
  Body?: StyledComponentType;
  HeadElement?: StyledComponentType;
  Toolbar?: StyledComponentType;
}

const Position: IPosition = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column; 
`;

Position.Body = styled.div`
  width: 100%; 
  height: 100%; 
`;

Position.Head = styled<{ className?: string }>(({ className, children }) => <header className={className}>
  <div>
    {children}
  </div>
</header>)`
  padding: 20px;
  >div{
    margin: -7.5px;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }
`;

Position.HeadElement = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  & > * {
    margin: 7.5px;
  }
`;

Position.Toolbar = styled.header`
  text-align: right;
  height: 15px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

const EditButton: React.SFC<{onEdit: (id: number) => void, id: number}> = ({
  onEdit,
  id,
}) => {

  function handleEdit() {
    onEdit(id);
  }

  return <Link.Button onClick={handleEdit}><PencilIcon /></Link.Button>;
};

const DeleteButton: React.SFC<{onDelete: (id: number) => void, id: number}> = ({
  onDelete,
  id,
}) => {

  function handleDelete() {
    onDelete(id);
  }

  return <Link.Button onClick={handleDelete}><TrashIcon /></Link.Button>;
};

interface IListProps extends
  IConnectedProps,
  IConnectedActions,
  IWithGrantedProps,
  RouteComponentProps<{clientId: string}> {
}

class List extends React.Component<IListProps> {

  constructor(props) {
    super(props);
    this.handleAddNewClick = this.handleAddNewClick.bind(this);
    this.handleChangePage = this.handleChangePage.bind(this);
    this.handleItemEdit = this.handleItemEdit.bind(this);
    this.handleItemDeleteRequest = this.handleItemDeleteRequest.bind(this);

    this.props.setSearch(`${this.props.match.url}`, (text) => {
      this.props.getAll(this.props.match.params.clientId, this.props.perPage, this.props.skip, text);
    });
  }

  componentDidMount() {
    this.props.getAll(this.props.match.params.clientId, this.props.perPage, this.props.skip);
  }

  handleAddNewClick() {
    history.push(`${this.props.match.url}/new`);
  }

  handleChangePage(pageInfo) {
    const {
      perPage,
      skip,
    } = pageInfo;

    this.props.getAll(this.props.match.params.clientId, perPage, skip);
    this.props.setPerPage(perPage);
  }

  renderPreloaderRows(perPage, status) {
    const rows = [];
    const elementsCount = (status === 'loading') ? perPage - 1 : 0;

    for (let i = 0; i < elementsCount; i++) {
      rows.push(<Table.Row
        key={i}
        colSpan="2"
        rows={perPage}
        isLoading={status === 'loading'}
        cells={[<span key="0">NO DIVISIONS FOUND</span>]}
      />);
    }
    return rows;
  }

  handleItemEdit(id) {
    history.push(`${this.props.match.url}/${id}`);
  }

  handleItemDeleteRequest(id) {
    this.props.prompt()
      .then(() => {
        this.props.remove(id)
          .then(() => {
            this.props.getAll(this.props.match.params.clientId, this.props.perPage, this.props.skip);
          });
      })
      .catch(() => { });
  }

  handleCreateCatalog(divisionId) {
    this.props.openModal(
      (resolve, reject) => (
        <PromptModal
          label="Catalog name"
          required
          resolve={resolve}
          maxLength={100}
          reject={reject}
          saveButtonText="Create"
          onSubmit={(name) => createClientCatalog(divisionId, name)}
        />
      ),
      { title: 'NEW CLIENT CATALOG' }
    ).then(({ id }) => {
      history.push(`/admin/catalog/${id}`); // redirect to edit catalog page
    }).catch(() => {
    });
  }

  render() {
    return (
      <MainContainer title="divisions management">
        <Position>
          <Position.Head>
            <Position.HeadElement>
              <Link to={`/admin/clients`}><CaretLeftIcon />Clients List</Link>
            </Position.HeadElement>
            <Position.HeadElement>
              {this.props.isGranted(ADMINISTRATION_CLIENTS_DIVISION_CREATE) && <Button onClick={this.handleAddNewClick} icon={<PlusIcon/>}> Add new</Button>}
            </Position.HeadElement>
          </Position.Head>
          <Position.Body>
            <Table fullHeight>
              <Table.Header columns={[
                { name: 'name', label: 'name' },
                { name: 'catalog', label: 'catalog' },
                { name: '', label: '' },
              ]} />
              <Table.Body>
                {
                  (this.props.divisions && !!this.props.divisions.length) ?
                    this.props.divisions.map((item, index) =>
                      <Table.Row
                        key={`${index}-${item.id}`}
                        cells={[
                          (
                            this.props.isGranted(ADMINISTRATION_CLIENTS_DIVISIONS_LOCATIONS) ?
                              <Link key={`cell-name-${index}-${item.id}`} to={`${this.props.match.url}/${item.id}/locations`}>{item.name}</Link>
                              :
                              item.name
                          ),
                          // ------------------------
                          (item.catalog ?
                            (this.props.isGranted(ADMINISTRATION_CLIENTCATALOGUE) ?
                              <Fragment>
                                <Link key={`cell-catalog-${index}-${item.id}`} to={`/admin/catalog/${item.catalog.id}`}>{item.catalog.name}</Link>
                                {' '}
                                <Link primary key={`cell-catalog-view-${index}-${item.id}`} to={`/admin/catalog/${item.catalog.id}/products`}>(view)</Link>
                              </Fragment> :
                              item.catalog.name
                            )
                            :
                            this.props.isGranted(ADMINISTRATION_CLIENTCATALOGUE_CREATE) &&
                            <Link.Button scale="sm" key={`cell-catalog-${index}-${item.id}`} onClick={() => this.handleCreateCatalog(item.id)}>Create Catalog</Link.Button>),
                          // ------------------------
                          <Position.Toolbar key={`cell-toolbar-${index}-${item.id}`} >
                            {
                              this.props.isGranted(ADMINISTRATION_CLIENTS_DIVISIONS_EDIT) &&
                              <EditButton onEdit={this.handleItemEdit} id={item.id} />
                            }
                            {
                              this.props.isGranted(ADMINISTRATION_CLIENTS_DIVISIONS_DELETE) &&
                              <DeleteButton onDelete={this.handleItemDeleteRequest} id={item.id} />
                            }
                          </Position.Toolbar>
                        ]}
                      />) :
                    <Table.Row
                      key={0}
                      colSpan="2"
                      rows={this.props.perPage}
                      isLoading={this.props.listStatus === 'loading'}
                      cells={[<span key="0">NO DIVISIONS FOUND</span>]}
                    />
                }
                {
                  (this.props.divisions && !!this.props.divisions.length) ||
                  this.renderPreloaderRows(this.props.perPage, this.props.listStatus)
                }
              </Table.Body>
            </Table>
          </Position.Body>
          {
            <Pagination
              skip={this.props.skip}
              perPage={this.props.perPage}
              count={this.props.totalCount}
              onChange={this.handleChangePage}
            />
          }
        </Position>
      </MainContainer>
    );
  }
}

type RemoveDivisionType = (id: number) => Promise<void>;

interface IConnectedProps {
  divisions: DivisionListDto[];
  listStatus: string;
  totalCount: number;
  skip: number;
  perPage: number;
}

interface IConnectedActions {
  getAll: typeof actions.getAll;
  remove: RemoveDivisionType;
  setSearch: typeof actionsSearch.set;
  prompt: IOpenConfirmPrompt;
  openModal: IOpenCustomModalCallback;
  setPerPage: typeof actionsLayout.setPerPage;
}

export default connect(
  createStructuredSelector<IGlobalStore, IConnectedProps>({
    divisions: state => state.divisions.divisions,
    listStatus: state => state.divisions.listStatus,
    totalCount: state => state.divisions.totalCount,
    skip: state => state.divisions.skip,
    perPage: state => state.layout.perPage,
  }),
  dispatch => bindActionCreators<IConnectedActions & ActionCreatorsMapObject, ActionCreatorsMapObject<Action>>({
    getAll: actions.getAll,
    remove: actions.remove as any as RemoveDivisionType,
    setSearch: actionsSearch.set,
    prompt: actionsModal.prompt as any as IOpenConfirmPrompt,
    openModal: actionsModal.openCustomModalCallback as any as  IOpenCustomModalCallback,
    setPerPage: actionsLayout.setPerPage,
  }, dispatch)
)(withGranted(List));

import React from 'react';
import {
  GetСategoriesDto,
  ListResultDtoOfGetСategoriesDto,
  ListResultDtoOfCountDtoOfInt64,
} from '../../../service-proxies';
import {
  getClientCategories,
} from 'src/_api/categories';

// type OnGetCategories = (catalogId: number) => Promise<ListResultDtoOfGetСategoriesDto>;
// type OnGetMasterCategories = () => Promise<ListResultDtoOfGetСategoriesDto>;

export interface IGetCategories extends GetСategoriesDto {
  expanded?: boolean;
  count?: number;
}

interface ICategoryContextProviderState {
  categories: GetСategoriesDto[];
  totalCount: number;
  status: 'loading' | 'done' | string;
  catalogId: number;
}

interface ICategoryContextProviderProps {
  catalogId?: number;
  onGetCategories?: ((catalogId?: number) => Promise<ListResultDtoOfGetСategoriesDto | ListResultDtoOfGetСategoriesDto>);
  onGetCategoriesCounts?: (filter: string, catalogId: number, isArchived: boolean) => Promise<ListResultDtoOfCountDtoOfInt64>;
}

const initialState = {
  categories: [],
  totalCount: null,
  status: 'loading',
  catalogId: null,
};

const CategoryContext = React.createContext<ICategoryContextProviderState>(initialState);

export class CategoryContextProvider extends React.Component<ICategoryContextProviderProps, ICategoryContextProviderState> {

  static defaultProps: ICategoryContextProviderProps = {
    onGetCategories: getClientCategories,
  };

  constructor(props: ICategoryContextProviderProps) {
    super(props);
    this.getCategories = this.getCategories.bind(this);
    this.state = {
      ...initialState,
    };
  }

  static getDerivedStateFromProps(props: ICategoryContextProviderProps, prevState: ICategoryContextProviderState) {
    if (props.catalogId !== prevState.catalogId) {
      return {
        ...prevState,
        catalogId: props.catalogId,
      };
    }
    return prevState;
  }

  componentDidMount() {
    this.getCategories();
  }

  componentDidUpdate(prevProps: ICategoryContextProviderProps, prevState: ICategoryContextProviderState) {
    if (this.state.catalogId !== prevState.catalogId) {
      this.getCategories();
    }
  }

  async getCategories(searchText: string = '', isArchived?: boolean, expandSubCategoryId?: number) {
    this.setState({ status: 'loading' });
    const categoriesData = await this.props.onGetCategories(this.props.catalogId);
    const categoriesCountsData = await this.props.onGetCategoriesCounts(searchText, this.props.catalogId, isArchived);
    const countsMap = {};
    categoriesCountsData.items.forEach(countData => {
      countsMap[countData.id] = countData.count;
    });
    const categories = categoriesData.items.map(categorie => {
      categorie.name = `${categorie.name} (${countsMap[categorie.id] || 0})`;
      return categorie;
    });

    const map = getExpandedMap(categories);

    preserveExpandedCategories(categories, map, parseInt(expandSubCategoryId as any as string, 10));

    this.setState({
      categories,
      status: 'done',
      totalCount: getTotalCategoriesCount(categories),
    });
  }

  render() {
    return <CategoryContext.Provider value={this.state}>
      {this.props.children}
    </CategoryContext.Provider>;
  }
}

function getTotalCategoriesCount(categories: GetСategoriesDto[]): number {
  let count = categories.length;
  for (const category of categories) {
    if (category.childCategories && category.childCategories.length) {
      count += getTotalCategoriesCount(category.childCategories);
    }
  }
  return count;
}

function getExpandedMap(categories: IGetCategories[]): {[id: number]: true} {
  const map = {};
  for(const category of categories) {
    if (category.expanded) {
      map[category.id] = true;
    }
    if (category.childCategories && category.childCategories.length) {
      Object.assign(map, getExpandedMap(category.childCategories));
    }
  }
  return map;
}

function preserveExpandedCategories(categories: IGetCategories[], expandedMap: {[id: number]: true}, expandSubCategoryId: number): boolean {
  let someFound = false;
  for(const category of categories) {
    if (expandedMap[category.id]) {
      category.expanded = true;
    }
    if (expandSubCategoryId && category.id === expandSubCategoryId) {
      category.expanded = true;
      someFound = true;
    }
    if (category.childCategories && category.childCategories.length) {
      if (preserveExpandedCategories(category.childCategories, expandedMap, expandSubCategoryId)) {
        category.expanded = true;
        someFound = true;
      }
    }
  }
  return someFound;
}

export const CategoryContextConsumer = CategoryContext.Consumer;
import * as React from "react";
import styled from "styled-components";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { Action, ActionCreatorsMapObject, bindActionCreators } from "redux";
import moment from "moment";
import DashboardDrawer from "./DashboardDrawer";

import {
  ClientUnitType,
  BuyerOrderListDto,
  SCMDashboardOrderListDto
} from "../../../service-proxies";
import * as ordersApi from "../../../_api/orders";
import * as notifyActions from "../../../_actions/notification";
import * as searchActions from "../../../_actions/search";
import * as layoutActions from "../../../_actions/layout";
import { IGlobalStore } from "../../../_reducers/reducers";
import withGranted, { IWithGrantedProps } from "../../HOC/WithGranted";
import { ORDERS_UNCOMPARED_APPROVE } from "../../../_constants/permissions";
import {
  saveFilterSettings,
  loadFilterSettings
} from "../../../_utils/userSession";

import {
  Article,
  DatePicker,
  Link,
  MainContainer,
  Pagination,
  RunningLineLoader,
  Separator,
  Skeleton,
  Table
} from "../../../components";
import LocationSelectorComponent from "../../../components/LocationSelector";
import { clientUnitFilterOptions } from "../../../_utils/clientUnitFilter";
import { IShortLocationInfo } from "../../../_types/common";
import { IFlatLocationOption } from "../../../_reducers/current-user";

interface ITwoLevelElement {
  className?: string;
}

const Position = {
  Container: styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
  `,

  Content: styled.div`
    height: 100%;
    flex: 1 1 100%;
  `,

  Footer: styled.div`
    flex: 0 0;
  `,

  Filter: styled.div`
    width: 260px;
    padding-top: 4px;
    margin-right: 16px;
  `,

  Toolbar: styled.div`
    text-align: right;
    > * {
      margin: 0 0 0 10px;
    }
  `,

  TopLine: styled<ITwoLevelElement>(function({ className, ...props }) {
    return (
      <div className={className}>
        <Separator>{props.children}</Separator>
      </div>
    );
  })`
    ${Separator} {
      width: 100%;
      padding: 10px 20px;
      display: flex;
    }
  `,

  TopLineElement: styled.div`
    flex: 1 1 auto;
    &:last-of-type {
      flex: 1 1 100%;
    }
    &:first-of-type {
      flex: 0 0 300px;
    }
  `,

  Head: styled<{ className?: string }>(({ className, children }) => (
    <header className={className}>
      <div>{children}</div>
    </header>
  ))`
    padding: 20px;
    > div {
      margin: -7.5px;
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
    }
  `,

  HeadElement: styled.div`
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    & > * {
      margin: 7.5px;
    }
  `,

  TabsSwitch: styled.div`
    padding: 10px 0 10px 10px;
  `
};

interface IListProps
  extends IConnectedProps,
    IConnectedActions,
    IWithGrantedProps {}

interface IListState {
  ordersStatus: string;
  dashboardOrders?: SCMDashboardOrderListDto[];
  selectedDashboardItem: SCMDashboardOrderListDto;
  showDashboardDetails: boolean;
  totalCountDashboard: number;
  startDate?: moment.Moment | null;
  dueDate?: moment.Moment | null;
  filter: string;
  dashboardSorting?: string;
  skip?: number;
  perPage?: number;
  focusedInput?: string;
  clientUnitId?: number;
  clientUnitType?: ClientUnitType;
}

interface IGetDashboardOrdersParams {
  clientUnitId?: number;
  filter?: string;
  dashboardSorting?: string;
  ordersStatus?: "loading" | "sorting" | "done" | string;
  orders?: BuyerOrderListDto[];
  skip?: number;
  perPage?: number;
  divisionLocationFilter?: number | null;
}

class List extends React.Component<IListProps, IListState> {
  constructor(props) {
    super(props);

    const filterSettings = loadFilterSettings("clientorders");

    this.state = {
      ordersStatus: "",
      totalCountDashboard: 0,
      selectedDashboardItem: null,
      showDashboardDetails: false,
      startDate:
        filterSettings.startDate != null
          ? moment(filterSettings.startDate)
          : null,
      dueDate:
        filterSettings.dueDate != null ? moment(filterSettings.dueDate) : null,
      filter: "",
      dashboardSorting: "approvalStatus DESC",
      skip: 0,
      perPage: this.props.perPage || 10,
      clientUnitId: filterSettings.location || props.locationInfo.id,
      clientUnitType: ClientUnitType._3
    };
    this.getDashboardOrders = this.getDashboardOrders.bind(this);
    this.handleDateChanged = this.handleDateChanged.bind(this);
    this.handleFocusedChange = this.handleFocusedChange.bind(this);
    this.handleSortChange = this.handleSortChange.bind(this);
    this.handlePaginate = this.handlePaginate.bind(this);
    this.handleFilterChange = this.handleFilterChange.bind(this);
    this.handleDetails = this.handleDetails.bind(this);
    this.handleCloseDetails = this.handleCloseDetails.bind(this);
  }

  componentDidMount() {
    this.props.setSearch("/clients-orders/dashboard/", text => {
      this.setState({ skip: 0 }, () => {
        this.getDashboardOrders({ filter: text });
      });
    });
    this.getDashboardOrders();
  }

  componentWillReceiveProps(nextProps: IListProps) {
    if (nextProps.locationInfo.id !== this.props.locationInfo.id) {
      this.setState(
        {
          clientUnitId: nextProps.locationInfo.id,
          clientUnitType: ClientUnitType._3
        },
        () => {
          this.getDashboardOrders();
        }
      );
    }
  }

  static renderPreloader(perPage: number, status: string): React.Component[] {
    const rows = [];
    const elementsCount = status === "loading" ? perPage : 0;

    for (let i = 0; i < elementsCount; i++) {
      rows.push(
        <Table.Row
          key={i}
          rows={perPage}
          cells={[
            <Skeleton.Line key={`order-list-preloader-${i}`} size="sm" />,
            <Skeleton.Line key={`order-list-preloader-${i}`} size="sm" />,
            <Skeleton.Line key={`order-list-preloader-${i}`} size="sm" />,
            <Skeleton.Line key={`order-list-preloader-${i}`} size="sm" />,
            <Skeleton.Line key={`order-list-preloader-${i}`} size="sm" />,
            <Skeleton.Line key={`order-list-preloader-${i}`} size="sm" />
          ]}
        />
      );
    }
    return rows;
  }

  getDashboardOrders(params: IGetDashboardOrdersParams = {}): void {
    let statusStartKey = "loading";
    let statusFinnishKey = "loaded";

    const skip =
      Number.isInteger(params.skip) && params.skip >= 0
        ? params.skip
        : this.state.skip;
    const sort = params.dashboardSorting || this.state.dashboardSorting;
    const filter = params.filter || this.state.filter;
    const perPage = params.perPage || this.props.perPage;
    const clientUnitId = params.clientUnitId || this.state.clientUnitId;

    if (params.dashboardSorting) {
      statusStartKey = "sorting";
      statusFinnishKey = "sorted";
    }

    this.setState({ ordersStatus: statusStartKey });

    ordersApi
      .getAllOrdersForSCMDashboard(clientUnitId, filter, sort, skip, perPage)
      .then(ordersData => {
        this.setState({
          ordersStatus: statusFinnishKey,
          dashboardOrders: ordersData.items,
          totalCountDashboard: ordersData.totalCount
        });
      })
      .catch(e => {
        notifyActions.notify("fgfg", "error");
        notifyActions.notifySwagger(e, "error");
      });
  }

  handleDateChanged(range: {
    startDate: moment.Moment;
    endDate: moment.Moment;
  }): void {
    const startDate = range.startDate
      ? range.startDate.startOf("day")
      : undefined;
    const dueDate = range.endDate ? range.endDate.startOf("day") : undefined;

    this.setState({ startDate, dueDate, skip: 0 }, () => {
      this.setFilterSettings();
      this.getDashboardOrders();
    });
  }

  handleFocusedChange(focusedInput) {
    this.setState({ focusedInput });
  }

  handleFilterChange(value: IFlatLocationOption) {
    this.setState(
      { clientUnitId: value.id, clientUnitType: value.level + 1, skip: 0 },
      () => {
        this.setFilterSettings();
        this.getDashboardOrders({
          dashboardSorting: this.state.dashboardSorting
        });
      }
    );
  }

  handleSortChange(sorting: string): void {
    this.setState({ dashboardSorting: sorting }, () => {
      const dashboardSorting = sorting;
      this.getDashboardOrders({ dashboardSorting });
    });
  }

  handlePaginate(pageInfo: { perPage: number; skip: number }): void {
    const { perPage, skip } = pageInfo;
    this.getDashboardOrders({ perPage, skip });
    this.props.setPerPage(perPage);
    this.setState({ skip });
  }

  handleCloseDetails() {
    const show = this.state.showDashboardDetails;
    this.setState({
      showDashboardDetails: !show
    });
  }

  handleDetails(item: SCMDashboardOrderListDto) {
    const show = this.state.showDashboardDetails;
    const selectedItem = this.state.selectedDashboardItem;
    if (item === selectedItem || selectedItem === null) {
      this.setState({
        showDashboardDetails: !show,
        selectedDashboardItem: item
      });
    } else {
      this.setState({
        showDashboardDetails: true,
        selectedDashboardItem: item
      });
    }
  }

  setFilterSettings() {
    const filter = {
      location: this.state.clientUnitId,
      startDate: this.state.startDate,
      dueDate: this.state.dueDate
    };

    saveFilterSettings("clientorders", filter);
  }

  render() {
    return (
      <React.Fragment>
        <MainContainer>
          <Position.Container>
            <Position.Head>
              <Position.HeadElement>
                <Article.Text>
                  <strong>SCM ORDER DASHBOARD</strong>
                </Article.Text>
              </Position.HeadElement>
              <Position.HeadElement>
                <Position.Filter>
                  <LocationSelectorComponent
                    customFilter={clientUnitFilterOptions}
                    locationOptions={this.props.locationOptions.filter(
                      opt => opt.level <= 1
                    )}
                    onLocationChange={this.handleFilterChange}
                    selectedLocation={this.state.clientUnitId}
                  />
                </Position.Filter>
                <DatePicker
                  compact
                  type="range"
                  startDateId="startDate"
                  endDateId="endDate"
                  isOutsideRange={() => false}
                  startDate={this.state.startDate}
                  endDate={this.state.dueDate}
                  onDatesChange={this.handleDateChanged}
                  focusedInput={this.state.focusedInput}
                  keepOpenOnDateSelect
                  showPeriods
                  onFocusChange={this.handleFocusedChange}
                />
              </Position.HeadElement>
            </Position.Head>
            <RunningLineLoader
              isActive={this.state.ordersStatus === "sorting"}
            />

            <Position.Content>
              <Table>
                <Table.Header
                  columns={[
                    {
                      name: "orderNumber",
                      label: "Order Number",
                      sortable: true
                    },
                    {
                      name: "submittingTime",
                      label: "Submitted Date",
                      sortable: true
                    },
                    { name: "buyerName", label: "Buyer", sortable: true },
                    {
                      name: "locationName",
                      label: "Location",
                      sortable: true
                    },
                    {
                      name: "approvalStatus",
                      label: "Approval Status",
                      sortable: true
                    },
                    { name: "toolbar", label: "", size: "lg" }
                  ]}
                  sortBy={this.state.dashboardSorting}
                  onSortChanged={this.handleSortChange}
                />
                <Table.Body>
                  {(this.state.ordersStatus === "loaded" ||
                    this.state.ordersStatus === "sorting" ||
                    this.state.ordersStatus === "sorted") &&
                    (this.state.dashboardOrders.length ? (
                      this.state.dashboardOrders.map((item, index) => (
                        <Table.Row
                          key={`order-list-${index}-${item.orderNumber}`}
                          // disabled={item.isApproved}
                          cells={[
                            item.orderNumber,
                            moment(item.submittingTime).format("MM/DD/YYYY"),
                            item.buyerName,
                            item.locationName,
                            item.approvalStatus,
                            <Position.Toolbar
                              key={`order-list-approve-${index}-${item.orderNumber}`}
                            >
                              {item.approvalStatus === "Uncompared" ? (
                                <Link
                                  key={`order-list-id-${index}-${item.orderNumber}`}
                                  to={`/clients-orders/dashboard/${item.orderNumber}`}
                                >
                                  View Order
                                </Link>
                              ) : null}
                              {this.props.isGranted(
                                ORDERS_UNCOMPARED_APPROVE
                              ) && (
                                <Link.Button
                                  id={item.orderNumber}
                                  onClick={() => this.handleDetails(item)}
                                >
                                  Details
                                </Link.Button>
                              )}
                            </Position.Toolbar>
                          ]}
                        />
                      ))
                    ) : (
                      <Table.Row
                        key={0}
                        colSpan="6"
                        rows={this.props.perPage}
                        cells={[<span key="0">NO ORDERS FOUND</span>]}
                      />
                    ))}
                  {List.renderPreloader(
                    this.props.perPage,
                    this.state.ordersStatus
                  )}
                </Table.Body>
              </Table>
            </Position.Content>
            <Position.Footer>
              <Pagination
                count={this.state.totalCountDashboard}
                skip={this.state.skip}
                perPage={this.props.perPage}
                onChange={this.handlePaginate}
              />
            </Position.Footer>
          </Position.Container>
          {this.state.showDashboardDetails ? (
            <div
              style={{
                float: "right",
                flex: "0 0",
                flexDirection: "column",
                position: "relative",
                border: "1px solid #F2F2F2",
                borderLeft: "20px solid #F2F2F2",
                flexBasis: "430px",
                fontFamily: "Montserrat, sans-serif",
                transition: "all .3s ease-in-out",
                opacity: 1,
                fontSize: 14
              }}
            >
              <DashboardDrawer
                order={this.state.selectedDashboardItem}
                onExit={this.handleCloseDetails}
              />
            </div>
          ) : (
            <div
              style={{
                float: "right",
                flexBasis: "0px",
                fontFamily: "Montserrat, sans-serif",
                transition: "all .3s ease-in-out",
                opacity: 0
              }}
             />
          )}
        </MainContainer>
      </React.Fragment>
    );
  }
}

interface IConnectedProps {
  locationInfo?: IShortLocationInfo;
  locationOptions: IFlatLocationOption[];
  perPage: number;
}

interface IConnectedActions {
  notifySwagger: typeof notifyActions.notifySwagger;
  setSearch: typeof searchActions.set;
  setPerPage: typeof layoutActions.setPerPage;
}

export default connect(
  createStructuredSelector<IGlobalStore, IConnectedProps>({
    perPage: state => state.layout.perPage,
    locationInfo: state => state.currentUser.locationInfo,
    locationOptions: state => state.currentUser.locationOptions
  }),
  dispatch =>
    bindActionCreators<IConnectedActions & ActionCreatorsMapObject, ActionCreatorsMapObject<Action>>(
      {
        notifySwagger: notifyActions.notifySwagger,
        setSearch: searchActions.set,
        setPerPage: layoutActions.setPerPage
      },
      dispatch
    )
)(withGranted(List));

import React from 'react';
import styled from 'styled-components';

const MinusIcon = props => {
  return(
    <i {...props}>
      <svg viewBox="-606 4397 10 2" width="10" height="10">
        <path d=" M -606 4397 L -606 4399 L -596 4399 L -596 4397 L -606 4397 Z " />
      </svg>
    </i>
  );
};

export default styled(MinusIcon)`
  ${ props => `
    width: 21px;
    height: 21px;
    display: inline-flex;
    justify-content: center;
    align-items: center;

    svg{
      path{
        fill: ${props.theme.primary}; 
      }
    }
  `}
`;

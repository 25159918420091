import React from 'react';
import styled from 'styled-components';

const HomeIcon = props => {
  return(
    <i {...props}>
      <svg viewBox="628 290 26 25.953" width="20" height="20">
        <path d=" M 641 290 L 628 297.486 L 629 299.219 L 629 315.953 L 653 315.953 L 653 299.219 L 654 297.486 L 641 290 Z  M 641 292.307 L 651 298.066 L 651 313.953 L 631 313.953 L 631 298.066 L 641 292.307 Z " />
      </svg>
    </i>
  );
};

export default styled(HomeIcon)`
  ${ props => `
    width: 21px;
    height: 21px;
    display: inline-flex;
    justify-content: center;

    svg{
      width: 100%;
      height: 100%;
      path{
        fill: ${props.theme.default}; 
      }
    }
  `}
`;
